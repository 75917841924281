$mdi-filename:         "materialdesignicons";
$mdi-font-name:        "Material Design Icons";
$mdi-font-family:      "materialdesignicons";
$mdi-font-weight:      "normal";
$mdi-font-path:        "../fonts" !default;
$mdi-font-size-base:   24px !default;
$mdi-css-prefix:       mdi !default;
$mdi-version:          "5.9.55" !default;

$mdi-icons: (
  "ab-testing": F01C9,
  "abacus": F16E0,
  "abjad-arabic": F1328,
  "abjad-hebrew": F1329,
  "abugida-devanagari": F132A,
  "abugida-thai": F132B,
  "access-point": F0003,
  "access-point-check": F1538,
  "access-point-minus": F1539,
  "access-point-network": F0002,
  "access-point-network-off": F0BE1,
  "access-point-off": F1511,
  "access-point-plus": F153A,
  "access-point-remove": F153B,
  "account": F0004,
  "account-alert": F0005,
  "account-alert-outline": F0B50,
  "account-arrow-left": F0B51,
  "account-arrow-left-outline": F0B52,
  "account-arrow-right": F0B53,
  "account-arrow-right-outline": F0B54,
  "account-box": F0006,
  "account-box-multiple": F0934,
  "account-box-multiple-outline": F100A,
  "account-box-outline": F0007,
  "account-cancel": F12DF,
  "account-cancel-outline": F12E0,
  "account-cash": F1097,
  "account-cash-outline": F1098,
  "account-check": F0008,
  "account-check-outline": F0BE2,
  "account-child": F0A89,
  "account-child-circle": F0A8A,
  "account-child-outline": F10C8,
  "account-circle": F0009,
  "account-circle-outline": F0B55,
  "account-clock": F0B56,
  "account-clock-outline": F0B57,
  "account-cog": F1370,
  "account-cog-outline": F1371,
  "account-convert": F000A,
  "account-convert-outline": F1301,
  "account-cowboy-hat": F0E9B,
  "account-details": F0631,
  "account-details-outline": F1372,
  "account-edit": F06BC,
  "account-edit-outline": F0FFB,
  "account-group": F0849,
  "account-group-outline": F0B58,
  "account-hard-hat": F05B5,
  "account-heart": F0899,
  "account-heart-outline": F0BE3,
  "account-key": F000B,
  "account-key-outline": F0BE4,
  "account-lock": F115E,
  "account-lock-outline": F115F,
  "account-minus": F000D,
  "account-minus-outline": F0AEC,
  "account-multiple": F000E,
  "account-multiple-check": F08C5,
  "account-multiple-check-outline": F11FE,
  "account-multiple-minus": F05D3,
  "account-multiple-minus-outline": F0BE5,
  "account-multiple-outline": F000F,
  "account-multiple-plus": F0010,
  "account-multiple-plus-outline": F0800,
  "account-multiple-remove": F120A,
  "account-multiple-remove-outline": F120B,
  "account-music": F0803,
  "account-music-outline": F0CE9,
  "account-network": F0011,
  "account-network-outline": F0BE6,
  "account-off": F0012,
  "account-off-outline": F0BE7,
  "account-outline": F0013,
  "account-plus": F0014,
  "account-plus-outline": F0801,
  "account-question": F0B59,
  "account-question-outline": F0B5A,
  "account-reactivate": F152B,
  "account-reactivate-outline": F152C,
  "account-remove": F0015,
  "account-remove-outline": F0AED,
  "account-search": F0016,
  "account-search-outline": F0935,
  "account-settings": F0630,
  "account-settings-outline": F10C9,
  "account-star": F0017,
  "account-star-outline": F0BE8,
  "account-supervisor": F0A8B,
  "account-supervisor-circle": F0A8C,
  "account-supervisor-circle-outline": F14EC,
  "account-supervisor-outline": F112D,
  "account-switch": F0019,
  "account-switch-outline": F04CB,
  "account-tie": F0CE3,
  "account-tie-outline": F10CA,
  "account-tie-voice": F1308,
  "account-tie-voice-off": F130A,
  "account-tie-voice-off-outline": F130B,
  "account-tie-voice-outline": F1309,
  "account-voice": F05CB,
  "adjust": F001A,
  "adobe": F0936,
  "adobe-acrobat": F0F9D,
  "air-conditioner": F001B,
  "air-filter": F0D43,
  "air-horn": F0DAC,
  "air-humidifier": F1099,
  "air-humidifier-off": F1466,
  "air-purifier": F0D44,
  "airbag": F0BE9,
  "airballoon": F001C,
  "airballoon-outline": F100B,
  "airplane": F001D,
  "airplane-landing": F05D4,
  "airplane-off": F001E,
  "airplane-takeoff": F05D5,
  "airport": F084B,
  "alarm": F0020,
  "alarm-bell": F078E,
  "alarm-check": F0021,
  "alarm-light": F078F,
  "alarm-light-off": F171E,
  "alarm-light-off-outline": F171F,
  "alarm-light-outline": F0BEA,
  "alarm-multiple": F0022,
  "alarm-note": F0E71,
  "alarm-note-off": F0E72,
  "alarm-off": F0023,
  "alarm-panel": F15C4,
  "alarm-panel-outline": F15C5,
  "alarm-plus": F0024,
  "alarm-snooze": F068E,
  "album": F0025,
  "alert": F0026,
  "alert-box": F0027,
  "alert-box-outline": F0CE4,
  "alert-circle": F0028,
  "alert-circle-check": F11ED,
  "alert-circle-check-outline": F11EE,
  "alert-circle-outline": F05D6,
  "alert-decagram": F06BD,
  "alert-decagram-outline": F0CE5,
  "alert-minus": F14BB,
  "alert-minus-outline": F14BE,
  "alert-octagon": F0029,
  "alert-octagon-outline": F0CE6,
  "alert-octagram": F0767,
  "alert-octagram-outline": F0CE7,
  "alert-outline": F002A,
  "alert-plus": F14BA,
  "alert-plus-outline": F14BD,
  "alert-remove": F14BC,
  "alert-remove-outline": F14BF,
  "alert-rhombus": F11CE,
  "alert-rhombus-outline": F11CF,
  "alien": F089A,
  "alien-outline": F10CB,
  "align-horizontal-center": F11C3,
  "align-horizontal-left": F11C2,
  "align-horizontal-right": F11C4,
  "align-vertical-bottom": F11C5,
  "align-vertical-center": F11C6,
  "align-vertical-top": F11C7,
  "all-inclusive": F06BE,
  "allergy": F1258,
  "alpha": F002B,
  "alpha-a": F0AEE,
  "alpha-a-box": F0B08,
  "alpha-a-box-outline": F0BEB,
  "alpha-a-circle": F0BEC,
  "alpha-a-circle-outline": F0BED,
  "alpha-b": F0AEF,
  "alpha-b-box": F0B09,
  "alpha-b-box-outline": F0BEE,
  "alpha-b-circle": F0BEF,
  "alpha-b-circle-outline": F0BF0,
  "alpha-c": F0AF0,
  "alpha-c-box": F0B0A,
  "alpha-c-box-outline": F0BF1,
  "alpha-c-circle": F0BF2,
  "alpha-c-circle-outline": F0BF3,
  "alpha-d": F0AF1,
  "alpha-d-box": F0B0B,
  "alpha-d-box-outline": F0BF4,
  "alpha-d-circle": F0BF5,
  "alpha-d-circle-outline": F0BF6,
  "alpha-e": F0AF2,
  "alpha-e-box": F0B0C,
  "alpha-e-box-outline": F0BF7,
  "alpha-e-circle": F0BF8,
  "alpha-e-circle-outline": F0BF9,
  "alpha-f": F0AF3,
  "alpha-f-box": F0B0D,
  "alpha-f-box-outline": F0BFA,
  "alpha-f-circle": F0BFB,
  "alpha-f-circle-outline": F0BFC,
  "alpha-g": F0AF4,
  "alpha-g-box": F0B0E,
  "alpha-g-box-outline": F0BFD,
  "alpha-g-circle": F0BFE,
  "alpha-g-circle-outline": F0BFF,
  "alpha-h": F0AF5,
  "alpha-h-box": F0B0F,
  "alpha-h-box-outline": F0C00,
  "alpha-h-circle": F0C01,
  "alpha-h-circle-outline": F0C02,
  "alpha-i": F0AF6,
  "alpha-i-box": F0B10,
  "alpha-i-box-outline": F0C03,
  "alpha-i-circle": F0C04,
  "alpha-i-circle-outline": F0C05,
  "alpha-j": F0AF7,
  "alpha-j-box": F0B11,
  "alpha-j-box-outline": F0C06,
  "alpha-j-circle": F0C07,
  "alpha-j-circle-outline": F0C08,
  "alpha-k": F0AF8,
  "alpha-k-box": F0B12,
  "alpha-k-box-outline": F0C09,
  "alpha-k-circle": F0C0A,
  "alpha-k-circle-outline": F0C0B,
  "alpha-l": F0AF9,
  "alpha-l-box": F0B13,
  "alpha-l-box-outline": F0C0C,
  "alpha-l-circle": F0C0D,
  "alpha-l-circle-outline": F0C0E,
  "alpha-m": F0AFA,
  "alpha-m-box": F0B14,
  "alpha-m-box-outline": F0C0F,
  "alpha-m-circle": F0C10,
  "alpha-m-circle-outline": F0C11,
  "alpha-n": F0AFB,
  "alpha-n-box": F0B15,
  "alpha-n-box-outline": F0C12,
  "alpha-n-circle": F0C13,
  "alpha-n-circle-outline": F0C14,
  "alpha-o": F0AFC,
  "alpha-o-box": F0B16,
  "alpha-o-box-outline": F0C15,
  "alpha-o-circle": F0C16,
  "alpha-o-circle-outline": F0C17,
  "alpha-p": F0AFD,
  "alpha-p-box": F0B17,
  "alpha-p-box-outline": F0C18,
  "alpha-p-circle": F0C19,
  "alpha-p-circle-outline": F0C1A,
  "alpha-q": F0AFE,
  "alpha-q-box": F0B18,
  "alpha-q-box-outline": F0C1B,
  "alpha-q-circle": F0C1C,
  "alpha-q-circle-outline": F0C1D,
  "alpha-r": F0AFF,
  "alpha-r-box": F0B19,
  "alpha-r-box-outline": F0C1E,
  "alpha-r-circle": F0C1F,
  "alpha-r-circle-outline": F0C20,
  "alpha-s": F0B00,
  "alpha-s-box": F0B1A,
  "alpha-s-box-outline": F0C21,
  "alpha-s-circle": F0C22,
  "alpha-s-circle-outline": F0C23,
  "alpha-t": F0B01,
  "alpha-t-box": F0B1B,
  "alpha-t-box-outline": F0C24,
  "alpha-t-circle": F0C25,
  "alpha-t-circle-outline": F0C26,
  "alpha-u": F0B02,
  "alpha-u-box": F0B1C,
  "alpha-u-box-outline": F0C27,
  "alpha-u-circle": F0C28,
  "alpha-u-circle-outline": F0C29,
  "alpha-v": F0B03,
  "alpha-v-box": F0B1D,
  "alpha-v-box-outline": F0C2A,
  "alpha-v-circle": F0C2B,
  "alpha-v-circle-outline": F0C2C,
  "alpha-w": F0B04,
  "alpha-w-box": F0B1E,
  "alpha-w-box-outline": F0C2D,
  "alpha-w-circle": F0C2E,
  "alpha-w-circle-outline": F0C2F,
  "alpha-x": F0B05,
  "alpha-x-box": F0B1F,
  "alpha-x-box-outline": F0C30,
  "alpha-x-circle": F0C31,
  "alpha-x-circle-outline": F0C32,
  "alpha-y": F0B06,
  "alpha-y-box": F0B20,
  "alpha-y-box-outline": F0C33,
  "alpha-y-circle": F0C34,
  "alpha-y-circle-outline": F0C35,
  "alpha-z": F0B07,
  "alpha-z-box": F0B21,
  "alpha-z-box-outline": F0C36,
  "alpha-z-circle": F0C37,
  "alpha-z-circle-outline": F0C38,
  "alphabet-aurebesh": F132C,
  "alphabet-cyrillic": F132D,
  "alphabet-greek": F132E,
  "alphabet-latin": F132F,
  "alphabet-piqad": F1330,
  "alphabet-tengwar": F1337,
  "alphabetical": F002C,
  "alphabetical-off": F100C,
  "alphabetical-variant": F100D,
  "alphabetical-variant-off": F100E,
  "altimeter": F05D7,
  "amazon": F002D,
  "amazon-alexa": F08C6,
  "ambulance": F002F,
  "ammunition": F0CE8,
  "ampersand": F0A8D,
  "amplifier": F0030,
  "amplifier-off": F11B5,
  "anchor": F0031,
  "android": F0032,
  "android-auto": F0A8E,
  "android-debug-bridge": F0033,
  "android-messages": F0D45,
  "android-studio": F0034,
  "angle-acute": F0937,
  "angle-obtuse": F0938,
  "angle-right": F0939,
  "angular": F06B2,
  "angularjs": F06BF,
  "animation": F05D8,
  "animation-outline": F0A8F,
  "animation-play": F093A,
  "animation-play-outline": F0A90,
  "ansible": F109A,
  "antenna": F1119,
  "anvil": F089B,
  "apache-kafka": F100F,
  "api": F109B,
  "api-off": F1257,
  "apple": F0035,
  "apple-airplay": F001F,
  "apple-finder": F0036,
  "apple-icloud": F0038,
  "apple-ios": F0037,
  "apple-keyboard-caps": F0632,
  "apple-keyboard-command": F0633,
  "apple-keyboard-control": F0634,
  "apple-keyboard-option": F0635,
  "apple-keyboard-shift": F0636,
  "apple-safari": F0039,
  "application": F0614,
  "application-cog": F1577,
  "application-export": F0DAD,
  "application-import": F0DAE,
  "application-settings": F1555,
  "approximately-equal": F0F9E,
  "approximately-equal-box": F0F9F,
  "apps": F003B,
  "apps-box": F0D46,
  "arch": F08C7,
  "archive": F003C,
  "archive-alert": F14FD,
  "archive-alert-outline": F14FE,
  "archive-arrow-down": F1259,
  "archive-arrow-down-outline": F125A,
  "archive-arrow-up": F125B,
  "archive-arrow-up-outline": F125C,
  "archive-outline": F120E,
  "arm-flex": F0FD7,
  "arm-flex-outline": F0FD6,
  "arrange-bring-forward": F003D,
  "arrange-bring-to-front": F003E,
  "arrange-send-backward": F003F,
  "arrange-send-to-back": F0040,
  "arrow-all": F0041,
  "arrow-bottom-left": F0042,
  "arrow-bottom-left-bold-outline": F09B7,
  "arrow-bottom-left-thick": F09B8,
  "arrow-bottom-left-thin-circle-outline": F1596,
  "arrow-bottom-right": F0043,
  "arrow-bottom-right-bold-outline": F09B9,
  "arrow-bottom-right-thick": F09BA,
  "arrow-bottom-right-thin-circle-outline": F1595,
  "arrow-collapse": F0615,
  "arrow-collapse-all": F0044,
  "arrow-collapse-down": F0792,
  "arrow-collapse-horizontal": F084C,
  "arrow-collapse-left": F0793,
  "arrow-collapse-right": F0794,
  "arrow-collapse-up": F0795,
  "arrow-collapse-vertical": F084D,
  "arrow-decision": F09BB,
  "arrow-decision-auto": F09BC,
  "arrow-decision-auto-outline": F09BD,
  "arrow-decision-outline": F09BE,
  "arrow-down": F0045,
  "arrow-down-bold": F072E,
  "arrow-down-bold-box": F072F,
  "arrow-down-bold-box-outline": F0730,
  "arrow-down-bold-circle": F0047,
  "arrow-down-bold-circle-outline": F0048,
  "arrow-down-bold-hexagon-outline": F0049,
  "arrow-down-bold-outline": F09BF,
  "arrow-down-box": F06C0,
  "arrow-down-circle": F0CDB,
  "arrow-down-circle-outline": F0CDC,
  "arrow-down-drop-circle": F004A,
  "arrow-down-drop-circle-outline": F004B,
  "arrow-down-thick": F0046,
  "arrow-down-thin-circle-outline": F1599,
  "arrow-expand": F0616,
  "arrow-expand-all": F004C,
  "arrow-expand-down": F0796,
  "arrow-expand-horizontal": F084E,
  "arrow-expand-left": F0797,
  "arrow-expand-right": F0798,
  "arrow-expand-up": F0799,
  "arrow-expand-vertical": F084F,
  "arrow-horizontal-lock": F115B,
  "arrow-left": F004D,
  "arrow-left-bold": F0731,
  "arrow-left-bold-box": F0732,
  "arrow-left-bold-box-outline": F0733,
  "arrow-left-bold-circle": F004F,
  "arrow-left-bold-circle-outline": F0050,
  "arrow-left-bold-hexagon-outline": F0051,
  "arrow-left-bold-outline": F09C0,
  "arrow-left-box": F06C1,
  "arrow-left-circle": F0CDD,
  "arrow-left-circle-outline": F0CDE,
  "arrow-left-drop-circle": F0052,
  "arrow-left-drop-circle-outline": F0053,
  "arrow-left-right": F0E73,
  "arrow-left-right-bold": F0E74,
  "arrow-left-right-bold-outline": F09C1,
  "arrow-left-thick": F004E,
  "arrow-left-thin-circle-outline": F159A,
  "arrow-right": F0054,
  "arrow-right-bold": F0734,
  "arrow-right-bold-box": F0735,
  "arrow-right-bold-box-outline": F0736,
  "arrow-right-bold-circle": F0056,
  "arrow-right-bold-circle-outline": F0057,
  "arrow-right-bold-hexagon-outline": F0058,
  "arrow-right-bold-outline": F09C2,
  "arrow-right-box": F06C2,
  "arrow-right-circle": F0CDF,
  "arrow-right-circle-outline": F0CE0,
  "arrow-right-drop-circle": F0059,
  "arrow-right-drop-circle-outline": F005A,
  "arrow-right-thick": F0055,
  "arrow-right-thin-circle-outline": F1598,
  "arrow-split-horizontal": F093B,
  "arrow-split-vertical": F093C,
  "arrow-top-left": F005B,
  "arrow-top-left-bold-outline": F09C3,
  "arrow-top-left-bottom-right": F0E75,
  "arrow-top-left-bottom-right-bold": F0E76,
  "arrow-top-left-thick": F09C4,
  "arrow-top-left-thin-circle-outline": F1593,
  "arrow-top-right": F005C,
  "arrow-top-right-bold-outline": F09C5,
  "arrow-top-right-bottom-left": F0E77,
  "arrow-top-right-bottom-left-bold": F0E78,
  "arrow-top-right-thick": F09C6,
  "arrow-top-right-thin-circle-outline": F1594,
  "arrow-up": F005D,
  "arrow-up-bold": F0737,
  "arrow-up-bold-box": F0738,
  "arrow-up-bold-box-outline": F0739,
  "arrow-up-bold-circle": F005F,
  "arrow-up-bold-circle-outline": F0060,
  "arrow-up-bold-hexagon-outline": F0061,
  "arrow-up-bold-outline": F09C7,
  "arrow-up-box": F06C3,
  "arrow-up-circle": F0CE1,
  "arrow-up-circle-outline": F0CE2,
  "arrow-up-down": F0E79,
  "arrow-up-down-bold": F0E7A,
  "arrow-up-down-bold-outline": F09C8,
  "arrow-up-drop-circle": F0062,
  "arrow-up-drop-circle-outline": F0063,
  "arrow-up-thick": F005E,
  "arrow-up-thin-circle-outline": F1597,
  "arrow-vertical-lock": F115C,
  "artstation": F0B5B,
  "aspect-ratio": F0A24,
  "assistant": F0064,
  "asterisk": F06C4,
  "at": F0065,
  "atlassian": F0804,
  "atm": F0D47,
  "atom": F0768,
  "atom-variant": F0E7B,
  "attachment": F0066,
  "audio-video": F093D,
  "audio-video-off": F11B6,
  "augmented-reality": F0850,
  "auto-download": F137E,
  "auto-fix": F0068,
  "auto-upload": F0069,
  "autorenew": F006A,
  "av-timer": F006B,
  "aws": F0E0F,
  "axe": F08C8,
  "axis": F0D48,
  "axis-arrow": F0D49,
  "axis-arrow-info": F140E,
  "axis-arrow-lock": F0D4A,
  "axis-lock": F0D4B,
  "axis-x-arrow": F0D4C,
  "axis-x-arrow-lock": F0D4D,
  "axis-x-rotate-clockwise": F0D4E,
  "axis-x-rotate-counterclockwise": F0D4F,
  "axis-x-y-arrow-lock": F0D50,
  "axis-y-arrow": F0D51,
  "axis-y-arrow-lock": F0D52,
  "axis-y-rotate-clockwise": F0D53,
  "axis-y-rotate-counterclockwise": F0D54,
  "axis-z-arrow": F0D55,
  "axis-z-arrow-lock": F0D56,
  "axis-z-rotate-clockwise": F0D57,
  "axis-z-rotate-counterclockwise": F0D58,
  "babel": F0A25,
  "baby": F006C,
  "baby-bottle": F0F39,
  "baby-bottle-outline": F0F3A,
  "baby-buggy": F13E0,
  "baby-carriage": F068F,
  "baby-carriage-off": F0FA0,
  "baby-face": F0E7C,
  "baby-face-outline": F0E7D,
  "backburger": F006D,
  "backspace": F006E,
  "backspace-outline": F0B5C,
  "backspace-reverse": F0E7E,
  "backspace-reverse-outline": F0E7F,
  "backup-restore": F006F,
  "bacteria": F0ED5,
  "bacteria-outline": F0ED6,
  "badge-account": F0DA7,
  "badge-account-alert": F0DA8,
  "badge-account-alert-outline": F0DA9,
  "badge-account-horizontal": F0E0D,
  "badge-account-horizontal-outline": F0E0E,
  "badge-account-outline": F0DAA,
  "badminton": F0851,
  "bag-carry-on": F0F3B,
  "bag-carry-on-check": F0D65,
  "bag-carry-on-off": F0F3C,
  "bag-checked": F0F3D,
  "bag-personal": F0E10,
  "bag-personal-off": F0E11,
  "bag-personal-off-outline": F0E12,
  "bag-personal-outline": F0E13,
  "bag-suitcase": F158B,
  "bag-suitcase-off": F158D,
  "bag-suitcase-off-outline": F158E,
  "bag-suitcase-outline": F158C,
  "baguette": F0F3E,
  "balloon": F0A26,
  "ballot": F09C9,
  "ballot-outline": F09CA,
  "ballot-recount": F0C39,
  "ballot-recount-outline": F0C3A,
  "bandage": F0DAF,
  "bandcamp": F0675,
  "bank": F0070,
  "bank-check": F1655,
  "bank-minus": F0DB0,
  "bank-off": F1656,
  "bank-off-outline": F1657,
  "bank-outline": F0E80,
  "bank-plus": F0DB1,
  "bank-remove": F0DB2,
  "bank-transfer": F0A27,
  "bank-transfer-in": F0A28,
  "bank-transfer-out": F0A29,
  "barcode": F0071,
  "barcode-off": F1236,
  "barcode-scan": F0072,
  "barley": F0073,
  "barley-off": F0B5D,
  "barn": F0B5E,
  "barrel": F0074,
  "baseball": F0852,
  "baseball-bat": F0853,
  "baseball-diamond": F15EC,
  "baseball-diamond-outline": F15ED,
  "bash": F1183,
  "basket": F0076,
  "basket-fill": F0077,
  "basket-minus": F1523,
  "basket-minus-outline": F1524,
  "basket-off": F1525,
  "basket-off-outline": F1526,
  "basket-outline": F1181,
  "basket-plus": F1527,
  "basket-plus-outline": F1528,
  "basket-remove": F1529,
  "basket-remove-outline": F152A,
  "basket-unfill": F0078,
  "basketball": F0806,
  "basketball-hoop": F0C3B,
  "basketball-hoop-outline": F0C3C,
  "bat": F0B5F,
  "battery": F0079,
  "battery-10": F007A,
  "battery-10-bluetooth": F093E,
  "battery-20": F007B,
  "battery-20-bluetooth": F093F,
  "battery-30": F007C,
  "battery-30-bluetooth": F0940,
  "battery-40": F007D,
  "battery-40-bluetooth": F0941,
  "battery-50": F007E,
  "battery-50-bluetooth": F0942,
  "battery-60": F007F,
  "battery-60-bluetooth": F0943,
  "battery-70": F0080,
  "battery-70-bluetooth": F0944,
  "battery-80": F0081,
  "battery-80-bluetooth": F0945,
  "battery-90": F0082,
  "battery-90-bluetooth": F0946,
  "battery-alert": F0083,
  "battery-alert-bluetooth": F0947,
  "battery-alert-variant": F10CC,
  "battery-alert-variant-outline": F10CD,
  "battery-bluetooth": F0948,
  "battery-bluetooth-variant": F0949,
  "battery-charging": F0084,
  "battery-charging-10": F089C,
  "battery-charging-100": F0085,
  "battery-charging-20": F0086,
  "battery-charging-30": F0087,
  "battery-charging-40": F0088,
  "battery-charging-50": F089D,
  "battery-charging-60": F0089,
  "battery-charging-70": F089E,
  "battery-charging-80": F008A,
  "battery-charging-90": F008B,
  "battery-charging-high": F12A6,
  "battery-charging-low": F12A4,
  "battery-charging-medium": F12A5,
  "battery-charging-outline": F089F,
  "battery-charging-wireless": F0807,
  "battery-charging-wireless-10": F0808,
  "battery-charging-wireless-20": F0809,
  "battery-charging-wireless-30": F080A,
  "battery-charging-wireless-40": F080B,
  "battery-charging-wireless-50": F080C,
  "battery-charging-wireless-60": F080D,
  "battery-charging-wireless-70": F080E,
  "battery-charging-wireless-80": F080F,
  "battery-charging-wireless-90": F0810,
  "battery-charging-wireless-alert": F0811,
  "battery-charging-wireless-outline": F0812,
  "battery-heart": F120F,
  "battery-heart-outline": F1210,
  "battery-heart-variant": F1211,
  "battery-high": F12A3,
  "battery-low": F12A1,
  "battery-medium": F12A2,
  "battery-minus": F008C,
  "battery-negative": F008D,
  "battery-off": F125D,
  "battery-off-outline": F125E,
  "battery-outline": F008E,
  "battery-plus": F008F,
  "battery-positive": F0090,
  "battery-unknown": F0091,
  "battery-unknown-bluetooth": F094A,
  "battlenet": F0B60,
  "beach": F0092,
  "beaker": F0CEA,
  "beaker-alert": F1229,
  "beaker-alert-outline": F122A,
  "beaker-check": F122B,
  "beaker-check-outline": F122C,
  "beaker-minus": F122D,
  "beaker-minus-outline": F122E,
  "beaker-outline": F0690,
  "beaker-plus": F122F,
  "beaker-plus-outline": F1230,
  "beaker-question": F1231,
  "beaker-question-outline": F1232,
  "beaker-remove": F1233,
  "beaker-remove-outline": F1234,
  "bed": F02E3,
  "bed-double": F0FD4,
  "bed-double-outline": F0FD3,
  "bed-empty": F08A0,
  "bed-king": F0FD2,
  "bed-king-outline": F0FD1,
  "bed-outline": F0099,
  "bed-queen": F0FD0,
  "bed-queen-outline": F0FDB,
  "bed-single": F106D,
  "bed-single-outline": F106E,
  "bee": F0FA1,
  "bee-flower": F0FA2,
  "beehive-off-outline": F13ED,
  "beehive-outline": F10CE,
  "beekeeper": F14E2,
  "beer": F0098,
  "beer-outline": F130C,
  "bell": F009A,
  "bell-alert": F0D59,
  "bell-alert-outline": F0E81,
  "bell-cancel": F13E7,
  "bell-cancel-outline": F13E8,
  "bell-check": F11E5,
  "bell-check-outline": F11E6,
  "bell-circle": F0D5A,
  "bell-circle-outline": F0D5B,
  "bell-minus": F13E9,
  "bell-minus-outline": F13EA,
  "bell-off": F009B,
  "bell-off-outline": F0A91,
  "bell-outline": F009C,
  "bell-plus": F009D,
  "bell-plus-outline": F0A92,
  "bell-remove": F13EB,
  "bell-remove-outline": F13EC,
  "bell-ring": F009E,
  "bell-ring-outline": F009F,
  "bell-sleep": F00A0,
  "bell-sleep-outline": F0A93,
  "beta": F00A1,
  "betamax": F09CB,
  "biathlon": F0E14,
  "bicycle": F109C,
  "bicycle-basket": F1235,
  "bicycle-electric": F15B4,
  "bicycle-penny-farthing": F15E9,
  "bike": F00A3,
  "bike-fast": F111F,
  "billboard": F1010,
  "billiards": F0B61,
  "billiards-rack": F0B62,
  "binoculars": F00A5,
  "bio": F00A6,
  "biohazard": F00A7,
  "bird": F15C6,
  "bitbucket": F00A8,
  "bitcoin": F0813,
  "black-mesa": F00A9,
  "blender": F0CEB,
  "blender-software": F00AB,
  "blinds": F00AC,
  "blinds-open": F1011,
  "block-helper": F00AD,
  "blogger": F00AE,
  "blood-bag": F0CEC,
  "bluetooth": F00AF,
  "bluetooth-audio": F00B0,
  "bluetooth-connect": F00B1,
  "bluetooth-off": F00B2,
  "bluetooth-settings": F00B3,
  "bluetooth-transfer": F00B4,
  "blur": F00B5,
  "blur-linear": F00B6,
  "blur-off": F00B7,
  "blur-radial": F00B8,
  "bolnisi-cross": F0CED,
  "bolt": F0DB3,
  "bomb": F0691,
  "bomb-off": F06C5,
  "bone": F00B9,
  "book": F00BA,
  "book-account": F13AD,
  "book-account-outline": F13AE,
  "book-alert": F167C,
  "book-alert-outline": F167D,
  "book-alphabet": F061D,
  "book-arrow-down": F167E,
  "book-arrow-down-outline": F167F,
  "book-arrow-left": F1680,
  "book-arrow-left-outline": F1681,
  "book-arrow-right": F1682,
  "book-arrow-right-outline": F1683,
  "book-arrow-up": F1684,
  "book-arrow-up-outline": F1685,
  "book-cancel": F1686,
  "book-cancel-outline": F1687,
  "book-check": F14F3,
  "book-check-outline": F14F4,
  "book-clock": F1688,
  "book-clock-outline": F1689,
  "book-cog": F168A,
  "book-cog-outline": F168B,
  "book-cross": F00A2,
  "book-edit": F168C,
  "book-edit-outline": F168D,
  "book-education": F16C9,
  "book-education-outline": F16CA,
  "book-information-variant": F106F,
  "book-lock": F079A,
  "book-lock-open": F079B,
  "book-lock-open-outline": F168E,
  "book-lock-outline": F168F,
  "book-marker": F1690,
  "book-marker-outline": F1691,
  "book-minus": F05D9,
  "book-minus-multiple": F0A94,
  "book-minus-multiple-outline": F090B,
  "book-minus-outline": F1692,
  "book-multiple": F00BB,
  "book-multiple-outline": F0436,
  "book-music": F0067,
  "book-music-outline": F1693,
  "book-off": F1694,
  "book-off-outline": F1695,
  "book-open": F00BD,
  "book-open-blank-variant": F00BE,
  "book-open-outline": F0B63,
  "book-open-page-variant": F05DA,
  "book-open-page-variant-outline": F15D6,
  "book-open-variant": F14F7,
  "book-outline": F0B64,
  "book-play": F0E82,
  "book-play-outline": F0E83,
  "book-plus": F05DB,
  "book-plus-multiple": F0A95,
  "book-plus-multiple-outline": F0ADE,
  "book-plus-outline": F1696,
  "book-refresh": F1697,
  "book-refresh-outline": F1698,
  "book-remove": F0A97,
  "book-remove-multiple": F0A96,
  "book-remove-multiple-outline": F04CA,
  "book-remove-outline": F1699,
  "book-search": F0E84,
  "book-search-outline": F0E85,
  "book-settings": F169A,
  "book-settings-outline": F169B,
  "book-sync": F169C,
  "book-sync-outline": F16C8,
  "book-variant": F00BF,
  "book-variant-multiple": F00BC,
  "bookmark": F00C0,
  "bookmark-check": F00C1,
  "bookmark-check-outline": F137B,
  "bookmark-minus": F09CC,
  "bookmark-minus-outline": F09CD,
  "bookmark-multiple": F0E15,
  "bookmark-multiple-outline": F0E16,
  "bookmark-music": F00C2,
  "bookmark-music-outline": F1379,
  "bookmark-off": F09CE,
  "bookmark-off-outline": F09CF,
  "bookmark-outline": F00C3,
  "bookmark-plus": F00C5,
  "bookmark-plus-outline": F00C4,
  "bookmark-remove": F00C6,
  "bookmark-remove-outline": F137A,
  "bookshelf": F125F,
  "boom-gate": F0E86,
  "boom-gate-alert": F0E87,
  "boom-gate-alert-outline": F0E88,
  "boom-gate-down": F0E89,
  "boom-gate-down-outline": F0E8A,
  "boom-gate-outline": F0E8B,
  "boom-gate-up": F0E8C,
  "boom-gate-up-outline": F0E8D,
  "boombox": F05DC,
  "boomerang": F10CF,
  "bootstrap": F06C6,
  "border-all": F00C7,
  "border-all-variant": F08A1,
  "border-bottom": F00C8,
  "border-bottom-variant": F08A2,
  "border-color": F00C9,
  "border-horizontal": F00CA,
  "border-inside": F00CB,
  "border-left": F00CC,
  "border-left-variant": F08A3,
  "border-none": F00CD,
  "border-none-variant": F08A4,
  "border-outside": F00CE,
  "border-right": F00CF,
  "border-right-variant": F08A5,
  "border-style": F00D0,
  "border-top": F00D1,
  "border-top-variant": F08A6,
  "border-vertical": F00D2,
  "bottle-soda": F1070,
  "bottle-soda-classic": F1071,
  "bottle-soda-classic-outline": F1363,
  "bottle-soda-outline": F1072,
  "bottle-tonic": F112E,
  "bottle-tonic-outline": F112F,
  "bottle-tonic-plus": F1130,
  "bottle-tonic-plus-outline": F1131,
  "bottle-tonic-skull": F1132,
  "bottle-tonic-skull-outline": F1133,
  "bottle-wine": F0854,
  "bottle-wine-outline": F1310,
  "bow-tie": F0678,
  "bowl": F028E,
  "bowl-mix": F0617,
  "bowl-mix-outline": F02E4,
  "bowl-outline": F02A9,
  "bowling": F00D3,
  "box": F00D4,
  "box-cutter": F00D5,
  "box-cutter-off": F0B4A,
  "box-shadow": F0637,
  "boxing-glove": F0B65,
  "braille": F09D0,
  "brain": F09D1,
  "bread-slice": F0CEE,
  "bread-slice-outline": F0CEF,
  "bridge": F0618,
  "briefcase": F00D6,
  "briefcase-account": F0CF0,
  "briefcase-account-outline": F0CF1,
  "briefcase-check": F00D7,
  "briefcase-check-outline": F131E,
  "briefcase-clock": F10D0,
  "briefcase-clock-outline": F10D1,
  "briefcase-download": F00D8,
  "briefcase-download-outline": F0C3D,
  "briefcase-edit": F0A98,
  "briefcase-edit-outline": F0C3E,
  "briefcase-minus": F0A2A,
  "briefcase-minus-outline": F0C3F,
  "briefcase-off": F1658,
  "briefcase-off-outline": F1659,
  "briefcase-outline": F0814,
  "briefcase-plus": F0A2B,
  "briefcase-plus-outline": F0C40,
  "briefcase-remove": F0A2C,
  "briefcase-remove-outline": F0C41,
  "briefcase-search": F0A2D,
  "briefcase-search-outline": F0C42,
  "briefcase-upload": F00D9,
  "briefcase-upload-outline": F0C43,
  "briefcase-variant": F1494,
  "briefcase-variant-off": F165A,
  "briefcase-variant-off-outline": F165B,
  "briefcase-variant-outline": F1495,
  "brightness-1": F00DA,
  "brightness-2": F00DB,
  "brightness-3": F00DC,
  "brightness-4": F00DD,
  "brightness-5": F00DE,
  "brightness-6": F00DF,
  "brightness-7": F00E0,
  "brightness-auto": F00E1,
  "brightness-percent": F0CF2,
  "broadcast": F1720,
  "broadcast-off": F1721,
  "broom": F00E2,
  "brush": F00E3,
  "bucket": F1415,
  "bucket-outline": F1416,
  "buddhism": F094B,
  "buffer": F0619,
  "buffet": F0578,
  "bug": F00E4,
  "bug-check": F0A2E,
  "bug-check-outline": F0A2F,
  "bug-outline": F0A30,
  "bugle": F0DB4,
  "bulldozer": F0B22,
  "bullet": F0CF3,
  "bulletin-board": F00E5,
  "bullhorn": F00E6,
  "bullhorn-outline": F0B23,
  "bullseye": F05DD,
  "bullseye-arrow": F08C9,
  "bulma": F12E7,
  "bunk-bed": F1302,
  "bunk-bed-outline": F0097,
  "bus": F00E7,
  "bus-alert": F0A99,
  "bus-articulated-end": F079C,
  "bus-articulated-front": F079D,
  "bus-clock": F08CA,
  "bus-double-decker": F079E,
  "bus-marker": F1212,
  "bus-multiple": F0F3F,
  "bus-school": F079F,
  "bus-side": F07A0,
  "bus-stop": F1012,
  "bus-stop-covered": F1013,
  "bus-stop-uncovered": F1014,
  "butterfly": F1589,
  "butterfly-outline": F158A,
  "cable-data": F1394,
  "cached": F00E8,
  "cactus": F0DB5,
  "cake": F00E9,
  "cake-layered": F00EA,
  "cake-variant": F00EB,
  "calculator": F00EC,
  "calculator-variant": F0A9A,
  "calculator-variant-outline": F15A6,
  "calendar": F00ED,
  "calendar-account": F0ED7,
  "calendar-account-outline": F0ED8,
  "calendar-alert": F0A31,
  "calendar-arrow-left": F1134,
  "calendar-arrow-right": F1135,
  "calendar-blank": F00EE,
  "calendar-blank-multiple": F1073,
  "calendar-blank-outline": F0B66,
  "calendar-check": F00EF,
  "calendar-check-outline": F0C44,
  "calendar-clock": F00F0,
  "calendar-clock-outline": F16E1,
  "calendar-cursor": F157B,
  "calendar-edit": F08A7,
  "calendar-end": F166C,
  "calendar-export": F0B24,
  "calendar-heart": F09D2,
  "calendar-import": F0B25,
  "calendar-lock": F1641,
  "calendar-lock-outline": F1642,
  "calendar-minus": F0D5C,
  "calendar-month": F0E17,
  "calendar-month-outline": F0E18,
  "calendar-multiple": F00F1,
  "calendar-multiple-check": F00F2,
  "calendar-multiselect": F0A32,
  "calendar-outline": F0B67,
  "calendar-plus": F00F3,
  "calendar-question": F0692,
  "calendar-range": F0679,
  "calendar-range-outline": F0B68,
  "calendar-refresh": F01E1,
  "calendar-refresh-outline": F0203,
  "calendar-remove": F00F4,
  "calendar-remove-outline": F0C45,
  "calendar-search": F094C,
  "calendar-star": F09D3,
  "calendar-start": F166D,
  "calendar-sync": F0E8E,
  "calendar-sync-outline": F0E8F,
  "calendar-text": F00F5,
  "calendar-text-outline": F0C46,
  "calendar-today": F00F6,
  "calendar-week": F0A33,
  "calendar-week-begin": F0A34,
  "calendar-weekend": F0ED9,
  "calendar-weekend-outline": F0EDA,
  "call-made": F00F7,
  "call-merge": F00F8,
  "call-missed": F00F9,
  "call-received": F00FA,
  "call-split": F00FB,
  "camcorder": F00FC,
  "camcorder-off": F00FF,
  "camera": F0100,
  "camera-account": F08CB,
  "camera-burst": F0693,
  "camera-control": F0B69,
  "camera-enhance": F0101,
  "camera-enhance-outline": F0B6A,
  "camera-flip": F15D9,
  "camera-flip-outline": F15DA,
  "camera-front": F0102,
  "camera-front-variant": F0103,
  "camera-gopro": F07A1,
  "camera-image": F08CC,
  "camera-iris": F0104,
  "camera-metering-center": F07A2,
  "camera-metering-matrix": F07A3,
  "camera-metering-partial": F07A4,
  "camera-metering-spot": F07A5,
  "camera-off": F05DF,
  "camera-outline": F0D5D,
  "camera-party-mode": F0105,
  "camera-plus": F0EDB,
  "camera-plus-outline": F0EDC,
  "camera-rear": F0106,
  "camera-rear-variant": F0107,
  "camera-retake": F0E19,
  "camera-retake-outline": F0E1A,
  "camera-switch": F0108,
  "camera-switch-outline": F084A,
  "camera-timer": F0109,
  "camera-wireless": F0DB6,
  "camera-wireless-outline": F0DB7,
  "campfire": F0EDD,
  "cancel": F073A,
  "candle": F05E2,
  "candycane": F010A,
  "cannabis": F07A6,
  "cannabis-off": F166E,
  "caps-lock": F0A9B,
  "car": F010B,
  "car-2-plus": F1015,
  "car-3-plus": F1016,
  "car-arrow-left": F13B2,
  "car-arrow-right": F13B3,
  "car-back": F0E1B,
  "car-battery": F010C,
  "car-brake-abs": F0C47,
  "car-brake-alert": F0C48,
  "car-brake-hold": F0D5E,
  "car-brake-parking": F0D5F,
  "car-brake-retarder": F1017,
  "car-child-seat": F0FA3,
  "car-clutch": F1018,
  "car-cog": F13CC,
  "car-connected": F010D,
  "car-convertible": F07A7,
  "car-coolant-level": F1019,
  "car-cruise-control": F0D60,
  "car-defrost-front": F0D61,
  "car-defrost-rear": F0D62,
  "car-door": F0B6B,
  "car-door-lock": F109D,
  "car-electric": F0B6C,
  "car-electric-outline": F15B5,
  "car-emergency": F160F,
  "car-esp": F0C49,
  "car-estate": F07A8,
  "car-hatchback": F07A9,
  "car-info": F11BE,
  "car-key": F0B6D,
  "car-lifted-pickup": F152D,
  "car-light-dimmed": F0C4A,
  "car-light-fog": F0C4B,
  "car-light-high": F0C4C,
  "car-limousine": F08CD,
  "car-multiple": F0B6E,
  "car-off": F0E1C,
  "car-outline": F14ED,
  "car-parking-lights": F0D63,
  "car-pickup": F07AA,
  "car-seat": F0FA4,
  "car-seat-cooler": F0FA5,
  "car-seat-heater": F0FA6,
  "car-settings": F13CD,
  "car-shift-pattern": F0F40,
  "car-side": F07AB,
  "car-sports": F07AC,
  "car-tire-alert": F0C4D,
  "car-traction-control": F0D64,
  "car-turbocharger": F101A,
  "car-wash": F010E,
  "car-windshield": F101B,
  "car-windshield-outline": F101C,
  "carabiner": F14C0,
  "caravan": F07AD,
  "card": F0B6F,
  "card-account-details": F05D2,
  "card-account-details-outline": F0DAB,
  "card-account-details-star": F02A3,
  "card-account-details-star-outline": F06DB,
  "card-account-mail": F018E,
  "card-account-mail-outline": F0E98,
  "card-account-phone": F0E99,
  "card-account-phone-outline": F0E9A,
  "card-bulleted": F0B70,
  "card-bulleted-off": F0B71,
  "card-bulleted-off-outline": F0B72,
  "card-bulleted-outline": F0B73,
  "card-bulleted-settings": F0B74,
  "card-bulleted-settings-outline": F0B75,
  "card-minus": F1600,
  "card-minus-outline": F1601,
  "card-off": F1602,
  "card-off-outline": F1603,
  "card-outline": F0B76,
  "card-plus": F11FF,
  "card-plus-outline": F1200,
  "card-remove": F1604,
  "card-remove-outline": F1605,
  "card-search": F1074,
  "card-search-outline": F1075,
  "card-text": F0B77,
  "card-text-outline": F0B78,
  "cards": F0638,
  "cards-club": F08CE,
  "cards-diamond": F08CF,
  "cards-diamond-outline": F101D,
  "cards-heart": F08D0,
  "cards-outline": F0639,
  "cards-playing-outline": F063A,
  "cards-spade": F08D1,
  "cards-variant": F06C7,
  "carrot": F010F,
  "cart": F0110,
  "cart-arrow-down": F0D66,
  "cart-arrow-right": F0C4E,
  "cart-arrow-up": F0D67,
  "cart-check": F15EA,
  "cart-minus": F0D68,
  "cart-off": F066B,
  "cart-outline": F0111,
  "cart-plus": F0112,
  "cart-remove": F0D69,
  "cart-variant": F15EB,
  "case-sensitive-alt": F0113,
  "cash": F0114,
  "cash-100": F0115,
  "cash-check": F14EE,
  "cash-lock": F14EA,
  "cash-lock-open": F14EB,
  "cash-marker": F0DB8,
  "cash-minus": F1260,
  "cash-multiple": F0116,
  "cash-plus": F1261,
  "cash-refund": F0A9C,
  "cash-register": F0CF4,
  "cash-remove": F1262,
  "cash-usd": F1176,
  "cash-usd-outline": F0117,
  "cassette": F09D4,
  "cast": F0118,
  "cast-audio": F101E,
  "cast-connected": F0119,
  "cast-education": F0E1D,
  "cast-off": F078A,
  "castle": F011A,
  "cat": F011B,
  "cctv": F07AE,
  "ceiling-light": F0769,
  "cellphone": F011C,
  "cellphone-android": F011D,
  "cellphone-arrow-down": F09D5,
  "cellphone-basic": F011E,
  "cellphone-charging": F1397,
  "cellphone-cog": F0951,
  "cellphone-dock": F011F,
  "cellphone-erase": F094D,
  "cellphone-information": F0F41,
  "cellphone-iphone": F0120,
  "cellphone-key": F094E,
  "cellphone-link": F0121,
  "cellphone-link-off": F0122,
  "cellphone-lock": F094F,
  "cellphone-message": F08D3,
  "cellphone-message-off": F10D2,
  "cellphone-nfc": F0E90,
  "cellphone-nfc-off": F12D8,
  "cellphone-off": F0950,
  "cellphone-play": F101F,
  "cellphone-screenshot": F0A35,
  "cellphone-settings": F0123,
  "cellphone-sound": F0952,
  "cellphone-text": F08D2,
  "cellphone-wireless": F0815,
  "celtic-cross": F0CF5,
  "centos": F111A,
  "certificate": F0124,
  "certificate-outline": F1188,
  "chair-rolling": F0F48,
  "chair-school": F0125,
  "charity": F0C4F,
  "chart-arc": F0126,
  "chart-areaspline": F0127,
  "chart-areaspline-variant": F0E91,
  "chart-bar": F0128,
  "chart-bar-stacked": F076A,
  "chart-bell-curve": F0C50,
  "chart-bell-curve-cumulative": F0FA7,
  "chart-box": F154D,
  "chart-box-outline": F154E,
  "chart-box-plus-outline": F154F,
  "chart-bubble": F05E3,
  "chart-donut": F07AF,
  "chart-donut-variant": F07B0,
  "chart-gantt": F066C,
  "chart-histogram": F0129,
  "chart-line": F012A,
  "chart-line-stacked": F076B,
  "chart-line-variant": F07B1,
  "chart-multiline": F08D4,
  "chart-multiple": F1213,
  "chart-pie": F012B,
  "chart-ppf": F1380,
  "chart-sankey": F11DF,
  "chart-sankey-variant": F11E0,
  "chart-scatter-plot": F0E92,
  "chart-scatter-plot-hexbin": F066D,
  "chart-timeline": F066E,
  "chart-timeline-variant": F0E93,
  "chart-timeline-variant-shimmer": F15B6,
  "chart-tree": F0E94,
  "chat": F0B79,
  "chat-alert": F0B7A,
  "chat-alert-outline": F12C9,
  "chat-minus": F1410,
  "chat-minus-outline": F1413,
  "chat-outline": F0EDE,
  "chat-plus": F140F,
  "chat-plus-outline": F1412,
  "chat-processing": F0B7B,
  "chat-processing-outline": F12CA,
  "chat-question": F1738,
  "chat-question-outline": F1739,
  "chat-remove": F1411,
  "chat-remove-outline": F1414,
  "chat-sleep": F12D1,
  "chat-sleep-outline": F12D2,
  "check": F012C,
  "check-all": F012D,
  "check-bold": F0E1E,
  "check-box-multiple-outline": F0C51,
  "check-box-outline": F0C52,
  "check-circle": F05E0,
  "check-circle-outline": F05E1,
  "check-decagram": F0791,
  "check-decagram-outline": F1740,
  "check-network": F0C53,
  "check-network-outline": F0C54,
  "check-outline": F0855,
  "check-underline": F0E1F,
  "check-underline-circle": F0E20,
  "check-underline-circle-outline": F0E21,
  "checkbook": F0A9D,
  "checkbox-blank": F012E,
  "checkbox-blank-circle": F012F,
  "checkbox-blank-circle-outline": F0130,
  "checkbox-blank-off": F12EC,
  "checkbox-blank-off-outline": F12ED,
  "checkbox-blank-outline": F0131,
  "checkbox-intermediate": F0856,
  "checkbox-marked": F0132,
  "checkbox-marked-circle": F0133,
  "checkbox-marked-circle-outline": F0134,
  "checkbox-marked-outline": F0135,
  "checkbox-multiple-blank": F0136,
  "checkbox-multiple-blank-circle": F063B,
  "checkbox-multiple-blank-circle-outline": F063C,
  "checkbox-multiple-blank-outline": F0137,
  "checkbox-multiple-marked": F0138,
  "checkbox-multiple-marked-circle": F063D,
  "checkbox-multiple-marked-circle-outline": F063E,
  "checkbox-multiple-marked-outline": F0139,
  "checkerboard": F013A,
  "checkerboard-minus": F1202,
  "checkerboard-plus": F1201,
  "checkerboard-remove": F1203,
  "cheese": F12B9,
  "cheese-off": F13EE,
  "chef-hat": F0B7C,
  "chemical-weapon": F013B,
  "chess-bishop": F085C,
  "chess-king": F0857,
  "chess-knight": F0858,
  "chess-pawn": F0859,
  "chess-queen": F085A,
  "chess-rook": F085B,
  "chevron-double-down": F013C,
  "chevron-double-left": F013D,
  "chevron-double-right": F013E,
  "chevron-double-up": F013F,
  "chevron-down": F0140,
  "chevron-down-box": F09D6,
  "chevron-down-box-outline": F09D7,
  "chevron-down-circle": F0B26,
  "chevron-down-circle-outline": F0B27,
  "chevron-left": F0141,
  "chevron-left-box": F09D8,
  "chevron-left-box-outline": F09D9,
  "chevron-left-circle": F0B28,
  "chevron-left-circle-outline": F0B29,
  "chevron-right": F0142,
  "chevron-right-box": F09DA,
  "chevron-right-box-outline": F09DB,
  "chevron-right-circle": F0B2A,
  "chevron-right-circle-outline": F0B2B,
  "chevron-triple-down": F0DB9,
  "chevron-triple-left": F0DBA,
  "chevron-triple-right": F0DBB,
  "chevron-triple-up": F0DBC,
  "chevron-up": F0143,
  "chevron-up-box": F09DC,
  "chevron-up-box-outline": F09DD,
  "chevron-up-circle": F0B2C,
  "chevron-up-circle-outline": F0B2D,
  "chili-hot": F07B2,
  "chili-medium": F07B3,
  "chili-mild": F07B4,
  "chili-off": F1467,
  "chip": F061A,
  "christianity": F0953,
  "christianity-outline": F0CF6,
  "church": F0144,
  "cigar": F1189,
  "cigar-off": F141B,
  "circle": F0765,
  "circle-box": F15DC,
  "circle-box-outline": F15DD,
  "circle-double": F0E95,
  "circle-edit-outline": F08D5,
  "circle-expand": F0E96,
  "circle-half": F1395,
  "circle-half-full": F1396,
  "circle-medium": F09DE,
  "circle-multiple": F0B38,
  "circle-multiple-outline": F0695,
  "circle-off-outline": F10D3,
  "circle-outline": F0766,
  "circle-slice-1": F0A9E,
  "circle-slice-2": F0A9F,
  "circle-slice-3": F0AA0,
  "circle-slice-4": F0AA1,
  "circle-slice-5": F0AA2,
  "circle-slice-6": F0AA3,
  "circle-slice-7": F0AA4,
  "circle-slice-8": F0AA5,
  "circle-small": F09DF,
  "circular-saw": F0E22,
  "city": F0146,
  "city-variant": F0A36,
  "city-variant-outline": F0A37,
  "clipboard": F0147,
  "clipboard-account": F0148,
  "clipboard-account-outline": F0C55,
  "clipboard-alert": F0149,
  "clipboard-alert-outline": F0CF7,
  "clipboard-arrow-down": F014A,
  "clipboard-arrow-down-outline": F0C56,
  "clipboard-arrow-left": F014B,
  "clipboard-arrow-left-outline": F0CF8,
  "clipboard-arrow-right": F0CF9,
  "clipboard-arrow-right-outline": F0CFA,
  "clipboard-arrow-up": F0C57,
  "clipboard-arrow-up-outline": F0C58,
  "clipboard-check": F014E,
  "clipboard-check-multiple": F1263,
  "clipboard-check-multiple-outline": F1264,
  "clipboard-check-outline": F08A8,
  "clipboard-clock": F16E2,
  "clipboard-clock-outline": F16E3,
  "clipboard-edit": F14E5,
  "clipboard-edit-outline": F14E6,
  "clipboard-file": F1265,
  "clipboard-file-outline": F1266,
  "clipboard-flow": F06C8,
  "clipboard-flow-outline": F1117,
  "clipboard-list": F10D4,
  "clipboard-list-outline": F10D5,
  "clipboard-minus": F1618,
  "clipboard-minus-outline": F1619,
  "clipboard-multiple": F1267,
  "clipboard-multiple-outline": F1268,
  "clipboard-off": F161A,
  "clipboard-off-outline": F161B,
  "clipboard-outline": F014C,
  "clipboard-play": F0C59,
  "clipboard-play-multiple": F1269,
  "clipboard-play-multiple-outline": F126A,
  "clipboard-play-outline": F0C5A,
  "clipboard-plus": F0751,
  "clipboard-plus-outline": F131F,
  "clipboard-pulse": F085D,
  "clipboard-pulse-outline": F085E,
  "clipboard-remove": F161C,
  "clipboard-remove-outline": F161D,
  "clipboard-search": F161E,
  "clipboard-search-outline": F161F,
  "clipboard-text": F014D,
  "clipboard-text-multiple": F126B,
  "clipboard-text-multiple-outline": F126C,
  "clipboard-text-off": F1620,
  "clipboard-text-off-outline": F1621,
  "clipboard-text-outline": F0A38,
  "clipboard-text-play": F0C5B,
  "clipboard-text-play-outline": F0C5C,
  "clipboard-text-search": F1622,
  "clipboard-text-search-outline": F1623,
  "clippy": F014F,
  "clock": F0954,
  "clock-alert": F0955,
  "clock-alert-outline": F05CE,
  "clock-check": F0FA8,
  "clock-check-outline": F0FA9,
  "clock-digital": F0E97,
  "clock-end": F0151,
  "clock-fast": F0152,
  "clock-in": F0153,
  "clock-out": F0154,
  "clock-outline": F0150,
  "clock-start": F0155,
  "clock-time-eight": F1446,
  "clock-time-eight-outline": F1452,
  "clock-time-eleven": F1449,
  "clock-time-eleven-outline": F1455,
  "clock-time-five": F1443,
  "clock-time-five-outline": F144F,
  "clock-time-four": F1442,
  "clock-time-four-outline": F144E,
  "clock-time-nine": F1447,
  "clock-time-nine-outline": F1453,
  "clock-time-one": F143F,
  "clock-time-one-outline": F144B,
  "clock-time-seven": F1445,
  "clock-time-seven-outline": F1451,
  "clock-time-six": F1444,
  "clock-time-six-outline": F1450,
  "clock-time-ten": F1448,
  "clock-time-ten-outline": F1454,
  "clock-time-three": F1441,
  "clock-time-three-outline": F144D,
  "clock-time-twelve": F144A,
  "clock-time-twelve-outline": F1456,
  "clock-time-two": F1440,
  "clock-time-two-outline": F144C,
  "close": F0156,
  "close-box": F0157,
  "close-box-multiple": F0C5D,
  "close-box-multiple-outline": F0C5E,
  "close-box-outline": F0158,
  "close-circle": F0159,
  "close-circle-multiple": F062A,
  "close-circle-multiple-outline": F0883,
  "close-circle-outline": F015A,
  "close-network": F015B,
  "close-network-outline": F0C5F,
  "close-octagon": F015C,
  "close-octagon-outline": F015D,
  "close-outline": F06C9,
  "close-thick": F1398,
  "closed-caption": F015E,
  "closed-caption-outline": F0DBD,
  "cloud": F015F,
  "cloud-alert": F09E0,
  "cloud-braces": F07B5,
  "cloud-check": F0160,
  "cloud-check-outline": F12CC,
  "cloud-circle": F0161,
  "cloud-download": F0162,
  "cloud-download-outline": F0B7D,
  "cloud-lock": F11F1,
  "cloud-lock-outline": F11F2,
  "cloud-off-outline": F0164,
  "cloud-outline": F0163,
  "cloud-print": F0165,
  "cloud-print-outline": F0166,
  "cloud-question": F0A39,
  "cloud-refresh": F052A,
  "cloud-search": F0956,
  "cloud-search-outline": F0957,
  "cloud-sync": F063F,
  "cloud-sync-outline": F12D6,
  "cloud-tags": F07B6,
  "cloud-upload": F0167,
  "cloud-upload-outline": F0B7E,
  "clover": F0816,
  "coach-lamp": F1020,
  "coat-rack": F109E,
  "code-array": F0168,
  "code-braces": F0169,
  "code-braces-box": F10D6,
  "code-brackets": F016A,
  "code-equal": F016B,
  "code-greater-than": F016C,
  "code-greater-than-or-equal": F016D,
  "code-json": F0626,
  "code-less-than": F016E,
  "code-less-than-or-equal": F016F,
  "code-not-equal": F0170,
  "code-not-equal-variant": F0171,
  "code-parentheses": F0172,
  "code-parentheses-box": F10D7,
  "code-string": F0173,
  "code-tags": F0174,
  "code-tags-check": F0694,
  "codepen": F0175,
  "coffee": F0176,
  "coffee-maker": F109F,
  "coffee-off": F0FAA,
  "coffee-off-outline": F0FAB,
  "coffee-outline": F06CA,
  "coffee-to-go": F0177,
  "coffee-to-go-outline": F130E,
  "coffin": F0B7F,
  "cog": F0493,
  "cog-box": F0494,
  "cog-clockwise": F11DD,
  "cog-counterclockwise": F11DE,
  "cog-off": F13CE,
  "cog-off-outline": F13CF,
  "cog-outline": F08BB,
  "cog-refresh": F145E,
  "cog-refresh-outline": F145F,
  "cog-sync": F1460,
  "cog-sync-outline": F1461,
  "cog-transfer": F105B,
  "cog-transfer-outline": F105C,
  "cogs": F08D6,
  "collage": F0640,
  "collapse-all": F0AA6,
  "collapse-all-outline": F0AA7,
  "color-helper": F0179,
  "comma": F0E23,
  "comma-box": F0E2B,
  "comma-box-outline": F0E24,
  "comma-circle": F0E25,
  "comma-circle-outline": F0E26,
  "comment": F017A,
  "comment-account": F017B,
  "comment-account-outline": F017C,
  "comment-alert": F017D,
  "comment-alert-outline": F017E,
  "comment-arrow-left": F09E1,
  "comment-arrow-left-outline": F09E2,
  "comment-arrow-right": F09E3,
  "comment-arrow-right-outline": F09E4,
  "comment-bookmark": F15AE,
  "comment-bookmark-outline": F15AF,
  "comment-check": F017F,
  "comment-check-outline": F0180,
  "comment-edit": F11BF,
  "comment-edit-outline": F12C4,
  "comment-eye": F0A3A,
  "comment-eye-outline": F0A3B,
  "comment-flash": F15B0,
  "comment-flash-outline": F15B1,
  "comment-minus": F15DF,
  "comment-minus-outline": F15E0,
  "comment-multiple": F085F,
  "comment-multiple-outline": F0181,
  "comment-off": F15E1,
  "comment-off-outline": F15E2,
  "comment-outline": F0182,
  "comment-plus": F09E5,
  "comment-plus-outline": F0183,
  "comment-processing": F0184,
  "comment-processing-outline": F0185,
  "comment-question": F0817,
  "comment-question-outline": F0186,
  "comment-quote": F1021,
  "comment-quote-outline": F1022,
  "comment-remove": F05DE,
  "comment-remove-outline": F0187,
  "comment-search": F0A3C,
  "comment-search-outline": F0A3D,
  "comment-text": F0188,
  "comment-text-multiple": F0860,
  "comment-text-multiple-outline": F0861,
  "comment-text-outline": F0189,
  "compare": F018A,
  "compare-horizontal": F1492,
  "compare-vertical": F1493,
  "compass": F018B,
  "compass-off": F0B80,
  "compass-off-outline": F0B81,
  "compass-outline": F018C,
  "compass-rose": F1382,
  "concourse-ci": F10A0,
  "connection": F1616,
  "console": F018D,
  "console-line": F07B7,
  "console-network": F08A9,
  "console-network-outline": F0C60,
  "consolidate": F10D8,
  "contactless-payment": F0D6A,
  "contactless-payment-circle": F0321,
  "contactless-payment-circle-outline": F0408,
  "contacts": F06CB,
  "contacts-outline": F05B8,
  "contain": F0A3E,
  "contain-end": F0A3F,
  "contain-start": F0A40,
  "content-copy": F018F,
  "content-cut": F0190,
  "content-duplicate": F0191,
  "content-paste": F0192,
  "content-save": F0193,
  "content-save-alert": F0F42,
  "content-save-alert-outline": F0F43,
  "content-save-all": F0194,
  "content-save-all-outline": F0F44,
  "content-save-cog": F145B,
  "content-save-cog-outline": F145C,
  "content-save-edit": F0CFB,
  "content-save-edit-outline": F0CFC,
  "content-save-move": F0E27,
  "content-save-move-outline": F0E28,
  "content-save-off": F1643,
  "content-save-off-outline": F1644,
  "content-save-outline": F0818,
  "content-save-settings": F061B,
  "content-save-settings-outline": F0B2E,
  "contrast": F0195,
  "contrast-box": F0196,
  "contrast-circle": F0197,
  "controller-classic": F0B82,
  "controller-classic-outline": F0B83,
  "cookie": F0198,
  "cookie-alert": F16D0,
  "cookie-alert-outline": F16D1,
  "cookie-check": F16D2,
  "cookie-check-outline": F16D3,
  "cookie-clock": F16E4,
  "cookie-clock-outline": F16E5,
  "cookie-cog": F16D4,
  "cookie-cog-outline": F16D5,
  "cookie-edit": F16E6,
  "cookie-edit-outline": F16E7,
  "cookie-lock": F16E8,
  "cookie-lock-outline": F16E9,
  "cookie-minus": F16DA,
  "cookie-minus-outline": F16DB,
  "cookie-off": F16EA,
  "cookie-off-outline": F16EB,
  "cookie-outline": F16DE,
  "cookie-plus": F16D6,
  "cookie-plus-outline": F16D7,
  "cookie-refresh": F16EC,
  "cookie-refresh-outline": F16ED,
  "cookie-remove": F16D8,
  "cookie-remove-outline": F16D9,
  "cookie-settings": F16DC,
  "cookie-settings-outline": F16DD,
  "coolant-temperature": F03C8,
  "copyright": F05E6,
  "cordova": F0958,
  "corn": F07B8,
  "corn-off": F13EF,
  "cosine-wave": F1479,
  "counter": F0199,
  "cow": F019A,
  "cpu-32-bit": F0EDF,
  "cpu-64-bit": F0EE0,
  "crane": F0862,
  "creation": F0674,
  "creative-commons": F0D6B,
  "credit-card": F0FEF,
  "credit-card-check": F13D0,
  "credit-card-check-outline": F13D1,
  "credit-card-clock": F0EE1,
  "credit-card-clock-outline": F0EE2,
  "credit-card-marker": F06A8,
  "credit-card-marker-outline": F0DBE,
  "credit-card-minus": F0FAC,
  "credit-card-minus-outline": F0FAD,
  "credit-card-multiple": F0FF0,
  "credit-card-multiple-outline": F019C,
  "credit-card-off": F0FF1,
  "credit-card-off-outline": F05E4,
  "credit-card-outline": F019B,
  "credit-card-plus": F0FF2,
  "credit-card-plus-outline": F0676,
  "credit-card-refresh": F1645,
  "credit-card-refresh-outline": F1646,
  "credit-card-refund": F0FF3,
  "credit-card-refund-outline": F0AA8,
  "credit-card-remove": F0FAE,
  "credit-card-remove-outline": F0FAF,
  "credit-card-scan": F0FF4,
  "credit-card-scan-outline": F019D,
  "credit-card-search": F1647,
  "credit-card-search-outline": F1648,
  "credit-card-settings": F0FF5,
  "credit-card-settings-outline": F08D7,
  "credit-card-sync": F1649,
  "credit-card-sync-outline": F164A,
  "credit-card-wireless": F0802,
  "credit-card-wireless-off": F057A,
  "credit-card-wireless-off-outline": F057B,
  "credit-card-wireless-outline": F0D6C,
  "cricket": F0D6D,
  "crop": F019E,
  "crop-free": F019F,
  "crop-landscape": F01A0,
  "crop-portrait": F01A1,
  "crop-rotate": F0696,
  "crop-square": F01A2,
  "crosshairs": F01A3,
  "crosshairs-gps": F01A4,
  "crosshairs-off": F0F45,
  "crosshairs-question": F1136,
  "crown": F01A5,
  "crown-outline": F11D0,
  "cryengine": F0959,
  "crystal-ball": F0B2F,
  "cube": F01A6,
  "cube-off": F141C,
  "cube-off-outline": F141D,
  "cube-outline": F01A7,
  "cube-scan": F0B84,
  "cube-send": F01A8,
  "cube-unfolded": F01A9,
  "cup": F01AA,
  "cup-off": F05E5,
  "cup-off-outline": F137D,
  "cup-outline": F130F,
  "cup-water": F01AB,
  "cupboard": F0F46,
  "cupboard-outline": F0F47,
  "cupcake": F095A,
  "curling": F0863,
  "currency-bdt": F0864,
  "currency-brl": F0B85,
  "currency-btc": F01AC,
  "currency-cny": F07BA,
  "currency-eth": F07BB,
  "currency-eur": F01AD,
  "currency-eur-off": F1315,
  "currency-gbp": F01AE,
  "currency-ils": F0C61,
  "currency-inr": F01AF,
  "currency-jpy": F07BC,
  "currency-krw": F07BD,
  "currency-kzt": F0865,
  "currency-mnt": F1512,
  "currency-ngn": F01B0,
  "currency-php": F09E6,
  "currency-rial": F0E9C,
  "currency-rub": F01B1,
  "currency-sign": F07BE,
  "currency-try": F01B2,
  "currency-twd": F07BF,
  "currency-usd": F01C1,
  "currency-usd-circle": F116B,
  "currency-usd-circle-outline": F0178,
  "currency-usd-off": F067A,
  "current-ac": F1480,
  "current-dc": F095C,
  "cursor-default": F01C0,
  "cursor-default-click": F0CFD,
  "cursor-default-click-outline": F0CFE,
  "cursor-default-gesture": F1127,
  "cursor-default-gesture-outline": F1128,
  "cursor-default-outline": F01BF,
  "cursor-move": F01BE,
  "cursor-pointer": F01BD,
  "cursor-text": F05E7,
  "dance-ballroom": F15FB,
  "dance-pole": F1578,
  "data-matrix": F153C,
  "data-matrix-edit": F153D,
  "data-matrix-minus": F153E,
  "data-matrix-plus": F153F,
  "data-matrix-remove": F1540,
  "data-matrix-scan": F1541,
  "database": F01BC,
  "database-alert": F163A,
  "database-alert-outline": F1624,
  "database-arrow-down": F163B,
  "database-arrow-down-outline": F1625,
  "database-arrow-left": F163C,
  "database-arrow-left-outline": F1626,
  "database-arrow-right": F163D,
  "database-arrow-right-outline": F1627,
  "database-arrow-up": F163E,
  "database-arrow-up-outline": F1628,
  "database-check": F0AA9,
  "database-check-outline": F1629,
  "database-clock": F163F,
  "database-clock-outline": F162A,
  "database-cog": F164B,
  "database-cog-outline": F164C,
  "database-edit": F0B86,
  "database-edit-outline": F162B,
  "database-export": F095E,
  "database-export-outline": F162C,
  "database-import": F095D,
  "database-import-outline": F162D,
  "database-lock": F0AAA,
  "database-lock-outline": F162E,
  "database-marker": F12F6,
  "database-marker-outline": F162F,
  "database-minus": F01BB,
  "database-minus-outline": F1630,
  "database-off": F1640,
  "database-off-outline": F1631,
  "database-outline": F1632,
  "database-plus": F01BA,
  "database-plus-outline": F1633,
  "database-refresh": F05C2,
  "database-refresh-outline": F1634,
  "database-remove": F0D00,
  "database-remove-outline": F1635,
  "database-search": F0866,
  "database-search-outline": F1636,
  "database-settings": F0D01,
  "database-settings-outline": F1637,
  "database-sync": F0CFF,
  "database-sync-outline": F1638,
  "death-star": F08D8,
  "death-star-variant": F08D9,
  "deathly-hallows": F0B87,
  "debian": F08DA,
  "debug-step-into": F01B9,
  "debug-step-out": F01B8,
  "debug-step-over": F01B7,
  "decagram": F076C,
  "decagram-outline": F076D,
  "decimal": F10A1,
  "decimal-comma": F10A2,
  "decimal-comma-decrease": F10A3,
  "decimal-comma-increase": F10A4,
  "decimal-decrease": F01B6,
  "decimal-increase": F01B5,
  "delete": F01B4,
  "delete-alert": F10A5,
  "delete-alert-outline": F10A6,
  "delete-circle": F0683,
  "delete-circle-outline": F0B88,
  "delete-clock": F1556,
  "delete-clock-outline": F1557,
  "delete-empty": F06CC,
  "delete-empty-outline": F0E9D,
  "delete-forever": F05E8,
  "delete-forever-outline": F0B89,
  "delete-off": F10A7,
  "delete-off-outline": F10A8,
  "delete-outline": F09E7,
  "delete-restore": F0819,
  "delete-sweep": F05E9,
  "delete-sweep-outline": F0C62,
  "delete-variant": F01B3,
  "delta": F01C2,
  "desk": F1239,
  "desk-lamp": F095F,
  "deskphone": F01C3,
  "desktop-classic": F07C0,
  "desktop-mac": F01C4,
  "desktop-mac-dashboard": F09E8,
  "desktop-tower": F01C5,
  "desktop-tower-monitor": F0AAB,
  "details": F01C6,
  "dev-to": F0D6E,
  "developer-board": F0697,
  "deviantart": F01C7,
  "devices": F0FB0,
  "diabetes": F1126,
  "dialpad": F061C,
  "diameter": F0C63,
  "diameter-outline": F0C64,
  "diameter-variant": F0C65,
  "diamond": F0B8A,
  "diamond-outline": F0B8B,
  "diamond-stone": F01C8,
  "dice-1": F01CA,
  "dice-1-outline": F114A,
  "dice-2": F01CB,
  "dice-2-outline": F114B,
  "dice-3": F01CC,
  "dice-3-outline": F114C,
  "dice-4": F01CD,
  "dice-4-outline": F114D,
  "dice-5": F01CE,
  "dice-5-outline": F114E,
  "dice-6": F01CF,
  "dice-6-outline": F114F,
  "dice-d10": F1153,
  "dice-d10-outline": F076F,
  "dice-d12": F1154,
  "dice-d12-outline": F0867,
  "dice-d20": F1155,
  "dice-d20-outline": F05EA,
  "dice-d4": F1150,
  "dice-d4-outline": F05EB,
  "dice-d6": F1151,
  "dice-d6-outline": F05ED,
  "dice-d8": F1152,
  "dice-d8-outline": F05EC,
  "dice-multiple": F076E,
  "dice-multiple-outline": F1156,
  "digital-ocean": F1237,
  "dip-switch": F07C1,
  "directions": F01D0,
  "directions-fork": F0641,
  "disc": F05EE,
  "disc-alert": F01D1,
  "disc-player": F0960,
  "discord": F066F,
  "dishwasher": F0AAC,
  "dishwasher-alert": F11B8,
  "dishwasher-off": F11B9,
  "disqus": F01D2,
  "distribute-horizontal-center": F11C9,
  "distribute-horizontal-left": F11C8,
  "distribute-horizontal-right": F11CA,
  "distribute-vertical-bottom": F11CB,
  "distribute-vertical-center": F11CC,
  "distribute-vertical-top": F11CD,
  "diving-flippers": F0DBF,
  "diving-helmet": F0DC0,
  "diving-scuba": F0DC1,
  "diving-scuba-flag": F0DC2,
  "diving-scuba-tank": F0DC3,
  "diving-scuba-tank-multiple": F0DC4,
  "diving-snorkel": F0DC5,
  "division": F01D4,
  "division-box": F01D5,
  "dlna": F0A41,
  "dna": F0684,
  "dns": F01D6,
  "dns-outline": F0B8C,
  "do-not-disturb": F0698,
  "do-not-disturb-off": F0699,
  "dock-bottom": F10A9,
  "dock-left": F10AA,
  "dock-right": F10AB,
  "dock-top": F1513,
  "dock-window": F10AC,
  "docker": F0868,
  "doctor": F0A42,
  "dog": F0A43,
  "dog-service": F0AAD,
  "dog-side": F0A44,
  "dog-side-off": F16EE,
  "dolby": F06B3,
  "dolly": F0E9E,
  "domain": F01D7,
  "domain-off": F0D6F,
  "domain-plus": F10AD,
  "domain-remove": F10AE,
  "dome-light": F141E,
  "domino-mask": F1023,
  "donkey": F07C2,
  "door": F081A,
  "door-closed": F081B,
  "door-closed-lock": F10AF,
  "door-open": F081C,
  "doorbell": F12E6,
  "doorbell-video": F0869,
  "dot-net": F0AAE,
  "dots-grid": F15FC,
  "dots-hexagon": F15FF,
  "dots-horizontal": F01D8,
  "dots-horizontal-circle": F07C3,
  "dots-horizontal-circle-outline": F0B8D,
  "dots-square": F15FD,
  "dots-triangle": F15FE,
  "dots-vertical": F01D9,
  "dots-vertical-circle": F07C4,
  "dots-vertical-circle-outline": F0B8E,
  "douban": F069A,
  "download": F01DA,
  "download-box": F1462,
  "download-box-outline": F1463,
  "download-circle": F1464,
  "download-circle-outline": F1465,
  "download-lock": F1320,
  "download-lock-outline": F1321,
  "download-multiple": F09E9,
  "download-network": F06F4,
  "download-network-outline": F0C66,
  "download-off": F10B0,
  "download-off-outline": F10B1,
  "download-outline": F0B8F,
  "drag": F01DB,
  "drag-horizontal": F01DC,
  "drag-horizontal-variant": F12F0,
  "drag-variant": F0B90,
  "drag-vertical": F01DD,
  "drag-vertical-variant": F12F1,
  "drama-masks": F0D02,
  "draw": F0F49,
  "drawing": F01DE,
  "drawing-box": F01DF,
  "dresser": F0F4A,
  "dresser-outline": F0F4B,
  "drone": F01E2,
  "dropbox": F01E3,
  "drupal": F01E4,
  "duck": F01E5,
  "dumbbell": F01E6,
  "dump-truck": F0C67,
  "ear-hearing": F07C5,
  "ear-hearing-off": F0A45,
  "earth": F01E7,
  "earth-arrow-right": F1311,
  "earth-box": F06CD,
  "earth-box-minus": F1407,
  "earth-box-off": F06CE,
  "earth-box-plus": F1406,
  "earth-box-remove": F1408,
  "earth-minus": F1404,
  "earth-off": F01E8,
  "earth-plus": F1403,
  "earth-remove": F1405,
  "egg": F0AAF,
  "egg-easter": F0AB0,
  "egg-off": F13F0,
  "egg-off-outline": F13F1,
  "egg-outline": F13F2,
  "eiffel-tower": F156B,
  "eight-track": F09EA,
  "eject": F01EA,
  "eject-outline": F0B91,
  "electric-switch": F0E9F,
  "electric-switch-closed": F10D9,
  "electron-framework": F1024,
  "elephant": F07C6,
  "elevation-decline": F01EB,
  "elevation-rise": F01EC,
  "elevator": F01ED,
  "elevator-down": F12C2,
  "elevator-passenger": F1381,
  "elevator-up": F12C1,
  "ellipse": F0EA0,
  "ellipse-outline": F0EA1,
  "email": F01EE,
  "email-alert": F06CF,
  "email-alert-outline": F0D42,
  "email-box": F0D03,
  "email-check": F0AB1,
  "email-check-outline": F0AB2,
  "email-edit": F0EE3,
  "email-edit-outline": F0EE4,
  "email-lock": F01F1,
  "email-mark-as-unread": F0B92,
  "email-minus": F0EE5,
  "email-minus-outline": F0EE6,
  "email-multiple": F0EE7,
  "email-multiple-outline": F0EE8,
  "email-newsletter": F0FB1,
  "email-off": F13E3,
  "email-off-outline": F13E4,
  "email-open": F01EF,
  "email-open-multiple": F0EE9,
  "email-open-multiple-outline": F0EEA,
  "email-open-outline": F05EF,
  "email-outline": F01F0,
  "email-plus": F09EB,
  "email-plus-outline": F09EC,
  "email-receive": F10DA,
  "email-receive-outline": F10DB,
  "email-remove": F1661,
  "email-remove-outline": F1662,
  "email-search": F0961,
  "email-search-outline": F0962,
  "email-send": F10DC,
  "email-send-outline": F10DD,
  "email-sync": F12C7,
  "email-sync-outline": F12C8,
  "email-variant": F05F0,
  "ember": F0B30,
  "emby": F06B4,
  "emoticon": F0C68,
  "emoticon-angry": F0C69,
  "emoticon-angry-outline": F0C6A,
  "emoticon-confused": F10DE,
  "emoticon-confused-outline": F10DF,
  "emoticon-cool": F0C6B,
  "emoticon-cool-outline": F01F3,
  "emoticon-cry": F0C6C,
  "emoticon-cry-outline": F0C6D,
  "emoticon-dead": F0C6E,
  "emoticon-dead-outline": F069B,
  "emoticon-devil": F0C6F,
  "emoticon-devil-outline": F01F4,
  "emoticon-excited": F0C70,
  "emoticon-excited-outline": F069C,
  "emoticon-frown": F0F4C,
  "emoticon-frown-outline": F0F4D,
  "emoticon-happy": F0C71,
  "emoticon-happy-outline": F01F5,
  "emoticon-kiss": F0C72,
  "emoticon-kiss-outline": F0C73,
  "emoticon-lol": F1214,
  "emoticon-lol-outline": F1215,
  "emoticon-neutral": F0C74,
  "emoticon-neutral-outline": F01F6,
  "emoticon-outline": F01F2,
  "emoticon-poop": F01F7,
  "emoticon-poop-outline": F0C75,
  "emoticon-sad": F0C76,
  "emoticon-sad-outline": F01F8,
  "emoticon-sick": F157C,
  "emoticon-sick-outline": F157D,
  "emoticon-tongue": F01F9,
  "emoticon-tongue-outline": F0C77,
  "emoticon-wink": F0C78,
  "emoticon-wink-outline": F0C79,
  "engine": F01FA,
  "engine-off": F0A46,
  "engine-off-outline": F0A47,
  "engine-outline": F01FB,
  "epsilon": F10E0,
  "equal": F01FC,
  "equal-box": F01FD,
  "equalizer": F0EA2,
  "equalizer-outline": F0EA3,
  "eraser": F01FE,
  "eraser-variant": F0642,
  "escalator": F01FF,
  "escalator-box": F1399,
  "escalator-down": F12C0,
  "escalator-up": F12BF,
  "eslint": F0C7A,
  "et": F0AB3,
  "ethereum": F086A,
  "ethernet": F0200,
  "ethernet-cable": F0201,
  "ethernet-cable-off": F0202,
  "ev-plug-ccs1": F1519,
  "ev-plug-ccs2": F151A,
  "ev-plug-chademo": F151B,
  "ev-plug-tesla": F151C,
  "ev-plug-type1": F151D,
  "ev-plug-type2": F151E,
  "ev-station": F05F1,
  "evernote": F0204,
  "excavator": F1025,
  "exclamation": F0205,
  "exclamation-thick": F1238,
  "exit-run": F0A48,
  "exit-to-app": F0206,
  "expand-all": F0AB4,
  "expand-all-outline": F0AB5,
  "expansion-card": F08AE,
  "expansion-card-variant": F0FB2,
  "exponent": F0963,
  "exponent-box": F0964,
  "export": F0207,
  "export-variant": F0B93,
  "eye": F0208,
  "eye-check": F0D04,
  "eye-check-outline": F0D05,
  "eye-circle": F0B94,
  "eye-circle-outline": F0B95,
  "eye-minus": F1026,
  "eye-minus-outline": F1027,
  "eye-off": F0209,
  "eye-off-outline": F06D1,
  "eye-outline": F06D0,
  "eye-plus": F086B,
  "eye-plus-outline": F086C,
  "eye-remove": F15E3,
  "eye-remove-outline": F15E4,
  "eye-settings": F086D,
  "eye-settings-outline": F086E,
  "eyedropper": F020A,
  "eyedropper-minus": F13DD,
  "eyedropper-off": F13DF,
  "eyedropper-plus": F13DC,
  "eyedropper-remove": F13DE,
  "eyedropper-variant": F020B,
  "face": F0643,
  "face-agent": F0D70,
  "face-mask": F1586,
  "face-mask-outline": F1587,
  "face-outline": F0B96,
  "face-profile": F0644,
  "face-profile-woman": F1076,
  "face-recognition": F0C7B,
  "face-shimmer": F15CC,
  "face-shimmer-outline": F15CD,
  "face-woman": F1077,
  "face-woman-outline": F1078,
  "face-woman-shimmer": F15CE,
  "face-woman-shimmer-outline": F15CF,
  "facebook": F020C,
  "facebook-gaming": F07DD,
  "facebook-messenger": F020E,
  "facebook-workplace": F0B31,
  "factory": F020F,
  "family-tree": F160E,
  "fan": F0210,
  "fan-alert": F146C,
  "fan-auto": F171D,
  "fan-chevron-down": F146D,
  "fan-chevron-up": F146E,
  "fan-minus": F1470,
  "fan-off": F081D,
  "fan-plus": F146F,
  "fan-remove": F1471,
  "fan-speed-1": F1472,
  "fan-speed-2": F1473,
  "fan-speed-3": F1474,
  "fast-forward": F0211,
  "fast-forward-10": F0D71,
  "fast-forward-30": F0D06,
  "fast-forward-5": F11F8,
  "fast-forward-60": F160B,
  "fast-forward-outline": F06D2,
  "fax": F0212,
  "feather": F06D3,
  "feature-search": F0A49,
  "feature-search-outline": F0A4A,
  "fedora": F08DB,
  "fencing": F14C1,
  "ferris-wheel": F0EA4,
  "ferry": F0213,
  "file": F0214,
  "file-account": F073B,
  "file-account-outline": F1028,
  "file-alert": F0A4B,
  "file-alert-outline": F0A4C,
  "file-cabinet": F0AB6,
  "file-cad": F0EEB,
  "file-cad-box": F0EEC,
  "file-cancel": F0DC6,
  "file-cancel-outline": F0DC7,
  "file-certificate": F1186,
  "file-certificate-outline": F1187,
  "file-chart": F0215,
  "file-chart-outline": F1029,
  "file-check": F0216,
  "file-check-outline": F0E29,
  "file-clock": F12E1,
  "file-clock-outline": F12E2,
  "file-cloud": F0217,
  "file-cloud-outline": F102A,
  "file-code": F022E,
  "file-code-outline": F102B,
  "file-cog": F107B,
  "file-cog-outline": F107C,
  "file-compare": F08AA,
  "file-delimited": F0218,
  "file-delimited-outline": F0EA5,
  "file-document": F0219,
  "file-document-edit": F0DC8,
  "file-document-edit-outline": F0DC9,
  "file-document-multiple": F1517,
  "file-document-multiple-outline": F1518,
  "file-document-outline": F09EE,
  "file-download": F0965,
  "file-download-outline": F0966,
  "file-edit": F11E7,
  "file-edit-outline": F11E8,
  "file-excel": F021B,
  "file-excel-box": F021C,
  "file-excel-box-outline": F102C,
  "file-excel-outline": F102D,
  "file-export": F021D,
  "file-export-outline": F102E,
  "file-eye": F0DCA,
  "file-eye-outline": F0DCB,
  "file-find": F021E,
  "file-find-outline": F0B97,
  "file-hidden": F0613,
  "file-image": F021F,
  "file-image-outline": F0EB0,
  "file-import": F0220,
  "file-import-outline": F102F,
  "file-key": F1184,
  "file-key-outline": F1185,
  "file-link": F1177,
  "file-link-outline": F1178,
  "file-lock": F0221,
  "file-lock-outline": F1030,
  "file-move": F0AB9,
  "file-move-outline": F1031,
  "file-multiple": F0222,
  "file-multiple-outline": F1032,
  "file-music": F0223,
  "file-music-outline": F0E2A,
  "file-outline": F0224,
  "file-pdf": F0225,
  "file-pdf-box": F0226,
  "file-pdf-box-outline": F0FB3,
  "file-pdf-outline": F0E2D,
  "file-percent": F081E,
  "file-percent-outline": F1033,
  "file-phone": F1179,
  "file-phone-outline": F117A,
  "file-plus": F0752,
  "file-plus-outline": F0EED,
  "file-powerpoint": F0227,
  "file-powerpoint-box": F0228,
  "file-powerpoint-box-outline": F1034,
  "file-powerpoint-outline": F1035,
  "file-presentation-box": F0229,
  "file-question": F086F,
  "file-question-outline": F1036,
  "file-refresh": F0918,
  "file-refresh-outline": F0541,
  "file-remove": F0B98,
  "file-remove-outline": F1037,
  "file-replace": F0B32,
  "file-replace-outline": F0B33,
  "file-restore": F0670,
  "file-restore-outline": F1038,
  "file-search": F0C7C,
  "file-search-outline": F0C7D,
  "file-send": F022A,
  "file-send-outline": F1039,
  "file-settings": F1079,
  "file-settings-outline": F107A,
  "file-star": F103A,
  "file-star-outline": F103B,
  "file-swap": F0FB4,
  "file-swap-outline": F0FB5,
  "file-sync": F1216,
  "file-sync-outline": F1217,
  "file-table": F0C7E,
  "file-table-box": F10E1,
  "file-table-box-multiple": F10E2,
  "file-table-box-multiple-outline": F10E3,
  "file-table-box-outline": F10E4,
  "file-table-outline": F0C7F,
  "file-tree": F0645,
  "file-tree-outline": F13D2,
  "file-undo": F08DC,
  "file-undo-outline": F103C,
  "file-upload": F0A4D,
  "file-upload-outline": F0A4E,
  "file-video": F022B,
  "file-video-outline": F0E2C,
  "file-word": F022C,
  "file-word-box": F022D,
  "file-word-box-outline": F103D,
  "file-word-outline": F103E,
  "film": F022F,
  "filmstrip": F0230,
  "filmstrip-box": F0332,
  "filmstrip-box-multiple": F0D18,
  "filmstrip-off": F0231,
  "filter": F0232,
  "filter-menu": F10E5,
  "filter-menu-outline": F10E6,
  "filter-minus": F0EEE,
  "filter-minus-outline": F0EEF,
  "filter-off": F14EF,
  "filter-off-outline": F14F0,
  "filter-outline": F0233,
  "filter-plus": F0EF0,
  "filter-plus-outline": F0EF1,
  "filter-remove": F0234,
  "filter-remove-outline": F0235,
  "filter-variant": F0236,
  "filter-variant-minus": F1112,
  "filter-variant-plus": F1113,
  "filter-variant-remove": F103F,
  "finance": F081F,
  "find-replace": F06D4,
  "fingerprint": F0237,
  "fingerprint-off": F0EB1,
  "fire": F0238,
  "fire-alert": F15D7,
  "fire-extinguisher": F0EF2,
  "fire-hydrant": F1137,
  "fire-hydrant-alert": F1138,
  "fire-hydrant-off": F1139,
  "fire-off": F1722,
  "fire-truck": F08AB,
  "firebase": F0967,
  "firefox": F0239,
  "fireplace": F0E2E,
  "fireplace-off": F0E2F,
  "firework": F0E30,
  "firework-off": F1723,
  "fish": F023A,
  "fish-off": F13F3,
  "fishbowl": F0EF3,
  "fishbowl-outline": F0EF4,
  "fit-to-page": F0EF5,
  "fit-to-page-outline": F0EF6,
  "flag": F023B,
  "flag-checkered": F023C,
  "flag-minus": F0B99,
  "flag-minus-outline": F10B2,
  "flag-outline": F023D,
  "flag-plus": F0B9A,
  "flag-plus-outline": F10B3,
  "flag-remove": F0B9B,
  "flag-remove-outline": F10B4,
  "flag-triangle": F023F,
  "flag-variant": F0240,
  "flag-variant-outline": F023E,
  "flare": F0D72,
  "flash": F0241,
  "flash-alert": F0EF7,
  "flash-alert-outline": F0EF8,
  "flash-auto": F0242,
  "flash-circle": F0820,
  "flash-off": F0243,
  "flash-outline": F06D5,
  "flash-red-eye": F067B,
  "flashlight": F0244,
  "flashlight-off": F0245,
  "flask": F0093,
  "flask-empty": F0094,
  "flask-empty-minus": F123A,
  "flask-empty-minus-outline": F123B,
  "flask-empty-off": F13F4,
  "flask-empty-off-outline": F13F5,
  "flask-empty-outline": F0095,
  "flask-empty-plus": F123C,
  "flask-empty-plus-outline": F123D,
  "flask-empty-remove": F123E,
  "flask-empty-remove-outline": F123F,
  "flask-minus": F1240,
  "flask-minus-outline": F1241,
  "flask-off": F13F6,
  "flask-off-outline": F13F7,
  "flask-outline": F0096,
  "flask-plus": F1242,
  "flask-plus-outline": F1243,
  "flask-remove": F1244,
  "flask-remove-outline": F1245,
  "flask-round-bottom": F124B,
  "flask-round-bottom-empty": F124C,
  "flask-round-bottom-empty-outline": F124D,
  "flask-round-bottom-outline": F124E,
  "fleur-de-lis": F1303,
  "flip-horizontal": F10E7,
  "flip-to-back": F0247,
  "flip-to-front": F0248,
  "flip-vertical": F10E8,
  "floor-lamp": F08DD,
  "floor-lamp-dual": F1040,
  "floor-lamp-variant": F1041,
  "floor-plan": F0821,
  "floppy": F0249,
  "floppy-variant": F09EF,
  "flower": F024A,
  "flower-outline": F09F0,
  "flower-poppy": F0D08,
  "flower-tulip": F09F1,
  "flower-tulip-outline": F09F2,
  "focus-auto": F0F4E,
  "focus-field": F0F4F,
  "focus-field-horizontal": F0F50,
  "focus-field-vertical": F0F51,
  "folder": F024B,
  "folder-account": F024C,
  "folder-account-outline": F0B9C,
  "folder-alert": F0DCC,
  "folder-alert-outline": F0DCD,
  "folder-clock": F0ABA,
  "folder-clock-outline": F0ABB,
  "folder-cog": F107F,
  "folder-cog-outline": F1080,
  "folder-download": F024D,
  "folder-download-outline": F10E9,
  "folder-edit": F08DE,
  "folder-edit-outline": F0DCE,
  "folder-google-drive": F024E,
  "folder-heart": F10EA,
  "folder-heart-outline": F10EB,
  "folder-home": F10B5,
  "folder-home-outline": F10B6,
  "folder-image": F024F,
  "folder-information": F10B7,
  "folder-information-outline": F10B8,
  "folder-key": F08AC,
  "folder-key-network": F08AD,
  "folder-key-network-outline": F0C80,
  "folder-key-outline": F10EC,
  "folder-lock": F0250,
  "folder-lock-open": F0251,
  "folder-marker": F126D,
  "folder-marker-outline": F126E,
  "folder-move": F0252,
  "folder-move-outline": F1246,
  "folder-multiple": F0253,
  "folder-multiple-image": F0254,
  "folder-multiple-outline": F0255,
  "folder-multiple-plus": F147E,
  "folder-multiple-plus-outline": F147F,
  "folder-music": F1359,
  "folder-music-outline": F135A,
  "folder-network": F0870,
  "folder-network-outline": F0C81,
  "folder-open": F0770,
  "folder-open-outline": F0DCF,
  "folder-outline": F0256,
  "folder-plus": F0257,
  "folder-plus-outline": F0B9D,
  "folder-pound": F0D09,
  "folder-pound-outline": F0D0A,
  "folder-refresh": F0749,
  "folder-refresh-outline": F0542,
  "folder-remove": F0258,
  "folder-remove-outline": F0B9E,
  "folder-search": F0968,
  "folder-search-outline": F0969,
  "folder-settings": F107D,
  "folder-settings-outline": F107E,
  "folder-star": F069D,
  "folder-star-multiple": F13D3,
  "folder-star-multiple-outline": F13D4,
  "folder-star-outline": F0B9F,
  "folder-swap": F0FB6,
  "folder-swap-outline": F0FB7,
  "folder-sync": F0D0B,
  "folder-sync-outline": F0D0C,
  "folder-table": F12E3,
  "folder-table-outline": F12E4,
  "folder-text": F0C82,
  "folder-text-outline": F0C83,
  "folder-upload": F0259,
  "folder-upload-outline": F10ED,
  "folder-zip": F06EB,
  "folder-zip-outline": F07B9,
  "font-awesome": F003A,
  "food": F025A,
  "food-apple": F025B,
  "food-apple-outline": F0C84,
  "food-croissant": F07C8,
  "food-drumstick": F141F,
  "food-drumstick-off": F1468,
  "food-drumstick-off-outline": F1469,
  "food-drumstick-outline": F1420,
  "food-fork-drink": F05F2,
  "food-halal": F1572,
  "food-kosher": F1573,
  "food-off": F05F3,
  "food-steak": F146A,
  "food-steak-off": F146B,
  "food-turkey": F171C,
  "food-variant": F025C,
  "food-variant-off": F13E5,
  "foot-print": F0F52,
  "football": F025D,
  "football-australian": F025E,
  "football-helmet": F025F,
  "forklift": F07C9,
  "form-dropdown": F1400,
  "form-select": F1401,
  "form-textarea": F1095,
  "form-textbox": F060E,
  "form-textbox-lock": F135D,
  "form-textbox-password": F07F5,
  "format-align-bottom": F0753,
  "format-align-center": F0260,
  "format-align-justify": F0261,
  "format-align-left": F0262,
  "format-align-middle": F0754,
  "format-align-right": F0263,
  "format-align-top": F0755,
  "format-annotation-minus": F0ABC,
  "format-annotation-plus": F0646,
  "format-bold": F0264,
  "format-clear": F0265,
  "format-color-fill": F0266,
  "format-color-highlight": F0E31,
  "format-color-marker-cancel": F1313,
  "format-color-text": F069E,
  "format-columns": F08DF,
  "format-float-center": F0267,
  "format-float-left": F0268,
  "format-float-none": F0269,
  "format-float-right": F026A,
  "format-font": F06D6,
  "format-font-size-decrease": F09F3,
  "format-font-size-increase": F09F4,
  "format-header-1": F026B,
  "format-header-2": F026C,
  "format-header-3": F026D,
  "format-header-4": F026E,
  "format-header-5": F026F,
  "format-header-6": F0270,
  "format-header-decrease": F0271,
  "format-header-equal": F0272,
  "format-header-increase": F0273,
  "format-header-pound": F0274,
  "format-horizontal-align-center": F061E,
  "format-horizontal-align-left": F061F,
  "format-horizontal-align-right": F0620,
  "format-indent-decrease": F0275,
  "format-indent-increase": F0276,
  "format-italic": F0277,
  "format-letter-case": F0B34,
  "format-letter-case-lower": F0B35,
  "format-letter-case-upper": F0B36,
  "format-letter-ends-with": F0FB8,
  "format-letter-matches": F0FB9,
  "format-letter-starts-with": F0FBA,
  "format-line-spacing": F0278,
  "format-line-style": F05C8,
  "format-line-weight": F05C9,
  "format-list-bulleted": F0279,
  "format-list-bulleted-square": F0DD0,
  "format-list-bulleted-triangle": F0EB2,
  "format-list-bulleted-type": F027A,
  "format-list-checkbox": F096A,
  "format-list-checks": F0756,
  "format-list-numbered": F027B,
  "format-list-numbered-rtl": F0D0D,
  "format-list-text": F126F,
  "format-overline": F0EB3,
  "format-page-break": F06D7,
  "format-paint": F027C,
  "format-paragraph": F027D,
  "format-pilcrow": F06D8,
  "format-quote-close": F027E,
  "format-quote-close-outline": F11A8,
  "format-quote-open": F0757,
  "format-quote-open-outline": F11A7,
  "format-rotate-90": F06AA,
  "format-section": F069F,
  "format-size": F027F,
  "format-strikethrough": F0280,
  "format-strikethrough-variant": F0281,
  "format-subscript": F0282,
  "format-superscript": F0283,
  "format-text": F0284,
  "format-text-rotation-angle-down": F0FBB,
  "format-text-rotation-angle-up": F0FBC,
  "format-text-rotation-down": F0D73,
  "format-text-rotation-down-vertical": F0FBD,
  "format-text-rotation-none": F0D74,
  "format-text-rotation-up": F0FBE,
  "format-text-rotation-vertical": F0FBF,
  "format-text-variant": F0E32,
  "format-text-variant-outline": F150F,
  "format-text-wrapping-clip": F0D0E,
  "format-text-wrapping-overflow": F0D0F,
  "format-text-wrapping-wrap": F0D10,
  "format-textbox": F0D11,
  "format-textdirection-l-to-r": F0285,
  "format-textdirection-r-to-l": F0286,
  "format-title": F05F4,
  "format-underline": F0287,
  "format-vertical-align-bottom": F0621,
  "format-vertical-align-center": F0622,
  "format-vertical-align-top": F0623,
  "format-wrap-inline": F0288,
  "format-wrap-square": F0289,
  "format-wrap-tight": F028A,
  "format-wrap-top-bottom": F028B,
  "forum": F028C,
  "forum-outline": F0822,
  "forward": F028D,
  "forwardburger": F0D75,
  "fountain": F096B,
  "fountain-pen": F0D12,
  "fountain-pen-tip": F0D13,
  "freebsd": F08E0,
  "frequently-asked-questions": F0EB4,
  "fridge": F0290,
  "fridge-alert": F11B1,
  "fridge-alert-outline": F11B2,
  "fridge-bottom": F0292,
  "fridge-industrial": F15EE,
  "fridge-industrial-alert": F15EF,
  "fridge-industrial-alert-outline": F15F0,
  "fridge-industrial-off": F15F1,
  "fridge-industrial-off-outline": F15F2,
  "fridge-industrial-outline": F15F3,
  "fridge-off": F11AF,
  "fridge-off-outline": F11B0,
  "fridge-outline": F028F,
  "fridge-top": F0291,
  "fridge-variant": F15F4,
  "fridge-variant-alert": F15F5,
  "fridge-variant-alert-outline": F15F6,
  "fridge-variant-off": F15F7,
  "fridge-variant-off-outline": F15F8,
  "fridge-variant-outline": F15F9,
  "fruit-cherries": F1042,
  "fruit-cherries-off": F13F8,
  "fruit-citrus": F1043,
  "fruit-citrus-off": F13F9,
  "fruit-grapes": F1044,
  "fruit-grapes-outline": F1045,
  "fruit-pineapple": F1046,
  "fruit-watermelon": F1047,
  "fuel": F07CA,
  "fullscreen": F0293,
  "fullscreen-exit": F0294,
  "function": F0295,
  "function-variant": F0871,
  "furigana-horizontal": F1081,
  "furigana-vertical": F1082,
  "fuse": F0C85,
  "fuse-alert": F142D,
  "fuse-blade": F0C86,
  "fuse-off": F142C,
  "gamepad": F0296,
  "gamepad-circle": F0E33,
  "gamepad-circle-down": F0E34,
  "gamepad-circle-left": F0E35,
  "gamepad-circle-outline": F0E36,
  "gamepad-circle-right": F0E37,
  "gamepad-circle-up": F0E38,
  "gamepad-down": F0E39,
  "gamepad-left": F0E3A,
  "gamepad-right": F0E3B,
  "gamepad-round": F0E3C,
  "gamepad-round-down": F0E3D,
  "gamepad-round-left": F0E3E,
  "gamepad-round-outline": F0E3F,
  "gamepad-round-right": F0E40,
  "gamepad-round-up": F0E41,
  "gamepad-square": F0EB5,
  "gamepad-square-outline": F0EB6,
  "gamepad-up": F0E42,
  "gamepad-variant": F0297,
  "gamepad-variant-outline": F0EB7,
  "gamma": F10EE,
  "gantry-crane": F0DD1,
  "garage": F06D9,
  "garage-alert": F0872,
  "garage-alert-variant": F12D5,
  "garage-open": F06DA,
  "garage-open-variant": F12D4,
  "garage-variant": F12D3,
  "gas-cylinder": F0647,
  "gas-station": F0298,
  "gas-station-off": F1409,
  "gas-station-off-outline": F140A,
  "gas-station-outline": F0EB8,
  "gate": F0299,
  "gate-and": F08E1,
  "gate-arrow-right": F1169,
  "gate-nand": F08E2,
  "gate-nor": F08E3,
  "gate-not": F08E4,
  "gate-open": F116A,
  "gate-or": F08E5,
  "gate-xnor": F08E6,
  "gate-xor": F08E7,
  "gatsby": F0E43,
  "gauge": F029A,
  "gauge-empty": F0873,
  "gauge-full": F0874,
  "gauge-low": F0875,
  "gavel": F029B,
  "gender-female": F029C,
  "gender-male": F029D,
  "gender-male-female": F029E,
  "gender-male-female-variant": F113F,
  "gender-non-binary": F1140,
  "gender-transgender": F029F,
  "gentoo": F08E8,
  "gesture": F07CB,
  "gesture-double-tap": F073C,
  "gesture-pinch": F0ABD,
  "gesture-spread": F0ABE,
  "gesture-swipe": F0D76,
  "gesture-swipe-down": F073D,
  "gesture-swipe-horizontal": F0ABF,
  "gesture-swipe-left": F073E,
  "gesture-swipe-right": F073F,
  "gesture-swipe-up": F0740,
  "gesture-swipe-vertical": F0AC0,
  "gesture-tap": F0741,
  "gesture-tap-box": F12A9,
  "gesture-tap-button": F12A8,
  "gesture-tap-hold": F0D77,
  "gesture-two-double-tap": F0742,
  "gesture-two-tap": F0743,
  "ghost": F02A0,
  "ghost-off": F09F5,
  "ghost-off-outline": F165C,
  "ghost-outline": F165D,
  "gif": F0D78,
  "gift": F0E44,
  "gift-off": F16EF,
  "gift-off-outline": F16F0,
  "gift-open": F16F1,
  "gift-open-outline": F16F2,
  "gift-outline": F02A1,
  "git": F02A2,
  "github": F02A4,
  "gitlab": F0BA0,
  "glass-cocktail": F0356,
  "glass-cocktail-off": F15E6,
  "glass-flute": F02A5,
  "glass-mug": F02A6,
  "glass-mug-off": F15E7,
  "glass-mug-variant": F1116,
  "glass-mug-variant-off": F15E8,
  "glass-pint-outline": F130D,
  "glass-stange": F02A7,
  "glass-tulip": F02A8,
  "glass-wine": F0876,
  "glasses": F02AA,
  "globe-light": F12D7,
  "globe-model": F08E9,
  "gmail": F02AB,
  "gnome": F02AC,
  "go-kart": F0D79,
  "go-kart-track": F0D7A,
  "gog": F0BA1,
  "gold": F124F,
  "golf": F0823,
  "golf-cart": F11A4,
  "golf-tee": F1083,
  "gondola": F0686,
  "goodreads": F0D7B,
  "google": F02AD,
  "google-ads": F0C87,
  "google-analytics": F07CC,
  "google-assistant": F07CD,
  "google-cardboard": F02AE,
  "google-chrome": F02AF,
  "google-circles": F02B0,
  "google-circles-communities": F02B1,
  "google-circles-extended": F02B2,
  "google-circles-group": F02B3,
  "google-classroom": F02C0,
  "google-cloud": F11F6,
  "google-controller": F02B4,
  "google-controller-off": F02B5,
  "google-downasaur": F1362,
  "google-drive": F02B6,
  "google-earth": F02B7,
  "google-fit": F096C,
  "google-glass": F02B8,
  "google-hangouts": F02C9,
  "google-home": F0824,
  "google-keep": F06DC,
  "google-lens": F09F6,
  "google-maps": F05F5,
  "google-my-business": F1048,
  "google-nearby": F02B9,
  "google-photos": F06DD,
  "google-play": F02BC,
  "google-plus": F02BD,
  "google-podcast": F0EB9,
  "google-spreadsheet": F09F7,
  "google-street-view": F0C88,
  "google-translate": F02BF,
  "gradient": F06A0,
  "grain": F0D7C,
  "graph": F1049,
  "graph-outline": F104A,
  "graphql": F0877,
  "grass": F1510,
  "grave-stone": F0BA2,
  "grease-pencil": F0648,
  "greater-than": F096D,
  "greater-than-or-equal": F096E,
  "grid": F02C1,
  "grid-large": F0758,
  "grid-off": F02C2,
  "grill": F0E45,
  "grill-outline": F118A,
  "group": F02C3,
  "guitar-acoustic": F0771,
  "guitar-electric": F02C4,
  "guitar-pick": F02C5,
  "guitar-pick-outline": F02C6,
  "guy-fawkes-mask": F0825,
  "hail": F0AC1,
  "hair-dryer": F10EF,
  "hair-dryer-outline": F10F0,
  "halloween": F0BA3,
  "hamburger": F0685,
  "hammer": F08EA,
  "hammer-screwdriver": F1322,
  "hammer-wrench": F1323,
  "hand": F0A4F,
  "hand-heart": F10F1,
  "hand-heart-outline": F157E,
  "hand-left": F0E46,
  "hand-okay": F0A50,
  "hand-peace": F0A51,
  "hand-peace-variant": F0A52,
  "hand-pointing-down": F0A53,
  "hand-pointing-left": F0A54,
  "hand-pointing-right": F02C7,
  "hand-pointing-up": F0A55,
  "hand-right": F0E47,
  "hand-saw": F0E48,
  "hand-wash": F157F,
  "hand-wash-outline": F1580,
  "hand-water": F139F,
  "handball": F0F53,
  "handcuffs": F113E,
  "handshake": F1218,
  "handshake-outline": F15A1,
  "hanger": F02C8,
  "hard-hat": F096F,
  "harddisk": F02CA,
  "harddisk-plus": F104B,
  "harddisk-remove": F104C,
  "hat-fedora": F0BA4,
  "hazard-lights": F0C89,
  "hdr": F0D7D,
  "hdr-off": F0D7E,
  "head": F135E,
  "head-alert": F1338,
  "head-alert-outline": F1339,
  "head-check": F133A,
  "head-check-outline": F133B,
  "head-cog": F133C,
  "head-cog-outline": F133D,
  "head-dots-horizontal": F133E,
  "head-dots-horizontal-outline": F133F,
  "head-flash": F1340,
  "head-flash-outline": F1341,
  "head-heart": F1342,
  "head-heart-outline": F1343,
  "head-lightbulb": F1344,
  "head-lightbulb-outline": F1345,
  "head-minus": F1346,
  "head-minus-outline": F1347,
  "head-outline": F135F,
  "head-plus": F1348,
  "head-plus-outline": F1349,
  "head-question": F134A,
  "head-question-outline": F134B,
  "head-remove": F134C,
  "head-remove-outline": F134D,
  "head-snowflake": F134E,
  "head-snowflake-outline": F134F,
  "head-sync": F1350,
  "head-sync-outline": F1351,
  "headphones": F02CB,
  "headphones-bluetooth": F0970,
  "headphones-box": F02CC,
  "headphones-off": F07CE,
  "headphones-settings": F02CD,
  "headset": F02CE,
  "headset-dock": F02CF,
  "headset-off": F02D0,
  "heart": F02D1,
  "heart-box": F02D2,
  "heart-box-outline": F02D3,
  "heart-broken": F02D4,
  "heart-broken-outline": F0D14,
  "heart-circle": F0971,
  "heart-circle-outline": F0972,
  "heart-cog": F1663,
  "heart-cog-outline": F1664,
  "heart-flash": F0EF9,
  "heart-half": F06DF,
  "heart-half-full": F06DE,
  "heart-half-outline": F06E0,
  "heart-minus": F142F,
  "heart-minus-outline": F1432,
  "heart-multiple": F0A56,
  "heart-multiple-outline": F0A57,
  "heart-off": F0759,
  "heart-off-outline": F1434,
  "heart-outline": F02D5,
  "heart-plus": F142E,
  "heart-plus-outline": F1431,
  "heart-pulse": F05F6,
  "heart-remove": F1430,
  "heart-remove-outline": F1433,
  "heart-settings": F1665,
  "heart-settings-outline": F1666,
  "helicopter": F0AC2,
  "help": F02D6,
  "help-box": F078B,
  "help-circle": F02D7,
  "help-circle-outline": F0625,
  "help-network": F06F5,
  "help-network-outline": F0C8A,
  "help-rhombus": F0BA5,
  "help-rhombus-outline": F0BA6,
  "hexadecimal": F12A7,
  "hexagon": F02D8,
  "hexagon-multiple": F06E1,
  "hexagon-multiple-outline": F10F2,
  "hexagon-outline": F02D9,
  "hexagon-slice-1": F0AC3,
  "hexagon-slice-2": F0AC4,
  "hexagon-slice-3": F0AC5,
  "hexagon-slice-4": F0AC6,
  "hexagon-slice-5": F0AC7,
  "hexagon-slice-6": F0AC8,
  "hexagram": F0AC9,
  "hexagram-outline": F0ACA,
  "high-definition": F07CF,
  "high-definition-box": F0878,
  "highway": F05F7,
  "hiking": F0D7F,
  "hinduism": F0973,
  "history": F02DA,
  "hockey-puck": F0879,
  "hockey-sticks": F087A,
  "hololens": F02DB,
  "home": F02DC,
  "home-account": F0826,
  "home-alert": F087B,
  "home-alert-outline": F15D0,
  "home-analytics": F0EBA,
  "home-assistant": F07D0,
  "home-automation": F07D1,
  "home-circle": F07D2,
  "home-circle-outline": F104D,
  "home-city": F0D15,
  "home-city-outline": F0D16,
  "home-currency-usd": F08AF,
  "home-edit": F1159,
  "home-edit-outline": F115A,
  "home-export-outline": F0F9B,
  "home-flood": F0EFA,
  "home-floor-0": F0DD2,
  "home-floor-1": F0D80,
  "home-floor-2": F0D81,
  "home-floor-3": F0D82,
  "home-floor-a": F0D83,
  "home-floor-b": F0D84,
  "home-floor-g": F0D85,
  "home-floor-l": F0D86,
  "home-floor-negative-1": F0DD3,
  "home-group": F0DD4,
  "home-heart": F0827,
  "home-import-outline": F0F9C,
  "home-lightbulb": F1251,
  "home-lightbulb-outline": F1252,
  "home-lock": F08EB,
  "home-lock-open": F08EC,
  "home-map-marker": F05F8,
  "home-minus": F0974,
  "home-minus-outline": F13D5,
  "home-modern": F02DD,
  "home-outline": F06A1,
  "home-plus": F0975,
  "home-plus-outline": F13D6,
  "home-remove": F1247,
  "home-remove-outline": F13D7,
  "home-roof": F112B,
  "home-search": F13B0,
  "home-search-outline": F13B1,
  "home-thermometer": F0F54,
  "home-thermometer-outline": F0F55,
  "home-variant": F02DE,
  "home-variant-outline": F0BA7,
  "hook": F06E2,
  "hook-off": F06E3,
  "hops": F02DF,
  "horizontal-rotate-clockwise": F10F3,
  "horizontal-rotate-counterclockwise": F10F4,
  "horse": F15BF,
  "horse-human": F15C0,
  "horse-variant": F15C1,
  "horseshoe": F0A58,
  "hospital": F0FF6,
  "hospital-box": F02E0,
  "hospital-box-outline": F0FF7,
  "hospital-building": F02E1,
  "hospital-marker": F02E2,
  "hot-tub": F0828,
  "hours-24": F1478,
  "hubspot": F0D17,
  "hulu": F0829,
  "human": F02E6,
  "human-baby-changing-table": F138B,
  "human-cane": F1581,
  "human-capacity-decrease": F159B,
  "human-capacity-increase": F159C,
  "human-child": F02E7,
  "human-edit": F14E8,
  "human-female": F0649,
  "human-female-boy": F0A59,
  "human-female-dance": F15C9,
  "human-female-female": F0A5A,
  "human-female-girl": F0A5B,
  "human-greeting": F064A,
  "human-greeting-proximity": F159D,
  "human-handsdown": F064B,
  "human-handsup": F064C,
  "human-male": F064D,
  "human-male-boy": F0A5C,
  "human-male-child": F138C,
  "human-male-female": F02E8,
  "human-male-girl": F0A5D,
  "human-male-height": F0EFB,
  "human-male-height-variant": F0EFC,
  "human-male-male": F0A5E,
  "human-pregnant": F05CF,
  "human-queue": F1571,
  "human-scooter": F11E9,
  "human-wheelchair": F138D,
  "humble-bundle": F0744,
  "hvac": F1352,
  "hvac-off": F159E,
  "hydraulic-oil-level": F1324,
  "hydraulic-oil-temperature": F1325,
  "hydro-power": F12E5,
  "ice-cream": F082A,
  "ice-cream-off": F0E52,
  "ice-pop": F0EFD,
  "id-card": F0FC0,
  "identifier": F0EFE,
  "ideogram-cjk": F1331,
  "ideogram-cjk-variant": F1332,
  "iframe": F0C8B,
  "iframe-array": F10F5,
  "iframe-array-outline": F10F6,
  "iframe-braces": F10F7,
  "iframe-braces-outline": F10F8,
  "iframe-outline": F0C8C,
  "iframe-parentheses": F10F9,
  "iframe-parentheses-outline": F10FA,
  "iframe-variable": F10FB,
  "iframe-variable-outline": F10FC,
  "image": F02E9,
  "image-album": F02EA,
  "image-area": F02EB,
  "image-area-close": F02EC,
  "image-auto-adjust": F0FC1,
  "image-broken": F02ED,
  "image-broken-variant": F02EE,
  "image-edit": F11E3,
  "image-edit-outline": F11E4,
  "image-filter-black-white": F02F0,
  "image-filter-center-focus": F02F1,
  "image-filter-center-focus-strong": F0EFF,
  "image-filter-center-focus-strong-outline": F0F00,
  "image-filter-center-focus-weak": F02F2,
  "image-filter-drama": F02F3,
  "image-filter-frames": F02F4,
  "image-filter-hdr": F02F5,
  "image-filter-none": F02F6,
  "image-filter-tilt-shift": F02F7,
  "image-filter-vintage": F02F8,
  "image-frame": F0E49,
  "image-minus": F1419,
  "image-move": F09F8,
  "image-multiple": F02F9,
  "image-multiple-outline": F02EF,
  "image-off": F082B,
  "image-off-outline": F11D1,
  "image-outline": F0976,
  "image-plus": F087C,
  "image-remove": F1418,
  "image-search": F0977,
  "image-search-outline": F0978,
  "image-size-select-actual": F0C8D,
  "image-size-select-large": F0C8E,
  "image-size-select-small": F0C8F,
  "image-text": F160D,
  "import": F02FA,
  "inbox": F0687,
  "inbox-arrow-down": F02FB,
  "inbox-arrow-down-outline": F1270,
  "inbox-arrow-up": F03D1,
  "inbox-arrow-up-outline": F1271,
  "inbox-full": F1272,
  "inbox-full-outline": F1273,
  "inbox-multiple": F08B0,
  "inbox-multiple-outline": F0BA8,
  "inbox-outline": F1274,
  "inbox-remove": F159F,
  "inbox-remove-outline": F15A0,
  "incognito": F05F9,
  "incognito-circle": F1421,
  "incognito-circle-off": F1422,
  "incognito-off": F0075,
  "infinity": F06E4,
  "information": F02FC,
  "information-outline": F02FD,
  "information-variant": F064E,
  "instagram": F02FE,
  "instrument-triangle": F104E,
  "invert-colors": F0301,
  "invert-colors-off": F0E4A,
  "iobroker": F12E8,
  "ip": F0A5F,
  "ip-network": F0A60,
  "ip-network-outline": F0C90,
  "ipod": F0C91,
  "islam": F0979,
  "island": F104F,
  "iv-bag": F10B9,
  "jabber": F0DD5,
  "jeepney": F0302,
  "jellyfish": F0F01,
  "jellyfish-outline": F0F02,
  "jira": F0303,
  "jquery": F087D,
  "jsfiddle": F0304,
  "judaism": F097A,
  "jump-rope": F12FF,
  "kabaddi": F0D87,
  "kangaroo": F1558,
  "karate": F082C,
  "keg": F0305,
  "kettle": F05FA,
  "kettle-alert": F1317,
  "kettle-alert-outline": F1318,
  "kettle-off": F131B,
  "kettle-off-outline": F131C,
  "kettle-outline": F0F56,
  "kettle-pour-over": F173C,
  "kettle-steam": F1319,
  "kettle-steam-outline": F131A,
  "kettlebell": F1300,
  "key": F0306,
  "key-arrow-right": F1312,
  "key-chain": F1574,
  "key-chain-variant": F1575,
  "key-change": F0307,
  "key-link": F119F,
  "key-minus": F0308,
  "key-outline": F0DD6,
  "key-plus": F0309,
  "key-remove": F030A,
  "key-star": F119E,
  "key-variant": F030B,
  "key-wireless": F0FC2,
  "keyboard": F030C,
  "keyboard-backspace": F030D,
  "keyboard-caps": F030E,
  "keyboard-close": F030F,
  "keyboard-esc": F12B7,
  "keyboard-f1": F12AB,
  "keyboard-f10": F12B4,
  "keyboard-f11": F12B5,
  "keyboard-f12": F12B6,
  "keyboard-f2": F12AC,
  "keyboard-f3": F12AD,
  "keyboard-f4": F12AE,
  "keyboard-f5": F12AF,
  "keyboard-f6": F12B0,
  "keyboard-f7": F12B1,
  "keyboard-f8": F12B2,
  "keyboard-f9": F12B3,
  "keyboard-off": F0310,
  "keyboard-off-outline": F0E4B,
  "keyboard-outline": F097B,
  "keyboard-return": F0311,
  "keyboard-settings": F09F9,
  "keyboard-settings-outline": F09FA,
  "keyboard-space": F1050,
  "keyboard-tab": F0312,
  "keyboard-variant": F0313,
  "khanda": F10FD,
  "kickstarter": F0745,
  "klingon": F135B,
  "knife": F09FB,
  "knife-military": F09FC,
  "koala": F173F,
  "kodi": F0314,
  "kubernetes": F10FE,
  "label": F0315,
  "label-multiple": F1375,
  "label-multiple-outline": F1376,
  "label-off": F0ACB,
  "label-off-outline": F0ACC,
  "label-outline": F0316,
  "label-percent": F12EA,
  "label-percent-outline": F12EB,
  "label-variant": F0ACD,
  "label-variant-outline": F0ACE,
  "ladder": F15A2,
  "ladybug": F082D,
  "lambda": F0627,
  "lamp": F06B5,
  "lamps": F1576,
  "lan": F0317,
  "lan-check": F12AA,
  "lan-connect": F0318,
  "lan-disconnect": F0319,
  "lan-pending": F031A,
  "language-c": F0671,
  "language-cpp": F0672,
  "language-csharp": F031B,
  "language-css3": F031C,
  "language-fortran": F121A,
  "language-go": F07D3,
  "language-haskell": F0C92,
  "language-html5": F031D,
  "language-java": F0B37,
  "language-javascript": F031E,
  "language-kotlin": F1219,
  "language-lua": F08B1,
  "language-markdown": F0354,
  "language-markdown-outline": F0F5B,
  "language-php": F031F,
  "language-python": F0320,
  "language-r": F07D4,
  "language-ruby": F0D2D,
  "language-ruby-on-rails": F0ACF,
  "language-rust": F1617,
  "language-swift": F06E5,
  "language-typescript": F06E6,
  "language-xaml": F0673,
  "laptop": F0322,
  "laptop-chromebook": F0323,
  "laptop-mac": F0324,
  "laptop-off": F06E7,
  "laptop-windows": F0325,
  "laravel": F0AD0,
  "laser-pointer": F1484,
  "lasso": F0F03,
  "lastpass": F0446,
  "latitude": F0F57,
  "launch": F0327,
  "lava-lamp": F07D5,
  "layers": F0328,
  "layers-minus": F0E4C,
  "layers-off": F0329,
  "layers-off-outline": F09FD,
  "layers-outline": F09FE,
  "layers-plus": F0E4D,
  "layers-remove": F0E4E,
  "layers-search": F1206,
  "layers-search-outline": F1207,
  "layers-triple": F0F58,
  "layers-triple-outline": F0F59,
  "lead-pencil": F064F,
  "leaf": F032A,
  "leaf-maple": F0C93,
  "leaf-maple-off": F12DA,
  "leaf-off": F12D9,
  "leak": F0DD7,
  "leak-off": F0DD8,
  "led-off": F032B,
  "led-on": F032C,
  "led-outline": F032D,
  "led-strip": F07D6,
  "led-strip-variant": F1051,
  "led-variant-off": F032E,
  "led-variant-on": F032F,
  "led-variant-outline": F0330,
  "leek": F117D,
  "less-than": F097C,
  "less-than-or-equal": F097D,
  "library": F0331,
  "library-shelves": F0BA9,
  "license": F0FC3,
  "lifebuoy": F087E,
  "light-switch": F097E,
  "lightbulb": F0335,
  "lightbulb-cfl": F1208,
  "lightbulb-cfl-off": F1209,
  "lightbulb-cfl-spiral": F1275,
  "lightbulb-cfl-spiral-off": F12C3,
  "lightbulb-group": F1253,
  "lightbulb-group-off": F12CD,
  "lightbulb-group-off-outline": F12CE,
  "lightbulb-group-outline": F1254,
  "lightbulb-multiple": F1255,
  "lightbulb-multiple-off": F12CF,
  "lightbulb-multiple-off-outline": F12D0,
  "lightbulb-multiple-outline": F1256,
  "lightbulb-off": F0E4F,
  "lightbulb-off-outline": F0E50,
  "lightbulb-on": F06E8,
  "lightbulb-on-outline": F06E9,
  "lightbulb-outline": F0336,
  "lighthouse": F09FF,
  "lighthouse-on": F0A00,
  "lightning-bolt": F140B,
  "lightning-bolt-outline": F140C,
  "lingerie": F1476,
  "link": F0337,
  "link-box": F0D1A,
  "link-box-outline": F0D1B,
  "link-box-variant": F0D1C,
  "link-box-variant-outline": F0D1D,
  "link-lock": F10BA,
  "link-off": F0338,
  "link-plus": F0C94,
  "link-variant": F0339,
  "link-variant-minus": F10FF,
  "link-variant-off": F033A,
  "link-variant-plus": F1100,
  "link-variant-remove": F1101,
  "linkedin": F033B,
  "linux": F033D,
  "linux-mint": F08ED,
  "lipstick": F13B5,
  "list-status": F15AB,
  "litecoin": F0A61,
  "loading": F0772,
  "location-enter": F0FC4,
  "location-exit": F0FC5,
  "lock": F033E,
  "lock-alert": F08EE,
  "lock-alert-outline": F15D1,
  "lock-check": F139A,
  "lock-check-outline": F16A8,
  "lock-clock": F097F,
  "lock-minus": F16A9,
  "lock-minus-outline": F16AA,
  "lock-off": F1671,
  "lock-off-outline": F1672,
  "lock-open": F033F,
  "lock-open-alert": F139B,
  "lock-open-alert-outline": F15D2,
  "lock-open-check": F139C,
  "lock-open-check-outline": F16AB,
  "lock-open-minus": F16AC,
  "lock-open-minus-outline": F16AD,
  "lock-open-outline": F0340,
  "lock-open-plus": F16AE,
  "lock-open-plus-outline": F16AF,
  "lock-open-remove": F16B0,
  "lock-open-remove-outline": F16B1,
  "lock-open-variant": F0FC6,
  "lock-open-variant-outline": F0FC7,
  "lock-outline": F0341,
  "lock-pattern": F06EA,
  "lock-plus": F05FB,
  "lock-plus-outline": F16B2,
  "lock-question": F08EF,
  "lock-remove": F16B3,
  "lock-remove-outline": F16B4,
  "lock-reset": F0773,
  "lock-smart": F08B2,
  "locker": F07D7,
  "locker-multiple": F07D8,
  "login": F0342,
  "login-variant": F05FC,
  "logout": F0343,
  "logout-variant": F05FD,
  "longitude": F0F5A,
  "looks": F0344,
  "lotion": F1582,
  "lotion-outline": F1583,
  "lotion-plus": F1584,
  "lotion-plus-outline": F1585,
  "loupe": F0345,
  "lumx": F0346,
  "lungs": F1084,
  "magnet": F0347,
  "magnet-on": F0348,
  "magnify": F0349,
  "magnify-close": F0980,
  "magnify-minus": F034A,
  "magnify-minus-cursor": F0A62,
  "magnify-minus-outline": F06EC,
  "magnify-plus": F034B,
  "magnify-plus-cursor": F0A63,
  "magnify-plus-outline": F06ED,
  "magnify-remove-cursor": F120C,
  "magnify-remove-outline": F120D,
  "magnify-scan": F1276,
  "mail": F0EBB,
  "mailbox": F06EE,
  "mailbox-open": F0D88,
  "mailbox-open-outline": F0D89,
  "mailbox-open-up": F0D8A,
  "mailbox-open-up-outline": F0D8B,
  "mailbox-outline": F0D8C,
  "mailbox-up": F0D8D,
  "mailbox-up-outline": F0D8E,
  "manjaro": F160A,
  "map": F034D,
  "map-check": F0EBC,
  "map-check-outline": F0EBD,
  "map-clock": F0D1E,
  "map-clock-outline": F0D1F,
  "map-legend": F0A01,
  "map-marker": F034E,
  "map-marker-alert": F0F05,
  "map-marker-alert-outline": F0F06,
  "map-marker-check": F0C95,
  "map-marker-check-outline": F12FB,
  "map-marker-circle": F034F,
  "map-marker-distance": F08F0,
  "map-marker-down": F1102,
  "map-marker-left": F12DB,
  "map-marker-left-outline": F12DD,
  "map-marker-minus": F0650,
  "map-marker-minus-outline": F12F9,
  "map-marker-multiple": F0350,
  "map-marker-multiple-outline": F1277,
  "map-marker-off": F0351,
  "map-marker-off-outline": F12FD,
  "map-marker-outline": F07D9,
  "map-marker-path": F0D20,
  "map-marker-plus": F0651,
  "map-marker-plus-outline": F12F8,
  "map-marker-question": F0F07,
  "map-marker-question-outline": F0F08,
  "map-marker-radius": F0352,
  "map-marker-radius-outline": F12FC,
  "map-marker-remove": F0F09,
  "map-marker-remove-outline": F12FA,
  "map-marker-remove-variant": F0F0A,
  "map-marker-right": F12DC,
  "map-marker-right-outline": F12DE,
  "map-marker-star": F1608,
  "map-marker-star-outline": F1609,
  "map-marker-up": F1103,
  "map-minus": F0981,
  "map-outline": F0982,
  "map-plus": F0983,
  "map-search": F0984,
  "map-search-outline": F0985,
  "mapbox": F0BAA,
  "margin": F0353,
  "marker": F0652,
  "marker-cancel": F0DD9,
  "marker-check": F0355,
  "mastodon": F0AD1,
  "material-design": F0986,
  "material-ui": F0357,
  "math-compass": F0358,
  "math-cos": F0C96,
  "math-integral": F0FC8,
  "math-integral-box": F0FC9,
  "math-log": F1085,
  "math-norm": F0FCA,
  "math-norm-box": F0FCB,
  "math-sin": F0C97,
  "math-tan": F0C98,
  "matrix": F0628,
  "medal": F0987,
  "medal-outline": F1326,
  "medical-bag": F06EF,
  "meditation": F117B,
  "memory": F035B,
  "menu": F035C,
  "menu-down": F035D,
  "menu-down-outline": F06B6,
  "menu-left": F035E,
  "menu-left-outline": F0A02,
  "menu-open": F0BAB,
  "menu-right": F035F,
  "menu-right-outline": F0A03,
  "menu-swap": F0A64,
  "menu-swap-outline": F0A65,
  "menu-up": F0360,
  "menu-up-outline": F06B7,
  "merge": F0F5C,
  "message": F0361,
  "message-alert": F0362,
  "message-alert-outline": F0A04,
  "message-arrow-left": F12F2,
  "message-arrow-left-outline": F12F3,
  "message-arrow-right": F12F4,
  "message-arrow-right-outline": F12F5,
  "message-bookmark": F15AC,
  "message-bookmark-outline": F15AD,
  "message-bulleted": F06A2,
  "message-bulleted-off": F06A3,
  "message-cog": F06F1,
  "message-cog-outline": F1172,
  "message-draw": F0363,
  "message-flash": F15A9,
  "message-flash-outline": F15AA,
  "message-image": F0364,
  "message-image-outline": F116C,
  "message-lock": F0FCC,
  "message-lock-outline": F116D,
  "message-minus": F116E,
  "message-minus-outline": F116F,
  "message-off": F164D,
  "message-off-outline": F164E,
  "message-outline": F0365,
  "message-plus": F0653,
  "message-plus-outline": F10BB,
  "message-processing": F0366,
  "message-processing-outline": F1170,
  "message-question": F173A,
  "message-question-outline": F173B,
  "message-reply": F0367,
  "message-reply-outline": F173D,
  "message-reply-text": F0368,
  "message-reply-text-outline": F173E,
  "message-settings": F06F0,
  "message-settings-outline": F1171,
  "message-text": F0369,
  "message-text-clock": F1173,
  "message-text-clock-outline": F1174,
  "message-text-lock": F0FCD,
  "message-text-lock-outline": F1175,
  "message-text-outline": F036A,
  "message-video": F036B,
  "meteor": F0629,
  "metronome": F07DA,
  "metronome-tick": F07DB,
  "micro-sd": F07DC,
  "microphone": F036C,
  "microphone-minus": F08B3,
  "microphone-off": F036D,
  "microphone-outline": F036E,
  "microphone-plus": F08B4,
  "microphone-settings": F036F,
  "microphone-variant": F0370,
  "microphone-variant-off": F0371,
  "microscope": F0654,
  "microsoft": F0372,
  "microsoft-access": F138E,
  "microsoft-azure": F0805,
  "microsoft-azure-devops": F0FD5,
  "microsoft-bing": F00A4,
  "microsoft-dynamics-365": F0988,
  "microsoft-edge": F01E9,
  "microsoft-edge-legacy": F1250,
  "microsoft-excel": F138F,
  "microsoft-internet-explorer": F0300,
  "microsoft-office": F03C6,
  "microsoft-onedrive": F03CA,
  "microsoft-onenote": F0747,
  "microsoft-outlook": F0D22,
  "microsoft-powerpoint": F1390,
  "microsoft-sharepoint": F1391,
  "microsoft-teams": F02BB,
  "microsoft-visual-studio": F0610,
  "microsoft-visual-studio-code": F0A1E,
  "microsoft-windows": F05B3,
  "microsoft-windows-classic": F0A21,
  "microsoft-word": F1392,
  "microsoft-xbox": F05B9,
  "microsoft-xbox-controller": F05BA,
  "microsoft-xbox-controller-battery-alert": F074B,
  "microsoft-xbox-controller-battery-charging": F0A22,
  "microsoft-xbox-controller-battery-empty": F074C,
  "microsoft-xbox-controller-battery-full": F074D,
  "microsoft-xbox-controller-battery-low": F074E,
  "microsoft-xbox-controller-battery-medium": F074F,
  "microsoft-xbox-controller-battery-unknown": F0750,
  "microsoft-xbox-controller-menu": F0E6F,
  "microsoft-xbox-controller-off": F05BB,
  "microsoft-xbox-controller-view": F0E70,
  "microsoft-yammer": F0789,
  "microwave": F0C99,
  "microwave-off": F1423,
  "middleware": F0F5D,
  "middleware-outline": F0F5E,
  "midi": F08F1,
  "midi-port": F08F2,
  "mine": F0DDA,
  "minecraft": F0373,
  "mini-sd": F0A05,
  "minidisc": F0A06,
  "minus": F0374,
  "minus-box": F0375,
  "minus-box-multiple": F1141,
  "minus-box-multiple-outline": F1142,
  "minus-box-outline": F06F2,
  "minus-circle": F0376,
  "minus-circle-multiple": F035A,
  "minus-circle-multiple-outline": F0AD3,
  "minus-circle-off": F1459,
  "minus-circle-off-outline": F145A,
  "minus-circle-outline": F0377,
  "minus-network": F0378,
  "minus-network-outline": F0C9A,
  "minus-thick": F1639,
  "mirror": F11FD,
  "mixed-martial-arts": F0D8F,
  "mixed-reality": F087F,
  "molecule": F0BAC,
  "molecule-co": F12FE,
  "molecule-co2": F07E4,
  "monitor": F0379,
  "monitor-cellphone": F0989,
  "monitor-cellphone-star": F098A,
  "monitor-clean": F1104,
  "monitor-dashboard": F0A07,
  "monitor-edit": F12C6,
  "monitor-eye": F13B4,
  "monitor-lock": F0DDB,
  "monitor-multiple": F037A,
  "monitor-off": F0D90,
  "monitor-screenshot": F0E51,
  "monitor-share": F1483,
  "monitor-speaker": F0F5F,
  "monitor-speaker-off": F0F60,
  "monitor-star": F0DDC,
  "moon-first-quarter": F0F61,
  "moon-full": F0F62,
  "moon-last-quarter": F0F63,
  "moon-new": F0F64,
  "moon-waning-crescent": F0F65,
  "moon-waning-gibbous": F0F66,
  "moon-waxing-crescent": F0F67,
  "moon-waxing-gibbous": F0F68,
  "moped": F1086,
  "moped-electric": F15B7,
  "moped-electric-outline": F15B8,
  "moped-outline": F15B9,
  "more": F037B,
  "mother-heart": F1314,
  "mother-nurse": F0D21,
  "motion": F15B2,
  "motion-outline": F15B3,
  "motion-pause": F1590,
  "motion-pause-outline": F1592,
  "motion-play": F158F,
  "motion-play-outline": F1591,
  "motion-sensor": F0D91,
  "motion-sensor-off": F1435,
  "motorbike": F037C,
  "motorbike-electric": F15BA,
  "mouse": F037D,
  "mouse-bluetooth": F098B,
  "mouse-move-down": F1550,
  "mouse-move-up": F1551,
  "mouse-move-vertical": F1552,
  "mouse-off": F037E,
  "mouse-variant": F037F,
  "mouse-variant-off": F0380,
  "move-resize": F0655,
  "move-resize-variant": F0656,
  "movie": F0381,
  "movie-check": F16F3,
  "movie-check-outline": F16F4,
  "movie-cog": F16F5,
  "movie-cog-outline": F16F6,
  "movie-edit": F1122,
  "movie-edit-outline": F1123,
  "movie-filter": F1124,
  "movie-filter-outline": F1125,
  "movie-minus": F16F7,
  "movie-minus-outline": F16F8,
  "movie-off": F16F9,
  "movie-off-outline": F16FA,
  "movie-open": F0FCE,
  "movie-open-check": F16FB,
  "movie-open-check-outline": F16FC,
  "movie-open-cog": F16FD,
  "movie-open-cog-outline": F16FE,
  "movie-open-edit": F16FF,
  "movie-open-edit-outline": F1700,
  "movie-open-minus": F1701,
  "movie-open-minus-outline": F1702,
  "movie-open-off": F1703,
  "movie-open-off-outline": F1704,
  "movie-open-outline": F0FCF,
  "movie-open-play": F1705,
  "movie-open-play-outline": F1706,
  "movie-open-plus": F1707,
  "movie-open-plus-outline": F1708,
  "movie-open-remove": F1709,
  "movie-open-remove-outline": F170A,
  "movie-open-settings": F170B,
  "movie-open-settings-outline": F170C,
  "movie-open-star": F170D,
  "movie-open-star-outline": F170E,
  "movie-outline": F0DDD,
  "movie-play": F170F,
  "movie-play-outline": F1710,
  "movie-plus": F1711,
  "movie-plus-outline": F1712,
  "movie-remove": F1713,
  "movie-remove-outline": F1714,
  "movie-roll": F07DE,
  "movie-search": F11D2,
  "movie-search-outline": F11D3,
  "movie-settings": F1715,
  "movie-settings-outline": F1716,
  "movie-star": F1717,
  "movie-star-outline": F1718,
  "mower": F166F,
  "mower-bag": F1670,
  "muffin": F098C,
  "multiplication": F0382,
  "multiplication-box": F0383,
  "mushroom": F07DF,
  "mushroom-off": F13FA,
  "mushroom-off-outline": F13FB,
  "mushroom-outline": F07E0,
  "music": F075A,
  "music-accidental-double-flat": F0F69,
  "music-accidental-double-sharp": F0F6A,
  "music-accidental-flat": F0F6B,
  "music-accidental-natural": F0F6C,
  "music-accidental-sharp": F0F6D,
  "music-box": F0384,
  "music-box-multiple": F0333,
  "music-box-multiple-outline": F0F04,
  "music-box-outline": F0385,
  "music-circle": F0386,
  "music-circle-outline": F0AD4,
  "music-clef-alto": F0F6E,
  "music-clef-bass": F0F6F,
  "music-clef-treble": F0F70,
  "music-note": F0387,
  "music-note-bluetooth": F05FE,
  "music-note-bluetooth-off": F05FF,
  "music-note-eighth": F0388,
  "music-note-eighth-dotted": F0F71,
  "music-note-half": F0389,
  "music-note-half-dotted": F0F72,
  "music-note-off": F038A,
  "music-note-off-outline": F0F73,
  "music-note-outline": F0F74,
  "music-note-plus": F0DDE,
  "music-note-quarter": F038B,
  "music-note-quarter-dotted": F0F75,
  "music-note-sixteenth": F038C,
  "music-note-sixteenth-dotted": F0F76,
  "music-note-whole": F038D,
  "music-note-whole-dotted": F0F77,
  "music-off": F075B,
  "music-rest-eighth": F0F78,
  "music-rest-half": F0F79,
  "music-rest-quarter": F0F7A,
  "music-rest-sixteenth": F0F7B,
  "music-rest-whole": F0F7C,
  "mustache": F15DE,
  "nail": F0DDF,
  "nas": F08F3,
  "nativescript": F0880,
  "nature": F038E,
  "nature-people": F038F,
  "navigation": F0390,
  "navigation-outline": F1607,
  "near-me": F05CD,
  "necklace": F0F0B,
  "needle": F0391,
  "netflix": F0746,
  "network": F06F3,
  "network-off": F0C9B,
  "network-off-outline": F0C9C,
  "network-outline": F0C9D,
  "network-strength-1": F08F4,
  "network-strength-1-alert": F08F5,
  "network-strength-2": F08F6,
  "network-strength-2-alert": F08F7,
  "network-strength-3": F08F8,
  "network-strength-3-alert": F08F9,
  "network-strength-4": F08FA,
  "network-strength-4-alert": F08FB,
  "network-strength-off": F08FC,
  "network-strength-off-outline": F08FD,
  "network-strength-outline": F08FE,
  "new-box": F0394,
  "newspaper": F0395,
  "newspaper-minus": F0F0C,
  "newspaper-plus": F0F0D,
  "newspaper-variant": F1001,
  "newspaper-variant-multiple": F1002,
  "newspaper-variant-multiple-outline": F1003,
  "newspaper-variant-outline": F1004,
  "nfc": F0396,
  "nfc-search-variant": F0E53,
  "nfc-tap": F0397,
  "nfc-variant": F0398,
  "nfc-variant-off": F0E54,
  "ninja": F0774,
  "nintendo-game-boy": F1393,
  "nintendo-switch": F07E1,
  "nintendo-wii": F05AB,
  "nintendo-wiiu": F072D,
  "nix": F1105,
  "nodejs": F0399,
  "noodles": F117E,
  "not-equal": F098D,
  "not-equal-variant": F098E,
  "note": F039A,
  "note-minus": F164F,
  "note-minus-outline": F1650,
  "note-multiple": F06B8,
  "note-multiple-outline": F06B9,
  "note-outline": F039B,
  "note-plus": F039C,
  "note-plus-outline": F039D,
  "note-remove": F1651,
  "note-remove-outline": F1652,
  "note-search": F1653,
  "note-search-outline": F1654,
  "note-text": F039E,
  "note-text-outline": F11D7,
  "notebook": F082E,
  "notebook-check": F14F5,
  "notebook-check-outline": F14F6,
  "notebook-edit": F14E7,
  "notebook-edit-outline": F14E9,
  "notebook-minus": F1610,
  "notebook-minus-outline": F1611,
  "notebook-multiple": F0E55,
  "notebook-outline": F0EBF,
  "notebook-plus": F1612,
  "notebook-plus-outline": F1613,
  "notebook-remove": F1614,
  "notebook-remove-outline": F1615,
  "notification-clear-all": F039F,
  "npm": F06F7,
  "nuke": F06A4,
  "null": F07E2,
  "numeric": F03A0,
  "numeric-0": F0B39,
  "numeric-0-box": F03A1,
  "numeric-0-box-multiple": F0F0E,
  "numeric-0-box-multiple-outline": F03A2,
  "numeric-0-box-outline": F03A3,
  "numeric-0-circle": F0C9E,
  "numeric-0-circle-outline": F0C9F,
  "numeric-1": F0B3A,
  "numeric-1-box": F03A4,
  "numeric-1-box-multiple": F0F0F,
  "numeric-1-box-multiple-outline": F03A5,
  "numeric-1-box-outline": F03A6,
  "numeric-1-circle": F0CA0,
  "numeric-1-circle-outline": F0CA1,
  "numeric-10": F0FE9,
  "numeric-10-box": F0F7D,
  "numeric-10-box-multiple": F0FEA,
  "numeric-10-box-multiple-outline": F0FEB,
  "numeric-10-box-outline": F0F7E,
  "numeric-10-circle": F0FEC,
  "numeric-10-circle-outline": F0FED,
  "numeric-2": F0B3B,
  "numeric-2-box": F03A7,
  "numeric-2-box-multiple": F0F10,
  "numeric-2-box-multiple-outline": F03A8,
  "numeric-2-box-outline": F03A9,
  "numeric-2-circle": F0CA2,
  "numeric-2-circle-outline": F0CA3,
  "numeric-3": F0B3C,
  "numeric-3-box": F03AA,
  "numeric-3-box-multiple": F0F11,
  "numeric-3-box-multiple-outline": F03AB,
  "numeric-3-box-outline": F03AC,
  "numeric-3-circle": F0CA4,
  "numeric-3-circle-outline": F0CA5,
  "numeric-4": F0B3D,
  "numeric-4-box": F03AD,
  "numeric-4-box-multiple": F0F12,
  "numeric-4-box-multiple-outline": F03B2,
  "numeric-4-box-outline": F03AE,
  "numeric-4-circle": F0CA6,
  "numeric-4-circle-outline": F0CA7,
  "numeric-5": F0B3E,
  "numeric-5-box": F03B1,
  "numeric-5-box-multiple": F0F13,
  "numeric-5-box-multiple-outline": F03AF,
  "numeric-5-box-outline": F03B0,
  "numeric-5-circle": F0CA8,
  "numeric-5-circle-outline": F0CA9,
  "numeric-6": F0B3F,
  "numeric-6-box": F03B3,
  "numeric-6-box-multiple": F0F14,
  "numeric-6-box-multiple-outline": F03B4,
  "numeric-6-box-outline": F03B5,
  "numeric-6-circle": F0CAA,
  "numeric-6-circle-outline": F0CAB,
  "numeric-7": F0B40,
  "numeric-7-box": F03B6,
  "numeric-7-box-multiple": F0F15,
  "numeric-7-box-multiple-outline": F03B7,
  "numeric-7-box-outline": F03B8,
  "numeric-7-circle": F0CAC,
  "numeric-7-circle-outline": F0CAD,
  "numeric-8": F0B41,
  "numeric-8-box": F03B9,
  "numeric-8-box-multiple": F0F16,
  "numeric-8-box-multiple-outline": F03BA,
  "numeric-8-box-outline": F03BB,
  "numeric-8-circle": F0CAE,
  "numeric-8-circle-outline": F0CAF,
  "numeric-9": F0B42,
  "numeric-9-box": F03BC,
  "numeric-9-box-multiple": F0F17,
  "numeric-9-box-multiple-outline": F03BD,
  "numeric-9-box-outline": F03BE,
  "numeric-9-circle": F0CB0,
  "numeric-9-circle-outline": F0CB1,
  "numeric-9-plus": F0FEE,
  "numeric-9-plus-box": F03BF,
  "numeric-9-plus-box-multiple": F0F18,
  "numeric-9-plus-box-multiple-outline": F03C0,
  "numeric-9-plus-box-outline": F03C1,
  "numeric-9-plus-circle": F0CB2,
  "numeric-9-plus-circle-outline": F0CB3,
  "numeric-negative-1": F1052,
  "numeric-positive-1": F15CB,
  "nut": F06F8,
  "nutrition": F03C2,
  "nuxt": F1106,
  "oar": F067C,
  "ocarina": F0DE0,
  "oci": F12E9,
  "ocr": F113A,
  "octagon": F03C3,
  "octagon-outline": F03C4,
  "octagram": F06F9,
  "octagram-outline": F0775,
  "odnoklassniki": F03C5,
  "offer": F121B,
  "office-building": F0991,
  "office-building-marker": F1520,
  "office-building-marker-outline": F1521,
  "office-building-outline": F151F,
  "oil": F03C7,
  "oil-lamp": F0F19,
  "oil-level": F1053,
  "oil-temperature": F0FF8,
  "omega": F03C9,
  "one-up": F0BAD,
  "onepassword": F0881,
  "opacity": F05CC,
  "open-in-app": F03CB,
  "open-in-new": F03CC,
  "open-source-initiative": F0BAE,
  "openid": F03CD,
  "opera": F03CE,
  "orbit": F0018,
  "orbit-variant": F15DB,
  "order-alphabetical-ascending": F020D,
  "order-alphabetical-descending": F0D07,
  "order-bool-ascending": F02BE,
  "order-bool-ascending-variant": F098F,
  "order-bool-descending": F1384,
  "order-bool-descending-variant": F0990,
  "order-numeric-ascending": F0545,
  "order-numeric-descending": F0546,
  "origin": F0B43,
  "ornament": F03CF,
  "ornament-variant": F03D0,
  "outdoor-lamp": F1054,
  "overscan": F1005,
  "owl": F03D2,
  "pac-man": F0BAF,
  "package": F03D3,
  "package-down": F03D4,
  "package-up": F03D5,
  "package-variant": F03D6,
  "package-variant-closed": F03D7,
  "page-first": F0600,
  "page-last": F0601,
  "page-layout-body": F06FA,
  "page-layout-footer": F06FB,
  "page-layout-header": F06FC,
  "page-layout-header-footer": F0F7F,
  "page-layout-sidebar-left": F06FD,
  "page-layout-sidebar-right": F06FE,
  "page-next": F0BB0,
  "page-next-outline": F0BB1,
  "page-previous": F0BB2,
  "page-previous-outline": F0BB3,
  "pail": F1417,
  "pail-minus": F1437,
  "pail-minus-outline": F143C,
  "pail-off": F1439,
  "pail-off-outline": F143E,
  "pail-outline": F143A,
  "pail-plus": F1436,
  "pail-plus-outline": F143B,
  "pail-remove": F1438,
  "pail-remove-outline": F143D,
  "palette": F03D8,
  "palette-advanced": F03D9,
  "palette-outline": F0E0C,
  "palette-swatch": F08B5,
  "palette-swatch-outline": F135C,
  "palm-tree": F1055,
  "pan": F0BB4,
  "pan-bottom-left": F0BB5,
  "pan-bottom-right": F0BB6,
  "pan-down": F0BB7,
  "pan-horizontal": F0BB8,
  "pan-left": F0BB9,
  "pan-right": F0BBA,
  "pan-top-left": F0BBB,
  "pan-top-right": F0BBC,
  "pan-up": F0BBD,
  "pan-vertical": F0BBE,
  "panda": F03DA,
  "pandora": F03DB,
  "panorama": F03DC,
  "panorama-fisheye": F03DD,
  "panorama-horizontal": F03DE,
  "panorama-vertical": F03DF,
  "panorama-wide-angle": F03E0,
  "paper-cut-vertical": F03E1,
  "paper-roll": F1157,
  "paper-roll-outline": F1158,
  "paperclip": F03E2,
  "parachute": F0CB4,
  "parachute-outline": F0CB5,
  "parking": F03E3,
  "party-popper": F1056,
  "passport": F07E3,
  "passport-biometric": F0DE1,
  "pasta": F1160,
  "patio-heater": F0F80,
  "patreon": F0882,
  "pause": F03E4,
  "pause-circle": F03E5,
  "pause-circle-outline": F03E6,
  "pause-octagon": F03E7,
  "pause-octagon-outline": F03E8,
  "paw": F03E9,
  "paw-off": F0657,
  "paw-off-outline": F1676,
  "paw-outline": F1675,
  "pdf-box": F0E56,
  "peace": F0884,
  "peanut": F0FFC,
  "peanut-off": F0FFD,
  "peanut-off-outline": F0FFF,
  "peanut-outline": F0FFE,
  "pen": F03EA,
  "pen-lock": F0DE2,
  "pen-minus": F0DE3,
  "pen-off": F0DE4,
  "pen-plus": F0DE5,
  "pen-remove": F0DE6,
  "pencil": F03EB,
  "pencil-box": F03EC,
  "pencil-box-multiple": F1144,
  "pencil-box-multiple-outline": F1145,
  "pencil-box-outline": F03ED,
  "pencil-circle": F06FF,
  "pencil-circle-outline": F0776,
  "pencil-lock": F03EE,
  "pencil-lock-outline": F0DE7,
  "pencil-minus": F0DE8,
  "pencil-minus-outline": F0DE9,
  "pencil-off": F03EF,
  "pencil-off-outline": F0DEA,
  "pencil-outline": F0CB6,
  "pencil-plus": F0DEB,
  "pencil-plus-outline": F0DEC,
  "pencil-remove": F0DED,
  "pencil-remove-outline": F0DEE,
  "pencil-ruler": F1353,
  "penguin": F0EC0,
  "pentagon": F0701,
  "pentagon-outline": F0700,
  "pentagram": F1667,
  "percent": F03F0,
  "percent-outline": F1278,
  "periodic-table": F08B6,
  "perspective-less": F0D23,
  "perspective-more": F0D24,
  "pharmacy": F03F1,
  "phone": F03F2,
  "phone-alert": F0F1A,
  "phone-alert-outline": F118E,
  "phone-bluetooth": F03F3,
  "phone-bluetooth-outline": F118F,
  "phone-cancel": F10BC,
  "phone-cancel-outline": F1190,
  "phone-check": F11A9,
  "phone-check-outline": F11AA,
  "phone-classic": F0602,
  "phone-classic-off": F1279,
  "phone-dial": F1559,
  "phone-dial-outline": F155A,
  "phone-forward": F03F4,
  "phone-forward-outline": F1191,
  "phone-hangup": F03F5,
  "phone-hangup-outline": F1192,
  "phone-in-talk": F03F6,
  "phone-in-talk-outline": F1182,
  "phone-incoming": F03F7,
  "phone-incoming-outline": F1193,
  "phone-lock": F03F8,
  "phone-lock-outline": F1194,
  "phone-log": F03F9,
  "phone-log-outline": F1195,
  "phone-message": F1196,
  "phone-message-outline": F1197,
  "phone-minus": F0658,
  "phone-minus-outline": F1198,
  "phone-missed": F03FA,
  "phone-missed-outline": F11A5,
  "phone-off": F0DEF,
  "phone-off-outline": F11A6,
  "phone-outgoing": F03FB,
  "phone-outgoing-outline": F1199,
  "phone-outline": F0DF0,
  "phone-paused": F03FC,
  "phone-paused-outline": F119A,
  "phone-plus": F0659,
  "phone-plus-outline": F119B,
  "phone-remove": F152F,
  "phone-remove-outline": F1530,
  "phone-return": F082F,
  "phone-return-outline": F119C,
  "phone-ring": F11AB,
  "phone-ring-outline": F11AC,
  "phone-rotate-landscape": F0885,
  "phone-rotate-portrait": F0886,
  "phone-settings": F03FD,
  "phone-settings-outline": F119D,
  "phone-voip": F03FE,
  "pi": F03FF,
  "pi-box": F0400,
  "pi-hole": F0DF1,
  "piano": F067D,
  "pickaxe": F08B7,
  "picture-in-picture-bottom-right": F0E57,
  "picture-in-picture-bottom-right-outline": F0E58,
  "picture-in-picture-top-right": F0E59,
  "picture-in-picture-top-right-outline": F0E5A,
  "pier": F0887,
  "pier-crane": F0888,
  "pig": F0401,
  "pig-variant": F1006,
  "pig-variant-outline": F1678,
  "piggy-bank": F1007,
  "piggy-bank-outline": F1679,
  "pill": F0402,
  "pillar": F0702,
  "pin": F0403,
  "pin-off": F0404,
  "pin-off-outline": F0930,
  "pin-outline": F0931,
  "pine-tree": F0405,
  "pine-tree-box": F0406,
  "pine-tree-fire": F141A,
  "pinterest": F0407,
  "pinwheel": F0AD5,
  "pinwheel-outline": F0AD6,
  "pipe": F07E5,
  "pipe-disconnected": F07E6,
  "pipe-leak": F0889,
  "pipe-wrench": F1354,
  "pirate": F0A08,
  "pistol": F0703,
  "piston": F088A,
  "pitchfork": F1553,
  "pizza": F0409,
  "play": F040A,
  "play-box": F127A,
  "play-box-multiple": F0D19,
  "play-box-multiple-outline": F13E6,
  "play-box-outline": F040B,
  "play-circle": F040C,
  "play-circle-outline": F040D,
  "play-network": F088B,
  "play-network-outline": F0CB7,
  "play-outline": F0F1B,
  "play-pause": F040E,
  "play-protected-content": F040F,
  "play-speed": F08FF,
  "playlist-check": F05C7,
  "playlist-edit": F0900,
  "playlist-minus": F0410,
  "playlist-music": F0CB8,
  "playlist-music-outline": F0CB9,
  "playlist-play": F0411,
  "playlist-plus": F0412,
  "playlist-remove": F0413,
  "playlist-star": F0DF2,
  "plex": F06BA,
  "plus": F0415,
  "plus-box": F0416,
  "plus-box-multiple": F0334,
  "plus-box-multiple-outline": F1143,
  "plus-box-outline": F0704,
  "plus-circle": F0417,
  "plus-circle-multiple": F034C,
  "plus-circle-multiple-outline": F0418,
  "plus-circle-outline": F0419,
  "plus-minus": F0992,
  "plus-minus-box": F0993,
  "plus-minus-variant": F14C9,
  "plus-network": F041A,
  "plus-network-outline": F0CBA,
  "plus-one": F041B,
  "plus-outline": F0705,
  "plus-thick": F11EC,
  "podcast": F0994,
  "podium": F0D25,
  "podium-bronze": F0D26,
  "podium-gold": F0D27,
  "podium-silver": F0D28,
  "point-of-sale": F0D92,
  "pokeball": F041D,
  "pokemon-go": F0A09,
  "poker-chip": F0830,
  "polaroid": F041E,
  "police-badge": F1167,
  "police-badge-outline": F1168,
  "poll": F041F,
  "poll-box": F0420,
  "poll-box-outline": F127B,
  "polo": F14C3,
  "polymer": F0421,
  "pool": F0606,
  "popcorn": F0422,
  "post": F1008,
  "post-outline": F1009,
  "postage-stamp": F0CBB,
  "pot": F02E5,
  "pot-mix": F065B,
  "pot-mix-outline": F0677,
  "pot-outline": F02FF,
  "pot-steam": F065A,
  "pot-steam-outline": F0326,
  "pound": F0423,
  "pound-box": F0424,
  "pound-box-outline": F117F,
  "power": F0425,
  "power-cycle": F0901,
  "power-off": F0902,
  "power-on": F0903,
  "power-plug": F06A5,
  "power-plug-off": F06A6,
  "power-plug-off-outline": F1424,
  "power-plug-outline": F1425,
  "power-settings": F0426,
  "power-sleep": F0904,
  "power-socket": F0427,
  "power-socket-au": F0905,
  "power-socket-de": F1107,
  "power-socket-eu": F07E7,
  "power-socket-fr": F1108,
  "power-socket-it": F14FF,
  "power-socket-jp": F1109,
  "power-socket-uk": F07E8,
  "power-socket-us": F07E9,
  "power-standby": F0906,
  "powershell": F0A0A,
  "prescription": F0706,
  "presentation": F0428,
  "presentation-play": F0429,
  "pretzel": F1562,
  "printer": F042A,
  "printer-3d": F042B,
  "printer-3d-nozzle": F0E5B,
  "printer-3d-nozzle-alert": F11C0,
  "printer-3d-nozzle-alert-outline": F11C1,
  "printer-3d-nozzle-outline": F0E5C,
  "printer-alert": F042C,
  "printer-check": F1146,
  "printer-eye": F1458,
  "printer-off": F0E5D,
  "printer-pos": F1057,
  "printer-search": F1457,
  "printer-settings": F0707,
  "printer-wireless": F0A0B,
  "priority-high": F0603,
  "priority-low": F0604,
  "professional-hexagon": F042D,
  "progress-alert": F0CBC,
  "progress-check": F0995,
  "progress-clock": F0996,
  "progress-close": F110A,
  "progress-download": F0997,
  "progress-question": F1522,
  "progress-upload": F0998,
  "progress-wrench": F0CBD,
  "projector": F042E,
  "projector-screen": F042F,
  "projector-screen-outline": F1724,
  "propane-tank": F1357,
  "propane-tank-outline": F1358,
  "protocol": F0FD8,
  "publish": F06A7,
  "pulse": F0430,
  "pump": F1402,
  "pumpkin": F0BBF,
  "purse": F0F1C,
  "purse-outline": F0F1D,
  "puzzle": F0431,
  "puzzle-check": F1426,
  "puzzle-check-outline": F1427,
  "puzzle-edit": F14D3,
  "puzzle-edit-outline": F14D9,
  "puzzle-heart": F14D4,
  "puzzle-heart-outline": F14DA,
  "puzzle-minus": F14D1,
  "puzzle-minus-outline": F14D7,
  "puzzle-outline": F0A66,
  "puzzle-plus": F14D0,
  "puzzle-plus-outline": F14D6,
  "puzzle-remove": F14D2,
  "puzzle-remove-outline": F14D8,
  "puzzle-star": F14D5,
  "puzzle-star-outline": F14DB,
  "qi": F0999,
  "qqchat": F0605,
  "qrcode": F0432,
  "qrcode-edit": F08B8,
  "qrcode-minus": F118C,
  "qrcode-plus": F118B,
  "qrcode-remove": F118D,
  "qrcode-scan": F0433,
  "quadcopter": F0434,
  "quality-high": F0435,
  "quality-low": F0A0C,
  "quality-medium": F0A0D,
  "quora": F0D29,
  "rabbit": F0907,
  "racing-helmet": F0D93,
  "racquetball": F0D94,
  "radar": F0437,
  "radiator": F0438,
  "radiator-disabled": F0AD7,
  "radiator-off": F0AD8,
  "radio": F0439,
  "radio-am": F0CBE,
  "radio-fm": F0CBF,
  "radio-handheld": F043A,
  "radio-off": F121C,
  "radio-tower": F043B,
  "radioactive": F043C,
  "radioactive-off": F0EC1,
  "radiobox-blank": F043D,
  "radiobox-marked": F043E,
  "radiology-box": F14C5,
  "radiology-box-outline": F14C6,
  "radius": F0CC0,
  "radius-outline": F0CC1,
  "railroad-light": F0F1E,
  "rake": F1544,
  "raspberry-pi": F043F,
  "ray-end": F0440,
  "ray-end-arrow": F0441,
  "ray-start": F0442,
  "ray-start-arrow": F0443,
  "ray-start-end": F0444,
  "ray-start-vertex-end": F15D8,
  "ray-vertex": F0445,
  "react": F0708,
  "read": F0447,
  "receipt": F0449,
  "record": F044A,
  "record-circle": F0EC2,
  "record-circle-outline": F0EC3,
  "record-player": F099A,
  "record-rec": F044B,
  "rectangle": F0E5E,
  "rectangle-outline": F0E5F,
  "recycle": F044C,
  "recycle-variant": F139D,
  "reddit": F044D,
  "redhat": F111B,
  "redo": F044E,
  "redo-variant": F044F,
  "reflect-horizontal": F0A0E,
  "reflect-vertical": F0A0F,
  "refresh": F0450,
  "refresh-circle": F1377,
  "regex": F0451,
  "registered-trademark": F0A67,
  "reiterate": F1588,
  "relation-many-to-many": F1496,
  "relation-many-to-one": F1497,
  "relation-many-to-one-or-many": F1498,
  "relation-many-to-only-one": F1499,
  "relation-many-to-zero-or-many": F149A,
  "relation-many-to-zero-or-one": F149B,
  "relation-one-or-many-to-many": F149C,
  "relation-one-or-many-to-one": F149D,
  "relation-one-or-many-to-one-or-many": F149E,
  "relation-one-or-many-to-only-one": F149F,
  "relation-one-or-many-to-zero-or-many": F14A0,
  "relation-one-or-many-to-zero-or-one": F14A1,
  "relation-one-to-many": F14A2,
  "relation-one-to-one": F14A3,
  "relation-one-to-one-or-many": F14A4,
  "relation-one-to-only-one": F14A5,
  "relation-one-to-zero-or-many": F14A6,
  "relation-one-to-zero-or-one": F14A7,
  "relation-only-one-to-many": F14A8,
  "relation-only-one-to-one": F14A9,
  "relation-only-one-to-one-or-many": F14AA,
  "relation-only-one-to-only-one": F14AB,
  "relation-only-one-to-zero-or-many": F14AC,
  "relation-only-one-to-zero-or-one": F14AD,
  "relation-zero-or-many-to-many": F14AE,
  "relation-zero-or-many-to-one": F14AF,
  "relation-zero-or-many-to-one-or-many": F14B0,
  "relation-zero-or-many-to-only-one": F14B1,
  "relation-zero-or-many-to-zero-or-many": F14B2,
  "relation-zero-or-many-to-zero-or-one": F14B3,
  "relation-zero-or-one-to-many": F14B4,
  "relation-zero-or-one-to-one": F14B5,
  "relation-zero-or-one-to-one-or-many": F14B6,
  "relation-zero-or-one-to-only-one": F14B7,
  "relation-zero-or-one-to-zero-or-many": F14B8,
  "relation-zero-or-one-to-zero-or-one": F14B9,
  "relative-scale": F0452,
  "reload": F0453,
  "reload-alert": F110B,
  "reminder": F088C,
  "remote": F0454,
  "remote-desktop": F08B9,
  "remote-off": F0EC4,
  "remote-tv": F0EC5,
  "remote-tv-off": F0EC6,
  "rename-box": F0455,
  "reorder-horizontal": F0688,
  "reorder-vertical": F0689,
  "repeat": F0456,
  "repeat-off": F0457,
  "repeat-once": F0458,
  "replay": F0459,
  "reply": F045A,
  "reply-all": F045B,
  "reply-all-outline": F0F1F,
  "reply-circle": F11AE,
  "reply-outline": F0F20,
  "reproduction": F045C,
  "resistor": F0B44,
  "resistor-nodes": F0B45,
  "resize": F0A68,
  "resize-bottom-right": F045D,
  "responsive": F045E,
  "restart": F0709,
  "restart-alert": F110C,
  "restart-off": F0D95,
  "restore": F099B,
  "restore-alert": F110D,
  "rewind": F045F,
  "rewind-10": F0D2A,
  "rewind-30": F0D96,
  "rewind-5": F11F9,
  "rewind-60": F160C,
  "rewind-outline": F070A,
  "rhombus": F070B,
  "rhombus-medium": F0A10,
  "rhombus-medium-outline": F14DC,
  "rhombus-outline": F070C,
  "rhombus-split": F0A11,
  "rhombus-split-outline": F14DD,
  "ribbon": F0460,
  "rice": F07EA,
  "rickshaw": F15BB,
  "rickshaw-electric": F15BC,
  "ring": F07EB,
  "rivet": F0E60,
  "road": F0461,
  "road-variant": F0462,
  "robber": F1058,
  "robot": F06A9,
  "robot-angry": F169D,
  "robot-angry-outline": F169E,
  "robot-confused": F169F,
  "robot-confused-outline": F16A0,
  "robot-dead": F16A1,
  "robot-dead-outline": F16A2,
  "robot-excited": F16A3,
  "robot-excited-outline": F16A4,
  "robot-happy": F1719,
  "robot-happy-outline": F171A,
  "robot-industrial": F0B46,
  "robot-love": F16A5,
  "robot-love-outline": F16A6,
  "robot-mower": F11F7,
  "robot-mower-outline": F11F3,
  "robot-off": F16A7,
  "robot-off-outline": F167B,
  "robot-outline": F167A,
  "robot-vacuum": F070D,
  "robot-vacuum-variant": F0908,
  "rocket": F0463,
  "rocket-launch": F14DE,
  "rocket-launch-outline": F14DF,
  "rocket-outline": F13AF,
  "rodent": F1327,
  "roller-skate": F0D2B,
  "roller-skate-off": F0145,
  "rollerblade": F0D2C,
  "rollerblade-off": F002E,
  "rollupjs": F0BC0,
  "roman-numeral-1": F1088,
  "roman-numeral-10": F1091,
  "roman-numeral-2": F1089,
  "roman-numeral-3": F108A,
  "roman-numeral-4": F108B,
  "roman-numeral-5": F108C,
  "roman-numeral-6": F108D,
  "roman-numeral-7": F108E,
  "roman-numeral-8": F108F,
  "roman-numeral-9": F1090,
  "room-service": F088D,
  "room-service-outline": F0D97,
  "rotate-3d": F0EC7,
  "rotate-3d-variant": F0464,
  "rotate-left": F0465,
  "rotate-left-variant": F0466,
  "rotate-orbit": F0D98,
  "rotate-right": F0467,
  "rotate-right-variant": F0468,
  "rounded-corner": F0607,
  "router": F11E2,
  "router-network": F1087,
  "router-wireless": F0469,
  "router-wireless-off": F15A3,
  "router-wireless-settings": F0A69,
  "routes": F046A,
  "routes-clock": F1059,
  "rowing": F0608,
  "rss": F046B,
  "rss-box": F046C,
  "rss-off": F0F21,
  "rug": F1475,
  "rugby": F0D99,
  "ruler": F046D,
  "ruler-square": F0CC2,
  "ruler-square-compass": F0EBE,
  "run": F070E,
  "run-fast": F046E,
  "rv-truck": F11D4,
  "sack": F0D2E,
  "sack-percent": F0D2F,
  "safe": F0A6A,
  "safe-square": F127C,
  "safe-square-outline": F127D,
  "safety-goggles": F0D30,
  "sail-boat": F0EC8,
  "sale": F046F,
  "salesforce": F088E,
  "sass": F07EC,
  "satellite": F0470,
  "satellite-uplink": F0909,
  "satellite-variant": F0471,
  "sausage": F08BA,
  "saw-blade": F0E61,
  "sawtooth-wave": F147A,
  "saxophone": F0609,
  "scale": F0472,
  "scale-balance": F05D1,
  "scale-bathroom": F0473,
  "scale-off": F105A,
  "scan-helper": F13D8,
  "scanner": F06AB,
  "scanner-off": F090A,
  "scatter-plot": F0EC9,
  "scatter-plot-outline": F0ECA,
  "school": F0474,
  "school-outline": F1180,
  "scissors-cutting": F0A6B,
  "scooter": F15BD,
  "scooter-electric": F15BE,
  "scoreboard": F127E,
  "scoreboard-outline": F127F,
  "screen-rotation": F0475,
  "screen-rotation-lock": F0478,
  "screw-flat-top": F0DF3,
  "screw-lag": F0DF4,
  "screw-machine-flat-top": F0DF5,
  "screw-machine-round-top": F0DF6,
  "screw-round-top": F0DF7,
  "screwdriver": F0476,
  "script": F0BC1,
  "script-outline": F0477,
  "script-text": F0BC2,
  "script-text-key": F1725,
  "script-text-key-outline": F1726,
  "script-text-outline": F0BC3,
  "script-text-play": F1727,
  "script-text-play-outline": F1728,
  "sd": F0479,
  "seal": F047A,
  "seal-variant": F0FD9,
  "search-web": F070F,
  "seat": F0CC3,
  "seat-flat": F047B,
  "seat-flat-angled": F047C,
  "seat-individual-suite": F047D,
  "seat-legroom-extra": F047E,
  "seat-legroom-normal": F047F,
  "seat-legroom-reduced": F0480,
  "seat-outline": F0CC4,
  "seat-passenger": F1249,
  "seat-recline-extra": F0481,
  "seat-recline-normal": F0482,
  "seatbelt": F0CC5,
  "security": F0483,
  "security-network": F0484,
  "seed": F0E62,
  "seed-off": F13FD,
  "seed-off-outline": F13FE,
  "seed-outline": F0E63,
  "seesaw": F15A4,
  "segment": F0ECB,
  "select": F0485,
  "select-all": F0486,
  "select-color": F0D31,
  "select-compare": F0AD9,
  "select-drag": F0A6C,
  "select-group": F0F82,
  "select-inverse": F0487,
  "select-marker": F1280,
  "select-multiple": F1281,
  "select-multiple-marker": F1282,
  "select-off": F0488,
  "select-place": F0FDA,
  "select-search": F1204,
  "selection": F0489,
  "selection-drag": F0A6D,
  "selection-ellipse": F0D32,
  "selection-ellipse-arrow-inside": F0F22,
  "selection-marker": F1283,
  "selection-multiple": F1285,
  "selection-multiple-marker": F1284,
  "selection-off": F0777,
  "selection-search": F1205,
  "semantic-web": F1316,
  "send": F048A,
  "send-check": F1161,
  "send-check-outline": F1162,
  "send-circle": F0DF8,
  "send-circle-outline": F0DF9,
  "send-clock": F1163,
  "send-clock-outline": F1164,
  "send-lock": F07ED,
  "send-lock-outline": F1166,
  "send-outline": F1165,
  "serial-port": F065C,
  "server": F048B,
  "server-minus": F048C,
  "server-network": F048D,
  "server-network-off": F048E,
  "server-off": F048F,
  "server-plus": F0490,
  "server-remove": F0491,
  "server-security": F0492,
  "set-all": F0778,
  "set-center": F0779,
  "set-center-right": F077A,
  "set-left": F077B,
  "set-left-center": F077C,
  "set-left-right": F077D,
  "set-merge": F14E0,
  "set-none": F077E,
  "set-right": F077F,
  "set-split": F14E1,
  "set-square": F145D,
  "set-top-box": F099F,
  "settings-helper": F0A6E,
  "shaker": F110E,
  "shaker-outline": F110F,
  "shape": F0831,
  "shape-circle-plus": F065D,
  "shape-outline": F0832,
  "shape-oval-plus": F11FA,
  "shape-plus": F0495,
  "shape-polygon-plus": F065E,
  "shape-rectangle-plus": F065F,
  "shape-square-plus": F0660,
  "shape-square-rounded-plus": F14FA,
  "share": F0496,
  "share-all": F11F4,
  "share-all-outline": F11F5,
  "share-circle": F11AD,
  "share-off": F0F23,
  "share-off-outline": F0F24,
  "share-outline": F0932,
  "share-variant": F0497,
  "share-variant-outline": F1514,
  "shark-fin": F1673,
  "shark-fin-outline": F1674,
  "sheep": F0CC6,
  "shield": F0498,
  "shield-account": F088F,
  "shield-account-outline": F0A12,
  "shield-account-variant": F15A7,
  "shield-account-variant-outline": F15A8,
  "shield-airplane": F06BB,
  "shield-airplane-outline": F0CC7,
  "shield-alert": F0ECC,
  "shield-alert-outline": F0ECD,
  "shield-bug": F13DA,
  "shield-bug-outline": F13DB,
  "shield-car": F0F83,
  "shield-check": F0565,
  "shield-check-outline": F0CC8,
  "shield-cross": F0CC9,
  "shield-cross-outline": F0CCA,
  "shield-edit": F11A0,
  "shield-edit-outline": F11A1,
  "shield-half": F1360,
  "shield-half-full": F0780,
  "shield-home": F068A,
  "shield-home-outline": F0CCB,
  "shield-key": F0BC4,
  "shield-key-outline": F0BC5,
  "shield-link-variant": F0D33,
  "shield-link-variant-outline": F0D34,
  "shield-lock": F099D,
  "shield-lock-outline": F0CCC,
  "shield-off": F099E,
  "shield-off-outline": F099C,
  "shield-outline": F0499,
  "shield-plus": F0ADA,
  "shield-plus-outline": F0ADB,
  "shield-refresh": F00AA,
  "shield-refresh-outline": F01E0,
  "shield-remove": F0ADC,
  "shield-remove-outline": F0ADD,
  "shield-search": F0D9A,
  "shield-star": F113B,
  "shield-star-outline": F113C,
  "shield-sun": F105D,
  "shield-sun-outline": F105E,
  "shield-sync": F11A2,
  "shield-sync-outline": F11A3,
  "ship-wheel": F0833,
  "shoe-ballet": F15CA,
  "shoe-cleat": F15C7,
  "shoe-formal": F0B47,
  "shoe-heel": F0B48,
  "shoe-print": F0DFA,
  "shoe-sneaker": F15C8,
  "shopping": F049A,
  "shopping-music": F049B,
  "shopping-outline": F11D5,
  "shopping-search": F0F84,
  "shore": F14F9,
  "shovel": F0710,
  "shovel-off": F0711,
  "shower": F09A0,
  "shower-head": F09A1,
  "shredder": F049C,
  "shuffle": F049D,
  "shuffle-disabled": F049E,
  "shuffle-variant": F049F,
  "shuriken": F137F,
  "sigma": F04A0,
  "sigma-lower": F062B,
  "sign-caution": F04A1,
  "sign-direction": F0781,
  "sign-direction-minus": F1000,
  "sign-direction-plus": F0FDC,
  "sign-direction-remove": F0FDD,
  "sign-pole": F14F8,
  "sign-real-estate": F1118,
  "sign-text": F0782,
  "signal": F04A2,
  "signal-2g": F0712,
  "signal-3g": F0713,
  "signal-4g": F0714,
  "signal-5g": F0A6F,
  "signal-cellular-1": F08BC,
  "signal-cellular-2": F08BD,
  "signal-cellular-3": F08BE,
  "signal-cellular-outline": F08BF,
  "signal-distance-variant": F0E64,
  "signal-hspa": F0715,
  "signal-hspa-plus": F0716,
  "signal-off": F0783,
  "signal-variant": F060A,
  "signature": F0DFB,
  "signature-freehand": F0DFC,
  "signature-image": F0DFD,
  "signature-text": F0DFE,
  "silo": F0B49,
  "silverware": F04A3,
  "silverware-clean": F0FDE,
  "silverware-fork": F04A4,
  "silverware-fork-knife": F0A70,
  "silverware-spoon": F04A5,
  "silverware-variant": F04A6,
  "sim": F04A7,
  "sim-alert": F04A8,
  "sim-alert-outline": F15D3,
  "sim-off": F04A9,
  "sim-off-outline": F15D4,
  "sim-outline": F15D5,
  "simple-icons": F131D,
  "sina-weibo": F0ADF,
  "sine-wave": F095B,
  "sitemap": F04AA,
  "size-l": F13A6,
  "size-m": F13A5,
  "size-s": F13A4,
  "size-xl": F13A7,
  "size-xs": F13A3,
  "size-xxl": F13A8,
  "size-xxs": F13A2,
  "size-xxxl": F13A9,
  "skate": F0D35,
  "skateboard": F14C2,
  "skew-less": F0D36,
  "skew-more": F0D37,
  "ski": F1304,
  "ski-cross-country": F1305,
  "ski-water": F1306,
  "skip-backward": F04AB,
  "skip-backward-outline": F0F25,
  "skip-forward": F04AC,
  "skip-forward-outline": F0F26,
  "skip-next": F04AD,
  "skip-next-circle": F0661,
  "skip-next-circle-outline": F0662,
  "skip-next-outline": F0F27,
  "skip-previous": F04AE,
  "skip-previous-circle": F0663,
  "skip-previous-circle-outline": F0664,
  "skip-previous-outline": F0F28,
  "skull": F068C,
  "skull-crossbones": F0BC6,
  "skull-crossbones-outline": F0BC7,
  "skull-outline": F0BC8,
  "skull-scan": F14C7,
  "skull-scan-outline": F14C8,
  "skype": F04AF,
  "skype-business": F04B0,
  "slack": F04B1,
  "slash-forward": F0FDF,
  "slash-forward-box": F0FE0,
  "sleep": F04B2,
  "sleep-off": F04B3,
  "slide": F15A5,
  "slope-downhill": F0DFF,
  "slope-uphill": F0E00,
  "slot-machine": F1114,
  "slot-machine-outline": F1115,
  "smart-card": F10BD,
  "smart-card-outline": F10BE,
  "smart-card-reader": F10BF,
  "smart-card-reader-outline": F10C0,
  "smog": F0A71,
  "smoke-detector": F0392,
  "smoking": F04B4,
  "smoking-off": F04B5,
  "smoking-pipe": F140D,
  "smoking-pipe-off": F1428,
  "snail": F1677,
  "snake": F150E,
  "snapchat": F04B6,
  "snowboard": F1307,
  "snowflake": F0717,
  "snowflake-alert": F0F29,
  "snowflake-melt": F12CB,
  "snowflake-off": F14E3,
  "snowflake-variant": F0F2A,
  "snowman": F04B7,
  "soccer": F04B8,
  "soccer-field": F0834,
  "social-distance-2-meters": F1579,
  "social-distance-6-feet": F157A,
  "sofa": F04B9,
  "sofa-outline": F156D,
  "sofa-single": F156E,
  "sofa-single-outline": F156F,
  "solar-panel": F0D9B,
  "solar-panel-large": F0D9C,
  "solar-power": F0A72,
  "soldering-iron": F1092,
  "solid": F068D,
  "sony-playstation": F0414,
  "sort": F04BA,
  "sort-alphabetical-ascending": F05BD,
  "sort-alphabetical-ascending-variant": F1148,
  "sort-alphabetical-descending": F05BF,
  "sort-alphabetical-descending-variant": F1149,
  "sort-alphabetical-variant": F04BB,
  "sort-ascending": F04BC,
  "sort-bool-ascending": F1385,
  "sort-bool-ascending-variant": F1386,
  "sort-bool-descending": F1387,
  "sort-bool-descending-variant": F1388,
  "sort-calendar-ascending": F1547,
  "sort-calendar-descending": F1548,
  "sort-clock-ascending": F1549,
  "sort-clock-ascending-outline": F154A,
  "sort-clock-descending": F154B,
  "sort-clock-descending-outline": F154C,
  "sort-descending": F04BD,
  "sort-numeric-ascending": F1389,
  "sort-numeric-ascending-variant": F090D,
  "sort-numeric-descending": F138A,
  "sort-numeric-descending-variant": F0AD2,
  "sort-numeric-variant": F04BE,
  "sort-reverse-variant": F033C,
  "sort-variant": F04BF,
  "sort-variant-lock": F0CCD,
  "sort-variant-lock-open": F0CCE,
  "sort-variant-remove": F1147,
  "soundcloud": F04C0,
  "source-branch": F062C,
  "source-branch-check": F14CF,
  "source-branch-minus": F14CB,
  "source-branch-plus": F14CA,
  "source-branch-refresh": F14CD,
  "source-branch-remove": F14CC,
  "source-branch-sync": F14CE,
  "source-commit": F0718,
  "source-commit-end": F0719,
  "source-commit-end-local": F071A,
  "source-commit-local": F071B,
  "source-commit-next-local": F071C,
  "source-commit-start": F071D,
  "source-commit-start-next-local": F071E,
  "source-fork": F04C1,
  "source-merge": F062D,
  "source-pull": F04C2,
  "source-repository": F0CCF,
  "source-repository-multiple": F0CD0,
  "soy-sauce": F07EE,
  "soy-sauce-off": F13FC,
  "spa": F0CD1,
  "spa-outline": F0CD2,
  "space-invaders": F0BC9,
  "space-station": F1383,
  "spade": F0E65,
  "sparkles": F1545,
  "speaker": F04C3,
  "speaker-bluetooth": F09A2,
  "speaker-multiple": F0D38,
  "speaker-off": F04C4,
  "speaker-wireless": F071F,
  "speedometer": F04C5,
  "speedometer-medium": F0F85,
  "speedometer-slow": F0F86,
  "spellcheck": F04C6,
  "spider": F11EA,
  "spider-thread": F11EB,
  "spider-web": F0BCA,
  "spirit-level": F14F1,
  "spoon-sugar": F1429,
  "spotify": F04C7,
  "spotlight": F04C8,
  "spotlight-beam": F04C9,
  "spray": F0665,
  "spray-bottle": F0AE0,
  "sprinkler": F105F,
  "sprinkler-variant": F1060,
  "sprout": F0E66,
  "sprout-outline": F0E67,
  "square": F0764,
  "square-circle": F1500,
  "square-edit-outline": F090C,
  "square-medium": F0A13,
  "square-medium-outline": F0A14,
  "square-off": F12EE,
  "square-off-outline": F12EF,
  "square-outline": F0763,
  "square-root": F0784,
  "square-root-box": F09A3,
  "square-rounded": F14FB,
  "square-rounded-outline": F14FC,
  "square-small": F0A15,
  "square-wave": F147B,
  "squeegee": F0AE1,
  "ssh": F08C0,
  "stack-exchange": F060B,
  "stack-overflow": F04CC,
  "stackpath": F0359,
  "stadium": F0FF9,
  "stadium-variant": F0720,
  "stairs": F04CD,
  "stairs-box": F139E,
  "stairs-down": F12BE,
  "stairs-up": F12BD,
  "stamper": F0D39,
  "standard-definition": F07EF,
  "star": F04CE,
  "star-box": F0A73,
  "star-box-multiple": F1286,
  "star-box-multiple-outline": F1287,
  "star-box-outline": F0A74,
  "star-check": F1566,
  "star-check-outline": F156A,
  "star-circle": F04CF,
  "star-circle-outline": F09A4,
  "star-cog": F1668,
  "star-cog-outline": F1669,
  "star-face": F09A5,
  "star-four-points": F0AE2,
  "star-four-points-outline": F0AE3,
  "star-half": F0246,
  "star-half-full": F04D0,
  "star-minus": F1564,
  "star-minus-outline": F1568,
  "star-off": F04D1,
  "star-off-outline": F155B,
  "star-outline": F04D2,
  "star-plus": F1563,
  "star-plus-outline": F1567,
  "star-remove": F1565,
  "star-remove-outline": F1569,
  "star-settings": F166A,
  "star-settings-outline": F166B,
  "star-shooting": F1741,
  "star-shooting-outline": F1742,
  "star-three-points": F0AE4,
  "star-three-points-outline": F0AE5,
  "state-machine": F11EF,
  "steam": F04D3,
  "steering": F04D4,
  "steering-off": F090E,
  "step-backward": F04D5,
  "step-backward-2": F04D6,
  "step-forward": F04D7,
  "step-forward-2": F04D8,
  "stethoscope": F04D9,
  "sticker": F1364,
  "sticker-alert": F1365,
  "sticker-alert-outline": F1366,
  "sticker-check": F1367,
  "sticker-check-outline": F1368,
  "sticker-circle-outline": F05D0,
  "sticker-emoji": F0785,
  "sticker-minus": F1369,
  "sticker-minus-outline": F136A,
  "sticker-outline": F136B,
  "sticker-plus": F136C,
  "sticker-plus-outline": F136D,
  "sticker-remove": F136E,
  "sticker-remove-outline": F136F,
  "stocking": F04DA,
  "stomach": F1093,
  "stop": F04DB,
  "stop-circle": F0666,
  "stop-circle-outline": F0667,
  "store": F04DC,
  "store-24-hour": F04DD,
  "store-minus": F165E,
  "store-outline": F1361,
  "store-plus": F165F,
  "store-remove": F1660,
  "storefront": F07C7,
  "storefront-outline": F10C1,
  "stove": F04DE,
  "strategy": F11D6,
  "stretch-to-page": F0F2B,
  "stretch-to-page-outline": F0F2C,
  "string-lights": F12BA,
  "string-lights-off": F12BB,
  "subdirectory-arrow-left": F060C,
  "subdirectory-arrow-right": F060D,
  "submarine": F156C,
  "subtitles": F0A16,
  "subtitles-outline": F0A17,
  "subway": F06AC,
  "subway-alert-variant": F0D9D,
  "subway-variant": F04DF,
  "summit": F0786,
  "sunglasses": F04E0,
  "surround-sound": F05C5,
  "surround-sound-2-0": F07F0,
  "surround-sound-2-1": F1729,
  "surround-sound-3-1": F07F1,
  "surround-sound-5-1": F07F2,
  "surround-sound-5-1-2": F172A,
  "surround-sound-7-1": F07F3,
  "svg": F0721,
  "swap-horizontal": F04E1,
  "swap-horizontal-bold": F0BCD,
  "swap-horizontal-circle": F0FE1,
  "swap-horizontal-circle-outline": F0FE2,
  "swap-horizontal-variant": F08C1,
  "swap-vertical": F04E2,
  "swap-vertical-bold": F0BCE,
  "swap-vertical-circle": F0FE3,
  "swap-vertical-circle-outline": F0FE4,
  "swap-vertical-variant": F08C2,
  "swim": F04E3,
  "switch": F04E4,
  "sword": F04E5,
  "sword-cross": F0787,
  "syllabary-hangul": F1333,
  "syllabary-hiragana": F1334,
  "syllabary-katakana": F1335,
  "syllabary-katakana-halfwidth": F1336,
  "symbol": F1501,
  "symfony": F0AE6,
  "sync": F04E6,
  "sync-alert": F04E7,
  "sync-circle": F1378,
  "sync-off": F04E8,
  "tab": F04E9,
  "tab-minus": F0B4B,
  "tab-plus": F075C,
  "tab-remove": F0B4C,
  "tab-unselected": F04EA,
  "table": F04EB,
  "table-account": F13B9,
  "table-alert": F13BA,
  "table-arrow-down": F13BB,
  "table-arrow-left": F13BC,
  "table-arrow-right": F13BD,
  "table-arrow-up": F13BE,
  "table-border": F0A18,
  "table-cancel": F13BF,
  "table-chair": F1061,
  "table-check": F13C0,
  "table-clock": F13C1,
  "table-cog": F13C2,
  "table-column": F0835,
  "table-column-plus-after": F04EC,
  "table-column-plus-before": F04ED,
  "table-column-remove": F04EE,
  "table-column-width": F04EF,
  "table-edit": F04F0,
  "table-eye": F1094,
  "table-eye-off": F13C3,
  "table-furniture": F05BC,
  "table-headers-eye": F121D,
  "table-headers-eye-off": F121E,
  "table-heart": F13C4,
  "table-key": F13C5,
  "table-large": F04F1,
  "table-large-plus": F0F87,
  "table-large-remove": F0F88,
  "table-lock": F13C6,
  "table-merge-cells": F09A6,
  "table-minus": F13C7,
  "table-multiple": F13C8,
  "table-network": F13C9,
  "table-of-contents": F0836,
  "table-off": F13CA,
  "table-picnic": F1743,
  "table-plus": F0A75,
  "table-refresh": F13A0,
  "table-remove": F0A76,
  "table-row": F0837,
  "table-row-height": F04F2,
  "table-row-plus-after": F04F3,
  "table-row-plus-before": F04F4,
  "table-row-remove": F04F5,
  "table-search": F090F,
  "table-settings": F0838,
  "table-split-cell": F142A,
  "table-star": F13CB,
  "table-sync": F13A1,
  "table-tennis": F0E68,
  "tablet": F04F6,
  "tablet-android": F04F7,
  "tablet-cellphone": F09A7,
  "tablet-dashboard": F0ECE,
  "tablet-ipad": F04F8,
  "taco": F0762,
  "tag": F04F9,
  "tag-arrow-down": F172B,
  "tag-arrow-down-outline": F172C,
  "tag-arrow-left": F172D,
  "tag-arrow-left-outline": F172E,
  "tag-arrow-right": F172F,
  "tag-arrow-right-outline": F1730,
  "tag-arrow-up": F1731,
  "tag-arrow-up-outline": F1732,
  "tag-faces": F04FA,
  "tag-heart": F068B,
  "tag-heart-outline": F0BCF,
  "tag-minus": F0910,
  "tag-minus-outline": F121F,
  "tag-multiple": F04FB,
  "tag-multiple-outline": F12F7,
  "tag-off": F1220,
  "tag-off-outline": F1221,
  "tag-outline": F04FC,
  "tag-plus": F0722,
  "tag-plus-outline": F1222,
  "tag-remove": F0723,
  "tag-remove-outline": F1223,
  "tag-text": F1224,
  "tag-text-outline": F04FD,
  "tailwind": F13FF,
  "tank": F0D3A,
  "tanker-truck": F0FE5,
  "tape-drive": F16DF,
  "tape-measure": F0B4D,
  "target": F04FE,
  "target-account": F0BD0,
  "target-variant": F0A77,
  "taxi": F04FF,
  "tea": F0D9E,
  "tea-outline": F0D9F,
  "teach": F0890,
  "teamviewer": F0500,
  "telegram": F0501,
  "telescope": F0B4E,
  "television": F0502,
  "television-ambient-light": F1356,
  "television-box": F0839,
  "television-classic": F07F4,
  "television-classic-off": F083A,
  "television-clean": F1110,
  "television-guide": F0503,
  "television-off": F083B,
  "television-pause": F0F89,
  "television-play": F0ECF,
  "television-stop": F0F8A,
  "temperature-celsius": F0504,
  "temperature-fahrenheit": F0505,
  "temperature-kelvin": F0506,
  "tennis": F0DA0,
  "tennis-ball": F0507,
  "tent": F0508,
  "terraform": F1062,
  "terrain": F0509,
  "test-tube": F0668,
  "test-tube-empty": F0911,
  "test-tube-off": F0912,
  "text": F09A8,
  "text-account": F1570,
  "text-box": F021A,
  "text-box-check": F0EA6,
  "text-box-check-outline": F0EA7,
  "text-box-minus": F0EA8,
  "text-box-minus-outline": F0EA9,
  "text-box-multiple": F0AB7,
  "text-box-multiple-outline": F0AB8,
  "text-box-outline": F09ED,
  "text-box-plus": F0EAA,
  "text-box-plus-outline": F0EAB,
  "text-box-remove": F0EAC,
  "text-box-remove-outline": F0EAD,
  "text-box-search": F0EAE,
  "text-box-search-outline": F0EAF,
  "text-recognition": F113D,
  "text-search": F13B8,
  "text-shadow": F0669,
  "text-short": F09A9,
  "text-subject": F09AA,
  "text-to-speech": F050A,
  "text-to-speech-off": F050B,
  "texture": F050C,
  "texture-box": F0FE6,
  "theater": F050D,
  "theme-light-dark": F050E,
  "thermometer": F050F,
  "thermometer-alert": F0E01,
  "thermometer-chevron-down": F0E02,
  "thermometer-chevron-up": F0E03,
  "thermometer-high": F10C2,
  "thermometer-lines": F0510,
  "thermometer-low": F10C3,
  "thermometer-minus": F0E04,
  "thermometer-off": F1531,
  "thermometer-plus": F0E05,
  "thermostat": F0393,
  "thermostat-box": F0891,
  "thought-bubble": F07F6,
  "thought-bubble-outline": F07F7,
  "thumb-down": F0511,
  "thumb-down-outline": F0512,
  "thumb-up": F0513,
  "thumb-up-outline": F0514,
  "thumbs-up-down": F0515,
  "ticket": F0516,
  "ticket-account": F0517,
  "ticket-confirmation": F0518,
  "ticket-confirmation-outline": F13AA,
  "ticket-outline": F0913,
  "ticket-percent": F0724,
  "ticket-percent-outline": F142B,
  "tie": F0519,
  "tilde": F0725,
  "timelapse": F051A,
  "timeline": F0BD1,
  "timeline-alert": F0F95,
  "timeline-alert-outline": F0F98,
  "timeline-check": F1532,
  "timeline-check-outline": F1533,
  "timeline-clock": F11FB,
  "timeline-clock-outline": F11FC,
  "timeline-help": F0F99,
  "timeline-help-outline": F0F9A,
  "timeline-minus": F1534,
  "timeline-minus-outline": F1535,
  "timeline-outline": F0BD2,
  "timeline-plus": F0F96,
  "timeline-plus-outline": F0F97,
  "timeline-remove": F1536,
  "timeline-remove-outline": F1537,
  "timeline-text": F0BD3,
  "timeline-text-outline": F0BD4,
  "timer": F13AB,
  "timer-10": F051C,
  "timer-3": F051D,
  "timer-off": F13AC,
  "timer-off-outline": F051E,
  "timer-outline": F051B,
  "timer-sand": F051F,
  "timer-sand-empty": F06AD,
  "timer-sand-full": F078C,
  "timetable": F0520,
  "toaster": F1063,
  "toaster-off": F11B7,
  "toaster-oven": F0CD3,
  "toggle-switch": F0521,
  "toggle-switch-off": F0522,
  "toggle-switch-off-outline": F0A19,
  "toggle-switch-outline": F0A1A,
  "toilet": F09AB,
  "toolbox": F09AC,
  "toolbox-outline": F09AD,
  "tools": F1064,
  "tooltip": F0523,
  "tooltip-account": F000C,
  "tooltip-check": F155C,
  "tooltip-check-outline": F155D,
  "tooltip-edit": F0524,
  "tooltip-edit-outline": F12C5,
  "tooltip-image": F0525,
  "tooltip-image-outline": F0BD5,
  "tooltip-minus": F155E,
  "tooltip-minus-outline": F155F,
  "tooltip-outline": F0526,
  "tooltip-plus": F0BD6,
  "tooltip-plus-outline": F0527,
  "tooltip-remove": F1560,
  "tooltip-remove-outline": F1561,
  "tooltip-text": F0528,
  "tooltip-text-outline": F0BD7,
  "tooth": F08C3,
  "tooth-outline": F0529,
  "toothbrush": F1129,
  "toothbrush-electric": F112C,
  "toothbrush-paste": F112A,
  "torch": F1606,
  "tortoise": F0D3B,
  "toslink": F12B8,
  "tournament": F09AE,
  "tow-truck": F083C,
  "tower-beach": F0681,
  "tower-fire": F0682,
  "toy-brick": F1288,
  "toy-brick-marker": F1289,
  "toy-brick-marker-outline": F128A,
  "toy-brick-minus": F128B,
  "toy-brick-minus-outline": F128C,
  "toy-brick-outline": F128D,
  "toy-brick-plus": F128E,
  "toy-brick-plus-outline": F128F,
  "toy-brick-remove": F1290,
  "toy-brick-remove-outline": F1291,
  "toy-brick-search": F1292,
  "toy-brick-search-outline": F1293,
  "track-light": F0914,
  "trackpad": F07F8,
  "trackpad-lock": F0933,
  "tractor": F0892,
  "tractor-variant": F14C4,
  "trademark": F0A78,
  "traffic-cone": F137C,
  "traffic-light": F052B,
  "train": F052C,
  "train-car": F0BD8,
  "train-car-passenger": F1733,
  "train-car-passenger-door": F1734,
  "train-car-passenger-door-open": F1735,
  "train-car-passenger-variant": F1736,
  "train-variant": F08C4,
  "tram": F052D,
  "tram-side": F0FE7,
  "transcribe": F052E,
  "transcribe-close": F052F,
  "transfer": F1065,
  "transfer-down": F0DA1,
  "transfer-left": F0DA2,
  "transfer-right": F0530,
  "transfer-up": F0DA3,
  "transit-connection": F0D3C,
  "transit-connection-horizontal": F1546,
  "transit-connection-variant": F0D3D,
  "transit-detour": F0F8B,
  "transit-skip": F1515,
  "transit-transfer": F06AE,
  "transition": F0915,
  "transition-masked": F0916,
  "translate": F05CA,
  "translate-off": F0E06,
  "transmission-tower": F0D3E,
  "trash-can": F0A79,
  "trash-can-outline": F0A7A,
  "tray": F1294,
  "tray-alert": F1295,
  "tray-full": F1296,
  "tray-minus": F1297,
  "tray-plus": F1298,
  "tray-remove": F1299,
  "treasure-chest": F0726,
  "tree": F0531,
  "tree-outline": F0E69,
  "trello": F0532,
  "trending-down": F0533,
  "trending-neutral": F0534,
  "trending-up": F0535,
  "triangle": F0536,
  "triangle-outline": F0537,
  "triangle-wave": F147C,
  "triforce": F0BD9,
  "trophy": F0538,
  "trophy-award": F0539,
  "trophy-broken": F0DA4,
  "trophy-outline": F053A,
  "trophy-variant": F053B,
  "trophy-variant-outline": F053C,
  "truck": F053D,
  "truck-check": F0CD4,
  "truck-check-outline": F129A,
  "truck-delivery": F053E,
  "truck-delivery-outline": F129B,
  "truck-fast": F0788,
  "truck-fast-outline": F129C,
  "truck-outline": F129D,
  "truck-trailer": F0727,
  "trumpet": F1096,
  "tshirt-crew": F0A7B,
  "tshirt-crew-outline": F053F,
  "tshirt-v": F0A7C,
  "tshirt-v-outline": F0540,
  "tumble-dryer": F0917,
  "tumble-dryer-alert": F11BA,
  "tumble-dryer-off": F11BB,
  "tune": F062E,
  "tune-variant": F1542,
  "tune-vertical": F066A,
  "tune-vertical-variant": F1543,
  "turkey": F171B,
  "turnstile": F0CD5,
  "turnstile-outline": F0CD6,
  "turtle": F0CD7,
  "twitch": F0543,
  "twitter": F0544,
  "twitter-retweet": F0547,
  "two-factor-authentication": F09AF,
  "typewriter": F0F2D,
  "ubisoft": F0BDA,
  "ubuntu": F0548,
  "ufo": F10C4,
  "ufo-outline": F10C5,
  "ultra-high-definition": F07F9,
  "umbraco": F0549,
  "umbrella": F054A,
  "umbrella-closed": F09B0,
  "umbrella-closed-outline": F13E2,
  "umbrella-closed-variant": F13E1,
  "umbrella-outline": F054B,
  "undo": F054C,
  "undo-variant": F054D,
  "unfold-less-horizontal": F054E,
  "unfold-less-vertical": F0760,
  "unfold-more-horizontal": F054F,
  "unfold-more-vertical": F0761,
  "ungroup": F0550,
  "unicode": F0ED0,
  "unicorn": F15C2,
  "unicorn-variant": F15C3,
  "unicycle": F15E5,
  "unity": F06AF,
  "unreal": F09B1,
  "untappd": F0551,
  "update": F06B0,
  "upload": F0552,
  "upload-lock": F1373,
  "upload-lock-outline": F1374,
  "upload-multiple": F083D,
  "upload-network": F06F6,
  "upload-network-outline": F0CD8,
  "upload-off": F10C6,
  "upload-off-outline": F10C7,
  "upload-outline": F0E07,
  "usb": F0553,
  "usb-flash-drive": F129E,
  "usb-flash-drive-outline": F129F,
  "usb-port": F11F0,
  "valve": F1066,
  "valve-closed": F1067,
  "valve-open": F1068,
  "van-passenger": F07FA,
  "van-utility": F07FB,
  "vanish": F07FC,
  "vanish-quarter": F1554,
  "vanity-light": F11E1,
  "variable": F0AE7,
  "variable-box": F1111,
  "vector-arrange-above": F0554,
  "vector-arrange-below": F0555,
  "vector-bezier": F0AE8,
  "vector-circle": F0556,
  "vector-circle-variant": F0557,
  "vector-combine": F0558,
  "vector-curve": F0559,
  "vector-difference": F055A,
  "vector-difference-ab": F055B,
  "vector-difference-ba": F055C,
  "vector-ellipse": F0893,
  "vector-intersection": F055D,
  "vector-line": F055E,
  "vector-link": F0FE8,
  "vector-point": F055F,
  "vector-polygon": F0560,
  "vector-polyline": F0561,
  "vector-polyline-edit": F1225,
  "vector-polyline-minus": F1226,
  "vector-polyline-plus": F1227,
  "vector-polyline-remove": F1228,
  "vector-radius": F074A,
  "vector-rectangle": F05C6,
  "vector-selection": F0562,
  "vector-square": F0001,
  "vector-triangle": F0563,
  "vector-union": F0564,
  "vhs": F0A1B,
  "vibrate": F0566,
  "vibrate-off": F0CD9,
  "video": F0567,
  "video-3d": F07FD,
  "video-3d-off": F13D9,
  "video-3d-variant": F0ED1,
  "video-4k-box": F083E,
  "video-account": F0919,
  "video-box": F00FD,
  "video-box-off": F00FE,
  "video-check": F1069,
  "video-check-outline": F106A,
  "video-high-definition": F152E,
  "video-image": F091A,
  "video-input-antenna": F083F,
  "video-input-component": F0840,
  "video-input-hdmi": F0841,
  "video-input-scart": F0F8C,
  "video-input-svideo": F0842,
  "video-minus": F09B2,
  "video-minus-outline": F02BA,
  "video-off": F0568,
  "video-off-outline": F0BDB,
  "video-outline": F0BDC,
  "video-plus": F09B3,
  "video-plus-outline": F01D3,
  "video-stabilization": F091B,
  "video-switch": F0569,
  "video-switch-outline": F0790,
  "video-vintage": F0A1C,
  "video-wireless": F0ED2,
  "video-wireless-outline": F0ED3,
  "view-agenda": F056A,
  "view-agenda-outline": F11D8,
  "view-array": F056B,
  "view-array-outline": F1485,
  "view-carousel": F056C,
  "view-carousel-outline": F1486,
  "view-column": F056D,
  "view-column-outline": F1487,
  "view-comfy": F0E6A,
  "view-comfy-outline": F1488,
  "view-compact": F0E6B,
  "view-compact-outline": F0E6C,
  "view-dashboard": F056E,
  "view-dashboard-outline": F0A1D,
  "view-dashboard-variant": F0843,
  "view-dashboard-variant-outline": F1489,
  "view-day": F056F,
  "view-day-outline": F148A,
  "view-grid": F0570,
  "view-grid-outline": F11D9,
  "view-grid-plus": F0F8D,
  "view-grid-plus-outline": F11DA,
  "view-headline": F0571,
  "view-list": F0572,
  "view-list-outline": F148B,
  "view-module": F0573,
  "view-module-outline": F148C,
  "view-parallel": F0728,
  "view-parallel-outline": F148D,
  "view-quilt": F0574,
  "view-quilt-outline": F148E,
  "view-sequential": F0729,
  "view-sequential-outline": F148F,
  "view-split-horizontal": F0BCB,
  "view-split-vertical": F0BCC,
  "view-stream": F0575,
  "view-stream-outline": F1490,
  "view-week": F0576,
  "view-week-outline": F1491,
  "vimeo": F0577,
  "violin": F060F,
  "virtual-reality": F0894,
  "virus": F13B6,
  "virus-outline": F13B7,
  "vk": F0579,
  "vlc": F057C,
  "voice-off": F0ED4,
  "voicemail": F057D,
  "volleyball": F09B4,
  "volume-high": F057E,
  "volume-low": F057F,
  "volume-medium": F0580,
  "volume-minus": F075E,
  "volume-mute": F075F,
  "volume-off": F0581,
  "volume-plus": F075D,
  "volume-source": F1120,
  "volume-variant-off": F0E08,
  "volume-vibrate": F1121,
  "vote": F0A1F,
  "vote-outline": F0A20,
  "vpn": F0582,
  "vuejs": F0844,
  "vuetify": F0E6D,
  "walk": F0583,
  "wall": F07FE,
  "wall-sconce": F091C,
  "wall-sconce-flat": F091D,
  "wall-sconce-flat-variant": F041C,
  "wall-sconce-round": F0748,
  "wall-sconce-round-variant": F091E,
  "wallet": F0584,
  "wallet-giftcard": F0585,
  "wallet-membership": F0586,
  "wallet-outline": F0BDD,
  "wallet-plus": F0F8E,
  "wallet-plus-outline": F0F8F,
  "wallet-travel": F0587,
  "wallpaper": F0E09,
  "wan": F0588,
  "wardrobe": F0F90,
  "wardrobe-outline": F0F91,
  "warehouse": F0F81,
  "washing-machine": F072A,
  "washing-machine-alert": F11BC,
  "washing-machine-off": F11BD,
  "watch": F0589,
  "watch-export": F058A,
  "watch-export-variant": F0895,
  "watch-import": F058B,
  "watch-import-variant": F0896,
  "watch-variant": F0897,
  "watch-vibrate": F06B1,
  "watch-vibrate-off": F0CDA,
  "water": F058C,
  "water-alert": F1502,
  "water-alert-outline": F1503,
  "water-boiler": F0F92,
  "water-boiler-alert": F11B3,
  "water-boiler-off": F11B4,
  "water-check": F1504,
  "water-check-outline": F1505,
  "water-minus": F1506,
  "water-minus-outline": F1507,
  "water-off": F058D,
  "water-off-outline": F1508,
  "water-outline": F0E0A,
  "water-percent": F058E,
  "water-percent-alert": F1509,
  "water-plus": F150A,
  "water-plus-outline": F150B,
  "water-polo": F12A0,
  "water-pump": F058F,
  "water-pump-off": F0F93,
  "water-remove": F150C,
  "water-remove-outline": F150D,
  "water-well": F106B,
  "water-well-outline": F106C,
  "watering-can": F1481,
  "watering-can-outline": F1482,
  "watermark": F0612,
  "wave": F0F2E,
  "waveform": F147D,
  "waves": F078D,
  "waze": F0BDE,
  "weather-cloudy": F0590,
  "weather-cloudy-alert": F0F2F,
  "weather-cloudy-arrow-right": F0E6E,
  "weather-fog": F0591,
  "weather-hail": F0592,
  "weather-hazy": F0F30,
  "weather-hurricane": F0898,
  "weather-lightning": F0593,
  "weather-lightning-rainy": F067E,
  "weather-night": F0594,
  "weather-night-partly-cloudy": F0F31,
  "weather-partly-cloudy": F0595,
  "weather-partly-lightning": F0F32,
  "weather-partly-rainy": F0F33,
  "weather-partly-snowy": F0F34,
  "weather-partly-snowy-rainy": F0F35,
  "weather-pouring": F0596,
  "weather-rainy": F0597,
  "weather-snowy": F0598,
  "weather-snowy-heavy": F0F36,
  "weather-snowy-rainy": F067F,
  "weather-sunny": F0599,
  "weather-sunny-alert": F0F37,
  "weather-sunny-off": F14E4,
  "weather-sunset": F059A,
  "weather-sunset-down": F059B,
  "weather-sunset-up": F059C,
  "weather-tornado": F0F38,
  "weather-windy": F059D,
  "weather-windy-variant": F059E,
  "web": F059F,
  "web-box": F0F94,
  "web-clock": F124A,
  "webcam": F05A0,
  "webcam-off": F1737,
  "webhook": F062F,
  "webpack": F072B,
  "webrtc": F1248,
  "wechat": F0611,
  "weight": F05A1,
  "weight-gram": F0D3F,
  "weight-kilogram": F05A2,
  "weight-lifter": F115D,
  "weight-pound": F09B5,
  "whatsapp": F05A3,
  "wheel-barrow": F14F2,
  "wheelchair-accessibility": F05A4,
  "whistle": F09B6,
  "whistle-outline": F12BC,
  "white-balance-auto": F05A5,
  "white-balance-incandescent": F05A6,
  "white-balance-iridescent": F05A7,
  "white-balance-sunny": F05A8,
  "widgets": F072C,
  "widgets-outline": F1355,
  "wifi": F05A9,
  "wifi-alert": F16B5,
  "wifi-arrow-down": F16B6,
  "wifi-arrow-left": F16B7,
  "wifi-arrow-left-right": F16B8,
  "wifi-arrow-right": F16B9,
  "wifi-arrow-up": F16BA,
  "wifi-arrow-up-down": F16BB,
  "wifi-cancel": F16BC,
  "wifi-check": F16BD,
  "wifi-cog": F16BE,
  "wifi-lock": F16BF,
  "wifi-lock-open": F16C0,
  "wifi-marker": F16C1,
  "wifi-minus": F16C2,
  "wifi-off": F05AA,
  "wifi-plus": F16C3,
  "wifi-refresh": F16C4,
  "wifi-remove": F16C5,
  "wifi-settings": F16C6,
  "wifi-star": F0E0B,
  "wifi-strength-1": F091F,
  "wifi-strength-1-alert": F0920,
  "wifi-strength-1-lock": F0921,
  "wifi-strength-1-lock-open": F16CB,
  "wifi-strength-2": F0922,
  "wifi-strength-2-alert": F0923,
  "wifi-strength-2-lock": F0924,
  "wifi-strength-2-lock-open": F16CC,
  "wifi-strength-3": F0925,
  "wifi-strength-3-alert": F0926,
  "wifi-strength-3-lock": F0927,
  "wifi-strength-3-lock-open": F16CD,
  "wifi-strength-4": F0928,
  "wifi-strength-4-alert": F0929,
  "wifi-strength-4-lock": F092A,
  "wifi-strength-4-lock-open": F16CE,
  "wifi-strength-alert-outline": F092B,
  "wifi-strength-lock-open-outline": F16CF,
  "wifi-strength-lock-outline": F092C,
  "wifi-strength-off": F092D,
  "wifi-strength-off-outline": F092E,
  "wifi-strength-outline": F092F,
  "wifi-sync": F16C7,
  "wikipedia": F05AC,
  "wind-turbine": F0DA5,
  "window-close": F05AD,
  "window-closed": F05AE,
  "window-closed-variant": F11DB,
  "window-maximize": F05AF,
  "window-minimize": F05B0,
  "window-open": F05B1,
  "window-open-variant": F11DC,
  "window-restore": F05B2,
  "window-shutter": F111C,
  "window-shutter-alert": F111D,
  "window-shutter-open": F111E,
  "windsock": F15FA,
  "wiper": F0AE9,
  "wiper-wash": F0DA6,
  "wizard-hat": F1477,
  "wordpress": F05B4,
  "wrap": F05B6,
  "wrap-disabled": F0BDF,
  "wrench": F05B7,
  "wrench-outline": F0BE0,
  "xamarin": F0845,
  "xamarin-outline": F0846,
  "xing": F05BE,
  "xml": F05C0,
  "xmpp": F07FF,
  "y-combinator": F0624,
  "yahoo": F0B4F,
  "yeast": F05C1,
  "yin-yang": F0680,
  "yoga": F117C,
  "youtube": F05C3,
  "youtube-gaming": F0848,
  "youtube-studio": F0847,
  "youtube-subscription": F0D40,
  "youtube-tv": F0448,
  "yurt": F1516,
  "z-wave": F0AEA,
  "zend": F0AEB,
  "zigbee": F0D41,
  "zip-box": F05C4,
  "zip-box-outline": F0FFA,
  "zip-disk": F0A23,
  "zodiac-aquarius": F0A7D,
  "zodiac-aries": F0A7E,
  "zodiac-cancer": F0A7F,
  "zodiac-capricorn": F0A80,
  "zodiac-gemini": F0A81,
  "zodiac-leo": F0A82,
  "zodiac-libra": F0A83,
  "zodiac-pisces": F0A84,
  "zodiac-sagittarius": F0A85,
  "zodiac-scorpio": F0A86,
  "zodiac-taurus": F0A87,
  "zodiac-virgo": F0A88
);