.nav-link[data-v-85dd69] {
  color: #fff;
  font-weight: bold;
}

body {
  background-image: url("background.0205d4de.png");
  background-repeat: repeat;
  margin: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-image: url("background.0205d4de.png");
  background-repeat: repeat;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

#main {
  min-width: 360px;
  margin: 0 auto;
}

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .3s;
  transition-timing-function: ease;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
}

.section[data-v-53d87f] {
  text-align: left;
  border-top: 1px solid gray;
}

.sectionHeader[data-v-53d87f] {
  text-transform: uppercase;
  font-weight: bold;
}

#mugshot[data-v-a5d81b] {
  height: 35%;
  width: 200px;
  text-align: center;
  border: 6px solid #303030;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

#descriptions[data-v-a5d81b] {
  margin-top: 20px;
}

.box > h3[data-v-c4cfdd] {
  font-weight: bold;
}

.box > p[data-v-c4cfdd] {
  text-align: left;
  padding: 0 15px 15px;
}

.box[data-v-8bdfb0] {
  color: #fff;
  background-color: #303030;
  border-radius: 10px;
}

.section[data-v-66bc13] {
  text-align: left;
  border-top: 1px solid gray;
}

.sectionHeader[data-v-66bc13] {
  text-transform: uppercase;
  font-weight: bold;
}

.v-application p[data-v-66bc13], h5[data-v-66bc13] {
  margin: 2px;
  padding: 0;
}

.experienceHeader[data-v-66bc13] {
  border-bottom: 1px solid gray;
}

.section[data-v-0cdef4] {
  text-align: left;
  border-top: 1px solid gray;
}

.sectionHeader[data-v-0cdef4] {
  text-transform: uppercase;
  font-weight: bold;
}

.box[data-v-0cdef4] {
  color: #fff;
  background-color: #303030;
  border-radius: 10px;
}

.input-field[data-v-0cdef4] {
  padding: 15px;
}

.error-message[data-v-0cdef4] {
  color: red;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small, .small {
  font-size: .875em;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: .875em;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  color: #212529;
  margin-bottom: 1rem;
}

.table th, .table td {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th, .table-sm td {
  padding: .3rem;
}

.table-bordered, .table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #00000013;
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #b8daff;
}

.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #c3e6cb;
}

.table-success th, .table-success td, .table-success thead th, .table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #bee5eb;
}

.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #ffeeba;
}

.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > th, .table-light > td {
  background-color: #fdfdfe;
}

.table-light th, .table-light td, .table-light thead th, .table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > th, .table-active > td, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th, .table-dark td, .table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row > .col > .valid-tooltip, .form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem) !important;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem) !important;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label:before, .custom-control-input.is-valid ~ .custom-control-label:before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before, .custom-control-input.is-valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row > .col > .invalid-tooltip, .form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem) !important;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem) !important;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label:before, .custom-control-input.is-invalid ~ .custom-control-label:before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group, .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #e9ecef;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  width: 1%;
  min-width: 0;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label, .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label:after, .input-group.has-validation > .form-control:nth-last-child(n+3), .input-group.has-validation > .custom-select:nth-last-child(n+3), .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label, .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend, .input-group-append {
  display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 2;
  position: relative;
}

.input-group-prepend .btn:focus, .input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
  height: calc(1.5em + .5rem + 2px);
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text, .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn, .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  z-index: 1;
  min-height: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  left: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label:before, .custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem;
  left: -2.25rem;
}

.custom-switch .custom-control-label:after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  top: calc(.25rem + 2px);
  left: calc(2px - 2.25rem);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.custom-file-label:after {
  z-index: 3;
  height: calc(1.5em + .75rem);
  color: #495057;
  content: "Browse";
  border-left: inherit;
  background-color: #e9ecef;
  border-radius: 0 .25rem .25rem 0;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  background-color: #0000;
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  margin-bottom: -1px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  min-height: 1px;
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  border-radius: calc(.25rem - 1px);
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
  flex-shrink: 0;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  .card-deck .card {
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  color: #6c757d;
  content: "/";
  padding-right: .5rem;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff80;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #28a74580;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #dc354580;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #343a4080;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  z-index: 2;
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  border-radius: .25rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .25rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  opacity: 0;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  flex-basis: 350px;
  font-size: .875rem;
  box-shadow: 0 .25rem .75rem #0000001a;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  opacity: 1;
  display: block;
}

.toast.hide {
  display: none;
}

.toast-header {
  color: #6c757d;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: calc(.3rem - 1px);
  border-bottom-right-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow:before, .bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-right .arrow:before, .bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow:before, .bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-left .arrow:before, .bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:before, .popover .arrow:after {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: .5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top > .arrow:before, .bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-top > .arrow:after, .bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: .5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-right > .arrow:before, .bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-right > .arrow:after, .bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: .5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom > .arrow:before, .bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-bottom > .arrow:after, .bs-popover-auto[x-placement^="bottom"] > .arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: .5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-left > .arrow:before, .bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-left > .arrow:after, .bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  animation: .75s linear infinite spinner-grow;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :before, :after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.bv-no-focus-ring:focus {
  outline: none;
}

@media (max-width: 575.98px) {
  .bv-d-xs-down-none {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .bv-d-sm-down-none {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .bv-d-md-down-none {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .bv-d-lg-down-none {
    display: none !important;
  }
}

.bv-d-xl-down-none {
  display: none !important;
}

.form-control.focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control.focus.is-valid {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.form-control.focus.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.b-avatar {
  vertical-align: middle;
  width: 2.5rem;
  height: 2.5rem;
  font-size: inherit;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  line-height: 1;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

.b-avatar:focus {
  outline: 0;
}

.b-avatar.btn, .b-avatar[href] {
  border: 0;
  padding: 0;
}

.b-avatar.btn .b-avatar-img img, .b-avatar[href] .b-avatar-img img {
  transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.b-avatar.btn:not(:disabled):not(.disabled), .b-avatar[href]:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img, .b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img {
  transform: scale(1.15);
}

.b-avatar.disabled, .b-avatar:disabled, .b-avatar[disabled] {
  opacity: .65;
  pointer-events: none;
}

.b-avatar .b-avatar-custom, .b-avatar .b-avatar-text, .b-avatar .b-avatar-img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(#fff, #000);
  mask-image: radial-gradient(#fff, #000);
}

.b-avatar .b-avatar-text {
  text-transform: uppercase;
  white-space: nowrap;
}

.b-avatar[href] {
  text-decoration: none;
}

.b-avatar > .b-icon {
  width: 60%;
  height: auto;
  max-width: 100%;
}

.b-avatar .b-avatar-img img {
  width: 100%;
  height: 100%;
  max-height: auto;
  border-radius: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

.b-avatar .b-avatar-badge {
  min-height: 1.5em;
  min-width: 1.5em;
  z-index: 1;
  border-radius: 10em;
  padding: .25em;
  font-size: 70%;
  font-weight: 700;
  line-height: 1;
  position: absolute;
}

.b-avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.b-avatar-sm .b-avatar-text {
  font-size: .6rem;
}

.b-avatar-sm .b-avatar-badge {
  font-size: .42rem;
}

.b-avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
}

.b-avatar-lg .b-avatar-text {
  font-size: 1.4rem;
}

.b-avatar-lg .b-avatar-badge {
  font-size: .98rem;
}

.b-avatar-group .b-avatar-group-inner {
  flex-wrap: wrap;
  display: flex;
}

.b-avatar-group .b-avatar {
  border: 1px solid #dee2e6;
}

.b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled), .b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled) {
  z-index: 1;
}

.b-calendar {
  display: inline-flex;
}

.b-calendar .b-calendar-inner {
  min-width: 250px;
}

.b-calendar .b-calendar-header, .b-calendar .b-calendar-nav {
  margin-bottom: .25rem;
}

.b-calendar .b-calendar-nav .btn {
  padding: .25rem;
}

.b-calendar output {
  padding: .25rem;
  font-size: 80%;
}

.b-calendar output.readonly {
  opacity: 1;
  background-color: #e9ecef;
}

.b-calendar .b-calendar-footer {
  margin-top: .5rem;
}

.b-calendar .b-calendar-grid {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.b-calendar .b-calendar-grid .row {
  flex-wrap: nowrap;
}

.b-calendar .b-calendar-grid-caption {
  padding: .25rem;
}

.b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 32px;
  height: 32px;
  margin: 3px auto;
  padding: 9px 0;
  font-size: 14px;
  line-height: 1;
}

.b-calendar .btn:disabled, .b-calendar .btn.disabled, .b-calendar .btn[aria-disabled="true"] {
  cursor: default;
  pointer-events: none;
}

.card-img-left {
  border-top-left-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-img-right {
  border-top-right-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret:after, .dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret:before {
  display: none !important;
}

.dropdown .dropdown-menu:focus {
  outline: none;
}

.b-dropdown-form {
  width: 100%;
  clear: both;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: inline-block;
}

.b-dropdown-form:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}

.b-dropdown-form.disabled, .b-dropdown-form:disabled {
  color: #adb5bd;
  pointer-events: none;
  outline: 0 !important;
}

.b-dropdown-text {
  width: 100%;
  clear: both;
  margin-bottom: 0;
  padding: .25rem 1.5rem;
  font-weight: lighter;
  display: inline-block;
}

.custom-checkbox.b-custom-control-lg, .input-group-lg .custom-checkbox {
  padding-left: 1.875rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.custom-checkbox.b-custom-control-lg .custom-control-label:before, .input-group-lg .custom-checkbox .custom-control-label:before {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: .3rem;
  top: .3125rem;
  left: -1.875rem;
}

.custom-checkbox.b-custom-control-lg .custom-control-label:after, .input-group-lg .custom-checkbox .custom-control-label:after {
  width: 1.25rem;
  height: 1.25rem;
  background-size: 50% 50%;
  top: .3125rem;
  left: -1.875rem;
}

.custom-checkbox.b-custom-control-sm, .input-group-sm .custom-checkbox {
  padding-left: 1.3125rem;
  font-size: .875rem;
  line-height: 1.5;
}

.custom-checkbox.b-custom-control-sm .custom-control-label:before, .input-group-sm .custom-checkbox .custom-control-label:before {
  width: .875rem;
  height: .875rem;
  border-radius: .2rem;
  top: .21875rem;
  left: -1.3125rem;
}

.custom-checkbox.b-custom-control-sm .custom-control-label:after, .input-group-sm .custom-checkbox .custom-control-label:after {
  width: .875rem;
  height: .875rem;
  background-size: 50% 50%;
  top: .21875rem;
  left: -1.3125rem;
}

.custom-switch.b-custom-control-lg, .input-group-lg .custom-switch {
  padding-left: 2.8125rem;
}

.custom-switch.b-custom-control-lg .custom-control-label, .input-group-lg .custom-switch .custom-control-label {
  font-size: 1.25rem;
  line-height: 1.5;
}

.custom-switch.b-custom-control-lg .custom-control-label:before, .input-group-lg .custom-switch .custom-control-label:before {
  height: 1.25rem;
  width: 2.1875rem;
  border-radius: .625rem;
  top: .3125rem;
  left: -2.8125rem;
}

.custom-switch.b-custom-control-lg .custom-control-label:after, .input-group-lg .custom-switch .custom-control-label:after {
  width: calc(1.25rem - 4px);
  height: calc(1.25rem - 4px);
  background-size: 50% 50%;
  border-radius: .625rem;
  top: calc(.3125rem + 2px);
  left: calc(2px - 2.8125rem);
}

.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label:after, .input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  transform: translateX(.9375rem);
}

.custom-switch.b-custom-control-sm, .input-group-sm .custom-switch {
  padding-left: 1.96875rem;
}

.custom-switch.b-custom-control-sm .custom-control-label, .input-group-sm .custom-switch .custom-control-label {
  font-size: .875rem;
  line-height: 1.5;
}

.custom-switch.b-custom-control-sm .custom-control-label:before, .input-group-sm .custom-switch .custom-control-label:before {
  width: 1.53125rem;
  height: .875rem;
  border-radius: .4375rem;
  top: .21875rem;
  left: -1.96875rem;
}

.custom-switch.b-custom-control-sm .custom-control-label:after, .input-group-sm .custom-switch .custom-control-label:after {
  width: calc(.875rem - 4px);
  height: calc(.875rem - 4px);
  background-size: 50% 50%;
  border-radius: .4375rem;
  top: calc(.21875rem + 2px);
  left: calc(2px - 1.96875rem);
}

.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label:after, .input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  transform: translateX(.65625rem);
}

.input-group > .input-group-prepend > .btn-group > .btn, .input-group > .input-group-append:not(:last-child) > .btn-group > .btn, .input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn-group > .btn, .input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn, .input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.b-form-btn-label-control.form-control {
  height: auto;
  background-image: none;
  align-items: stretch;
  padding: 0;
  display: flex;
}

.input-group .b-form-btn-label-control.form-control {
  padding: 0;
}

[dir="rtl"] .b-form-btn-label-control.form-control, .b-form-btn-label-control.form-control[dir="rtl"] {
  flex-direction: row-reverse;
}

[dir="rtl"] .b-form-btn-label-control.form-control > label, .b-form-btn-label-control.form-control[dir="rtl"] > label {
  text-align: right;
}

.b-form-btn-label-control.form-control > .btn {
  line-height: 1;
  font-size: inherit;
  border: 0;
  box-shadow: none !important;
}

.b-form-btn-label-control.form-control > .btn:disabled {
  pointer-events: none;
}

.b-form-btn-label-control.form-control.is-valid > .btn {
  color: #28a745;
}

.b-form-btn-label-control.form-control.is-invalid > .btn {
  color: #dc3545;
}

.b-form-btn-label-control.form-control > .dropdown-menu {
  padding: .5rem;
}

.b-form-btn-label-control.form-control > .form-control {
  height: auto;
  min-height: calc(1.5em + .75rem);
  word-break: break-word;
  font-size: inherit;
  white-space: normal;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding-left: .25rem;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  min-height: calc(1.5em + .5rem);
}

.b-form-btn-label-control.form-control > .form-control.form-control-lg {
  min-height: calc(1.5em + 1rem);
}

.input-group.input-group-sm .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(1.5em + .5rem);
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.input-group.input-group-lg .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(1.5em + 1rem);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.b-form-btn-label-control.form-control[aria-disabled="true"], .b-form-btn-label-control.form-control[aria-readonly="true"] {
  opacity: 1;
  background-color: #e9ecef;
}

.b-form-btn-label-control.form-control[aria-disabled="true"] {
  pointer-events: none;
}

.b-form-btn-label-control.form-control[aria-disabled="true"] > label {
  cursor: default;
}

.b-form-btn-label-control.btn-group > .dropdown-menu {
  padding: .5rem;
}

.custom-file-label {
  white-space: nowrap;
  overflow-x: hidden;
}

.b-custom-control-lg.custom-file, .b-custom-control-lg .custom-file-input, .b-custom-control-lg .custom-file-label, .input-group-lg.custom-file, .input-group-lg .custom-file-input, .input-group-lg .custom-file-label {
  height: calc(1.5em + 1rem + 2px);
  font-size: 1.25rem;
}

.b-custom-control-lg .custom-file-label, .b-custom-control-lg .custom-file-label:after, .input-group-lg .custom-file-label, .input-group-lg .custom-file-label:after {
  padding: .5rem 1rem;
  line-height: 1.5;
}

.b-custom-control-lg .custom-file-label, .input-group-lg .custom-file-label {
  border-radius: .3rem;
}

.b-custom-control-lg .custom-file-label:after, .input-group-lg .custom-file-label:after {
  font-size: inherit;
  height: calc(1.5em + 1rem);
  border-radius: 0 .3rem .3rem 0;
}

.b-custom-control-sm.custom-file, .b-custom-control-sm .custom-file-input, .b-custom-control-sm .custom-file-label, .input-group-sm.custom-file, .input-group-sm .custom-file-input, .input-group-sm .custom-file-label {
  height: calc(1.5em + .5rem + 2px);
  font-size: .875rem;
}

.b-custom-control-sm .custom-file-label, .b-custom-control-sm .custom-file-label:after, .input-group-sm .custom-file-label, .input-group-sm .custom-file-label:after {
  padding: .25rem .5rem;
  line-height: 1.5;
}

.b-custom-control-sm .custom-file-label, .input-group-sm .custom-file-label {
  border-radius: .2rem;
}

.b-custom-control-sm .custom-file-label:after, .input-group-sm .custom-file-label:after {
  font-size: inherit;
  height: calc(1.5em + .5rem);
  border-radius: 0 .2rem .2rem 0;
}

.was-validated .form-control:invalid, .was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-position: right calc(.375em + .1875rem) center;
}

input[type="color"].form-control {
  height: calc(1.5em + .75rem + 2px);
  padding: .125rem .25rem;
}

input[type="color"].form-control.form-control-sm, .input-group-sm input[type="color"].form-control {
  height: calc(1.5em + .5rem + 2px);
  padding: .125rem .25rem;
}

input[type="color"].form-control.form-control-lg, .input-group-lg input[type="color"].form-control {
  height: calc(1.5em + 1rem + 2px);
  padding: .125rem .25rem;
}

input[type="color"].form-control:disabled {
  opacity: .65;
  background-color: #adb5bd;
}

.input-group > .custom-range {
  width: 1%;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .custom-range + .form-control, .input-group > .custom-range + .form-control-plaintext, .input-group > .custom-range + .custom-select, .input-group > .custom-range + .custom-range, .input-group > .custom-range + .custom-file, .input-group > .form-control + .custom-range, .input-group > .form-control-plaintext + .custom-range, .input-group > .custom-select + .custom-range, .input-group > .custom-range + .custom-range, .input-group > .custom-file + .custom-range {
  margin-left: -1px;
}

.input-group > .custom-range:focus {
  z-index: 3;
}

.input-group > .custom-range:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-range:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-range {
  height: calc(1.5em + .75rem + 2px);
  height: calc(1.5em + .75rem + 2px);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: 0 .75rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .input-group > .custom-range {
    transition: none;
  }
}

.input-group > .custom-range:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.input-group > .custom-range:disabled, .input-group > .custom-range[readonly] {
  background-color: #e9ecef;
}

.input-group-lg > .custom-range {
  height: calc(1.5em + 1rem + 2px);
  border-radius: .3rem;
  padding: 0 1rem;
}

.input-group-sm > .custom-range {
  height: calc(1.5em + .5rem + 2px);
  border-radius: .2rem;
  padding: 0 .5rem;
}

.was-validated .input-group .custom-range:valid, .input-group .custom-range.is-valid {
  border-color: #28a745;
}

.was-validated .input-group .custom-range:valid:focus, .input-group .custom-range.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .custom-range:valid:focus::-webkit-slider-thumb, .custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #9be7ac;
}

.was-validated .custom-range:valid:focus::-moz-range-thumb, .custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #9be7ac;
}

.was-validated .custom-range:valid:focus::-ms-thumb, .custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #9be7ac;
}

.was-validated .custom-range:valid::-webkit-slider-thumb, .custom-range.is-valid::-webkit-slider-thumb {
  background-color: #28a745;
  background-image: none;
}

.was-validated .custom-range:valid::-webkit-slider-thumb:active, .custom-range.is-valid::-webkit-slider-thumb:active {
  background-color: #9be7ac;
  background-image: none;
}

.was-validated .custom-range:valid::-webkit-slider-runnable-track, .custom-range.is-valid::-webkit-slider-runnable-track {
  background-color: #28a74559;
}

.was-validated .custom-range:valid::-moz-range-thumb, .custom-range.is-valid::-moz-range-thumb {
  background-color: #28a745;
  background-image: none;
}

.was-validated .custom-range:valid::-moz-range-thumb:active, .custom-range.is-valid::-moz-range-thumb:active {
  background-color: #9be7ac;
  background-image: none;
}

.was-validated .custom-range:valid::-moz-range-track, .custom-range.is-valid::-moz-range-track {
  background: #28a74559;
}

.was-validated .custom-range:valid ~ .valid-feedback, .was-validated .custom-range:valid ~ .valid-tooltip, .custom-range.is-valid ~ .valid-feedback, .custom-range.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-range:valid::-ms-thumb, .custom-range.is-valid::-ms-thumb {
  background-color: #28a745;
  background-image: none;
}

.was-validated .custom-range:valid::-ms-thumb:active, .custom-range.is-valid::-ms-thumb:active {
  background-color: #9be7ac;
  background-image: none;
}

.was-validated .custom-range:valid::-ms-track-lower, .custom-range.is-valid::-ms-track-lower {
  background: #28a74559;
}

.was-validated .custom-range:valid::-ms-track-upper, .custom-range.is-valid::-ms-track-upper {
  background: #28a74559;
}

.was-validated .input-group .custom-range:invalid, .input-group .custom-range.is-invalid {
  border-color: #dc3545;
}

.was-validated .input-group .custom-range:invalid:focus, .input-group .custom-range.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .custom-range:invalid:focus::-webkit-slider-thumb, .custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #f6cdd1;
}

.was-validated .custom-range:invalid:focus::-moz-range-thumb, .custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #f6cdd1;
}

.was-validated .custom-range:invalid:focus::-ms-thumb, .custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #f6cdd1;
}

.was-validated .custom-range:invalid::-webkit-slider-thumb, .custom-range.is-invalid::-webkit-slider-thumb {
  background-color: #dc3545;
  background-image: none;
}

.was-validated .custom-range:invalid::-webkit-slider-thumb:active, .custom-range.is-invalid::-webkit-slider-thumb:active {
  background-color: #f6cdd1;
  background-image: none;
}

.was-validated .custom-range:invalid::-webkit-slider-runnable-track, .custom-range.is-invalid::-webkit-slider-runnable-track {
  background-color: #dc354559;
}

.was-validated .custom-range:invalid::-moz-range-thumb, .custom-range.is-invalid::-moz-range-thumb {
  background-color: #dc3545;
  background-image: none;
}

.was-validated .custom-range:invalid::-moz-range-thumb:active, .custom-range.is-invalid::-moz-range-thumb:active {
  background-color: #f6cdd1;
  background-image: none;
}

.was-validated .custom-range:invalid::-moz-range-track, .custom-range.is-invalid::-moz-range-track {
  background: #dc354559;
}

.was-validated .custom-range:invalid ~ .invalid-feedback, .was-validated .custom-range:invalid ~ .invalid-tooltip, .custom-range.is-invalid ~ .invalid-feedback, .custom-range.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-range:invalid::-ms-thumb, .custom-range.is-invalid::-ms-thumb {
  background-color: #dc3545;
  background-image: none;
}

.was-validated .custom-range:invalid::-ms-thumb:active, .custom-range.is-invalid::-ms-thumb:active {
  background-color: #f6cdd1;
  background-image: none;
}

.was-validated .custom-range:invalid::-ms-track-lower, .custom-range.is-invalid::-ms-track-lower {
  background: #dc354559;
}

.was-validated .custom-range:invalid::-ms-track-upper, .custom-range.is-invalid::-ms-track-upper {
  background: #dc354559;
}

.custom-radio.b-custom-control-lg, .input-group-lg .custom-radio {
  padding-left: 1.875rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.custom-radio.b-custom-control-lg .custom-control-label:before, .input-group-lg .custom-radio .custom-control-label:before {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  top: .3125rem;
  left: -1.875rem;
}

.custom-radio.b-custom-control-lg .custom-control-label:after, .input-group-lg .custom-radio .custom-control-label:after {
  width: 1.25rem;
  height: 1.25rem;
  background: 50% / 50% 50% no-repeat;
  top: .3125rem;
  left: -1.875rem;
}

.custom-radio.b-custom-control-sm, .input-group-sm .custom-radio {
  padding-left: 1.3125rem;
  font-size: .875rem;
  line-height: 1.5;
}

.custom-radio.b-custom-control-sm .custom-control-label:before, .input-group-sm .custom-radio .custom-control-label:before {
  width: .875rem;
  height: .875rem;
  border-radius: 50%;
  top: .21875rem;
  left: -1.3125rem;
}

.custom-radio.b-custom-control-sm .custom-control-label:after, .input-group-sm .custom-radio .custom-control-label:after {
  width: .875rem;
  height: .875rem;
  background: 50% / 50% 50% no-repeat;
  top: .21875rem;
  left: -1.3125rem;
}

.b-rating {
  text-align: center;
}

.b-rating.d-inline-flex {
  width: auto;
}

.b-rating .b-rating-star, .b-rating .b-rating-value {
  padding: 0 .25em;
}

.b-rating .b-rating-value {
  min-width: 2.5em;
}

.b-rating .b-rating-star {
  outline: 0;
  justify-content: center;
  display: inline-flex;
}

.b-rating .b-rating-star .b-rating-icon {
  transition: all .15s ease-in-out;
  display: inline-flex;
}

.b-rating.disabled, .b-rating:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.b-rating:not(.disabled):not(.readonly) .b-rating-star {
  cursor: pointer;
}

.b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon, .b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
  transform: scale(1.5);
}

.b-rating[dir="rtl"] .b-rating-star-half {
  transform: scale(-1, 1);
}

.b-form-spinbutton {
  text-align: center;
  background-image: none;
  padding: 0;
  overflow: hidden;
}

[dir="rtl"] .b-form-spinbutton:not(.flex-column), .b-form-spinbutton[dir="rtl"]:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-form-spinbutton output {
  font-size: inherit;
  width: auto;
  background-color: #0000;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0 .25rem;
}

.b-form-spinbutton output > div, .b-form-spinbutton output > bdi {
  min-width: 2.25em;
  height: 1.5em;
  display: block;
}

.b-form-spinbutton.flex-column {
  height: auto;
  width: auto;
}

.b-form-spinbutton.flex-column output {
  margin: 0 .25rem;
  padding: .25rem 0;
}

.b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
  output-width: 100%;
}

.b-form-spinbutton.d-inline-flex:not(.flex-column) {
  width: auto;
}

.b-form-spinbutton .btn {
  line-height: 1;
  box-shadow: none !important;
}

.b-form-spinbutton .btn:disabled {
  pointer-events: none;
}

.b-form-spinbutton .btn:hover:not(:disabled) > div > .b-icon {
  transform: scale(1.25);
}

.b-form-spinbutton.disabled, .b-form-spinbutton.readonly {
  background-color: #e9ecef;
}

.b-form-spinbutton.disabled {
  pointer-events: none;
}

.b-form-tags.focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.b-form-tags.focus.is-valid {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.b-form-tags.focus.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.b-form-tags.disabled {
  background-color: #e9ecef;
}

.b-form-tags-list {
  margin-top: -.25rem;
}

.b-form-tags-list .b-form-tags-field, .b-form-tags-list .b-form-tag {
  margin-top: .25rem;
}

.b-form-tags-input {
  color: #495057;
}

.b-form-tag {
  margin-right: .25rem;
  font-size: 75%;
  font-weight: normal;
  line-height: 1.5;
}

.b-form-tag.disabled {
  opacity: .75;
}

.b-form-tag > button.b-form-tag-remove {
  color: inherit;
  float: none;
  margin-left: .25rem;
  font-size: 125%;
  line-height: 1;
}

.form-control-sm .b-form-tag, .form-control-lg .b-form-tag {
  line-height: 1.5;
}

.media-aside {
  margin-right: 1rem;
  display: flex;
}

.media-aside-right {
  margin-left: 1rem;
  margin-right: 0;
}

.modal-backdrop {
  opacity: .5;
}

.b-pagination-pills .page-item .page-link {
  margin-left: .25rem;
  line-height: 1;
  border-radius: 50rem !important;
}

.b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}

.popover.b-popover {
  opacity: 1;
  outline: 0;
  display: block;
}

.popover.b-popover.fade:not(.show) {
  opacity: 0;
}

.popover.b-popover.show {
  opacity: 1;
}

.b-popover-primary.popover {
  background-color: #cce5ff;
  border-color: #b8daff;
}

.b-popover-primary.bs-popover-top > .arrow:before, .b-popover-primary.bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-top-color: #b8daff;
}

.b-popover-primary.bs-popover-top > .arrow:after, .b-popover-primary.bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-top-color: #cce5ff;
}

.b-popover-primary.bs-popover-right > .arrow:before, .b-popover-primary.bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-right-color: #b8daff;
}

.b-popover-primary.bs-popover-right > .arrow:after, .b-popover-primary.bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-right-color: #cce5ff;
}

.b-popover-primary.bs-popover-bottom > .arrow:before, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-bottom-color: #b8daff;
}

.b-popover-primary.bs-popover-bottom > .arrow:after, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .b-popover-primary.bs-popover-bottom .popover-header:before, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  border-bottom-color: #bdddff;
}

.b-popover-primary.bs-popover-left > .arrow:before, .b-popover-primary.bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-left-color: #b8daff;
}

.b-popover-primary.bs-popover-left > .arrow:after, .b-popover-primary.bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-left-color: #cce5ff;
}

.b-popover-primary .popover-header {
  color: #212529;
  background-color: #bdddff;
  border-bottom-color: #a3d0ff;
}

.b-popover-primary .popover-body {
  color: #004085;
}

.b-popover-secondary.popover {
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.b-popover-secondary.bs-popover-top > .arrow:before, .b-popover-secondary.bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-top-color: #d6d8db;
}

.b-popover-secondary.bs-popover-top > .arrow:after, .b-popover-secondary.bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-top-color: #e2e3e5;
}

.b-popover-secondary.bs-popover-right > .arrow:before, .b-popover-secondary.bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-right-color: #d6d8db;
}

.b-popover-secondary.bs-popover-right > .arrow:after, .b-popover-secondary.bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-right-color: #e2e3e5;
}

.b-popover-secondary.bs-popover-bottom > .arrow:before, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-bottom-color: #d6d8db;
}

.b-popover-secondary.bs-popover-bottom > .arrow:after, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .b-popover-secondary.bs-popover-bottom .popover-header:before, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  border-bottom-color: #dadbde;
}

.b-popover-secondary.bs-popover-left > .arrow:before, .b-popover-secondary.bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-left-color: #d6d8db;
}

.b-popover-secondary.bs-popover-left > .arrow:after, .b-popover-secondary.bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-left-color: #e2e3e5;
}

.b-popover-secondary .popover-header {
  color: #212529;
  background-color: #dadbde;
  border-bottom-color: #ccced2;
}

.b-popover-secondary .popover-body {
  color: #383d41;
}

.b-popover-success.popover {
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.b-popover-success.bs-popover-top > .arrow:before, .b-popover-success.bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-top-color: #c3e6cb;
}

.b-popover-success.bs-popover-top > .arrow:after, .b-popover-success.bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-top-color: #d4edda;
}

.b-popover-success.bs-popover-right > .arrow:before, .b-popover-success.bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-right-color: #c3e6cb;
}

.b-popover-success.bs-popover-right > .arrow:after, .b-popover-success.bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-right-color: #d4edda;
}

.b-popover-success.bs-popover-bottom > .arrow:before, .b-popover-success.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-bottom-color: #c3e6cb;
}

.b-popover-success.bs-popover-bottom > .arrow:after, .b-popover-success.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .b-popover-success.bs-popover-bottom .popover-header:before, .b-popover-success.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  border-bottom-color: #c9e8d1;
}

.b-popover-success.bs-popover-left > .arrow:before, .b-popover-success.bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-left-color: #c3e6cb;
}

.b-popover-success.bs-popover-left > .arrow:after, .b-popover-success.bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-left-color: #d4edda;
}

.b-popover-success .popover-header {
  color: #212529;
  background-color: #c9e8d1;
  border-bottom-color: #b7e1c1;
}

.b-popover-success .popover-body {
  color: #155724;
}

.b-popover-info.popover {
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.b-popover-info.bs-popover-top > .arrow:before, .b-popover-info.bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-top-color: #bee5eb;
}

.b-popover-info.bs-popover-top > .arrow:after, .b-popover-info.bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-top-color: #d1ecf1;
}

.b-popover-info.bs-popover-right > .arrow:before, .b-popover-info.bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-right-color: #bee5eb;
}

.b-popover-info.bs-popover-right > .arrow:after, .b-popover-info.bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-right-color: #d1ecf1;
}

.b-popover-info.bs-popover-bottom > .arrow:before, .b-popover-info.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-bottom-color: #bee5eb;
}

.b-popover-info.bs-popover-bottom > .arrow:after, .b-popover-info.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .b-popover-info.bs-popover-bottom .popover-header:before, .b-popover-info.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  border-bottom-color: #c5e7ed;
}

.b-popover-info.bs-popover-left > .arrow:before, .b-popover-info.bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-left-color: #bee5eb;
}

.b-popover-info.bs-popover-left > .arrow:after, .b-popover-info.bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-left-color: #d1ecf1;
}

.b-popover-info .popover-header {
  color: #212529;
  background-color: #c5e7ed;
  border-bottom-color: #b2dfe7;
}

.b-popover-info .popover-body {
  color: #0c5460;
}

.b-popover-warning.popover {
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.b-popover-warning.bs-popover-top > .arrow:before, .b-popover-warning.bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-top-color: #ffeeba;
}

.b-popover-warning.bs-popover-top > .arrow:after, .b-popover-warning.bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-top-color: #fff3cd;
}

.b-popover-warning.bs-popover-right > .arrow:before, .b-popover-warning.bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-right-color: #ffeeba;
}

.b-popover-warning.bs-popover-right > .arrow:after, .b-popover-warning.bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-right-color: #fff3cd;
}

.b-popover-warning.bs-popover-bottom > .arrow:before, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-bottom-color: #ffeeba;
}

.b-popover-warning.bs-popover-bottom > .arrow:after, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .b-popover-warning.bs-popover-bottom .popover-header:before, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  border-bottom-color: #ffefbe;
}

.b-popover-warning.bs-popover-left > .arrow:before, .b-popover-warning.bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-left-color: #ffeeba;
}

.b-popover-warning.bs-popover-left > .arrow:after, .b-popover-warning.bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-left-color: #fff3cd;
}

.b-popover-warning .popover-header {
  color: #212529;
  background-color: #ffefbe;
  border-bottom-color: #ffe9a4;
}

.b-popover-warning .popover-body {
  color: #856404;
}

.b-popover-danger.popover {
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.b-popover-danger.bs-popover-top > .arrow:before, .b-popover-danger.bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-top-color: #f5c6cb;
}

.b-popover-danger.bs-popover-top > .arrow:after, .b-popover-danger.bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-top-color: #f8d7da;
}

.b-popover-danger.bs-popover-right > .arrow:before, .b-popover-danger.bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-right-color: #f5c6cb;
}

.b-popover-danger.bs-popover-right > .arrow:after, .b-popover-danger.bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-right-color: #f8d7da;
}

.b-popover-danger.bs-popover-bottom > .arrow:before, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-bottom-color: #f5c6cb;
}

.b-popover-danger.bs-popover-bottom > .arrow:after, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .b-popover-danger.bs-popover-bottom .popover-header:before, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  border-bottom-color: #f6cace;
}

.b-popover-danger.bs-popover-left > .arrow:before, .b-popover-danger.bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-left-color: #f5c6cb;
}

.b-popover-danger.bs-popover-left > .arrow:after, .b-popover-danger.bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-left-color: #f8d7da;
}

.b-popover-danger .popover-header {
  color: #212529;
  background-color: #f6cace;
  border-bottom-color: #f2b4ba;
}

.b-popover-danger .popover-body {
  color: #721c24;
}

.b-popover-light.popover {
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.b-popover-light.bs-popover-top > .arrow:before, .b-popover-light.bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-top-color: #fdfdfe;
}

.b-popover-light.bs-popover-top > .arrow:after, .b-popover-light.bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-top-color: #fefefe;
}

.b-popover-light.bs-popover-right > .arrow:before, .b-popover-light.bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-right-color: #fdfdfe;
}

.b-popover-light.bs-popover-right > .arrow:after, .b-popover-light.bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-right-color: #fefefe;
}

.b-popover-light.bs-popover-bottom > .arrow:before, .b-popover-light.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-bottom-color: #fdfdfe;
}

.b-popover-light.bs-popover-bottom > .arrow:after, .b-popover-light.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .b-popover-light.bs-popover-bottom .popover-header:before, .b-popover-light.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  border-bottom-color: #f6f6f6;
}

.b-popover-light.bs-popover-left > .arrow:before, .b-popover-light.bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-left-color: #fdfdfe;
}

.b-popover-light.bs-popover-left > .arrow:after, .b-popover-light.bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-left-color: #fefefe;
}

.b-popover-light .popover-header {
  color: #212529;
  background-color: #f6f6f6;
  border-bottom-color: #eaeaea;
}

.b-popover-light .popover-body {
  color: #818182;
}

.b-popover-dark.popover {
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.b-popover-dark.bs-popover-top > .arrow:before, .b-popover-dark.bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-top-color: #c6c8ca;
}

.b-popover-dark.bs-popover-top > .arrow:after, .b-popover-dark.bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-top-color: #d6d8d9;
}

.b-popover-dark.bs-popover-right > .arrow:before, .b-popover-dark.bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-right-color: #c6c8ca;
}

.b-popover-dark.bs-popover-right > .arrow:after, .b-popover-dark.bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-right-color: #d6d8d9;
}

.b-popover-dark.bs-popover-bottom > .arrow:before, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-bottom-color: #c6c8ca;
}

.b-popover-dark.bs-popover-bottom > .arrow:after, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .b-popover-dark.bs-popover-bottom .popover-header:before, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  border-bottom-color: #ced0d2;
}

.b-popover-dark.bs-popover-left > .arrow:before, .b-popover-dark.bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-left-color: #c6c8ca;
}

.b-popover-dark.bs-popover-left > .arrow:after, .b-popover-dark.bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-left-color: #d6d8d9;
}

.b-popover-dark .popover-header {
  color: #212529;
  background-color: #ced0d2;
  border-bottom-color: #c1c4c5;
}

.b-popover-dark .popover-body {
  color: #1b1e21;
}

.b-sidebar-outer {
  height: 0;
  z-index: calc(1030 + 5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: visible;
}

.b-sidebar-backdrop {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: .6;
  position: fixed;
  top: 0;
  left: 0;
}

.b-sidebar {
  width: 320px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  outline: 0;
  flex-direction: column;
  margin: 0;
  display: flex;
  position: fixed;
  top: 0;
  transform: translateX(0);
}

.b-sidebar.slide {
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .b-sidebar.slide {
    transition: none;
  }
}

.b-sidebar:not(.b-sidebar-right) {
  left: 0;
  right: auto;
}

.b-sidebar:not(.b-sidebar-right).slide:not(.show) {
  transform: translateX(-100%);
}

.b-sidebar:not(.b-sidebar-right) > .b-sidebar-header .close {
  margin-left: auto;
}

.b-sidebar.b-sidebar-right {
  left: auto;
  right: 0;
}

.b-sidebar.b-sidebar-right.slide:not(.show) {
  transform: translateX(100%);
}

.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-right: auto;
}

.b-sidebar > .b-sidebar-header {
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
  padding: .5rem 1rem;
  font-size: 1.5rem;
  display: flex;
}

[dir="rtl"] .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
}

.b-sidebar > .b-sidebar-header .close {
  float: none;
  font-size: 1.5rem;
}

.b-sidebar > .b-sidebar-body {
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.b-sidebar > .b-sidebar-footer {
  flex-grow: 0;
}

.b-skeleton-wrapper {
  cursor: wait;
}

.b-skeleton {
  cursor: wait;
  background-color: #0000001f;
  position: relative;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(#fff, #000);
  mask-image: radial-gradient(#fff, #000);
}

.b-skeleton:before {
  content: " ";
}

.b-skeleton-text {
  height: 1rem;
  border-radius: .25rem;
  margin-bottom: .25rem;
}

.b-skeleton-button {
  width: 75px;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.b-skeleton-avatar {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.b-skeleton-input {
  height: calc(1.5em + .75rem + 2px);
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  line-height: 1.5;
}

.b-skeleton-icon-wrapper svg {
  color: #0000001f;
}

.b-skeleton-img {
  height: 100%;
  width: 100%;
}

.b-skeleton-animate-wave:after {
  content: "";
  z-index: 0;
  background: linear-gradient(90deg, #0000, #fff6, #0000);
  animation: 1.75s linear infinite b-skeleton-animate-wave;
  position: absolute;
  inset: 0;
}

@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-wave:after {
    background: none;
    animation: none;
  }
}

@keyframes b-skeleton-animate-wave {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.b-skeleton-animate-fade {
  animation: .875s ease-in-out infinite alternate b-skeleton-animate-fade;
}

@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-fade {
    animation: none;
  }
}

@keyframes b-skeleton-animate-fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: .4;
  }
}

.b-skeleton-animate-throb {
  animation: .875s ease-in infinite alternate b-skeleton-animate-throb;
}

@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-throb {
    animation: none;
  }
}

@keyframes b-skeleton-animate-throb {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.975);
  }
}

.table.b-table.b-table-fixed {
  table-layout: fixed;
}

.table.b-table.b-table-no-border-collapse {
  border-collapse: separate;
  border-spacing: 0;
}

.table.b-table[aria-busy="true"] {
  opacity: .55;
}

.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}

.table.b-table > caption {
  caption-side: bottom;
}

.table.b-table.b-table-caption-top > caption {
  caption-side: top !important;
}

.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
  background-color: #00000013;
}

.table.b-table.table-hover > tbody > tr.table-active:hover td, .table.b-table.table-hover > tbody > tr.table-active:hover th {
  color: #212529;
  background-image: linear-gradient(#00000013, #00000013);
  background-repeat: no-repeat;
}

.table.b-table > tbody > .bg-active, .table.b-table > tbody > .bg-active > th, .table.b-table > tbody > .bg-active > td {
  background-color: #ffffff13 !important;
}

.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover td, .table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover th {
  color: #fff;
  background-image: linear-gradient(#ffffff13, #ffffff13);
  background-repeat: no-repeat;
}

.b-table-sticky-header, .table-responsive, [class*="table-responsive-"] {
  margin-bottom: 1rem;
}

.b-table-sticky-header > .table, .table-responsive > .table, [class*="table-responsive-"] > .table {
  margin-bottom: 0;
}

.b-table-sticky-header {
  max-height: 300px;
  overflow-y: auto;
}

@media print {
  .b-table-sticky-header {
    max-height: none !important;
    overflow-y: visible !important;
  }
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    z-index: 2;
    position: sticky;
    top: 0;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column, .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column, .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column, .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column, .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column, .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column, [class*="table-responsive-"] > .table.b-table > thead > tr > .b-table-sticky-column, [class*="table-responsive-"] > .table.b-table > tbody > tr > .b-table-sticky-column, [class*="table-responsive-"] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    position: sticky;
    left: 0;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column, .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column, [class*="table-responsive-"] > .table.b-table > thead > tr > .b-table-sticky-column {
    z-index: 5;
  }

  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column, .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column, .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column, .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column, [class*="table-responsive-"] > .table.b-table > tbody > tr > .b-table-sticky-column, [class*="table-responsive-"] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    z-index: 2;
  }

  .table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default {
    color: #212529;
    background-color: #fff;
  }

  .table.b-table.table-dark > thead > tr > .bg-b-table-default, .table.b-table.table-dark > tbody > tr > .bg-b-table-default, .table.b-table.table-dark > tfoot > tr > .bg-b-table-default {
    color: #fff;
    background-color: #343a40;
  }

  .table.b-table.table-striped > tbody > tr:nth-of-type(2n+1) > .table-b-table-default {
    background-image: linear-gradient(#0000000d, #0000000d);
    background-repeat: no-repeat;
  }

  .table.b-table.table-striped.table-dark > tbody > tr:nth-of-type(2n+1) > .bg-b-table-default {
    background-image: linear-gradient(#ffffff0d, #ffffff0d);
    background-repeat: no-repeat;
  }

  .table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
    color: #212529;
    background-image: linear-gradient(#00000013, #00000013);
    background-repeat: no-repeat;
  }

  .table.b-table.table-hover.table-dark > tbody > tr:hover > .bg-b-table-default {
    color: #fff;
    background-image: linear-gradient(#ffffff13, #ffffff13);
    background-repeat: no-repeat;
  }
}

.table.b-table > thead > tr > [aria-sort], .table.b-table > tfoot > tr > [aria-sort] {
  cursor: pointer;
  background-image: none;
  background-repeat: no-repeat;
  background-size: .65em 1em;
}

.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), .table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right .375rem center;
  padding-right: calc(.75rem + .65em);
}

.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left, .table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: .375rem 50%;
  padding-left: calc(.75rem + .65em);
}

.table.b-table > thead > tr > [aria-sort="none"], .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > [aria-sort="ascending"], .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > [aria-sort="descending"], .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table.table-dark > thead > tr > [aria-sort="none"], .table.b-table.table-dark > tfoot > tr > [aria-sort="none"], .table.b-table > .thead-dark > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table.table-dark > thead > tr > [aria-sort="ascending"], .table.b-table.table-dark > tfoot > tr > [aria-sort="ascending"], .table.b-table > .thead-dark > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table.table-dark > thead > tr > [aria-sort="descending"], .table.b-table.table-dark > tfoot > tr > [aria-sort="descending"], .table.b-table > .thead-dark > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > .table-dark[aria-sort="none"], .table.b-table > tfoot > tr > .table-dark[aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > .table-dark[aria-sort="ascending"], .table.b-table > tfoot > tr > .table-dark[aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > .table-dark[aria-sort="descending"], .table.b-table > tfoot > tr > .table-dark[aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), .table.b-table.table-sm > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right .15rem center;
  padding-right: calc(.3rem + .65em);
}

.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left, .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: .15rem 50%;
  padding-left: calc(.3rem + .65em);
}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
  cursor: pointer;
}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range > tbody > tr {
  -webkit-user-select: none;
  user-select: none;
}

@media (max-width: 575.98px) {
  .table.b-table.b-table-stacked-sm {
    width: 100%;
    display: block;
  }

  .table.b-table.b-table-stacked-sm > caption, .table.b-table.b-table-stacked-sm > tbody, .table.b-table.b-table-stacked-sm > tbody > tr, .table.b-table.b-table-stacked-sm > tbody > tr > td, .table.b-table.b-table-stacked-sm > tbody > tr > th {
    display: block;
  }

  .table.b-table.b-table-stacked-sm > thead, .table.b-table.b-table-stacked-sm > tfoot, .table.b-table.b-table-stacked-sm > thead > tr.b-table-top-row, .table.b-table.b-table-stacked-sm > thead > tr.b-table-bottom-row, .table.b-table.b-table-stacked-sm > tfoot > tr.b-table-top-row, .table.b-table.b-table-stacked-sm > tfoot > tr.b-table-bottom-row {
    display: none;
  }

  .table.b-table.b-table-stacked-sm > caption {
    caption-side: top !important;
  }

  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]:before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    margin: 0;
    padding: 0 .5rem 0 0;
    font-style: normal;
    font-weight: bold;
  }

  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]:after {
    clear: both;
    content: "";
    display: block;
  }

  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
    width: 60%;
    margin: 0;
    padding: 0 0 0 .5rem;
    display: inline-block;
  }

  .table.b-table.b-table-stacked-sm > tbody > tr.top-row, .table.b-table.b-table-stacked-sm > tbody > tr.bottom-row {
    display: none;
  }

  .table.b-table.b-table-stacked-sm > tbody > tr > :first-child, .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + td, .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}

@media (max-width: 767.98px) {
  .table.b-table.b-table-stacked-md {
    width: 100%;
    display: block;
  }

  .table.b-table.b-table-stacked-md > caption, .table.b-table.b-table-stacked-md > tbody, .table.b-table.b-table-stacked-md > tbody > tr, .table.b-table.b-table-stacked-md > tbody > tr > td, .table.b-table.b-table-stacked-md > tbody > tr > th {
    display: block;
  }

  .table.b-table.b-table-stacked-md > thead, .table.b-table.b-table-stacked-md > tfoot, .table.b-table.b-table-stacked-md > thead > tr.b-table-top-row, .table.b-table.b-table-stacked-md > thead > tr.b-table-bottom-row, .table.b-table.b-table-stacked-md > tfoot > tr.b-table-top-row, .table.b-table.b-table-stacked-md > tfoot > tr.b-table-bottom-row {
    display: none;
  }

  .table.b-table.b-table-stacked-md > caption {
    caption-side: top !important;
  }

  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]:before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    margin: 0;
    padding: 0 .5rem 0 0;
    font-style: normal;
    font-weight: bold;
  }

  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]:after {
    clear: both;
    content: "";
    display: block;
  }

  .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    width: 60%;
    margin: 0;
    padding: 0 0 0 .5rem;
    display: inline-block;
  }

  .table.b-table.b-table-stacked-md > tbody > tr.top-row, .table.b-table.b-table-stacked-md > tbody > tr.bottom-row {
    display: none;
  }

  .table.b-table.b-table-stacked-md > tbody > tr > :first-child, .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + td, .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}

@media (max-width: 991.98px) {
  .table.b-table.b-table-stacked-lg {
    width: 100%;
    display: block;
  }

  .table.b-table.b-table-stacked-lg > caption, .table.b-table.b-table-stacked-lg > tbody, .table.b-table.b-table-stacked-lg > tbody > tr, .table.b-table.b-table-stacked-lg > tbody > tr > td, .table.b-table.b-table-stacked-lg > tbody > tr > th {
    display: block;
  }

  .table.b-table.b-table-stacked-lg > thead, .table.b-table.b-table-stacked-lg > tfoot, .table.b-table.b-table-stacked-lg > thead > tr.b-table-top-row, .table.b-table.b-table-stacked-lg > thead > tr.b-table-bottom-row, .table.b-table.b-table-stacked-lg > tfoot > tr.b-table-top-row, .table.b-table.b-table-stacked-lg > tfoot > tr.b-table-bottom-row {
    display: none;
  }

  .table.b-table.b-table-stacked-lg > caption {
    caption-side: top !important;
  }

  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]:before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    margin: 0;
    padding: 0 .5rem 0 0;
    font-style: normal;
    font-weight: bold;
  }

  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]:after {
    clear: both;
    content: "";
    display: block;
  }

  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
    width: 60%;
    margin: 0;
    padding: 0 0 0 .5rem;
    display: inline-block;
  }

  .table.b-table.b-table-stacked-lg > tbody > tr.top-row, .table.b-table.b-table-stacked-lg > tbody > tr.bottom-row {
    display: none;
  }

  .table.b-table.b-table-stacked-lg > tbody > tr > :first-child, .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + td, .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}

@media (max-width: 1199.98px) {
  .table.b-table.b-table-stacked-xl {
    width: 100%;
    display: block;
  }

  .table.b-table.b-table-stacked-xl > caption, .table.b-table.b-table-stacked-xl > tbody, .table.b-table.b-table-stacked-xl > tbody > tr, .table.b-table.b-table-stacked-xl > tbody > tr > td, .table.b-table.b-table-stacked-xl > tbody > tr > th {
    display: block;
  }

  .table.b-table.b-table-stacked-xl > thead, .table.b-table.b-table-stacked-xl > tfoot, .table.b-table.b-table-stacked-xl > thead > tr.b-table-top-row, .table.b-table.b-table-stacked-xl > thead > tr.b-table-bottom-row, .table.b-table.b-table-stacked-xl > tfoot > tr.b-table-top-row, .table.b-table.b-table-stacked-xl > tfoot > tr.b-table-bottom-row {
    display: none;
  }

  .table.b-table.b-table-stacked-xl > caption {
    caption-side: top !important;
  }

  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]:before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    margin: 0;
    padding: 0 .5rem 0 0;
    font-style: normal;
    font-weight: bold;
  }

  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]:after {
    clear: both;
    content: "";
    display: block;
  }

  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label] > div {
    width: 60%;
    margin: 0;
    padding: 0 0 0 .5rem;
    display: inline-block;
  }

  .table.b-table.b-table-stacked-xl > tbody > tr.top-row, .table.b-table.b-table-stacked-xl > tbody > tr.bottom-row {
    display: none;
  }

  .table.b-table.b-table-stacked-xl > tbody > tr > :first-child, .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + td, .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}

.table.b-table.b-table-stacked {
  width: 100%;
  display: block;
}

.table.b-table.b-table-stacked > caption, .table.b-table.b-table-stacked > tbody, .table.b-table.b-table-stacked > tbody > tr, .table.b-table.b-table-stacked > tbody > tr > td, .table.b-table.b-table-stacked > tbody > tr > th {
  display: block;
}

.table.b-table.b-table-stacked > thead, .table.b-table.b-table-stacked > tfoot, .table.b-table.b-table-stacked > thead > tr.b-table-top-row, .table.b-table.b-table-stacked > thead > tr.b-table-bottom-row, .table.b-table.b-table-stacked > tfoot > tr.b-table-top-row, .table.b-table.b-table-stacked > tfoot > tr.b-table-bottom-row {
  display: none;
}

.table.b-table.b-table-stacked > caption {
  caption-side: top !important;
}

.table.b-table.b-table-stacked > tbody > tr > [data-label]:before {
  content: attr(data-label);
  width: 40%;
  float: left;
  text-align: right;
  overflow-wrap: break-word;
  margin: 0;
  padding: 0 .5rem 0 0;
  font-style: normal;
  font-weight: bold;
}

.table.b-table.b-table-stacked > tbody > tr > [data-label]:after {
  clear: both;
  content: "";
  display: block;
}

.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  width: 60%;
  margin: 0;
  padding: 0 0 0 .5rem;
  display: inline-block;
}

.table.b-table.b-table-stacked > tbody > tr.top-row, .table.b-table.b-table-stacked > tbody > tr.bottom-row {
  display: none;
}

.table.b-table.b-table-stacked > tbody > tr > :first-child, .table.b-table.b-table-stacked > tbody > tr > [rowspan] + td, .table.b-table.b-table-stacked > tbody > tr > [rowspan] + th {
  border-top-width: 3px;
}

.b-time {
  min-width: 150px;
}

.b-time[aria-disabled="true"] output, .b-time[aria-readonly="true"] output, .b-time output.disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.b-time[aria-disabled="true"] output {
  pointer-events: none;
}

[dir="rtl"] .b-time > .d-flex:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-time .b-time-header {
  margin-bottom: .5rem;
}

.b-time .b-time-header output {
  padding: .25rem;
  font-size: 80%;
}

.b-time .b-time-footer {
  margin-top: .5rem;
}

.b-time .b-time-ampm {
  margin-left: .5rem;
}

.b-toast {
  max-width: 350px;
  backface-visibility: hidden;
  z-index: 1;
  background-clip: padding-box;
  border-radius: .25rem;
  display: block;
  position: relative;
}

.b-toast .toast {
  background-color: #ffffffd9;
}

.b-toast:not(:last-child) {
  margin-bottom: .75rem;
}

.b-toast.b-toast-solid .toast {
  background-color: #fff;
}

.b-toast .toast {
  opacity: 1;
}

.b-toast .toast.fade:not(.show) {
  opacity: 0;
}

.b-toast .toast .toast-body {
  display: block;
}

.b-toast-primary .toast {
  color: #004085;
  background-color: #e6f2ffd9;
  border-color: #b8daffd9;
}

.b-toast-primary .toast .toast-header {
  color: #004085;
  background-color: #cce5ffd9;
  border-bottom-color: #b8daffd9;
}

.b-toast-primary.b-toast-solid .toast {
  background-color: #e6f2ff;
}

.b-toast-secondary .toast {
  color: #383d41;
  background-color: #eff0f1d9;
  border-color: #d6d8dbd9;
}

.b-toast-secondary .toast .toast-header {
  color: #383d41;
  background-color: #e2e3e5d9;
  border-bottom-color: #d6d8dbd9;
}

.b-toast-secondary.b-toast-solid .toast {
  background-color: #eff0f1;
}

.b-toast-success .toast {
  color: #155724;
  background-color: #e6f5e9d9;
  border-color: #c3e6cbd9;
}

.b-toast-success .toast .toast-header {
  color: #155724;
  background-color: #d4eddad9;
  border-bottom-color: #c3e6cbd9;
}

.b-toast-success.b-toast-solid .toast {
  background-color: #e6f5e9;
}

.b-toast-info .toast {
  color: #0c5460;
  background-color: #e5f4f7d9;
  border-color: #bee5ebd9;
}

.b-toast-info .toast .toast-header {
  color: #0c5460;
  background-color: #d1ecf1d9;
  border-bottom-color: #bee5ebd9;
}

.b-toast-info.b-toast-solid .toast {
  background-color: #e5f4f7;
}

.b-toast-warning .toast {
  color: #856404;
  background-color: #fff9e7d9;
  border-color: #ffeebad9;
}

.b-toast-warning .toast .toast-header {
  color: #856404;
  background-color: #fff3cdd9;
  border-bottom-color: #ffeebad9;
}

.b-toast-warning.b-toast-solid .toast {
  background-color: #fff9e7;
}

.b-toast-danger .toast {
  color: #721c24;
  background-color: #fcedeed9;
  border-color: #f5c6cbd9;
}

.b-toast-danger .toast .toast-header {
  color: #721c24;
  background-color: #f8d7dad9;
  border-bottom-color: #f5c6cbd9;
}

.b-toast-danger.b-toast-solid .toast {
  background-color: #fcedee;
}

.b-toast-light .toast {
  color: #818182;
  background-color: #ffffffd9;
  border-color: #fdfdfed9;
}

.b-toast-light .toast .toast-header {
  color: #818182;
  background-color: #fefefed9;
  border-bottom-color: #fdfdfed9;
}

.b-toast-light.b-toast-solid .toast {
  background-color: #fff;
}

.b-toast-dark .toast {
  color: #1b1e21;
  background-color: #e3e5e5d9;
  border-color: #c6c8cad9;
}

.b-toast-dark .toast .toast-header {
  color: #1b1e21;
  background-color: #d6d8d9d9;
  border-bottom-color: #c6c8cad9;
}

.b-toast-dark.b-toast-solid .toast {
  background-color: #e3e5e5;
}

.b-toaster {
  z-index: 1100;
}

.b-toaster .b-toaster-slot {
  display: block;
  position: relative;
}

.b-toaster .b-toaster-slot:empty {
  display: none !important;
}

.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full, .b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  height: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: .5rem;
  right: .5rem;
  overflow: visible;
}

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  max-width: 350px;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
}

.b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot .b-toast, .b-toaster.b-toaster-top-full .b-toaster-slot .toast, .b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast, .b-toaster.b-toaster-bottom-full .b-toaster-slot .toast {
  width: 100%;
  max-width: 100%;
}

.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full {
  top: 0;
}

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot {
  top: .5rem;
}

.b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  bottom: 0;
}

.b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  bottom: .5rem;
}

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-left: auto;
}

.b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-right: auto;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-move, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move {
  transition: transform .175s, -webkit-transform .175s;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade {
  transition-delay: .175s;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active {
  transition-delay: .175s;
  position: absolute;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade {
  transition-delay: 0s;
}

.tooltip.b-tooltip {
  opacity: .9;
  outline: 0;
  display: block;
}

.tooltip.b-tooltip.fade:not(.show) {
  opacity: 0;
}

.tooltip.b-tooltip.show {
  opacity: .9;
}

.tooltip.b-tooltip.noninteractive {
  pointer-events: none;
}

.tooltip.b-tooltip .arrow {
  margin: 0 .25rem;
}

.tooltip.b-tooltip.bs-tooltip-right .arrow, .tooltip.b-tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.bs-tooltip-left .arrow, .tooltip.b-tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow {
  margin: .25rem 0;
}

.tooltip.b-tooltip-primary.bs-tooltip-top .arrow:before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #007bff;
}

.tooltip.b-tooltip-primary.bs-tooltip-right .arrow:before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #007bff;
}

.tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow:before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #007bff;
}

.tooltip.b-tooltip-primary.bs-tooltip-left .arrow:before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #007bff;
}

.tooltip.b-tooltip-primary .tooltip-inner {
  color: #fff;
  background-color: #007bff;
}

.tooltip.b-tooltip-secondary.bs-tooltip-top .arrow:before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #6c757d;
}

.tooltip.b-tooltip-secondary.bs-tooltip-right .arrow:before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #6c757d;
}

.tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow:before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #6c757d;
}

.tooltip.b-tooltip-secondary.bs-tooltip-left .arrow:before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #6c757d;
}

.tooltip.b-tooltip-secondary .tooltip-inner {
  color: #fff;
  background-color: #6c757d;
}

.tooltip.b-tooltip-success.bs-tooltip-top .arrow:before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #28a745;
}

.tooltip.b-tooltip-success.bs-tooltip-right .arrow:before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #28a745;
}

.tooltip.b-tooltip-success.bs-tooltip-bottom .arrow:before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #28a745;
}

.tooltip.b-tooltip-success.bs-tooltip-left .arrow:before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #28a745;
}

.tooltip.b-tooltip-success .tooltip-inner {
  color: #fff;
  background-color: #28a745;
}

.tooltip.b-tooltip-info.bs-tooltip-top .arrow:before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #17a2b8;
}

.tooltip.b-tooltip-info.bs-tooltip-right .arrow:before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #17a2b8;
}

.tooltip.b-tooltip-info.bs-tooltip-bottom .arrow:before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #17a2b8;
}

.tooltip.b-tooltip-info.bs-tooltip-left .arrow:before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #17a2b8;
}

.tooltip.b-tooltip-info .tooltip-inner {
  color: #fff;
  background-color: #17a2b8;
}

.tooltip.b-tooltip-warning.bs-tooltip-top .arrow:before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #ffc107;
}

.tooltip.b-tooltip-warning.bs-tooltip-right .arrow:before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #ffc107;
}

.tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow:before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #ffc107;
}

.tooltip.b-tooltip-warning.bs-tooltip-left .arrow:before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #ffc107;
}

.tooltip.b-tooltip-warning .tooltip-inner {
  color: #212529;
  background-color: #ffc107;
}

.tooltip.b-tooltip-danger.bs-tooltip-top .arrow:before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #dc3545;
}

.tooltip.b-tooltip-danger.bs-tooltip-right .arrow:before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #dc3545;
}

.tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow:before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #dc3545;
}

.tooltip.b-tooltip-danger.bs-tooltip-left .arrow:before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #dc3545;
}

.tooltip.b-tooltip-danger .tooltip-inner {
  color: #fff;
  background-color: #dc3545;
}

.tooltip.b-tooltip-light.bs-tooltip-top .arrow:before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #f8f9fa;
}

.tooltip.b-tooltip-light.bs-tooltip-right .arrow:before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #f8f9fa;
}

.tooltip.b-tooltip-light.bs-tooltip-bottom .arrow:before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #f8f9fa;
}

.tooltip.b-tooltip-light.bs-tooltip-left .arrow:before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #f8f9fa;
}

.tooltip.b-tooltip-light .tooltip-inner {
  color: #212529;
  background-color: #f8f9fa;
}

.tooltip.b-tooltip-dark.bs-tooltip-top .arrow:before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #343a40;
}

.tooltip.b-tooltip-dark.bs-tooltip-right .arrow:before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #343a40;
}

.tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow:before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #343a40;
}

.tooltip.b-tooltip-dark.bs-tooltip-left .arrow:before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #343a40;
}

.tooltip.b-tooltip-dark .tooltip-inner {
  color: #fff;
  background-color: #343a40;
}

.b-icon.bi {
  vertical-align: -.15em;
  display: inline-block;
  overflow: visible;
}

.b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
  transform-origin: center;
  animation: .75s ease-in-out infinite alternate b-icon-animation-cylon;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
  transform-origin: center;
  animation: .75s ease-in-out infinite alternate b-icon-animation-cylon-vertical;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
  transform-origin: center;
  animation: .75s ease-in-out infinite alternate b-icon-animation-fade;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
  transform-origin: center;
  animation: 2s linear infinite b-icon-animation-spin;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
  transform-origin: center;
  animation: 2s linear infinite reverse b-icon-animation-spin;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
  transform-origin: center;
  animation: 1s steps(8, end) infinite b-icon-animation-spin;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
  transform-origin: center;
  animation: 1s steps(8, end) infinite reverse b-icon-animation-spin;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
  transform-origin: center;
  animation: .75s ease-in-out infinite alternate b-icon-animation-throb;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
    animation: none;
  }
}

@keyframes b-icon-animation-cylon {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

@keyframes b-icon-animation-cylon-vertical {
  0% {
    transform: translateY(25%);
  }

  100% {
    transform: translateY(-25%);
  }
}

@keyframes b-icon-animation-fade {
  0% {
    opacity: .1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes b-icon-animation-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes b-icon-animation-throb {
  0% {
    opacity: .5;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.btn .b-icon.bi, .nav-link .b-icon.bi, .dropdown-toggle .b-icon.bi, .dropdown-item .b-icon.bi, .input-group-text .b-icon.bi {
  vertical-align: text-bottom;
  font-size: 125%;
}

@keyframes v-shake {
  59% {
    margin-left: 0;
  }

  60%, 80% {
    margin-left: 2px;
  }

  70%, 90% {
    margin-left: -2px;
  }
}

.v-application .black {
  background-color: #000 !important;
  border-color: #000 !important;
}

.v-application .black--text {
  color: #000 !important;
  caret-color: #000 !important;
}

.v-application .white {
  background-color: #fff !important;
  border-color: #fff !important;
}

.v-application .white--text {
  color: #fff !important;
  caret-color: #fff !important;
}

.v-application .transparent {
  background-color: #0000 !important;
  border-color: #0000 !important;
}

.v-application .transparent--text {
  color: #0000 !important;
  caret-color: #0000 !important;
}

.v-application .red {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}

.v-application .red--text {
  color: #f44336 !important;
  caret-color: #f44336 !important;
}

.v-application .red.lighten-5 {
  background-color: #ffebee !important;
  border-color: #ffebee !important;
}

.v-application .red--text.text--lighten-5 {
  color: #ffebee !important;
  caret-color: #ffebee !important;
}

.v-application .red.lighten-4 {
  background-color: #ffcdd2 !important;
  border-color: #ffcdd2 !important;
}

.v-application .red--text.text--lighten-4 {
  color: #ffcdd2 !important;
  caret-color: #ffcdd2 !important;
}

.v-application .red.lighten-3 {
  background-color: #ef9a9a !important;
  border-color: #ef9a9a !important;
}

.v-application .red--text.text--lighten-3 {
  color: #ef9a9a !important;
  caret-color: #ef9a9a !important;
}

.v-application .red.lighten-2 {
  background-color: #e57373 !important;
  border-color: #e57373 !important;
}

.v-application .red--text.text--lighten-2 {
  color: #e57373 !important;
  caret-color: #e57373 !important;
}

.v-application .red.lighten-1 {
  background-color: #ef5350 !important;
  border-color: #ef5350 !important;
}

.v-application .red--text.text--lighten-1 {
  color: #ef5350 !important;
  caret-color: #ef5350 !important;
}

.v-application .red.darken-1 {
  background-color: #e53935 !important;
  border-color: #e53935 !important;
}

.v-application .red--text.text--darken-1 {
  color: #e53935 !important;
  caret-color: #e53935 !important;
}

.v-application .red.darken-2 {
  background-color: #d32f2f !important;
  border-color: #d32f2f !important;
}

.v-application .red--text.text--darken-2 {
  color: #d32f2f !important;
  caret-color: #d32f2f !important;
}

.v-application .red.darken-3 {
  background-color: #c62828 !important;
  border-color: #c62828 !important;
}

.v-application .red--text.text--darken-3 {
  color: #c62828 !important;
  caret-color: #c62828 !important;
}

.v-application .red.darken-4 {
  background-color: #b71c1c !important;
  border-color: #b71c1c !important;
}

.v-application .red--text.text--darken-4 {
  color: #b71c1c !important;
  caret-color: #b71c1c !important;
}

.v-application .red.accent-1 {
  background-color: #ff8a80 !important;
  border-color: #ff8a80 !important;
}

.v-application .red--text.text--accent-1 {
  color: #ff8a80 !important;
  caret-color: #ff8a80 !important;
}

.v-application .red.accent-2 {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}

.v-application .red--text.text--accent-2 {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}

.v-application .red.accent-3 {
  background-color: #ff1744 !important;
  border-color: #ff1744 !important;
}

.v-application .red--text.text--accent-3 {
  color: #ff1744 !important;
  caret-color: #ff1744 !important;
}

.v-application .red.accent-4 {
  background-color: #d50000 !important;
  border-color: #d50000 !important;
}

.v-application .red--text.text--accent-4 {
  color: #d50000 !important;
  caret-color: #d50000 !important;
}

.v-application .pink {
  background-color: #e91e63 !important;
  border-color: #e91e63 !important;
}

.v-application .pink--text {
  color: #e91e63 !important;
  caret-color: #e91e63 !important;
}

.v-application .pink.lighten-5 {
  background-color: #fce4ec !important;
  border-color: #fce4ec !important;
}

.v-application .pink--text.text--lighten-5 {
  color: #fce4ec !important;
  caret-color: #fce4ec !important;
}

.v-application .pink.lighten-4 {
  background-color: #f8bbd0 !important;
  border-color: #f8bbd0 !important;
}

.v-application .pink--text.text--lighten-4 {
  color: #f8bbd0 !important;
  caret-color: #f8bbd0 !important;
}

.v-application .pink.lighten-3 {
  background-color: #f48fb1 !important;
  border-color: #f48fb1 !important;
}

.v-application .pink--text.text--lighten-3 {
  color: #f48fb1 !important;
  caret-color: #f48fb1 !important;
}

.v-application .pink.lighten-2 {
  background-color: #f06292 !important;
  border-color: #f06292 !important;
}

.v-application .pink--text.text--lighten-2 {
  color: #f06292 !important;
  caret-color: #f06292 !important;
}

.v-application .pink.lighten-1 {
  background-color: #ec407a !important;
  border-color: #ec407a !important;
}

.v-application .pink--text.text--lighten-1 {
  color: #ec407a !important;
  caret-color: #ec407a !important;
}

.v-application .pink.darken-1 {
  background-color: #d81b60 !important;
  border-color: #d81b60 !important;
}

.v-application .pink--text.text--darken-1 {
  color: #d81b60 !important;
  caret-color: #d81b60 !important;
}

.v-application .pink.darken-2 {
  background-color: #c2185b !important;
  border-color: #c2185b !important;
}

.v-application .pink--text.text--darken-2 {
  color: #c2185b !important;
  caret-color: #c2185b !important;
}

.v-application .pink.darken-3 {
  background-color: #ad1457 !important;
  border-color: #ad1457 !important;
}

.v-application .pink--text.text--darken-3 {
  color: #ad1457 !important;
  caret-color: #ad1457 !important;
}

.v-application .pink.darken-4 {
  background-color: #880e4f !important;
  border-color: #880e4f !important;
}

.v-application .pink--text.text--darken-4 {
  color: #880e4f !important;
  caret-color: #880e4f !important;
}

.v-application .pink.accent-1 {
  background-color: #ff80ab !important;
  border-color: #ff80ab !important;
}

.v-application .pink--text.text--accent-1 {
  color: #ff80ab !important;
  caret-color: #ff80ab !important;
}

.v-application .pink.accent-2 {
  background-color: #ff4081 !important;
  border-color: #ff4081 !important;
}

.v-application .pink--text.text--accent-2 {
  color: #ff4081 !important;
  caret-color: #ff4081 !important;
}

.v-application .pink.accent-3 {
  background-color: #f50057 !important;
  border-color: #f50057 !important;
}

.v-application .pink--text.text--accent-3 {
  color: #f50057 !important;
  caret-color: #f50057 !important;
}

.v-application .pink.accent-4 {
  background-color: #c51162 !important;
  border-color: #c51162 !important;
}

.v-application .pink--text.text--accent-4 {
  color: #c51162 !important;
  caret-color: #c51162 !important;
}

.v-application .purple {
  background-color: #9c27b0 !important;
  border-color: #9c27b0 !important;
}

.v-application .purple--text {
  color: #9c27b0 !important;
  caret-color: #9c27b0 !important;
}

.v-application .purple.lighten-5 {
  background-color: #f3e5f5 !important;
  border-color: #f3e5f5 !important;
}

.v-application .purple--text.text--lighten-5 {
  color: #f3e5f5 !important;
  caret-color: #f3e5f5 !important;
}

.v-application .purple.lighten-4 {
  background-color: #e1bee7 !important;
  border-color: #e1bee7 !important;
}

.v-application .purple--text.text--lighten-4 {
  color: #e1bee7 !important;
  caret-color: #e1bee7 !important;
}

.v-application .purple.lighten-3 {
  background-color: #ce93d8 !important;
  border-color: #ce93d8 !important;
}

.v-application .purple--text.text--lighten-3 {
  color: #ce93d8 !important;
  caret-color: #ce93d8 !important;
}

.v-application .purple.lighten-2 {
  background-color: #ba68c8 !important;
  border-color: #ba68c8 !important;
}

.v-application .purple--text.text--lighten-2 {
  color: #ba68c8 !important;
  caret-color: #ba68c8 !important;
}

.v-application .purple.lighten-1 {
  background-color: #ab47bc !important;
  border-color: #ab47bc !important;
}

.v-application .purple--text.text--lighten-1 {
  color: #ab47bc !important;
  caret-color: #ab47bc !important;
}

.v-application .purple.darken-1 {
  background-color: #8e24aa !important;
  border-color: #8e24aa !important;
}

.v-application .purple--text.text--darken-1 {
  color: #8e24aa !important;
  caret-color: #8e24aa !important;
}

.v-application .purple.darken-2 {
  background-color: #7b1fa2 !important;
  border-color: #7b1fa2 !important;
}

.v-application .purple--text.text--darken-2 {
  color: #7b1fa2 !important;
  caret-color: #7b1fa2 !important;
}

.v-application .purple.darken-3 {
  background-color: #6a1b9a !important;
  border-color: #6a1b9a !important;
}

.v-application .purple--text.text--darken-3 {
  color: #6a1b9a !important;
  caret-color: #6a1b9a !important;
}

.v-application .purple.darken-4 {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}

.v-application .purple--text.text--darken-4 {
  color: #4a148c !important;
  caret-color: #4a148c !important;
}

.v-application .purple.accent-1 {
  background-color: #ea80fc !important;
  border-color: #ea80fc !important;
}

.v-application .purple--text.text--accent-1 {
  color: #ea80fc !important;
  caret-color: #ea80fc !important;
}

.v-application .purple.accent-2 {
  background-color: #e040fb !important;
  border-color: #e040fb !important;
}

.v-application .purple--text.text--accent-2 {
  color: #e040fb !important;
  caret-color: #e040fb !important;
}

.v-application .purple.accent-3 {
  background-color: #d500f9 !important;
  border-color: #d500f9 !important;
}

.v-application .purple--text.text--accent-3 {
  color: #d500f9 !important;
  caret-color: #d500f9 !important;
}

.v-application .purple.accent-4 {
  background-color: #a0f !important;
  border-color: #a0f !important;
}

.v-application .purple--text.text--accent-4 {
  color: #a0f !important;
  caret-color: #a0f !important;
}

.v-application .deep-purple {
  background-color: #673ab7 !important;
  border-color: #673ab7 !important;
}

.v-application .deep-purple--text {
  color: #673ab7 !important;
  caret-color: #673ab7 !important;
}

.v-application .deep-purple.lighten-5 {
  background-color: #ede7f6 !important;
  border-color: #ede7f6 !important;
}

.v-application .deep-purple--text.text--lighten-5 {
  color: #ede7f6 !important;
  caret-color: #ede7f6 !important;
}

.v-application .deep-purple.lighten-4 {
  background-color: #d1c4e9 !important;
  border-color: #d1c4e9 !important;
}

.v-application .deep-purple--text.text--lighten-4 {
  color: #d1c4e9 !important;
  caret-color: #d1c4e9 !important;
}

.v-application .deep-purple.lighten-3 {
  background-color: #b39ddb !important;
  border-color: #b39ddb !important;
}

.v-application .deep-purple--text.text--lighten-3 {
  color: #b39ddb !important;
  caret-color: #b39ddb !important;
}

.v-application .deep-purple.lighten-2 {
  background-color: #9575cd !important;
  border-color: #9575cd !important;
}

.v-application .deep-purple--text.text--lighten-2 {
  color: #9575cd !important;
  caret-color: #9575cd !important;
}

.v-application .deep-purple.lighten-1 {
  background-color: #7e57c2 !important;
  border-color: #7e57c2 !important;
}

.v-application .deep-purple--text.text--lighten-1 {
  color: #7e57c2 !important;
  caret-color: #7e57c2 !important;
}

.v-application .deep-purple.darken-1 {
  background-color: #5e35b1 !important;
  border-color: #5e35b1 !important;
}

.v-application .deep-purple--text.text--darken-1 {
  color: #5e35b1 !important;
  caret-color: #5e35b1 !important;
}

.v-application .deep-purple.darken-2 {
  background-color: #512da8 !important;
  border-color: #512da8 !important;
}

.v-application .deep-purple--text.text--darken-2 {
  color: #512da8 !important;
  caret-color: #512da8 !important;
}

.v-application .deep-purple.darken-3 {
  background-color: #4527a0 !important;
  border-color: #4527a0 !important;
}

.v-application .deep-purple--text.text--darken-3 {
  color: #4527a0 !important;
  caret-color: #4527a0 !important;
}

.v-application .deep-purple.darken-4 {
  background-color: #311b92 !important;
  border-color: #311b92 !important;
}

.v-application .deep-purple--text.text--darken-4 {
  color: #311b92 !important;
  caret-color: #311b92 !important;
}

.v-application .deep-purple.accent-1 {
  background-color: #b388ff !important;
  border-color: #b388ff !important;
}

.v-application .deep-purple--text.text--accent-1 {
  color: #b388ff !important;
  caret-color: #b388ff !important;
}

.v-application .deep-purple.accent-2 {
  background-color: #7c4dff !important;
  border-color: #7c4dff !important;
}

.v-application .deep-purple--text.text--accent-2 {
  color: #7c4dff !important;
  caret-color: #7c4dff !important;
}

.v-application .deep-purple.accent-3 {
  background-color: #651fff !important;
  border-color: #651fff !important;
}

.v-application .deep-purple--text.text--accent-3 {
  color: #651fff !important;
  caret-color: #651fff !important;
}

.v-application .deep-purple.accent-4 {
  background-color: #6200ea !important;
  border-color: #6200ea !important;
}

.v-application .deep-purple--text.text--accent-4 {
  color: #6200ea !important;
  caret-color: #6200ea !important;
}

.v-application .indigo {
  background-color: #3f51b5 !important;
  border-color: #3f51b5 !important;
}

.v-application .indigo--text {
  color: #3f51b5 !important;
  caret-color: #3f51b5 !important;
}

.v-application .indigo.lighten-5 {
  background-color: #e8eaf6 !important;
  border-color: #e8eaf6 !important;
}

.v-application .indigo--text.text--lighten-5 {
  color: #e8eaf6 !important;
  caret-color: #e8eaf6 !important;
}

.v-application .indigo.lighten-4 {
  background-color: #c5cae9 !important;
  border-color: #c5cae9 !important;
}

.v-application .indigo--text.text--lighten-4 {
  color: #c5cae9 !important;
  caret-color: #c5cae9 !important;
}

.v-application .indigo.lighten-3 {
  background-color: #9fa8da !important;
  border-color: #9fa8da !important;
}

.v-application .indigo--text.text--lighten-3 {
  color: #9fa8da !important;
  caret-color: #9fa8da !important;
}

.v-application .indigo.lighten-2 {
  background-color: #7986cb !important;
  border-color: #7986cb !important;
}

.v-application .indigo--text.text--lighten-2 {
  color: #7986cb !important;
  caret-color: #7986cb !important;
}

.v-application .indigo.lighten-1 {
  background-color: #5c6bc0 !important;
  border-color: #5c6bc0 !important;
}

.v-application .indigo--text.text--lighten-1 {
  color: #5c6bc0 !important;
  caret-color: #5c6bc0 !important;
}

.v-application .indigo.darken-1 {
  background-color: #3949ab !important;
  border-color: #3949ab !important;
}

.v-application .indigo--text.text--darken-1 {
  color: #3949ab !important;
  caret-color: #3949ab !important;
}

.v-application .indigo.darken-2 {
  background-color: #303f9f !important;
  border-color: #303f9f !important;
}

.v-application .indigo--text.text--darken-2 {
  color: #303f9f !important;
  caret-color: #303f9f !important;
}

.v-application .indigo.darken-3 {
  background-color: #283593 !important;
  border-color: #283593 !important;
}

.v-application .indigo--text.text--darken-3 {
  color: #283593 !important;
  caret-color: #283593 !important;
}

.v-application .indigo.darken-4 {
  background-color: #1a237e !important;
  border-color: #1a237e !important;
}

.v-application .indigo--text.text--darken-4 {
  color: #1a237e !important;
  caret-color: #1a237e !important;
}

.v-application .indigo.accent-1 {
  background-color: #8c9eff !important;
  border-color: #8c9eff !important;
}

.v-application .indigo--text.text--accent-1 {
  color: #8c9eff !important;
  caret-color: #8c9eff !important;
}

.v-application .indigo.accent-2 {
  background-color: #536dfe !important;
  border-color: #536dfe !important;
}

.v-application .indigo--text.text--accent-2 {
  color: #536dfe !important;
  caret-color: #536dfe !important;
}

.v-application .indigo.accent-3 {
  background-color: #3d5afe !important;
  border-color: #3d5afe !important;
}

.v-application .indigo--text.text--accent-3 {
  color: #3d5afe !important;
  caret-color: #3d5afe !important;
}

.v-application .indigo.accent-4 {
  background-color: #304ffe !important;
  border-color: #304ffe !important;
}

.v-application .indigo--text.text--accent-4 {
  color: #304ffe !important;
  caret-color: #304ffe !important;
}

.v-application .blue {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.v-application .blue--text {
  color: #2196f3 !important;
  caret-color: #2196f3 !important;
}

.v-application .blue.lighten-5 {
  background-color: #e3f2fd !important;
  border-color: #e3f2fd !important;
}

.v-application .blue--text.text--lighten-5 {
  color: #e3f2fd !important;
  caret-color: #e3f2fd !important;
}

.v-application .blue.lighten-4 {
  background-color: #bbdefb !important;
  border-color: #bbdefb !important;
}

.v-application .blue--text.text--lighten-4 {
  color: #bbdefb !important;
  caret-color: #bbdefb !important;
}

.v-application .blue.lighten-3 {
  background-color: #90caf9 !important;
  border-color: #90caf9 !important;
}

.v-application .blue--text.text--lighten-3 {
  color: #90caf9 !important;
  caret-color: #90caf9 !important;
}

.v-application .blue.lighten-2 {
  background-color: #64b5f6 !important;
  border-color: #64b5f6 !important;
}

.v-application .blue--text.text--lighten-2 {
  color: #64b5f6 !important;
  caret-color: #64b5f6 !important;
}

.v-application .blue.lighten-1 {
  background-color: #42a5f5 !important;
  border-color: #42a5f5 !important;
}

.v-application .blue--text.text--lighten-1 {
  color: #42a5f5 !important;
  caret-color: #42a5f5 !important;
}

.v-application .blue.darken-1 {
  background-color: #1e88e5 !important;
  border-color: #1e88e5 !important;
}

.v-application .blue--text.text--darken-1 {
  color: #1e88e5 !important;
  caret-color: #1e88e5 !important;
}

.v-application .blue.darken-2 {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}

.v-application .blue--text.text--darken-2 {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}

.v-application .blue.darken-3 {
  background-color: #1565c0 !important;
  border-color: #1565c0 !important;
}

.v-application .blue--text.text--darken-3 {
  color: #1565c0 !important;
  caret-color: #1565c0 !important;
}

.v-application .blue.darken-4 {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}

.v-application .blue--text.text--darken-4 {
  color: #0d47a1 !important;
  caret-color: #0d47a1 !important;
}

.v-application .blue.accent-1 {
  background-color: #82b1ff !important;
  border-color: #82b1ff !important;
}

.v-application .blue--text.text--accent-1 {
  color: #82b1ff !important;
  caret-color: #82b1ff !important;
}

.v-application .blue.accent-2 {
  background-color: #448aff !important;
  border-color: #448aff !important;
}

.v-application .blue--text.text--accent-2 {
  color: #448aff !important;
  caret-color: #448aff !important;
}

.v-application .blue.accent-3 {
  background-color: #2979ff !important;
  border-color: #2979ff !important;
}

.v-application .blue--text.text--accent-3 {
  color: #2979ff !important;
  caret-color: #2979ff !important;
}

.v-application .blue.accent-4 {
  background-color: #2962ff !important;
  border-color: #2962ff !important;
}

.v-application .blue--text.text--accent-4 {
  color: #2962ff !important;
  caret-color: #2962ff !important;
}

.v-application .light-blue {
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
}

.v-application .light-blue--text {
  color: #03a9f4 !important;
  caret-color: #03a9f4 !important;
}

.v-application .light-blue.lighten-5 {
  background-color: #e1f5fe !important;
  border-color: #e1f5fe !important;
}

.v-application .light-blue--text.text--lighten-5 {
  color: #e1f5fe !important;
  caret-color: #e1f5fe !important;
}

.v-application .light-blue.lighten-4 {
  background-color: #b3e5fc !important;
  border-color: #b3e5fc !important;
}

.v-application .light-blue--text.text--lighten-4 {
  color: #b3e5fc !important;
  caret-color: #b3e5fc !important;
}

.v-application .light-blue.lighten-3 {
  background-color: #81d4fa !important;
  border-color: #81d4fa !important;
}

.v-application .light-blue--text.text--lighten-3 {
  color: #81d4fa !important;
  caret-color: #81d4fa !important;
}

.v-application .light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
  border-color: #4fc3f7 !important;
}

.v-application .light-blue--text.text--lighten-2 {
  color: #4fc3f7 !important;
  caret-color: #4fc3f7 !important;
}

.v-application .light-blue.lighten-1 {
  background-color: #29b6f6 !important;
  border-color: #29b6f6 !important;
}

.v-application .light-blue--text.text--lighten-1 {
  color: #29b6f6 !important;
  caret-color: #29b6f6 !important;
}

.v-application .light-blue.darken-1 {
  background-color: #039be5 !important;
  border-color: #039be5 !important;
}

.v-application .light-blue--text.text--darken-1 {
  color: #039be5 !important;
  caret-color: #039be5 !important;
}

.v-application .light-blue.darken-2 {
  background-color: #0288d1 !important;
  border-color: #0288d1 !important;
}

.v-application .light-blue--text.text--darken-2 {
  color: #0288d1 !important;
  caret-color: #0288d1 !important;
}

.v-application .light-blue.darken-3 {
  background-color: #0277bd !important;
  border-color: #0277bd !important;
}

.v-application .light-blue--text.text--darken-3 {
  color: #0277bd !important;
  caret-color: #0277bd !important;
}

.v-application .light-blue.darken-4 {
  background-color: #01579b !important;
  border-color: #01579b !important;
}

.v-application .light-blue--text.text--darken-4 {
  color: #01579b !important;
  caret-color: #01579b !important;
}

.v-application .light-blue.accent-1 {
  background-color: #80d8ff !important;
  border-color: #80d8ff !important;
}

.v-application .light-blue--text.text--accent-1 {
  color: #80d8ff !important;
  caret-color: #80d8ff !important;
}

.v-application .light-blue.accent-2 {
  background-color: #40c4ff !important;
  border-color: #40c4ff !important;
}

.v-application .light-blue--text.text--accent-2 {
  color: #40c4ff !important;
  caret-color: #40c4ff !important;
}

.v-application .light-blue.accent-3 {
  background-color: #00b0ff !important;
  border-color: #00b0ff !important;
}

.v-application .light-blue--text.text--accent-3 {
  color: #00b0ff !important;
  caret-color: #00b0ff !important;
}

.v-application .light-blue.accent-4 {
  background-color: #0091ea !important;
  border-color: #0091ea !important;
}

.v-application .light-blue--text.text--accent-4 {
  color: #0091ea !important;
  caret-color: #0091ea !important;
}

.v-application .cyan {
  background-color: #00bcd4 !important;
  border-color: #00bcd4 !important;
}

.v-application .cyan--text {
  color: #00bcd4 !important;
  caret-color: #00bcd4 !important;
}

.v-application .cyan.lighten-5 {
  background-color: #e0f7fa !important;
  border-color: #e0f7fa !important;
}

.v-application .cyan--text.text--lighten-5 {
  color: #e0f7fa !important;
  caret-color: #e0f7fa !important;
}

.v-application .cyan.lighten-4 {
  background-color: #b2ebf2 !important;
  border-color: #b2ebf2 !important;
}

.v-application .cyan--text.text--lighten-4 {
  color: #b2ebf2 !important;
  caret-color: #b2ebf2 !important;
}

.v-application .cyan.lighten-3 {
  background-color: #80deea !important;
  border-color: #80deea !important;
}

.v-application .cyan--text.text--lighten-3 {
  color: #80deea !important;
  caret-color: #80deea !important;
}

.v-application .cyan.lighten-2 {
  background-color: #4dd0e1 !important;
  border-color: #4dd0e1 !important;
}

.v-application .cyan--text.text--lighten-2 {
  color: #4dd0e1 !important;
  caret-color: #4dd0e1 !important;
}

.v-application .cyan.lighten-1 {
  background-color: #26c6da !important;
  border-color: #26c6da !important;
}

.v-application .cyan--text.text--lighten-1 {
  color: #26c6da !important;
  caret-color: #26c6da !important;
}

.v-application .cyan.darken-1 {
  background-color: #00acc1 !important;
  border-color: #00acc1 !important;
}

.v-application .cyan--text.text--darken-1 {
  color: #00acc1 !important;
  caret-color: #00acc1 !important;
}

.v-application .cyan.darken-2 {
  background-color: #0097a7 !important;
  border-color: #0097a7 !important;
}

.v-application .cyan--text.text--darken-2 {
  color: #0097a7 !important;
  caret-color: #0097a7 !important;
}

.v-application .cyan.darken-3 {
  background-color: #00838f !important;
  border-color: #00838f !important;
}

.v-application .cyan--text.text--darken-3 {
  color: #00838f !important;
  caret-color: #00838f !important;
}

.v-application .cyan.darken-4 {
  background-color: #006064 !important;
  border-color: #006064 !important;
}

.v-application .cyan--text.text--darken-4 {
  color: #006064 !important;
  caret-color: #006064 !important;
}

.v-application .cyan.accent-1 {
  background-color: #84ffff !important;
  border-color: #84ffff !important;
}

.v-application .cyan--text.text--accent-1 {
  color: #84ffff !important;
  caret-color: #84ffff !important;
}

.v-application .cyan.accent-2 {
  background-color: #18ffff !important;
  border-color: #18ffff !important;
}

.v-application .cyan--text.text--accent-2 {
  color: #18ffff !important;
  caret-color: #18ffff !important;
}

.v-application .cyan.accent-3 {
  background-color: #00e5ff !important;
  border-color: #00e5ff !important;
}

.v-application .cyan--text.text--accent-3 {
  color: #00e5ff !important;
  caret-color: #00e5ff !important;
}

.v-application .cyan.accent-4 {
  background-color: #00b8d4 !important;
  border-color: #00b8d4 !important;
}

.v-application .cyan--text.text--accent-4 {
  color: #00b8d4 !important;
  caret-color: #00b8d4 !important;
}

.v-application .teal {
  background-color: #009688 !important;
  border-color: #009688 !important;
}

.v-application .teal--text {
  color: #009688 !important;
  caret-color: #009688 !important;
}

.v-application .teal.lighten-5 {
  background-color: #e0f2f1 !important;
  border-color: #e0f2f1 !important;
}

.v-application .teal--text.text--lighten-5 {
  color: #e0f2f1 !important;
  caret-color: #e0f2f1 !important;
}

.v-application .teal.lighten-4 {
  background-color: #b2dfdb !important;
  border-color: #b2dfdb !important;
}

.v-application .teal--text.text--lighten-4 {
  color: #b2dfdb !important;
  caret-color: #b2dfdb !important;
}

.v-application .teal.lighten-3 {
  background-color: #80cbc4 !important;
  border-color: #80cbc4 !important;
}

.v-application .teal--text.text--lighten-3 {
  color: #80cbc4 !important;
  caret-color: #80cbc4 !important;
}

.v-application .teal.lighten-2 {
  background-color: #4db6ac !important;
  border-color: #4db6ac !important;
}

.v-application .teal--text.text--lighten-2 {
  color: #4db6ac !important;
  caret-color: #4db6ac !important;
}

.v-application .teal.lighten-1 {
  background-color: #26a69a !important;
  border-color: #26a69a !important;
}

.v-application .teal--text.text--lighten-1 {
  color: #26a69a !important;
  caret-color: #26a69a !important;
}

.v-application .teal.darken-1 {
  background-color: #00897b !important;
  border-color: #00897b !important;
}

.v-application .teal--text.text--darken-1 {
  color: #00897b !important;
  caret-color: #00897b !important;
}

.v-application .teal.darken-2 {
  background-color: #00796b !important;
  border-color: #00796b !important;
}

.v-application .teal--text.text--darken-2 {
  color: #00796b !important;
  caret-color: #00796b !important;
}

.v-application .teal.darken-3 {
  background-color: #00695c !important;
  border-color: #00695c !important;
}

.v-application .teal--text.text--darken-3 {
  color: #00695c !important;
  caret-color: #00695c !important;
}

.v-application .teal.darken-4 {
  background-color: #004d40 !important;
  border-color: #004d40 !important;
}

.v-application .teal--text.text--darken-4 {
  color: #004d40 !important;
  caret-color: #004d40 !important;
}

.v-application .teal.accent-1 {
  background-color: #a7ffeb !important;
  border-color: #a7ffeb !important;
}

.v-application .teal--text.text--accent-1 {
  color: #a7ffeb !important;
  caret-color: #a7ffeb !important;
}

.v-application .teal.accent-2 {
  background-color: #64ffda !important;
  border-color: #64ffda !important;
}

.v-application .teal--text.text--accent-2 {
  color: #64ffda !important;
  caret-color: #64ffda !important;
}

.v-application .teal.accent-3 {
  background-color: #1de9b6 !important;
  border-color: #1de9b6 !important;
}

.v-application .teal--text.text--accent-3 {
  color: #1de9b6 !important;
  caret-color: #1de9b6 !important;
}

.v-application .teal.accent-4 {
  background-color: #00bfa5 !important;
  border-color: #00bfa5 !important;
}

.v-application .teal--text.text--accent-4 {
  color: #00bfa5 !important;
  caret-color: #00bfa5 !important;
}

.v-application .green {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

.v-application .green--text {
  color: #4caf50 !important;
  caret-color: #4caf50 !important;
}

.v-application .green.lighten-5 {
  background-color: #e8f5e9 !important;
  border-color: #e8f5e9 !important;
}

.v-application .green--text.text--lighten-5 {
  color: #e8f5e9 !important;
  caret-color: #e8f5e9 !important;
}

.v-application .green.lighten-4 {
  background-color: #c8e6c9 !important;
  border-color: #c8e6c9 !important;
}

.v-application .green--text.text--lighten-4 {
  color: #c8e6c9 !important;
  caret-color: #c8e6c9 !important;
}

.v-application .green.lighten-3 {
  background-color: #a5d6a7 !important;
  border-color: #a5d6a7 !important;
}

.v-application .green--text.text--lighten-3 {
  color: #a5d6a7 !important;
  caret-color: #a5d6a7 !important;
}

.v-application .green.lighten-2 {
  background-color: #81c784 !important;
  border-color: #81c784 !important;
}

.v-application .green--text.text--lighten-2 {
  color: #81c784 !important;
  caret-color: #81c784 !important;
}

.v-application .green.lighten-1 {
  background-color: #66bb6a !important;
  border-color: #66bb6a !important;
}

.v-application .green--text.text--lighten-1 {
  color: #66bb6a !important;
  caret-color: #66bb6a !important;
}

.v-application .green.darken-1 {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}

.v-application .green--text.text--darken-1 {
  color: #43a047 !important;
  caret-color: #43a047 !important;
}

.v-application .green.darken-2 {
  background-color: #388e3c !important;
  border-color: #388e3c !important;
}

.v-application .green--text.text--darken-2 {
  color: #388e3c !important;
  caret-color: #388e3c !important;
}

.v-application .green.darken-3 {
  background-color: #2e7d32 !important;
  border-color: #2e7d32 !important;
}

.v-application .green--text.text--darken-3 {
  color: #2e7d32 !important;
  caret-color: #2e7d32 !important;
}

.v-application .green.darken-4 {
  background-color: #1b5e20 !important;
  border-color: #1b5e20 !important;
}

.v-application .green--text.text--darken-4 {
  color: #1b5e20 !important;
  caret-color: #1b5e20 !important;
}

.v-application .green.accent-1 {
  background-color: #b9f6ca !important;
  border-color: #b9f6ca !important;
}

.v-application .green--text.text--accent-1 {
  color: #b9f6ca !important;
  caret-color: #b9f6ca !important;
}

.v-application .green.accent-2 {
  background-color: #69f0ae !important;
  border-color: #69f0ae !important;
}

.v-application .green--text.text--accent-2 {
  color: #69f0ae !important;
  caret-color: #69f0ae !important;
}

.v-application .green.accent-3 {
  background-color: #00e676 !important;
  border-color: #00e676 !important;
}

.v-application .green--text.text--accent-3 {
  color: #00e676 !important;
  caret-color: #00e676 !important;
}

.v-application .green.accent-4 {
  background-color: #00c853 !important;
  border-color: #00c853 !important;
}

.v-application .green--text.text--accent-4 {
  color: #00c853 !important;
  caret-color: #00c853 !important;
}

.v-application .light-green {
  background-color: #8bc34a !important;
  border-color: #8bc34a !important;
}

.v-application .light-green--text {
  color: #8bc34a !important;
  caret-color: #8bc34a !important;
}

.v-application .light-green.lighten-5 {
  background-color: #f1f8e9 !important;
  border-color: #f1f8e9 !important;
}

.v-application .light-green--text.text--lighten-5 {
  color: #f1f8e9 !important;
  caret-color: #f1f8e9 !important;
}

.v-application .light-green.lighten-4 {
  background-color: #dcedc8 !important;
  border-color: #dcedc8 !important;
}

.v-application .light-green--text.text--lighten-4 {
  color: #dcedc8 !important;
  caret-color: #dcedc8 !important;
}

.v-application .light-green.lighten-3 {
  background-color: #c5e1a5 !important;
  border-color: #c5e1a5 !important;
}

.v-application .light-green--text.text--lighten-3 {
  color: #c5e1a5 !important;
  caret-color: #c5e1a5 !important;
}

.v-application .light-green.lighten-2 {
  background-color: #aed581 !important;
  border-color: #aed581 !important;
}

.v-application .light-green--text.text--lighten-2 {
  color: #aed581 !important;
  caret-color: #aed581 !important;
}

.v-application .light-green.lighten-1 {
  background-color: #9ccc65 !important;
  border-color: #9ccc65 !important;
}

.v-application .light-green--text.text--lighten-1 {
  color: #9ccc65 !important;
  caret-color: #9ccc65 !important;
}

.v-application .light-green.darken-1 {
  background-color: #7cb342 !important;
  border-color: #7cb342 !important;
}

.v-application .light-green--text.text--darken-1 {
  color: #7cb342 !important;
  caret-color: #7cb342 !important;
}

.v-application .light-green.darken-2 {
  background-color: #689f38 !important;
  border-color: #689f38 !important;
}

.v-application .light-green--text.text--darken-2 {
  color: #689f38 !important;
  caret-color: #689f38 !important;
}

.v-application .light-green.darken-3 {
  background-color: #558b2f !important;
  border-color: #558b2f !important;
}

.v-application .light-green--text.text--darken-3 {
  color: #558b2f !important;
  caret-color: #558b2f !important;
}

.v-application .light-green.darken-4 {
  background-color: #33691e !important;
  border-color: #33691e !important;
}

.v-application .light-green--text.text--darken-4 {
  color: #33691e !important;
  caret-color: #33691e !important;
}

.v-application .light-green.accent-1 {
  background-color: #ccff90 !important;
  border-color: #ccff90 !important;
}

.v-application .light-green--text.text--accent-1 {
  color: #ccff90 !important;
  caret-color: #ccff90 !important;
}

.v-application .light-green.accent-2 {
  background-color: #b2ff59 !important;
  border-color: #b2ff59 !important;
}

.v-application .light-green--text.text--accent-2 {
  color: #b2ff59 !important;
  caret-color: #b2ff59 !important;
}

.v-application .light-green.accent-3 {
  background-color: #76ff03 !important;
  border-color: #76ff03 !important;
}

.v-application .light-green--text.text--accent-3 {
  color: #76ff03 !important;
  caret-color: #76ff03 !important;
}

.v-application .light-green.accent-4 {
  background-color: #64dd17 !important;
  border-color: #64dd17 !important;
}

.v-application .light-green--text.text--accent-4 {
  color: #64dd17 !important;
  caret-color: #64dd17 !important;
}

.v-application .lime {
  background-color: #cddc39 !important;
  border-color: #cddc39 !important;
}

.v-application .lime--text {
  color: #cddc39 !important;
  caret-color: #cddc39 !important;
}

.v-application .lime.lighten-5 {
  background-color: #f9fbe7 !important;
  border-color: #f9fbe7 !important;
}

.v-application .lime--text.text--lighten-5 {
  color: #f9fbe7 !important;
  caret-color: #f9fbe7 !important;
}

.v-application .lime.lighten-4 {
  background-color: #f0f4c3 !important;
  border-color: #f0f4c3 !important;
}

.v-application .lime--text.text--lighten-4 {
  color: #f0f4c3 !important;
  caret-color: #f0f4c3 !important;
}

.v-application .lime.lighten-3 {
  background-color: #e6ee9c !important;
  border-color: #e6ee9c !important;
}

.v-application .lime--text.text--lighten-3 {
  color: #e6ee9c !important;
  caret-color: #e6ee9c !important;
}

.v-application .lime.lighten-2 {
  background-color: #dce775 !important;
  border-color: #dce775 !important;
}

.v-application .lime--text.text--lighten-2 {
  color: #dce775 !important;
  caret-color: #dce775 !important;
}

.v-application .lime.lighten-1 {
  background-color: #d4e157 !important;
  border-color: #d4e157 !important;
}

.v-application .lime--text.text--lighten-1 {
  color: #d4e157 !important;
  caret-color: #d4e157 !important;
}

.v-application .lime.darken-1 {
  background-color: #c0ca33 !important;
  border-color: #c0ca33 !important;
}

.v-application .lime--text.text--darken-1 {
  color: #c0ca33 !important;
  caret-color: #c0ca33 !important;
}

.v-application .lime.darken-2 {
  background-color: #afb42b !important;
  border-color: #afb42b !important;
}

.v-application .lime--text.text--darken-2 {
  color: #afb42b !important;
  caret-color: #afb42b !important;
}

.v-application .lime.darken-3 {
  background-color: #9e9d24 !important;
  border-color: #9e9d24 !important;
}

.v-application .lime--text.text--darken-3 {
  color: #9e9d24 !important;
  caret-color: #9e9d24 !important;
}

.v-application .lime.darken-4 {
  background-color: #827717 !important;
  border-color: #827717 !important;
}

.v-application .lime--text.text--darken-4 {
  color: #827717 !important;
  caret-color: #827717 !important;
}

.v-application .lime.accent-1 {
  background-color: #f4ff81 !important;
  border-color: #f4ff81 !important;
}

.v-application .lime--text.text--accent-1 {
  color: #f4ff81 !important;
  caret-color: #f4ff81 !important;
}

.v-application .lime.accent-2 {
  background-color: #eeff41 !important;
  border-color: #eeff41 !important;
}

.v-application .lime--text.text--accent-2 {
  color: #eeff41 !important;
  caret-color: #eeff41 !important;
}

.v-application .lime.accent-3 {
  background-color: #c6ff00 !important;
  border-color: #c6ff00 !important;
}

.v-application .lime--text.text--accent-3 {
  color: #c6ff00 !important;
  caret-color: #c6ff00 !important;
}

.v-application .lime.accent-4 {
  background-color: #aeea00 !important;
  border-color: #aeea00 !important;
}

.v-application .lime--text.text--accent-4 {
  color: #aeea00 !important;
  caret-color: #aeea00 !important;
}

.v-application .yellow {
  background-color: #ffeb3b !important;
  border-color: #ffeb3b !important;
}

.v-application .yellow--text {
  color: #ffeb3b !important;
  caret-color: #ffeb3b !important;
}

.v-application .yellow.lighten-5 {
  background-color: #fffde7 !important;
  border-color: #fffde7 !important;
}

.v-application .yellow--text.text--lighten-5 {
  color: #fffde7 !important;
  caret-color: #fffde7 !important;
}

.v-application .yellow.lighten-4 {
  background-color: #fff9c4 !important;
  border-color: #fff9c4 !important;
}

.v-application .yellow--text.text--lighten-4 {
  color: #fff9c4 !important;
  caret-color: #fff9c4 !important;
}

.v-application .yellow.lighten-3 {
  background-color: #fff59d !important;
  border-color: #fff59d !important;
}

.v-application .yellow--text.text--lighten-3 {
  color: #fff59d !important;
  caret-color: #fff59d !important;
}

.v-application .yellow.lighten-2 {
  background-color: #fff176 !important;
  border-color: #fff176 !important;
}

.v-application .yellow--text.text--lighten-2 {
  color: #fff176 !important;
  caret-color: #fff176 !important;
}

.v-application .yellow.lighten-1 {
  background-color: #ffee58 !important;
  border-color: #ffee58 !important;
}

.v-application .yellow--text.text--lighten-1 {
  color: #ffee58 !important;
  caret-color: #ffee58 !important;
}

.v-application .yellow.darken-1 {
  background-color: #fdd835 !important;
  border-color: #fdd835 !important;
}

.v-application .yellow--text.text--darken-1 {
  color: #fdd835 !important;
  caret-color: #fdd835 !important;
}

.v-application .yellow.darken-2 {
  background-color: #fbc02d !important;
  border-color: #fbc02d !important;
}

.v-application .yellow--text.text--darken-2 {
  color: #fbc02d !important;
  caret-color: #fbc02d !important;
}

.v-application .yellow.darken-3 {
  background-color: #f9a825 !important;
  border-color: #f9a825 !important;
}

.v-application .yellow--text.text--darken-3 {
  color: #f9a825 !important;
  caret-color: #f9a825 !important;
}

.v-application .yellow.darken-4 {
  background-color: #f57f17 !important;
  border-color: #f57f17 !important;
}

.v-application .yellow--text.text--darken-4 {
  color: #f57f17 !important;
  caret-color: #f57f17 !important;
}

.v-application .yellow.accent-1 {
  background-color: #ffff8d !important;
  border-color: #ffff8d !important;
}

.v-application .yellow--text.text--accent-1 {
  color: #ffff8d !important;
  caret-color: #ffff8d !important;
}

.v-application .yellow.accent-2 {
  background-color: #ff0 !important;
  border-color: #ff0 !important;
}

.v-application .yellow--text.text--accent-2 {
  color: #ff0 !important;
  caret-color: #ff0 !important;
}

.v-application .yellow.accent-3 {
  background-color: #ffea00 !important;
  border-color: #ffea00 !important;
}

.v-application .yellow--text.text--accent-3 {
  color: #ffea00 !important;
  caret-color: #ffea00 !important;
}

.v-application .yellow.accent-4 {
  background-color: #ffd600 !important;
  border-color: #ffd600 !important;
}

.v-application .yellow--text.text--accent-4 {
  color: #ffd600 !important;
  caret-color: #ffd600 !important;
}

.v-application .amber {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.v-application .amber--text {
  color: #ffc107 !important;
  caret-color: #ffc107 !important;
}

.v-application .amber.lighten-5 {
  background-color: #fff8e1 !important;
  border-color: #fff8e1 !important;
}

.v-application .amber--text.text--lighten-5 {
  color: #fff8e1 !important;
  caret-color: #fff8e1 !important;
}

.v-application .amber.lighten-4 {
  background-color: #ffecb3 !important;
  border-color: #ffecb3 !important;
}

.v-application .amber--text.text--lighten-4 {
  color: #ffecb3 !important;
  caret-color: #ffecb3 !important;
}

.v-application .amber.lighten-3 {
  background-color: #ffe082 !important;
  border-color: #ffe082 !important;
}

.v-application .amber--text.text--lighten-3 {
  color: #ffe082 !important;
  caret-color: #ffe082 !important;
}

.v-application .amber.lighten-2 {
  background-color: #ffd54f !important;
  border-color: #ffd54f !important;
}

.v-application .amber--text.text--lighten-2 {
  color: #ffd54f !important;
  caret-color: #ffd54f !important;
}

.v-application .amber.lighten-1 {
  background-color: #ffca28 !important;
  border-color: #ffca28 !important;
}

.v-application .amber--text.text--lighten-1 {
  color: #ffca28 !important;
  caret-color: #ffca28 !important;
}

.v-application .amber.darken-1 {
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
}

.v-application .amber--text.text--darken-1 {
  color: #ffb300 !important;
  caret-color: #ffb300 !important;
}

.v-application .amber.darken-2 {
  background-color: #ffa000 !important;
  border-color: #ffa000 !important;
}

.v-application .amber--text.text--darken-2 {
  color: #ffa000 !important;
  caret-color: #ffa000 !important;
}

.v-application .amber.darken-3 {
  background-color: #ff8f00 !important;
  border-color: #ff8f00 !important;
}

.v-application .amber--text.text--darken-3 {
  color: #ff8f00 !important;
  caret-color: #ff8f00 !important;
}

.v-application .amber.darken-4 {
  background-color: #ff6f00 !important;
  border-color: #ff6f00 !important;
}

.v-application .amber--text.text--darken-4 {
  color: #ff6f00 !important;
  caret-color: #ff6f00 !important;
}

.v-application .amber.accent-1 {
  background-color: #ffe57f !important;
  border-color: #ffe57f !important;
}

.v-application .amber--text.text--accent-1 {
  color: #ffe57f !important;
  caret-color: #ffe57f !important;
}

.v-application .amber.accent-2 {
  background-color: #ffd740 !important;
  border-color: #ffd740 !important;
}

.v-application .amber--text.text--accent-2 {
  color: #ffd740 !important;
  caret-color: #ffd740 !important;
}

.v-application .amber.accent-3 {
  background-color: #ffc400 !important;
  border-color: #ffc400 !important;
}

.v-application .amber--text.text--accent-3 {
  color: #ffc400 !important;
  caret-color: #ffc400 !important;
}

.v-application .amber.accent-4 {
  background-color: #ffab00 !important;
  border-color: #ffab00 !important;
}

.v-application .amber--text.text--accent-4 {
  color: #ffab00 !important;
  caret-color: #ffab00 !important;
}

.v-application .orange {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}

.v-application .orange--text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}

.v-application .orange.lighten-5 {
  background-color: #fff3e0 !important;
  border-color: #fff3e0 !important;
}

.v-application .orange--text.text--lighten-5 {
  color: #fff3e0 !important;
  caret-color: #fff3e0 !important;
}

.v-application .orange.lighten-4 {
  background-color: #ffe0b2 !important;
  border-color: #ffe0b2 !important;
}

.v-application .orange--text.text--lighten-4 {
  color: #ffe0b2 !important;
  caret-color: #ffe0b2 !important;
}

.v-application .orange.lighten-3 {
  background-color: #ffcc80 !important;
  border-color: #ffcc80 !important;
}

.v-application .orange--text.text--lighten-3 {
  color: #ffcc80 !important;
  caret-color: #ffcc80 !important;
}

.v-application .orange.lighten-2 {
  background-color: #ffb74d !important;
  border-color: #ffb74d !important;
}

.v-application .orange--text.text--lighten-2 {
  color: #ffb74d !important;
  caret-color: #ffb74d !important;
}

.v-application .orange.lighten-1 {
  background-color: #ffa726 !important;
  border-color: #ffa726 !important;
}

.v-application .orange--text.text--lighten-1 {
  color: #ffa726 !important;
  caret-color: #ffa726 !important;
}

.v-application .orange.darken-1 {
  background-color: #fb8c00 !important;
  border-color: #fb8c00 !important;
}

.v-application .orange--text.text--darken-1 {
  color: #fb8c00 !important;
  caret-color: #fb8c00 !important;
}

.v-application .orange.darken-2 {
  background-color: #f57c00 !important;
  border-color: #f57c00 !important;
}

.v-application .orange--text.text--darken-2 {
  color: #f57c00 !important;
  caret-color: #f57c00 !important;
}

.v-application .orange.darken-3 {
  background-color: #ef6c00 !important;
  border-color: #ef6c00 !important;
}

.v-application .orange--text.text--darken-3 {
  color: #ef6c00 !important;
  caret-color: #ef6c00 !important;
}

.v-application .orange.darken-4 {
  background-color: #e65100 !important;
  border-color: #e65100 !important;
}

.v-application .orange--text.text--darken-4 {
  color: #e65100 !important;
  caret-color: #e65100 !important;
}

.v-application .orange.accent-1 {
  background-color: #ffd180 !important;
  border-color: #ffd180 !important;
}

.v-application .orange--text.text--accent-1 {
  color: #ffd180 !important;
  caret-color: #ffd180 !important;
}

.v-application .orange.accent-2 {
  background-color: #ffab40 !important;
  border-color: #ffab40 !important;
}

.v-application .orange--text.text--accent-2 {
  color: #ffab40 !important;
  caret-color: #ffab40 !important;
}

.v-application .orange.accent-3 {
  background-color: #ff9100 !important;
  border-color: #ff9100 !important;
}

.v-application .orange--text.text--accent-3 {
  color: #ff9100 !important;
  caret-color: #ff9100 !important;
}

.v-application .orange.accent-4 {
  background-color: #ff6d00 !important;
  border-color: #ff6d00 !important;
}

.v-application .orange--text.text--accent-4 {
  color: #ff6d00 !important;
  caret-color: #ff6d00 !important;
}

.v-application .deep-orange {
  background-color: #ff5722 !important;
  border-color: #ff5722 !important;
}

.v-application .deep-orange--text {
  color: #ff5722 !important;
  caret-color: #ff5722 !important;
}

.v-application .deep-orange.lighten-5 {
  background-color: #fbe9e7 !important;
  border-color: #fbe9e7 !important;
}

.v-application .deep-orange--text.text--lighten-5 {
  color: #fbe9e7 !important;
  caret-color: #fbe9e7 !important;
}

.v-application .deep-orange.lighten-4 {
  background-color: #ffccbc !important;
  border-color: #ffccbc !important;
}

.v-application .deep-orange--text.text--lighten-4 {
  color: #ffccbc !important;
  caret-color: #ffccbc !important;
}

.v-application .deep-orange.lighten-3 {
  background-color: #ffab91 !important;
  border-color: #ffab91 !important;
}

.v-application .deep-orange--text.text--lighten-3 {
  color: #ffab91 !important;
  caret-color: #ffab91 !important;
}

.v-application .deep-orange.lighten-2 {
  background-color: #ff8a65 !important;
  border-color: #ff8a65 !important;
}

.v-application .deep-orange--text.text--lighten-2 {
  color: #ff8a65 !important;
  caret-color: #ff8a65 !important;
}

.v-application .deep-orange.lighten-1 {
  background-color: #ff7043 !important;
  border-color: #ff7043 !important;
}

.v-application .deep-orange--text.text--lighten-1 {
  color: #ff7043 !important;
  caret-color: #ff7043 !important;
}

.v-application .deep-orange.darken-1 {
  background-color: #f4511e !important;
  border-color: #f4511e !important;
}

.v-application .deep-orange--text.text--darken-1 {
  color: #f4511e !important;
  caret-color: #f4511e !important;
}

.v-application .deep-orange.darken-2 {
  background-color: #e64a19 !important;
  border-color: #e64a19 !important;
}

.v-application .deep-orange--text.text--darken-2 {
  color: #e64a19 !important;
  caret-color: #e64a19 !important;
}

.v-application .deep-orange.darken-3 {
  background-color: #d84315 !important;
  border-color: #d84315 !important;
}

.v-application .deep-orange--text.text--darken-3 {
  color: #d84315 !important;
  caret-color: #d84315 !important;
}

.v-application .deep-orange.darken-4 {
  background-color: #bf360c !important;
  border-color: #bf360c !important;
}

.v-application .deep-orange--text.text--darken-4 {
  color: #bf360c !important;
  caret-color: #bf360c !important;
}

.v-application .deep-orange.accent-1 {
  background-color: #ff9e80 !important;
  border-color: #ff9e80 !important;
}

.v-application .deep-orange--text.text--accent-1 {
  color: #ff9e80 !important;
  caret-color: #ff9e80 !important;
}

.v-application .deep-orange.accent-2 {
  background-color: #ff6e40 !important;
  border-color: #ff6e40 !important;
}

.v-application .deep-orange--text.text--accent-2 {
  color: #ff6e40 !important;
  caret-color: #ff6e40 !important;
}

.v-application .deep-orange.accent-3 {
  background-color: #ff3d00 !important;
  border-color: #ff3d00 !important;
}

.v-application .deep-orange--text.text--accent-3 {
  color: #ff3d00 !important;
  caret-color: #ff3d00 !important;
}

.v-application .deep-orange.accent-4 {
  background-color: #dd2c00 !important;
  border-color: #dd2c00 !important;
}

.v-application .deep-orange--text.text--accent-4 {
  color: #dd2c00 !important;
  caret-color: #dd2c00 !important;
}

.v-application .brown {
  background-color: #795548 !important;
  border-color: #795548 !important;
}

.v-application .brown--text {
  color: #795548 !important;
  caret-color: #795548 !important;
}

.v-application .brown.lighten-5 {
  background-color: #efebe9 !important;
  border-color: #efebe9 !important;
}

.v-application .brown--text.text--lighten-5 {
  color: #efebe9 !important;
  caret-color: #efebe9 !important;
}

.v-application .brown.lighten-4 {
  background-color: #d7ccc8 !important;
  border-color: #d7ccc8 !important;
}

.v-application .brown--text.text--lighten-4 {
  color: #d7ccc8 !important;
  caret-color: #d7ccc8 !important;
}

.v-application .brown.lighten-3 {
  background-color: #bcaaa4 !important;
  border-color: #bcaaa4 !important;
}

.v-application .brown--text.text--lighten-3 {
  color: #bcaaa4 !important;
  caret-color: #bcaaa4 !important;
}

.v-application .brown.lighten-2 {
  background-color: #a1887f !important;
  border-color: #a1887f !important;
}

.v-application .brown--text.text--lighten-2 {
  color: #a1887f !important;
  caret-color: #a1887f !important;
}

.v-application .brown.lighten-1 {
  background-color: #8d6e63 !important;
  border-color: #8d6e63 !important;
}

.v-application .brown--text.text--lighten-1 {
  color: #8d6e63 !important;
  caret-color: #8d6e63 !important;
}

.v-application .brown.darken-1 {
  background-color: #6d4c41 !important;
  border-color: #6d4c41 !important;
}

.v-application .brown--text.text--darken-1 {
  color: #6d4c41 !important;
  caret-color: #6d4c41 !important;
}

.v-application .brown.darken-2 {
  background-color: #5d4037 !important;
  border-color: #5d4037 !important;
}

.v-application .brown--text.text--darken-2 {
  color: #5d4037 !important;
  caret-color: #5d4037 !important;
}

.v-application .brown.darken-3 {
  background-color: #4e342e !important;
  border-color: #4e342e !important;
}

.v-application .brown--text.text--darken-3 {
  color: #4e342e !important;
  caret-color: #4e342e !important;
}

.v-application .brown.darken-4 {
  background-color: #3e2723 !important;
  border-color: #3e2723 !important;
}

.v-application .brown--text.text--darken-4 {
  color: #3e2723 !important;
  caret-color: #3e2723 !important;
}

.v-application .blue-grey {
  background-color: #607d8b !important;
  border-color: #607d8b !important;
}

.v-application .blue-grey--text {
  color: #607d8b !important;
  caret-color: #607d8b !important;
}

.v-application .blue-grey.lighten-5 {
  background-color: #eceff1 !important;
  border-color: #eceff1 !important;
}

.v-application .blue-grey--text.text--lighten-5 {
  color: #eceff1 !important;
  caret-color: #eceff1 !important;
}

.v-application .blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
  border-color: #cfd8dc !important;
}

.v-application .blue-grey--text.text--lighten-4 {
  color: #cfd8dc !important;
  caret-color: #cfd8dc !important;
}

.v-application .blue-grey.lighten-3 {
  background-color: #b0bec5 !important;
  border-color: #b0bec5 !important;
}

.v-application .blue-grey--text.text--lighten-3 {
  color: #b0bec5 !important;
  caret-color: #b0bec5 !important;
}

.v-application .blue-grey.lighten-2 {
  background-color: #90a4ae !important;
  border-color: #90a4ae !important;
}

.v-application .blue-grey--text.text--lighten-2 {
  color: #90a4ae !important;
  caret-color: #90a4ae !important;
}

.v-application .blue-grey.lighten-1 {
  background-color: #78909c !important;
  border-color: #78909c !important;
}

.v-application .blue-grey--text.text--lighten-1 {
  color: #78909c !important;
  caret-color: #78909c !important;
}

.v-application .blue-grey.darken-1 {
  background-color: #546e7a !important;
  border-color: #546e7a !important;
}

.v-application .blue-grey--text.text--darken-1 {
  color: #546e7a !important;
  caret-color: #546e7a !important;
}

.v-application .blue-grey.darken-2 {
  background-color: #455a64 !important;
  border-color: #455a64 !important;
}

.v-application .blue-grey--text.text--darken-2 {
  color: #455a64 !important;
  caret-color: #455a64 !important;
}

.v-application .blue-grey.darken-3 {
  background-color: #37474f !important;
  border-color: #37474f !important;
}

.v-application .blue-grey--text.text--darken-3 {
  color: #37474f !important;
  caret-color: #37474f !important;
}

.v-application .blue-grey.darken-4 {
  background-color: #263238 !important;
  border-color: #263238 !important;
}

.v-application .blue-grey--text.text--darken-4 {
  color: #263238 !important;
  caret-color: #263238 !important;
}

.v-application .grey {
  background-color: #9e9e9e !important;
  border-color: #9e9e9e !important;
}

.v-application .grey--text {
  color: #9e9e9e !important;
  caret-color: #9e9e9e !important;
}

.v-application .grey.lighten-5 {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}

.v-application .grey--text.text--lighten-5 {
  color: #fafafa !important;
  caret-color: #fafafa !important;
}

.v-application .grey.lighten-4 {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}

.v-application .grey--text.text--lighten-4 {
  color: #f5f5f5 !important;
  caret-color: #f5f5f5 !important;
}

.v-application .grey.lighten-3 {
  background-color: #eee !important;
  border-color: #eee !important;
}

.v-application .grey--text.text--lighten-3 {
  color: #eee !important;
  caret-color: #eee !important;
}

.v-application .grey.lighten-2 {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}

.v-application .grey--text.text--lighten-2 {
  color: #e0e0e0 !important;
  caret-color: #e0e0e0 !important;
}

.v-application .grey.lighten-1 {
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}

.v-application .grey--text.text--lighten-1 {
  color: #bdbdbd !important;
  caret-color: #bdbdbd !important;
}

.v-application .grey.darken-1 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.v-application .grey--text.text--darken-1 {
  color: #757575 !important;
  caret-color: #757575 !important;
}

.v-application .grey.darken-2 {
  background-color: #616161 !important;
  border-color: #616161 !important;
}

.v-application .grey--text.text--darken-2 {
  color: #616161 !important;
  caret-color: #616161 !important;
}

.v-application .grey.darken-3 {
  background-color: #424242 !important;
  border-color: #424242 !important;
}

.v-application .grey--text.text--darken-3 {
  color: #424242 !important;
  caret-color: #424242 !important;
}

.v-application .grey.darken-4 {
  background-color: #212121 !important;
  border-color: #212121 !important;
}

.v-application .grey--text.text--darken-4 {
  color: #212121 !important;
  caret-color: #212121 !important;
}

.v-application .shades.black {
  background-color: #000 !important;
  border-color: #000 !important;
}

.v-application .shades--text.text--black {
  color: #000 !important;
  caret-color: #000 !important;
}

.v-application .shades.white {
  background-color: #fff !important;
  border-color: #fff !important;
}

.v-application .shades--text.text--white {
  color: #fff !important;
  caret-color: #fff !important;
}

.v-application .shades.transparent {
  background-color: #0000 !important;
  border-color: #0000 !important;
}

.v-application .shades--text.text--transparent {
  color: #0000 !important;
  caret-color: #0000 !important;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  tab-size: 4;
  overflow-y: scroll;
}

*, :after, :before {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

:after, :before {
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  margin: 0;
  padding: 0;
}

hr {
  height: 0;
  overflow: visible;
}

details, main {
  display: block;
}

summary {
  display: list-item;
}

small {
  font-size: 80%;
}

[hidden] {
  display: none;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  text-decoration: underline dotted;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

code, kbd, pre, samp {
  font-family: monospace;
}

pre {
  font-size: 1em;
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

input {
  border-radius: 0;
}

[disabled] {
  cursor: default;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

optgroup {
  font-weight: 700;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"], [type="button"], [type="reset"], [type="submit"], button {
  cursor: pointer;
  color: inherit;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

button, input, select, textarea {
  background-color: #0000;
  border-style: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  color: inherit;
  white-space: normal;
  max-width: 100%;
  border: 0;
  display: table;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
}

img {
  border-style: none;
}

progress {
  vertical-align: baseline;
}

@media screen {
  [hidden~="screen"] {
    display: inherit;
  }

  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"] {
  cursor: default;
}

.v-application .elevation-24 {
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f !important;
}

.v-application .elevation-23 {
  box-shadow: 0 11px 14px -7px #0003, 0 23px 36px 3px #00000024, 0 9px 44px 8px #0000001f !important;
}

.v-application .elevation-22 {
  box-shadow: 0 10px 14px -6px #0003, 0 22px 35px 3px #00000024, 0 8px 42px 7px #0000001f !important;
}

.v-application .elevation-21 {
  box-shadow: 0 10px 13px -6px #0003, 0 21px 33px 3px #00000024, 0 8px 40px 7px #0000001f !important;
}

.v-application .elevation-20 {
  box-shadow: 0 10px 13px -6px #0003, 0 20px 31px 3px #00000024, 0 8px 38px 7px #0000001f !important;
}

.v-application .elevation-19 {
  box-shadow: 0 9px 12px -6px #0003, 0 19px 29px 2px #00000024, 0 7px 36px 6px #0000001f !important;
}

.v-application .elevation-18 {
  box-shadow: 0 9px 11px -5px #0003, 0 18px 28px 2px #00000024, 0 7px 34px 6px #0000001f !important;
}

.v-application .elevation-17 {
  box-shadow: 0 8px 11px -5px #0003, 0 17px 26px 2px #00000024, 0 6px 32px 5px #0000001f !important;
}

.v-application .elevation-16 {
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f !important;
}

.v-application .elevation-15 {
  box-shadow: 0 8px 9px -5px #0003, 0 15px 22px 2px #00000024, 0 6px 28px 5px #0000001f !important;
}

.v-application .elevation-14 {
  box-shadow: 0 7px 9px -4px #0003, 0 14px 21px 2px #00000024, 0 5px 26px 4px #0000001f !important;
}

.v-application .elevation-13 {
  box-shadow: 0 7px 8px -4px #0003, 0 13px 19px 2px #00000024, 0 5px 24px 4px #0000001f !important;
}

.v-application .elevation-12 {
  box-shadow: 0 7px 8px -4px #0003, 0 12px 17px 2px #00000024, 0 5px 22px 4px #0000001f !important;
}

.v-application .elevation-11 {
  box-shadow: 0 6px 7px -4px #0003, 0 11px 15px 1px #00000024, 0 4px 20px 3px #0000001f !important;
}

.v-application .elevation-10 {
  box-shadow: 0 6px 6px -3px #0003, 0 10px 14px 1px #00000024, 0 4px 18px 3px #0000001f !important;
}

.v-application .elevation-9 {
  box-shadow: 0 5px 6px -3px #0003, 0 9px 12px 1px #00000024, 0 3px 16px 2px #0000001f !important;
}

.v-application .elevation-8 {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f !important;
}

.v-application .elevation-7 {
  box-shadow: 0 4px 5px -2px #0003, 0 7px 10px 1px #00000024, 0 2px 16px 1px #0000001f !important;
}

.v-application .elevation-6 {
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f !important;
}

.v-application .elevation-5 {
  box-shadow: 0 3px 5px -1px #0003, 0 5px 8px #00000024, 0 1px 14px #0000001f !important;
}

.v-application .elevation-4 {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f !important;
}

.v-application .elevation-3 {
  box-shadow: 0 3px 3px -2px #0003, 0 3px 4px #00000024, 0 1px 8px #0000001f !important;
}

.v-application .elevation-2 {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f !important;
}

.v-application .elevation-1 {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f !important;
}

.v-application .elevation-0 {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f !important;
}

.carousel-transition-enter {
  transform: translate(100%);
}

.carousel-transition-leave, .carousel-transition-leave-to {
  position: absolute;
  top: 0;
  transform: translate(-100%);
}

.carousel-reverse-transition-enter {
  transform: translate(-100%);
}

.carousel-reverse-transition-leave, .carousel-reverse-transition-leave-to {
  position: absolute;
  top: 0;
  transform: translate(100%);
}

.dialog-transition-enter-active, .dialog-transition-leave-active {
  pointer-events: none !important;
}

.dialog-transition-enter, .dialog-transition-leave-to {
  opacity: 0;
  transform: scale(.5);
}

.dialog-transition-enter-to, .dialog-transition-leave {
  opacity: 1;
}

.dialog-bottom-transition-enter, .dialog-bottom-transition-leave-to {
  transform: translateY(100%);
}

.dialog-top-transition-enter, .dialog-top-transition-leave-to {
  transform: translateY(-100%);
}

.picker-reverse-transition-enter-active, .picker-reverse-transition-leave-active, .picker-transition-enter-active, .picker-transition-leave-active {
  transition: all .3s cubic-bezier(0, 0, .2, 1);
}

.picker-reverse-transition-enter, .picker-reverse-transition-leave-to, .picker-transition-enter, .picker-transition-leave-to {
  opacity: 0;
}

.picker-reverse-transition-leave, .picker-reverse-transition-leave-active, .picker-reverse-transition-leave-to, .picker-transition-leave, .picker-transition-leave-active, .picker-transition-leave-to {
  position: absolute !important;
}

.picker-transition-enter {
  transform: translateY(100%);
}

.picker-reverse-transition-enter, .picker-transition-leave-to {
  transform: translateY(-100%);
}

.picker-reverse-transition-leave-to {
  transform: translateY(100%);
}

.picker-title-transition-enter-to, .picker-title-transition-leave {
  transform: translate(0);
}

.picker-title-transition-enter {
  transform: translate(-100%);
}

.picker-title-transition-leave-to {
  opacity: 0;
  transform: translate(100%);
}

.picker-title-transition-leave, .picker-title-transition-leave-active, .picker-title-transition-leave-to {
  position: absolute !important;
}

.tab-transition-enter {
  transform: translate(100%);
}

.tab-transition-leave, .tab-transition-leave-active {
  position: absolute;
  top: 0;
}

.tab-transition-leave-to {
  position: absolute;
}

.tab-reverse-transition-enter, .tab-transition-leave-to {
  transform: translate(-100%);
}

.tab-reverse-transition-leave, .tab-reverse-transition-leave-to {
  position: absolute;
  top: 0;
  transform: translate(100%);
}

.expand-transition-enter-active, .expand-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.expand-transition-move {
  transition: transform .6s;
}

.expand-x-transition-enter-active, .expand-x-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.expand-x-transition-move {
  transition: transform .6s;
}

.scale-transition-enter-active, .scale-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.scale-transition-move {
  transition: transform .6s;
}

.scale-transition-enter, .scale-transition-leave, .scale-transition-leave-to {
  opacity: 0;
  transform: scale(0);
}

.scale-rotate-transition-enter-active, .scale-rotate-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.scale-rotate-transition-move {
  transition: transform .6s;
}

.scale-rotate-transition-enter, .scale-rotate-transition-leave, .scale-rotate-transition-leave-to {
  opacity: 0;
  transform: scale(0)rotate(-45deg);
}

.scale-rotate-reverse-transition-enter-active, .scale-rotate-reverse-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.scale-rotate-reverse-transition-move {
  transition: transform .6s;
}

.scale-rotate-reverse-transition-enter, .scale-rotate-reverse-transition-leave, .scale-rotate-reverse-transition-leave-to {
  opacity: 0;
  transform: scale(0)rotate(45deg);
}

.message-transition-enter-active, .message-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.message-transition-move {
  transition: transform .6s;
}

.message-transition-enter, .message-transition-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.message-transition-leave, .message-transition-leave-active {
  position: absolute;
}

.slide-y-transition-enter-active, .slide-y-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.slide-y-transition-move {
  transition: transform .6s;
}

.slide-y-transition-enter, .slide-y-transition-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.slide-y-reverse-transition-enter-active, .slide-y-reverse-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.slide-y-reverse-transition-move {
  transition: transform .6s;
}

.slide-y-reverse-transition-enter, .slide-y-reverse-transition-leave-to {
  opacity: 0;
  transform: translateY(15px);
}

.scroll-y-transition-enter-active, .scroll-y-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.scroll-y-transition-move {
  transition: transform .6s;
}

.scroll-y-transition-enter, .scroll-y-transition-leave-to {
  opacity: 0;
}

.scroll-y-transition-enter {
  transform: translateY(-15px);
}

.scroll-y-transition-leave-to {
  transform: translateY(15px);
}

.scroll-y-reverse-transition-enter-active, .scroll-y-reverse-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.scroll-y-reverse-transition-move {
  transition: transform .6s;
}

.scroll-y-reverse-transition-enter, .scroll-y-reverse-transition-leave-to {
  opacity: 0;
}

.scroll-y-reverse-transition-enter {
  transform: translateY(15px);
}

.scroll-y-reverse-transition-leave-to {
  transform: translateY(-15px);
}

.scroll-x-transition-enter-active, .scroll-x-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.scroll-x-transition-move {
  transition: transform .6s;
}

.scroll-x-transition-enter, .scroll-x-transition-leave-to {
  opacity: 0;
}

.scroll-x-transition-enter {
  transform: translateX(-15px);
}

.scroll-x-transition-leave-to {
  transform: translateX(15px);
}

.scroll-x-reverse-transition-enter-active, .scroll-x-reverse-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.scroll-x-reverse-transition-move {
  transition: transform .6s;
}

.scroll-x-reverse-transition-enter, .scroll-x-reverse-transition-leave-to {
  opacity: 0;
}

.scroll-x-reverse-transition-enter {
  transform: translateX(15px);
}

.scroll-x-reverse-transition-leave-to {
  transform: translateX(-15px);
}

.slide-x-transition-enter-active, .slide-x-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.slide-x-transition-move {
  transition: transform .6s;
}

.slide-x-transition-enter, .slide-x-transition-leave-to {
  opacity: 0;
  transform: translateX(-15px);
}

.slide-x-reverse-transition-enter-active, .slide-x-reverse-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.slide-x-reverse-transition-move {
  transition: transform .6s;
}

.slide-x-reverse-transition-enter, .slide-x-reverse-transition-leave-to {
  opacity: 0;
  transform: translateX(15px);
}

.fade-transition-enter-active, .fade-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.fade-transition-move {
  transition: transform .6s;
}

.fade-transition-enter, .fade-transition-leave-to {
  opacity: 0 !important;
}

.fab-transition-enter-active, .fab-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.fab-transition-move {
  transition: transform .6s;
}

.fab-transition-enter, .fab-transition-leave-to {
  transform: scale(0)rotate(-45deg);
}

.v-application .blockquote {
  padding: 16px 0 16px 24px;
  font-size: 18px;
  font-weight: 300;
}

.v-application code, .v-application kbd {
  border-radius: 3px;
  font-size: 85%;
  font-weight: 400;
}

.v-application code {
  padding: .2em .4em;
}

.v-application kbd {
  padding: .2em .4rem;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.theme--light.v-application code {
  color: currentColor;
  background-color: #0000000d;
}

.theme--light.v-application kbd {
  color: #fff;
  background: #212529;
}

.theme--dark.v-application code {
  color: currentColor;
  background-color: #ffffff1a;
}

.theme--dark.v-application kbd {
  color: #fff;
  background: #212529;
}

html {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: #0000;
  font-size: 16px;
  overflow-x: hidden;
}

html.overflow-y-hidden {
  overflow-y: hidden !important;
}

.v-application {
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

.v-application ::-ms-clear, .v-application ::-ms-reveal {
  display: none;
}

@supports (-webkit-touch-callout:none) {
  body {
    cursor: pointer;
  }
}

.v-application .theme--light.heading {
  color: #000000de;
}

.v-application .theme--dark.heading {
  color: #fff;
}

.v-application ol, .v-application ul {
  padding-left: 24px;
}

.v-application .display-4 {
  line-height: 6rem;
  letter-spacing: -.015625em !important;
  font-size: 6rem !important;
}

.v-application .display-3, .v-application .display-4 {
  font-weight: 300;
  font-family: Roboto, sans-serif !important;
}

.v-application .display-3 {
  line-height: 3.75rem;
  letter-spacing: -.00833333em !important;
  font-size: 3.75rem !important;
}

.v-application .display-2 {
  line-height: 3.125rem;
  letter-spacing: normal !important;
  font-size: 3rem !important;
}

.v-application .display-1, .v-application .display-2 {
  font-weight: 400;
  font-family: Roboto, sans-serif !important;
}

.v-application .display-1 {
  line-height: 2.5rem;
  letter-spacing: .00735294em !important;
  font-size: 2.125rem !important;
}

.v-application .headline {
  font-weight: 400;
  letter-spacing: normal !important;
  font-size: 1.5rem !important;
}

.v-application .headline, .v-application .title {
  line-height: 2rem;
  font-family: Roboto, sans-serif !important;
}

.v-application .title {
  font-weight: 500;
  letter-spacing: .0125em !important;
  font-size: 1.25rem !important;
}

.v-application .subtitle-2 {
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: .00714286em !important;
  font-family: Roboto, sans-serif !important;
  font-size: .875rem !important;
}

.v-application .subtitle-1 {
  line-height: 1.75rem;
  letter-spacing: .009375em !important;
  font-size: 1rem !important;
}

.v-application .body-2, .v-application .subtitle-1 {
  font-weight: 400;
  font-family: Roboto, sans-serif !important;
}

.v-application .body-2 {
  line-height: 1.25rem;
  letter-spacing: .0178571em !important;
  font-size: .875rem !important;
}

.v-application .body-1 {
  line-height: 1.5rem;
  letter-spacing: .03125em !important;
  font-size: 1rem !important;
}

.v-application .body-1, .v-application .caption {
  font-weight: 400;
  font-family: Roboto, sans-serif !important;
}

.v-application .caption {
  line-height: 1.25rem;
  letter-spacing: .0333333em !important;
  font-size: .75rem !important;
}

.v-application .overline {
  text-transform: uppercase;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: .166667em !important;
  font-family: Roboto, sans-serif !important;
  font-size: .75rem !important;
}

.v-application p {
  margin-bottom: 16px;
}

@media only print {
  .v-application .hidden-print-only {
    display: none !important;
  }
}

@media only screen {
  .v-application .hidden-screen-only {
    display: none !important;
  }
}

@media only screen and (max-width: 599.98px) {
  .v-application .hidden-xs-only {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 959.98px) {
  .v-application .hidden-sm-only {
    display: none !important;
  }
}

@media only screen and (max-width: 959.98px) {
  .v-application .hidden-sm-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .v-application .hidden-sm-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1263.98px) {
  .v-application .hidden-md-only {
    display: none !important;
  }
}

@media only screen and (max-width: 1263.98px) {
  .v-application .hidden-md-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 960px) {
  .v-application .hidden-md-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 1264px) and (max-width: 1903.98px) {
  .v-application .hidden-lg-only {
    display: none !important;
  }
}

@media only screen and (max-width: 1903.98px) {
  .v-application .hidden-lg-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 1264px) {
  .v-application .hidden-lg-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 1904px) {
  .v-application .hidden-xl-only {
    display: none !important;
  }
}

.d-sr-only, .d-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  white-space: nowrap !important;
  width: 1px !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.v-application .font-weight-thin {
  font-weight: 100 !important;
}

.v-application .font-weight-light {
  font-weight: 300 !important;
}

.v-application .font-weight-regular {
  font-weight: 400 !important;
}

.v-application .font-weight-medium {
  font-weight: 500 !important;
}

.v-application .font-weight-bold {
  font-weight: 700 !important;
}

.v-application .font-weight-black {
  font-weight: 900 !important;
}

.v-application .font-italic {
  font-style: italic !important;
}

.v-application .transition-fast-out-slow-in {
  transition: all .3s cubic-bezier(.4, 0, .2, 1) !important;
}

.v-application .transition-linear-out-slow-in {
  transition: all .3s cubic-bezier(0, 0, .2, 1) !important;
}

.v-application .transition-fast-out-linear-in {
  transition: all .3s cubic-bezier(.4, 0, 1, 1) !important;
}

.v-application .transition-ease-in-out {
  transition: all .3s cubic-bezier(.4, 0, .6, 1) !important;
}

.v-application .transition-fast-in-fast-out {
  transition: all .3s cubic-bezier(.25, .8, .25, 1) !important;
}

.v-application .transition-swing {
  transition: all .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.v-application .overflow-auto {
  overflow: auto !important;
}

.v-application .overflow-hidden {
  overflow: hidden !important;
}

.v-application .overflow-visible {
  overflow: visible !important;
}

.v-application .overflow-x-auto {
  overflow-x: auto !important;
}

.v-application .overflow-x-hidden {
  overflow-x: hidden !important;
}

.v-application .overflow-y-auto {
  overflow-y: auto !important;
}

.v-application .overflow-y-hidden {
  overflow-y: hidden !important;
}

.v-application .d-none {
  display: none !important;
}

.v-application .d-inline {
  display: inline !important;
}

.v-application .d-inline-block {
  display: inline-block !important;
}

.v-application .d-block {
  display: block !important;
}

.v-application .d-table {
  display: table !important;
}

.v-application .d-table-row {
  display: table-row !important;
}

.v-application .d-table-cell {
  display: table-cell !important;
}

.v-application .d-flex {
  display: flex !important;
}

.v-application .d-inline-flex {
  display: inline-flex !important;
}

.v-application .float-none {
  float: none !important;
}

.v-application .float-left {
  float: left !important;
}

.v-application .float-right {
  float: right !important;
}

.v-application--is-rtl .float-end {
  float: left !important;
}

.v-application--is-ltr .float-end, .v-application--is-rtl .float-start {
  float: right !important;
}

.v-application--is-ltr .float-start {
  float: left !important;
}

.v-application .flex-fill {
  flex: auto !important;
}

.v-application .flex-row {
  flex-direction: row !important;
}

.v-application .flex-column {
  flex-direction: column !important;
}

.v-application .flex-row-reverse {
  flex-direction: row-reverse !important;
}

.v-application .flex-column-reverse {
  flex-direction: column-reverse !important;
}

.v-application .flex-grow-0 {
  flex-grow: 0 !important;
}

.v-application .flex-grow-1 {
  flex-grow: 1 !important;
}

.v-application .flex-shrink-0 {
  flex-shrink: 0 !important;
}

.v-application .flex-shrink-1 {
  flex-shrink: 1 !important;
}

.v-application .flex-wrap {
  flex-wrap: wrap !important;
}

.v-application .flex-nowrap {
  flex-wrap: nowrap !important;
}

.v-application .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.v-application .justify-start {
  justify-content: flex-start !important;
}

.v-application .justify-end {
  justify-content: flex-end !important;
}

.v-application .justify-center {
  justify-content: center !important;
}

.v-application .justify-space-between {
  justify-content: space-between !important;
}

.v-application .justify-space-around {
  justify-content: space-around !important;
}

.v-application .align-start {
  align-items: flex-start !important;
}

.v-application .align-end {
  align-items: flex-end !important;
}

.v-application .align-center {
  align-items: center !important;
}

.v-application .align-baseline {
  align-items: baseline !important;
}

.v-application .align-stretch {
  align-items: stretch !important;
}

.v-application .align-content-start {
  align-content: flex-start !important;
}

.v-application .align-content-end {
  align-content: flex-end !important;
}

.v-application .align-content-center {
  align-content: center !important;
}

.v-application .align-content-space-between {
  align-content: space-between !important;
}

.v-application .align-content-space-around {
  align-content: space-around !important;
}

.v-application .align-content-stretch {
  align-content: stretch !important;
}

.v-application .align-self-auto {
  align-self: auto !important;
}

.v-application .align-self-start {
  align-self: flex-start !important;
}

.v-application .align-self-end {
  align-self: flex-end !important;
}

.v-application .align-self-center {
  align-self: center !important;
}

.v-application .align-self-baseline {
  align-self: baseline !important;
}

.v-application .align-self-stretch {
  align-self: stretch !important;
}

.v-application .order-first {
  order: -1 !important;
}

.v-application .order-0 {
  order: 0 !important;
}

.v-application .order-1 {
  order: 1 !important;
}

.v-application .order-2 {
  order: 2 !important;
}

.v-application .order-3 {
  order: 3 !important;
}

.v-application .order-4 {
  order: 4 !important;
}

.v-application .order-5 {
  order: 5 !important;
}

.v-application .order-6 {
  order: 6 !important;
}

.v-application .order-7 {
  order: 7 !important;
}

.v-application .order-8 {
  order: 8 !important;
}

.v-application .order-9 {
  order: 9 !important;
}

.v-application .order-10 {
  order: 10 !important;
}

.v-application .order-11 {
  order: 11 !important;
}

.v-application .order-12 {
  order: 12 !important;
}

.v-application .order-last {
  order: 13 !important;
}

.v-application .ma-0 {
  margin: 0 !important;
}

.v-application .ma-1 {
  margin: 4px !important;
}

.v-application .ma-2 {
  margin: 8px !important;
}

.v-application .ma-3 {
  margin: 12px !important;
}

.v-application .ma-4 {
  margin: 16px !important;
}

.v-application .ma-5 {
  margin: 20px !important;
}

.v-application .ma-6 {
  margin: 24px !important;
}

.v-application .ma-7 {
  margin: 28px !important;
}

.v-application .ma-8 {
  margin: 32px !important;
}

.v-application .ma-9 {
  margin: 36px !important;
}

.v-application .ma-10 {
  margin: 40px !important;
}

.v-application .ma-11 {
  margin: 44px !important;
}

.v-application .ma-12 {
  margin: 48px !important;
}

.v-application .ma-13 {
  margin: 52px !important;
}

.v-application .ma-14 {
  margin: 56px !important;
}

.v-application .ma-15 {
  margin: 60px !important;
}

.v-application .ma-16 {
  margin: 64px !important;
}

.v-application .ma-auto {
  margin: auto !important;
}

.v-application .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-application .mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.v-application .mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.v-application .mx-3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.v-application .mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.v-application .mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.v-application .mx-6 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.v-application .mx-7 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.v-application .mx-8 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.v-application .mx-9 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.v-application .mx-10 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.v-application .mx-11 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.v-application .mx-12 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.v-application .mx-13 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}

.v-application .mx-14 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.v-application .mx-15 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.v-application .mx-16 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.v-application .mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.v-application .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.v-application .my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.v-application .my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.v-application .my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.v-application .my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.v-application .my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.v-application .my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.v-application .my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.v-application .my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.v-application .my-9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.v-application .my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.v-application .my-11 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.v-application .my-12 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.v-application .my-13 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.v-application .my-14 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.v-application .my-15 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.v-application .my-16 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.v-application .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.v-application .mt-0 {
  margin-top: 0 !important;
}

.v-application .mt-1 {
  margin-top: 4px !important;
}

.v-application .mt-2 {
  margin-top: 8px !important;
}

.v-application .mt-3 {
  margin-top: 12px !important;
}

.v-application .mt-4 {
  margin-top: 16px !important;
}

.v-application .mt-5 {
  margin-top: 20px !important;
}

.v-application .mt-6 {
  margin-top: 24px !important;
}

.v-application .mt-7 {
  margin-top: 28px !important;
}

.v-application .mt-8 {
  margin-top: 32px !important;
}

.v-application .mt-9 {
  margin-top: 36px !important;
}

.v-application .mt-10 {
  margin-top: 40px !important;
}

.v-application .mt-11 {
  margin-top: 44px !important;
}

.v-application .mt-12 {
  margin-top: 48px !important;
}

.v-application .mt-13 {
  margin-top: 52px !important;
}

.v-application .mt-14 {
  margin-top: 56px !important;
}

.v-application .mt-15 {
  margin-top: 60px !important;
}

.v-application .mt-16 {
  margin-top: 64px !important;
}

.v-application .mt-auto {
  margin-top: auto !important;
}

.v-application .mr-0 {
  margin-right: 0 !important;
}

.v-application .mr-1 {
  margin-right: 4px !important;
}

.v-application .mr-2 {
  margin-right: 8px !important;
}

.v-application .mr-3 {
  margin-right: 12px !important;
}

.v-application .mr-4 {
  margin-right: 16px !important;
}

.v-application .mr-5 {
  margin-right: 20px !important;
}

.v-application .mr-6 {
  margin-right: 24px !important;
}

.v-application .mr-7 {
  margin-right: 28px !important;
}

.v-application .mr-8 {
  margin-right: 32px !important;
}

.v-application .mr-9 {
  margin-right: 36px !important;
}

.v-application .mr-10 {
  margin-right: 40px !important;
}

.v-application .mr-11 {
  margin-right: 44px !important;
}

.v-application .mr-12 {
  margin-right: 48px !important;
}

.v-application .mr-13 {
  margin-right: 52px !important;
}

.v-application .mr-14 {
  margin-right: 56px !important;
}

.v-application .mr-15 {
  margin-right: 60px !important;
}

.v-application .mr-16 {
  margin-right: 64px !important;
}

.v-application .mr-auto {
  margin-right: auto !important;
}

.v-application .mb-0 {
  margin-bottom: 0 !important;
}

.v-application .mb-1 {
  margin-bottom: 4px !important;
}

.v-application .mb-2 {
  margin-bottom: 8px !important;
}

.v-application .mb-3 {
  margin-bottom: 12px !important;
}

.v-application .mb-4 {
  margin-bottom: 16px !important;
}

.v-application .mb-5 {
  margin-bottom: 20px !important;
}

.v-application .mb-6 {
  margin-bottom: 24px !important;
}

.v-application .mb-7 {
  margin-bottom: 28px !important;
}

.v-application .mb-8 {
  margin-bottom: 32px !important;
}

.v-application .mb-9 {
  margin-bottom: 36px !important;
}

.v-application .mb-10 {
  margin-bottom: 40px !important;
}

.v-application .mb-11 {
  margin-bottom: 44px !important;
}

.v-application .mb-12 {
  margin-bottom: 48px !important;
}

.v-application .mb-13 {
  margin-bottom: 52px !important;
}

.v-application .mb-14 {
  margin-bottom: 56px !important;
}

.v-application .mb-15 {
  margin-bottom: 60px !important;
}

.v-application .mb-16 {
  margin-bottom: 64px !important;
}

.v-application .mb-auto {
  margin-bottom: auto !important;
}

.v-application .ml-0 {
  margin-left: 0 !important;
}

.v-application .ml-1 {
  margin-left: 4px !important;
}

.v-application .ml-2 {
  margin-left: 8px !important;
}

.v-application .ml-3 {
  margin-left: 12px !important;
}

.v-application .ml-4 {
  margin-left: 16px !important;
}

.v-application .ml-5 {
  margin-left: 20px !important;
}

.v-application .ml-6 {
  margin-left: 24px !important;
}

.v-application .ml-7 {
  margin-left: 28px !important;
}

.v-application .ml-8 {
  margin-left: 32px !important;
}

.v-application .ml-9 {
  margin-left: 36px !important;
}

.v-application .ml-10 {
  margin-left: 40px !important;
}

.v-application .ml-11 {
  margin-left: 44px !important;
}

.v-application .ml-12 {
  margin-left: 48px !important;
}

.v-application .ml-13 {
  margin-left: 52px !important;
}

.v-application .ml-14 {
  margin-left: 56px !important;
}

.v-application .ml-15 {
  margin-left: 60px !important;
}

.v-application .ml-16 {
  margin-left: 64px !important;
}

.v-application .ml-auto {
  margin-left: auto !important;
}

.v-application--is-ltr .ms-0 {
  margin-left: 0 !important;
}

.v-application--is-rtl .ms-0 {
  margin-right: 0 !important;
}

.v-application--is-ltr .ms-1 {
  margin-left: 4px !important;
}

.v-application--is-rtl .ms-1 {
  margin-right: 4px !important;
}

.v-application--is-ltr .ms-2 {
  margin-left: 8px !important;
}

.v-application--is-rtl .ms-2 {
  margin-right: 8px !important;
}

.v-application--is-ltr .ms-3 {
  margin-left: 12px !important;
}

.v-application--is-rtl .ms-3 {
  margin-right: 12px !important;
}

.v-application--is-ltr .ms-4 {
  margin-left: 16px !important;
}

.v-application--is-rtl .ms-4 {
  margin-right: 16px !important;
}

.v-application--is-ltr .ms-5 {
  margin-left: 20px !important;
}

.v-application--is-rtl .ms-5 {
  margin-right: 20px !important;
}

.v-application--is-ltr .ms-6 {
  margin-left: 24px !important;
}

.v-application--is-rtl .ms-6 {
  margin-right: 24px !important;
}

.v-application--is-ltr .ms-7 {
  margin-left: 28px !important;
}

.v-application--is-rtl .ms-7 {
  margin-right: 28px !important;
}

.v-application--is-ltr .ms-8 {
  margin-left: 32px !important;
}

.v-application--is-rtl .ms-8 {
  margin-right: 32px !important;
}

.v-application--is-ltr .ms-9 {
  margin-left: 36px !important;
}

.v-application--is-rtl .ms-9 {
  margin-right: 36px !important;
}

.v-application--is-ltr .ms-10 {
  margin-left: 40px !important;
}

.v-application--is-rtl .ms-10 {
  margin-right: 40px !important;
}

.v-application--is-ltr .ms-11 {
  margin-left: 44px !important;
}

.v-application--is-rtl .ms-11 {
  margin-right: 44px !important;
}

.v-application--is-ltr .ms-12 {
  margin-left: 48px !important;
}

.v-application--is-rtl .ms-12 {
  margin-right: 48px !important;
}

.v-application--is-ltr .ms-13 {
  margin-left: 52px !important;
}

.v-application--is-rtl .ms-13 {
  margin-right: 52px !important;
}

.v-application--is-ltr .ms-14 {
  margin-left: 56px !important;
}

.v-application--is-rtl .ms-14 {
  margin-right: 56px !important;
}

.v-application--is-ltr .ms-15 {
  margin-left: 60px !important;
}

.v-application--is-rtl .ms-15 {
  margin-right: 60px !important;
}

.v-application--is-ltr .ms-16 {
  margin-left: 64px !important;
}

.v-application--is-rtl .ms-16 {
  margin-right: 64px !important;
}

.v-application--is-ltr .ms-auto {
  margin-left: auto !important;
}

.v-application--is-rtl .ms-auto {
  margin-right: auto !important;
}

.v-application--is-ltr .me-0 {
  margin-right: 0 !important;
}

.v-application--is-rtl .me-0 {
  margin-left: 0 !important;
}

.v-application--is-ltr .me-1 {
  margin-right: 4px !important;
}

.v-application--is-rtl .me-1 {
  margin-left: 4px !important;
}

.v-application--is-ltr .me-2 {
  margin-right: 8px !important;
}

.v-application--is-rtl .me-2 {
  margin-left: 8px !important;
}

.v-application--is-ltr .me-3 {
  margin-right: 12px !important;
}

.v-application--is-rtl .me-3 {
  margin-left: 12px !important;
}

.v-application--is-ltr .me-4 {
  margin-right: 16px !important;
}

.v-application--is-rtl .me-4 {
  margin-left: 16px !important;
}

.v-application--is-ltr .me-5 {
  margin-right: 20px !important;
}

.v-application--is-rtl .me-5 {
  margin-left: 20px !important;
}

.v-application--is-ltr .me-6 {
  margin-right: 24px !important;
}

.v-application--is-rtl .me-6 {
  margin-left: 24px !important;
}

.v-application--is-ltr .me-7 {
  margin-right: 28px !important;
}

.v-application--is-rtl .me-7 {
  margin-left: 28px !important;
}

.v-application--is-ltr .me-8 {
  margin-right: 32px !important;
}

.v-application--is-rtl .me-8 {
  margin-left: 32px !important;
}

.v-application--is-ltr .me-9 {
  margin-right: 36px !important;
}

.v-application--is-rtl .me-9 {
  margin-left: 36px !important;
}

.v-application--is-ltr .me-10 {
  margin-right: 40px !important;
}

.v-application--is-rtl .me-10 {
  margin-left: 40px !important;
}

.v-application--is-ltr .me-11 {
  margin-right: 44px !important;
}

.v-application--is-rtl .me-11 {
  margin-left: 44px !important;
}

.v-application--is-ltr .me-12 {
  margin-right: 48px !important;
}

.v-application--is-rtl .me-12 {
  margin-left: 48px !important;
}

.v-application--is-ltr .me-13 {
  margin-right: 52px !important;
}

.v-application--is-rtl .me-13 {
  margin-left: 52px !important;
}

.v-application--is-ltr .me-14 {
  margin-right: 56px !important;
}

.v-application--is-rtl .me-14 {
  margin-left: 56px !important;
}

.v-application--is-ltr .me-15 {
  margin-right: 60px !important;
}

.v-application--is-rtl .me-15 {
  margin-left: 60px !important;
}

.v-application--is-ltr .me-16 {
  margin-right: 64px !important;
}

.v-application--is-rtl .me-16 {
  margin-left: 64px !important;
}

.v-application--is-ltr .me-auto {
  margin-right: auto !important;
}

.v-application--is-rtl .me-auto {
  margin-left: auto !important;
}

.v-application .ma-n1 {
  margin: -4px !important;
}

.v-application .ma-n2 {
  margin: -8px !important;
}

.v-application .ma-n3 {
  margin: -12px !important;
}

.v-application .ma-n4 {
  margin: -16px !important;
}

.v-application .ma-n5 {
  margin: -20px !important;
}

.v-application .ma-n6 {
  margin: -24px !important;
}

.v-application .ma-n7 {
  margin: -28px !important;
}

.v-application .ma-n8 {
  margin: -32px !important;
}

.v-application .ma-n9 {
  margin: -36px !important;
}

.v-application .ma-n10 {
  margin: -40px !important;
}

.v-application .ma-n11 {
  margin: -44px !important;
}

.v-application .ma-n12 {
  margin: -48px !important;
}

.v-application .ma-n13 {
  margin: -52px !important;
}

.v-application .ma-n14 {
  margin: -56px !important;
}

.v-application .ma-n15 {
  margin: -60px !important;
}

.v-application .ma-n16 {
  margin: -64px !important;
}

.v-application .mx-n1 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.v-application .mx-n2 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.v-application .mx-n3 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.v-application .mx-n4 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.v-application .mx-n5 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.v-application .mx-n6 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

.v-application .mx-n7 {
  margin-left: -28px !important;
  margin-right: -28px !important;
}

.v-application .mx-n8 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.v-application .mx-n9 {
  margin-left: -36px !important;
  margin-right: -36px !important;
}

.v-application .mx-n10 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}

.v-application .mx-n11 {
  margin-left: -44px !important;
  margin-right: -44px !important;
}

.v-application .mx-n12 {
  margin-left: -48px !important;
  margin-right: -48px !important;
}

.v-application .mx-n13 {
  margin-left: -52px !important;
  margin-right: -52px !important;
}

.v-application .mx-n14 {
  margin-left: -56px !important;
  margin-right: -56px !important;
}

.v-application .mx-n15 {
  margin-left: -60px !important;
  margin-right: -60px !important;
}

.v-application .mx-n16 {
  margin-left: -64px !important;
  margin-right: -64px !important;
}

.v-application .my-n1 {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.v-application .my-n2 {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.v-application .my-n3 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.v-application .my-n4 {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.v-application .my-n5 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.v-application .my-n6 {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.v-application .my-n7 {
  margin-top: -28px !important;
  margin-bottom: -28px !important;
}

.v-application .my-n8 {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.v-application .my-n9 {
  margin-top: -36px !important;
  margin-bottom: -36px !important;
}

.v-application .my-n10 {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}

.v-application .my-n11 {
  margin-top: -44px !important;
  margin-bottom: -44px !important;
}

.v-application .my-n12 {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}

.v-application .my-n13 {
  margin-top: -52px !important;
  margin-bottom: -52px !important;
}

.v-application .my-n14 {
  margin-top: -56px !important;
  margin-bottom: -56px !important;
}

.v-application .my-n15 {
  margin-top: -60px !important;
  margin-bottom: -60px !important;
}

.v-application .my-n16 {
  margin-top: -64px !important;
  margin-bottom: -64px !important;
}

.v-application .mt-n1 {
  margin-top: -4px !important;
}

.v-application .mt-n2 {
  margin-top: -8px !important;
}

.v-application .mt-n3 {
  margin-top: -12px !important;
}

.v-application .mt-n4 {
  margin-top: -16px !important;
}

.v-application .mt-n5 {
  margin-top: -20px !important;
}

.v-application .mt-n6 {
  margin-top: -24px !important;
}

.v-application .mt-n7 {
  margin-top: -28px !important;
}

.v-application .mt-n8 {
  margin-top: -32px !important;
}

.v-application .mt-n9 {
  margin-top: -36px !important;
}

.v-application .mt-n10 {
  margin-top: -40px !important;
}

.v-application .mt-n11 {
  margin-top: -44px !important;
}

.v-application .mt-n12 {
  margin-top: -48px !important;
}

.v-application .mt-n13 {
  margin-top: -52px !important;
}

.v-application .mt-n14 {
  margin-top: -56px !important;
}

.v-application .mt-n15 {
  margin-top: -60px !important;
}

.v-application .mt-n16 {
  margin-top: -64px !important;
}

.v-application .mr-n1 {
  margin-right: -4px !important;
}

.v-application .mr-n2 {
  margin-right: -8px !important;
}

.v-application .mr-n3 {
  margin-right: -12px !important;
}

.v-application .mr-n4 {
  margin-right: -16px !important;
}

.v-application .mr-n5 {
  margin-right: -20px !important;
}

.v-application .mr-n6 {
  margin-right: -24px !important;
}

.v-application .mr-n7 {
  margin-right: -28px !important;
}

.v-application .mr-n8 {
  margin-right: -32px !important;
}

.v-application .mr-n9 {
  margin-right: -36px !important;
}

.v-application .mr-n10 {
  margin-right: -40px !important;
}

.v-application .mr-n11 {
  margin-right: -44px !important;
}

.v-application .mr-n12 {
  margin-right: -48px !important;
}

.v-application .mr-n13 {
  margin-right: -52px !important;
}

.v-application .mr-n14 {
  margin-right: -56px !important;
}

.v-application .mr-n15 {
  margin-right: -60px !important;
}

.v-application .mr-n16 {
  margin-right: -64px !important;
}

.v-application .mb-n1 {
  margin-bottom: -4px !important;
}

.v-application .mb-n2 {
  margin-bottom: -8px !important;
}

.v-application .mb-n3 {
  margin-bottom: -12px !important;
}

.v-application .mb-n4 {
  margin-bottom: -16px !important;
}

.v-application .mb-n5 {
  margin-bottom: -20px !important;
}

.v-application .mb-n6 {
  margin-bottom: -24px !important;
}

.v-application .mb-n7 {
  margin-bottom: -28px !important;
}

.v-application .mb-n8 {
  margin-bottom: -32px !important;
}

.v-application .mb-n9 {
  margin-bottom: -36px !important;
}

.v-application .mb-n10 {
  margin-bottom: -40px !important;
}

.v-application .mb-n11 {
  margin-bottom: -44px !important;
}

.v-application .mb-n12 {
  margin-bottom: -48px !important;
}

.v-application .mb-n13 {
  margin-bottom: -52px !important;
}

.v-application .mb-n14 {
  margin-bottom: -56px !important;
}

.v-application .mb-n15 {
  margin-bottom: -60px !important;
}

.v-application .mb-n16 {
  margin-bottom: -64px !important;
}

.v-application .ml-n1 {
  margin-left: -4px !important;
}

.v-application .ml-n2 {
  margin-left: -8px !important;
}

.v-application .ml-n3 {
  margin-left: -12px !important;
}

.v-application .ml-n4 {
  margin-left: -16px !important;
}

.v-application .ml-n5 {
  margin-left: -20px !important;
}

.v-application .ml-n6 {
  margin-left: -24px !important;
}

.v-application .ml-n7 {
  margin-left: -28px !important;
}

.v-application .ml-n8 {
  margin-left: -32px !important;
}

.v-application .ml-n9 {
  margin-left: -36px !important;
}

.v-application .ml-n10 {
  margin-left: -40px !important;
}

.v-application .ml-n11 {
  margin-left: -44px !important;
}

.v-application .ml-n12 {
  margin-left: -48px !important;
}

.v-application .ml-n13 {
  margin-left: -52px !important;
}

.v-application .ml-n14 {
  margin-left: -56px !important;
}

.v-application .ml-n15 {
  margin-left: -60px !important;
}

.v-application .ml-n16 {
  margin-left: -64px !important;
}

.v-application--is-ltr .ms-n1 {
  margin-left: -4px !important;
}

.v-application--is-rtl .ms-n1 {
  margin-right: -4px !important;
}

.v-application--is-ltr .ms-n2 {
  margin-left: -8px !important;
}

.v-application--is-rtl .ms-n2 {
  margin-right: -8px !important;
}

.v-application--is-ltr .ms-n3 {
  margin-left: -12px !important;
}

.v-application--is-rtl .ms-n3 {
  margin-right: -12px !important;
}

.v-application--is-ltr .ms-n4 {
  margin-left: -16px !important;
}

.v-application--is-rtl .ms-n4 {
  margin-right: -16px !important;
}

.v-application--is-ltr .ms-n5 {
  margin-left: -20px !important;
}

.v-application--is-rtl .ms-n5 {
  margin-right: -20px !important;
}

.v-application--is-ltr .ms-n6 {
  margin-left: -24px !important;
}

.v-application--is-rtl .ms-n6 {
  margin-right: -24px !important;
}

.v-application--is-ltr .ms-n7 {
  margin-left: -28px !important;
}

.v-application--is-rtl .ms-n7 {
  margin-right: -28px !important;
}

.v-application--is-ltr .ms-n8 {
  margin-left: -32px !important;
}

.v-application--is-rtl .ms-n8 {
  margin-right: -32px !important;
}

.v-application--is-ltr .ms-n9 {
  margin-left: -36px !important;
}

.v-application--is-rtl .ms-n9 {
  margin-right: -36px !important;
}

.v-application--is-ltr .ms-n10 {
  margin-left: -40px !important;
}

.v-application--is-rtl .ms-n10 {
  margin-right: -40px !important;
}

.v-application--is-ltr .ms-n11 {
  margin-left: -44px !important;
}

.v-application--is-rtl .ms-n11 {
  margin-right: -44px !important;
}

.v-application--is-ltr .ms-n12 {
  margin-left: -48px !important;
}

.v-application--is-rtl .ms-n12 {
  margin-right: -48px !important;
}

.v-application--is-ltr .ms-n13 {
  margin-left: -52px !important;
}

.v-application--is-rtl .ms-n13 {
  margin-right: -52px !important;
}

.v-application--is-ltr .ms-n14 {
  margin-left: -56px !important;
}

.v-application--is-rtl .ms-n14 {
  margin-right: -56px !important;
}

.v-application--is-ltr .ms-n15 {
  margin-left: -60px !important;
}

.v-application--is-rtl .ms-n15 {
  margin-right: -60px !important;
}

.v-application--is-ltr .ms-n16 {
  margin-left: -64px !important;
}

.v-application--is-rtl .ms-n16 {
  margin-right: -64px !important;
}

.v-application--is-ltr .me-n1 {
  margin-right: -4px !important;
}

.v-application--is-rtl .me-n1 {
  margin-left: -4px !important;
}

.v-application--is-ltr .me-n2 {
  margin-right: -8px !important;
}

.v-application--is-rtl .me-n2 {
  margin-left: -8px !important;
}

.v-application--is-ltr .me-n3 {
  margin-right: -12px !important;
}

.v-application--is-rtl .me-n3 {
  margin-left: -12px !important;
}

.v-application--is-ltr .me-n4 {
  margin-right: -16px !important;
}

.v-application--is-rtl .me-n4 {
  margin-left: -16px !important;
}

.v-application--is-ltr .me-n5 {
  margin-right: -20px !important;
}

.v-application--is-rtl .me-n5 {
  margin-left: -20px !important;
}

.v-application--is-ltr .me-n6 {
  margin-right: -24px !important;
}

.v-application--is-rtl .me-n6 {
  margin-left: -24px !important;
}

.v-application--is-ltr .me-n7 {
  margin-right: -28px !important;
}

.v-application--is-rtl .me-n7 {
  margin-left: -28px !important;
}

.v-application--is-ltr .me-n8 {
  margin-right: -32px !important;
}

.v-application--is-rtl .me-n8 {
  margin-left: -32px !important;
}

.v-application--is-ltr .me-n9 {
  margin-right: -36px !important;
}

.v-application--is-rtl .me-n9 {
  margin-left: -36px !important;
}

.v-application--is-ltr .me-n10 {
  margin-right: -40px !important;
}

.v-application--is-rtl .me-n10 {
  margin-left: -40px !important;
}

.v-application--is-ltr .me-n11 {
  margin-right: -44px !important;
}

.v-application--is-rtl .me-n11 {
  margin-left: -44px !important;
}

.v-application--is-ltr .me-n12 {
  margin-right: -48px !important;
}

.v-application--is-rtl .me-n12 {
  margin-left: -48px !important;
}

.v-application--is-ltr .me-n13 {
  margin-right: -52px !important;
}

.v-application--is-rtl .me-n13 {
  margin-left: -52px !important;
}

.v-application--is-ltr .me-n14 {
  margin-right: -56px !important;
}

.v-application--is-rtl .me-n14 {
  margin-left: -56px !important;
}

.v-application--is-ltr .me-n15 {
  margin-right: -60px !important;
}

.v-application--is-rtl .me-n15 {
  margin-left: -60px !important;
}

.v-application--is-ltr .me-n16 {
  margin-right: -64px !important;
}

.v-application--is-rtl .me-n16 {
  margin-left: -64px !important;
}

.v-application .pa-0 {
  padding: 0 !important;
}

.v-application .pa-1 {
  padding: 4px !important;
}

.v-application .pa-2 {
  padding: 8px !important;
}

.v-application .pa-3 {
  padding: 12px !important;
}

.v-application .pa-4 {
  padding: 16px !important;
}

.v-application .pa-5 {
  padding: 20px !important;
}

.v-application .pa-6 {
  padding: 24px !important;
}

.v-application .pa-7 {
  padding: 28px !important;
}

.v-application .pa-8 {
  padding: 32px !important;
}

.v-application .pa-9 {
  padding: 36px !important;
}

.v-application .pa-10 {
  padding: 40px !important;
}

.v-application .pa-11 {
  padding: 44px !important;
}

.v-application .pa-12 {
  padding: 48px !important;
}

.v-application .pa-13 {
  padding: 52px !important;
}

.v-application .pa-14 {
  padding: 56px !important;
}

.v-application .pa-15 {
  padding: 60px !important;
}

.v-application .pa-16 {
  padding: 64px !important;
}

.v-application .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-application .px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.v-application .px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.v-application .px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.v-application .px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.v-application .px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.v-application .px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.v-application .px-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.v-application .px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.v-application .px-9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.v-application .px-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.v-application .px-11 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.v-application .px-12 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.v-application .px-13 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.v-application .px-14 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.v-application .px-15 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.v-application .px-16 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.v-application .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.v-application .py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.v-application .py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.v-application .py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.v-application .py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.v-application .py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.v-application .py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.v-application .py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.v-application .py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.v-application .py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.v-application .py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.v-application .py-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.v-application .py-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.v-application .py-13 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.v-application .py-14 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.v-application .py-15 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.v-application .py-16 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.v-application .pt-0 {
  padding-top: 0 !important;
}

.v-application .pt-1 {
  padding-top: 4px !important;
}

.v-application .pt-2 {
  padding-top: 8px !important;
}

.v-application .pt-3 {
  padding-top: 12px !important;
}

.v-application .pt-4 {
  padding-top: 16px !important;
}

.v-application .pt-5 {
  padding-top: 20px !important;
}

.v-application .pt-6 {
  padding-top: 24px !important;
}

.v-application .pt-7 {
  padding-top: 28px !important;
}

.v-application .pt-8 {
  padding-top: 32px !important;
}

.v-application .pt-9 {
  padding-top: 36px !important;
}

.v-application .pt-10 {
  padding-top: 40px !important;
}

.v-application .pt-11 {
  padding-top: 44px !important;
}

.v-application .pt-12 {
  padding-top: 48px !important;
}

.v-application .pt-13 {
  padding-top: 52px !important;
}

.v-application .pt-14 {
  padding-top: 56px !important;
}

.v-application .pt-15 {
  padding-top: 60px !important;
}

.v-application .pt-16 {
  padding-top: 64px !important;
}

.v-application .pr-0 {
  padding-right: 0 !important;
}

.v-application .pr-1 {
  padding-right: 4px !important;
}

.v-application .pr-2 {
  padding-right: 8px !important;
}

.v-application .pr-3 {
  padding-right: 12px !important;
}

.v-application .pr-4 {
  padding-right: 16px !important;
}

.v-application .pr-5 {
  padding-right: 20px !important;
}

.v-application .pr-6 {
  padding-right: 24px !important;
}

.v-application .pr-7 {
  padding-right: 28px !important;
}

.v-application .pr-8 {
  padding-right: 32px !important;
}

.v-application .pr-9 {
  padding-right: 36px !important;
}

.v-application .pr-10 {
  padding-right: 40px !important;
}

.v-application .pr-11 {
  padding-right: 44px !important;
}

.v-application .pr-12 {
  padding-right: 48px !important;
}

.v-application .pr-13 {
  padding-right: 52px !important;
}

.v-application .pr-14 {
  padding-right: 56px !important;
}

.v-application .pr-15 {
  padding-right: 60px !important;
}

.v-application .pr-16 {
  padding-right: 64px !important;
}

.v-application .pb-0 {
  padding-bottom: 0 !important;
}

.v-application .pb-1 {
  padding-bottom: 4px !important;
}

.v-application .pb-2 {
  padding-bottom: 8px !important;
}

.v-application .pb-3 {
  padding-bottom: 12px !important;
}

.v-application .pb-4 {
  padding-bottom: 16px !important;
}

.v-application .pb-5 {
  padding-bottom: 20px !important;
}

.v-application .pb-6 {
  padding-bottom: 24px !important;
}

.v-application .pb-7 {
  padding-bottom: 28px !important;
}

.v-application .pb-8 {
  padding-bottom: 32px !important;
}

.v-application .pb-9 {
  padding-bottom: 36px !important;
}

.v-application .pb-10 {
  padding-bottom: 40px !important;
}

.v-application .pb-11 {
  padding-bottom: 44px !important;
}

.v-application .pb-12 {
  padding-bottom: 48px !important;
}

.v-application .pb-13 {
  padding-bottom: 52px !important;
}

.v-application .pb-14 {
  padding-bottom: 56px !important;
}

.v-application .pb-15 {
  padding-bottom: 60px !important;
}

.v-application .pb-16 {
  padding-bottom: 64px !important;
}

.v-application .pl-0 {
  padding-left: 0 !important;
}

.v-application .pl-1 {
  padding-left: 4px !important;
}

.v-application .pl-2 {
  padding-left: 8px !important;
}

.v-application .pl-3 {
  padding-left: 12px !important;
}

.v-application .pl-4 {
  padding-left: 16px !important;
}

.v-application .pl-5 {
  padding-left: 20px !important;
}

.v-application .pl-6 {
  padding-left: 24px !important;
}

.v-application .pl-7 {
  padding-left: 28px !important;
}

.v-application .pl-8 {
  padding-left: 32px !important;
}

.v-application .pl-9 {
  padding-left: 36px !important;
}

.v-application .pl-10 {
  padding-left: 40px !important;
}

.v-application .pl-11 {
  padding-left: 44px !important;
}

.v-application .pl-12 {
  padding-left: 48px !important;
}

.v-application .pl-13 {
  padding-left: 52px !important;
}

.v-application .pl-14 {
  padding-left: 56px !important;
}

.v-application .pl-15 {
  padding-left: 60px !important;
}

.v-application .pl-16 {
  padding-left: 64px !important;
}

.v-application--is-ltr .ps-0 {
  padding-left: 0 !important;
}

.v-application--is-rtl .ps-0 {
  padding-right: 0 !important;
}

.v-application--is-ltr .ps-1 {
  padding-left: 4px !important;
}

.v-application--is-rtl .ps-1 {
  padding-right: 4px !important;
}

.v-application--is-ltr .ps-2 {
  padding-left: 8px !important;
}

.v-application--is-rtl .ps-2 {
  padding-right: 8px !important;
}

.v-application--is-ltr .ps-3 {
  padding-left: 12px !important;
}

.v-application--is-rtl .ps-3 {
  padding-right: 12px !important;
}

.v-application--is-ltr .ps-4 {
  padding-left: 16px !important;
}

.v-application--is-rtl .ps-4 {
  padding-right: 16px !important;
}

.v-application--is-ltr .ps-5 {
  padding-left: 20px !important;
}

.v-application--is-rtl .ps-5 {
  padding-right: 20px !important;
}

.v-application--is-ltr .ps-6 {
  padding-left: 24px !important;
}

.v-application--is-rtl .ps-6 {
  padding-right: 24px !important;
}

.v-application--is-ltr .ps-7 {
  padding-left: 28px !important;
}

.v-application--is-rtl .ps-7 {
  padding-right: 28px !important;
}

.v-application--is-ltr .ps-8 {
  padding-left: 32px !important;
}

.v-application--is-rtl .ps-8 {
  padding-right: 32px !important;
}

.v-application--is-ltr .ps-9 {
  padding-left: 36px !important;
}

.v-application--is-rtl .ps-9 {
  padding-right: 36px !important;
}

.v-application--is-ltr .ps-10 {
  padding-left: 40px !important;
}

.v-application--is-rtl .ps-10 {
  padding-right: 40px !important;
}

.v-application--is-ltr .ps-11 {
  padding-left: 44px !important;
}

.v-application--is-rtl .ps-11 {
  padding-right: 44px !important;
}

.v-application--is-ltr .ps-12 {
  padding-left: 48px !important;
}

.v-application--is-rtl .ps-12 {
  padding-right: 48px !important;
}

.v-application--is-ltr .ps-13 {
  padding-left: 52px !important;
}

.v-application--is-rtl .ps-13 {
  padding-right: 52px !important;
}

.v-application--is-ltr .ps-14 {
  padding-left: 56px !important;
}

.v-application--is-rtl .ps-14 {
  padding-right: 56px !important;
}

.v-application--is-ltr .ps-15 {
  padding-left: 60px !important;
}

.v-application--is-rtl .ps-15 {
  padding-right: 60px !important;
}

.v-application--is-ltr .ps-16 {
  padding-left: 64px !important;
}

.v-application--is-rtl .ps-16 {
  padding-right: 64px !important;
}

.v-application--is-ltr .pe-0 {
  padding-right: 0 !important;
}

.v-application--is-rtl .pe-0 {
  padding-left: 0 !important;
}

.v-application--is-ltr .pe-1 {
  padding-right: 4px !important;
}

.v-application--is-rtl .pe-1 {
  padding-left: 4px !important;
}

.v-application--is-ltr .pe-2 {
  padding-right: 8px !important;
}

.v-application--is-rtl .pe-2 {
  padding-left: 8px !important;
}

.v-application--is-ltr .pe-3 {
  padding-right: 12px !important;
}

.v-application--is-rtl .pe-3 {
  padding-left: 12px !important;
}

.v-application--is-ltr .pe-4 {
  padding-right: 16px !important;
}

.v-application--is-rtl .pe-4 {
  padding-left: 16px !important;
}

.v-application--is-ltr .pe-5 {
  padding-right: 20px !important;
}

.v-application--is-rtl .pe-5 {
  padding-left: 20px !important;
}

.v-application--is-ltr .pe-6 {
  padding-right: 24px !important;
}

.v-application--is-rtl .pe-6 {
  padding-left: 24px !important;
}

.v-application--is-ltr .pe-7 {
  padding-right: 28px !important;
}

.v-application--is-rtl .pe-7 {
  padding-left: 28px !important;
}

.v-application--is-ltr .pe-8 {
  padding-right: 32px !important;
}

.v-application--is-rtl .pe-8 {
  padding-left: 32px !important;
}

.v-application--is-ltr .pe-9 {
  padding-right: 36px !important;
}

.v-application--is-rtl .pe-9 {
  padding-left: 36px !important;
}

.v-application--is-ltr .pe-10 {
  padding-right: 40px !important;
}

.v-application--is-rtl .pe-10 {
  padding-left: 40px !important;
}

.v-application--is-ltr .pe-11 {
  padding-right: 44px !important;
}

.v-application--is-rtl .pe-11 {
  padding-left: 44px !important;
}

.v-application--is-ltr .pe-12 {
  padding-right: 48px !important;
}

.v-application--is-rtl .pe-12 {
  padding-left: 48px !important;
}

.v-application--is-ltr .pe-13 {
  padding-right: 52px !important;
}

.v-application--is-rtl .pe-13 {
  padding-left: 52px !important;
}

.v-application--is-ltr .pe-14 {
  padding-right: 56px !important;
}

.v-application--is-rtl .pe-14 {
  padding-left: 56px !important;
}

.v-application--is-ltr .pe-15 {
  padding-right: 60px !important;
}

.v-application--is-rtl .pe-15 {
  padding-left: 60px !important;
}

.v-application--is-ltr .pe-16 {
  padding-right: 64px !important;
}

.v-application--is-rtl .pe-16 {
  padding-left: 64px !important;
}

.v-application .rounded-0 {
  border-radius: 0 !important;
}

.v-application .rounded-sm {
  border-radius: 2px !important;
}

.v-application .rounded {
  border-radius: 4px !important;
}

.v-application .rounded-lg {
  border-radius: 8px !important;
}

.v-application .rounded-xl {
  border-radius: 24px !important;
}

.v-application .rounded-pill {
  border-radius: 9999px !important;
}

.v-application .rounded-circle {
  border-radius: 50% !important;
}

.v-application .rounded-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.v-application .rounded-t-sm {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.v-application .rounded-t {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.v-application .rounded-t-lg {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.v-application .rounded-t-xl {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}

.v-application .rounded-t-pill {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.v-application .rounded-t-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.v-application .rounded-r-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.v-application .rounded-r-sm {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.v-application .rounded-r {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.v-application .rounded-r-lg {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.v-application .rounded-r-xl {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.v-application .rounded-r-pill {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.v-application .rounded-r-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.v-application .rounded-b-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.v-application .rounded-b-sm {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.v-application .rounded-b {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.v-application .rounded-b-lg {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.v-application .rounded-b-xl {
  border-bottom-left-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.v-application .rounded-b-pill {
  border-bottom-left-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.v-application .rounded-b-circle {
  border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.v-application .rounded-l-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.v-application .rounded-l-sm {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.v-application .rounded-l {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.v-application .rounded-l-lg {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.v-application .rounded-l-xl {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
}

.v-application .rounded-l-pill {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.v-application .rounded-l-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.v-application .rounded-tl-0 {
  border-top-left-radius: 0 !important;
}

.v-application .rounded-tl-sm {
  border-top-left-radius: 2px !important;
}

.v-application .rounded-tl {
  border-top-left-radius: 4px !important;
}

.v-application .rounded-tl-lg {
  border-top-left-radius: 8px !important;
}

.v-application .rounded-tl-xl {
  border-top-left-radius: 24px !important;
}

.v-application .rounded-tl-pill {
  border-top-left-radius: 9999px !important;
}

.v-application .rounded-tl-circle {
  border-top-left-radius: 50% !important;
}

.v-application .rounded-tr-0 {
  border-top-right-radius: 0 !important;
}

.v-application .rounded-tr-sm {
  border-top-right-radius: 2px !important;
}

.v-application .rounded-tr {
  border-top-right-radius: 4px !important;
}

.v-application .rounded-tr-lg {
  border-top-right-radius: 8px !important;
}

.v-application .rounded-tr-xl {
  border-top-right-radius: 24px !important;
}

.v-application .rounded-tr-pill {
  border-top-right-radius: 9999px !important;
}

.v-application .rounded-tr-circle {
  border-top-right-radius: 50% !important;
}

.v-application .rounded-br-0 {
  border-bottom-right-radius: 0 !important;
}

.v-application .rounded-br-sm {
  border-bottom-right-radius: 2px !important;
}

.v-application .rounded-br {
  border-bottom-right-radius: 4px !important;
}

.v-application .rounded-br-lg {
  border-bottom-right-radius: 8px !important;
}

.v-application .rounded-br-xl {
  border-bottom-right-radius: 24px !important;
}

.v-application .rounded-br-pill {
  border-bottom-right-radius: 9999px !important;
}

.v-application .rounded-br-circle {
  border-bottom-right-radius: 50% !important;
}

.v-application .rounded-bl-0 {
  border-bottom-left-radius: 0 !important;
}

.v-application .rounded-bl-sm {
  border-bottom-left-radius: 2px !important;
}

.v-application .rounded-bl {
  border-bottom-left-radius: 4px !important;
}

.v-application .rounded-bl-lg {
  border-bottom-left-radius: 8px !important;
}

.v-application .rounded-bl-xl {
  border-bottom-left-radius: 24px !important;
}

.v-application .rounded-bl-pill {
  border-bottom-left-radius: 9999px !important;
}

.v-application .rounded-bl-circle {
  border-bottom-left-radius: 50% !important;
}

.v-application .text-left {
  text-align: left !important;
}

.v-application .text-right {
  text-align: right !important;
}

.v-application .text-center {
  text-align: center !important;
}

.v-application .text-justify {
  text-align: justify !important;
}

.v-application .text-start {
  text-align: start !important;
}

.v-application .text-end {
  text-align: end !important;
}

.v-application .text-decoration-line-through {
  text-decoration: line-through !important;
}

.v-application .text-decoration-none {
  text-decoration: none !important;
}

.v-application .text-decoration-overline {
  text-decoration: overline !important;
}

.v-application .text-decoration-underline {
  text-decoration: underline !important;
}

.v-application .text-wrap {
  white-space: normal !important;
}

.v-application .text-no-wrap {
  white-space: nowrap !important;
}

.v-application .text-pre {
  white-space: pre !important;
}

.v-application .text-pre-line {
  white-space: pre-line !important;
}

.v-application .text-pre-wrap {
  white-space: pre-wrap !important;
}

.v-application .text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.v-application .text-truncate {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.v-application .text-none {
  text-transform: none !important;
}

.v-application .text-capitalize {
  text-transform: capitalize !important;
}

.v-application .text-lowercase {
  text-transform: lowercase !important;
}

.v-application .text-uppercase {
  text-transform: uppercase !important;
}

.v-application .text-h1 {
  line-height: 6rem;
  letter-spacing: -.015625em !important;
  font-size: 6rem !important;
}

.v-application .text-h1, .v-application .text-h2 {
  font-weight: 300;
  font-family: Roboto, sans-serif !important;
}

.v-application .text-h2 {
  line-height: 3.75rem;
  letter-spacing: -.00833333em !important;
  font-size: 3.75rem !important;
}

.v-application .text-h3 {
  line-height: 3.125rem;
  letter-spacing: normal !important;
  font-size: 3rem !important;
}

.v-application .text-h3, .v-application .text-h4 {
  font-weight: 400;
  font-family: Roboto, sans-serif !important;
}

.v-application .text-h4 {
  line-height: 2.5rem;
  letter-spacing: .00735294em !important;
  font-size: 2.125rem !important;
}

.v-application .text-h5 {
  font-weight: 400;
  letter-spacing: normal !important;
  font-size: 1.5rem !important;
}

.v-application .text-h5, .v-application .text-h6 {
  line-height: 2rem;
  font-family: Roboto, sans-serif !important;
}

.v-application .text-h6 {
  font-weight: 500;
  letter-spacing: .0125em !important;
  font-size: 1.25rem !important;
}

.v-application .text-subtitle-1 {
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: .009375em !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem !important;
}

.v-application .text-subtitle-2 {
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: .00714286em !important;
  font-family: Roboto, sans-serif !important;
  font-size: .875rem !important;
}

.v-application .text-body-1 {
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: .03125em !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem !important;
}

.v-application .text-body-2 {
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: .0178571em !important;
}

.v-application .text-body-2, .v-application .text-button {
  font-family: Roboto, sans-serif !important;
  font-size: .875rem !important;
}

.v-application .text-button {
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: .0892857em !important;
  text-transform: uppercase !important;
}

.v-application .text-caption {
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: .0333333em !important;
}

.v-application .text-caption, .v-application .text-overline {
  font-family: Roboto, sans-serif !important;
  font-size: .75rem !important;
}

.v-application .text-overline {
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: .166667em !important;
  text-transform: uppercase !important;
}

@media (min-width: 600px) {
  .v-application .d-sm-none {
    display: none !important;
  }

  .v-application .d-sm-inline {
    display: inline !important;
  }

  .v-application .d-sm-inline-block {
    display: inline-block !important;
  }

  .v-application .d-sm-block {
    display: block !important;
  }

  .v-application .d-sm-table {
    display: table !important;
  }

  .v-application .d-sm-table-row {
    display: table-row !important;
  }

  .v-application .d-sm-table-cell {
    display: table-cell !important;
  }

  .v-application .d-sm-flex {
    display: flex !important;
  }

  .v-application .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .v-application .float-sm-none {
    float: none !important;
  }

  .v-application .float-sm-left {
    float: left !important;
  }

  .v-application .float-sm-right {
    float: right !important;
  }

  .v-application--is-rtl .float-sm-end {
    float: left !important;
  }

  .v-application--is-ltr .float-sm-end, .v-application--is-rtl .float-sm-start {
    float: right !important;
  }

  .v-application--is-ltr .float-sm-start {
    float: left !important;
  }

  .v-application .flex-sm-fill {
    flex: auto !important;
  }

  .v-application .flex-sm-row {
    flex-direction: row !important;
  }

  .v-application .flex-sm-column {
    flex-direction: column !important;
  }

  .v-application .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .v-application .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .v-application .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .v-application .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .v-application .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .v-application .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .v-application .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .v-application .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .v-application .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .v-application .justify-sm-start {
    justify-content: flex-start !important;
  }

  .v-application .justify-sm-end {
    justify-content: flex-end !important;
  }

  .v-application .justify-sm-center {
    justify-content: center !important;
  }

  .v-application .justify-sm-space-between {
    justify-content: space-between !important;
  }

  .v-application .justify-sm-space-around {
    justify-content: space-around !important;
  }

  .v-application .align-sm-start {
    align-items: flex-start !important;
  }

  .v-application .align-sm-end {
    align-items: flex-end !important;
  }

  .v-application .align-sm-center {
    align-items: center !important;
  }

  .v-application .align-sm-baseline {
    align-items: baseline !important;
  }

  .v-application .align-sm-stretch {
    align-items: stretch !important;
  }

  .v-application .align-content-sm-start {
    align-content: flex-start !important;
  }

  .v-application .align-content-sm-end {
    align-content: flex-end !important;
  }

  .v-application .align-content-sm-center {
    align-content: center !important;
  }

  .v-application .align-content-sm-space-between {
    align-content: space-between !important;
  }

  .v-application .align-content-sm-space-around {
    align-content: space-around !important;
  }

  .v-application .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .v-application .align-self-sm-auto {
    align-self: auto !important;
  }

  .v-application .align-self-sm-start {
    align-self: flex-start !important;
  }

  .v-application .align-self-sm-end {
    align-self: flex-end !important;
  }

  .v-application .align-self-sm-center {
    align-self: center !important;
  }

  .v-application .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .v-application .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .v-application .order-sm-first {
    order: -1 !important;
  }

  .v-application .order-sm-0 {
    order: 0 !important;
  }

  .v-application .order-sm-1 {
    order: 1 !important;
  }

  .v-application .order-sm-2 {
    order: 2 !important;
  }

  .v-application .order-sm-3 {
    order: 3 !important;
  }

  .v-application .order-sm-4 {
    order: 4 !important;
  }

  .v-application .order-sm-5 {
    order: 5 !important;
  }

  .v-application .order-sm-6 {
    order: 6 !important;
  }

  .v-application .order-sm-7 {
    order: 7 !important;
  }

  .v-application .order-sm-8 {
    order: 8 !important;
  }

  .v-application .order-sm-9 {
    order: 9 !important;
  }

  .v-application .order-sm-10 {
    order: 10 !important;
  }

  .v-application .order-sm-11 {
    order: 11 !important;
  }

  .v-application .order-sm-12 {
    order: 12 !important;
  }

  .v-application .order-sm-last {
    order: 13 !important;
  }

  .v-application .ma-sm-0 {
    margin: 0 !important;
  }

  .v-application .ma-sm-1 {
    margin: 4px !important;
  }

  .v-application .ma-sm-2 {
    margin: 8px !important;
  }

  .v-application .ma-sm-3 {
    margin: 12px !important;
  }

  .v-application .ma-sm-4 {
    margin: 16px !important;
  }

  .v-application .ma-sm-5 {
    margin: 20px !important;
  }

  .v-application .ma-sm-6 {
    margin: 24px !important;
  }

  .v-application .ma-sm-7 {
    margin: 28px !important;
  }

  .v-application .ma-sm-8 {
    margin: 32px !important;
  }

  .v-application .ma-sm-9 {
    margin: 36px !important;
  }

  .v-application .ma-sm-10 {
    margin: 40px !important;
  }

  .v-application .ma-sm-11 {
    margin: 44px !important;
  }

  .v-application .ma-sm-12 {
    margin: 48px !important;
  }

  .v-application .ma-sm-13 {
    margin: 52px !important;
  }

  .v-application .ma-sm-14 {
    margin: 56px !important;
  }

  .v-application .ma-sm-15 {
    margin: 60px !important;
  }

  .v-application .ma-sm-16 {
    margin: 64px !important;
  }

  .v-application .ma-sm-auto {
    margin: auto !important;
  }

  .v-application .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .v-application .mx-sm-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .v-application .mx-sm-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .v-application .mx-sm-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .v-application .mx-sm-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .v-application .mx-sm-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .v-application .mx-sm-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .v-application .mx-sm-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .v-application .mx-sm-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .v-application .mx-sm-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .v-application .mx-sm-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .v-application .mx-sm-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .v-application .mx-sm-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .v-application .mx-sm-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .v-application .mx-sm-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .v-application .mx-sm-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .v-application .mx-sm-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .v-application .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .v-application .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .v-application .my-sm-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .v-application .my-sm-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .v-application .my-sm-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .v-application .my-sm-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .v-application .my-sm-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .v-application .my-sm-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .v-application .my-sm-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .v-application .my-sm-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .v-application .my-sm-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .v-application .my-sm-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .v-application .my-sm-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .v-application .my-sm-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .v-application .my-sm-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .v-application .my-sm-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .v-application .my-sm-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .v-application .my-sm-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .v-application .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .v-application .mt-sm-0 {
    margin-top: 0 !important;
  }

  .v-application .mt-sm-1 {
    margin-top: 4px !important;
  }

  .v-application .mt-sm-2 {
    margin-top: 8px !important;
  }

  .v-application .mt-sm-3 {
    margin-top: 12px !important;
  }

  .v-application .mt-sm-4 {
    margin-top: 16px !important;
  }

  .v-application .mt-sm-5 {
    margin-top: 20px !important;
  }

  .v-application .mt-sm-6 {
    margin-top: 24px !important;
  }

  .v-application .mt-sm-7 {
    margin-top: 28px !important;
  }

  .v-application .mt-sm-8 {
    margin-top: 32px !important;
  }

  .v-application .mt-sm-9 {
    margin-top: 36px !important;
  }

  .v-application .mt-sm-10 {
    margin-top: 40px !important;
  }

  .v-application .mt-sm-11 {
    margin-top: 44px !important;
  }

  .v-application .mt-sm-12 {
    margin-top: 48px !important;
  }

  .v-application .mt-sm-13 {
    margin-top: 52px !important;
  }

  .v-application .mt-sm-14 {
    margin-top: 56px !important;
  }

  .v-application .mt-sm-15 {
    margin-top: 60px !important;
  }

  .v-application .mt-sm-16 {
    margin-top: 64px !important;
  }

  .v-application .mt-sm-auto {
    margin-top: auto !important;
  }

  .v-application .mr-sm-0 {
    margin-right: 0 !important;
  }

  .v-application .mr-sm-1 {
    margin-right: 4px !important;
  }

  .v-application .mr-sm-2 {
    margin-right: 8px !important;
  }

  .v-application .mr-sm-3 {
    margin-right: 12px !important;
  }

  .v-application .mr-sm-4 {
    margin-right: 16px !important;
  }

  .v-application .mr-sm-5 {
    margin-right: 20px !important;
  }

  .v-application .mr-sm-6 {
    margin-right: 24px !important;
  }

  .v-application .mr-sm-7 {
    margin-right: 28px !important;
  }

  .v-application .mr-sm-8 {
    margin-right: 32px !important;
  }

  .v-application .mr-sm-9 {
    margin-right: 36px !important;
  }

  .v-application .mr-sm-10 {
    margin-right: 40px !important;
  }

  .v-application .mr-sm-11 {
    margin-right: 44px !important;
  }

  .v-application .mr-sm-12 {
    margin-right: 48px !important;
  }

  .v-application .mr-sm-13 {
    margin-right: 52px !important;
  }

  .v-application .mr-sm-14 {
    margin-right: 56px !important;
  }

  .v-application .mr-sm-15 {
    margin-right: 60px !important;
  }

  .v-application .mr-sm-16 {
    margin-right: 64px !important;
  }

  .v-application .mr-sm-auto {
    margin-right: auto !important;
  }

  .v-application .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .v-application .mb-sm-1 {
    margin-bottom: 4px !important;
  }

  .v-application .mb-sm-2 {
    margin-bottom: 8px !important;
  }

  .v-application .mb-sm-3 {
    margin-bottom: 12px !important;
  }

  .v-application .mb-sm-4 {
    margin-bottom: 16px !important;
  }

  .v-application .mb-sm-5 {
    margin-bottom: 20px !important;
  }

  .v-application .mb-sm-6 {
    margin-bottom: 24px !important;
  }

  .v-application .mb-sm-7 {
    margin-bottom: 28px !important;
  }

  .v-application .mb-sm-8 {
    margin-bottom: 32px !important;
  }

  .v-application .mb-sm-9 {
    margin-bottom: 36px !important;
  }

  .v-application .mb-sm-10 {
    margin-bottom: 40px !important;
  }

  .v-application .mb-sm-11 {
    margin-bottom: 44px !important;
  }

  .v-application .mb-sm-12 {
    margin-bottom: 48px !important;
  }

  .v-application .mb-sm-13 {
    margin-bottom: 52px !important;
  }

  .v-application .mb-sm-14 {
    margin-bottom: 56px !important;
  }

  .v-application .mb-sm-15 {
    margin-bottom: 60px !important;
  }

  .v-application .mb-sm-16 {
    margin-bottom: 64px !important;
  }

  .v-application .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .v-application .ml-sm-0 {
    margin-left: 0 !important;
  }

  .v-application .ml-sm-1 {
    margin-left: 4px !important;
  }

  .v-application .ml-sm-2 {
    margin-left: 8px !important;
  }

  .v-application .ml-sm-3 {
    margin-left: 12px !important;
  }

  .v-application .ml-sm-4 {
    margin-left: 16px !important;
  }

  .v-application .ml-sm-5 {
    margin-left: 20px !important;
  }

  .v-application .ml-sm-6 {
    margin-left: 24px !important;
  }

  .v-application .ml-sm-7 {
    margin-left: 28px !important;
  }

  .v-application .ml-sm-8 {
    margin-left: 32px !important;
  }

  .v-application .ml-sm-9 {
    margin-left: 36px !important;
  }

  .v-application .ml-sm-10 {
    margin-left: 40px !important;
  }

  .v-application .ml-sm-11 {
    margin-left: 44px !important;
  }

  .v-application .ml-sm-12 {
    margin-left: 48px !important;
  }

  .v-application .ml-sm-13 {
    margin-left: 52px !important;
  }

  .v-application .ml-sm-14 {
    margin-left: 56px !important;
  }

  .v-application .ml-sm-15 {
    margin-left: 60px !important;
  }

  .v-application .ml-sm-16 {
    margin-left: 64px !important;
  }

  .v-application .ml-sm-auto {
    margin-left: auto !important;
  }

  .v-application--is-ltr .ms-sm-0 {
    margin-left: 0 !important;
  }

  .v-application--is-rtl .ms-sm-0 {
    margin-right: 0 !important;
  }

  .v-application--is-ltr .ms-sm-1 {
    margin-left: 4px !important;
  }

  .v-application--is-rtl .ms-sm-1 {
    margin-right: 4px !important;
  }

  .v-application--is-ltr .ms-sm-2 {
    margin-left: 8px !important;
  }

  .v-application--is-rtl .ms-sm-2 {
    margin-right: 8px !important;
  }

  .v-application--is-ltr .ms-sm-3 {
    margin-left: 12px !important;
  }

  .v-application--is-rtl .ms-sm-3 {
    margin-right: 12px !important;
  }

  .v-application--is-ltr .ms-sm-4 {
    margin-left: 16px !important;
  }

  .v-application--is-rtl .ms-sm-4 {
    margin-right: 16px !important;
  }

  .v-application--is-ltr .ms-sm-5 {
    margin-left: 20px !important;
  }

  .v-application--is-rtl .ms-sm-5 {
    margin-right: 20px !important;
  }

  .v-application--is-ltr .ms-sm-6 {
    margin-left: 24px !important;
  }

  .v-application--is-rtl .ms-sm-6 {
    margin-right: 24px !important;
  }

  .v-application--is-ltr .ms-sm-7 {
    margin-left: 28px !important;
  }

  .v-application--is-rtl .ms-sm-7 {
    margin-right: 28px !important;
  }

  .v-application--is-ltr .ms-sm-8 {
    margin-left: 32px !important;
  }

  .v-application--is-rtl .ms-sm-8 {
    margin-right: 32px !important;
  }

  .v-application--is-ltr .ms-sm-9 {
    margin-left: 36px !important;
  }

  .v-application--is-rtl .ms-sm-9 {
    margin-right: 36px !important;
  }

  .v-application--is-ltr .ms-sm-10 {
    margin-left: 40px !important;
  }

  .v-application--is-rtl .ms-sm-10 {
    margin-right: 40px !important;
  }

  .v-application--is-ltr .ms-sm-11 {
    margin-left: 44px !important;
  }

  .v-application--is-rtl .ms-sm-11 {
    margin-right: 44px !important;
  }

  .v-application--is-ltr .ms-sm-12 {
    margin-left: 48px !important;
  }

  .v-application--is-rtl .ms-sm-12 {
    margin-right: 48px !important;
  }

  .v-application--is-ltr .ms-sm-13 {
    margin-left: 52px !important;
  }

  .v-application--is-rtl .ms-sm-13 {
    margin-right: 52px !important;
  }

  .v-application--is-ltr .ms-sm-14 {
    margin-left: 56px !important;
  }

  .v-application--is-rtl .ms-sm-14 {
    margin-right: 56px !important;
  }

  .v-application--is-ltr .ms-sm-15 {
    margin-left: 60px !important;
  }

  .v-application--is-rtl .ms-sm-15 {
    margin-right: 60px !important;
  }

  .v-application--is-ltr .ms-sm-16 {
    margin-left: 64px !important;
  }

  .v-application--is-rtl .ms-sm-16 {
    margin-right: 64px !important;
  }

  .v-application--is-ltr .ms-sm-auto {
    margin-left: auto !important;
  }

  .v-application--is-rtl .ms-sm-auto {
    margin-right: auto !important;
  }

  .v-application--is-ltr .me-sm-0 {
    margin-right: 0 !important;
  }

  .v-application--is-rtl .me-sm-0 {
    margin-left: 0 !important;
  }

  .v-application--is-ltr .me-sm-1 {
    margin-right: 4px !important;
  }

  .v-application--is-rtl .me-sm-1 {
    margin-left: 4px !important;
  }

  .v-application--is-ltr .me-sm-2 {
    margin-right: 8px !important;
  }

  .v-application--is-rtl .me-sm-2 {
    margin-left: 8px !important;
  }

  .v-application--is-ltr .me-sm-3 {
    margin-right: 12px !important;
  }

  .v-application--is-rtl .me-sm-3 {
    margin-left: 12px !important;
  }

  .v-application--is-ltr .me-sm-4 {
    margin-right: 16px !important;
  }

  .v-application--is-rtl .me-sm-4 {
    margin-left: 16px !important;
  }

  .v-application--is-ltr .me-sm-5 {
    margin-right: 20px !important;
  }

  .v-application--is-rtl .me-sm-5 {
    margin-left: 20px !important;
  }

  .v-application--is-ltr .me-sm-6 {
    margin-right: 24px !important;
  }

  .v-application--is-rtl .me-sm-6 {
    margin-left: 24px !important;
  }

  .v-application--is-ltr .me-sm-7 {
    margin-right: 28px !important;
  }

  .v-application--is-rtl .me-sm-7 {
    margin-left: 28px !important;
  }

  .v-application--is-ltr .me-sm-8 {
    margin-right: 32px !important;
  }

  .v-application--is-rtl .me-sm-8 {
    margin-left: 32px !important;
  }

  .v-application--is-ltr .me-sm-9 {
    margin-right: 36px !important;
  }

  .v-application--is-rtl .me-sm-9 {
    margin-left: 36px !important;
  }

  .v-application--is-ltr .me-sm-10 {
    margin-right: 40px !important;
  }

  .v-application--is-rtl .me-sm-10 {
    margin-left: 40px !important;
  }

  .v-application--is-ltr .me-sm-11 {
    margin-right: 44px !important;
  }

  .v-application--is-rtl .me-sm-11 {
    margin-left: 44px !important;
  }

  .v-application--is-ltr .me-sm-12 {
    margin-right: 48px !important;
  }

  .v-application--is-rtl .me-sm-12 {
    margin-left: 48px !important;
  }

  .v-application--is-ltr .me-sm-13 {
    margin-right: 52px !important;
  }

  .v-application--is-rtl .me-sm-13 {
    margin-left: 52px !important;
  }

  .v-application--is-ltr .me-sm-14 {
    margin-right: 56px !important;
  }

  .v-application--is-rtl .me-sm-14 {
    margin-left: 56px !important;
  }

  .v-application--is-ltr .me-sm-15 {
    margin-right: 60px !important;
  }

  .v-application--is-rtl .me-sm-15 {
    margin-left: 60px !important;
  }

  .v-application--is-ltr .me-sm-16 {
    margin-right: 64px !important;
  }

  .v-application--is-rtl .me-sm-16 {
    margin-left: 64px !important;
  }

  .v-application--is-ltr .me-sm-auto {
    margin-right: auto !important;
  }

  .v-application--is-rtl .me-sm-auto {
    margin-left: auto !important;
  }

  .v-application .ma-sm-n1 {
    margin: -4px !important;
  }

  .v-application .ma-sm-n2 {
    margin: -8px !important;
  }

  .v-application .ma-sm-n3 {
    margin: -12px !important;
  }

  .v-application .ma-sm-n4 {
    margin: -16px !important;
  }

  .v-application .ma-sm-n5 {
    margin: -20px !important;
  }

  .v-application .ma-sm-n6 {
    margin: -24px !important;
  }

  .v-application .ma-sm-n7 {
    margin: -28px !important;
  }

  .v-application .ma-sm-n8 {
    margin: -32px !important;
  }

  .v-application .ma-sm-n9 {
    margin: -36px !important;
  }

  .v-application .ma-sm-n10 {
    margin: -40px !important;
  }

  .v-application .ma-sm-n11 {
    margin: -44px !important;
  }

  .v-application .ma-sm-n12 {
    margin: -48px !important;
  }

  .v-application .ma-sm-n13 {
    margin: -52px !important;
  }

  .v-application .ma-sm-n14 {
    margin: -56px !important;
  }

  .v-application .ma-sm-n15 {
    margin: -60px !important;
  }

  .v-application .ma-sm-n16 {
    margin: -64px !important;
  }

  .v-application .mx-sm-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .v-application .mx-sm-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .v-application .mx-sm-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .v-application .mx-sm-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .v-application .mx-sm-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .v-application .mx-sm-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .v-application .mx-sm-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .v-application .mx-sm-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .v-application .mx-sm-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .v-application .mx-sm-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .v-application .mx-sm-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .v-application .mx-sm-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .v-application .mx-sm-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .v-application .mx-sm-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .v-application .mx-sm-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .v-application .mx-sm-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .v-application .my-sm-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .v-application .my-sm-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .v-application .my-sm-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .v-application .my-sm-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .v-application .my-sm-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .v-application .my-sm-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .v-application .my-sm-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .v-application .my-sm-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .v-application .my-sm-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .v-application .my-sm-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .v-application .my-sm-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .v-application .my-sm-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .v-application .my-sm-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .v-application .my-sm-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .v-application .my-sm-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .v-application .my-sm-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .v-application .mt-sm-n1 {
    margin-top: -4px !important;
  }

  .v-application .mt-sm-n2 {
    margin-top: -8px !important;
  }

  .v-application .mt-sm-n3 {
    margin-top: -12px !important;
  }

  .v-application .mt-sm-n4 {
    margin-top: -16px !important;
  }

  .v-application .mt-sm-n5 {
    margin-top: -20px !important;
  }

  .v-application .mt-sm-n6 {
    margin-top: -24px !important;
  }

  .v-application .mt-sm-n7 {
    margin-top: -28px !important;
  }

  .v-application .mt-sm-n8 {
    margin-top: -32px !important;
  }

  .v-application .mt-sm-n9 {
    margin-top: -36px !important;
  }

  .v-application .mt-sm-n10 {
    margin-top: -40px !important;
  }

  .v-application .mt-sm-n11 {
    margin-top: -44px !important;
  }

  .v-application .mt-sm-n12 {
    margin-top: -48px !important;
  }

  .v-application .mt-sm-n13 {
    margin-top: -52px !important;
  }

  .v-application .mt-sm-n14 {
    margin-top: -56px !important;
  }

  .v-application .mt-sm-n15 {
    margin-top: -60px !important;
  }

  .v-application .mt-sm-n16 {
    margin-top: -64px !important;
  }

  .v-application .mr-sm-n1 {
    margin-right: -4px !important;
  }

  .v-application .mr-sm-n2 {
    margin-right: -8px !important;
  }

  .v-application .mr-sm-n3 {
    margin-right: -12px !important;
  }

  .v-application .mr-sm-n4 {
    margin-right: -16px !important;
  }

  .v-application .mr-sm-n5 {
    margin-right: -20px !important;
  }

  .v-application .mr-sm-n6 {
    margin-right: -24px !important;
  }

  .v-application .mr-sm-n7 {
    margin-right: -28px !important;
  }

  .v-application .mr-sm-n8 {
    margin-right: -32px !important;
  }

  .v-application .mr-sm-n9 {
    margin-right: -36px !important;
  }

  .v-application .mr-sm-n10 {
    margin-right: -40px !important;
  }

  .v-application .mr-sm-n11 {
    margin-right: -44px !important;
  }

  .v-application .mr-sm-n12 {
    margin-right: -48px !important;
  }

  .v-application .mr-sm-n13 {
    margin-right: -52px !important;
  }

  .v-application .mr-sm-n14 {
    margin-right: -56px !important;
  }

  .v-application .mr-sm-n15 {
    margin-right: -60px !important;
  }

  .v-application .mr-sm-n16 {
    margin-right: -64px !important;
  }

  .v-application .mb-sm-n1 {
    margin-bottom: -4px !important;
  }

  .v-application .mb-sm-n2 {
    margin-bottom: -8px !important;
  }

  .v-application .mb-sm-n3 {
    margin-bottom: -12px !important;
  }

  .v-application .mb-sm-n4 {
    margin-bottom: -16px !important;
  }

  .v-application .mb-sm-n5 {
    margin-bottom: -20px !important;
  }

  .v-application .mb-sm-n6 {
    margin-bottom: -24px !important;
  }

  .v-application .mb-sm-n7 {
    margin-bottom: -28px !important;
  }

  .v-application .mb-sm-n8 {
    margin-bottom: -32px !important;
  }

  .v-application .mb-sm-n9 {
    margin-bottom: -36px !important;
  }

  .v-application .mb-sm-n10 {
    margin-bottom: -40px !important;
  }

  .v-application .mb-sm-n11 {
    margin-bottom: -44px !important;
  }

  .v-application .mb-sm-n12 {
    margin-bottom: -48px !important;
  }

  .v-application .mb-sm-n13 {
    margin-bottom: -52px !important;
  }

  .v-application .mb-sm-n14 {
    margin-bottom: -56px !important;
  }

  .v-application .mb-sm-n15 {
    margin-bottom: -60px !important;
  }

  .v-application .mb-sm-n16 {
    margin-bottom: -64px !important;
  }

  .v-application .ml-sm-n1 {
    margin-left: -4px !important;
  }

  .v-application .ml-sm-n2 {
    margin-left: -8px !important;
  }

  .v-application .ml-sm-n3 {
    margin-left: -12px !important;
  }

  .v-application .ml-sm-n4 {
    margin-left: -16px !important;
  }

  .v-application .ml-sm-n5 {
    margin-left: -20px !important;
  }

  .v-application .ml-sm-n6 {
    margin-left: -24px !important;
  }

  .v-application .ml-sm-n7 {
    margin-left: -28px !important;
  }

  .v-application .ml-sm-n8 {
    margin-left: -32px !important;
  }

  .v-application .ml-sm-n9 {
    margin-left: -36px !important;
  }

  .v-application .ml-sm-n10 {
    margin-left: -40px !important;
  }

  .v-application .ml-sm-n11 {
    margin-left: -44px !important;
  }

  .v-application .ml-sm-n12 {
    margin-left: -48px !important;
  }

  .v-application .ml-sm-n13 {
    margin-left: -52px !important;
  }

  .v-application .ml-sm-n14 {
    margin-left: -56px !important;
  }

  .v-application .ml-sm-n15 {
    margin-left: -60px !important;
  }

  .v-application .ml-sm-n16 {
    margin-left: -64px !important;
  }

  .v-application--is-ltr .ms-sm-n1 {
    margin-left: -4px !important;
  }

  .v-application--is-rtl .ms-sm-n1 {
    margin-right: -4px !important;
  }

  .v-application--is-ltr .ms-sm-n2 {
    margin-left: -8px !important;
  }

  .v-application--is-rtl .ms-sm-n2 {
    margin-right: -8px !important;
  }

  .v-application--is-ltr .ms-sm-n3 {
    margin-left: -12px !important;
  }

  .v-application--is-rtl .ms-sm-n3 {
    margin-right: -12px !important;
  }

  .v-application--is-ltr .ms-sm-n4 {
    margin-left: -16px !important;
  }

  .v-application--is-rtl .ms-sm-n4 {
    margin-right: -16px !important;
  }

  .v-application--is-ltr .ms-sm-n5 {
    margin-left: -20px !important;
  }

  .v-application--is-rtl .ms-sm-n5 {
    margin-right: -20px !important;
  }

  .v-application--is-ltr .ms-sm-n6 {
    margin-left: -24px !important;
  }

  .v-application--is-rtl .ms-sm-n6 {
    margin-right: -24px !important;
  }

  .v-application--is-ltr .ms-sm-n7 {
    margin-left: -28px !important;
  }

  .v-application--is-rtl .ms-sm-n7 {
    margin-right: -28px !important;
  }

  .v-application--is-ltr .ms-sm-n8 {
    margin-left: -32px !important;
  }

  .v-application--is-rtl .ms-sm-n8 {
    margin-right: -32px !important;
  }

  .v-application--is-ltr .ms-sm-n9 {
    margin-left: -36px !important;
  }

  .v-application--is-rtl .ms-sm-n9 {
    margin-right: -36px !important;
  }

  .v-application--is-ltr .ms-sm-n10 {
    margin-left: -40px !important;
  }

  .v-application--is-rtl .ms-sm-n10 {
    margin-right: -40px !important;
  }

  .v-application--is-ltr .ms-sm-n11 {
    margin-left: -44px !important;
  }

  .v-application--is-rtl .ms-sm-n11 {
    margin-right: -44px !important;
  }

  .v-application--is-ltr .ms-sm-n12 {
    margin-left: -48px !important;
  }

  .v-application--is-rtl .ms-sm-n12 {
    margin-right: -48px !important;
  }

  .v-application--is-ltr .ms-sm-n13 {
    margin-left: -52px !important;
  }

  .v-application--is-rtl .ms-sm-n13 {
    margin-right: -52px !important;
  }

  .v-application--is-ltr .ms-sm-n14 {
    margin-left: -56px !important;
  }

  .v-application--is-rtl .ms-sm-n14 {
    margin-right: -56px !important;
  }

  .v-application--is-ltr .ms-sm-n15 {
    margin-left: -60px !important;
  }

  .v-application--is-rtl .ms-sm-n15 {
    margin-right: -60px !important;
  }

  .v-application--is-ltr .ms-sm-n16 {
    margin-left: -64px !important;
  }

  .v-application--is-rtl .ms-sm-n16 {
    margin-right: -64px !important;
  }

  .v-application--is-ltr .me-sm-n1 {
    margin-right: -4px !important;
  }

  .v-application--is-rtl .me-sm-n1 {
    margin-left: -4px !important;
  }

  .v-application--is-ltr .me-sm-n2 {
    margin-right: -8px !important;
  }

  .v-application--is-rtl .me-sm-n2 {
    margin-left: -8px !important;
  }

  .v-application--is-ltr .me-sm-n3 {
    margin-right: -12px !important;
  }

  .v-application--is-rtl .me-sm-n3 {
    margin-left: -12px !important;
  }

  .v-application--is-ltr .me-sm-n4 {
    margin-right: -16px !important;
  }

  .v-application--is-rtl .me-sm-n4 {
    margin-left: -16px !important;
  }

  .v-application--is-ltr .me-sm-n5 {
    margin-right: -20px !important;
  }

  .v-application--is-rtl .me-sm-n5 {
    margin-left: -20px !important;
  }

  .v-application--is-ltr .me-sm-n6 {
    margin-right: -24px !important;
  }

  .v-application--is-rtl .me-sm-n6 {
    margin-left: -24px !important;
  }

  .v-application--is-ltr .me-sm-n7 {
    margin-right: -28px !important;
  }

  .v-application--is-rtl .me-sm-n7 {
    margin-left: -28px !important;
  }

  .v-application--is-ltr .me-sm-n8 {
    margin-right: -32px !important;
  }

  .v-application--is-rtl .me-sm-n8 {
    margin-left: -32px !important;
  }

  .v-application--is-ltr .me-sm-n9 {
    margin-right: -36px !important;
  }

  .v-application--is-rtl .me-sm-n9 {
    margin-left: -36px !important;
  }

  .v-application--is-ltr .me-sm-n10 {
    margin-right: -40px !important;
  }

  .v-application--is-rtl .me-sm-n10 {
    margin-left: -40px !important;
  }

  .v-application--is-ltr .me-sm-n11 {
    margin-right: -44px !important;
  }

  .v-application--is-rtl .me-sm-n11 {
    margin-left: -44px !important;
  }

  .v-application--is-ltr .me-sm-n12 {
    margin-right: -48px !important;
  }

  .v-application--is-rtl .me-sm-n12 {
    margin-left: -48px !important;
  }

  .v-application--is-ltr .me-sm-n13 {
    margin-right: -52px !important;
  }

  .v-application--is-rtl .me-sm-n13 {
    margin-left: -52px !important;
  }

  .v-application--is-ltr .me-sm-n14 {
    margin-right: -56px !important;
  }

  .v-application--is-rtl .me-sm-n14 {
    margin-left: -56px !important;
  }

  .v-application--is-ltr .me-sm-n15 {
    margin-right: -60px !important;
  }

  .v-application--is-rtl .me-sm-n15 {
    margin-left: -60px !important;
  }

  .v-application--is-ltr .me-sm-n16 {
    margin-right: -64px !important;
  }

  .v-application--is-rtl .me-sm-n16 {
    margin-left: -64px !important;
  }

  .v-application .pa-sm-0 {
    padding: 0 !important;
  }

  .v-application .pa-sm-1 {
    padding: 4px !important;
  }

  .v-application .pa-sm-2 {
    padding: 8px !important;
  }

  .v-application .pa-sm-3 {
    padding: 12px !important;
  }

  .v-application .pa-sm-4 {
    padding: 16px !important;
  }

  .v-application .pa-sm-5 {
    padding: 20px !important;
  }

  .v-application .pa-sm-6 {
    padding: 24px !important;
  }

  .v-application .pa-sm-7 {
    padding: 28px !important;
  }

  .v-application .pa-sm-8 {
    padding: 32px !important;
  }

  .v-application .pa-sm-9 {
    padding: 36px !important;
  }

  .v-application .pa-sm-10 {
    padding: 40px !important;
  }

  .v-application .pa-sm-11 {
    padding: 44px !important;
  }

  .v-application .pa-sm-12 {
    padding: 48px !important;
  }

  .v-application .pa-sm-13 {
    padding: 52px !important;
  }

  .v-application .pa-sm-14 {
    padding: 56px !important;
  }

  .v-application .pa-sm-15 {
    padding: 60px !important;
  }

  .v-application .pa-sm-16 {
    padding: 64px !important;
  }

  .v-application .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .v-application .px-sm-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .v-application .px-sm-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .v-application .px-sm-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .v-application .px-sm-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .v-application .px-sm-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .v-application .px-sm-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .v-application .px-sm-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .v-application .px-sm-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .v-application .px-sm-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .v-application .px-sm-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .v-application .px-sm-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .v-application .px-sm-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .v-application .px-sm-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .v-application .px-sm-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .v-application .px-sm-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .v-application .px-sm-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .v-application .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .v-application .py-sm-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .v-application .py-sm-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .v-application .py-sm-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .v-application .py-sm-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .v-application .py-sm-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .v-application .py-sm-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .v-application .py-sm-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .v-application .py-sm-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .v-application .py-sm-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .v-application .py-sm-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .v-application .py-sm-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .v-application .py-sm-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .v-application .py-sm-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .v-application .py-sm-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .v-application .py-sm-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .v-application .py-sm-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .v-application .pt-sm-0 {
    padding-top: 0 !important;
  }

  .v-application .pt-sm-1 {
    padding-top: 4px !important;
  }

  .v-application .pt-sm-2 {
    padding-top: 8px !important;
  }

  .v-application .pt-sm-3 {
    padding-top: 12px !important;
  }

  .v-application .pt-sm-4 {
    padding-top: 16px !important;
  }

  .v-application .pt-sm-5 {
    padding-top: 20px !important;
  }

  .v-application .pt-sm-6 {
    padding-top: 24px !important;
  }

  .v-application .pt-sm-7 {
    padding-top: 28px !important;
  }

  .v-application .pt-sm-8 {
    padding-top: 32px !important;
  }

  .v-application .pt-sm-9 {
    padding-top: 36px !important;
  }

  .v-application .pt-sm-10 {
    padding-top: 40px !important;
  }

  .v-application .pt-sm-11 {
    padding-top: 44px !important;
  }

  .v-application .pt-sm-12 {
    padding-top: 48px !important;
  }

  .v-application .pt-sm-13 {
    padding-top: 52px !important;
  }

  .v-application .pt-sm-14 {
    padding-top: 56px !important;
  }

  .v-application .pt-sm-15 {
    padding-top: 60px !important;
  }

  .v-application .pt-sm-16 {
    padding-top: 64px !important;
  }

  .v-application .pr-sm-0 {
    padding-right: 0 !important;
  }

  .v-application .pr-sm-1 {
    padding-right: 4px !important;
  }

  .v-application .pr-sm-2 {
    padding-right: 8px !important;
  }

  .v-application .pr-sm-3 {
    padding-right: 12px !important;
  }

  .v-application .pr-sm-4 {
    padding-right: 16px !important;
  }

  .v-application .pr-sm-5 {
    padding-right: 20px !important;
  }

  .v-application .pr-sm-6 {
    padding-right: 24px !important;
  }

  .v-application .pr-sm-7 {
    padding-right: 28px !important;
  }

  .v-application .pr-sm-8 {
    padding-right: 32px !important;
  }

  .v-application .pr-sm-9 {
    padding-right: 36px !important;
  }

  .v-application .pr-sm-10 {
    padding-right: 40px !important;
  }

  .v-application .pr-sm-11 {
    padding-right: 44px !important;
  }

  .v-application .pr-sm-12 {
    padding-right: 48px !important;
  }

  .v-application .pr-sm-13 {
    padding-right: 52px !important;
  }

  .v-application .pr-sm-14 {
    padding-right: 56px !important;
  }

  .v-application .pr-sm-15 {
    padding-right: 60px !important;
  }

  .v-application .pr-sm-16 {
    padding-right: 64px !important;
  }

  .v-application .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .v-application .pb-sm-1 {
    padding-bottom: 4px !important;
  }

  .v-application .pb-sm-2 {
    padding-bottom: 8px !important;
  }

  .v-application .pb-sm-3 {
    padding-bottom: 12px !important;
  }

  .v-application .pb-sm-4 {
    padding-bottom: 16px !important;
  }

  .v-application .pb-sm-5 {
    padding-bottom: 20px !important;
  }

  .v-application .pb-sm-6 {
    padding-bottom: 24px !important;
  }

  .v-application .pb-sm-7 {
    padding-bottom: 28px !important;
  }

  .v-application .pb-sm-8 {
    padding-bottom: 32px !important;
  }

  .v-application .pb-sm-9 {
    padding-bottom: 36px !important;
  }

  .v-application .pb-sm-10 {
    padding-bottom: 40px !important;
  }

  .v-application .pb-sm-11 {
    padding-bottom: 44px !important;
  }

  .v-application .pb-sm-12 {
    padding-bottom: 48px !important;
  }

  .v-application .pb-sm-13 {
    padding-bottom: 52px !important;
  }

  .v-application .pb-sm-14 {
    padding-bottom: 56px !important;
  }

  .v-application .pb-sm-15 {
    padding-bottom: 60px !important;
  }

  .v-application .pb-sm-16 {
    padding-bottom: 64px !important;
  }

  .v-application .pl-sm-0 {
    padding-left: 0 !important;
  }

  .v-application .pl-sm-1 {
    padding-left: 4px !important;
  }

  .v-application .pl-sm-2 {
    padding-left: 8px !important;
  }

  .v-application .pl-sm-3 {
    padding-left: 12px !important;
  }

  .v-application .pl-sm-4 {
    padding-left: 16px !important;
  }

  .v-application .pl-sm-5 {
    padding-left: 20px !important;
  }

  .v-application .pl-sm-6 {
    padding-left: 24px !important;
  }

  .v-application .pl-sm-7 {
    padding-left: 28px !important;
  }

  .v-application .pl-sm-8 {
    padding-left: 32px !important;
  }

  .v-application .pl-sm-9 {
    padding-left: 36px !important;
  }

  .v-application .pl-sm-10 {
    padding-left: 40px !important;
  }

  .v-application .pl-sm-11 {
    padding-left: 44px !important;
  }

  .v-application .pl-sm-12 {
    padding-left: 48px !important;
  }

  .v-application .pl-sm-13 {
    padding-left: 52px !important;
  }

  .v-application .pl-sm-14 {
    padding-left: 56px !important;
  }

  .v-application .pl-sm-15 {
    padding-left: 60px !important;
  }

  .v-application .pl-sm-16 {
    padding-left: 64px !important;
  }

  .v-application--is-ltr .ps-sm-0 {
    padding-left: 0 !important;
  }

  .v-application--is-rtl .ps-sm-0 {
    padding-right: 0 !important;
  }

  .v-application--is-ltr .ps-sm-1 {
    padding-left: 4px !important;
  }

  .v-application--is-rtl .ps-sm-1 {
    padding-right: 4px !important;
  }

  .v-application--is-ltr .ps-sm-2 {
    padding-left: 8px !important;
  }

  .v-application--is-rtl .ps-sm-2 {
    padding-right: 8px !important;
  }

  .v-application--is-ltr .ps-sm-3 {
    padding-left: 12px !important;
  }

  .v-application--is-rtl .ps-sm-3 {
    padding-right: 12px !important;
  }

  .v-application--is-ltr .ps-sm-4 {
    padding-left: 16px !important;
  }

  .v-application--is-rtl .ps-sm-4 {
    padding-right: 16px !important;
  }

  .v-application--is-ltr .ps-sm-5 {
    padding-left: 20px !important;
  }

  .v-application--is-rtl .ps-sm-5 {
    padding-right: 20px !important;
  }

  .v-application--is-ltr .ps-sm-6 {
    padding-left: 24px !important;
  }

  .v-application--is-rtl .ps-sm-6 {
    padding-right: 24px !important;
  }

  .v-application--is-ltr .ps-sm-7 {
    padding-left: 28px !important;
  }

  .v-application--is-rtl .ps-sm-7 {
    padding-right: 28px !important;
  }

  .v-application--is-ltr .ps-sm-8 {
    padding-left: 32px !important;
  }

  .v-application--is-rtl .ps-sm-8 {
    padding-right: 32px !important;
  }

  .v-application--is-ltr .ps-sm-9 {
    padding-left: 36px !important;
  }

  .v-application--is-rtl .ps-sm-9 {
    padding-right: 36px !important;
  }

  .v-application--is-ltr .ps-sm-10 {
    padding-left: 40px !important;
  }

  .v-application--is-rtl .ps-sm-10 {
    padding-right: 40px !important;
  }

  .v-application--is-ltr .ps-sm-11 {
    padding-left: 44px !important;
  }

  .v-application--is-rtl .ps-sm-11 {
    padding-right: 44px !important;
  }

  .v-application--is-ltr .ps-sm-12 {
    padding-left: 48px !important;
  }

  .v-application--is-rtl .ps-sm-12 {
    padding-right: 48px !important;
  }

  .v-application--is-ltr .ps-sm-13 {
    padding-left: 52px !important;
  }

  .v-application--is-rtl .ps-sm-13 {
    padding-right: 52px !important;
  }

  .v-application--is-ltr .ps-sm-14 {
    padding-left: 56px !important;
  }

  .v-application--is-rtl .ps-sm-14 {
    padding-right: 56px !important;
  }

  .v-application--is-ltr .ps-sm-15 {
    padding-left: 60px !important;
  }

  .v-application--is-rtl .ps-sm-15 {
    padding-right: 60px !important;
  }

  .v-application--is-ltr .ps-sm-16 {
    padding-left: 64px !important;
  }

  .v-application--is-rtl .ps-sm-16 {
    padding-right: 64px !important;
  }

  .v-application--is-ltr .pe-sm-0 {
    padding-right: 0 !important;
  }

  .v-application--is-rtl .pe-sm-0 {
    padding-left: 0 !important;
  }

  .v-application--is-ltr .pe-sm-1 {
    padding-right: 4px !important;
  }

  .v-application--is-rtl .pe-sm-1 {
    padding-left: 4px !important;
  }

  .v-application--is-ltr .pe-sm-2 {
    padding-right: 8px !important;
  }

  .v-application--is-rtl .pe-sm-2 {
    padding-left: 8px !important;
  }

  .v-application--is-ltr .pe-sm-3 {
    padding-right: 12px !important;
  }

  .v-application--is-rtl .pe-sm-3 {
    padding-left: 12px !important;
  }

  .v-application--is-ltr .pe-sm-4 {
    padding-right: 16px !important;
  }

  .v-application--is-rtl .pe-sm-4 {
    padding-left: 16px !important;
  }

  .v-application--is-ltr .pe-sm-5 {
    padding-right: 20px !important;
  }

  .v-application--is-rtl .pe-sm-5 {
    padding-left: 20px !important;
  }

  .v-application--is-ltr .pe-sm-6 {
    padding-right: 24px !important;
  }

  .v-application--is-rtl .pe-sm-6 {
    padding-left: 24px !important;
  }

  .v-application--is-ltr .pe-sm-7 {
    padding-right: 28px !important;
  }

  .v-application--is-rtl .pe-sm-7 {
    padding-left: 28px !important;
  }

  .v-application--is-ltr .pe-sm-8 {
    padding-right: 32px !important;
  }

  .v-application--is-rtl .pe-sm-8 {
    padding-left: 32px !important;
  }

  .v-application--is-ltr .pe-sm-9 {
    padding-right: 36px !important;
  }

  .v-application--is-rtl .pe-sm-9 {
    padding-left: 36px !important;
  }

  .v-application--is-ltr .pe-sm-10 {
    padding-right: 40px !important;
  }

  .v-application--is-rtl .pe-sm-10 {
    padding-left: 40px !important;
  }

  .v-application--is-ltr .pe-sm-11 {
    padding-right: 44px !important;
  }

  .v-application--is-rtl .pe-sm-11 {
    padding-left: 44px !important;
  }

  .v-application--is-ltr .pe-sm-12 {
    padding-right: 48px !important;
  }

  .v-application--is-rtl .pe-sm-12 {
    padding-left: 48px !important;
  }

  .v-application--is-ltr .pe-sm-13 {
    padding-right: 52px !important;
  }

  .v-application--is-rtl .pe-sm-13 {
    padding-left: 52px !important;
  }

  .v-application--is-ltr .pe-sm-14 {
    padding-right: 56px !important;
  }

  .v-application--is-rtl .pe-sm-14 {
    padding-left: 56px !important;
  }

  .v-application--is-ltr .pe-sm-15 {
    padding-right: 60px !important;
  }

  .v-application--is-rtl .pe-sm-15 {
    padding-left: 60px !important;
  }

  .v-application--is-ltr .pe-sm-16 {
    padding-right: 64px !important;
  }

  .v-application--is-rtl .pe-sm-16 {
    padding-left: 64px !important;
  }

  .v-application .text-sm-left {
    text-align: left !important;
  }

  .v-application .text-sm-right {
    text-align: right !important;
  }

  .v-application .text-sm-center {
    text-align: center !important;
  }

  .v-application .text-sm-justify {
    text-align: justify !important;
  }

  .v-application .text-sm-start {
    text-align: start !important;
  }

  .v-application .text-sm-end {
    text-align: end !important;
  }

  .v-application .text-sm-h1 {
    line-height: 6rem;
    letter-spacing: -.015625em !important;
    font-size: 6rem !important;
  }

  .v-application .text-sm-h1, .v-application .text-sm-h2 {
    font-weight: 300;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-sm-h2 {
    line-height: 3.75rem;
    letter-spacing: -.00833333em !important;
    font-size: 3.75rem !important;
  }

  .v-application .text-sm-h3 {
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-size: 3rem !important;
  }

  .v-application .text-sm-h3, .v-application .text-sm-h4 {
    font-weight: 400;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-sm-h4 {
    line-height: 2.5rem;
    letter-spacing: .00735294em !important;
    font-size: 2.125rem !important;
  }

  .v-application .text-sm-h5 {
    font-weight: 400;
    letter-spacing: normal !important;
    font-size: 1.5rem !important;
  }

  .v-application .text-sm-h5, .v-application .text-sm-h6 {
    line-height: 2rem;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-sm-h6 {
    font-weight: 500;
    letter-spacing: .0125em !important;
    font-size: 1.25rem !important;
  }

  .v-application .text-sm-subtitle-1 {
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: .009375em !important;
    font-family: Roboto, sans-serif !important;
    font-size: 1rem !important;
  }

  .v-application .text-sm-subtitle-2 {
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: .00714286em !important;
    font-family: Roboto, sans-serif !important;
    font-size: .875rem !important;
  }

  .v-application .text-sm-body-1 {
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: .03125em !important;
    font-family: Roboto, sans-serif !important;
    font-size: 1rem !important;
  }

  .v-application .text-sm-body-2 {
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .0178571em !important;
  }

  .v-application .text-sm-body-2, .v-application .text-sm-button {
    font-family: Roboto, sans-serif !important;
    font-size: .875rem !important;
  }

  .v-application .text-sm-button {
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
  }

  .v-application .text-sm-caption {
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .0333333em !important;
  }

  .v-application .text-sm-caption, .v-application .text-sm-overline {
    font-family: Roboto, sans-serif !important;
    font-size: .75rem !important;
  }

  .v-application .text-sm-overline {
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
  }
}

@media (min-width: 960px) {
  .v-application .d-md-none {
    display: none !important;
  }

  .v-application .d-md-inline {
    display: inline !important;
  }

  .v-application .d-md-inline-block {
    display: inline-block !important;
  }

  .v-application .d-md-block {
    display: block !important;
  }

  .v-application .d-md-table {
    display: table !important;
  }

  .v-application .d-md-table-row {
    display: table-row !important;
  }

  .v-application .d-md-table-cell {
    display: table-cell !important;
  }

  .v-application .d-md-flex {
    display: flex !important;
  }

  .v-application .d-md-inline-flex {
    display: inline-flex !important;
  }

  .v-application .float-md-none {
    float: none !important;
  }

  .v-application .float-md-left {
    float: left !important;
  }

  .v-application .float-md-right {
    float: right !important;
  }

  .v-application--is-rtl .float-md-end {
    float: left !important;
  }

  .v-application--is-ltr .float-md-end, .v-application--is-rtl .float-md-start {
    float: right !important;
  }

  .v-application--is-ltr .float-md-start {
    float: left !important;
  }

  .v-application .flex-md-fill {
    flex: auto !important;
  }

  .v-application .flex-md-row {
    flex-direction: row !important;
  }

  .v-application .flex-md-column {
    flex-direction: column !important;
  }

  .v-application .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .v-application .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .v-application .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .v-application .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .v-application .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .v-application .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .v-application .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .v-application .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .v-application .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .v-application .justify-md-start {
    justify-content: flex-start !important;
  }

  .v-application .justify-md-end {
    justify-content: flex-end !important;
  }

  .v-application .justify-md-center {
    justify-content: center !important;
  }

  .v-application .justify-md-space-between {
    justify-content: space-between !important;
  }

  .v-application .justify-md-space-around {
    justify-content: space-around !important;
  }

  .v-application .align-md-start {
    align-items: flex-start !important;
  }

  .v-application .align-md-end {
    align-items: flex-end !important;
  }

  .v-application .align-md-center {
    align-items: center !important;
  }

  .v-application .align-md-baseline {
    align-items: baseline !important;
  }

  .v-application .align-md-stretch {
    align-items: stretch !important;
  }

  .v-application .align-content-md-start {
    align-content: flex-start !important;
  }

  .v-application .align-content-md-end {
    align-content: flex-end !important;
  }

  .v-application .align-content-md-center {
    align-content: center !important;
  }

  .v-application .align-content-md-space-between {
    align-content: space-between !important;
  }

  .v-application .align-content-md-space-around {
    align-content: space-around !important;
  }

  .v-application .align-content-md-stretch {
    align-content: stretch !important;
  }

  .v-application .align-self-md-auto {
    align-self: auto !important;
  }

  .v-application .align-self-md-start {
    align-self: flex-start !important;
  }

  .v-application .align-self-md-end {
    align-self: flex-end !important;
  }

  .v-application .align-self-md-center {
    align-self: center !important;
  }

  .v-application .align-self-md-baseline {
    align-self: baseline !important;
  }

  .v-application .align-self-md-stretch {
    align-self: stretch !important;
  }

  .v-application .order-md-first {
    order: -1 !important;
  }

  .v-application .order-md-0 {
    order: 0 !important;
  }

  .v-application .order-md-1 {
    order: 1 !important;
  }

  .v-application .order-md-2 {
    order: 2 !important;
  }

  .v-application .order-md-3 {
    order: 3 !important;
  }

  .v-application .order-md-4 {
    order: 4 !important;
  }

  .v-application .order-md-5 {
    order: 5 !important;
  }

  .v-application .order-md-6 {
    order: 6 !important;
  }

  .v-application .order-md-7 {
    order: 7 !important;
  }

  .v-application .order-md-8 {
    order: 8 !important;
  }

  .v-application .order-md-9 {
    order: 9 !important;
  }

  .v-application .order-md-10 {
    order: 10 !important;
  }

  .v-application .order-md-11 {
    order: 11 !important;
  }

  .v-application .order-md-12 {
    order: 12 !important;
  }

  .v-application .order-md-last {
    order: 13 !important;
  }

  .v-application .ma-md-0 {
    margin: 0 !important;
  }

  .v-application .ma-md-1 {
    margin: 4px !important;
  }

  .v-application .ma-md-2 {
    margin: 8px !important;
  }

  .v-application .ma-md-3 {
    margin: 12px !important;
  }

  .v-application .ma-md-4 {
    margin: 16px !important;
  }

  .v-application .ma-md-5 {
    margin: 20px !important;
  }

  .v-application .ma-md-6 {
    margin: 24px !important;
  }

  .v-application .ma-md-7 {
    margin: 28px !important;
  }

  .v-application .ma-md-8 {
    margin: 32px !important;
  }

  .v-application .ma-md-9 {
    margin: 36px !important;
  }

  .v-application .ma-md-10 {
    margin: 40px !important;
  }

  .v-application .ma-md-11 {
    margin: 44px !important;
  }

  .v-application .ma-md-12 {
    margin: 48px !important;
  }

  .v-application .ma-md-13 {
    margin: 52px !important;
  }

  .v-application .ma-md-14 {
    margin: 56px !important;
  }

  .v-application .ma-md-15 {
    margin: 60px !important;
  }

  .v-application .ma-md-16 {
    margin: 64px !important;
  }

  .v-application .ma-md-auto {
    margin: auto !important;
  }

  .v-application .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .v-application .mx-md-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .v-application .mx-md-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .v-application .mx-md-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .v-application .mx-md-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .v-application .mx-md-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .v-application .mx-md-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .v-application .mx-md-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .v-application .mx-md-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .v-application .mx-md-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .v-application .mx-md-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .v-application .mx-md-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .v-application .mx-md-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .v-application .mx-md-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .v-application .mx-md-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .v-application .mx-md-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .v-application .mx-md-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .v-application .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .v-application .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .v-application .my-md-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .v-application .my-md-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .v-application .my-md-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .v-application .my-md-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .v-application .my-md-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .v-application .my-md-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .v-application .my-md-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .v-application .my-md-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .v-application .my-md-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .v-application .my-md-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .v-application .my-md-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .v-application .my-md-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .v-application .my-md-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .v-application .my-md-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .v-application .my-md-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .v-application .my-md-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .v-application .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .v-application .mt-md-0 {
    margin-top: 0 !important;
  }

  .v-application .mt-md-1 {
    margin-top: 4px !important;
  }

  .v-application .mt-md-2 {
    margin-top: 8px !important;
  }

  .v-application .mt-md-3 {
    margin-top: 12px !important;
  }

  .v-application .mt-md-4 {
    margin-top: 16px !important;
  }

  .v-application .mt-md-5 {
    margin-top: 20px !important;
  }

  .v-application .mt-md-6 {
    margin-top: 24px !important;
  }

  .v-application .mt-md-7 {
    margin-top: 28px !important;
  }

  .v-application .mt-md-8 {
    margin-top: 32px !important;
  }

  .v-application .mt-md-9 {
    margin-top: 36px !important;
  }

  .v-application .mt-md-10 {
    margin-top: 40px !important;
  }

  .v-application .mt-md-11 {
    margin-top: 44px !important;
  }

  .v-application .mt-md-12 {
    margin-top: 48px !important;
  }

  .v-application .mt-md-13 {
    margin-top: 52px !important;
  }

  .v-application .mt-md-14 {
    margin-top: 56px !important;
  }

  .v-application .mt-md-15 {
    margin-top: 60px !important;
  }

  .v-application .mt-md-16 {
    margin-top: 64px !important;
  }

  .v-application .mt-md-auto {
    margin-top: auto !important;
  }

  .v-application .mr-md-0 {
    margin-right: 0 !important;
  }

  .v-application .mr-md-1 {
    margin-right: 4px !important;
  }

  .v-application .mr-md-2 {
    margin-right: 8px !important;
  }

  .v-application .mr-md-3 {
    margin-right: 12px !important;
  }

  .v-application .mr-md-4 {
    margin-right: 16px !important;
  }

  .v-application .mr-md-5 {
    margin-right: 20px !important;
  }

  .v-application .mr-md-6 {
    margin-right: 24px !important;
  }

  .v-application .mr-md-7 {
    margin-right: 28px !important;
  }

  .v-application .mr-md-8 {
    margin-right: 32px !important;
  }

  .v-application .mr-md-9 {
    margin-right: 36px !important;
  }

  .v-application .mr-md-10 {
    margin-right: 40px !important;
  }

  .v-application .mr-md-11 {
    margin-right: 44px !important;
  }

  .v-application .mr-md-12 {
    margin-right: 48px !important;
  }

  .v-application .mr-md-13 {
    margin-right: 52px !important;
  }

  .v-application .mr-md-14 {
    margin-right: 56px !important;
  }

  .v-application .mr-md-15 {
    margin-right: 60px !important;
  }

  .v-application .mr-md-16 {
    margin-right: 64px !important;
  }

  .v-application .mr-md-auto {
    margin-right: auto !important;
  }

  .v-application .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .v-application .mb-md-1 {
    margin-bottom: 4px !important;
  }

  .v-application .mb-md-2 {
    margin-bottom: 8px !important;
  }

  .v-application .mb-md-3 {
    margin-bottom: 12px !important;
  }

  .v-application .mb-md-4 {
    margin-bottom: 16px !important;
  }

  .v-application .mb-md-5 {
    margin-bottom: 20px !important;
  }

  .v-application .mb-md-6 {
    margin-bottom: 24px !important;
  }

  .v-application .mb-md-7 {
    margin-bottom: 28px !important;
  }

  .v-application .mb-md-8 {
    margin-bottom: 32px !important;
  }

  .v-application .mb-md-9 {
    margin-bottom: 36px !important;
  }

  .v-application .mb-md-10 {
    margin-bottom: 40px !important;
  }

  .v-application .mb-md-11 {
    margin-bottom: 44px !important;
  }

  .v-application .mb-md-12 {
    margin-bottom: 48px !important;
  }

  .v-application .mb-md-13 {
    margin-bottom: 52px !important;
  }

  .v-application .mb-md-14 {
    margin-bottom: 56px !important;
  }

  .v-application .mb-md-15 {
    margin-bottom: 60px !important;
  }

  .v-application .mb-md-16 {
    margin-bottom: 64px !important;
  }

  .v-application .mb-md-auto {
    margin-bottom: auto !important;
  }

  .v-application .ml-md-0 {
    margin-left: 0 !important;
  }

  .v-application .ml-md-1 {
    margin-left: 4px !important;
  }

  .v-application .ml-md-2 {
    margin-left: 8px !important;
  }

  .v-application .ml-md-3 {
    margin-left: 12px !important;
  }

  .v-application .ml-md-4 {
    margin-left: 16px !important;
  }

  .v-application .ml-md-5 {
    margin-left: 20px !important;
  }

  .v-application .ml-md-6 {
    margin-left: 24px !important;
  }

  .v-application .ml-md-7 {
    margin-left: 28px !important;
  }

  .v-application .ml-md-8 {
    margin-left: 32px !important;
  }

  .v-application .ml-md-9 {
    margin-left: 36px !important;
  }

  .v-application .ml-md-10 {
    margin-left: 40px !important;
  }

  .v-application .ml-md-11 {
    margin-left: 44px !important;
  }

  .v-application .ml-md-12 {
    margin-left: 48px !important;
  }

  .v-application .ml-md-13 {
    margin-left: 52px !important;
  }

  .v-application .ml-md-14 {
    margin-left: 56px !important;
  }

  .v-application .ml-md-15 {
    margin-left: 60px !important;
  }

  .v-application .ml-md-16 {
    margin-left: 64px !important;
  }

  .v-application .ml-md-auto {
    margin-left: auto !important;
  }

  .v-application--is-ltr .ms-md-0 {
    margin-left: 0 !important;
  }

  .v-application--is-rtl .ms-md-0 {
    margin-right: 0 !important;
  }

  .v-application--is-ltr .ms-md-1 {
    margin-left: 4px !important;
  }

  .v-application--is-rtl .ms-md-1 {
    margin-right: 4px !important;
  }

  .v-application--is-ltr .ms-md-2 {
    margin-left: 8px !important;
  }

  .v-application--is-rtl .ms-md-2 {
    margin-right: 8px !important;
  }

  .v-application--is-ltr .ms-md-3 {
    margin-left: 12px !important;
  }

  .v-application--is-rtl .ms-md-3 {
    margin-right: 12px !important;
  }

  .v-application--is-ltr .ms-md-4 {
    margin-left: 16px !important;
  }

  .v-application--is-rtl .ms-md-4 {
    margin-right: 16px !important;
  }

  .v-application--is-ltr .ms-md-5 {
    margin-left: 20px !important;
  }

  .v-application--is-rtl .ms-md-5 {
    margin-right: 20px !important;
  }

  .v-application--is-ltr .ms-md-6 {
    margin-left: 24px !important;
  }

  .v-application--is-rtl .ms-md-6 {
    margin-right: 24px !important;
  }

  .v-application--is-ltr .ms-md-7 {
    margin-left: 28px !important;
  }

  .v-application--is-rtl .ms-md-7 {
    margin-right: 28px !important;
  }

  .v-application--is-ltr .ms-md-8 {
    margin-left: 32px !important;
  }

  .v-application--is-rtl .ms-md-8 {
    margin-right: 32px !important;
  }

  .v-application--is-ltr .ms-md-9 {
    margin-left: 36px !important;
  }

  .v-application--is-rtl .ms-md-9 {
    margin-right: 36px !important;
  }

  .v-application--is-ltr .ms-md-10 {
    margin-left: 40px !important;
  }

  .v-application--is-rtl .ms-md-10 {
    margin-right: 40px !important;
  }

  .v-application--is-ltr .ms-md-11 {
    margin-left: 44px !important;
  }

  .v-application--is-rtl .ms-md-11 {
    margin-right: 44px !important;
  }

  .v-application--is-ltr .ms-md-12 {
    margin-left: 48px !important;
  }

  .v-application--is-rtl .ms-md-12 {
    margin-right: 48px !important;
  }

  .v-application--is-ltr .ms-md-13 {
    margin-left: 52px !important;
  }

  .v-application--is-rtl .ms-md-13 {
    margin-right: 52px !important;
  }

  .v-application--is-ltr .ms-md-14 {
    margin-left: 56px !important;
  }

  .v-application--is-rtl .ms-md-14 {
    margin-right: 56px !important;
  }

  .v-application--is-ltr .ms-md-15 {
    margin-left: 60px !important;
  }

  .v-application--is-rtl .ms-md-15 {
    margin-right: 60px !important;
  }

  .v-application--is-ltr .ms-md-16 {
    margin-left: 64px !important;
  }

  .v-application--is-rtl .ms-md-16 {
    margin-right: 64px !important;
  }

  .v-application--is-ltr .ms-md-auto {
    margin-left: auto !important;
  }

  .v-application--is-rtl .ms-md-auto {
    margin-right: auto !important;
  }

  .v-application--is-ltr .me-md-0 {
    margin-right: 0 !important;
  }

  .v-application--is-rtl .me-md-0 {
    margin-left: 0 !important;
  }

  .v-application--is-ltr .me-md-1 {
    margin-right: 4px !important;
  }

  .v-application--is-rtl .me-md-1 {
    margin-left: 4px !important;
  }

  .v-application--is-ltr .me-md-2 {
    margin-right: 8px !important;
  }

  .v-application--is-rtl .me-md-2 {
    margin-left: 8px !important;
  }

  .v-application--is-ltr .me-md-3 {
    margin-right: 12px !important;
  }

  .v-application--is-rtl .me-md-3 {
    margin-left: 12px !important;
  }

  .v-application--is-ltr .me-md-4 {
    margin-right: 16px !important;
  }

  .v-application--is-rtl .me-md-4 {
    margin-left: 16px !important;
  }

  .v-application--is-ltr .me-md-5 {
    margin-right: 20px !important;
  }

  .v-application--is-rtl .me-md-5 {
    margin-left: 20px !important;
  }

  .v-application--is-ltr .me-md-6 {
    margin-right: 24px !important;
  }

  .v-application--is-rtl .me-md-6 {
    margin-left: 24px !important;
  }

  .v-application--is-ltr .me-md-7 {
    margin-right: 28px !important;
  }

  .v-application--is-rtl .me-md-7 {
    margin-left: 28px !important;
  }

  .v-application--is-ltr .me-md-8 {
    margin-right: 32px !important;
  }

  .v-application--is-rtl .me-md-8 {
    margin-left: 32px !important;
  }

  .v-application--is-ltr .me-md-9 {
    margin-right: 36px !important;
  }

  .v-application--is-rtl .me-md-9 {
    margin-left: 36px !important;
  }

  .v-application--is-ltr .me-md-10 {
    margin-right: 40px !important;
  }

  .v-application--is-rtl .me-md-10 {
    margin-left: 40px !important;
  }

  .v-application--is-ltr .me-md-11 {
    margin-right: 44px !important;
  }

  .v-application--is-rtl .me-md-11 {
    margin-left: 44px !important;
  }

  .v-application--is-ltr .me-md-12 {
    margin-right: 48px !important;
  }

  .v-application--is-rtl .me-md-12 {
    margin-left: 48px !important;
  }

  .v-application--is-ltr .me-md-13 {
    margin-right: 52px !important;
  }

  .v-application--is-rtl .me-md-13 {
    margin-left: 52px !important;
  }

  .v-application--is-ltr .me-md-14 {
    margin-right: 56px !important;
  }

  .v-application--is-rtl .me-md-14 {
    margin-left: 56px !important;
  }

  .v-application--is-ltr .me-md-15 {
    margin-right: 60px !important;
  }

  .v-application--is-rtl .me-md-15 {
    margin-left: 60px !important;
  }

  .v-application--is-ltr .me-md-16 {
    margin-right: 64px !important;
  }

  .v-application--is-rtl .me-md-16 {
    margin-left: 64px !important;
  }

  .v-application--is-ltr .me-md-auto {
    margin-right: auto !important;
  }

  .v-application--is-rtl .me-md-auto {
    margin-left: auto !important;
  }

  .v-application .ma-md-n1 {
    margin: -4px !important;
  }

  .v-application .ma-md-n2 {
    margin: -8px !important;
  }

  .v-application .ma-md-n3 {
    margin: -12px !important;
  }

  .v-application .ma-md-n4 {
    margin: -16px !important;
  }

  .v-application .ma-md-n5 {
    margin: -20px !important;
  }

  .v-application .ma-md-n6 {
    margin: -24px !important;
  }

  .v-application .ma-md-n7 {
    margin: -28px !important;
  }

  .v-application .ma-md-n8 {
    margin: -32px !important;
  }

  .v-application .ma-md-n9 {
    margin: -36px !important;
  }

  .v-application .ma-md-n10 {
    margin: -40px !important;
  }

  .v-application .ma-md-n11 {
    margin: -44px !important;
  }

  .v-application .ma-md-n12 {
    margin: -48px !important;
  }

  .v-application .ma-md-n13 {
    margin: -52px !important;
  }

  .v-application .ma-md-n14 {
    margin: -56px !important;
  }

  .v-application .ma-md-n15 {
    margin: -60px !important;
  }

  .v-application .ma-md-n16 {
    margin: -64px !important;
  }

  .v-application .mx-md-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .v-application .mx-md-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .v-application .mx-md-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .v-application .mx-md-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .v-application .mx-md-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .v-application .mx-md-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .v-application .mx-md-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .v-application .mx-md-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .v-application .mx-md-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .v-application .mx-md-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .v-application .mx-md-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .v-application .mx-md-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .v-application .mx-md-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .v-application .mx-md-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .v-application .mx-md-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .v-application .mx-md-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .v-application .my-md-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .v-application .my-md-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .v-application .my-md-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .v-application .my-md-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .v-application .my-md-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .v-application .my-md-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .v-application .my-md-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .v-application .my-md-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .v-application .my-md-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .v-application .my-md-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .v-application .my-md-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .v-application .my-md-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .v-application .my-md-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .v-application .my-md-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .v-application .my-md-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .v-application .my-md-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .v-application .mt-md-n1 {
    margin-top: -4px !important;
  }

  .v-application .mt-md-n2 {
    margin-top: -8px !important;
  }

  .v-application .mt-md-n3 {
    margin-top: -12px !important;
  }

  .v-application .mt-md-n4 {
    margin-top: -16px !important;
  }

  .v-application .mt-md-n5 {
    margin-top: -20px !important;
  }

  .v-application .mt-md-n6 {
    margin-top: -24px !important;
  }

  .v-application .mt-md-n7 {
    margin-top: -28px !important;
  }

  .v-application .mt-md-n8 {
    margin-top: -32px !important;
  }

  .v-application .mt-md-n9 {
    margin-top: -36px !important;
  }

  .v-application .mt-md-n10 {
    margin-top: -40px !important;
  }

  .v-application .mt-md-n11 {
    margin-top: -44px !important;
  }

  .v-application .mt-md-n12 {
    margin-top: -48px !important;
  }

  .v-application .mt-md-n13 {
    margin-top: -52px !important;
  }

  .v-application .mt-md-n14 {
    margin-top: -56px !important;
  }

  .v-application .mt-md-n15 {
    margin-top: -60px !important;
  }

  .v-application .mt-md-n16 {
    margin-top: -64px !important;
  }

  .v-application .mr-md-n1 {
    margin-right: -4px !important;
  }

  .v-application .mr-md-n2 {
    margin-right: -8px !important;
  }

  .v-application .mr-md-n3 {
    margin-right: -12px !important;
  }

  .v-application .mr-md-n4 {
    margin-right: -16px !important;
  }

  .v-application .mr-md-n5 {
    margin-right: -20px !important;
  }

  .v-application .mr-md-n6 {
    margin-right: -24px !important;
  }

  .v-application .mr-md-n7 {
    margin-right: -28px !important;
  }

  .v-application .mr-md-n8 {
    margin-right: -32px !important;
  }

  .v-application .mr-md-n9 {
    margin-right: -36px !important;
  }

  .v-application .mr-md-n10 {
    margin-right: -40px !important;
  }

  .v-application .mr-md-n11 {
    margin-right: -44px !important;
  }

  .v-application .mr-md-n12 {
    margin-right: -48px !important;
  }

  .v-application .mr-md-n13 {
    margin-right: -52px !important;
  }

  .v-application .mr-md-n14 {
    margin-right: -56px !important;
  }

  .v-application .mr-md-n15 {
    margin-right: -60px !important;
  }

  .v-application .mr-md-n16 {
    margin-right: -64px !important;
  }

  .v-application .mb-md-n1 {
    margin-bottom: -4px !important;
  }

  .v-application .mb-md-n2 {
    margin-bottom: -8px !important;
  }

  .v-application .mb-md-n3 {
    margin-bottom: -12px !important;
  }

  .v-application .mb-md-n4 {
    margin-bottom: -16px !important;
  }

  .v-application .mb-md-n5 {
    margin-bottom: -20px !important;
  }

  .v-application .mb-md-n6 {
    margin-bottom: -24px !important;
  }

  .v-application .mb-md-n7 {
    margin-bottom: -28px !important;
  }

  .v-application .mb-md-n8 {
    margin-bottom: -32px !important;
  }

  .v-application .mb-md-n9 {
    margin-bottom: -36px !important;
  }

  .v-application .mb-md-n10 {
    margin-bottom: -40px !important;
  }

  .v-application .mb-md-n11 {
    margin-bottom: -44px !important;
  }

  .v-application .mb-md-n12 {
    margin-bottom: -48px !important;
  }

  .v-application .mb-md-n13 {
    margin-bottom: -52px !important;
  }

  .v-application .mb-md-n14 {
    margin-bottom: -56px !important;
  }

  .v-application .mb-md-n15 {
    margin-bottom: -60px !important;
  }

  .v-application .mb-md-n16 {
    margin-bottom: -64px !important;
  }

  .v-application .ml-md-n1 {
    margin-left: -4px !important;
  }

  .v-application .ml-md-n2 {
    margin-left: -8px !important;
  }

  .v-application .ml-md-n3 {
    margin-left: -12px !important;
  }

  .v-application .ml-md-n4 {
    margin-left: -16px !important;
  }

  .v-application .ml-md-n5 {
    margin-left: -20px !important;
  }

  .v-application .ml-md-n6 {
    margin-left: -24px !important;
  }

  .v-application .ml-md-n7 {
    margin-left: -28px !important;
  }

  .v-application .ml-md-n8 {
    margin-left: -32px !important;
  }

  .v-application .ml-md-n9 {
    margin-left: -36px !important;
  }

  .v-application .ml-md-n10 {
    margin-left: -40px !important;
  }

  .v-application .ml-md-n11 {
    margin-left: -44px !important;
  }

  .v-application .ml-md-n12 {
    margin-left: -48px !important;
  }

  .v-application .ml-md-n13 {
    margin-left: -52px !important;
  }

  .v-application .ml-md-n14 {
    margin-left: -56px !important;
  }

  .v-application .ml-md-n15 {
    margin-left: -60px !important;
  }

  .v-application .ml-md-n16 {
    margin-left: -64px !important;
  }

  .v-application--is-ltr .ms-md-n1 {
    margin-left: -4px !important;
  }

  .v-application--is-rtl .ms-md-n1 {
    margin-right: -4px !important;
  }

  .v-application--is-ltr .ms-md-n2 {
    margin-left: -8px !important;
  }

  .v-application--is-rtl .ms-md-n2 {
    margin-right: -8px !important;
  }

  .v-application--is-ltr .ms-md-n3 {
    margin-left: -12px !important;
  }

  .v-application--is-rtl .ms-md-n3 {
    margin-right: -12px !important;
  }

  .v-application--is-ltr .ms-md-n4 {
    margin-left: -16px !important;
  }

  .v-application--is-rtl .ms-md-n4 {
    margin-right: -16px !important;
  }

  .v-application--is-ltr .ms-md-n5 {
    margin-left: -20px !important;
  }

  .v-application--is-rtl .ms-md-n5 {
    margin-right: -20px !important;
  }

  .v-application--is-ltr .ms-md-n6 {
    margin-left: -24px !important;
  }

  .v-application--is-rtl .ms-md-n6 {
    margin-right: -24px !important;
  }

  .v-application--is-ltr .ms-md-n7 {
    margin-left: -28px !important;
  }

  .v-application--is-rtl .ms-md-n7 {
    margin-right: -28px !important;
  }

  .v-application--is-ltr .ms-md-n8 {
    margin-left: -32px !important;
  }

  .v-application--is-rtl .ms-md-n8 {
    margin-right: -32px !important;
  }

  .v-application--is-ltr .ms-md-n9 {
    margin-left: -36px !important;
  }

  .v-application--is-rtl .ms-md-n9 {
    margin-right: -36px !important;
  }

  .v-application--is-ltr .ms-md-n10 {
    margin-left: -40px !important;
  }

  .v-application--is-rtl .ms-md-n10 {
    margin-right: -40px !important;
  }

  .v-application--is-ltr .ms-md-n11 {
    margin-left: -44px !important;
  }

  .v-application--is-rtl .ms-md-n11 {
    margin-right: -44px !important;
  }

  .v-application--is-ltr .ms-md-n12 {
    margin-left: -48px !important;
  }

  .v-application--is-rtl .ms-md-n12 {
    margin-right: -48px !important;
  }

  .v-application--is-ltr .ms-md-n13 {
    margin-left: -52px !important;
  }

  .v-application--is-rtl .ms-md-n13 {
    margin-right: -52px !important;
  }

  .v-application--is-ltr .ms-md-n14 {
    margin-left: -56px !important;
  }

  .v-application--is-rtl .ms-md-n14 {
    margin-right: -56px !important;
  }

  .v-application--is-ltr .ms-md-n15 {
    margin-left: -60px !important;
  }

  .v-application--is-rtl .ms-md-n15 {
    margin-right: -60px !important;
  }

  .v-application--is-ltr .ms-md-n16 {
    margin-left: -64px !important;
  }

  .v-application--is-rtl .ms-md-n16 {
    margin-right: -64px !important;
  }

  .v-application--is-ltr .me-md-n1 {
    margin-right: -4px !important;
  }

  .v-application--is-rtl .me-md-n1 {
    margin-left: -4px !important;
  }

  .v-application--is-ltr .me-md-n2 {
    margin-right: -8px !important;
  }

  .v-application--is-rtl .me-md-n2 {
    margin-left: -8px !important;
  }

  .v-application--is-ltr .me-md-n3 {
    margin-right: -12px !important;
  }

  .v-application--is-rtl .me-md-n3 {
    margin-left: -12px !important;
  }

  .v-application--is-ltr .me-md-n4 {
    margin-right: -16px !important;
  }

  .v-application--is-rtl .me-md-n4 {
    margin-left: -16px !important;
  }

  .v-application--is-ltr .me-md-n5 {
    margin-right: -20px !important;
  }

  .v-application--is-rtl .me-md-n5 {
    margin-left: -20px !important;
  }

  .v-application--is-ltr .me-md-n6 {
    margin-right: -24px !important;
  }

  .v-application--is-rtl .me-md-n6 {
    margin-left: -24px !important;
  }

  .v-application--is-ltr .me-md-n7 {
    margin-right: -28px !important;
  }

  .v-application--is-rtl .me-md-n7 {
    margin-left: -28px !important;
  }

  .v-application--is-ltr .me-md-n8 {
    margin-right: -32px !important;
  }

  .v-application--is-rtl .me-md-n8 {
    margin-left: -32px !important;
  }

  .v-application--is-ltr .me-md-n9 {
    margin-right: -36px !important;
  }

  .v-application--is-rtl .me-md-n9 {
    margin-left: -36px !important;
  }

  .v-application--is-ltr .me-md-n10 {
    margin-right: -40px !important;
  }

  .v-application--is-rtl .me-md-n10 {
    margin-left: -40px !important;
  }

  .v-application--is-ltr .me-md-n11 {
    margin-right: -44px !important;
  }

  .v-application--is-rtl .me-md-n11 {
    margin-left: -44px !important;
  }

  .v-application--is-ltr .me-md-n12 {
    margin-right: -48px !important;
  }

  .v-application--is-rtl .me-md-n12 {
    margin-left: -48px !important;
  }

  .v-application--is-ltr .me-md-n13 {
    margin-right: -52px !important;
  }

  .v-application--is-rtl .me-md-n13 {
    margin-left: -52px !important;
  }

  .v-application--is-ltr .me-md-n14 {
    margin-right: -56px !important;
  }

  .v-application--is-rtl .me-md-n14 {
    margin-left: -56px !important;
  }

  .v-application--is-ltr .me-md-n15 {
    margin-right: -60px !important;
  }

  .v-application--is-rtl .me-md-n15 {
    margin-left: -60px !important;
  }

  .v-application--is-ltr .me-md-n16 {
    margin-right: -64px !important;
  }

  .v-application--is-rtl .me-md-n16 {
    margin-left: -64px !important;
  }

  .v-application .pa-md-0 {
    padding: 0 !important;
  }

  .v-application .pa-md-1 {
    padding: 4px !important;
  }

  .v-application .pa-md-2 {
    padding: 8px !important;
  }

  .v-application .pa-md-3 {
    padding: 12px !important;
  }

  .v-application .pa-md-4 {
    padding: 16px !important;
  }

  .v-application .pa-md-5 {
    padding: 20px !important;
  }

  .v-application .pa-md-6 {
    padding: 24px !important;
  }

  .v-application .pa-md-7 {
    padding: 28px !important;
  }

  .v-application .pa-md-8 {
    padding: 32px !important;
  }

  .v-application .pa-md-9 {
    padding: 36px !important;
  }

  .v-application .pa-md-10 {
    padding: 40px !important;
  }

  .v-application .pa-md-11 {
    padding: 44px !important;
  }

  .v-application .pa-md-12 {
    padding: 48px !important;
  }

  .v-application .pa-md-13 {
    padding: 52px !important;
  }

  .v-application .pa-md-14 {
    padding: 56px !important;
  }

  .v-application .pa-md-15 {
    padding: 60px !important;
  }

  .v-application .pa-md-16 {
    padding: 64px !important;
  }

  .v-application .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .v-application .px-md-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .v-application .px-md-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .v-application .px-md-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .v-application .px-md-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .v-application .px-md-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .v-application .px-md-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .v-application .px-md-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .v-application .px-md-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .v-application .px-md-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .v-application .px-md-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .v-application .px-md-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .v-application .px-md-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .v-application .px-md-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .v-application .px-md-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .v-application .px-md-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .v-application .px-md-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .v-application .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .v-application .py-md-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .v-application .py-md-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .v-application .py-md-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .v-application .py-md-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .v-application .py-md-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .v-application .py-md-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .v-application .py-md-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .v-application .py-md-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .v-application .py-md-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .v-application .py-md-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .v-application .py-md-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .v-application .py-md-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .v-application .py-md-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .v-application .py-md-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .v-application .py-md-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .v-application .py-md-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .v-application .pt-md-0 {
    padding-top: 0 !important;
  }

  .v-application .pt-md-1 {
    padding-top: 4px !important;
  }

  .v-application .pt-md-2 {
    padding-top: 8px !important;
  }

  .v-application .pt-md-3 {
    padding-top: 12px !important;
  }

  .v-application .pt-md-4 {
    padding-top: 16px !important;
  }

  .v-application .pt-md-5 {
    padding-top: 20px !important;
  }

  .v-application .pt-md-6 {
    padding-top: 24px !important;
  }

  .v-application .pt-md-7 {
    padding-top: 28px !important;
  }

  .v-application .pt-md-8 {
    padding-top: 32px !important;
  }

  .v-application .pt-md-9 {
    padding-top: 36px !important;
  }

  .v-application .pt-md-10 {
    padding-top: 40px !important;
  }

  .v-application .pt-md-11 {
    padding-top: 44px !important;
  }

  .v-application .pt-md-12 {
    padding-top: 48px !important;
  }

  .v-application .pt-md-13 {
    padding-top: 52px !important;
  }

  .v-application .pt-md-14 {
    padding-top: 56px !important;
  }

  .v-application .pt-md-15 {
    padding-top: 60px !important;
  }

  .v-application .pt-md-16 {
    padding-top: 64px !important;
  }

  .v-application .pr-md-0 {
    padding-right: 0 !important;
  }

  .v-application .pr-md-1 {
    padding-right: 4px !important;
  }

  .v-application .pr-md-2 {
    padding-right: 8px !important;
  }

  .v-application .pr-md-3 {
    padding-right: 12px !important;
  }

  .v-application .pr-md-4 {
    padding-right: 16px !important;
  }

  .v-application .pr-md-5 {
    padding-right: 20px !important;
  }

  .v-application .pr-md-6 {
    padding-right: 24px !important;
  }

  .v-application .pr-md-7 {
    padding-right: 28px !important;
  }

  .v-application .pr-md-8 {
    padding-right: 32px !important;
  }

  .v-application .pr-md-9 {
    padding-right: 36px !important;
  }

  .v-application .pr-md-10 {
    padding-right: 40px !important;
  }

  .v-application .pr-md-11 {
    padding-right: 44px !important;
  }

  .v-application .pr-md-12 {
    padding-right: 48px !important;
  }

  .v-application .pr-md-13 {
    padding-right: 52px !important;
  }

  .v-application .pr-md-14 {
    padding-right: 56px !important;
  }

  .v-application .pr-md-15 {
    padding-right: 60px !important;
  }

  .v-application .pr-md-16 {
    padding-right: 64px !important;
  }

  .v-application .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .v-application .pb-md-1 {
    padding-bottom: 4px !important;
  }

  .v-application .pb-md-2 {
    padding-bottom: 8px !important;
  }

  .v-application .pb-md-3 {
    padding-bottom: 12px !important;
  }

  .v-application .pb-md-4 {
    padding-bottom: 16px !important;
  }

  .v-application .pb-md-5 {
    padding-bottom: 20px !important;
  }

  .v-application .pb-md-6 {
    padding-bottom: 24px !important;
  }

  .v-application .pb-md-7 {
    padding-bottom: 28px !important;
  }

  .v-application .pb-md-8 {
    padding-bottom: 32px !important;
  }

  .v-application .pb-md-9 {
    padding-bottom: 36px !important;
  }

  .v-application .pb-md-10 {
    padding-bottom: 40px !important;
  }

  .v-application .pb-md-11 {
    padding-bottom: 44px !important;
  }

  .v-application .pb-md-12 {
    padding-bottom: 48px !important;
  }

  .v-application .pb-md-13 {
    padding-bottom: 52px !important;
  }

  .v-application .pb-md-14 {
    padding-bottom: 56px !important;
  }

  .v-application .pb-md-15 {
    padding-bottom: 60px !important;
  }

  .v-application .pb-md-16 {
    padding-bottom: 64px !important;
  }

  .v-application .pl-md-0 {
    padding-left: 0 !important;
  }

  .v-application .pl-md-1 {
    padding-left: 4px !important;
  }

  .v-application .pl-md-2 {
    padding-left: 8px !important;
  }

  .v-application .pl-md-3 {
    padding-left: 12px !important;
  }

  .v-application .pl-md-4 {
    padding-left: 16px !important;
  }

  .v-application .pl-md-5 {
    padding-left: 20px !important;
  }

  .v-application .pl-md-6 {
    padding-left: 24px !important;
  }

  .v-application .pl-md-7 {
    padding-left: 28px !important;
  }

  .v-application .pl-md-8 {
    padding-left: 32px !important;
  }

  .v-application .pl-md-9 {
    padding-left: 36px !important;
  }

  .v-application .pl-md-10 {
    padding-left: 40px !important;
  }

  .v-application .pl-md-11 {
    padding-left: 44px !important;
  }

  .v-application .pl-md-12 {
    padding-left: 48px !important;
  }

  .v-application .pl-md-13 {
    padding-left: 52px !important;
  }

  .v-application .pl-md-14 {
    padding-left: 56px !important;
  }

  .v-application .pl-md-15 {
    padding-left: 60px !important;
  }

  .v-application .pl-md-16 {
    padding-left: 64px !important;
  }

  .v-application--is-ltr .ps-md-0 {
    padding-left: 0 !important;
  }

  .v-application--is-rtl .ps-md-0 {
    padding-right: 0 !important;
  }

  .v-application--is-ltr .ps-md-1 {
    padding-left: 4px !important;
  }

  .v-application--is-rtl .ps-md-1 {
    padding-right: 4px !important;
  }

  .v-application--is-ltr .ps-md-2 {
    padding-left: 8px !important;
  }

  .v-application--is-rtl .ps-md-2 {
    padding-right: 8px !important;
  }

  .v-application--is-ltr .ps-md-3 {
    padding-left: 12px !important;
  }

  .v-application--is-rtl .ps-md-3 {
    padding-right: 12px !important;
  }

  .v-application--is-ltr .ps-md-4 {
    padding-left: 16px !important;
  }

  .v-application--is-rtl .ps-md-4 {
    padding-right: 16px !important;
  }

  .v-application--is-ltr .ps-md-5 {
    padding-left: 20px !important;
  }

  .v-application--is-rtl .ps-md-5 {
    padding-right: 20px !important;
  }

  .v-application--is-ltr .ps-md-6 {
    padding-left: 24px !important;
  }

  .v-application--is-rtl .ps-md-6 {
    padding-right: 24px !important;
  }

  .v-application--is-ltr .ps-md-7 {
    padding-left: 28px !important;
  }

  .v-application--is-rtl .ps-md-7 {
    padding-right: 28px !important;
  }

  .v-application--is-ltr .ps-md-8 {
    padding-left: 32px !important;
  }

  .v-application--is-rtl .ps-md-8 {
    padding-right: 32px !important;
  }

  .v-application--is-ltr .ps-md-9 {
    padding-left: 36px !important;
  }

  .v-application--is-rtl .ps-md-9 {
    padding-right: 36px !important;
  }

  .v-application--is-ltr .ps-md-10 {
    padding-left: 40px !important;
  }

  .v-application--is-rtl .ps-md-10 {
    padding-right: 40px !important;
  }

  .v-application--is-ltr .ps-md-11 {
    padding-left: 44px !important;
  }

  .v-application--is-rtl .ps-md-11 {
    padding-right: 44px !important;
  }

  .v-application--is-ltr .ps-md-12 {
    padding-left: 48px !important;
  }

  .v-application--is-rtl .ps-md-12 {
    padding-right: 48px !important;
  }

  .v-application--is-ltr .ps-md-13 {
    padding-left: 52px !important;
  }

  .v-application--is-rtl .ps-md-13 {
    padding-right: 52px !important;
  }

  .v-application--is-ltr .ps-md-14 {
    padding-left: 56px !important;
  }

  .v-application--is-rtl .ps-md-14 {
    padding-right: 56px !important;
  }

  .v-application--is-ltr .ps-md-15 {
    padding-left: 60px !important;
  }

  .v-application--is-rtl .ps-md-15 {
    padding-right: 60px !important;
  }

  .v-application--is-ltr .ps-md-16 {
    padding-left: 64px !important;
  }

  .v-application--is-rtl .ps-md-16 {
    padding-right: 64px !important;
  }

  .v-application--is-ltr .pe-md-0 {
    padding-right: 0 !important;
  }

  .v-application--is-rtl .pe-md-0 {
    padding-left: 0 !important;
  }

  .v-application--is-ltr .pe-md-1 {
    padding-right: 4px !important;
  }

  .v-application--is-rtl .pe-md-1 {
    padding-left: 4px !important;
  }

  .v-application--is-ltr .pe-md-2 {
    padding-right: 8px !important;
  }

  .v-application--is-rtl .pe-md-2 {
    padding-left: 8px !important;
  }

  .v-application--is-ltr .pe-md-3 {
    padding-right: 12px !important;
  }

  .v-application--is-rtl .pe-md-3 {
    padding-left: 12px !important;
  }

  .v-application--is-ltr .pe-md-4 {
    padding-right: 16px !important;
  }

  .v-application--is-rtl .pe-md-4 {
    padding-left: 16px !important;
  }

  .v-application--is-ltr .pe-md-5 {
    padding-right: 20px !important;
  }

  .v-application--is-rtl .pe-md-5 {
    padding-left: 20px !important;
  }

  .v-application--is-ltr .pe-md-6 {
    padding-right: 24px !important;
  }

  .v-application--is-rtl .pe-md-6 {
    padding-left: 24px !important;
  }

  .v-application--is-ltr .pe-md-7 {
    padding-right: 28px !important;
  }

  .v-application--is-rtl .pe-md-7 {
    padding-left: 28px !important;
  }

  .v-application--is-ltr .pe-md-8 {
    padding-right: 32px !important;
  }

  .v-application--is-rtl .pe-md-8 {
    padding-left: 32px !important;
  }

  .v-application--is-ltr .pe-md-9 {
    padding-right: 36px !important;
  }

  .v-application--is-rtl .pe-md-9 {
    padding-left: 36px !important;
  }

  .v-application--is-ltr .pe-md-10 {
    padding-right: 40px !important;
  }

  .v-application--is-rtl .pe-md-10 {
    padding-left: 40px !important;
  }

  .v-application--is-ltr .pe-md-11 {
    padding-right: 44px !important;
  }

  .v-application--is-rtl .pe-md-11 {
    padding-left: 44px !important;
  }

  .v-application--is-ltr .pe-md-12 {
    padding-right: 48px !important;
  }

  .v-application--is-rtl .pe-md-12 {
    padding-left: 48px !important;
  }

  .v-application--is-ltr .pe-md-13 {
    padding-right: 52px !important;
  }

  .v-application--is-rtl .pe-md-13 {
    padding-left: 52px !important;
  }

  .v-application--is-ltr .pe-md-14 {
    padding-right: 56px !important;
  }

  .v-application--is-rtl .pe-md-14 {
    padding-left: 56px !important;
  }

  .v-application--is-ltr .pe-md-15 {
    padding-right: 60px !important;
  }

  .v-application--is-rtl .pe-md-15 {
    padding-left: 60px !important;
  }

  .v-application--is-ltr .pe-md-16 {
    padding-right: 64px !important;
  }

  .v-application--is-rtl .pe-md-16 {
    padding-left: 64px !important;
  }

  .v-application .text-md-left {
    text-align: left !important;
  }

  .v-application .text-md-right {
    text-align: right !important;
  }

  .v-application .text-md-center {
    text-align: center !important;
  }

  .v-application .text-md-justify {
    text-align: justify !important;
  }

  .v-application .text-md-start {
    text-align: start !important;
  }

  .v-application .text-md-end {
    text-align: end !important;
  }

  .v-application .text-md-h1 {
    line-height: 6rem;
    letter-spacing: -.015625em !important;
    font-size: 6rem !important;
  }

  .v-application .text-md-h1, .v-application .text-md-h2 {
    font-weight: 300;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-md-h2 {
    line-height: 3.75rem;
    letter-spacing: -.00833333em !important;
    font-size: 3.75rem !important;
  }

  .v-application .text-md-h3 {
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-size: 3rem !important;
  }

  .v-application .text-md-h3, .v-application .text-md-h4 {
    font-weight: 400;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-md-h4 {
    line-height: 2.5rem;
    letter-spacing: .00735294em !important;
    font-size: 2.125rem !important;
  }

  .v-application .text-md-h5 {
    font-weight: 400;
    letter-spacing: normal !important;
    font-size: 1.5rem !important;
  }

  .v-application .text-md-h5, .v-application .text-md-h6 {
    line-height: 2rem;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-md-h6 {
    font-weight: 500;
    letter-spacing: .0125em !important;
    font-size: 1.25rem !important;
  }

  .v-application .text-md-subtitle-1 {
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: .009375em !important;
    font-family: Roboto, sans-serif !important;
    font-size: 1rem !important;
  }

  .v-application .text-md-subtitle-2 {
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: .00714286em !important;
    font-family: Roboto, sans-serif !important;
    font-size: .875rem !important;
  }

  .v-application .text-md-body-1 {
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: .03125em !important;
    font-family: Roboto, sans-serif !important;
    font-size: 1rem !important;
  }

  .v-application .text-md-body-2 {
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .0178571em !important;
  }

  .v-application .text-md-body-2, .v-application .text-md-button {
    font-family: Roboto, sans-serif !important;
    font-size: .875rem !important;
  }

  .v-application .text-md-button {
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
  }

  .v-application .text-md-caption {
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .0333333em !important;
  }

  .v-application .text-md-caption, .v-application .text-md-overline {
    font-family: Roboto, sans-serif !important;
    font-size: .75rem !important;
  }

  .v-application .text-md-overline {
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
  }
}

@media (min-width: 1264px) {
  .v-application .d-lg-none {
    display: none !important;
  }

  .v-application .d-lg-inline {
    display: inline !important;
  }

  .v-application .d-lg-inline-block {
    display: inline-block !important;
  }

  .v-application .d-lg-block {
    display: block !important;
  }

  .v-application .d-lg-table {
    display: table !important;
  }

  .v-application .d-lg-table-row {
    display: table-row !important;
  }

  .v-application .d-lg-table-cell {
    display: table-cell !important;
  }

  .v-application .d-lg-flex {
    display: flex !important;
  }

  .v-application .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .v-application .float-lg-none {
    float: none !important;
  }

  .v-application .float-lg-left {
    float: left !important;
  }

  .v-application .float-lg-right {
    float: right !important;
  }

  .v-application--is-rtl .float-lg-end {
    float: left !important;
  }

  .v-application--is-ltr .float-lg-end, .v-application--is-rtl .float-lg-start {
    float: right !important;
  }

  .v-application--is-ltr .float-lg-start {
    float: left !important;
  }

  .v-application .flex-lg-fill {
    flex: auto !important;
  }

  .v-application .flex-lg-row {
    flex-direction: row !important;
  }

  .v-application .flex-lg-column {
    flex-direction: column !important;
  }

  .v-application .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .v-application .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .v-application .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .v-application .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .v-application .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .v-application .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .v-application .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .v-application .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .v-application .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .v-application .justify-lg-start {
    justify-content: flex-start !important;
  }

  .v-application .justify-lg-end {
    justify-content: flex-end !important;
  }

  .v-application .justify-lg-center {
    justify-content: center !important;
  }

  .v-application .justify-lg-space-between {
    justify-content: space-between !important;
  }

  .v-application .justify-lg-space-around {
    justify-content: space-around !important;
  }

  .v-application .align-lg-start {
    align-items: flex-start !important;
  }

  .v-application .align-lg-end {
    align-items: flex-end !important;
  }

  .v-application .align-lg-center {
    align-items: center !important;
  }

  .v-application .align-lg-baseline {
    align-items: baseline !important;
  }

  .v-application .align-lg-stretch {
    align-items: stretch !important;
  }

  .v-application .align-content-lg-start {
    align-content: flex-start !important;
  }

  .v-application .align-content-lg-end {
    align-content: flex-end !important;
  }

  .v-application .align-content-lg-center {
    align-content: center !important;
  }

  .v-application .align-content-lg-space-between {
    align-content: space-between !important;
  }

  .v-application .align-content-lg-space-around {
    align-content: space-around !important;
  }

  .v-application .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .v-application .align-self-lg-auto {
    align-self: auto !important;
  }

  .v-application .align-self-lg-start {
    align-self: flex-start !important;
  }

  .v-application .align-self-lg-end {
    align-self: flex-end !important;
  }

  .v-application .align-self-lg-center {
    align-self: center !important;
  }

  .v-application .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .v-application .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .v-application .order-lg-first {
    order: -1 !important;
  }

  .v-application .order-lg-0 {
    order: 0 !important;
  }

  .v-application .order-lg-1 {
    order: 1 !important;
  }

  .v-application .order-lg-2 {
    order: 2 !important;
  }

  .v-application .order-lg-3 {
    order: 3 !important;
  }

  .v-application .order-lg-4 {
    order: 4 !important;
  }

  .v-application .order-lg-5 {
    order: 5 !important;
  }

  .v-application .order-lg-6 {
    order: 6 !important;
  }

  .v-application .order-lg-7 {
    order: 7 !important;
  }

  .v-application .order-lg-8 {
    order: 8 !important;
  }

  .v-application .order-lg-9 {
    order: 9 !important;
  }

  .v-application .order-lg-10 {
    order: 10 !important;
  }

  .v-application .order-lg-11 {
    order: 11 !important;
  }

  .v-application .order-lg-12 {
    order: 12 !important;
  }

  .v-application .order-lg-last {
    order: 13 !important;
  }

  .v-application .ma-lg-0 {
    margin: 0 !important;
  }

  .v-application .ma-lg-1 {
    margin: 4px !important;
  }

  .v-application .ma-lg-2 {
    margin: 8px !important;
  }

  .v-application .ma-lg-3 {
    margin: 12px !important;
  }

  .v-application .ma-lg-4 {
    margin: 16px !important;
  }

  .v-application .ma-lg-5 {
    margin: 20px !important;
  }

  .v-application .ma-lg-6 {
    margin: 24px !important;
  }

  .v-application .ma-lg-7 {
    margin: 28px !important;
  }

  .v-application .ma-lg-8 {
    margin: 32px !important;
  }

  .v-application .ma-lg-9 {
    margin: 36px !important;
  }

  .v-application .ma-lg-10 {
    margin: 40px !important;
  }

  .v-application .ma-lg-11 {
    margin: 44px !important;
  }

  .v-application .ma-lg-12 {
    margin: 48px !important;
  }

  .v-application .ma-lg-13 {
    margin: 52px !important;
  }

  .v-application .ma-lg-14 {
    margin: 56px !important;
  }

  .v-application .ma-lg-15 {
    margin: 60px !important;
  }

  .v-application .ma-lg-16 {
    margin: 64px !important;
  }

  .v-application .ma-lg-auto {
    margin: auto !important;
  }

  .v-application .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .v-application .mx-lg-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .v-application .mx-lg-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .v-application .mx-lg-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .v-application .mx-lg-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .v-application .mx-lg-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .v-application .mx-lg-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .v-application .mx-lg-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .v-application .mx-lg-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .v-application .mx-lg-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .v-application .mx-lg-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .v-application .mx-lg-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .v-application .mx-lg-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .v-application .mx-lg-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .v-application .mx-lg-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .v-application .mx-lg-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .v-application .mx-lg-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .v-application .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .v-application .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .v-application .my-lg-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .v-application .my-lg-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .v-application .my-lg-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .v-application .my-lg-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .v-application .my-lg-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .v-application .my-lg-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .v-application .my-lg-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .v-application .my-lg-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .v-application .my-lg-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .v-application .my-lg-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .v-application .my-lg-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .v-application .my-lg-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .v-application .my-lg-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .v-application .my-lg-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .v-application .my-lg-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .v-application .my-lg-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .v-application .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .v-application .mt-lg-0 {
    margin-top: 0 !important;
  }

  .v-application .mt-lg-1 {
    margin-top: 4px !important;
  }

  .v-application .mt-lg-2 {
    margin-top: 8px !important;
  }

  .v-application .mt-lg-3 {
    margin-top: 12px !important;
  }

  .v-application .mt-lg-4 {
    margin-top: 16px !important;
  }

  .v-application .mt-lg-5 {
    margin-top: 20px !important;
  }

  .v-application .mt-lg-6 {
    margin-top: 24px !important;
  }

  .v-application .mt-lg-7 {
    margin-top: 28px !important;
  }

  .v-application .mt-lg-8 {
    margin-top: 32px !important;
  }

  .v-application .mt-lg-9 {
    margin-top: 36px !important;
  }

  .v-application .mt-lg-10 {
    margin-top: 40px !important;
  }

  .v-application .mt-lg-11 {
    margin-top: 44px !important;
  }

  .v-application .mt-lg-12 {
    margin-top: 48px !important;
  }

  .v-application .mt-lg-13 {
    margin-top: 52px !important;
  }

  .v-application .mt-lg-14 {
    margin-top: 56px !important;
  }

  .v-application .mt-lg-15 {
    margin-top: 60px !important;
  }

  .v-application .mt-lg-16 {
    margin-top: 64px !important;
  }

  .v-application .mt-lg-auto {
    margin-top: auto !important;
  }

  .v-application .mr-lg-0 {
    margin-right: 0 !important;
  }

  .v-application .mr-lg-1 {
    margin-right: 4px !important;
  }

  .v-application .mr-lg-2 {
    margin-right: 8px !important;
  }

  .v-application .mr-lg-3 {
    margin-right: 12px !important;
  }

  .v-application .mr-lg-4 {
    margin-right: 16px !important;
  }

  .v-application .mr-lg-5 {
    margin-right: 20px !important;
  }

  .v-application .mr-lg-6 {
    margin-right: 24px !important;
  }

  .v-application .mr-lg-7 {
    margin-right: 28px !important;
  }

  .v-application .mr-lg-8 {
    margin-right: 32px !important;
  }

  .v-application .mr-lg-9 {
    margin-right: 36px !important;
  }

  .v-application .mr-lg-10 {
    margin-right: 40px !important;
  }

  .v-application .mr-lg-11 {
    margin-right: 44px !important;
  }

  .v-application .mr-lg-12 {
    margin-right: 48px !important;
  }

  .v-application .mr-lg-13 {
    margin-right: 52px !important;
  }

  .v-application .mr-lg-14 {
    margin-right: 56px !important;
  }

  .v-application .mr-lg-15 {
    margin-right: 60px !important;
  }

  .v-application .mr-lg-16 {
    margin-right: 64px !important;
  }

  .v-application .mr-lg-auto {
    margin-right: auto !important;
  }

  .v-application .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .v-application .mb-lg-1 {
    margin-bottom: 4px !important;
  }

  .v-application .mb-lg-2 {
    margin-bottom: 8px !important;
  }

  .v-application .mb-lg-3 {
    margin-bottom: 12px !important;
  }

  .v-application .mb-lg-4 {
    margin-bottom: 16px !important;
  }

  .v-application .mb-lg-5 {
    margin-bottom: 20px !important;
  }

  .v-application .mb-lg-6 {
    margin-bottom: 24px !important;
  }

  .v-application .mb-lg-7 {
    margin-bottom: 28px !important;
  }

  .v-application .mb-lg-8 {
    margin-bottom: 32px !important;
  }

  .v-application .mb-lg-9 {
    margin-bottom: 36px !important;
  }

  .v-application .mb-lg-10 {
    margin-bottom: 40px !important;
  }

  .v-application .mb-lg-11 {
    margin-bottom: 44px !important;
  }

  .v-application .mb-lg-12 {
    margin-bottom: 48px !important;
  }

  .v-application .mb-lg-13 {
    margin-bottom: 52px !important;
  }

  .v-application .mb-lg-14 {
    margin-bottom: 56px !important;
  }

  .v-application .mb-lg-15 {
    margin-bottom: 60px !important;
  }

  .v-application .mb-lg-16 {
    margin-bottom: 64px !important;
  }

  .v-application .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .v-application .ml-lg-0 {
    margin-left: 0 !important;
  }

  .v-application .ml-lg-1 {
    margin-left: 4px !important;
  }

  .v-application .ml-lg-2 {
    margin-left: 8px !important;
  }

  .v-application .ml-lg-3 {
    margin-left: 12px !important;
  }

  .v-application .ml-lg-4 {
    margin-left: 16px !important;
  }

  .v-application .ml-lg-5 {
    margin-left: 20px !important;
  }

  .v-application .ml-lg-6 {
    margin-left: 24px !important;
  }

  .v-application .ml-lg-7 {
    margin-left: 28px !important;
  }

  .v-application .ml-lg-8 {
    margin-left: 32px !important;
  }

  .v-application .ml-lg-9 {
    margin-left: 36px !important;
  }

  .v-application .ml-lg-10 {
    margin-left: 40px !important;
  }

  .v-application .ml-lg-11 {
    margin-left: 44px !important;
  }

  .v-application .ml-lg-12 {
    margin-left: 48px !important;
  }

  .v-application .ml-lg-13 {
    margin-left: 52px !important;
  }

  .v-application .ml-lg-14 {
    margin-left: 56px !important;
  }

  .v-application .ml-lg-15 {
    margin-left: 60px !important;
  }

  .v-application .ml-lg-16 {
    margin-left: 64px !important;
  }

  .v-application .ml-lg-auto {
    margin-left: auto !important;
  }

  .v-application--is-ltr .ms-lg-0 {
    margin-left: 0 !important;
  }

  .v-application--is-rtl .ms-lg-0 {
    margin-right: 0 !important;
  }

  .v-application--is-ltr .ms-lg-1 {
    margin-left: 4px !important;
  }

  .v-application--is-rtl .ms-lg-1 {
    margin-right: 4px !important;
  }

  .v-application--is-ltr .ms-lg-2 {
    margin-left: 8px !important;
  }

  .v-application--is-rtl .ms-lg-2 {
    margin-right: 8px !important;
  }

  .v-application--is-ltr .ms-lg-3 {
    margin-left: 12px !important;
  }

  .v-application--is-rtl .ms-lg-3 {
    margin-right: 12px !important;
  }

  .v-application--is-ltr .ms-lg-4 {
    margin-left: 16px !important;
  }

  .v-application--is-rtl .ms-lg-4 {
    margin-right: 16px !important;
  }

  .v-application--is-ltr .ms-lg-5 {
    margin-left: 20px !important;
  }

  .v-application--is-rtl .ms-lg-5 {
    margin-right: 20px !important;
  }

  .v-application--is-ltr .ms-lg-6 {
    margin-left: 24px !important;
  }

  .v-application--is-rtl .ms-lg-6 {
    margin-right: 24px !important;
  }

  .v-application--is-ltr .ms-lg-7 {
    margin-left: 28px !important;
  }

  .v-application--is-rtl .ms-lg-7 {
    margin-right: 28px !important;
  }

  .v-application--is-ltr .ms-lg-8 {
    margin-left: 32px !important;
  }

  .v-application--is-rtl .ms-lg-8 {
    margin-right: 32px !important;
  }

  .v-application--is-ltr .ms-lg-9 {
    margin-left: 36px !important;
  }

  .v-application--is-rtl .ms-lg-9 {
    margin-right: 36px !important;
  }

  .v-application--is-ltr .ms-lg-10 {
    margin-left: 40px !important;
  }

  .v-application--is-rtl .ms-lg-10 {
    margin-right: 40px !important;
  }

  .v-application--is-ltr .ms-lg-11 {
    margin-left: 44px !important;
  }

  .v-application--is-rtl .ms-lg-11 {
    margin-right: 44px !important;
  }

  .v-application--is-ltr .ms-lg-12 {
    margin-left: 48px !important;
  }

  .v-application--is-rtl .ms-lg-12 {
    margin-right: 48px !important;
  }

  .v-application--is-ltr .ms-lg-13 {
    margin-left: 52px !important;
  }

  .v-application--is-rtl .ms-lg-13 {
    margin-right: 52px !important;
  }

  .v-application--is-ltr .ms-lg-14 {
    margin-left: 56px !important;
  }

  .v-application--is-rtl .ms-lg-14 {
    margin-right: 56px !important;
  }

  .v-application--is-ltr .ms-lg-15 {
    margin-left: 60px !important;
  }

  .v-application--is-rtl .ms-lg-15 {
    margin-right: 60px !important;
  }

  .v-application--is-ltr .ms-lg-16 {
    margin-left: 64px !important;
  }

  .v-application--is-rtl .ms-lg-16 {
    margin-right: 64px !important;
  }

  .v-application--is-ltr .ms-lg-auto {
    margin-left: auto !important;
  }

  .v-application--is-rtl .ms-lg-auto {
    margin-right: auto !important;
  }

  .v-application--is-ltr .me-lg-0 {
    margin-right: 0 !important;
  }

  .v-application--is-rtl .me-lg-0 {
    margin-left: 0 !important;
  }

  .v-application--is-ltr .me-lg-1 {
    margin-right: 4px !important;
  }

  .v-application--is-rtl .me-lg-1 {
    margin-left: 4px !important;
  }

  .v-application--is-ltr .me-lg-2 {
    margin-right: 8px !important;
  }

  .v-application--is-rtl .me-lg-2 {
    margin-left: 8px !important;
  }

  .v-application--is-ltr .me-lg-3 {
    margin-right: 12px !important;
  }

  .v-application--is-rtl .me-lg-3 {
    margin-left: 12px !important;
  }

  .v-application--is-ltr .me-lg-4 {
    margin-right: 16px !important;
  }

  .v-application--is-rtl .me-lg-4 {
    margin-left: 16px !important;
  }

  .v-application--is-ltr .me-lg-5 {
    margin-right: 20px !important;
  }

  .v-application--is-rtl .me-lg-5 {
    margin-left: 20px !important;
  }

  .v-application--is-ltr .me-lg-6 {
    margin-right: 24px !important;
  }

  .v-application--is-rtl .me-lg-6 {
    margin-left: 24px !important;
  }

  .v-application--is-ltr .me-lg-7 {
    margin-right: 28px !important;
  }

  .v-application--is-rtl .me-lg-7 {
    margin-left: 28px !important;
  }

  .v-application--is-ltr .me-lg-8 {
    margin-right: 32px !important;
  }

  .v-application--is-rtl .me-lg-8 {
    margin-left: 32px !important;
  }

  .v-application--is-ltr .me-lg-9 {
    margin-right: 36px !important;
  }

  .v-application--is-rtl .me-lg-9 {
    margin-left: 36px !important;
  }

  .v-application--is-ltr .me-lg-10 {
    margin-right: 40px !important;
  }

  .v-application--is-rtl .me-lg-10 {
    margin-left: 40px !important;
  }

  .v-application--is-ltr .me-lg-11 {
    margin-right: 44px !important;
  }

  .v-application--is-rtl .me-lg-11 {
    margin-left: 44px !important;
  }

  .v-application--is-ltr .me-lg-12 {
    margin-right: 48px !important;
  }

  .v-application--is-rtl .me-lg-12 {
    margin-left: 48px !important;
  }

  .v-application--is-ltr .me-lg-13 {
    margin-right: 52px !important;
  }

  .v-application--is-rtl .me-lg-13 {
    margin-left: 52px !important;
  }

  .v-application--is-ltr .me-lg-14 {
    margin-right: 56px !important;
  }

  .v-application--is-rtl .me-lg-14 {
    margin-left: 56px !important;
  }

  .v-application--is-ltr .me-lg-15 {
    margin-right: 60px !important;
  }

  .v-application--is-rtl .me-lg-15 {
    margin-left: 60px !important;
  }

  .v-application--is-ltr .me-lg-16 {
    margin-right: 64px !important;
  }

  .v-application--is-rtl .me-lg-16 {
    margin-left: 64px !important;
  }

  .v-application--is-ltr .me-lg-auto {
    margin-right: auto !important;
  }

  .v-application--is-rtl .me-lg-auto {
    margin-left: auto !important;
  }

  .v-application .ma-lg-n1 {
    margin: -4px !important;
  }

  .v-application .ma-lg-n2 {
    margin: -8px !important;
  }

  .v-application .ma-lg-n3 {
    margin: -12px !important;
  }

  .v-application .ma-lg-n4 {
    margin: -16px !important;
  }

  .v-application .ma-lg-n5 {
    margin: -20px !important;
  }

  .v-application .ma-lg-n6 {
    margin: -24px !important;
  }

  .v-application .ma-lg-n7 {
    margin: -28px !important;
  }

  .v-application .ma-lg-n8 {
    margin: -32px !important;
  }

  .v-application .ma-lg-n9 {
    margin: -36px !important;
  }

  .v-application .ma-lg-n10 {
    margin: -40px !important;
  }

  .v-application .ma-lg-n11 {
    margin: -44px !important;
  }

  .v-application .ma-lg-n12 {
    margin: -48px !important;
  }

  .v-application .ma-lg-n13 {
    margin: -52px !important;
  }

  .v-application .ma-lg-n14 {
    margin: -56px !important;
  }

  .v-application .ma-lg-n15 {
    margin: -60px !important;
  }

  .v-application .ma-lg-n16 {
    margin: -64px !important;
  }

  .v-application .mx-lg-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .v-application .mx-lg-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .v-application .mx-lg-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .v-application .mx-lg-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .v-application .mx-lg-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .v-application .mx-lg-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .v-application .mx-lg-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .v-application .mx-lg-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .v-application .mx-lg-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .v-application .mx-lg-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .v-application .mx-lg-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .v-application .mx-lg-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .v-application .mx-lg-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .v-application .mx-lg-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .v-application .mx-lg-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .v-application .mx-lg-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .v-application .my-lg-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .v-application .my-lg-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .v-application .my-lg-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .v-application .my-lg-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .v-application .my-lg-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .v-application .my-lg-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .v-application .my-lg-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .v-application .my-lg-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .v-application .my-lg-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .v-application .my-lg-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .v-application .my-lg-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .v-application .my-lg-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .v-application .my-lg-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .v-application .my-lg-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .v-application .my-lg-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .v-application .my-lg-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .v-application .mt-lg-n1 {
    margin-top: -4px !important;
  }

  .v-application .mt-lg-n2 {
    margin-top: -8px !important;
  }

  .v-application .mt-lg-n3 {
    margin-top: -12px !important;
  }

  .v-application .mt-lg-n4 {
    margin-top: -16px !important;
  }

  .v-application .mt-lg-n5 {
    margin-top: -20px !important;
  }

  .v-application .mt-lg-n6 {
    margin-top: -24px !important;
  }

  .v-application .mt-lg-n7 {
    margin-top: -28px !important;
  }

  .v-application .mt-lg-n8 {
    margin-top: -32px !important;
  }

  .v-application .mt-lg-n9 {
    margin-top: -36px !important;
  }

  .v-application .mt-lg-n10 {
    margin-top: -40px !important;
  }

  .v-application .mt-lg-n11 {
    margin-top: -44px !important;
  }

  .v-application .mt-lg-n12 {
    margin-top: -48px !important;
  }

  .v-application .mt-lg-n13 {
    margin-top: -52px !important;
  }

  .v-application .mt-lg-n14 {
    margin-top: -56px !important;
  }

  .v-application .mt-lg-n15 {
    margin-top: -60px !important;
  }

  .v-application .mt-lg-n16 {
    margin-top: -64px !important;
  }

  .v-application .mr-lg-n1 {
    margin-right: -4px !important;
  }

  .v-application .mr-lg-n2 {
    margin-right: -8px !important;
  }

  .v-application .mr-lg-n3 {
    margin-right: -12px !important;
  }

  .v-application .mr-lg-n4 {
    margin-right: -16px !important;
  }

  .v-application .mr-lg-n5 {
    margin-right: -20px !important;
  }

  .v-application .mr-lg-n6 {
    margin-right: -24px !important;
  }

  .v-application .mr-lg-n7 {
    margin-right: -28px !important;
  }

  .v-application .mr-lg-n8 {
    margin-right: -32px !important;
  }

  .v-application .mr-lg-n9 {
    margin-right: -36px !important;
  }

  .v-application .mr-lg-n10 {
    margin-right: -40px !important;
  }

  .v-application .mr-lg-n11 {
    margin-right: -44px !important;
  }

  .v-application .mr-lg-n12 {
    margin-right: -48px !important;
  }

  .v-application .mr-lg-n13 {
    margin-right: -52px !important;
  }

  .v-application .mr-lg-n14 {
    margin-right: -56px !important;
  }

  .v-application .mr-lg-n15 {
    margin-right: -60px !important;
  }

  .v-application .mr-lg-n16 {
    margin-right: -64px !important;
  }

  .v-application .mb-lg-n1 {
    margin-bottom: -4px !important;
  }

  .v-application .mb-lg-n2 {
    margin-bottom: -8px !important;
  }

  .v-application .mb-lg-n3 {
    margin-bottom: -12px !important;
  }

  .v-application .mb-lg-n4 {
    margin-bottom: -16px !important;
  }

  .v-application .mb-lg-n5 {
    margin-bottom: -20px !important;
  }

  .v-application .mb-lg-n6 {
    margin-bottom: -24px !important;
  }

  .v-application .mb-lg-n7 {
    margin-bottom: -28px !important;
  }

  .v-application .mb-lg-n8 {
    margin-bottom: -32px !important;
  }

  .v-application .mb-lg-n9 {
    margin-bottom: -36px !important;
  }

  .v-application .mb-lg-n10 {
    margin-bottom: -40px !important;
  }

  .v-application .mb-lg-n11 {
    margin-bottom: -44px !important;
  }

  .v-application .mb-lg-n12 {
    margin-bottom: -48px !important;
  }

  .v-application .mb-lg-n13 {
    margin-bottom: -52px !important;
  }

  .v-application .mb-lg-n14 {
    margin-bottom: -56px !important;
  }

  .v-application .mb-lg-n15 {
    margin-bottom: -60px !important;
  }

  .v-application .mb-lg-n16 {
    margin-bottom: -64px !important;
  }

  .v-application .ml-lg-n1 {
    margin-left: -4px !important;
  }

  .v-application .ml-lg-n2 {
    margin-left: -8px !important;
  }

  .v-application .ml-lg-n3 {
    margin-left: -12px !important;
  }

  .v-application .ml-lg-n4 {
    margin-left: -16px !important;
  }

  .v-application .ml-lg-n5 {
    margin-left: -20px !important;
  }

  .v-application .ml-lg-n6 {
    margin-left: -24px !important;
  }

  .v-application .ml-lg-n7 {
    margin-left: -28px !important;
  }

  .v-application .ml-lg-n8 {
    margin-left: -32px !important;
  }

  .v-application .ml-lg-n9 {
    margin-left: -36px !important;
  }

  .v-application .ml-lg-n10 {
    margin-left: -40px !important;
  }

  .v-application .ml-lg-n11 {
    margin-left: -44px !important;
  }

  .v-application .ml-lg-n12 {
    margin-left: -48px !important;
  }

  .v-application .ml-lg-n13 {
    margin-left: -52px !important;
  }

  .v-application .ml-lg-n14 {
    margin-left: -56px !important;
  }

  .v-application .ml-lg-n15 {
    margin-left: -60px !important;
  }

  .v-application .ml-lg-n16 {
    margin-left: -64px !important;
  }

  .v-application--is-ltr .ms-lg-n1 {
    margin-left: -4px !important;
  }

  .v-application--is-rtl .ms-lg-n1 {
    margin-right: -4px !important;
  }

  .v-application--is-ltr .ms-lg-n2 {
    margin-left: -8px !important;
  }

  .v-application--is-rtl .ms-lg-n2 {
    margin-right: -8px !important;
  }

  .v-application--is-ltr .ms-lg-n3 {
    margin-left: -12px !important;
  }

  .v-application--is-rtl .ms-lg-n3 {
    margin-right: -12px !important;
  }

  .v-application--is-ltr .ms-lg-n4 {
    margin-left: -16px !important;
  }

  .v-application--is-rtl .ms-lg-n4 {
    margin-right: -16px !important;
  }

  .v-application--is-ltr .ms-lg-n5 {
    margin-left: -20px !important;
  }

  .v-application--is-rtl .ms-lg-n5 {
    margin-right: -20px !important;
  }

  .v-application--is-ltr .ms-lg-n6 {
    margin-left: -24px !important;
  }

  .v-application--is-rtl .ms-lg-n6 {
    margin-right: -24px !important;
  }

  .v-application--is-ltr .ms-lg-n7 {
    margin-left: -28px !important;
  }

  .v-application--is-rtl .ms-lg-n7 {
    margin-right: -28px !important;
  }

  .v-application--is-ltr .ms-lg-n8 {
    margin-left: -32px !important;
  }

  .v-application--is-rtl .ms-lg-n8 {
    margin-right: -32px !important;
  }

  .v-application--is-ltr .ms-lg-n9 {
    margin-left: -36px !important;
  }

  .v-application--is-rtl .ms-lg-n9 {
    margin-right: -36px !important;
  }

  .v-application--is-ltr .ms-lg-n10 {
    margin-left: -40px !important;
  }

  .v-application--is-rtl .ms-lg-n10 {
    margin-right: -40px !important;
  }

  .v-application--is-ltr .ms-lg-n11 {
    margin-left: -44px !important;
  }

  .v-application--is-rtl .ms-lg-n11 {
    margin-right: -44px !important;
  }

  .v-application--is-ltr .ms-lg-n12 {
    margin-left: -48px !important;
  }

  .v-application--is-rtl .ms-lg-n12 {
    margin-right: -48px !important;
  }

  .v-application--is-ltr .ms-lg-n13 {
    margin-left: -52px !important;
  }

  .v-application--is-rtl .ms-lg-n13 {
    margin-right: -52px !important;
  }

  .v-application--is-ltr .ms-lg-n14 {
    margin-left: -56px !important;
  }

  .v-application--is-rtl .ms-lg-n14 {
    margin-right: -56px !important;
  }

  .v-application--is-ltr .ms-lg-n15 {
    margin-left: -60px !important;
  }

  .v-application--is-rtl .ms-lg-n15 {
    margin-right: -60px !important;
  }

  .v-application--is-ltr .ms-lg-n16 {
    margin-left: -64px !important;
  }

  .v-application--is-rtl .ms-lg-n16 {
    margin-right: -64px !important;
  }

  .v-application--is-ltr .me-lg-n1 {
    margin-right: -4px !important;
  }

  .v-application--is-rtl .me-lg-n1 {
    margin-left: -4px !important;
  }

  .v-application--is-ltr .me-lg-n2 {
    margin-right: -8px !important;
  }

  .v-application--is-rtl .me-lg-n2 {
    margin-left: -8px !important;
  }

  .v-application--is-ltr .me-lg-n3 {
    margin-right: -12px !important;
  }

  .v-application--is-rtl .me-lg-n3 {
    margin-left: -12px !important;
  }

  .v-application--is-ltr .me-lg-n4 {
    margin-right: -16px !important;
  }

  .v-application--is-rtl .me-lg-n4 {
    margin-left: -16px !important;
  }

  .v-application--is-ltr .me-lg-n5 {
    margin-right: -20px !important;
  }

  .v-application--is-rtl .me-lg-n5 {
    margin-left: -20px !important;
  }

  .v-application--is-ltr .me-lg-n6 {
    margin-right: -24px !important;
  }

  .v-application--is-rtl .me-lg-n6 {
    margin-left: -24px !important;
  }

  .v-application--is-ltr .me-lg-n7 {
    margin-right: -28px !important;
  }

  .v-application--is-rtl .me-lg-n7 {
    margin-left: -28px !important;
  }

  .v-application--is-ltr .me-lg-n8 {
    margin-right: -32px !important;
  }

  .v-application--is-rtl .me-lg-n8 {
    margin-left: -32px !important;
  }

  .v-application--is-ltr .me-lg-n9 {
    margin-right: -36px !important;
  }

  .v-application--is-rtl .me-lg-n9 {
    margin-left: -36px !important;
  }

  .v-application--is-ltr .me-lg-n10 {
    margin-right: -40px !important;
  }

  .v-application--is-rtl .me-lg-n10 {
    margin-left: -40px !important;
  }

  .v-application--is-ltr .me-lg-n11 {
    margin-right: -44px !important;
  }

  .v-application--is-rtl .me-lg-n11 {
    margin-left: -44px !important;
  }

  .v-application--is-ltr .me-lg-n12 {
    margin-right: -48px !important;
  }

  .v-application--is-rtl .me-lg-n12 {
    margin-left: -48px !important;
  }

  .v-application--is-ltr .me-lg-n13 {
    margin-right: -52px !important;
  }

  .v-application--is-rtl .me-lg-n13 {
    margin-left: -52px !important;
  }

  .v-application--is-ltr .me-lg-n14 {
    margin-right: -56px !important;
  }

  .v-application--is-rtl .me-lg-n14 {
    margin-left: -56px !important;
  }

  .v-application--is-ltr .me-lg-n15 {
    margin-right: -60px !important;
  }

  .v-application--is-rtl .me-lg-n15 {
    margin-left: -60px !important;
  }

  .v-application--is-ltr .me-lg-n16 {
    margin-right: -64px !important;
  }

  .v-application--is-rtl .me-lg-n16 {
    margin-left: -64px !important;
  }

  .v-application .pa-lg-0 {
    padding: 0 !important;
  }

  .v-application .pa-lg-1 {
    padding: 4px !important;
  }

  .v-application .pa-lg-2 {
    padding: 8px !important;
  }

  .v-application .pa-lg-3 {
    padding: 12px !important;
  }

  .v-application .pa-lg-4 {
    padding: 16px !important;
  }

  .v-application .pa-lg-5 {
    padding: 20px !important;
  }

  .v-application .pa-lg-6 {
    padding: 24px !important;
  }

  .v-application .pa-lg-7 {
    padding: 28px !important;
  }

  .v-application .pa-lg-8 {
    padding: 32px !important;
  }

  .v-application .pa-lg-9 {
    padding: 36px !important;
  }

  .v-application .pa-lg-10 {
    padding: 40px !important;
  }

  .v-application .pa-lg-11 {
    padding: 44px !important;
  }

  .v-application .pa-lg-12 {
    padding: 48px !important;
  }

  .v-application .pa-lg-13 {
    padding: 52px !important;
  }

  .v-application .pa-lg-14 {
    padding: 56px !important;
  }

  .v-application .pa-lg-15 {
    padding: 60px !important;
  }

  .v-application .pa-lg-16 {
    padding: 64px !important;
  }

  .v-application .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .v-application .px-lg-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .v-application .px-lg-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .v-application .px-lg-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .v-application .px-lg-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .v-application .px-lg-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .v-application .px-lg-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .v-application .px-lg-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .v-application .px-lg-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .v-application .px-lg-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .v-application .px-lg-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .v-application .px-lg-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .v-application .px-lg-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .v-application .px-lg-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .v-application .px-lg-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .v-application .px-lg-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .v-application .px-lg-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .v-application .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .v-application .py-lg-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .v-application .py-lg-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .v-application .py-lg-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .v-application .py-lg-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .v-application .py-lg-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .v-application .py-lg-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .v-application .py-lg-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .v-application .py-lg-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .v-application .py-lg-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .v-application .py-lg-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .v-application .py-lg-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .v-application .py-lg-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .v-application .py-lg-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .v-application .py-lg-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .v-application .py-lg-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .v-application .py-lg-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .v-application .pt-lg-0 {
    padding-top: 0 !important;
  }

  .v-application .pt-lg-1 {
    padding-top: 4px !important;
  }

  .v-application .pt-lg-2 {
    padding-top: 8px !important;
  }

  .v-application .pt-lg-3 {
    padding-top: 12px !important;
  }

  .v-application .pt-lg-4 {
    padding-top: 16px !important;
  }

  .v-application .pt-lg-5 {
    padding-top: 20px !important;
  }

  .v-application .pt-lg-6 {
    padding-top: 24px !important;
  }

  .v-application .pt-lg-7 {
    padding-top: 28px !important;
  }

  .v-application .pt-lg-8 {
    padding-top: 32px !important;
  }

  .v-application .pt-lg-9 {
    padding-top: 36px !important;
  }

  .v-application .pt-lg-10 {
    padding-top: 40px !important;
  }

  .v-application .pt-lg-11 {
    padding-top: 44px !important;
  }

  .v-application .pt-lg-12 {
    padding-top: 48px !important;
  }

  .v-application .pt-lg-13 {
    padding-top: 52px !important;
  }

  .v-application .pt-lg-14 {
    padding-top: 56px !important;
  }

  .v-application .pt-lg-15 {
    padding-top: 60px !important;
  }

  .v-application .pt-lg-16 {
    padding-top: 64px !important;
  }

  .v-application .pr-lg-0 {
    padding-right: 0 !important;
  }

  .v-application .pr-lg-1 {
    padding-right: 4px !important;
  }

  .v-application .pr-lg-2 {
    padding-right: 8px !important;
  }

  .v-application .pr-lg-3 {
    padding-right: 12px !important;
  }

  .v-application .pr-lg-4 {
    padding-right: 16px !important;
  }

  .v-application .pr-lg-5 {
    padding-right: 20px !important;
  }

  .v-application .pr-lg-6 {
    padding-right: 24px !important;
  }

  .v-application .pr-lg-7 {
    padding-right: 28px !important;
  }

  .v-application .pr-lg-8 {
    padding-right: 32px !important;
  }

  .v-application .pr-lg-9 {
    padding-right: 36px !important;
  }

  .v-application .pr-lg-10 {
    padding-right: 40px !important;
  }

  .v-application .pr-lg-11 {
    padding-right: 44px !important;
  }

  .v-application .pr-lg-12 {
    padding-right: 48px !important;
  }

  .v-application .pr-lg-13 {
    padding-right: 52px !important;
  }

  .v-application .pr-lg-14 {
    padding-right: 56px !important;
  }

  .v-application .pr-lg-15 {
    padding-right: 60px !important;
  }

  .v-application .pr-lg-16 {
    padding-right: 64px !important;
  }

  .v-application .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .v-application .pb-lg-1 {
    padding-bottom: 4px !important;
  }

  .v-application .pb-lg-2 {
    padding-bottom: 8px !important;
  }

  .v-application .pb-lg-3 {
    padding-bottom: 12px !important;
  }

  .v-application .pb-lg-4 {
    padding-bottom: 16px !important;
  }

  .v-application .pb-lg-5 {
    padding-bottom: 20px !important;
  }

  .v-application .pb-lg-6 {
    padding-bottom: 24px !important;
  }

  .v-application .pb-lg-7 {
    padding-bottom: 28px !important;
  }

  .v-application .pb-lg-8 {
    padding-bottom: 32px !important;
  }

  .v-application .pb-lg-9 {
    padding-bottom: 36px !important;
  }

  .v-application .pb-lg-10 {
    padding-bottom: 40px !important;
  }

  .v-application .pb-lg-11 {
    padding-bottom: 44px !important;
  }

  .v-application .pb-lg-12 {
    padding-bottom: 48px !important;
  }

  .v-application .pb-lg-13 {
    padding-bottom: 52px !important;
  }

  .v-application .pb-lg-14 {
    padding-bottom: 56px !important;
  }

  .v-application .pb-lg-15 {
    padding-bottom: 60px !important;
  }

  .v-application .pb-lg-16 {
    padding-bottom: 64px !important;
  }

  .v-application .pl-lg-0 {
    padding-left: 0 !important;
  }

  .v-application .pl-lg-1 {
    padding-left: 4px !important;
  }

  .v-application .pl-lg-2 {
    padding-left: 8px !important;
  }

  .v-application .pl-lg-3 {
    padding-left: 12px !important;
  }

  .v-application .pl-lg-4 {
    padding-left: 16px !important;
  }

  .v-application .pl-lg-5 {
    padding-left: 20px !important;
  }

  .v-application .pl-lg-6 {
    padding-left: 24px !important;
  }

  .v-application .pl-lg-7 {
    padding-left: 28px !important;
  }

  .v-application .pl-lg-8 {
    padding-left: 32px !important;
  }

  .v-application .pl-lg-9 {
    padding-left: 36px !important;
  }

  .v-application .pl-lg-10 {
    padding-left: 40px !important;
  }

  .v-application .pl-lg-11 {
    padding-left: 44px !important;
  }

  .v-application .pl-lg-12 {
    padding-left: 48px !important;
  }

  .v-application .pl-lg-13 {
    padding-left: 52px !important;
  }

  .v-application .pl-lg-14 {
    padding-left: 56px !important;
  }

  .v-application .pl-lg-15 {
    padding-left: 60px !important;
  }

  .v-application .pl-lg-16 {
    padding-left: 64px !important;
  }

  .v-application--is-ltr .ps-lg-0 {
    padding-left: 0 !important;
  }

  .v-application--is-rtl .ps-lg-0 {
    padding-right: 0 !important;
  }

  .v-application--is-ltr .ps-lg-1 {
    padding-left: 4px !important;
  }

  .v-application--is-rtl .ps-lg-1 {
    padding-right: 4px !important;
  }

  .v-application--is-ltr .ps-lg-2 {
    padding-left: 8px !important;
  }

  .v-application--is-rtl .ps-lg-2 {
    padding-right: 8px !important;
  }

  .v-application--is-ltr .ps-lg-3 {
    padding-left: 12px !important;
  }

  .v-application--is-rtl .ps-lg-3 {
    padding-right: 12px !important;
  }

  .v-application--is-ltr .ps-lg-4 {
    padding-left: 16px !important;
  }

  .v-application--is-rtl .ps-lg-4 {
    padding-right: 16px !important;
  }

  .v-application--is-ltr .ps-lg-5 {
    padding-left: 20px !important;
  }

  .v-application--is-rtl .ps-lg-5 {
    padding-right: 20px !important;
  }

  .v-application--is-ltr .ps-lg-6 {
    padding-left: 24px !important;
  }

  .v-application--is-rtl .ps-lg-6 {
    padding-right: 24px !important;
  }

  .v-application--is-ltr .ps-lg-7 {
    padding-left: 28px !important;
  }

  .v-application--is-rtl .ps-lg-7 {
    padding-right: 28px !important;
  }

  .v-application--is-ltr .ps-lg-8 {
    padding-left: 32px !important;
  }

  .v-application--is-rtl .ps-lg-8 {
    padding-right: 32px !important;
  }

  .v-application--is-ltr .ps-lg-9 {
    padding-left: 36px !important;
  }

  .v-application--is-rtl .ps-lg-9 {
    padding-right: 36px !important;
  }

  .v-application--is-ltr .ps-lg-10 {
    padding-left: 40px !important;
  }

  .v-application--is-rtl .ps-lg-10 {
    padding-right: 40px !important;
  }

  .v-application--is-ltr .ps-lg-11 {
    padding-left: 44px !important;
  }

  .v-application--is-rtl .ps-lg-11 {
    padding-right: 44px !important;
  }

  .v-application--is-ltr .ps-lg-12 {
    padding-left: 48px !important;
  }

  .v-application--is-rtl .ps-lg-12 {
    padding-right: 48px !important;
  }

  .v-application--is-ltr .ps-lg-13 {
    padding-left: 52px !important;
  }

  .v-application--is-rtl .ps-lg-13 {
    padding-right: 52px !important;
  }

  .v-application--is-ltr .ps-lg-14 {
    padding-left: 56px !important;
  }

  .v-application--is-rtl .ps-lg-14 {
    padding-right: 56px !important;
  }

  .v-application--is-ltr .ps-lg-15 {
    padding-left: 60px !important;
  }

  .v-application--is-rtl .ps-lg-15 {
    padding-right: 60px !important;
  }

  .v-application--is-ltr .ps-lg-16 {
    padding-left: 64px !important;
  }

  .v-application--is-rtl .ps-lg-16 {
    padding-right: 64px !important;
  }

  .v-application--is-ltr .pe-lg-0 {
    padding-right: 0 !important;
  }

  .v-application--is-rtl .pe-lg-0 {
    padding-left: 0 !important;
  }

  .v-application--is-ltr .pe-lg-1 {
    padding-right: 4px !important;
  }

  .v-application--is-rtl .pe-lg-1 {
    padding-left: 4px !important;
  }

  .v-application--is-ltr .pe-lg-2 {
    padding-right: 8px !important;
  }

  .v-application--is-rtl .pe-lg-2 {
    padding-left: 8px !important;
  }

  .v-application--is-ltr .pe-lg-3 {
    padding-right: 12px !important;
  }

  .v-application--is-rtl .pe-lg-3 {
    padding-left: 12px !important;
  }

  .v-application--is-ltr .pe-lg-4 {
    padding-right: 16px !important;
  }

  .v-application--is-rtl .pe-lg-4 {
    padding-left: 16px !important;
  }

  .v-application--is-ltr .pe-lg-5 {
    padding-right: 20px !important;
  }

  .v-application--is-rtl .pe-lg-5 {
    padding-left: 20px !important;
  }

  .v-application--is-ltr .pe-lg-6 {
    padding-right: 24px !important;
  }

  .v-application--is-rtl .pe-lg-6 {
    padding-left: 24px !important;
  }

  .v-application--is-ltr .pe-lg-7 {
    padding-right: 28px !important;
  }

  .v-application--is-rtl .pe-lg-7 {
    padding-left: 28px !important;
  }

  .v-application--is-ltr .pe-lg-8 {
    padding-right: 32px !important;
  }

  .v-application--is-rtl .pe-lg-8 {
    padding-left: 32px !important;
  }

  .v-application--is-ltr .pe-lg-9 {
    padding-right: 36px !important;
  }

  .v-application--is-rtl .pe-lg-9 {
    padding-left: 36px !important;
  }

  .v-application--is-ltr .pe-lg-10 {
    padding-right: 40px !important;
  }

  .v-application--is-rtl .pe-lg-10 {
    padding-left: 40px !important;
  }

  .v-application--is-ltr .pe-lg-11 {
    padding-right: 44px !important;
  }

  .v-application--is-rtl .pe-lg-11 {
    padding-left: 44px !important;
  }

  .v-application--is-ltr .pe-lg-12 {
    padding-right: 48px !important;
  }

  .v-application--is-rtl .pe-lg-12 {
    padding-left: 48px !important;
  }

  .v-application--is-ltr .pe-lg-13 {
    padding-right: 52px !important;
  }

  .v-application--is-rtl .pe-lg-13 {
    padding-left: 52px !important;
  }

  .v-application--is-ltr .pe-lg-14 {
    padding-right: 56px !important;
  }

  .v-application--is-rtl .pe-lg-14 {
    padding-left: 56px !important;
  }

  .v-application--is-ltr .pe-lg-15 {
    padding-right: 60px !important;
  }

  .v-application--is-rtl .pe-lg-15 {
    padding-left: 60px !important;
  }

  .v-application--is-ltr .pe-lg-16 {
    padding-right: 64px !important;
  }

  .v-application--is-rtl .pe-lg-16 {
    padding-left: 64px !important;
  }

  .v-application .text-lg-left {
    text-align: left !important;
  }

  .v-application .text-lg-right {
    text-align: right !important;
  }

  .v-application .text-lg-center {
    text-align: center !important;
  }

  .v-application .text-lg-justify {
    text-align: justify !important;
  }

  .v-application .text-lg-start {
    text-align: start !important;
  }

  .v-application .text-lg-end {
    text-align: end !important;
  }

  .v-application .text-lg-h1 {
    line-height: 6rem;
    letter-spacing: -.015625em !important;
    font-size: 6rem !important;
  }

  .v-application .text-lg-h1, .v-application .text-lg-h2 {
    font-weight: 300;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-lg-h2 {
    line-height: 3.75rem;
    letter-spacing: -.00833333em !important;
    font-size: 3.75rem !important;
  }

  .v-application .text-lg-h3 {
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-size: 3rem !important;
  }

  .v-application .text-lg-h3, .v-application .text-lg-h4 {
    font-weight: 400;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-lg-h4 {
    line-height: 2.5rem;
    letter-spacing: .00735294em !important;
    font-size: 2.125rem !important;
  }

  .v-application .text-lg-h5 {
    font-weight: 400;
    letter-spacing: normal !important;
    font-size: 1.5rem !important;
  }

  .v-application .text-lg-h5, .v-application .text-lg-h6 {
    line-height: 2rem;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-lg-h6 {
    font-weight: 500;
    letter-spacing: .0125em !important;
    font-size: 1.25rem !important;
  }

  .v-application .text-lg-subtitle-1 {
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: .009375em !important;
    font-family: Roboto, sans-serif !important;
    font-size: 1rem !important;
  }

  .v-application .text-lg-subtitle-2 {
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: .00714286em !important;
    font-family: Roboto, sans-serif !important;
    font-size: .875rem !important;
  }

  .v-application .text-lg-body-1 {
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: .03125em !important;
    font-family: Roboto, sans-serif !important;
    font-size: 1rem !important;
  }

  .v-application .text-lg-body-2 {
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .0178571em !important;
  }

  .v-application .text-lg-body-2, .v-application .text-lg-button {
    font-family: Roboto, sans-serif !important;
    font-size: .875rem !important;
  }

  .v-application .text-lg-button {
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
  }

  .v-application .text-lg-caption {
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .0333333em !important;
  }

  .v-application .text-lg-caption, .v-application .text-lg-overline {
    font-family: Roboto, sans-serif !important;
    font-size: .75rem !important;
  }

  .v-application .text-lg-overline {
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
  }
}

@media (min-width: 1904px) {
  .v-application .d-xl-none {
    display: none !important;
  }

  .v-application .d-xl-inline {
    display: inline !important;
  }

  .v-application .d-xl-inline-block {
    display: inline-block !important;
  }

  .v-application .d-xl-block {
    display: block !important;
  }

  .v-application .d-xl-table {
    display: table !important;
  }

  .v-application .d-xl-table-row {
    display: table-row !important;
  }

  .v-application .d-xl-table-cell {
    display: table-cell !important;
  }

  .v-application .d-xl-flex {
    display: flex !important;
  }

  .v-application .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .v-application .float-xl-none {
    float: none !important;
  }

  .v-application .float-xl-left {
    float: left !important;
  }

  .v-application .float-xl-right {
    float: right !important;
  }

  .v-application--is-rtl .float-xl-end {
    float: left !important;
  }

  .v-application--is-ltr .float-xl-end, .v-application--is-rtl .float-xl-start {
    float: right !important;
  }

  .v-application--is-ltr .float-xl-start {
    float: left !important;
  }

  .v-application .flex-xl-fill {
    flex: auto !important;
  }

  .v-application .flex-xl-row {
    flex-direction: row !important;
  }

  .v-application .flex-xl-column {
    flex-direction: column !important;
  }

  .v-application .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .v-application .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .v-application .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .v-application .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .v-application .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .v-application .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .v-application .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .v-application .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .v-application .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .v-application .justify-xl-start {
    justify-content: flex-start !important;
  }

  .v-application .justify-xl-end {
    justify-content: flex-end !important;
  }

  .v-application .justify-xl-center {
    justify-content: center !important;
  }

  .v-application .justify-xl-space-between {
    justify-content: space-between !important;
  }

  .v-application .justify-xl-space-around {
    justify-content: space-around !important;
  }

  .v-application .align-xl-start {
    align-items: flex-start !important;
  }

  .v-application .align-xl-end {
    align-items: flex-end !important;
  }

  .v-application .align-xl-center {
    align-items: center !important;
  }

  .v-application .align-xl-baseline {
    align-items: baseline !important;
  }

  .v-application .align-xl-stretch {
    align-items: stretch !important;
  }

  .v-application .align-content-xl-start {
    align-content: flex-start !important;
  }

  .v-application .align-content-xl-end {
    align-content: flex-end !important;
  }

  .v-application .align-content-xl-center {
    align-content: center !important;
  }

  .v-application .align-content-xl-space-between {
    align-content: space-between !important;
  }

  .v-application .align-content-xl-space-around {
    align-content: space-around !important;
  }

  .v-application .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .v-application .align-self-xl-auto {
    align-self: auto !important;
  }

  .v-application .align-self-xl-start {
    align-self: flex-start !important;
  }

  .v-application .align-self-xl-end {
    align-self: flex-end !important;
  }

  .v-application .align-self-xl-center {
    align-self: center !important;
  }

  .v-application .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .v-application .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .v-application .order-xl-first {
    order: -1 !important;
  }

  .v-application .order-xl-0 {
    order: 0 !important;
  }

  .v-application .order-xl-1 {
    order: 1 !important;
  }

  .v-application .order-xl-2 {
    order: 2 !important;
  }

  .v-application .order-xl-3 {
    order: 3 !important;
  }

  .v-application .order-xl-4 {
    order: 4 !important;
  }

  .v-application .order-xl-5 {
    order: 5 !important;
  }

  .v-application .order-xl-6 {
    order: 6 !important;
  }

  .v-application .order-xl-7 {
    order: 7 !important;
  }

  .v-application .order-xl-8 {
    order: 8 !important;
  }

  .v-application .order-xl-9 {
    order: 9 !important;
  }

  .v-application .order-xl-10 {
    order: 10 !important;
  }

  .v-application .order-xl-11 {
    order: 11 !important;
  }

  .v-application .order-xl-12 {
    order: 12 !important;
  }

  .v-application .order-xl-last {
    order: 13 !important;
  }

  .v-application .ma-xl-0 {
    margin: 0 !important;
  }

  .v-application .ma-xl-1 {
    margin: 4px !important;
  }

  .v-application .ma-xl-2 {
    margin: 8px !important;
  }

  .v-application .ma-xl-3 {
    margin: 12px !important;
  }

  .v-application .ma-xl-4 {
    margin: 16px !important;
  }

  .v-application .ma-xl-5 {
    margin: 20px !important;
  }

  .v-application .ma-xl-6 {
    margin: 24px !important;
  }

  .v-application .ma-xl-7 {
    margin: 28px !important;
  }

  .v-application .ma-xl-8 {
    margin: 32px !important;
  }

  .v-application .ma-xl-9 {
    margin: 36px !important;
  }

  .v-application .ma-xl-10 {
    margin: 40px !important;
  }

  .v-application .ma-xl-11 {
    margin: 44px !important;
  }

  .v-application .ma-xl-12 {
    margin: 48px !important;
  }

  .v-application .ma-xl-13 {
    margin: 52px !important;
  }

  .v-application .ma-xl-14 {
    margin: 56px !important;
  }

  .v-application .ma-xl-15 {
    margin: 60px !important;
  }

  .v-application .ma-xl-16 {
    margin: 64px !important;
  }

  .v-application .ma-xl-auto {
    margin: auto !important;
  }

  .v-application .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .v-application .mx-xl-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .v-application .mx-xl-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .v-application .mx-xl-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .v-application .mx-xl-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .v-application .mx-xl-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .v-application .mx-xl-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .v-application .mx-xl-7 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }

  .v-application .mx-xl-8 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .v-application .mx-xl-9 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .v-application .mx-xl-10 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .v-application .mx-xl-11 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }

  .v-application .mx-xl-12 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }

  .v-application .mx-xl-13 {
    margin-left: 52px !important;
    margin-right: 52px !important;
  }

  .v-application .mx-xl-14 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  .v-application .mx-xl-15 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .v-application .mx-xl-16 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }

  .v-application .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .v-application .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .v-application .my-xl-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .v-application .my-xl-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .v-application .my-xl-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .v-application .my-xl-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .v-application .my-xl-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .v-application .my-xl-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .v-application .my-xl-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .v-application .my-xl-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .v-application .my-xl-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .v-application .my-xl-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .v-application .my-xl-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .v-application .my-xl-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .v-application .my-xl-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }

  .v-application .my-xl-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }

  .v-application .my-xl-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .v-application .my-xl-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .v-application .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .v-application .mt-xl-0 {
    margin-top: 0 !important;
  }

  .v-application .mt-xl-1 {
    margin-top: 4px !important;
  }

  .v-application .mt-xl-2 {
    margin-top: 8px !important;
  }

  .v-application .mt-xl-3 {
    margin-top: 12px !important;
  }

  .v-application .mt-xl-4 {
    margin-top: 16px !important;
  }

  .v-application .mt-xl-5 {
    margin-top: 20px !important;
  }

  .v-application .mt-xl-6 {
    margin-top: 24px !important;
  }

  .v-application .mt-xl-7 {
    margin-top: 28px !important;
  }

  .v-application .mt-xl-8 {
    margin-top: 32px !important;
  }

  .v-application .mt-xl-9 {
    margin-top: 36px !important;
  }

  .v-application .mt-xl-10 {
    margin-top: 40px !important;
  }

  .v-application .mt-xl-11 {
    margin-top: 44px !important;
  }

  .v-application .mt-xl-12 {
    margin-top: 48px !important;
  }

  .v-application .mt-xl-13 {
    margin-top: 52px !important;
  }

  .v-application .mt-xl-14 {
    margin-top: 56px !important;
  }

  .v-application .mt-xl-15 {
    margin-top: 60px !important;
  }

  .v-application .mt-xl-16 {
    margin-top: 64px !important;
  }

  .v-application .mt-xl-auto {
    margin-top: auto !important;
  }

  .v-application .mr-xl-0 {
    margin-right: 0 !important;
  }

  .v-application .mr-xl-1 {
    margin-right: 4px !important;
  }

  .v-application .mr-xl-2 {
    margin-right: 8px !important;
  }

  .v-application .mr-xl-3 {
    margin-right: 12px !important;
  }

  .v-application .mr-xl-4 {
    margin-right: 16px !important;
  }

  .v-application .mr-xl-5 {
    margin-right: 20px !important;
  }

  .v-application .mr-xl-6 {
    margin-right: 24px !important;
  }

  .v-application .mr-xl-7 {
    margin-right: 28px !important;
  }

  .v-application .mr-xl-8 {
    margin-right: 32px !important;
  }

  .v-application .mr-xl-9 {
    margin-right: 36px !important;
  }

  .v-application .mr-xl-10 {
    margin-right: 40px !important;
  }

  .v-application .mr-xl-11 {
    margin-right: 44px !important;
  }

  .v-application .mr-xl-12 {
    margin-right: 48px !important;
  }

  .v-application .mr-xl-13 {
    margin-right: 52px !important;
  }

  .v-application .mr-xl-14 {
    margin-right: 56px !important;
  }

  .v-application .mr-xl-15 {
    margin-right: 60px !important;
  }

  .v-application .mr-xl-16 {
    margin-right: 64px !important;
  }

  .v-application .mr-xl-auto {
    margin-right: auto !important;
  }

  .v-application .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .v-application .mb-xl-1 {
    margin-bottom: 4px !important;
  }

  .v-application .mb-xl-2 {
    margin-bottom: 8px !important;
  }

  .v-application .mb-xl-3 {
    margin-bottom: 12px !important;
  }

  .v-application .mb-xl-4 {
    margin-bottom: 16px !important;
  }

  .v-application .mb-xl-5 {
    margin-bottom: 20px !important;
  }

  .v-application .mb-xl-6 {
    margin-bottom: 24px !important;
  }

  .v-application .mb-xl-7 {
    margin-bottom: 28px !important;
  }

  .v-application .mb-xl-8 {
    margin-bottom: 32px !important;
  }

  .v-application .mb-xl-9 {
    margin-bottom: 36px !important;
  }

  .v-application .mb-xl-10 {
    margin-bottom: 40px !important;
  }

  .v-application .mb-xl-11 {
    margin-bottom: 44px !important;
  }

  .v-application .mb-xl-12 {
    margin-bottom: 48px !important;
  }

  .v-application .mb-xl-13 {
    margin-bottom: 52px !important;
  }

  .v-application .mb-xl-14 {
    margin-bottom: 56px !important;
  }

  .v-application .mb-xl-15 {
    margin-bottom: 60px !important;
  }

  .v-application .mb-xl-16 {
    margin-bottom: 64px !important;
  }

  .v-application .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .v-application .ml-xl-0 {
    margin-left: 0 !important;
  }

  .v-application .ml-xl-1 {
    margin-left: 4px !important;
  }

  .v-application .ml-xl-2 {
    margin-left: 8px !important;
  }

  .v-application .ml-xl-3 {
    margin-left: 12px !important;
  }

  .v-application .ml-xl-4 {
    margin-left: 16px !important;
  }

  .v-application .ml-xl-5 {
    margin-left: 20px !important;
  }

  .v-application .ml-xl-6 {
    margin-left: 24px !important;
  }

  .v-application .ml-xl-7 {
    margin-left: 28px !important;
  }

  .v-application .ml-xl-8 {
    margin-left: 32px !important;
  }

  .v-application .ml-xl-9 {
    margin-left: 36px !important;
  }

  .v-application .ml-xl-10 {
    margin-left: 40px !important;
  }

  .v-application .ml-xl-11 {
    margin-left: 44px !important;
  }

  .v-application .ml-xl-12 {
    margin-left: 48px !important;
  }

  .v-application .ml-xl-13 {
    margin-left: 52px !important;
  }

  .v-application .ml-xl-14 {
    margin-left: 56px !important;
  }

  .v-application .ml-xl-15 {
    margin-left: 60px !important;
  }

  .v-application .ml-xl-16 {
    margin-left: 64px !important;
  }

  .v-application .ml-xl-auto {
    margin-left: auto !important;
  }

  .v-application--is-ltr .ms-xl-0 {
    margin-left: 0 !important;
  }

  .v-application--is-rtl .ms-xl-0 {
    margin-right: 0 !important;
  }

  .v-application--is-ltr .ms-xl-1 {
    margin-left: 4px !important;
  }

  .v-application--is-rtl .ms-xl-1 {
    margin-right: 4px !important;
  }

  .v-application--is-ltr .ms-xl-2 {
    margin-left: 8px !important;
  }

  .v-application--is-rtl .ms-xl-2 {
    margin-right: 8px !important;
  }

  .v-application--is-ltr .ms-xl-3 {
    margin-left: 12px !important;
  }

  .v-application--is-rtl .ms-xl-3 {
    margin-right: 12px !important;
  }

  .v-application--is-ltr .ms-xl-4 {
    margin-left: 16px !important;
  }

  .v-application--is-rtl .ms-xl-4 {
    margin-right: 16px !important;
  }

  .v-application--is-ltr .ms-xl-5 {
    margin-left: 20px !important;
  }

  .v-application--is-rtl .ms-xl-5 {
    margin-right: 20px !important;
  }

  .v-application--is-ltr .ms-xl-6 {
    margin-left: 24px !important;
  }

  .v-application--is-rtl .ms-xl-6 {
    margin-right: 24px !important;
  }

  .v-application--is-ltr .ms-xl-7 {
    margin-left: 28px !important;
  }

  .v-application--is-rtl .ms-xl-7 {
    margin-right: 28px !important;
  }

  .v-application--is-ltr .ms-xl-8 {
    margin-left: 32px !important;
  }

  .v-application--is-rtl .ms-xl-8 {
    margin-right: 32px !important;
  }

  .v-application--is-ltr .ms-xl-9 {
    margin-left: 36px !important;
  }

  .v-application--is-rtl .ms-xl-9 {
    margin-right: 36px !important;
  }

  .v-application--is-ltr .ms-xl-10 {
    margin-left: 40px !important;
  }

  .v-application--is-rtl .ms-xl-10 {
    margin-right: 40px !important;
  }

  .v-application--is-ltr .ms-xl-11 {
    margin-left: 44px !important;
  }

  .v-application--is-rtl .ms-xl-11 {
    margin-right: 44px !important;
  }

  .v-application--is-ltr .ms-xl-12 {
    margin-left: 48px !important;
  }

  .v-application--is-rtl .ms-xl-12 {
    margin-right: 48px !important;
  }

  .v-application--is-ltr .ms-xl-13 {
    margin-left: 52px !important;
  }

  .v-application--is-rtl .ms-xl-13 {
    margin-right: 52px !important;
  }

  .v-application--is-ltr .ms-xl-14 {
    margin-left: 56px !important;
  }

  .v-application--is-rtl .ms-xl-14 {
    margin-right: 56px !important;
  }

  .v-application--is-ltr .ms-xl-15 {
    margin-left: 60px !important;
  }

  .v-application--is-rtl .ms-xl-15 {
    margin-right: 60px !important;
  }

  .v-application--is-ltr .ms-xl-16 {
    margin-left: 64px !important;
  }

  .v-application--is-rtl .ms-xl-16 {
    margin-right: 64px !important;
  }

  .v-application--is-ltr .ms-xl-auto {
    margin-left: auto !important;
  }

  .v-application--is-rtl .ms-xl-auto {
    margin-right: auto !important;
  }

  .v-application--is-ltr .me-xl-0 {
    margin-right: 0 !important;
  }

  .v-application--is-rtl .me-xl-0 {
    margin-left: 0 !important;
  }

  .v-application--is-ltr .me-xl-1 {
    margin-right: 4px !important;
  }

  .v-application--is-rtl .me-xl-1 {
    margin-left: 4px !important;
  }

  .v-application--is-ltr .me-xl-2 {
    margin-right: 8px !important;
  }

  .v-application--is-rtl .me-xl-2 {
    margin-left: 8px !important;
  }

  .v-application--is-ltr .me-xl-3 {
    margin-right: 12px !important;
  }

  .v-application--is-rtl .me-xl-3 {
    margin-left: 12px !important;
  }

  .v-application--is-ltr .me-xl-4 {
    margin-right: 16px !important;
  }

  .v-application--is-rtl .me-xl-4 {
    margin-left: 16px !important;
  }

  .v-application--is-ltr .me-xl-5 {
    margin-right: 20px !important;
  }

  .v-application--is-rtl .me-xl-5 {
    margin-left: 20px !important;
  }

  .v-application--is-ltr .me-xl-6 {
    margin-right: 24px !important;
  }

  .v-application--is-rtl .me-xl-6 {
    margin-left: 24px !important;
  }

  .v-application--is-ltr .me-xl-7 {
    margin-right: 28px !important;
  }

  .v-application--is-rtl .me-xl-7 {
    margin-left: 28px !important;
  }

  .v-application--is-ltr .me-xl-8 {
    margin-right: 32px !important;
  }

  .v-application--is-rtl .me-xl-8 {
    margin-left: 32px !important;
  }

  .v-application--is-ltr .me-xl-9 {
    margin-right: 36px !important;
  }

  .v-application--is-rtl .me-xl-9 {
    margin-left: 36px !important;
  }

  .v-application--is-ltr .me-xl-10 {
    margin-right: 40px !important;
  }

  .v-application--is-rtl .me-xl-10 {
    margin-left: 40px !important;
  }

  .v-application--is-ltr .me-xl-11 {
    margin-right: 44px !important;
  }

  .v-application--is-rtl .me-xl-11 {
    margin-left: 44px !important;
  }

  .v-application--is-ltr .me-xl-12 {
    margin-right: 48px !important;
  }

  .v-application--is-rtl .me-xl-12 {
    margin-left: 48px !important;
  }

  .v-application--is-ltr .me-xl-13 {
    margin-right: 52px !important;
  }

  .v-application--is-rtl .me-xl-13 {
    margin-left: 52px !important;
  }

  .v-application--is-ltr .me-xl-14 {
    margin-right: 56px !important;
  }

  .v-application--is-rtl .me-xl-14 {
    margin-left: 56px !important;
  }

  .v-application--is-ltr .me-xl-15 {
    margin-right: 60px !important;
  }

  .v-application--is-rtl .me-xl-15 {
    margin-left: 60px !important;
  }

  .v-application--is-ltr .me-xl-16 {
    margin-right: 64px !important;
  }

  .v-application--is-rtl .me-xl-16 {
    margin-left: 64px !important;
  }

  .v-application--is-ltr .me-xl-auto {
    margin-right: auto !important;
  }

  .v-application--is-rtl .me-xl-auto {
    margin-left: auto !important;
  }

  .v-application .ma-xl-n1 {
    margin: -4px !important;
  }

  .v-application .ma-xl-n2 {
    margin: -8px !important;
  }

  .v-application .ma-xl-n3 {
    margin: -12px !important;
  }

  .v-application .ma-xl-n4 {
    margin: -16px !important;
  }

  .v-application .ma-xl-n5 {
    margin: -20px !important;
  }

  .v-application .ma-xl-n6 {
    margin: -24px !important;
  }

  .v-application .ma-xl-n7 {
    margin: -28px !important;
  }

  .v-application .ma-xl-n8 {
    margin: -32px !important;
  }

  .v-application .ma-xl-n9 {
    margin: -36px !important;
  }

  .v-application .ma-xl-n10 {
    margin: -40px !important;
  }

  .v-application .ma-xl-n11 {
    margin: -44px !important;
  }

  .v-application .ma-xl-n12 {
    margin: -48px !important;
  }

  .v-application .ma-xl-n13 {
    margin: -52px !important;
  }

  .v-application .ma-xl-n14 {
    margin: -56px !important;
  }

  .v-application .ma-xl-n15 {
    margin: -60px !important;
  }

  .v-application .ma-xl-n16 {
    margin: -64px !important;
  }

  .v-application .mx-xl-n1 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .v-application .mx-xl-n2 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .v-application .mx-xl-n3 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .v-application .mx-xl-n4 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  .v-application .mx-xl-n5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .v-application .mx-xl-n6 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .v-application .mx-xl-n7 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }

  .v-application .mx-xl-n8 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }

  .v-application .mx-xl-n9 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }

  .v-application .mx-xl-n10 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .v-application .mx-xl-n11 {
    margin-left: -44px !important;
    margin-right: -44px !important;
  }

  .v-application .mx-xl-n12 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }

  .v-application .mx-xl-n13 {
    margin-left: -52px !important;
    margin-right: -52px !important;
  }

  .v-application .mx-xl-n14 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }

  .v-application .mx-xl-n15 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }

  .v-application .mx-xl-n16 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }

  .v-application .my-xl-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .v-application .my-xl-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .v-application .my-xl-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .v-application .my-xl-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .v-application .my-xl-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .v-application .my-xl-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .v-application .my-xl-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .v-application .my-xl-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .v-application .my-xl-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .v-application .my-xl-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .v-application .my-xl-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .v-application .my-xl-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .v-application .my-xl-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }

  .v-application .my-xl-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }

  .v-application .my-xl-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }

  .v-application .my-xl-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }

  .v-application .mt-xl-n1 {
    margin-top: -4px !important;
  }

  .v-application .mt-xl-n2 {
    margin-top: -8px !important;
  }

  .v-application .mt-xl-n3 {
    margin-top: -12px !important;
  }

  .v-application .mt-xl-n4 {
    margin-top: -16px !important;
  }

  .v-application .mt-xl-n5 {
    margin-top: -20px !important;
  }

  .v-application .mt-xl-n6 {
    margin-top: -24px !important;
  }

  .v-application .mt-xl-n7 {
    margin-top: -28px !important;
  }

  .v-application .mt-xl-n8 {
    margin-top: -32px !important;
  }

  .v-application .mt-xl-n9 {
    margin-top: -36px !important;
  }

  .v-application .mt-xl-n10 {
    margin-top: -40px !important;
  }

  .v-application .mt-xl-n11 {
    margin-top: -44px !important;
  }

  .v-application .mt-xl-n12 {
    margin-top: -48px !important;
  }

  .v-application .mt-xl-n13 {
    margin-top: -52px !important;
  }

  .v-application .mt-xl-n14 {
    margin-top: -56px !important;
  }

  .v-application .mt-xl-n15 {
    margin-top: -60px !important;
  }

  .v-application .mt-xl-n16 {
    margin-top: -64px !important;
  }

  .v-application .mr-xl-n1 {
    margin-right: -4px !important;
  }

  .v-application .mr-xl-n2 {
    margin-right: -8px !important;
  }

  .v-application .mr-xl-n3 {
    margin-right: -12px !important;
  }

  .v-application .mr-xl-n4 {
    margin-right: -16px !important;
  }

  .v-application .mr-xl-n5 {
    margin-right: -20px !important;
  }

  .v-application .mr-xl-n6 {
    margin-right: -24px !important;
  }

  .v-application .mr-xl-n7 {
    margin-right: -28px !important;
  }

  .v-application .mr-xl-n8 {
    margin-right: -32px !important;
  }

  .v-application .mr-xl-n9 {
    margin-right: -36px !important;
  }

  .v-application .mr-xl-n10 {
    margin-right: -40px !important;
  }

  .v-application .mr-xl-n11 {
    margin-right: -44px !important;
  }

  .v-application .mr-xl-n12 {
    margin-right: -48px !important;
  }

  .v-application .mr-xl-n13 {
    margin-right: -52px !important;
  }

  .v-application .mr-xl-n14 {
    margin-right: -56px !important;
  }

  .v-application .mr-xl-n15 {
    margin-right: -60px !important;
  }

  .v-application .mr-xl-n16 {
    margin-right: -64px !important;
  }

  .v-application .mb-xl-n1 {
    margin-bottom: -4px !important;
  }

  .v-application .mb-xl-n2 {
    margin-bottom: -8px !important;
  }

  .v-application .mb-xl-n3 {
    margin-bottom: -12px !important;
  }

  .v-application .mb-xl-n4 {
    margin-bottom: -16px !important;
  }

  .v-application .mb-xl-n5 {
    margin-bottom: -20px !important;
  }

  .v-application .mb-xl-n6 {
    margin-bottom: -24px !important;
  }

  .v-application .mb-xl-n7 {
    margin-bottom: -28px !important;
  }

  .v-application .mb-xl-n8 {
    margin-bottom: -32px !important;
  }

  .v-application .mb-xl-n9 {
    margin-bottom: -36px !important;
  }

  .v-application .mb-xl-n10 {
    margin-bottom: -40px !important;
  }

  .v-application .mb-xl-n11 {
    margin-bottom: -44px !important;
  }

  .v-application .mb-xl-n12 {
    margin-bottom: -48px !important;
  }

  .v-application .mb-xl-n13 {
    margin-bottom: -52px !important;
  }

  .v-application .mb-xl-n14 {
    margin-bottom: -56px !important;
  }

  .v-application .mb-xl-n15 {
    margin-bottom: -60px !important;
  }

  .v-application .mb-xl-n16 {
    margin-bottom: -64px !important;
  }

  .v-application .ml-xl-n1 {
    margin-left: -4px !important;
  }

  .v-application .ml-xl-n2 {
    margin-left: -8px !important;
  }

  .v-application .ml-xl-n3 {
    margin-left: -12px !important;
  }

  .v-application .ml-xl-n4 {
    margin-left: -16px !important;
  }

  .v-application .ml-xl-n5 {
    margin-left: -20px !important;
  }

  .v-application .ml-xl-n6 {
    margin-left: -24px !important;
  }

  .v-application .ml-xl-n7 {
    margin-left: -28px !important;
  }

  .v-application .ml-xl-n8 {
    margin-left: -32px !important;
  }

  .v-application .ml-xl-n9 {
    margin-left: -36px !important;
  }

  .v-application .ml-xl-n10 {
    margin-left: -40px !important;
  }

  .v-application .ml-xl-n11 {
    margin-left: -44px !important;
  }

  .v-application .ml-xl-n12 {
    margin-left: -48px !important;
  }

  .v-application .ml-xl-n13 {
    margin-left: -52px !important;
  }

  .v-application .ml-xl-n14 {
    margin-left: -56px !important;
  }

  .v-application .ml-xl-n15 {
    margin-left: -60px !important;
  }

  .v-application .ml-xl-n16 {
    margin-left: -64px !important;
  }

  .v-application--is-ltr .ms-xl-n1 {
    margin-left: -4px !important;
  }

  .v-application--is-rtl .ms-xl-n1 {
    margin-right: -4px !important;
  }

  .v-application--is-ltr .ms-xl-n2 {
    margin-left: -8px !important;
  }

  .v-application--is-rtl .ms-xl-n2 {
    margin-right: -8px !important;
  }

  .v-application--is-ltr .ms-xl-n3 {
    margin-left: -12px !important;
  }

  .v-application--is-rtl .ms-xl-n3 {
    margin-right: -12px !important;
  }

  .v-application--is-ltr .ms-xl-n4 {
    margin-left: -16px !important;
  }

  .v-application--is-rtl .ms-xl-n4 {
    margin-right: -16px !important;
  }

  .v-application--is-ltr .ms-xl-n5 {
    margin-left: -20px !important;
  }

  .v-application--is-rtl .ms-xl-n5 {
    margin-right: -20px !important;
  }

  .v-application--is-ltr .ms-xl-n6 {
    margin-left: -24px !important;
  }

  .v-application--is-rtl .ms-xl-n6 {
    margin-right: -24px !important;
  }

  .v-application--is-ltr .ms-xl-n7 {
    margin-left: -28px !important;
  }

  .v-application--is-rtl .ms-xl-n7 {
    margin-right: -28px !important;
  }

  .v-application--is-ltr .ms-xl-n8 {
    margin-left: -32px !important;
  }

  .v-application--is-rtl .ms-xl-n8 {
    margin-right: -32px !important;
  }

  .v-application--is-ltr .ms-xl-n9 {
    margin-left: -36px !important;
  }

  .v-application--is-rtl .ms-xl-n9 {
    margin-right: -36px !important;
  }

  .v-application--is-ltr .ms-xl-n10 {
    margin-left: -40px !important;
  }

  .v-application--is-rtl .ms-xl-n10 {
    margin-right: -40px !important;
  }

  .v-application--is-ltr .ms-xl-n11 {
    margin-left: -44px !important;
  }

  .v-application--is-rtl .ms-xl-n11 {
    margin-right: -44px !important;
  }

  .v-application--is-ltr .ms-xl-n12 {
    margin-left: -48px !important;
  }

  .v-application--is-rtl .ms-xl-n12 {
    margin-right: -48px !important;
  }

  .v-application--is-ltr .ms-xl-n13 {
    margin-left: -52px !important;
  }

  .v-application--is-rtl .ms-xl-n13 {
    margin-right: -52px !important;
  }

  .v-application--is-ltr .ms-xl-n14 {
    margin-left: -56px !important;
  }

  .v-application--is-rtl .ms-xl-n14 {
    margin-right: -56px !important;
  }

  .v-application--is-ltr .ms-xl-n15 {
    margin-left: -60px !important;
  }

  .v-application--is-rtl .ms-xl-n15 {
    margin-right: -60px !important;
  }

  .v-application--is-ltr .ms-xl-n16 {
    margin-left: -64px !important;
  }

  .v-application--is-rtl .ms-xl-n16 {
    margin-right: -64px !important;
  }

  .v-application--is-ltr .me-xl-n1 {
    margin-right: -4px !important;
  }

  .v-application--is-rtl .me-xl-n1 {
    margin-left: -4px !important;
  }

  .v-application--is-ltr .me-xl-n2 {
    margin-right: -8px !important;
  }

  .v-application--is-rtl .me-xl-n2 {
    margin-left: -8px !important;
  }

  .v-application--is-ltr .me-xl-n3 {
    margin-right: -12px !important;
  }

  .v-application--is-rtl .me-xl-n3 {
    margin-left: -12px !important;
  }

  .v-application--is-ltr .me-xl-n4 {
    margin-right: -16px !important;
  }

  .v-application--is-rtl .me-xl-n4 {
    margin-left: -16px !important;
  }

  .v-application--is-ltr .me-xl-n5 {
    margin-right: -20px !important;
  }

  .v-application--is-rtl .me-xl-n5 {
    margin-left: -20px !important;
  }

  .v-application--is-ltr .me-xl-n6 {
    margin-right: -24px !important;
  }

  .v-application--is-rtl .me-xl-n6 {
    margin-left: -24px !important;
  }

  .v-application--is-ltr .me-xl-n7 {
    margin-right: -28px !important;
  }

  .v-application--is-rtl .me-xl-n7 {
    margin-left: -28px !important;
  }

  .v-application--is-ltr .me-xl-n8 {
    margin-right: -32px !important;
  }

  .v-application--is-rtl .me-xl-n8 {
    margin-left: -32px !important;
  }

  .v-application--is-ltr .me-xl-n9 {
    margin-right: -36px !important;
  }

  .v-application--is-rtl .me-xl-n9 {
    margin-left: -36px !important;
  }

  .v-application--is-ltr .me-xl-n10 {
    margin-right: -40px !important;
  }

  .v-application--is-rtl .me-xl-n10 {
    margin-left: -40px !important;
  }

  .v-application--is-ltr .me-xl-n11 {
    margin-right: -44px !important;
  }

  .v-application--is-rtl .me-xl-n11 {
    margin-left: -44px !important;
  }

  .v-application--is-ltr .me-xl-n12 {
    margin-right: -48px !important;
  }

  .v-application--is-rtl .me-xl-n12 {
    margin-left: -48px !important;
  }

  .v-application--is-ltr .me-xl-n13 {
    margin-right: -52px !important;
  }

  .v-application--is-rtl .me-xl-n13 {
    margin-left: -52px !important;
  }

  .v-application--is-ltr .me-xl-n14 {
    margin-right: -56px !important;
  }

  .v-application--is-rtl .me-xl-n14 {
    margin-left: -56px !important;
  }

  .v-application--is-ltr .me-xl-n15 {
    margin-right: -60px !important;
  }

  .v-application--is-rtl .me-xl-n15 {
    margin-left: -60px !important;
  }

  .v-application--is-ltr .me-xl-n16 {
    margin-right: -64px !important;
  }

  .v-application--is-rtl .me-xl-n16 {
    margin-left: -64px !important;
  }

  .v-application .pa-xl-0 {
    padding: 0 !important;
  }

  .v-application .pa-xl-1 {
    padding: 4px !important;
  }

  .v-application .pa-xl-2 {
    padding: 8px !important;
  }

  .v-application .pa-xl-3 {
    padding: 12px !important;
  }

  .v-application .pa-xl-4 {
    padding: 16px !important;
  }

  .v-application .pa-xl-5 {
    padding: 20px !important;
  }

  .v-application .pa-xl-6 {
    padding: 24px !important;
  }

  .v-application .pa-xl-7 {
    padding: 28px !important;
  }

  .v-application .pa-xl-8 {
    padding: 32px !important;
  }

  .v-application .pa-xl-9 {
    padding: 36px !important;
  }

  .v-application .pa-xl-10 {
    padding: 40px !important;
  }

  .v-application .pa-xl-11 {
    padding: 44px !important;
  }

  .v-application .pa-xl-12 {
    padding: 48px !important;
  }

  .v-application .pa-xl-13 {
    padding: 52px !important;
  }

  .v-application .pa-xl-14 {
    padding: 56px !important;
  }

  .v-application .pa-xl-15 {
    padding: 60px !important;
  }

  .v-application .pa-xl-16 {
    padding: 64px !important;
  }

  .v-application .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .v-application .px-xl-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .v-application .px-xl-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .v-application .px-xl-3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .v-application .px-xl-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .v-application .px-xl-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .v-application .px-xl-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .v-application .px-xl-7 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .v-application .px-xl-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .v-application .px-xl-9 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .v-application .px-xl-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .v-application .px-xl-11 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .v-application .px-xl-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .v-application .px-xl-13 {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .v-application .px-xl-14 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  .v-application .px-xl-15 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .v-application .px-xl-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .v-application .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .v-application .py-xl-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .v-application .py-xl-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .v-application .py-xl-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .v-application .py-xl-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .v-application .py-xl-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .v-application .py-xl-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .v-application .py-xl-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .v-application .py-xl-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .v-application .py-xl-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .v-application .py-xl-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .v-application .py-xl-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .v-application .py-xl-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .v-application .py-xl-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }

  .v-application .py-xl-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }

  .v-application .py-xl-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .v-application .py-xl-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  .v-application .pt-xl-0 {
    padding-top: 0 !important;
  }

  .v-application .pt-xl-1 {
    padding-top: 4px !important;
  }

  .v-application .pt-xl-2 {
    padding-top: 8px !important;
  }

  .v-application .pt-xl-3 {
    padding-top: 12px !important;
  }

  .v-application .pt-xl-4 {
    padding-top: 16px !important;
  }

  .v-application .pt-xl-5 {
    padding-top: 20px !important;
  }

  .v-application .pt-xl-6 {
    padding-top: 24px !important;
  }

  .v-application .pt-xl-7 {
    padding-top: 28px !important;
  }

  .v-application .pt-xl-8 {
    padding-top: 32px !important;
  }

  .v-application .pt-xl-9 {
    padding-top: 36px !important;
  }

  .v-application .pt-xl-10 {
    padding-top: 40px !important;
  }

  .v-application .pt-xl-11 {
    padding-top: 44px !important;
  }

  .v-application .pt-xl-12 {
    padding-top: 48px !important;
  }

  .v-application .pt-xl-13 {
    padding-top: 52px !important;
  }

  .v-application .pt-xl-14 {
    padding-top: 56px !important;
  }

  .v-application .pt-xl-15 {
    padding-top: 60px !important;
  }

  .v-application .pt-xl-16 {
    padding-top: 64px !important;
  }

  .v-application .pr-xl-0 {
    padding-right: 0 !important;
  }

  .v-application .pr-xl-1 {
    padding-right: 4px !important;
  }

  .v-application .pr-xl-2 {
    padding-right: 8px !important;
  }

  .v-application .pr-xl-3 {
    padding-right: 12px !important;
  }

  .v-application .pr-xl-4 {
    padding-right: 16px !important;
  }

  .v-application .pr-xl-5 {
    padding-right: 20px !important;
  }

  .v-application .pr-xl-6 {
    padding-right: 24px !important;
  }

  .v-application .pr-xl-7 {
    padding-right: 28px !important;
  }

  .v-application .pr-xl-8 {
    padding-right: 32px !important;
  }

  .v-application .pr-xl-9 {
    padding-right: 36px !important;
  }

  .v-application .pr-xl-10 {
    padding-right: 40px !important;
  }

  .v-application .pr-xl-11 {
    padding-right: 44px !important;
  }

  .v-application .pr-xl-12 {
    padding-right: 48px !important;
  }

  .v-application .pr-xl-13 {
    padding-right: 52px !important;
  }

  .v-application .pr-xl-14 {
    padding-right: 56px !important;
  }

  .v-application .pr-xl-15 {
    padding-right: 60px !important;
  }

  .v-application .pr-xl-16 {
    padding-right: 64px !important;
  }

  .v-application .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .v-application .pb-xl-1 {
    padding-bottom: 4px !important;
  }

  .v-application .pb-xl-2 {
    padding-bottom: 8px !important;
  }

  .v-application .pb-xl-3 {
    padding-bottom: 12px !important;
  }

  .v-application .pb-xl-4 {
    padding-bottom: 16px !important;
  }

  .v-application .pb-xl-5 {
    padding-bottom: 20px !important;
  }

  .v-application .pb-xl-6 {
    padding-bottom: 24px !important;
  }

  .v-application .pb-xl-7 {
    padding-bottom: 28px !important;
  }

  .v-application .pb-xl-8 {
    padding-bottom: 32px !important;
  }

  .v-application .pb-xl-9 {
    padding-bottom: 36px !important;
  }

  .v-application .pb-xl-10 {
    padding-bottom: 40px !important;
  }

  .v-application .pb-xl-11 {
    padding-bottom: 44px !important;
  }

  .v-application .pb-xl-12 {
    padding-bottom: 48px !important;
  }

  .v-application .pb-xl-13 {
    padding-bottom: 52px !important;
  }

  .v-application .pb-xl-14 {
    padding-bottom: 56px !important;
  }

  .v-application .pb-xl-15 {
    padding-bottom: 60px !important;
  }

  .v-application .pb-xl-16 {
    padding-bottom: 64px !important;
  }

  .v-application .pl-xl-0 {
    padding-left: 0 !important;
  }

  .v-application .pl-xl-1 {
    padding-left: 4px !important;
  }

  .v-application .pl-xl-2 {
    padding-left: 8px !important;
  }

  .v-application .pl-xl-3 {
    padding-left: 12px !important;
  }

  .v-application .pl-xl-4 {
    padding-left: 16px !important;
  }

  .v-application .pl-xl-5 {
    padding-left: 20px !important;
  }

  .v-application .pl-xl-6 {
    padding-left: 24px !important;
  }

  .v-application .pl-xl-7 {
    padding-left: 28px !important;
  }

  .v-application .pl-xl-8 {
    padding-left: 32px !important;
  }

  .v-application .pl-xl-9 {
    padding-left: 36px !important;
  }

  .v-application .pl-xl-10 {
    padding-left: 40px !important;
  }

  .v-application .pl-xl-11 {
    padding-left: 44px !important;
  }

  .v-application .pl-xl-12 {
    padding-left: 48px !important;
  }

  .v-application .pl-xl-13 {
    padding-left: 52px !important;
  }

  .v-application .pl-xl-14 {
    padding-left: 56px !important;
  }

  .v-application .pl-xl-15 {
    padding-left: 60px !important;
  }

  .v-application .pl-xl-16 {
    padding-left: 64px !important;
  }

  .v-application--is-ltr .ps-xl-0 {
    padding-left: 0 !important;
  }

  .v-application--is-rtl .ps-xl-0 {
    padding-right: 0 !important;
  }

  .v-application--is-ltr .ps-xl-1 {
    padding-left: 4px !important;
  }

  .v-application--is-rtl .ps-xl-1 {
    padding-right: 4px !important;
  }

  .v-application--is-ltr .ps-xl-2 {
    padding-left: 8px !important;
  }

  .v-application--is-rtl .ps-xl-2 {
    padding-right: 8px !important;
  }

  .v-application--is-ltr .ps-xl-3 {
    padding-left: 12px !important;
  }

  .v-application--is-rtl .ps-xl-3 {
    padding-right: 12px !important;
  }

  .v-application--is-ltr .ps-xl-4 {
    padding-left: 16px !important;
  }

  .v-application--is-rtl .ps-xl-4 {
    padding-right: 16px !important;
  }

  .v-application--is-ltr .ps-xl-5 {
    padding-left: 20px !important;
  }

  .v-application--is-rtl .ps-xl-5 {
    padding-right: 20px !important;
  }

  .v-application--is-ltr .ps-xl-6 {
    padding-left: 24px !important;
  }

  .v-application--is-rtl .ps-xl-6 {
    padding-right: 24px !important;
  }

  .v-application--is-ltr .ps-xl-7 {
    padding-left: 28px !important;
  }

  .v-application--is-rtl .ps-xl-7 {
    padding-right: 28px !important;
  }

  .v-application--is-ltr .ps-xl-8 {
    padding-left: 32px !important;
  }

  .v-application--is-rtl .ps-xl-8 {
    padding-right: 32px !important;
  }

  .v-application--is-ltr .ps-xl-9 {
    padding-left: 36px !important;
  }

  .v-application--is-rtl .ps-xl-9 {
    padding-right: 36px !important;
  }

  .v-application--is-ltr .ps-xl-10 {
    padding-left: 40px !important;
  }

  .v-application--is-rtl .ps-xl-10 {
    padding-right: 40px !important;
  }

  .v-application--is-ltr .ps-xl-11 {
    padding-left: 44px !important;
  }

  .v-application--is-rtl .ps-xl-11 {
    padding-right: 44px !important;
  }

  .v-application--is-ltr .ps-xl-12 {
    padding-left: 48px !important;
  }

  .v-application--is-rtl .ps-xl-12 {
    padding-right: 48px !important;
  }

  .v-application--is-ltr .ps-xl-13 {
    padding-left: 52px !important;
  }

  .v-application--is-rtl .ps-xl-13 {
    padding-right: 52px !important;
  }

  .v-application--is-ltr .ps-xl-14 {
    padding-left: 56px !important;
  }

  .v-application--is-rtl .ps-xl-14 {
    padding-right: 56px !important;
  }

  .v-application--is-ltr .ps-xl-15 {
    padding-left: 60px !important;
  }

  .v-application--is-rtl .ps-xl-15 {
    padding-right: 60px !important;
  }

  .v-application--is-ltr .ps-xl-16 {
    padding-left: 64px !important;
  }

  .v-application--is-rtl .ps-xl-16 {
    padding-right: 64px !important;
  }

  .v-application--is-ltr .pe-xl-0 {
    padding-right: 0 !important;
  }

  .v-application--is-rtl .pe-xl-0 {
    padding-left: 0 !important;
  }

  .v-application--is-ltr .pe-xl-1 {
    padding-right: 4px !important;
  }

  .v-application--is-rtl .pe-xl-1 {
    padding-left: 4px !important;
  }

  .v-application--is-ltr .pe-xl-2 {
    padding-right: 8px !important;
  }

  .v-application--is-rtl .pe-xl-2 {
    padding-left: 8px !important;
  }

  .v-application--is-ltr .pe-xl-3 {
    padding-right: 12px !important;
  }

  .v-application--is-rtl .pe-xl-3 {
    padding-left: 12px !important;
  }

  .v-application--is-ltr .pe-xl-4 {
    padding-right: 16px !important;
  }

  .v-application--is-rtl .pe-xl-4 {
    padding-left: 16px !important;
  }

  .v-application--is-ltr .pe-xl-5 {
    padding-right: 20px !important;
  }

  .v-application--is-rtl .pe-xl-5 {
    padding-left: 20px !important;
  }

  .v-application--is-ltr .pe-xl-6 {
    padding-right: 24px !important;
  }

  .v-application--is-rtl .pe-xl-6 {
    padding-left: 24px !important;
  }

  .v-application--is-ltr .pe-xl-7 {
    padding-right: 28px !important;
  }

  .v-application--is-rtl .pe-xl-7 {
    padding-left: 28px !important;
  }

  .v-application--is-ltr .pe-xl-8 {
    padding-right: 32px !important;
  }

  .v-application--is-rtl .pe-xl-8 {
    padding-left: 32px !important;
  }

  .v-application--is-ltr .pe-xl-9 {
    padding-right: 36px !important;
  }

  .v-application--is-rtl .pe-xl-9 {
    padding-left: 36px !important;
  }

  .v-application--is-ltr .pe-xl-10 {
    padding-right: 40px !important;
  }

  .v-application--is-rtl .pe-xl-10 {
    padding-left: 40px !important;
  }

  .v-application--is-ltr .pe-xl-11 {
    padding-right: 44px !important;
  }

  .v-application--is-rtl .pe-xl-11 {
    padding-left: 44px !important;
  }

  .v-application--is-ltr .pe-xl-12 {
    padding-right: 48px !important;
  }

  .v-application--is-rtl .pe-xl-12 {
    padding-left: 48px !important;
  }

  .v-application--is-ltr .pe-xl-13 {
    padding-right: 52px !important;
  }

  .v-application--is-rtl .pe-xl-13 {
    padding-left: 52px !important;
  }

  .v-application--is-ltr .pe-xl-14 {
    padding-right: 56px !important;
  }

  .v-application--is-rtl .pe-xl-14 {
    padding-left: 56px !important;
  }

  .v-application--is-ltr .pe-xl-15 {
    padding-right: 60px !important;
  }

  .v-application--is-rtl .pe-xl-15 {
    padding-left: 60px !important;
  }

  .v-application--is-ltr .pe-xl-16 {
    padding-right: 64px !important;
  }

  .v-application--is-rtl .pe-xl-16 {
    padding-left: 64px !important;
  }

  .v-application .text-xl-left {
    text-align: left !important;
  }

  .v-application .text-xl-right {
    text-align: right !important;
  }

  .v-application .text-xl-center {
    text-align: center !important;
  }

  .v-application .text-xl-justify {
    text-align: justify !important;
  }

  .v-application .text-xl-start {
    text-align: start !important;
  }

  .v-application .text-xl-end {
    text-align: end !important;
  }

  .v-application .text-xl-h1 {
    line-height: 6rem;
    letter-spacing: -.015625em !important;
    font-size: 6rem !important;
  }

  .v-application .text-xl-h1, .v-application .text-xl-h2 {
    font-weight: 300;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-xl-h2 {
    line-height: 3.75rem;
    letter-spacing: -.00833333em !important;
    font-size: 3.75rem !important;
  }

  .v-application .text-xl-h3 {
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-size: 3rem !important;
  }

  .v-application .text-xl-h3, .v-application .text-xl-h4 {
    font-weight: 400;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-xl-h4 {
    line-height: 2.5rem;
    letter-spacing: .00735294em !important;
    font-size: 2.125rem !important;
  }

  .v-application .text-xl-h5 {
    font-weight: 400;
    letter-spacing: normal !important;
    font-size: 1.5rem !important;
  }

  .v-application .text-xl-h5, .v-application .text-xl-h6 {
    line-height: 2rem;
    font-family: Roboto, sans-serif !important;
  }

  .v-application .text-xl-h6 {
    font-weight: 500;
    letter-spacing: .0125em !important;
    font-size: 1.25rem !important;
  }

  .v-application .text-xl-subtitle-1 {
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: .009375em !important;
    font-family: Roboto, sans-serif !important;
    font-size: 1rem !important;
  }

  .v-application .text-xl-subtitle-2 {
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: .00714286em !important;
    font-family: Roboto, sans-serif !important;
    font-size: .875rem !important;
  }

  .v-application .text-xl-body-1 {
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: .03125em !important;
    font-family: Roboto, sans-serif !important;
    font-size: 1rem !important;
  }

  .v-application .text-xl-body-2 {
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .0178571em !important;
  }

  .v-application .text-xl-body-2, .v-application .text-xl-button {
    font-family: Roboto, sans-serif !important;
    font-size: .875rem !important;
  }

  .v-application .text-xl-button {
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: .0892857em !important;
    text-transform: uppercase !important;
  }

  .v-application .text-xl-caption {
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .0333333em !important;
  }

  .v-application .text-xl-caption, .v-application .text-xl-overline {
    font-family: Roboto, sans-serif !important;
    font-size: .75rem !important;
  }

  .v-application .text-xl-overline {
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: .166667em !important;
    text-transform: uppercase !important;
  }
}

@media print {
  .v-application .d-print-none {
    display: none !important;
  }

  .v-application .d-print-inline {
    display: inline !important;
  }

  .v-application .d-print-inline-block {
    display: inline-block !important;
  }

  .v-application .d-print-block {
    display: block !important;
  }

  .v-application .d-print-table {
    display: table !important;
  }

  .v-application .d-print-table-row {
    display: table-row !important;
  }

  .v-application .d-print-table-cell {
    display: table-cell !important;
  }

  .v-application .d-print-flex {
    display: flex !important;
  }

  .v-application .d-print-inline-flex {
    display: inline-flex !important;
  }

  .v-application .float-print-none {
    float: none !important;
  }

  .v-application .float-print-left {
    float: left !important;
  }

  .v-application .float-print-right {
    float: right !important;
  }

  .v-application--is-rtl .float-print-end {
    float: left !important;
  }

  .v-application--is-ltr .float-print-end, .v-application--is-rtl .float-print-start {
    float: right !important;
  }

  .v-application--is-ltr .float-print-start {
    float: left !important;
  }
}

.theme--light.v-application {
  color: #000000de;
  background: #fff;
}

.theme--light.v-application .text--primary {
  color: #000000de !important;
}

.theme--light.v-application .text--secondary {
  color: #0009 !important;
}

.theme--light.v-application .text--disabled {
  color: #00000061 !important;
}

.theme--dark.v-application {
  color: #fff;
  background: #121212;
}

.theme--dark.v-application .text--primary {
  color: #fff !important;
}

.theme--dark.v-application .text--secondary {
  color: #ffffffb3 !important;
}

.theme--dark.v-application .text--disabled {
  color: #ffffff80 !important;
}

.v-application {
  display: flex;
  position: relative;
}

.v-application a {
  cursor: pointer;
}

.v-application--is-rtl {
  direction: rtl;
}

.v-application--wrap {
  backface-visibility: hidden;
  min-height: 100vh;
  max-width: 100%;
  flex-direction: column;
  flex: auto;
  display: flex;
  position: relative;
}

@-moz-document url-prefix() {
  @media print {
    .v-application, .v-application--wrap {
      display: block;
    }
  }
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #f5f5f5;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
  background-color: #272727;
}

.v-sheet.v-app-bar.v-toolbar {
  border-radius: 0;
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.v-sheet.v-app-bar.v-toolbar.v-sheet--shaped {
  border-radius: 24px 0;
}

.v-app-bar:not([data-booted="true"]) {
  transition: none !important;
}

.v-app-bar.v-app-bar--fixed {
  z-index: 5;
  position: fixed;
  top: 0;
}

.v-app-bar.v-app-bar.v-app-bar--hide-shadow {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f !important;
}

.v-app-bar--fade-img-on-scroll .v-toolbar__image .v-image__image {
  transition: opacity .4s cubic-bezier(.4, 0, .2, 1);
}

.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll .v-toolbar__image {
  will-change: opacity;
}

.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll.v-app-bar--collapse-on-scroll .v-toolbar__extension {
  display: none;
}

.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll.v-app-bar--is-scrolled .v-toolbar__title {
  padding-top: 9px;
}

.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll.v-app-bar--is-scrolled:not(.v-app-bar--bottom) .v-toolbar__title {
  padding-bottom: 9px;
}

.v-app-bar.v-app-bar--shrink-on-scroll .v-toolbar__title {
  font-size: inherit;
}

.v-app-bar-title__content, .v-app-bar-title__placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.v-app-bar-title__content {
  position: absolute;
}

.theme--light.v-toolbar.v-sheet {
  background-color: #fff;
}

.theme--dark.v-toolbar.v-sheet {
  background-color: #272727;
}

.v-sheet.v-toolbar {
  border-radius: 0;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.v-sheet.v-toolbar.v-sheet--shaped {
  border-radius: 24px 0;
}

.v-toolbar {
  contain: layout;
  max-width: 100%;
  flex: auto;
  transition: transform .2s cubic-bezier(.4, 0, .2, 1), background-color .2s cubic-bezier(.4, 0, .2, 1), left .2s cubic-bezier(.4, 0, .2, 1), right .2s cubic-bezier(.4, 0, .2, 1), box-shadow .28s cubic-bezier(.4, 0, .2, 1), max-width .25s cubic-bezier(.4, 0, .2, 1), width .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: relative;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.v-toolbar .v-input {
  margin-top: 0;
  padding-top: 0;
}

.v-toolbar__content, .v-toolbar__extension {
  padding: 4px 16px;
}

.v-toolbar__content .v-btn.v-btn--icon.v-size--default, .v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
  height: 48px;
  width: 48px;
}

.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:first-child {
  margin-left: -12px;
}

.v-application--is-rtl .v-toolbar__content > .v-btn.v-btn--icon:first-child, .v-application--is-rtl .v-toolbar__extension > .v-btn.v-btn--icon:first-child {
  margin-right: -12px;
}

.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
  padding-left: 20px;
}

.v-application--is-rtl .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-application--is-rtl .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
  padding-right: 20px;
}

.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:last-child {
  margin-right: -12px;
}

.v-application--is-rtl .v-toolbar__content > .v-btn.v-btn--icon:last-child, .v-application--is-rtl .v-toolbar__extension > .v-btn.v-btn--icon:last-child {
  margin-left: -12px;
}

.v-toolbar__content > .v-tabs, .v-toolbar__extension > .v-tabs {
  height: inherit;
  margin-top: -4px;
  margin-bottom: -4px;
}

.v-toolbar__content > .v-tabs > .v-slide-group.v-tabs-bar, .v-toolbar__extension > .v-tabs > .v-slide-group.v-tabs-bar {
  background-color: inherit;
  height: inherit;
}

.v-toolbar__content > .v-tabs:first-child, .v-toolbar__extension > .v-tabs:first-child {
  margin-left: -16px;
}

.v-toolbar__content > .v-tabs:last-child, .v-toolbar__extension > .v-tabs:last-child {
  margin-right: -16px;
}

.v-toolbar__content, .v-toolbar__extension {
  z-index: 0;
  align-items: center;
  display: flex;
  position: relative;
}

.v-toolbar__image {
  width: 100%;
  z-index: 0;
  contain: strict;
  position: absolute;
  top: 0;
  bottom: 0;
}

.v-toolbar__image, .v-toolbar__image .v-image {
  border-radius: inherit;
}

.v-toolbar__items {
  height: inherit;
  display: flex;
}

.v-toolbar__items > .v-btn {
  max-height: none;
  border-radius: 0;
  height: 100% !important;
}

.v-toolbar__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.25rem;
  line-height: 1.5;
  overflow: hidden;
}

.v-toolbar.v-toolbar--absolute {
  z-index: 1;
  position: absolute;
  top: 0;
}

.v-toolbar.v-toolbar--bottom {
  top: auto;
  bottom: 0;
}

.v-toolbar.v-toolbar--collapse .v-toolbar__title {
  white-space: nowrap;
}

.v-toolbar.v-toolbar--collapsed {
  max-width: 112px;
  overflow: hidden;
}

.v-application--is-ltr .v-toolbar.v-toolbar--collapsed {
  border-bottom-right-radius: 24px;
}

.v-application--is-rtl .v-toolbar.v-toolbar--collapsed {
  border-bottom-left-radius: 24px;
}

.v-toolbar.v-toolbar--collapsed .v-toolbar__extension, .v-toolbar.v-toolbar--collapsed .v-toolbar__title {
  display: none;
}

.v-toolbar--dense .v-toolbar__content, .v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
}

.v-toolbar--flat {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f !important;
}

.v-toolbar--floating {
  display: inline-flex;
}

.v-toolbar--prominent .v-toolbar__content {
  align-items: flex-start;
}

.v-toolbar--prominent .v-toolbar__title {
  padding-top: 6px;
  font-size: 1.5rem;
}

.v-toolbar--prominent:not(.v-toolbar--bottom) .v-toolbar__title {
  align-self: flex-end;
  padding-top: 0;
  padding-bottom: 6px;
}

.theme--light.v-sheet {
  color: #000000de;
  background-color: #fff;
  border-color: #fff;
}

.theme--light.v-sheet--outlined {
  border: thin solid #0000001f;
}

.theme--dark.v-sheet {
  color: #fff;
  background-color: #1e1e1e;
  border-color: #1e1e1e;
}

.theme--dark.v-sheet--outlined {
  border: thin solid #ffffff1f;
}

.v-sheet {
  border-radius: 0;
}

.v-sheet:not(.v-sheet--outlined) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.v-sheet.v-sheet--shaped {
  border-radius: 24px 0;
}

.theme--light.v-image {
  color: #000000de;
}

.theme--dark.v-image {
  color: #fff;
}

.v-image {
  z-index: 0;
}

.v-image__image, .v-image__placeholder {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.v-image__image {
  background-repeat: no-repeat;
}

.v-image__image--preload {
  filter: blur(2px);
}

.v-image__image--contain {
  background-size: contain;
}

.v-image__image--cover {
  background-size: cover;
}

.v-responsive {
  max-width: 100%;
  flex: 1 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.v-responsive__content {
  max-width: 100%;
  flex: 1 0 0;
}

.v-application--is-ltr .v-responsive__sizer ~ .v-responsive__content {
  margin-left: -100%;
}

.v-application--is-rtl .v-responsive__sizer ~ .v-responsive__content {
  margin-right: -100%;
}

.v-responsive__sizer {
  flex: 1 0 0;
  transition: padding-bottom .2s cubic-bezier(.25, .8, .5, 1);
}

.v-btn:not(.v-btn--outlined).accent, .v-btn:not(.v-btn--outlined).error, .v-btn:not(.v-btn--outlined).info, .v-btn:not(.v-btn--outlined).primary, .v-btn:not(.v-btn--outlined).secondary, .v-btn:not(.v-btn--outlined).success, .v-btn:not(.v-btn--outlined).warning {
  color: #fff;
}

.theme--light.v-btn {
  color: #000000de;
}

.theme--light.v-btn.v-btn--disabled, .theme--light.v-btn.v-btn--disabled .v-btn__loading, .theme--light.v-btn.v-btn--disabled .v-icon {
  color: #00000042 !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #0000001f !important;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #f5f5f5;
}

.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-color: #0000001f;
}

.theme--light.v-btn.v-btn--icon {
  color: #0000008a;
}

.theme--light.v-btn:hover:before {
  opacity: .08;
}

.theme--light.v-btn:focus:before {
  opacity: .24;
}

.theme--light.v-btn--active:before, .theme--light.v-btn--active:hover:before {
  opacity: .18;
}

.theme--light.v-btn--active:focus:before {
  opacity: .16;
}

.theme--dark.v-btn {
  color: #fff;
}

.theme--dark.v-btn.v-btn--disabled, .theme--dark.v-btn.v-btn--disabled .v-btn__loading, .theme--dark.v-btn.v-btn--disabled .v-icon {
  color: #ffffff4d !important;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #ffffff1f !important;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #272727;
}

.theme--dark.v-btn.v-btn--outlined.v-btn--text {
  border-color: #ffffff1f;
}

.theme--dark.v-btn.v-btn--icon {
  color: #fff;
}

.theme--dark.v-btn:hover:before {
  opacity: .08;
}

.theme--dark.v-btn:focus:before {
  opacity: .24;
}

.theme--dark.v-btn--active:before, .theme--dark.v-btn--active:hover:before {
  opacity: .18;
}

.theme--dark.v-btn--active:focus:before {
  opacity: .32;
}

.v-btn {
  letter-spacing: .0892857em;
  text-indent: .0892857em;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 4px;
  outline: 0;
  flex: none;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  transition-property: box-shadow, transform, opacity;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
  position: relative;
}

.v-btn.v-size--x-small {
  font-size: .625rem;
}

.v-btn.v-size--small {
  font-size: .75rem;
}

.v-btn.v-size--default, .v-btn.v-size--large {
  font-size: .875rem;
}

.v-btn.v-size--x-large {
  font-size: 1rem;
}

.v-btn:before {
  border-radius: inherit;
  color: inherit;
  content: "";
  opacity: 0;
  pointer-events: none;
  background-color: currentColor;
  transition: opacity .2s cubic-bezier(.4, 0, .6, 1);
  position: absolute;
  inset: 0;
}

.v-btn:not(.v-btn--round).v-size--x-small {
  height: 20px;
  min-width: 36px;
  padding: 0 8.88889px;
}

.v-btn:not(.v-btn--round).v-size--small {
  height: 28px;
  min-width: 50px;
  padding: 0 12.4444px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
}

.v-btn:not(.v-btn--round).v-size--large {
  height: 44px;
  min-width: 78px;
  padding: 0 19.5556px;
}

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 52px;
  min-width: 92px;
  padding: 0 23.1111px;
}

.v-btn > .v-btn__content .v-icon {
  color: inherit;
}

.v-btn__content {
  color: inherit;
  align-items: center;
  justify-content: inherit;
  flex: 1 0 auto;
  line-height: normal;
  transition: opacity;
  display: flex;
  position: relative;
}

.v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.v-application--is-ltr .v-btn__content .v-icon--left {
  margin-left: -4px;
  margin-right: 8px;
}

.v-application--is-ltr .v-btn__content .v-icon--right, .v-application--is-rtl .v-btn__content .v-icon--left {
  margin-left: 8px;
  margin-right: -4px;
}

.v-application--is-rtl .v-btn__content .v-icon--right {
  margin-left: -4px;
  margin-right: 8px;
}

.v-btn__loader {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.v-btn--absolute.v-btn--right, .v-btn--fixed.v-btn--right {
  right: 16px;
}

.v-btn--absolute.v-btn--left, .v-btn--fixed.v-btn--left {
  left: 16px;
}

.v-btn--absolute.v-btn--top, .v-btn--fixed.v-btn--top {
  top: 16px;
}

.v-btn--absolute.v-btn--bottom, .v-btn--fixed.v-btn--bottom {
  bottom: 16px;
}

.v-btn--absolute {
  position: absolute;
}

.v-btn--fixed {
  position: fixed;
}

.v-btn--block {
  max-width: none;
  flex: 1 0 auto;
  display: flex;
  min-width: 100% !important;
}

.v-btn--is-elevated {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-btn--is-elevated:after {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.v-btn--is-elevated:active {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

.v-btn--is-elevated.v-btn--fab {
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}

.v-btn--is-elevated.v-btn--fab:after {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

.v-btn--is-elevated.v-btn--fab:active {
  box-shadow: 0 7px 8px -4px #0003, 0 12px 17px 2px #00000024, 0 5px 22px 4px #0000001f;
}

.v-btn--disabled {
  pointer-events: none;
}

.v-btn--fab, .v-btn--icon {
  min-height: 0;
  min-width: 0;
  padding: 0;
}

.v-btn--fab.v-size--x-small .v-icon, .v-btn--icon.v-size--x-small .v-icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.v-btn--fab.v-size--default .v-icon, .v-btn--fab.v-size--small .v-icon, .v-btn--icon.v-size--default .v-icon, .v-btn--icon.v-size--small .v-icon {
  height: 24px;
  width: 24px;
  font-size: 24px;
}

.v-btn--fab.v-size--large .v-icon, .v-btn--icon.v-size--large .v-icon {
  height: 28px;
  width: 28px;
  font-size: 28px;
}

.v-btn--fab.v-size--x-large .v-icon, .v-btn--icon.v-size--x-large .v-icon {
  height: 32px;
  width: 32px;
  font-size: 32px;
}

.v-btn--icon.v-size--x-small {
  height: 20px;
  width: 20px;
}

.v-btn--icon.v-size--small {
  height: 28px;
  width: 28px;
}

.v-btn--icon.v-size--default {
  height: 36px;
  width: 36px;
}

.v-btn--icon.v-size--large {
  height: 44px;
  width: 44px;
}

.v-btn--icon.v-size--x-large {
  height: 52px;
  width: 52px;
}

.v-btn--fab.v-btn--absolute, .v-btn--fab.v-btn--fixed {
  z-index: 4;
}

.v-btn--fab.v-size--x-small {
  height: 32px;
  width: 32px;
}

.v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--bottom {
  bottom: -16px;
}

.v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--top {
  top: -16px;
}

.v-btn--fab.v-size--small {
  height: 40px;
  width: 40px;
}

.v-btn--fab.v-size--small.v-btn--absolute.v-btn--bottom {
  bottom: -20px;
}

.v-btn--fab.v-size--small.v-btn--absolute.v-btn--top {
  top: -20px;
}

.v-btn--fab.v-size--default {
  height: 56px;
  width: 56px;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: -28px;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: -28px;
}

.v-btn--fab.v-size--large {
  height: 64px;
  width: 64px;
}

.v-btn--fab.v-size--large.v-btn--absolute.v-btn--bottom {
  bottom: -32px;
}

.v-btn--fab.v-size--large.v-btn--absolute.v-btn--top {
  top: -32px;
}

.v-btn--fab.v-size--x-large {
  height: 72px;
  width: 72px;
}

.v-btn--fab.v-size--x-large.v-btn--absolute.v-btn--bottom {
  bottom: -36px;
}

.v-btn--fab.v-size--x-large.v-btn--absolute.v-btn--top {
  top: -36px;
}

.v-btn--loading {
  pointer-events: none;
  transition: none;
}

.v-btn--loading .v-btn__content {
  opacity: 0;
}

.v-btn--outlined {
  border: thin solid;
}

.v-btn--plain:before {
  display: none;
}

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: .62;
}

.v-btn--round {
  border-radius: 50%;
}

.v-btn--rounded {
  border-radius: 28px;
}

.v-btn--tile {
  border-radius: 0;
}

.v-ripple__container {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  z-index: 0;
  contain: strict;
}

.v-ripple__animation, .v-ripple__container {
  color: inherit;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.v-ripple__animation {
  opacity: 0;
  will-change: transform, opacity;
  background: currentColor;
  border-radius: 50%;
}

.v-ripple__animation--enter {
  opacity: 0;
  transition: none;
}

.v-ripple__animation--in {
  opacity: .25;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1), opacity .1s cubic-bezier(.4, 0, .2, 1);
}

.v-ripple__animation--out {
  opacity: 0;
  transition: opacity .3s cubic-bezier(.4, 0, .2, 1);
}

.v-progress-circular {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.v-progress-circular > svg {
  width: 100%;
  height: 100%;
  z-index: 0;
  margin: auto;
  position: absolute;
  inset: 0;
}

.v-progress-circular--indeterminate > svg {
  transform-origin: center;
  transition: all .2s ease-in-out;
  animation: 1.4s linear infinite progress-circular-rotate;
}

.v-progress-circular--indeterminate .v-progress-circular__overlay {
  stroke-linecap: round;
  stroke-dasharray: 80 200;
  stroke-dashoffset: 0;
  animation: 1.4s ease-in-out infinite progress-circular-dash;
}

.v-progress-circular--indeterminate:not(.v-progress-circular--visible) .v-progress-circular__overlay, .v-progress-circular--indeterminate:not(.v-progress-circular--visible) > svg {
  animation-play-state: paused !important;
}

.v-progress-circular__info {
  justify-content: center;
  align-items: center;
  display: flex;
}

.v-progress-circular__underlay {
  stroke: #9e9e9e66;
  z-index: 1;
}

.v-progress-circular__overlay {
  stroke: currentColor;
  z-index: 2;
  transition: all .6s ease-in-out;
}

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100 200;
    stroke-dashoffset: -15px;
  }

  to {
    stroke-dasharray: 100 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes progress-circular-rotate {
  to {
    transform: rotate(1turn);
  }
}

.theme--light.v-icon {
  color: #0000008a;
}

.theme--light.v-icon:focus:after {
  opacity: .12;
}

.theme--light.v-icon.v-icon.v-icon--disabled {
  color: #00000061 !important;
}

.theme--dark.v-icon {
  color: #fff;
}

.theme--dark.v-icon:focus:after {
  opacity: .24;
}

.theme--dark.v-icon.v-icon.v-icon--disabled {
  color: #ffffff80 !important;
}

.v-icon.v-icon {
  font-feature-settings: "liga";
  letter-spacing: normal;
  text-indent: 0;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 1;
  transition: all .3s cubic-bezier(.25, .8, .5, 1), visibility;
  display: inline-flex;
  position: relative;
}

.v-icon.v-icon:after {
  content: "";
  height: 100%;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  background-color: currentColor;
  border-radius: 50%;
  transition: opacity .2s cubic-bezier(.4, 0, .6, 1);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.3);
}

.v-icon.v-icon--dense {
  font-size: 20px;
}

.v-icon--right {
  margin-left: 8px;
}

.v-icon--left {
  margin-right: 8px;
}

.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: none;
}

.v-icon--disabled {
  pointer-events: none;
}

.v-icon--dense .v-icon__component, .v-icon--dense .v-icon__svg {
  height: 20px;
}

.v-icon__component, .v-icon__svg {
  height: 24px;
  width: 24px;
}

.v-icon__svg {
  fill: currentColor;
}

.theme--light.v-alert .v-alert--prominent .v-alert__icon:after {
  background: #0000001f;
}

.theme--dark.v-alert .v-alert--prominent .v-alert__icon:after {
  background: #ffffff1f;
}

.v-sheet.v-alert {
  border-radius: 4px;
}

.v-sheet.v-alert:not(.v-sheet--outlined) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.v-sheet.v-alert.v-sheet--shaped {
  border-radius: 24px 4px;
}

.v-alert {
  margin-bottom: 16px;
  padding: 16px;
  font-size: 16px;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  display: block;
  position: relative;
}

.v-alert:not(.v-sheet--tile) {
  border-radius: 4px;
}

.v-application--is-ltr .v-alert > .v-alert__content, .v-application--is-ltr .v-alert > .v-icon {
  margin-right: 16px;
}

.v-application--is-rtl .v-alert > .v-alert__content, .v-application--is-rtl .v-alert > .v-icon {
  margin-left: 16px;
}

.v-application--is-ltr .v-alert > .v-icon + .v-alert__content {
  margin-right: 0;
}

.v-application--is-rtl .v-alert > .v-icon + .v-alert__content {
  margin-left: 0;
}

.v-application--is-ltr .v-alert > .v-alert__content + .v-icon {
  margin-right: 0;
}

.v-application--is-rtl .v-alert > .v-alert__content + .v-icon {
  margin-left: 0;
}

.v-alert__border {
  content: "";
  border-style: solid;
  border-width: 4px;
  position: absolute;
}

.v-alert__border:not(.v-alert__border--has-color) {
  opacity: .26;
}

.v-alert__border--left, .v-alert__border--right {
  top: 0;
  bottom: 0;
}

.v-alert__border--bottom, .v-alert__border--top {
  left: 0;
  right: 0;
}

.v-alert__border--bottom {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
}

.v-application--is-ltr .v-alert__border--left {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  left: 0;
}

.v-application--is-ltr .v-alert__border--right, .v-application--is-rtl .v-alert__border--left {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  right: 0;
}

.v-application--is-rtl .v-alert__border--right {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  left: 0;
}

.v-alert__border--top {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  top: 0;
}

.v-alert__content {
  flex: auto;
}

.v-application--is-ltr .v-alert__dismissible {
  margin: -16px -8px -16px 8px;
}

.v-application--is-rtl .v-alert__dismissible {
  margin: -16px 8px -16px -8px;
}

.v-alert__icon {
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  align-self: flex-start;
  position: relative;
}

.v-application--is-ltr .v-alert__icon {
  margin-right: 16px;
}

.v-application--is-rtl .v-alert__icon {
  margin-left: 16px;
}

.v-alert__icon.v-icon {
  font-size: 24px;
}

.v-alert__wrapper {
  border-radius: inherit;
  align-items: center;
  display: flex;
}

.v-application--is-ltr .v-alert--border.v-alert--prominent .v-alert__icon {
  margin-left: 8px;
}

.v-application--is-rtl .v-alert--border.v-alert--prominent .v-alert__icon {
  margin-right: 8px;
}

.v-alert--dense {
  padding-top: 8px;
  padding-bottom: 8px;
}

.v-alert--dense .v-alert__border {
  border-width: medium;
}

.v-alert--outlined {
  background: none !important;
  border: thin solid !important;
}

.v-alert--outlined .v-alert__icon {
  color: inherit !important;
}

.v-alert--prominent .v-alert__icon {
  height: 48px;
  min-width: 48px;
  align-self: center;
}

.v-alert--prominent .v-alert__icon.v-icon {
  font-size: 32px;
}

.v-alert--prominent .v-alert__icon.v-icon:after {
  content: "";
  opacity: .16;
  border-radius: 50%;
  position: absolute;
  inset: 0;
  background: currentColor !important;
}

.v-alert--prominent.v-alert--dense .v-alert__icon.v-icon:after {
  transform: scale(1);
}

.v-alert--text {
  background: none !important;
}

.v-alert--text:before {
  border-radius: inherit;
  content: "";
  opacity: .12;
  pointer-events: none;
  background-color: currentColor;
  position: absolute;
  inset: 0;
}

.v-autocomplete.v-input > .v-input__control > .v-input__slot {
  cursor: text;
}

.v-autocomplete input {
  align-self: center;
}

.v-autocomplete.v-select.v-input--is-focused input {
  min-width: 64px;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 0;
  padding: 0;
}

.v-autocomplete--is-selecting-index input {
  opacity: 0;
}

.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot > input {
  margin-top: 24px;
}

.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input {
  margin-top: 20px;
}

.v-autocomplete__content.v-menu__content, .v-autocomplete__content.v-menu__content .v-card {
  border-radius: 0;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #0000006b;
}

.theme--light.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
  border-color: #000000de;
}

.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border-image: repeating-linear-gradient(90deg, #00000061 0 2px, #0000 0 4px) 1 repeat;
}

.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #0000000f;
}

.theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: #0000001f;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #fff;
}

.theme--light.v-text-field--solo-inverted > .v-input__control > .v-input__slot {
  background: #0000000f;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #424242;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input {
  color: #fff;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input::-moz-placeholder {
  color: #ffffff80;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input:-ms-input-placeholder {
  color: #ffffff80;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input::placeholder {
  color: #ffffff80;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot .v-label {
  color: #ffffffb3;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: #00000061;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) > .v-input__control > .v-input__slot:hover fieldset {
  color: #000000db;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled > .v-input__control > .v-input__slot fieldset {
  color: #00000042;
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #ffffffb3;
}

.theme--dark.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
  border-color: #fff;
}

.theme--dark.v-text-field.v-input--is-disabled .v-input__slot:before {
  border-image: repeating-linear-gradient(90deg, #ffffff80 0 2px, #0000 0 4px) 1 repeat;
}

.theme--dark.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #ffffff14;
}

.theme--dark.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: #ffffff29;
}

.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #1e1e1e;
}

.theme--dark.v-text-field--solo-inverted > .v-input__control > .v-input__slot {
  background: #ffffff29;
}

.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #fff;
}

.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input {
  color: #000000de;
}

.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input::-moz-placeholder {
  color: #00000061;
}

.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input:-ms-input-placeholder {
  color: #00000061;
}

.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input::placeholder {
  color: #00000061;
}

.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot .v-label {
  color: #0009;
}

.theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: #ffffff3d;
}

.theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) > .v-input__control > .v-input__slot:hover fieldset {
  color: #fff;
}

.theme--dark.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled > .v-input__control > .v-input__slot fieldset {
  color: #ffffff29;
}

.v-text-field {
  margin-top: 4px;
  padding-top: 12px;
}

.v-text-field__prefix, .v-text-field__suffix {
  line-height: 20px;
}

.v-text-field input {
  max-width: 100%;
  min-width: 0;
  width: 100%;
  flex: auto;
  padding: 8px 0;
  line-height: 20px;
}

.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: inherit;
}

.v-text-field.v-input--has-state .v-input__control > .v-text-field__details > .v-counter, .v-text-field.v-input--is-disabled .v-input__control > .v-text-field__details > .v-counter, .v-text-field.v-input--is-disabled .v-input__control > .v-text-field__details > .v-messages, .v-text-field .v-input__control, .v-text-field fieldset {
  color: currentColor;
}

.v-text-field.v-input--dense {
  padding-top: 0;
}

.v-text-field.v-input--dense .v-label {
  top: 4px;
}

.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix, .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix, .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 4px 0 2px;
}

.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix {
  padding-right: 4px;
}

.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix {
  padding-left: 4px;
}

.v-text-field.v-input--dense[type="text"]::-ms-clear {
  display: none;
}

.v-text-field.v-input--dense .v-input__append-inner, .v-text-field.v-input--dense .v-input__prepend-inner {
  margin-top: 0;
}

.v-text-field .v-input__append-inner, .v-text-field .v-input__prepend-inner {
  -webkit-user-select: none;
  user-select: none;
  align-self: flex-start;
  margin-top: 4px;
  line-height: 1;
  display: inline-flex;
}

.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  margin-right: auto;
  padding-right: 4px;
}

.v-application--is-ltr .v-text-field .v-input__append-inner, .v-application--is-rtl .v-text-field .v-input__prepend-inner {
  margin-left: auto;
  padding-left: 4px;
}

.v-application--is-rtl .v-text-field .v-input__append-inner {
  margin-right: auto;
  padding-right: 4px;
}

.v-text-field .v-counter {
  white-space: nowrap;
}

.v-application--is-ltr .v-text-field .v-counter {
  margin-left: 8px;
}

.v-application--is-rtl .v-text-field .v-counter {
  margin-right: 8px;
}

.v-text-field .v-label {
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  top: 6px;
  overflow: hidden;
}

.v-application--is-ltr .v-text-field .v-label {
  transform-origin: 0 0;
}

.v-application--is-rtl .v-text-field .v-label {
  transform-origin: 100% 0;
}

.v-text-field .v-label--active {
  max-width: 133%;
  pointer-events: auto;
  transform: translateY(-18px)scale(.75);
}

.v-text-field > .v-input__control > .v-input__slot {
  cursor: text;
}

.v-text-field > .v-input__control > .v-input__slot:after, .v-text-field > .v-input__control > .v-input__slot:before {
  content: "";
  width: 100%;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  bottom: -1px;
  left: 0;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-style: solid;
  border-width: thin 0 0;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: currentColor;
  border: thin solid;
  border-width: thin 0;
  transform: scaleX(0);
}

.v-text-field__details {
  max-width: 100%;
  min-height: 14px;
  flex: 1 0 auto;
  display: flex;
  overflow: hidden;
}

.v-text-field__prefix, .v-text-field__suffix {
  cursor: default;
  white-space: nowrap;
  align-self: center;
  transition: color .3s cubic-bezier(.25, .8, .5, 1);
}

.v-application--is-ltr .v-text-field__prefix {
  text-align: right;
  padding-right: 4px;
}

.v-application--is-rtl .v-text-field__prefix {
  text-align: left;
  padding-left: 4px;
}

.v-text-field__suffix {
  white-space: nowrap;
}

.v-application--is-ltr .v-text-field__suffix {
  padding-left: 4px;
}

.v-application--is-rtl .v-text-field__suffix {
  padding-right: 4px;
}

.v-application--is-ltr .v-text-field--reverse .v-text-field__prefix {
  text-align: left;
  padding-left: 4px;
  padding-right: 0;
}

.v-application--is-rtl .v-text-field--reverse .v-text-field__prefix {
  text-align: right;
  padding-left: 0;
  padding-right: 4px;
}

.v-application--is-ltr .v-text-field--reverse .v-text-field__suffix {
  padding-left: 0;
  padding-right: 4px;
}

.v-application--is-rtl .v-text-field--reverse .v-text-field__suffix {
  padding-left: 4px;
  padding-right: 0;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  flex: auto;
  display: flex;
  position: relative;
}

.v-text-field:not(.v-text-field--is-booted) .v-label, .v-text-field:not(.v-text-field--is-booted) legend {
  transition: none;
}

.v-text-field--filled, .v-text-field--full-width, .v-text-field--outlined {
  position: relative;
}

.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 56px;
  align-items: stretch;
}

.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense > .v-input__control > .v-input__slot {
  min-height: 52px;
}

.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot {
  min-height: 40px;
}

.v-text-field--outlined {
  border-radius: 4px;
}

.v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__prepend-outer {
  margin-top: 17px;
}

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__prepend-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__prepend-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__append-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__append-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__prepend-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__prepend-outer {
  margin-top: 14px;
}

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__prepend-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__prepend-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__prepend-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__prepend-outer {
  margin-top: 9px;
}

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer {
  margin-top: 8px;
}

.v-text-field--filled .v-label, .v-text-field--full-width .v-label {
  top: 18px;
}

.v-text-field--filled .v-label--active, .v-text-field--full-width .v-label--active {
  transform: translateY(-6px)scale(.75);
}

.v-text-field--filled.v-input--dense .v-label, .v-text-field--full-width.v-input--dense .v-label {
  top: 17px;
}

.v-text-field--filled.v-input--dense .v-label--active, .v-text-field--full-width.v-input--dense .v-label--active {
  transform: translateY(-10px)scale(.75);
}

.v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
  top: 11px;
}

.v-text-field--filled {
  border-radius: 4px 4px 0 0;
}

.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 22px;
}

.v-text-field--filled.v-input--dense:not(.v-text-field--single-line).v-text-field--outlined input {
  margin-top: 0;
}

.v-text-field--filled .v-text-field__prefix, .v-text-field--filled .v-text-field__suffix {
  max-height: 32px;
  margin-top: 20px;
}

.v-text-field--full-width {
  border-radius: 0;
}

.v-text-field--outlined .v-text-field__slot, .v-text-field--single-line .v-text-field__slot {
  align-items: center;
}

.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0;
}

.v-text-field.v-text-field--enclosed.v-text-field--single-line .v-text-field__prefix, .v-text-field.v-text-field--enclosed.v-text-field--single-line .v-text-field__suffix {
  margin-top: 0;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--filled) .v-progress-linear__background {
  display: none;
}

.v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
  padding: 0 12px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 8px;
  padding-top: 0;
}

.v-application--is-ltr .v-text-field--reverse input {
  text-align: right;
}

.v-application--is-rtl .v-text-field--reverse input {
  text-align: left;
}

.v-application--is-ltr .v-text-field--reverse .v-label {
  transform-origin: 100% 0;
}

.v-application--is-rtl .v-text-field--reverse .v-label {
  transform-origin: 0 0;
}

.v-text-field--reverse .v-text-field__slot, .v-text-field--reverse > .v-input__control > .v-input__slot {
  flex-direction: row-reverse;
}

.v-text-field--outlined > .v-input__control > .v-input__slot:after, .v-text-field--outlined > .v-input__control > .v-input__slot:before, .v-text-field--rounded > .v-input__control > .v-input__slot:after, .v-text-field--rounded > .v-input__control > .v-input__slot:before, .v-text-field--solo > .v-input__control > .v-input__slot:after, .v-text-field--solo > .v-input__control > .v-input__slot:before {
  display: none;
}

.v-text-field--outlined, .v-text-field--solo {
  border-radius: 4px;
}

.v-text-field--outlined {
  margin-bottom: 16px;
  transition: border .3s cubic-bezier(.25, .8, .5, 1);
}

.v-text-field--outlined .v-label {
  top: 18px;
}

.v-text-field--outlined .v-label--active {
  transform: translateY(-24px)scale(.75);
}

.v-text-field--outlined.v-input--dense .v-label {
  top: 10px;
}

.v-text-field--outlined.v-input--dense .v-label--active {
  transform: translateY(-16px)scale(.75);
}

.v-text-field--outlined fieldset {
  border-collapse: collapse;
  pointer-events: none;
  border: 1px solid;
  transition-property: color;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.25, .8, .25, 1);
  position: absolute;
  inset: -5px 0 0;
}

.v-application--is-ltr .v-text-field--outlined fieldset {
  padding-left: 8px;
}

.v-application--is-ltr .v-text-field--outlined.v-text-field--reverse fieldset, .v-application--is-rtl .v-text-field--outlined fieldset {
  padding-right: 8px;
}

.v-application--is-rtl .v-text-field--outlined.v-text-field--reverse fieldset {
  padding-left: 8px;
}

.v-text-field--outlined legend {
  padding: 0;
  line-height: 11px;
  transition: width .3s cubic-bezier(.25, .8, .5, 1);
}

.v-application--is-ltr .v-text-field--outlined legend {
  text-align: left;
}

.v-application--is-rtl .v-text-field--outlined legend {
  text-align: right;
}

.v-application--is-ltr .v-text-field--outlined.v-text-field--reverse legend {
  margin-left: auto;
}

.v-application--is-rtl .v-text-field--outlined.v-text-field--reverse legend {
  margin-right: auto;
}

.v-application--is-ltr .v-text-field--outlined.v-text-field--rounded legend {
  margin-left: 12px;
}

.v-application--is-rtl .v-text-field--outlined.v-text-field--rounded legend {
  margin-right: 12px;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: none;
}

.v-text-field--outlined .v-text-field__prefix {
  max-height: 32px;
}

.v-text-field--outlined .v-input__append-outer, .v-text-field--outlined .v-input__prepend-outer {
  margin-top: 18px;
}

.v-text-field--outlined.v-input--has-state fieldset, .v-text-field--outlined.v-input--is-focused fieldset {
  border: 2px solid;
}

.v-text-field--rounded {
  border-radius: 28px;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 24px;
}

.v-text-field--shaped {
  border-radius: 16px 16px 0 0;
}

.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 9px);
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 48px;
  padding: 0;
}

.v-text-field.v-text-field--solo .v-input__control input {
  caret-color: auto;
}

.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 38px;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
}

.v-text-field.v-text-field--solo .v-input__append-outer, .v-text-field.v-text-field--solo .v-input__prepend-outer {
  margin-top: 12px;
}

.v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer, .v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer {
  margin-top: 7px;
}

.v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after {
  transform: scaleX(1);
}

.v-text-field.v-input--has-state > .v-input__control > .v-input__slot:before {
  border-color: currentColor;
}

.v-text-field .v-input__icon--clear {
  opacity: 0;
  transition: opacity .3s cubic-bezier(.25, .8, .5, 1);
}

.v-text-field.v-input--is-dirty:hover .v-input__icon--clear, .v-text-field.v-input--is-focused .v-input__icon--clear {
  opacity: 1;
}

.theme--light.v-select .v-select__selections {
  color: #000000de;
}

.theme--light.v-select.v-input--is-disabled .v-select__selections, .theme--light.v-select .v-select__selection--disabled {
  color: #00000061;
}

.theme--dark.v-select .v-select__selections, .theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections {
  color: #fff;
}

.theme--dark.v-select.v-input--is-disabled .v-select__selections, .theme--dark.v-select .v-select__selection--disabled {
  color: #ffffff80;
}

.theme--dark.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections {
  color: #000000de;
}

.v-select {
  position: relative;
}

.v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections {
  flex-wrap: nowrap;
}

.v-select > .v-input__control > .v-input__slot {
  cursor: pointer;
}

.v-select .v-chip {
  flex: 0 auto;
  margin: 4px;
}

.v-select .v-chip--selected:after {
  opacity: .22;
}

.v-select .fade-transition-leave-active {
  position: absolute;
  left: 0;
}

.v-select.v-input--is-dirty ::-moz-placeholder {
  color: #0000 !important;
}

.v-select.v-input--is-dirty :-ms-input-placeholder {
  color: #0000 !important;
}

.v-select.v-input--is-dirty ::placeholder {
  color: #0000 !important;
}

.v-select:not(.v-input--is-dirty):not(.v-input--is-focused) .v-text-field__prefix {
  line-height: 20px;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  top: 7px;
}

.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__selections {
  padding-top: 20px;
}

.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
  padding: 8px 0;
}

.v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections {
  padding: 4px 0;
}

.v-select.v-text-field input {
  min-width: 0;
  flex: 1;
  position: relative;
}

.v-select.v-text-field:not(.v-text-field--single-line) input {
  margin-top: 0;
}

.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(180deg);
}

.v-select.v-select--chips input {
  margin: 0;
}

.v-select.v-select--chips .v-select__selections {
  min-height: 42px;
}

.v-select.v-select--chips.v-input--dense .v-select__selections {
  min-height: 40px;
}

.v-select.v-select--chips .v-chip--select.v-chip--active:before {
  opacity: .2;
}

.v-select.v-select--chips.v-select--chips--small .v-select__selections {
  min-height: 26px;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
  min-height: 68px;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box.v-input--dense .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-input--dense .v-select__selections {
  min-height: 40px;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box.v-select--chips--small .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small .v-select__selections {
  min-height: 26px;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box.v-select--chips--small.v-input--dense .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections {
  min-height: 38px;
}

.v-select.v-text-field--reverse .v-select__selections, .v-select.v-text-field--reverse .v-select__slot {
  flex-direction: row-reverse;
}

.v-select.v-input--is-disabled:not(.v-input--is-readonly):not(.v-autocomplete) {
  pointer-events: none;
}

.v-select__selections {
  max-width: 100%;
  min-width: 0;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  line-height: 18px;
  display: flex;
}

.v-select__selection {
  max-width: 90%;
}

.v-select__selection--comma {
  min-height: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 7px 4px 7px 0;
  overflow: hidden;
}

.v-select.v-input--dense .v-select__selection--comma {
  margin: 5px 4px 3px 0;
}

.v-select.v-input--dense .v-chip {
  margin: 0 4px;
}

.v-select__slot {
  max-width: 100%;
  min-width: 0;
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot > input {
  align-self: flex-end;
}

.theme--light.v-input, .theme--light.v-input input, .theme--light.v-input textarea {
  color: #000000de;
}

.theme--light.v-input input::-moz-placeholder, .theme--light.v-input textarea::-moz-placeholder {
  color: #00000061;
}

.theme--light.v-input input:-ms-input-placeholder, .theme--light.v-input textarea:-ms-input-placeholder {
  color: #00000061;
}

.theme--light.v-input input::placeholder, .theme--light.v-input textarea::placeholder, .theme--light.v-input--is-disabled, .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
  color: #00000061;
}

.theme--dark.v-input, .theme--dark.v-input input, .theme--dark.v-input textarea {
  color: #fff;
}

.theme--dark.v-input input::-moz-placeholder, .theme--dark.v-input textarea::-moz-placeholder {
  color: #ffffff80;
}

.theme--dark.v-input input:-ms-input-placeholder, .theme--dark.v-input textarea:-ms-input-placeholder {
  color: #ffffff80;
}

.theme--dark.v-input input::placeholder, .theme--dark.v-input textarea::placeholder, .theme--dark.v-input--is-disabled, .theme--dark.v-input--is-disabled input, .theme--dark.v-input--is-disabled textarea {
  color: #ffffff80;
}

.v-input {
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
  flex: auto;
  align-items: flex-start;
  font-size: 16px;
  display: flex;
}

.v-input .v-progress-linear {
  top: calc(100% - 1px);
  left: 0;
}

.v-input input {
  max-height: 32px;
}

.v-input input:invalid, .v-input textarea:invalid {
  box-shadow: none;
}

.v-input input:active, .v-input input:focus, .v-input textarea:active, .v-input textarea:focus {
  outline: none;
}

.v-input .v-label {
  height: 20px;
  letter-spacing: normal;
  line-height: 20px;
}

.v-input__append-outer, .v-input__prepend-outer {
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 1;
  display: inline-flex;
}

.v-input__append-outer .v-icon, .v-input__prepend-outer .v-icon {
  -webkit-user-select: none;
  user-select: none;
}

.v-application--is-ltr .v-input__append-outer {
  margin-left: 9px;
}

.v-application--is-ltr .v-input__prepend-outer, .v-application--is-rtl .v-input__append-outer {
  margin-right: 9px;
}

.v-application--is-rtl .v-input__prepend-outer {
  margin-left: 9px;
}

.v-input__control {
  height: auto;
  min-width: 0;
  width: 100%;
  flex-flow: column wrap;
  flex-grow: 1;
  display: flex;
}

.v-input__icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.v-input__icon--clear {
  border-radius: 50%;
}

.v-input__icon--clear .v-icon--disabled {
  visibility: hidden;
}

.v-input__slot {
  min-height: inherit;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  transition: height .3s cubic-bezier(.25, .8, .5, 1), min-height .3s cubic-bezier(.25, .8, .5, 1);
  display: flex;
  position: relative;
}

.v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 4px;
}

.v-input--is-loading > .v-input__control > .v-input__slot:after, .v-input--is-loading > .v-input__control > .v-input__slot:before {
  display: none;
}

.v-input--hide-details > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}

.v-input--has-state.error--text .v-label {
  animation: .6s cubic-bezier(.25, .8, .5, 1) v-shake;
}

.v-input--hide-spin-buttons input::-webkit-inner-spin-button, .v-input--hide-spin-buttons input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-input--hide-spin-buttons input[type="number"] {
  -moz-appearance: textfield;
}

.theme--light.v-label {
  color: #0009;
}

.theme--light.v-label--is-disabled {
  color: #00000061;
}

.theme--dark.v-label {
  color: #ffffffb3;
}

.theme--dark.v-label--is-disabled {
  color: #ffffff80;
}

.v-label {
  min-height: 8px;
  font-size: 16px;
  line-height: 1;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
}

.theme--light.v-messages {
  color: #0009;
}

.theme--dark.v-messages {
  color: #ffffffb3;
}

.v-messages {
  min-height: 14px;
  min-width: 1px;
  flex: auto;
  font-size: 12px;
  position: relative;
}

.v-application--is-ltr .v-messages {
  text-align: left;
}

.v-application--is-rtl .v-messages {
  text-align: right;
}

.v-messages__message {
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  line-height: 12px;
}

.theme--light.v-progress-linear {
  color: #000000de;
}

.theme--dark.v-progress-linear {
  color: #fff;
}

.v-progress-linear {
  width: 100%;
  background: none;
  transition: all .2s cubic-bezier(.4, 0, .6, 1);
  position: relative;
  overflow: hidden;
}

.v-progress-linear__buffer {
  height: inherit;
  width: 100%;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.v-progress-linear--reverse .v-progress-linear__buffer {
  left: auto;
  right: 0;
}

.v-progress-linear__background {
  transition: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.v-progress-linear--reverse .v-progress-linear__background {
  left: auto;
  right: 0;
}

.v-progress-linear__content {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.v-progress-linear--reverse .v-progress-linear__content {
  left: auto;
  right: 0;
}

.v-progress-linear__determinate {
  height: inherit;
  transition: inherit;
  position: absolute;
  left: 0;
}

.v-progress-linear--reverse .v-progress-linear__determinate {
  left: auto;
  right: 0;
}

.v-progress-linear .v-progress-linear__indeterminate .long, .v-progress-linear .v-progress-linear__indeterminate .short {
  background-color: inherit;
  height: inherit;
  width: auto;
  will-change: left, right;
  animation-play-state: paused;
  position: absolute;
  inset: 0 auto 0 0;
}

.v-progress-linear .v-progress-linear__indeterminate--active .long {
  animation-name: indeterminate-ltr;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
}

.v-progress-linear .v-progress-linear__indeterminate--active .short {
  animation-name: indeterminate-short-ltr;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
}

.v-progress-linear--reverse .v-progress-linear__indeterminate .long, .v-progress-linear--reverse .v-progress-linear__indeterminate .short {
  left: auto;
  right: 0;
}

.v-progress-linear--reverse .v-progress-linear__indeterminate--active .long {
  animation-name: indeterminate-rtl;
}

.v-progress-linear--reverse .v-progress-linear__indeterminate--active .short {
  animation-name: indeterminate-short-rtl;
}

.v-progress-linear__stream {
  opacity: .3;
  pointer-events: none;
  border-color: currentColor;
  border-top-style: dotted;
  border-top-width: 4px;
  transition: inherit;
  animation: .25s linear infinite paused stream-ltr;
  position: absolute;
  inset: calc(50% - 2px) -8px 0 auto;
}

.v-progress-linear--reverse .v-progress-linear__stream {
  animation: .25s linear infinite stream-rtl;
  left: -8px;
  right: auto;
}

.v-progress-linear__wrapper {
  transition: inherit;
  position: relative;
  overflow: hidden;
}

.v-progress-linear--absolute, .v-progress-linear--fixed {
  z-index: 1;
  left: 0;
}

.v-progress-linear--absolute {
  position: absolute;
}

.v-progress-linear--fixed {
  position: fixed;
}

.v-progress-linear--reactive .v-progress-linear__content {
  pointer-events: none;
}

.v-progress-linear--rounded {
  border-radius: 4px;
}

.v-progress-linear--striped .v-progress-linear__determinate {
  background-image: linear-gradient(135deg, #ffffff40 25%, #0000 0 50%, #ffffff40 0 75%, #0000 0, #0000);
  background-repeat: repeat;
  background-size: 40px 40px;
}

.v-progress-linear--query .v-progress-linear__indeterminate--active .long {
  animation-name: query-ltr;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.v-progress-linear--query .v-progress-linear__indeterminate--active .short {
  animation-name: query-short-ltr;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.v-progress-linear--query.v-progress-linear--reverse .v-progress-linear__indeterminate--active .long {
  animation-name: query-rtl;
}

.v-progress-linear--query.v-progress-linear--reverse .v-progress-linear__indeterminate--active .short {
  animation-name: query-short-rtl;
}

.v-progress-linear--visible .v-progress-linear__indeterminate--active .long, .v-progress-linear--visible .v-progress-linear__indeterminate--active .short, .v-progress-linear--visible .v-progress-linear__stream {
  animation-play-state: running;
}

@keyframes indeterminate-ltr {
  0% {
    left: -90%;
    right: 100%;
  }

  60% {
    left: -90%;
    right: 100%;
  }

  to {
    left: 100%;
    right: -35%;
  }
}

@keyframes indeterminate-rtl {
  0% {
    left: 100%;
    right: -90%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  to {
    left: -35%;
    right: 100%;
  }
}

@keyframes indeterminate-short-ltr {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  to {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short-rtl {
  0% {
    left: 100%;
    right: -200%;
  }

  60% {
    left: -8%;
    right: 107%;
  }

  to {
    left: -8%;
    right: 107%;
  }
}

@keyframes query-ltr {
  0% {
    left: 100%;
    right: -90%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  to {
    left: -35%;
    right: 100%;
  }
}

@keyframes query-rtl {
  0% {
    left: -90%;
    right: 100%;
  }

  60% {
    left: -90%;
    right: 100%;
  }

  to {
    left: 100%;
    right: -35%;
  }
}

@keyframes query-short-ltr {
  0% {
    left: 100%;
    right: -200%;
  }

  60% {
    left: -8%;
    right: 107%;
  }

  to {
    left: -8%;
    right: 107%;
  }
}

@keyframes query-short-rtl {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  to {
    left: 107%;
    right: -8%;
  }
}

@keyframes stream-ltr {
  to {
    transform: translateX(-8px);
  }
}

@keyframes stream-rtl {
  to {
    transform: translateX(8px);
  }
}

.theme--light.v-counter {
  color: #0009;
}

.theme--dark.v-counter {
  color: #ffffffb3;
}

.v-counter {
  min-height: 12px;
  flex: 0 auto;
  font-size: 12px;
  line-height: 12px;
}

.theme--light.v-list-item--disabled {
  color: #00000061;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #000000de;
}

.theme--light.v-list-item .v-list-item__mask {
  color: #00000061;
  background: #eee;
}

.theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__action-text, .theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle {
  color: #0009;
}

.theme--light.v-list-item:hover:before {
  opacity: .04;
}

.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
  opacity: .12;
}

.theme--light.v-list-item--active:focus:before, .theme--light.v-list-item.v-list-item--highlighted:before {
  opacity: .16;
}

.theme--dark.v-list-item--disabled {
  color: #ffffff80;
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff;
}

.theme--dark.v-list-item .v-list-item__mask {
  color: #ffffff80;
  background: #494949;
}

.theme--dark.v-list-item:not(.v-list-item--disabled) .v-list-item__action-text, .theme--dark.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle {
  color: #ffffffb3;
}

.theme--dark.v-list-item:hover:before {
  opacity: .08;
}

.theme--dark.v-list-item--active:before, .theme--dark.v-list-item--active:hover:before, .theme--dark.v-list-item:focus:before {
  opacity: .24;
}

.theme--dark.v-list-item--active:focus:before, .theme--dark.v-list-item.v-list-item--highlighted:before {
  opacity: .32;
}

.v-list-item {
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  flex: 100%;
  align-items: center;
  padding: 0 16px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.v-list-item--disabled {
  pointer-events: none;
}

.v-list-item--selectable {
  -webkit-user-select: auto;
  user-select: auto;
}

.v-list-item:after {
  content: "";
  min-height: inherit;
  font-size: 0;
}

.v-list-item__action {
  align-self: center;
  margin: 12px 0;
}

.v-list-item__action .v-input, .v-list-item__action .v-input--selection-controls__input, .v-list-item__action .v-input__control, .v-list-item__action .v-input__slot {
  margin: 0 !important;
}

.v-list-item__action .v-input {
  padding: 0;
}

.v-list-item__action .v-input .v-messages {
  display: none;
}

.v-list-item__action-text {
  font-size: .75rem;
}

.v-list-item__avatar {
  justify-content: flex-start;
  align-self: center;
}

.v-list-item__avatar, .v-list-item__avatar.v-list-item__avatar--horizontal {
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-application--is-ltr .v-list-item__avatar.v-list-item__avatar--horizontal:first-child {
  margin-left: -16px;
}

.v-application--is-rtl .v-list-item__avatar.v-list-item__avatar--horizontal:first-child {
  margin-right: -16px;
}

.v-application--is-ltr .v-list-item__avatar.v-list-item__avatar--horizontal:last-child {
  margin-left: -16px;
}

.v-application--is-rtl .v-list-item__avatar.v-list-item__avatar--horizontal:last-child {
  margin-right: -16px;
}

.v-list-item__content {
  flex-wrap: wrap;
  flex: 1;
  align-self: center;
  align-items: center;
  padding: 12px 0;
  display: flex;
  overflow: hidden;
}

.v-list-item__content > * {
  flex: 1 0 100%;
  line-height: 1.1;
}

.v-list-item__content > :not(:last-child) {
  margin-bottom: 2px;
}

.v-list-item__icon {
  align-self: flex-start;
  margin: 16px 0;
}

.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 16px;
}

.v-application--is-ltr .v-list-item__avatar:first-child, .v-application--is-rtl .v-list-item__action:last-of-type:not(:only-child), .v-application--is-rtl .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-rtl .v-list-item__icon:last-of-type:not(:only-child) {
  margin-right: 16px;
}

.v-application--is-rtl .v-list-item__avatar:first-child {
  margin-left: 16px;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 32px;
}

.v-application--is-rtl .v-list-item__action:first-child, .v-application--is-rtl .v-list-item__icon:first-child {
  margin-left: 32px;
}

.v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
  min-width: 24px;
  display: inline-flex;
}

.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
  line-height: 1.2;
}

.v-list-item__subtitle, .v-list-item__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 100%;
  overflow: hidden;
}

.v-list-item__title {
  align-self: center;
  font-size: 1rem;
}

.v-list-item__title > .v-badge {
  margin-top: 16px;
}

.v-list-item__subtitle {
  font-size: .875rem;
}

.v-list--dense .v-list-item, .v-list-item--dense {
  min-height: 40px;
}

.v-list--dense .v-list-item .v-list-item__icon, .v-list-item--dense .v-list-item__icon {
  height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-list--dense .v-list-item .v-list-item__content, .v-list-item--dense .v-list-item__content {
  padding: 8px 0;
}

.v-list--dense .v-list-item .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list-item--dense .v-list-item__title {
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1rem;
}

.v-list--dense .v-list-item.v-list-item--two-line, .v-list-item--dense.v-list-item--two-line {
  min-height: 60px;
}

.v-list--dense .v-list-item.v-list-item--three-line, .v-list-item--dense.v-list-item--three-line {
  min-height: 76px;
}

.v-list-item--link {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.v-list-item--link:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  background-color: currentColor;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  inset: 0;
}

.v-list .v-list-item--active, .v-list .v-list-item--active .v-icon {
  color: inherit;
}

.v-list-item__action--stack {
  white-space: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
}

.v-list--three-line .v-list-item .v-list-item__avatar:not(.v-list-item__avatar--horizontal), .v-list--three-line .v-list-item .v-list-item__icon, .v-list--two-line .v-list-item .v-list-item__avatar:not(.v-list-item__avatar--horizontal), .v-list--two-line .v-list-item .v-list-item__icon, .v-list-item--three-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal), .v-list-item--three-line .v-list-item__icon, .v-list-item--two-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal), .v-list-item--two-line .v-list-item__icon {
  margin-top: 16px;
  margin-bottom: 16px;
}

.v-list--two-line .v-list-item, .v-list-item--two-line {
  min-height: 64px;
}

.v-list--two-line .v-list-item .v-list-item__icon, .v-list-item--two-line .v-list-item__icon {
  margin-bottom: 32px;
}

.v-list--three-line .v-list-item, .v-list-item--three-line {
  min-height: 88px;
}

.v-list--three-line .v-list-item .v-list-item__action, .v-list--three-line .v-list-item .v-list-item__avatar, .v-list-item--three-line .v-list-item__action, .v-list-item--three-line .v-list-item__avatar {
  align-self: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;
}

.v-list--three-line .v-list-item .v-list-item__content, .v-list-item--three-line .v-list-item__content {
  align-self: stretch;
}

.v-list--three-line .v-list-item .v-list-item__subtitle, .v-list-item--three-line .v-list-item__subtitle {
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.v-simple-checkbox {
  -webkit-user-select: none;
  user-select: none;
  align-self: center;
  line-height: normal;
  position: relative;
}

.v-simple-checkbox .v-icon {
  cursor: pointer;
}

.v-simple-checkbox--disabled {
  cursor: default;
}

.theme--light.v-divider {
  border-color: #0000001f;
}

.theme--dark.v-divider {
  border-color: #ffffff1f;
}

.v-divider {
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: 0 solid;
  border-top-width: thin;
  flex: 1 1 0;
  transition: inherit;
  display: block;
}

.v-divider--inset:not(.v-divider--vertical) {
  max-width: calc(100% - 72px);
}

.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 72px;
}

.v-application--is-rtl .v-divider--inset:not(.v-divider--vertical) {
  margin-right: 72px;
}

.v-divider--vertical {
  height: inherit;
  min-height: 100%;
  max-height: 100%;
  max-width: 0;
  width: 0;
  vertical-align: text-bottom;
  border: 0 solid;
  border-right-width: thin;
  align-self: stretch;
  margin: 0 -1px;
  display: inline-flex;
}

.v-divider--vertical.v-divider--inset {
  min-height: 0;
  max-height: calc(100% - 16px);
  margin-top: 8px;
}

.theme--light.v-subheader {
  color: #0009;
}

.theme--dark.v-subheader {
  color: #ffffffb3;
}

.v-subheader {
  height: 48px;
  align-items: center;
  padding: 0 16px;
  font-size: .875rem;
  font-weight: 400;
  display: flex;
}

.v-subheader--inset {
  margin-left: 56px;
}

.v-list.accent > .v-list-item, .v-list.error > .v-list-item, .v-list.info > .v-list-item, .v-list.primary > .v-list-item, .v-list.secondary > .v-list-item, .v-list.success > .v-list-item, .v-list.warning > .v-list-item {
  color: #fff;
}

.theme--light.v-list {
  color: #000000de;
  background: #fff;
}

.theme--light.v-list .v-list--disabled {
  color: #00000061;
}

.theme--light.v-list .v-list-group--active:after, .theme--light.v-list .v-list-group--active:before {
  background: #0000001f;
}

.theme--dark.v-list {
  color: #fff;
  background: #1e1e1e;
}

.theme--dark.v-list .v-list--disabled {
  color: #ffffff80;
}

.theme--dark.v-list .v-list-group--active:after, .theme--dark.v-list .v-list-group--active:before {
  background: #ffffff1f;
}

.v-sheet.v-list {
  border-radius: 0;
}

.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.v-sheet.v-list.v-sheet--shaped {
  border-radius: 0;
}

.v-list {
  padding: 8px 0;
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: static;
}

.v-list--disabled {
  pointer-events: none;
}

.v-list--flat .v-list-item:before {
  display: none;
}

.v-list--dense .v-subheader {
  height: 40px;
  padding: 0 8px;
  font-size: .75rem;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 8px;
}

.v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child), .v-list--nav .v-list-item--dense:not(:last-child):not(:only-child), .v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child) {
  margin-bottom: 4px;
}

.v-list--nav {
  padding-left: 8px;
  padding-right: 8px;
}

.v-list--nav .v-list-item {
  padding: 0 8px;
}

.v-list--nav .v-list-item, .v-list--nav .v-list-item:before {
  border-radius: 4px;
}

.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item, .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item:before, .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item > .v-ripple__container {
  border-top-right-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}

.v-application--is-rtl .v-list.v-sheet--shaped .v-list-item, .v-application--is-rtl .v-list.v-sheet--shaped .v-list-item:before, .v-application--is-rtl .v-list.v-sheet--shaped .v-list-item > .v-ripple__container {
  border-top-left-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}

.v-application--is-ltr .v-list.v-sheet--shaped.v-list--two-line .v-list-item, .v-application--is-ltr .v-list.v-sheet--shaped.v-list--two-line .v-list-item:before, .v-application--is-ltr .v-list.v-sheet--shaped.v-list--two-line .v-list-item > .v-ripple__container {
  border-top-right-radius: 42.6667px !important;
  border-bottom-right-radius: 42.6667px !important;
}

.v-application--is-rtl .v-list.v-sheet--shaped.v-list--two-line .v-list-item, .v-application--is-rtl .v-list.v-sheet--shaped.v-list--two-line .v-list-item:before, .v-application--is-rtl .v-list.v-sheet--shaped.v-list--two-line .v-list-item > .v-ripple__container {
  border-top-left-radius: 42.6667px !important;
  border-bottom-left-radius: 42.6667px !important;
}

.v-application--is-ltr .v-list.v-sheet--shaped.v-list--three-line .v-list-item, .v-application--is-ltr .v-list.v-sheet--shaped.v-list--three-line .v-list-item:before, .v-application--is-ltr .v-list.v-sheet--shaped.v-list--three-line .v-list-item > .v-ripple__container {
  border-top-right-radius: 58.6667px !important;
  border-bottom-right-radius: 58.6667px !important;
}

.v-application--is-rtl .v-list.v-sheet--shaped.v-list--three-line .v-list-item, .v-application--is-rtl .v-list.v-sheet--shaped.v-list--three-line .v-list-item:before, .v-application--is-rtl .v-list.v-sheet--shaped.v-list--three-line .v-list-item > .v-ripple__container {
  border-top-left-radius: 58.6667px !important;
  border-bottom-left-radius: 58.6667px !important;
}

.v-application--is-ltr .v-list.v-sheet--shaped {
  padding-right: 8px;
}

.v-application--is-rtl .v-list.v-sheet--shaped {
  padding-left: 8px;
}

.v-list--rounded {
  padding: 8px;
}

.v-list--rounded .v-list-item, .v-list--rounded .v-list-item:before, .v-list--rounded .v-list-item > .v-ripple__container {
  border-radius: 32px !important;
}

.v-list--rounded.v-list--two-line .v-list-item, .v-list--rounded.v-list--two-line .v-list-item:before, .v-list--rounded.v-list--two-line .v-list-item > .v-ripple__container {
  border-radius: 42.6667px !important;
}

.v-list--rounded.v-list--three-line .v-list-item, .v-list--rounded.v-list--three-line .v-list-item:before, .v-list--rounded.v-list--three-line .v-list-item > .v-ripple__container {
  border-radius: 58.6667px !important;
}

.v-list--subheader {
  padding-top: 0;
}

.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 48px;
  justify-content: flex-end;
  align-self: center;
  margin: 0;
}

.v-list-group--sub-group {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.v-list-group__header.v-list-item--active:not(:hover):not(:focus):before {
  opacity: 0;
}

.v-list-group__items {
  flex: auto;
}

.v-list-group__items .v-list-group__items, .v-list-group__items .v-list-item {
  overflow: hidden;
}

.v-list-group--active > .v-list-group__header.v-list-group__header--sub-group > .v-list-group__header__prepend-icon .v-icon, .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
  transform: rotate(-180deg);
}

.v-list-group--active > .v-list-group__header .v-list-group__header__prepend-icon .v-icon, .v-list-group--active > .v-list-group__header .v-list-item, .v-list-group--active > .v-list-group__header .v-list-item__content {
  color: inherit;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-item__action:first-child, .v-application--is-ltr .v-list-group--sub-group .v-list-item__avatar:first-child, .v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
  margin-right: 16px;
}

.v-application--is-rtl .v-list-group--sub-group .v-list-item__action:first-child, .v-application--is-rtl .v-list-group--sub-group .v-list-item__avatar:first-child, .v-application--is-rtl .v-list-group--sub-group .v-list-item__icon:first-child {
  margin-left: 16px;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 32px;
}

.v-application--is-rtl .v-list-group--sub-group .v-list-group__header {
  padding-right: 32px;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-group__items .v-list-item {
  padding-left: 40px;
}

.v-application--is-rtl .v-list-group--sub-group .v-list-group__items .v-list-item {
  padding-right: 40px;
}

.v-list-group--sub-group.v-list-group--active .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
  transform: rotate(-180deg);
}

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 72px;
}

.v-application--is-rtl .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-right: 72px;
}

.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 88px;
}

.v-application--is-rtl .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-right: 88px;
}

.v-application--is-ltr .v-list--dense .v-list-group--sub-group .v-list-group__header {
  padding-left: 24px;
}

.v-application--is-rtl .v-list--dense .v-list-group--sub-group .v-list-group__header {
  padding-right: 24px;
}

.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 64px;
}

.v-application--is-rtl .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-right: 64px;
}

.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 80px;
}

.v-application--is-rtl .v-list--dense.v-list--nav .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-right: 80px;
}

.v-avatar {
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  line-height: normal;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.v-avatar .v-icon, .v-avatar .v-image, .v-avatar .v-responsive__content, .v-avatar img, .v-avatar svg {
  border-radius: inherit;
  height: inherit;
  width: inherit;
  display: inline-flex;
}

.v-list-item-group .v-list-item--active {
  color: inherit;
}

.v-item-group {
  max-width: 100%;
  flex: 0 auto;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: relative;
}

.v-chip:not(.v-chip--outlined).accent, .v-chip:not(.v-chip--outlined).error, .v-chip:not(.v-chip--outlined).info, .v-chip:not(.v-chip--outlined).primary, .v-chip:not(.v-chip--outlined).secondary, .v-chip:not(.v-chip--outlined).success, .v-chip:not(.v-chip--outlined).warning {
  color: #fff;
}

.theme--light.v-chip {
  color: #000000de;
  border-color: #0000001f;
}

.theme--light.v-chip:not(.v-chip--active) {
  background: #e0e0e0;
}

.theme--light.v-chip:hover:before {
  opacity: .04;
}

.theme--light.v-chip--active:before, .theme--light.v-chip--active:hover:before, .theme--light.v-chip:focus:before {
  opacity: .12;
}

.theme--light.v-chip--active:focus:before {
  opacity: .16;
}

.theme--dark.v-chip {
  color: #fff;
  border-color: #ffffff1f;
}

.theme--dark.v-chip:not(.v-chip--active) {
  background: #555;
}

.theme--dark.v-chip:hover:before {
  opacity: .08;
}

.theme--dark.v-chip--active:before, .theme--dark.v-chip--active:hover:before, .theme--dark.v-chip:focus:before {
  opacity: .24;
}

.theme--dark.v-chip--active:focus:before {
  opacity: .32;
}

.v-chip {
  cursor: default;
  max-width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;
  align-items: center;
  padding: 0 12px;
  line-height: 20px;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.v-chip:before {
  border-radius: inherit;
  content: "";
  opacity: 0;
  pointer-events: none;
  background-color: currentColor;
  position: absolute;
  inset: 0;
}

.v-chip .v-avatar {
  height: 24px !important;
  min-width: 24px !important;
  width: 24px !important;
}

.v-chip .v-icon {
  font-size: 24px;
}

.v-application--is-ltr .v-chip .v-avatar--left, .v-application--is-ltr .v-chip .v-icon--left {
  margin-left: -6px;
  margin-right: 6px;
}

.v-application--is-ltr .v-chip .v-avatar--right, .v-application--is-ltr .v-chip .v-icon--right, .v-application--is-rtl .v-chip .v-avatar--left, .v-application--is-rtl .v-chip .v-icon--left {
  margin-left: 6px;
  margin-right: -6px;
}

.v-application--is-rtl .v-chip .v-avatar--right, .v-application--is-rtl .v-chip .v-icon--right {
  margin-left: -6px;
  margin-right: 6px;
}

.v-chip:not(.v-chip--no-color) .v-icon {
  color: inherit;
}

.v-chip .v-chip__close.v-icon {
  max-height: 18px;
  max-width: 18px;
  -webkit-user-select: none;
  user-select: none;
  font-size: 18px;
}

.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
  margin-right: -4px;
}

.v-application--is-rtl .v-chip .v-chip__close.v-icon.v-icon--right {
  margin-left: -4px;
}

.v-chip .v-chip__close.v-icon:active, .v-chip .v-chip__close.v-icon:focus, .v-chip .v-chip__close.v-icon:hover {
  opacity: .72;
}

.v-chip .v-chip__content {
  height: 100%;
  max-width: 100%;
  align-items: center;
  display: inline-flex;
}

.v-chip--active .v-icon {
  color: inherit;
}

.v-chip--link:before {
  transition: opacity .3s cubic-bezier(.25, .8, .5, 1);
}

.v-chip--link:focus:before {
  opacity: .32;
}

.v-chip--clickable {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.v-chip--clickable:active {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-chip--disabled {
  opacity: .4;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.v-chip__filter {
  max-width: 24px;
}

.v-chip__filter.v-icon {
  color: inherit;
}

.v-chip__filter.expand-x-transition-enter, .v-chip__filter.expand-x-transition-leave-active {
  margin: 0;
}

.v-chip--pill .v-chip__filter {
  margin: 0 16px 0 0;
}

.v-chip--pill .v-avatar {
  height: 32px !important;
  width: 32px !important;
}

.v-application--is-ltr .v-chip--pill .v-avatar--left {
  margin-left: -12px;
}

.v-application--is-ltr .v-chip--pill .v-avatar--right, .v-application--is-rtl .v-chip--pill .v-avatar--left {
  margin-right: -12px;
}

.v-application--is-rtl .v-chip--pill .v-avatar--right {
  margin-left: -12px;
}

.v-chip--label {
  border-radius: 4px !important;
}

.v-chip.v-chip--outlined {
  border-style: solid;
  border-width: thin;
}

.v-chip.v-chip--outlined.v-chip--active:before {
  opacity: .08;
}

.v-chip.v-chip--outlined .v-icon {
  color: inherit;
}

.v-chip.v-chip--outlined.v-chip.v-chip {
  background-color: #0000 !important;
}

.v-chip.v-chip--selected {
  background: none;
}

.v-chip.v-chip--selected:after {
  opacity: .28;
}

.v-chip.v-size--x-small {
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
}

.v-chip.v-size--small {
  height: 24px;
  border-radius: 12px;
  font-size: 12px;
}

.v-chip.v-size--default {
  height: 32px;
  border-radius: 16px;
  font-size: 14px;
}

.v-chip.v-size--large {
  height: 54px;
  border-radius: 27px;
  font-size: 16px;
}

.v-chip.v-size--x-large {
  height: 66px;
  border-radius: 33px;
  font-size: 18px;
}

.v-menu {
  display: none;
}

.v-menu--attached {
  display: inline;
}

.v-menu__content {
  max-width: 80%;
  contain: content;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

.v-menu__content--active {
  pointer-events: none;
}

.v-menu__content--auto .v-list-item {
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.25, .8, .25, 1);
}

.v-menu__content--fixed {
  position: fixed;
}

.v-menu__content > .card {
  contain: content;
  backface-visibility: hidden;
}

.v-menu > .v-menu__content {
  max-width: none;
}

.v-menu-transition-enter .v-list-item {
  min-width: 0;
  pointer-events: none;
}

.v-menu-transition-enter-to .v-list-item {
  transition-delay: .1s;
}

.v-menu-transition-leave-active, .v-menu-transition-leave-to {
  pointer-events: none;
}

.v-menu-transition-enter, .v-menu-transition-leave-to {
  opacity: 0;
}

.v-menu-transition-enter-active, .v-menu-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.v-menu-transition-enter.v-menu__content--auto {
  transition: none !important;
}

.v-menu-transition-enter.v-menu__content--auto .v-list-item {
  opacity: 0;
  transform: translateY(-15px);
}

.v-menu-transition-enter.v-menu__content--auto .v-list-item--active {
  opacity: 1;
  pointer-events: auto;
  transform: none !important;
}

.theme--light.v-badge .v-badge__badge:after {
  border-color: #fff;
}

.theme--dark.v-badge .v-badge__badge:after {
  border-color: #1e1e1e;
}

.v-badge {
  position: relative;
}

.v-badge, .v-badge__badge {
  line-height: 1;
  display: inline-block;
}

.v-badge__badge {
  color: #fff;
  height: 20px;
  letter-spacing: 0;
  min-width: 20px;
  pointer-events: auto;
  text-align: center;
  text-indent: 0;
  white-space: nowrap;
  border-radius: 10px;
  padding: 4px 6px;
  font-size: 12px;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  top: auto;
}

.v-application--is-ltr .v-badge__badge {
  right: auto;
}

.v-application--is-rtl .v-badge__badge {
  left: auto;
}

.v-badge__badge .v-icon {
  color: inherit;
  height: 12px;
  width: 12px;
  margin: 0 -2px;
  font-size: 12px;
}

.v-badge__badge .v-img {
  height: 12px;
  width: 12px;
}

.v-badge__wrapper {
  height: 100%;
  pointer-events: none;
  width: 100%;
  flex: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.v-badge--avatar .v-badge__badge {
  padding: 0;
}

.v-badge--avatar .v-badge__badge .v-avatar {
  height: 20px !important;
  min-width: 0 !important;
  max-width: 20px !important;
}

.v-badge--bordered .v-badge__badge:after {
  border-radius: inherit;
  content: "";
  border-style: solid;
  border-width: 2px;
  position: absolute;
  inset: 0;
  transform: scale(1.15);
}

.v-badge--dot .v-badge__badge {
  height: 9px;
  min-width: 0;
  width: 9px;
  border-radius: 4.5px;
  padding: 0;
}

.v-badge--dot .v-badge__badge:after {
  border-width: 1.5px;
}

.v-badge--icon .v-badge__badge {
  padding: 4px 6px;
}

.v-badge--inline {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.v-badge--inline .v-badge__badge, .v-badge--inline .v-badge__wrapper {
  position: relative;
}

.v-badge--inline .v-badge__wrapper {
  margin: 0 4px;
}

.v-badge--tile .v-badge__badge {
  border-radius: 0;
}

.theme--light.v-banner.v-sheet {
  background-color: #0000;
}

.theme--light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper {
  border-bottom: thin solid #0000001f;
}

.theme--dark.v-banner.v-sheet {
  background-color: #0000;
}

.theme--dark.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper {
  border-bottom: thin solid #ffffff1f;
}

.v-sheet.v-banner {
  border-radius: 0;
}

.v-sheet.v-banner:not(.v-sheet--outlined) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.v-sheet.v-banner.v-sheet--shaped {
  border-radius: 24px 0;
}

.v-banner {
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

.v-banner__actions {
  flex: 1 0 auto;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: center;
  margin-bottom: -8px;
  display: flex;
}

.v-application--is-ltr .v-banner__actions {
  margin-left: 90px;
}

.v-application--is-rtl .v-banner__actions {
  margin-right: 90px;
}

.v-application--is-ltr .v-banner__actions > * {
  margin-left: 8px;
}

.v-application--is-rtl .v-banner__actions > * {
  margin-right: 8px;
}

.v-banner__content {
  flex: auto;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.v-banner__text {
  max-width: 100%;
  flex: auto;
  line-height: 20px;
}

.v-banner__icon {
  flex: none;
  display: inline-flex;
}

.v-application--is-ltr .v-banner__icon {
  margin-right: 24px;
}

.v-application--is-rtl .v-banner__icon {
  margin-left: 24px;
}

.v-banner__wrapper {
  flex: auto;
  align-items: center;
  display: flex;
}

.v-application--is-ltr .v-banner__wrapper {
  padding: 16px 8px 16px 24px;
}

.v-application--is-rtl .v-banner__wrapper {
  padding: 16px 24px 16px 8px;
}

.v-banner--single-line .v-banner__actions {
  align-self: center;
  margin-bottom: 0;
}

.v-banner--single-line .v-banner__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.v-banner--single-line .v-banner__wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
}

.v-application--is-ltr .v-banner--has-icon .v-banner__wrapper {
  padding-left: 16px;
}

.v-application--is-rtl .v-banner--has-icon .v-banner__wrapper {
  padding-right: 16px;
}

.v-banner--is-mobile .v-banner__actions {
  flex: 1 0 100%;
  margin-left: 0;
  margin-right: 0;
  padding-top: 12px;
}

.v-banner--is-mobile .v-banner__wrapper {
  flex-wrap: wrap;
  padding-top: 16px;
}

.v-application--is-ltr .v-banner--is-mobile .v-banner__wrapper {
  padding-left: 16px;
}

.v-application--is-rtl .v-banner--is-mobile .v-banner__wrapper {
  padding-right: 16px;
}

.v-banner--is-mobile.v-banner--has-icon .v-banner__wrapper {
  padding-top: 24px;
}

.v-banner--is-mobile.v-banner--single-line .v-banner__actions {
  flex: initial;
  padding-top: 0;
}

.v-application--is-ltr .v-banner--is-mobile.v-banner--single-line .v-banner__actions {
  margin-left: 36px;
}

.v-application--is-rtl .v-banner--is-mobile.v-banner--single-line .v-banner__actions {
  margin-right: 36px;
}

.v-banner--is-mobile.v-banner--single-line .v-banner__wrapper {
  flex-wrap: nowrap;
  padding-top: 10px;
}

.v-application--is-ltr .v-banner--is-mobile .v-banner__icon {
  margin-right: 16px;
}

.v-application--is-rtl .v-banner--is-mobile .v-banner__icon {
  margin-left: 16px;
}

.v-application--is-ltr .v-banner--is-mobile .v-banner__content {
  padding-right: 8px;
}

.v-application--is-rtl .v-banner--is-mobile .v-banner__content {
  padding-left: 8px;
}

.v-banner--is-mobile .v-banner__content .v-banner__wrapper {
  flex-wrap: nowrap;
  padding-top: 10px;
}

.theme--light.v-bottom-navigation {
  color: #000000de;
  background-color: #fff;
}

.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: #0009 !important;
}

.theme--dark.v-bottom-navigation {
  color: #fff;
  background-color: #2e2e2e;
}

.theme--dark.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: #ffffffb3 !important;
}

.v-item-group.v-bottom-navigation {
  width: 100%;
  justify-content: center;
  display: flex;
  bottom: 0;
  left: 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.v-item-group.v-bottom-navigation .v-btn {
  box-shadow: none;
  height: inherit;
  max-width: 168px;
  min-width: 80px;
  text-transform: none;
  background-color: #0000;
  border-radius: 0;
  flex: 0 auto;
  font-size: .75rem;
  position: relative;
}

.v-item-group.v-bottom-navigation .v-btn:after {
  content: none;
}

.v-item-group.v-bottom-navigation .v-btn .v-btn__content {
  height: inherit;
  flex-direction: column-reverse;
}

.v-item-group.v-bottom-navigation .v-btn .v-btn__content > :not(.v-icon) {
  line-height: 1.2;
}

.v-item-group.v-bottom-navigation .v-btn.v-btn--active {
  color: inherit;
}

.v-item-group.v-bottom-navigation .v-btn.v-btn--active:not(:hover):before {
  opacity: 0;
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4;
}

.v-item-group.v-bottom-navigation--absolute {
  position: absolute;
}

.v-item-group.v-bottom-navigation--active {
  transform: translate(0);
}

.v-item-group.v-bottom-navigation--fixed {
  position: fixed;
}

.v-item-group.v-bottom-navigation--grow .v-btn {
  width: 100%;
}

.v-item-group.v-bottom-navigation--horizontal .v-btn > .v-btn__content {
  flex-direction: row-reverse;
}

.v-item-group.v-bottom-navigation--horizontal .v-btn > .v-btn__content > .v-icon {
  margin-bottom: 0;
  margin-right: 16px;
}

.v-item-group.v-bottom-navigation--shift .v-btn .v-btn__content > :not(.v-icon) {
  opacity: 0;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  top: calc(100% - 12px);
  transform: scale(.9);
}

.v-item-group.v-bottom-navigation--shift .v-btn--active .v-btn__content > .v-icon {
  transform: translateY(-8px);
}

.v-item-group.v-bottom-navigation--shift .v-btn--active .v-btn__content > :not(.v-icon) {
  opacity: 1;
  top: calc(100% - 22px);
  transform: scale(1);
}

.bottom-sheet-transition-enter, .bottom-sheet-transition-leave-to {
  transform: translateY(100%);
}

.v-bottom-sheet.v-dialog {
  border-radius: 0;
  flex: 0 auto;
  align-self: flex-end;
  margin: 0;
  overflow: visible;
}

.v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
  max-width: 70%;
}

@media only screen and (max-width: 599.98px) {
  .v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
    max-width: none;
  }
}

.v-dialog {
  pointer-events: auto;
  width: 100%;
  z-index: inherit;
  border-radius: 4px;
  outline: none;
  margin: 24px;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  overflow-y: auto;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90%;
}

.v-dialog > * {
  width: 100%;
}

.v-dialog > .v-card > .v-card__title {
  letter-spacing: .0125em;
  padding: 16px 24px 10px;
  font-size: 1.25rem;
  font-weight: 500;
}

.v-dialog > .v-card > .v-card__subtitle, .v-dialog > .v-card > .v-card__text {
  padding: 0 24px 20px;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}

.v-dialog__content {
  height: 100%;
  pointer-events: none;
  width: 100%;
  z-index: 6;
  justify-content: center;
  align-items: center;
  transition: all .2s cubic-bezier(.25, .8, .25, 1), z-index 1ms;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.v-dialog__container {
  display: none;
}

.v-dialog__container--attached {
  display: inline;
}

.v-dialog--animated {
  animation-name: animate-dialog;
  animation-duration: .15s;
  animation-timing-function: cubic-bezier(.25, .8, .25, 1);
}

.v-dialog--fullscreen {
  height: 100%;
  border-radius: 0;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.v-dialog--fullscreen > .v-card {
  min-height: 100%;
  min-width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.v-dialog--scrollable, .v-dialog--scrollable > form {
  display: flex;
}

.v-dialog--scrollable > .v-card, .v-dialog--scrollable > form > .v-card {
  max-height: 100%;
  max-width: 100%;
  flex-direction: column;
  flex: 100%;
  display: flex;
}

.v-dialog--scrollable > .v-card > .v-card__actions, .v-dialog--scrollable > .v-card > .v-card__title, .v-dialog--scrollable > form > .v-card > .v-card__actions, .v-dialog--scrollable > form > .v-card > .v-card__title {
  flex: none;
}

.v-dialog--scrollable > .v-card > .v-card__text, .v-dialog--scrollable > form > .v-card > .v-card__text {
  backface-visibility: hidden;
  flex: auto;
  overflow-y: auto;
}

@keyframes animate-dialog {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.03);
  }

  to {
    transform: scale(1);
  }
}

.theme--light.v-overlay {
  color: #000000de;
}

.theme--dark.v-overlay {
  color: #fff;
}

.v-overlay {
  border-radius: inherit;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  transition: all .3s cubic-bezier(.25, .8, .5, 1), z-index 1ms;
  display: flex;
  position: fixed;
  inset: 0;
}

.v-overlay__content {
  position: relative;
}

.v-overlay__scrim {
  border-radius: inherit;
  height: 100%;
  width: 100%;
  will-change: opacity;
  transition: inherit;
  position: absolute;
  inset: 0;
}

.v-overlay--absolute {
  position: absolute;
}

.v-overlay--active {
  pointer-events: auto;
}

.theme--light.v-breadcrumbs .v-breadcrumbs__divider, .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color: #00000061;
}

.theme--dark.v-breadcrumbs .v-breadcrumbs__divider, .theme--dark.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color: #ffffff80;
}

.v-breadcrumbs {
  flex-wrap: wrap;
  flex: 0 auto;
  align-items: center;
  margin: 0;
  padding: 18px 12px;
  list-style-type: none;
  display: flex;
}

.v-breadcrumbs li {
  align-items: center;
  font-size: 14px;
  display: inline-flex;
}

.v-breadcrumbs li .v-icon {
  font-size: 16px;
}

.v-breadcrumbs li:nth-child(2n) {
  padding: 0 12px;
}

.v-breadcrumbs__item {
  align-items: center;
  text-decoration: none;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  display: inline-flex;
}

.v-breadcrumbs__item--disabled {
  pointer-events: none;
}

.v-breadcrumbs--large li, .v-breadcrumbs--large li .v-icon {
  font-size: 16px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  color: #000000de;
  background: #fff;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #0000001f !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:focus:not(:active) {
  border-color: #00000042;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon {
  color: #000;
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  color: #fff;
  background: #1e1e1e;
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #ffffff1f !important;
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:focus:not(:active) {
  border-color: #ffffff4d;
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon {
  color: #fff;
}

.v-btn-toggle {
  max-width: 100%;
  border-radius: 4px;
  display: inline-flex;
}

.v-btn-toggle > .v-btn.v-btn {
  box-shadow: none;
  opacity: .8;
  border-style: solid;
  border-width: thin;
  border-radius: 0;
  padding: 0 12px;
}

.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:last-child, .v-application--is-rtl .v-btn-toggle > .v-btn.v-btn:first-child {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.v-application--is-rtl .v-btn-toggle > .v-btn.v-btn:last-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.v-btn-toggle > .v-btn.v-btn--active {
  color: inherit;
  opacity: 1;
}

.v-btn-toggle > .v-btn.v-btn:after {
  display: none;
}

.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:not(:first-child), .v-application--is-rtl .v-btn-toggle > .v-btn.v-btn:not(:last-child) {
  border-left-width: 0;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 48px;
  min-height: 0;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 48px;
}

.v-btn-toggle--borderless > .v-btn.v-btn {
  border-width: 0;
}

.v-btn-toggle--dense > .v-btn.v-btn {
  padding: 0 8px;
}

.v-btn-toggle--group {
  border-radius: 0;
}

.v-btn-toggle--group > .v-btn.v-btn {
  min-width: auto;
  border-color: #0000;
  margin: 4px;
  background-color: #0000 !important;
}

.v-btn-toggle--rounded {
  border-radius: 24px;
}

.v-btn-toggle--shaped {
  border-radius: 24px 4px;
}

.v-btn-toggle--tile {
  border-radius: 0;
}

.theme--light.v-calendar-events .v-event-timed {
  border: 1px solid !important;
}

.theme--light.v-calendar-events .v-event-more {
  background-color: #fff;
}

.theme--light.v-calendar-events .v-event-more.v-outside {
  background-color: #f7f7f7;
}

.theme--dark.v-calendar-events .v-event-timed {
  border: 1px solid !important;
}

.theme--dark.v-calendar-events .v-event-more {
  background-color: #303030;
}

.theme--dark.v-calendar-events .v-event-more.v-outside {
  background-color: #202020;
}

.v-calendar .v-event {
  border-radius: 4px;
  margin-right: -1px;
  line-height: 20px;
}

.v-calendar .v-event, .v-calendar .v-event-more {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}

.v-calendar .v-event-more {
  font-weight: 700;
}

.v-calendar .v-event-timed-container {
  pointer-events: none;
  margin-right: 10px;
  position: absolute;
  inset: 0;
}

.v-calendar .v-event-timed {
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  pointer-events: all;
  border-radius: 4px;
  font-size: 12px;
  position: absolute;
}

.v-calendar .v-event-summary {
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}

.v-calendar.v-calendar-events .v-calendar-weekly__head-weekday {
  margin-right: -1px;
}

.v-calendar.v-calendar-events .v-calendar-weekly__day {
  margin-right: -1px;
  overflow: visible;
}

.theme--light.v-calendar-weekly {
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}

.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
  color: #000;
  border-right: 1px solid #e0e0e0;
}

.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: #00000061;
}

.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: #f7f7f7;
}

.theme--light.v-calendar-weekly .v-calendar-weekly__head-weeknumber {
  background-color: #f1f3f4;
  border-right: 1px solid #e0e0e0;
}

.theme--light.v-calendar-weekly .v-calendar-weekly__day {
  color: #000;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside {
  background-color: #f7f7f7;
}

.theme--light.v-calendar-weekly .v-calendar-weekly__weeknumber {
  color: #000;
  background-color: #f1f3f4;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.theme--dark.v-calendar-weekly {
  background-color: #303030;
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday {
  color: #fff;
  border-right: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: #ffffff80;
}

.theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: #202020;
}

.theme--dark.v-calendar-weekly .v-calendar-weekly__head-weeknumber {
  background-color: #202020;
  border-right: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-weekly .v-calendar-weekly__day {
  color: #fff;
  border-bottom: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-weekly .v-calendar-weekly__day.v-outside {
  background-color: #202020;
}

.theme--dark.v-calendar-weekly .v-calendar-weekly__weeknumber {
  color: #fff;
  background-color: #202020;
  border-bottom: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
}

.v-calendar-weekly {
  width: 100%;
  height: 100%;
  min-height: 0;
  flex-direction: column;
  display: flex;
}

.v-calendar-weekly__head {
  display: flex;
}

.v-calendar-weekly__head, .v-calendar-weekly__head-weekday {
  -webkit-user-select: none;
  user-select: none;
}

.v-calendar-weekly__head-weekday {
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  flex: 1 0 20px;
  padding: 0 4px;
  font-size: 11px;
  overflow: hidden;
}

.v-calendar-weekly__head-weeknumber {
  flex: 0 0 24px;
  position: relative;
}

.v-calendar-weekly__week {
  height: unset;
  min-height: 0;
  flex: 1;
  display: flex;
}

.v-calendar-weekly__weeknumber {
  height: unset;
  min-height: 0;
  text-align: center;
  flex: 0 0 24px;
  padding-top: 14.5px;
  display: flex;
}

.v-calendar-weekly__weeknumber > small {
  width: 100% !important;
}

.v-calendar-weekly__day {
  width: 0;
  -webkit-user-select: none;
  user-select: none;
  min-width: 0;
  flex: 1;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.v-calendar-weekly__day.v-present .v-calendar-weekly__day-month {
  color: currentColor;
}

.v-calendar-weekly__day-label {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  box-shadow: none;
  text-align: center;
  margin: 4px 0 0;
  text-decoration: none;
}

.v-calendar-weekly__day-label .v-btn {
  text-transform: none;
  font-size: 12px;
}

.v-calendar-weekly__day-month {
  -webkit-user-select: none;
  user-select: none;
  box-shadow: none;
  height: 32px;
  line-height: 32px;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 36px;
}

.theme--light.v-calendar-daily {
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-head {
  border-right: 1px solid #e0e0e0;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-head:after {
  background: linear-gradient(90deg, #0000, #e0e0e0);
}

.theme--light.v-calendar-daily .v-calendar-daily_head-day {
  color: #000;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label, .theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday {
  color: #00000061;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
  border-right: 1px solid #e0e0e0;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {
  color: #424242;
}

.theme--light.v-calendar-daily .v-calendar-daily__day {
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.theme--light.v-calendar-daily .v-calendar-daily__day-interval {
  border-top: 1px solid #e0e0e0;
}

.theme--light.v-calendar-daily .v-calendar-daily__day-interval:first-child {
  border-top: none !important;
}

.theme--light.v-calendar-daily .v-calendar-daily__interval:after {
  border-top: 1px solid #e0e0e0;
}

.theme--dark.v-calendar-daily {
  background-color: #303030;
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-daily .v-calendar-daily__intervals-head {
  border-right: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-daily .v-calendar-daily__intervals-head:after {
  background: linear-gradient(90deg, #0000, #9e9e9e);
}

.theme--dark.v-calendar-daily .v-calendar-daily_head-day {
  color: #fff;
  border-bottom: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label, .theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday {
  color: #ffffff80;
}

.theme--dark.v-calendar-daily .v-calendar-daily__intervals-body {
  border-right: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {
  color: #eee;
}

.theme--dark.v-calendar-daily .v-calendar-daily__day {
  border-bottom: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-daily .v-calendar-daily__day-interval {
  border-top: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-daily .v-calendar-daily__day-interval:first-child {
  border-top: none !important;
}

.theme--dark.v-calendar-daily .v-calendar-daily__interval:after {
  border-top: 1px solid #9e9e9e;
}

.v-calendar-daily {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.v-calendar-daily__head {
  flex: none;
  display: flex;
}

.v-calendar-daily__intervals-head {
  flex: none;
  position: relative;
}

.v-calendar-daily__intervals-head:after {
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.v-calendar-daily_head-day {
  width: 0;
  flex: auto;
  position: relative;
}

.v-calendar-daily_head-weekday {
  text-transform: uppercase;
  padding: 3px 0 0;
  font-size: 11px;
}

.v-calendar-daily_head-day-label, .v-calendar-daily_head-weekday {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
}

.v-calendar-daily_head-day-label {
  cursor: pointer;
  padding: 0 0 3px;
}

.v-calendar-daily__body {
  flex-direction: column;
  flex: 60%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.v-calendar-daily__scroll-area {
  flex: auto;
  align-items: flex-start;
  display: flex;
  overflow-y: scroll;
}

.v-calendar-daily__pane {
  width: 100%;
  flex: none;
  align-items: flex-start;
  display: flex;
  overflow-y: hidden;
}

.v-calendar-daily__day-container {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
}

.v-calendar-daily__intervals-body {
  -webkit-user-select: none;
  user-select: none;
  flex: none;
}

.v-calendar-daily__interval {
  text-align: right;
  border-bottom: none;
  padding-right: 8px;
  position: relative;
}

.v-calendar-daily__interval:after {
  width: 8px;
  height: 1px;
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  right: 0;
}

.v-calendar-daily__interval-text {
  padding-right: 4px;
  font-size: 10px;
  display: block;
  position: relative;
  top: -6px;
}

.v-calendar-daily__day {
  width: 0;
  flex: 1;
  position: relative;
}

.theme--light.v-calendar-category .v-calendar-category__column, .theme--light.v-calendar-category .v-calendar-category__column-header {
  border-right: 1px solid #e0e0e0;
}

.theme--light.v-calendar-category .v-calendar-daily__head, .theme--light.v-calendar-category .v-calendar-daily__intervals-body, .theme--light.v-calendar-category .v-calendar-daily__intervals-head {
  background: #fff;
}

.theme--dark.v-calendar-category .v-calendar-category__column, .theme--dark.v-calendar-category .v-calendar-category__column-header {
  border-right: 1px solid #9e9e9e;
}

.theme--dark.v-calendar-category .v-calendar-daily__head, .theme--dark.v-calendar-category .v-calendar-daily__intervals-body, .theme--dark.v-calendar-category .v-calendar-daily__intervals-head {
  background: #303030;
}

.v-calendar-category {
  position: relative;
  overflow: auto;
}

.v-calendar-category .v-calendar-category__category {
  text-align: center;
}

.v-calendar-category .v-calendar-daily__day-container {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.v-calendar-category .v-calendar-daily__day-container .v-calendar-category__columns {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.v-calendar-category .v-calendar-daily__day-body {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
}

.v-calendar-category .v-calendar-daily__head {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  z-index: 2;
  flex-direction: row;
  position: sticky;
  top: 0;
}

.v-calendar-category .v-calendar-daily_head-day {
  width: auto;
  position: unset;
}

.v-calendar-category .v-calendar-daily__intervals-head {
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
}

.v-calendar-category .v-calendar-daily_head-weekday {
  width: 50px;
  position: sticky;
  left: 50%;
}

.v-calendar-category .v-calendar-daily_head-day-label {
  width: 56px;
  position: sticky;
  left: 50%;
}

.v-calendar-category .v-calendar-daily__day {
  min-width: 200px;
}

.v-calendar-category .v-calendar-daily__intervals-body {
  z-index: 1;
  position: sticky;
  left: 0;
}

.v-calendar-category .v-calendar-daily__interval:last-of-type:after {
  display: none;
}

.v-calendar-category .v-calendar-daily__body {
  overflow: visible;
}

.v-calendar-category .v-calendar-daily__body .v-calendar-daily__scroll-area {
  flex: none;
  overflow-y: visible;
}

.v-calendar-category .v-calendar-daily__pane {
  overflow-y: visible;
}

.v-calendar-category .v-calendar-category__columns {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  display: flex;
}

.v-calendar-category .v-calendar-category__columns .v-calendar-category__column, .v-calendar-category .v-calendar-category__columns .v-calendar-category__column-header {
  width: 0;
  flex: auto;
  position: relative;
}

.v-calendar-category .v-calendar-category__columns .v-calendar-category__column-header {
  min-width: 200px;
}

.theme--light.v-card {
  color: #000000de;
  background-color: #fff;
}

.theme--light.v-card > .v-card__subtitle, .theme--light.v-card > .v-card__text {
  color: #0009;
}

.theme--dark.v-card {
  color: #fff;
  background-color: #1e1e1e;
}

.theme--dark.v-card > .v-card__subtitle, .theme--dark.v-card > .v-card__text {
  color: #ffffffb3;
}

.v-sheet.v-card {
  border-radius: 4px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-sheet.v-card.v-sheet--shaped {
  border-radius: 24px 4px;
}

.v-card {
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: normal;
  border-width: thin;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  display: block;
  position: relative;
}

.v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.v-card > :last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.v-card__progress {
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.v-card__subtitle + .v-card__text {
  padding-top: 0;
}

.v-card__subtitle, .v-card__text {
  letter-spacing: .00714286em;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.v-card__subtitle, .v-card__text, .v-card__title {
  padding: 16px;
}

.v-card__title {
  letter-spacing: .0125em;
  word-break: break-all;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  display: flex;
}

.v-card__title + .v-card__subtitle, .v-card__title + .v-card__text {
  padding-top: 0;
}

.v-card__title + .v-card__subtitle {
  margin-top: -16px;
}

.v-card__text {
  width: 100%;
}

.v-card__actions {
  align-items: center;
  padding: 8px;
  display: flex;
}

.v-card__actions > .v-btn.v-btn {
  padding: 0 8px;
}

.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 8px;
}

.v-application--is-ltr .v-card__actions > .v-btn.v-btn .v-icon--left {
  margin-left: 4px;
}

.v-application--is-ltr .v-card__actions > .v-btn.v-btn .v-icon--right {
  margin-right: 4px;
}

.v-application--is-rtl .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-right: 8px;
}

.v-application--is-rtl .v-card__actions > .v-btn.v-btn .v-icon--left {
  margin-right: 4px;
}

.v-application--is-rtl .v-card__actions > .v-btn.v-btn .v-icon--right {
  margin-left: 4px;
}

.v-card--flat {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f !important;
}

.v-sheet.v-card--hover {
  cursor: pointer;
  transition: box-shadow .4s cubic-bezier(.25, .8, .25, 1);
}

.v-sheet.v-card--hover:focus, .v-sheet.v-card--hover:hover {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

.v-card--link, .v-card--link .v-chip {
  cursor: pointer;
}

.v-card--link:focus:before {
  opacity: .08;
}

.v-card--link:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  background: currentColor;
  transition: opacity .2s;
  position: absolute;
  inset: 0;
}

.v-card--disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.v-card--disabled > :not(.v-card__progress) {
  opacity: .6;
  transition: inherit;
}

.v-card--loading {
  overflow: hidden;
}

.v-card--raised {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

.v-carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.v-carousel__controls {
  height: 50px;
  width: 100%;
  z-index: 1;
  background: #0000004d;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  display: flex;
  position: absolute;
  bottom: 0;
}

.v-carousel__controls > .v-item-group {
  flex: 0 auto;
}

.v-carousel__controls__item {
  margin: 0 8px;
}

.v-carousel__controls__item .v-icon {
  opacity: .5;
}

.v-carousel__controls__item--active .v-icon {
  opacity: 1;
  vertical-align: middle;
}

.v-carousel__controls__item:hover {
  background: none;
}

.v-carousel__controls__item:hover .v-icon {
  opacity: .8;
}

.v-carousel__progress {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.v-carousel .v-window-item {
  height: inherit;
  text-decoration: none;
  display: block;
}

.v-carousel--hide-delimiter-background .v-carousel__controls {
  background: none;
}

.v-carousel--vertical-delimiters .v-carousel__controls {
  width: 50px;
  height: 100% !important;
}

.v-window {
  overflow: hidden;
}

.v-window__container {
  height: inherit;
  flex-direction: column;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  display: flex;
  position: relative;
}

.v-window__container--is-active {
  overflow: hidden;
}

.v-window__next, .v-window__prev {
  z-index: 1;
  background: #0000004d;
  border-radius: 50%;
  margin: 0 16px;
  position: absolute;
  top: calc(50% - 20px);
}

.v-window__next .v-btn:hover, .v-window__prev .v-btn:hover {
  background: none;
}

.v-application--is-ltr .v-window__prev {
  left: 0;
}

.v-application--is-ltr .v-window__next, .v-application--is-rtl .v-window__prev {
  right: 0;
}

.v-application--is-rtl .v-window__next {
  left: 0;
}

.v-window--show-arrows-on-hover {
  overflow: hidden;
}

.v-window--show-arrows-on-hover .v-window__next, .v-window--show-arrows-on-hover .v-window__prev {
  transition: transform .2s cubic-bezier(.25, .8, .5, 1);
}

.v-application--is-ltr .v-window--show-arrows-on-hover .v-window__prev {
  transform: translateX(-200%);
}

.v-application--is-ltr .v-window--show-arrows-on-hover .v-window__next, .v-application--is-rtl .v-window--show-arrows-on-hover .v-window__prev {
  transform: translateX(200%);
}

.v-application--is-rtl .v-window--show-arrows-on-hover .v-window__next {
  transform: translateX(-200%);
}

.v-window--show-arrows-on-hover:hover .v-window__next, .v-window--show-arrows-on-hover:hover .v-window__prev {
  transform: translateX(0);
}

.v-window-x-reverse-transition-enter-active, .v-window-x-reverse-transition-leave-active, .v-window-x-transition-enter-active, .v-window-x-transition-leave-active, .v-window-y-reverse-transition-enter-active, .v-window-y-reverse-transition-leave-active, .v-window-y-transition-enter-active, .v-window-y-transition-leave-active {
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
}

.v-window-x-reverse-transition-leave, .v-window-x-reverse-transition-leave-to, .v-window-x-transition-leave, .v-window-x-transition-leave-to, .v-window-y-reverse-transition-leave, .v-window-y-reverse-transition-leave-to, .v-window-y-transition-leave, .v-window-y-transition-leave-to {
  width: 100%;
  top: 0;
  position: absolute !important;
}

.v-window-x-transition-enter {
  transform: translateX(100%);
}

.v-window-x-reverse-transition-enter, .v-window-x-transition-leave-to {
  transform: translateX(-100%);
}

.v-window-x-reverse-transition-leave-to {
  transform: translateX(100%);
}

.v-window-y-transition-enter {
  transform: translateY(100%);
}

.v-window-y-reverse-transition-enter, .v-window-y-transition-leave-to {
  transform: translateY(-100%);
}

.v-window-y-reverse-transition-leave-to {
  transform: translateY(100%);
}

.v-input--checkbox.v-input--indeterminate.v-input--is-disabled {
  opacity: .6;
}

.v-input--checkbox.v-input--dense {
  margin-top: 4px;
}

.v-input--selection-controls {
  margin-top: 16px;
  padding-top: 4px;
}

.v-input--selection-controls > .v-input__append-outer, .v-input--selection-controls > .v-input__prepend-outer {
  margin-top: 0;
  margin-bottom: 0;
}

.v-input--selection-controls:not(.v-input--hide-details) > .v-input__slot {
  margin-bottom: 12px;
}

.v-input--selection-controls .v-input__slot, .v-input--selection-controls .v-radio {
  cursor: pointer;
}

.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  height: auto;
  flex: auto;
  align-items: center;
  display: inline-flex;
}

.v-input--selection-controls__input {
  color: inherit;
  height: 24px;
  width: 24px;
  -webkit-user-select: none;
  user-select: none;
  flex: none;
  transition: transform .3s cubic-bezier(.25, .8, .5, 1);
  display: inline-flex;
  position: relative;
}

.v-input--selection-controls__input .v-icon {
  width: 100%;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 8px;
}

.v-application--is-rtl .v-input--selection-controls__input {
  margin-left: 8px;
}

.v-input--selection-controls__input input[role="checkbox"], .v-input--selection-controls__input input[role="radio"], .v-input--selection-controls__input input[role="switch"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
}

.v-input--selection-controls__input + .v-label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.v-input--selection-controls__ripple {
  cursor: pointer;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin: 7px;
  transition: inherit;
  position: absolute;
  top: calc(50% - 24px);
  left: -12px;
}

.v-input--selection-controls__ripple:before {
  border-radius: inherit;
  content: "";
  opacity: .2;
  transform-origin: center;
  transition: inherit;
  position: absolute;
  inset: 0;
  transform: scale(.2);
}

.v-input--selection-controls__ripple > .v-ripple__container {
  transform: scale(1.2);
}

.v-input--selection-controls.v-input--dense .v-input--selection-controls__ripple {
  width: 28px;
  height: 28px;
  left: -9px;
}

.v-input--selection-controls.v-input--dense:not(.v-input--switch) .v-input--selection-controls__ripple {
  top: calc(50% - 21px);
}

.v-input--selection-controls.v-input {
  flex: 0 auto;
}

.v-input--selection-controls.v-input--is-focused .v-input--selection-controls__ripple:before, .v-input--selection-controls .v-radio--is-focused .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(1.2);
}

.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
  color: inherit;
}

.v-input--selection-controls.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: none;
}

.v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transition: none;
  transform: scale(1.2);
}

.v-chip-group .v-chip {
  margin: 4px 8px 4px 0;
}

.v-chip-group .v-chip--active {
  color: inherit;
}

.v-chip-group .v-chip--active.v-chip--no-color:after {
  opacity: .22;
}

.v-chip-group .v-chip--active.v-chip--no-color:focus:after {
  opacity: .32;
}

.v-chip-group .v-slide-group__content {
  padding: 4px 0;
}

.v-chip-group--column .v-slide-group__content {
  white-space: normal;
  max-width: 100%;
  flex-wrap: wrap;
}

.v-slide-group {
  display: flex;
}

.v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__next, .v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__prev {
  display: none;
}

.v-slide-group.v-item-group > .v-slide-group__next, .v-slide-group.v-item-group > .v-slide-group__prev {
  cursor: pointer;
}

.v-slide-item {
  flex: 0 auto;
  display: inline-flex;
}

.v-slide-group__next, .v-slide-group__prev {
  min-width: 52px;
  flex: 0 52px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.v-slide-group__content {
  white-space: nowrap;
  flex: 1 0 auto;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  display: flex;
  position: relative;
}

.v-slide-group__wrapper {
  contain: content;
  flex: auto;
  display: flex;
  overflow: hidden;
}

.v-slide-group__next--disabled, .v-slide-group__prev--disabled {
  pointer-events: none;
}

.theme--light.v-color-picker .v-color-picker__input input {
  color: #000000de;
  border: thin solid #0000001f;
}

.theme--light.v-color-picker span {
  color: #0009;
}

.theme--light.v-color-picker .v-color-picker__color, .theme--light.v-color-picker .v-color-picker__dot {
  background-color: #fff0;
}

.theme--dark.v-color-picker .v-color-picker__input input {
  color: #fff;
  border: thin solid #ffffff1f;
}

.theme--dark.v-color-picker span {
  color: #ffffffb3;
}

.theme--dark.v-color-picker .v-color-picker__color, .theme--dark.v-color-picker .v-color-picker__dot {
  background-color: #ffffff1f;
}

.v-color-picker {
  contain: content;
  border-radius: 4px;
  align-self: flex-start;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-color-picker__controls {
  flex-direction: column;
  padding: 16px;
  display: flex;
}

.v-color-picker--flat, .v-color-picker--flat .v-color-picker__track:not(.v-input--is-disabled) .v-slider__thumb {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.v-color-picker__edit {
  margin-top: 24px;
  display: flex;
}

.v-color-picker__input {
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.v-application--is-ltr .v-color-picker__input:not(:last-child) {
  margin-right: 8px;
}

.v-application--is-rtl .v-color-picker__input:not(:last-child) {
  margin-left: 8px;
}

.v-color-picker__input input {
  min-width: 0;
  text-align: center;
  width: 100%;
  height: 28px;
  border-radius: 4px;
  outline: none;
  margin-bottom: 8px;
}

.v-color-picker__input span {
  font-size: .75rem;
}

.v-color-picker__canvas {
  contain: strict;
  position: relative;
  overflow: hidden;
}

.v-color-picker__canvas-dot {
  width: 15px;
  height: 15px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1.5px #0000004d;
}

.v-color-picker__canvas-dot--disabled {
  box-shadow: 0 0 0 1.5px #ffffffb3, inset 0 0 1px 1.5px #0000004d;
}

.v-color-picker__canvas:hover .v-color-picker__canvas-dot {
  will-change: transform;
}

.v-color-picker .v-input__slider {
  border-radius: 5px;
}

.v-color-picker .v-input__slider .v-slider {
  margin: 0;
}

.v-color-picker__alpha:not(.v-input--is-disabled) .v-slider {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC");
  border-radius: 5px;
}

.v-color-picker__sliders {
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
}

.v-color-picker__dot {
  height: 30px;
  width: 30px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC");
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.v-application--is-ltr .v-color-picker__dot {
  margin-right: 24px;
}

.v-application--is-rtl .v-color-picker__dot {
  margin-left: 24px;
}

.v-color-picker__dot > div {
  width: 100%;
  height: 100%;
}

.v-application--is-ltr .v-color-picker__hue:not(.v-input--is-disabled) {
  background: linear-gradient(90deg, red 0, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, red);
}

.v-application--is-rtl .v-color-picker__hue:not(.v-input--is-disabled) {
  background: linear-gradient(270deg, red 0, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, red);
}

.v-color-picker__track {
  width: 100%;
  position: relative;
}

.v-color-picker__preview {
  align-items: center;
  display: flex;
}

.v-color-picker__preview .v-slider {
  min-height: 10px;
}

.v-color-picker__preview .v-slider:not(.v-slider--disabled) .v-slider__thumb {
  box-shadow: 0 3px 3px -2px #0003, 0 3px 4px #00000024, 0 1px 8px #0000001f;
}

.v-color-picker__preview .v-slider:not(.v-slider--disabled) .v-slider__track-container {
  opacity: 0;
}

.v-color-picker__preview:not(.v-color-picker__preview--hide-alpha) .v-color-picker__hue {
  margin-bottom: 24px;
}

.theme--light.v-slider .v-slider__thumb, .theme--light.v-slider .v-slider__track-background, .theme--light.v-slider .v-slider__track-fill {
  background: #00000042;
}

.theme--dark.v-slider .v-slider__thumb, .theme--dark.v-slider .v-slider__track-background, .theme--dark.v-slider .v-slider__track-fill {
  background: #fff3;
}

.v-slider {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.v-slider input {
  cursor: default;
  width: 100%;
  padding: 0;
  display: none;
}

.v-slider__track-container {
  border-radius: 0;
  position: absolute;
}

.v-slider__thumb-container, .v-slider__track-background, .v-slider__track-fill {
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
}

.v-slider__thumb-container {
  outline: none;
  top: 50%;
}

.v-slider__thumb-container:hover .v-slider__thumb:before {
  transform: scale(1);
}

.v-slider__thumb {
  width: 12px;
  height: 12px;
  -webkit-user-select: none;
  user-select: none;
  top: 50%;
  left: -6px;
  transform: translateY(-50%);
}

.v-slider__thumb, .v-slider__thumb:before {
  border-radius: 50%;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
}

.v-slider__thumb:before {
  content: "";
  color: inherit;
  width: 36px;
  height: 36px;
  opacity: .3;
  pointer-events: none;
  background: currentColor;
  top: -12px;
  left: -12px;
  transform: scale(.1);
}

.v-slider__thumb:after {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-slider__tick, .v-slider__ticks-container {
  position: absolute;
}

.v-slider__tick {
  opacity: 0;
  background-color: #00000080;
  border-radius: 0;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
}

.v-slider__tick--filled {
  background-color: #ffffff80;
}

.v-application--is-ltr .v-slider__tick:first-child .v-slider__tick-label {
  transform: none;
}

.v-application--is-rtl .v-slider__tick:first-child .v-slider__tick-label {
  transform: translateX(100%);
}

.v-application--is-ltr .v-slider__tick:last-child .v-slider__tick-label {
  transform: translateX(-100%);
}

.v-application--is-rtl .v-slider__tick:last-child .v-slider__tick-label {
  transform: none;
}

.v-slider__tick-label {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  position: absolute;
}

.v-slider__thumb-label-container {
  top: 0;
}

.v-slider__thumb-label, .v-slider__thumb-label-container {
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  position: absolute;
  left: 0;
}

.v-slider__thumb-label {
  color: #fff;
  width: 32px;
  height: 32px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50% 50% 0;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  display: flex;
  bottom: 100%;
}

.v-slider--horizontal {
  min-height: 32px;
  margin-left: 8px;
  margin-right: 8px;
}

.v-slider--horizontal .v-slider__track-container {
  width: 100%;
  height: 2px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.v-slider--horizontal .v-slider__track-background, .v-slider--horizontal .v-slider__track-fill {
  height: 100%;
}

.v-slider--horizontal .v-slider__ticks-container {
  height: 2px;
  width: 100%;
  left: 0;
}

.v-application--is-ltr .v-slider--horizontal .v-slider__tick:first-child .v-slider__tick-label, .v-application--is-rtl .v-slider--horizontal .v-slider__tick:first-child .v-slider__tick-label {
  transform: translateX(0);
}

.v-application--is-ltr .v-slider--horizontal .v-slider__tick:last-child .v-slider__tick-label {
  transform: translateX(-100%);
}

.v-application--is-rtl .v-slider--horizontal .v-slider__tick:last-child .v-slider__tick-label {
  transform: translateX(100%);
}

.v-slider--horizontal .v-slider__tick .v-slider__tick-label {
  top: 8px;
}

.v-application--is-ltr .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
  transform: translateX(-50%);
}

.v-application--is-rtl .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
  transform: translateX(50%);
}

.v-slider--horizontal .v-slider__thumb-label {
  transform: translateY(-20%)translateY(-12px)translateX(-50%)rotate(45deg);
}

.v-slider--horizontal .v-slider__thumb-label > * {
  transform: rotate(-45deg);
}

.v-slider--vertical {
  min-height: 150px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.v-slider--vertical .v-slider__track-container {
  height: 100%;
  width: 2px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.v-slider--vertical .v-slider__track-background, .v-slider--vertical .v-slider__track-fill {
  width: 100%;
}

.v-slider--vertical .v-slider__thumb-container {
  left: 50%;
}

.v-slider--vertical .v-slider__ticks-container {
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.v-application--is-ltr .v-slider--vertical .v-slider__tick .v-slider__tick-label, .v-application--is-ltr .v-slider--vertical .v-slider__tick:first-child .v-slider__tick-label, .v-application--is-ltr .v-slider--vertical .v-slider__tick:last-child .v-slider__tick-label {
  left: 12px;
  transform: translateY(-50%);
}

.v-application--is-rtl .v-slider--vertical .v-slider__tick .v-slider__tick-label, .v-application--is-rtl .v-slider--vertical .v-slider__tick:first-child .v-slider__tick-label, .v-application--is-rtl .v-slider--vertical .v-slider__tick:last-child .v-slider__tick-label {
  right: 12px;
  transform: translateY(-50%);
}

.v-slider--vertical .v-slider__thumb-label > * {
  transform: rotate(-135deg);
}

.v-slider__thumb-container--focused .v-slider__thumb:before {
  transform: scale(1);
}

.v-slider--active .v-slider__tick {
  opacity: 1;
}

.v-slider__thumb-container--active .v-slider__thumb:before {
  transform: scale(1.5) !important;
}

.v-slider--disabled {
  pointer-events: none;
}

.v-slider--disabled .v-slider__thumb {
  width: 8px;
  height: 8px;
  left: -4px;
}

.v-slider--disabled .v-slider__thumb:before {
  display: none;
}

.v-slider__ticks-container--always-show .v-slider__tick {
  opacity: 1;
}

.v-input__slider.v-input--is-readonly > .v-input__control {
  pointer-events: none;
}

.v-application--is-ltr .v-input__slider .v-input__slot .v-label {
  margin-left: 0;
  margin-right: 12px;
}

.v-application--is-ltr .v-input__slider--inverse-label .v-input__slot .v-label, .v-application--is-rtl .v-input__slider .v-input__slot .v-label {
  margin-left: 12px;
  margin-right: 0;
}

.v-application--is-rtl .v-input__slider--inverse-label .v-input__slot .v-label {
  margin-left: 0;
  margin-right: 12px;
}

.v-input__slider--vertical {
  align-items: center;
}

.v-application--is-ltr .v-input__slider--vertical {
  flex-direction: column-reverse;
}

.v-application--is-rtl .v-input__slider--vertical {
  flex-direction: column;
}

.v-input__slider--vertical .v-input__append-outer, .v-input__slider--vertical .v-input__prepend-outer, .v-input__slider--vertical .v-input__slot {
  margin: 0;
}

.v-input__slider--vertical .v-messages {
  display: none;
}

.v-input--has-state .v-slider__track-background {
  opacity: .4;
}

.v-color-picker__swatches {
  overflow-y: auto;
}

.v-color-picker__swatches > div {
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px;
  display: flex;
}

.v-color-picker__swatch {
  flex-direction: column;
  margin-bottom: 10px;
  display: flex;
}

.v-color-picker__color {
  height: 18px;
  max-height: 18px;
  width: 45px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC");
  border-radius: 2px;
  margin: 2px 4px;
  position: relative;
  overflow: hidden;
}

.v-color-picker__color > div {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.v-main {
  max-width: 100%;
  flex: 1 0 auto;
  transition: all .2s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.v-main:not([data-booted="true"]) {
  transition: none !important;
}

.v-main__wrap {
  max-width: 100%;
  flex: auto;
  position: relative;
}

@-moz-document url-prefix() {
  @media print {
    .v-main {
      display: block;
    }
  }
}

.v-data-footer {
  flex-wrap: wrap;
  align-items: center;
  padding: 0 8px;
  font-size: .75rem;
  display: flex;
}

.v-data-footer .v-btn {
  color: inherit;
}

.v-application--is-ltr .v-data-footer__icons-before .v-btn:last-child {
  margin-right: 7px;
}

.v-application--is-ltr .v-data-footer__icons-after .v-btn:first-child, .v-application--is-rtl .v-data-footer__icons-before .v-btn:last-child {
  margin-left: 7px;
}

.v-application--is-rtl .v-data-footer__icons-after .v-btn:first-child {
  margin-right: 7px;
}

.v-data-footer__pagination {
  text-align: center;
  display: block;
}

.v-application--is-ltr .v-data-footer__pagination {
  margin: 0 32px 0 24px;
}

.v-application--is-rtl .v-data-footer__pagination {
  margin: 0 24px 0 32px;
}

.v-data-footer__select {
  white-space: nowrap;
  flex: 0 0 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.v-application--is-ltr .v-data-footer__select {
  margin-left: auto;
  margin-right: 14px;
}

.v-application--is-rtl .v-data-footer__select {
  margin-left: 14px;
  margin-right: auto;
}

.v-data-footer__select .v-select {
  flex: 0 1 0;
  padding: 0;
  position: static;
}

.v-application--is-ltr .v-data-footer__select .v-select {
  margin: 13px 0 13px 34px;
}

.v-application--is-rtl .v-data-footer__select .v-select {
  margin: 13px 34px 13px 0;
}

.v-data-footer__select .v-select__selections {
  flex-wrap: nowrap;
}

.v-data-footer__select .v-select__selections .v-select__selection--comma {
  font-size: .75rem;
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #f5f5f5;
}

.theme--light.v-data-table .v-row-group__header, .theme--light.v-data-table .v-row-group__summary {
  background: #eee;
}

.theme--light.v-data-table .v-data-footer {
  border-top: thin solid #0000001f;
}

.theme--light.v-data-table .v-data-table__empty-wrapper {
  color: #00000061;
}

.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #505050;
}

.theme--dark.v-data-table .v-row-group__header, .theme--dark.v-data-table .v-row-group__summary {
  background: #616161;
}

.theme--dark.v-data-table .v-data-footer {
  border-top: thin solid #ffffff1f;
}

.theme--dark.v-data-table .v-data-table__empty-wrapper {
  color: #ffffff80;
}

.v-data-table {
  border-radius: 4px;
}

.v-data-table--mobile > .v-data-table__wrapper tbody {
  flex-direction: column;
  display: flex;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  border-bottom: 0;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0 4px 8px -5px #323232bf, inset 0 -4px 8px -5px #323232bf;
}

.v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:first-child {
  border-top-left-radius: 4px;
}

.v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:last-child {
  border-top-right-radius: 4px;
}

.v-data-table > .v-data-table__wrapper tbody tr:last-child:hover td:first-child {
  border-bottom-left-radius: 4px;
}

.v-data-table > .v-data-table__wrapper tbody tr:last-child:hover td:last-child {
  border-bottom-right-radius: 4px;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  display: initial;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  height: auto;
  min-height: 48px;
}

.v-data-table__empty-wrapper {
  text-align: center;
}

.v-data-table__mobile-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.v-data-table__mobile-row__header {
  font-weight: 600;
}

.v-application--is-ltr .v-data-table__mobile-row__header {
  padding-right: 16px;
}

.v-application--is-rtl .v-data-table__mobile-row__header {
  padding-left: 16px;
}

.v-application--is-ltr .v-data-table__mobile-row__cell {
  text-align: right;
}

.v-application--is-rtl .v-data-table__mobile-row__cell {
  text-align: left;
}

.v-row-group__header td, .v-row-group__summary td {
  height: 35px;
}

.v-data-table__expand-icon {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.v-data-table__expand-icon--active {
  transform: rotate(-180deg);
}

.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #00000061;
}

.theme--light.v-data-table .v-data-table-header th.sortable.active, .theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon, .theme--light.v-data-table .v-data-table-header th.sortable:hover {
  color: #000000de;
}

.theme--light.v-data-table .v-data-table-header__sort-badge {
  color: #000000de;
  background-color: #0000001f;
}

.theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #ffffff80;
}

.theme--dark.v-data-table .v-data-table-header th.sortable.active, .theme--dark.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon, .theme--dark.v-data-table .v-data-table-header th.sortable:hover {
  color: #fff;
}

.theme--dark.v-data-table .v-data-table-header__sort-badge {
  color: #fff;
  background-color: #ffffff1f;
}

.v-data-table-header th.sortable {
  pointer-events: auto;
  cursor: pointer;
  outline: 0;
}

.v-data-table-header th.sortable .v-data-table-header__icon {
  line-height: .9;
}

.v-data-table-header th.active .v-data-table-header__icon, .v-data-table-header th:hover .v-data-table-header__icon {
  opacity: 1;
  transform: none;
}

.v-data-table-header th.desc .v-data-table-header__icon {
  transform: rotate(-180deg);
}

.v-data-table-header__icon {
  opacity: 0;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  display: inline-block;
}

.v-data-table-header__sort-badge {
  min-width: 18px;
  min-height: 18px;
  height: 18px;
  width: 18px;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.v-data-table-header-mobile th {
  height: auto;
}

.v-data-table-header-mobile__wrapper {
  display: flex;
}

.v-data-table-header-mobile__wrapper .v-select {
  margin-bottom: 8px;
}

.v-data-table-header-mobile__wrapper .v-select .v-chip {
  height: 24px;
}

.v-data-table-header-mobile__wrapper .v-select .v-chip__close.desc .v-icon {
  transform: rotate(-180deg);
}

.v-data-table-header-mobile__select {
  min-width: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.theme--light.v-data-table {
  color: #000000de;
  background-color: #fff;
}

.theme--light.v-data-table .v-data-table__divider {
  border-right: thin solid #0000001f;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #fff;
  box-shadow: inset 0 -1px #0000001f;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #0009;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: thin solid #0000001f;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
  background: #f5f5f5;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #eee;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th:not(.v-data-table__mobile-row) {
  border-top: thin solid #0000001f;
}

.theme--dark.v-data-table {
  color: #fff;
  background-color: #1e1e1e;
}

.theme--dark.v-data-table .v-data-table__divider {
  border-right: thin solid #ffffff1f;
}

.theme--dark.v-data-table.v-data-table--fixed-header thead th {
  background: #1e1e1e;
  box-shadow: inset 0 -1px #ffffff1f;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #ffffffb3;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child, .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: thin solid #ffffff1f;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
  background: #505050;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #616161;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th:not(.v-data-table__mobile-row) {
  border-top: thin solid #ffffff1f;
}

.v-data-table {
  max-width: 100%;
  line-height: 1.5;
}

.v-data-table > .v-data-table__wrapper > table {
  width: 100%;
  border-spacing: 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 16px;
  transition: height .2s cubic-bezier(.4, 0, .6, 1);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  -webkit-user-select: none;
  user-select: none;
  height: 48px;
  font-size: .75rem;
}

.v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th, .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  text-align: left;
}

.v-application--is-rtl .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-application--is-rtl .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th, .v-application--is-rtl .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  text-align: right;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  height: 48px;
  font-size: .875rem;
}

.v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.v-data-table__progress {
  height: auto !important;
}

.v-data-table__progress th {
  padding: 0;
  position: relative;
  height: auto !important;
  border: none !important;
}

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > th, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th {
  height: 32px;
}

.v-data-table--has-top > .v-data-table__wrapper > table > tbody > tr:first-child:hover > td:first-child {
  border-top-left-radius: 0;
}

.v-data-table--has-top > .v-data-table__wrapper > table > tbody > tr:first-child:hover > td:last-child {
  border-top-right-radius: 0;
}

.v-data-table--has-bottom > .v-data-table__wrapper > table > tbody > tr:last-child:hover > td:first-child {
  border-bottom-left-radius: 0;
}

.v-data-table--has-bottom > .v-data-table__wrapper > table > tbody > tr:last-child:hover > td:last-child {
  border-bottom-right-radius: 0;
}

.v-data-table--fixed-header > .v-data-table__wrapper, .v-data-table--fixed-height .v-data-table__wrapper {
  overflow-y: auto;
}

.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 2;
  position: sticky;
  top: 0;
  border-bottom: 0 !important;
}

.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr:nth-child(2) > th {
  top: 48px;
}

.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 17px;
}

.v-application--is-rtl .v-data-table--fixed-header .v-data-footer {
  margin-left: 17px;
}

.v-data-table--fixed-header.v-data-table--dense > .v-data-table__wrapper > table > thead > tr:nth-child(2) > th {
  top: 32px;
}

.theme--light.v-small-dialog__actions, .theme--light.v-small-dialog__menu-content {
  background: #fff;
}

.theme--dark.v-small-dialog__actions, .theme--dark.v-small-dialog__menu-content {
  background: #1e1e1e;
}

.v-small-dialog {
  display: block;
}

.v-small-dialog__activator {
  cursor: pointer;
}

.v-small-dialog__activator__content {
  display: inline-block;
}

.v-small-dialog__content {
  padding: 0 16px;
}

.v-small-dialog__actions {
  text-align: right;
  white-space: pre;
  padding: 8px;
}

.v-virtual-table {
  position: relative;
}

.v-virtual-table__wrapper {
  display: flex;
}

.v-virtual-table__table {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.theme--light.v-picker__title {
  background: #e0e0e0;
}

.theme--dark.v-picker__title {
  background: #616161;
}

.theme--light.v-picker__body {
  background: #fff;
}

.theme--dark.v-picker__body {
  background: #424242;
}

.v-picker {
  contain: layout style;
  vertical-align: top;
  border-radius: 4px;
  flex-direction: column;
  font-size: 1rem;
  display: inline-flex;
  position: relative;
}

.v-picker--full-width {
  width: 100%;
  display: flex;
}

.v-picker--full-width > .v-picker__body {
  margin: initial;
}

.v-picker__title {
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px;
}

.v-picker__title__btn {
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
}

.v-picker__title__btn:not(.v-picker__title__btn--active) {
  opacity: .6;
  cursor: pointer;
}

.v-picker__title__btn:not(.v-picker__title__btn--active):hover:not(:focus) {
  opacity: 1;
}

.v-picker__title__btn--readonly {
  pointer-events: none;
}

.v-picker__title__btn--active {
  opacity: 1;
}

.v-picker__body {
  height: auto;
  z-index: 0;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.v-picker__body > div {
  width: 100%;
}

.v-picker__body > div.fade-transition-leave-active {
  position: absolute;
}

.v-picker--landscape .v-picker__title {
  width: 170px;
  height: 100%;
  z-index: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  top: 0;
}

.v-application--is-ltr .v-picker--landscape .v-picker__title {
  left: 0;
}

.v-application--is-rtl .v-picker--landscape .v-picker__title {
  right: 0;
}

.v-application--is-ltr .v-picker--landscape .v-picker__actions:not(.v-picker__actions--no-title), .v-application--is-ltr .v-picker--landscape .v-picker__body:not(.v-picker__body--no-title) {
  margin-left: 170px;
  margin-right: 0;
}

.v-application--is-rtl .v-picker--landscape .v-picker__actions:not(.v-picker__actions--no-title), .v-application--is-rtl .v-picker--landscape .v-picker__body:not(.v-picker__body--no-title) {
  margin-left: 0;
  margin-right: 170px;
}

.v-picker--flat {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.v-date-picker-title {
  flex-flow: column wrap;
  justify-content: space-between;
  line-height: 1;
  display: flex;
}

.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
  text-align: left;
}

.v-application--is-rtl .v-date-picker-title .v-picker__title__btn {
  text-align: right;
}

.v-date-picker-title__year {
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
}

.v-date-picker-title__date {
  text-align: left;
  margin-bottom: -8px;
  padding-bottom: 8px;
  font-size: 34px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.v-date-picker-title__date > div {
  position: relative;
}

.v-date-picker-title--disabled {
  pointer-events: none;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
  color: #000000de;
}

.theme--light.v-date-picker-header .v-date-picker-header__value--disabled button {
  color: #00000061;
}

.theme--dark.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
  color: #fff;
}

.theme--dark.v-date-picker-header .v-date-picker-header__value--disabled button {
  color: #ffffff80;
}

.v-date-picker-header {
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  display: flex;
  position: relative;
}

.v-date-picker-header .v-btn {
  z-index: auto;
  margin: 0;
}

.v-date-picker-header .v-icon {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.v-date-picker-header__value {
  text-align: center;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.v-date-picker-header__value div {
  width: 100%;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
}

.v-date-picker-header__value button {
  cursor: pointer;
  outline: none;
  padding: .5rem;
  font-weight: 700;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
}

.v-date-picker-header--disabled {
  pointer-events: none;
}

.theme--light.v-date-picker-table .v-date-picker-table--date__week, .theme--light.v-date-picker-table th {
  color: #00000061;
}

.theme--dark.v-date-picker-table .v-date-picker-table--date__week, .theme--dark.v-date-picker-table th {
  color: #ffffff80;
}

.v-date-picker-table {
  height: 242px;
  padding: 0 12px;
  position: relative;
}

.v-date-picker-table table {
  table-layout: fixed;
  width: 100%;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  top: 0;
}

.v-date-picker-table td, .v-date-picker-table th {
  text-align: center;
  position: relative;
}

.v-date-picker-table th {
  font-size: 12px;
}

.v-date-picker-table--date .v-btn {
  height: 32px;
  width: 32px;
}

.v-date-picker-table .v-btn {
  z-index: auto;
  margin: 0;
  font-size: 12px;
}

.v-date-picker-table .v-btn.v-btn--active {
  color: #fff;
}

.v-date-picker-table--month td {
  width: 33.3333%;
  height: 56px;
  vertical-align: middle;
  text-align: center;
}

.v-date-picker-table--month td .v-btn {
  max-width: 140px;
  min-width: 40px;
  width: 100%;
  margin: 0 auto;
}

.v-date-picker-table--date th {
  padding: 8px 0;
  font-weight: 600;
}

.v-date-picker-table--date td {
  width: 45px;
}

.v-date-picker-table__events {
  height: 8px;
  text-align: center;
  white-space: pre;
  width: 100%;
  position: absolute;
  left: 0;
}

.v-date-picker-table__events > div {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 1px;
  display: inline-block;
}

.v-date-picker-table--date .v-date-picker-table__events {
  bottom: 6px;
}

.v-date-picker-table--month .v-date-picker-table__events {
  bottom: 8px;
}

.v-date-picker-table__current .v-date-picker-table__events {
  margin-bottom: -1px;
}

.v-date-picker-table--disabled {
  pointer-events: none;
}

.v-date-picker-years {
  height: 290px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  list-style-type: none;
  overflow: auto;
}

.v-date-picker-years.v-date-picker-years {
  padding: 0;
}

.v-date-picker-years li {
  cursor: pointer;
  padding: 8px 0;
  transition: none;
}

.v-date-picker-years li.active {
  padding: 10px 0;
  font-size: 26px;
  font-weight: 500;
}

.v-date-picker-years li:hover {
  background: #0000001f;
}

.v-picker--landscape .v-date-picker-years {
  height: 290px;
  padding: 0;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  color: #000000de;
  background-color: #fff;
}

.theme--light.v-expansion-panels .v-expansion-panel--disabled {
  color: #00000061;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after {
  border-color: #0000001f;
}

.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: #0000008a;
}

.theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:hover:before {
  opacity: .04;
}

.theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:before, .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:hover:before, .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:focus:before {
  opacity: .12;
}

.theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:focus:before {
  opacity: .16;
}

.theme--light.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover:before {
  opacity: .04;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  color: #fff;
  background-color: #1e1e1e;
}

.theme--dark.v-expansion-panels .v-expansion-panel--disabled {
  color: #ffffff80;
}

.theme--dark.v-expansion-panels .v-expansion-panel:not(:first-child):after {
  border-color: #ffffff1f;
}

.theme--dark.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: #fff;
}

.theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:hover:before {
  opacity: .08;
}

.theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:before, .theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:hover:before, .theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:focus:before {
  opacity: .24;
}

.theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:focus:before {
  opacity: .32;
}

.theme--dark.v-expansion-panels.v-expansion-panels--hover > .v-expansion-panel > .v-expansion-panel-header:hover:before {
  opacity: .08;
}

.v-expansion-panels {
  width: 100%;
  z-index: 1;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.v-expansion-panels > * {
  cursor: auto;
}

.v-expansion-panels > :first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.v-expansion-panels > :last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
  border-radius: 4px;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active + .v-expansion-panel {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--next-active {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--next-active .v-expansion-panel-header {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.v-expansion-panel {
  max-width: 100%;
  flex: 1 0 100%;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: relative;
}

.v-expansion-panel:before {
  border-radius: inherit;
  content: "";
  z-index: -1;
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  inset: 0;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-expansion-panel:not(:first-child):after {
  content: "";
  border-top: thin solid;
  transition: border-color .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.v-expansion-panel--disabled .v-expansion-panel-header {
  pointer-events: none;
}

.v-expansion-panel--active + .v-expansion-panel, .v-expansion-panel--active:not(:first-child) {
  margin-top: 16px;
}

.v-expansion-panel--active + .v-expansion-panel:after, .v-expansion-panel--active:not(:first-child):after {
  opacity: 0;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 64px;
}

.v-expansion-panel--active > .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
  transform: rotate(-180deg);
}

.v-expansion-panel-header__icon {
  -webkit-user-select: none;
  user-select: none;
  margin-top: -4px;
  margin-bottom: -4px;
  display: inline-flex;
}

.v-application--is-ltr .v-expansion-panel-header__icon {
  margin-left: auto;
}

.v-application--is-rtl .v-expansion-panel-header__icon {
  margin-right: auto;
}

.v-expansion-panel-header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  min-height: 48px;
  width: 100%;
  outline: none;
  align-items: center;
  padding: 16px 24px;
  font-size: .9375rem;
  line-height: 1;
  transition: min-height .3s cubic-bezier(.25, .8, .5, 1);
  display: flex;
  position: relative;
}

.v-application--is-ltr .v-expansion-panel-header {
  text-align: left;
}

.v-application--is-rtl .v-expansion-panel-header {
  text-align: right;
}

.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus:before {
  opacity: .12;
}

.v-expansion-panel-header:before {
  border-radius: inherit;
  content: "";
  opacity: 0;
  pointer-events: none;
  background-color: currentColor;
  transition: opacity .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  inset: 0;
}

.v-expansion-panel-header > :not(.v-expansion-panel-header__icon) {
  flex: auto;
}

.v-expansion-panel-content {
  display: flex;
}

.v-expansion-panel-content__wrap {
  max-width: 100%;
  flex: auto;
  padding: 0 24px 16px;
}

.v-expansion-panels--accordion > .v-expansion-panel {
  margin-top: 0;
}

.v-expansion-panels--accordion > .v-expansion-panel:after {
  opacity: 1;
}

.v-expansion-panels--popout > .v-expansion-panel {
  max-width: calc(100% - 32px);
}

.v-expansion-panels--popout > .v-expansion-panel--active {
  max-width: calc(100% + 16px);
}

.v-expansion-panels--inset > .v-expansion-panel {
  max-width: 100%;
}

.v-expansion-panels--inset > .v-expansion-panel--active {
  max-width: calc(100% - 32px);
}

.v-expansion-panels--flat > .v-expansion-panel:after {
  border-top: none;
}

.v-expansion-panels--flat > .v-expansion-panel:before {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.v-expansion-panels--tile, .v-expansion-panels--tile > .v-expansion-panel:before {
  border-radius: 0;
}

.theme--light.v-file-input .v-file-input__text {
  color: #000000de;
}

.theme--light.v-file-input .v-file-input__text--placeholder {
  color: #0009;
}

.theme--light.v-file-input.v-input--is-disabled .v-file-input__text, .theme--light.v-file-input.v-input--is-disabled .v-file-input__text .v-file-input__text--placeholder {
  color: #00000061;
}

.theme--dark.v-file-input .v-file-input__text {
  color: #fff;
}

.theme--dark.v-file-input .v-file-input__text--placeholder {
  color: #ffffffb3;
}

.theme--dark.v-file-input.v-input--is-disabled .v-file-input__text, .theme--dark.v-file-input.v-input--is-disabled .v-file-input__text .v-file-input__text--placeholder {
  color: #ffffff80;
}

.v-file-input input[type="file"] {
  opacity: 0;
  pointer-events: none;
  max-width: 0;
  width: 0;
  position: absolute;
  left: 0;
}

.v-file-input .v-file-input__text {
  width: 100%;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.v-file-input .v-file-input__text.v-file-input__text--chips {
  flex-wrap: wrap;
}

.v-file-input .v-file-input__text .v-chip {
  margin: 4px;
}

.v-file-input .v-text-field__slot {
  min-height: 32px;
}

.v-file-input.v-input--dense .v-text-field__slot {
  min-height: 26px;
}

.v-file-input.v-text-field--filled:not(.v-text-field--single-line) .v-file-input__text {
  padding-top: 22px;
}

.v-file-input.v-text-field--outlined .v-text-field__slot {
  padding: 6px 0;
}

.v-file-input.v-text-field--outlined.v-input--dense .v-text-field__slot {
  padding: 3px 0;
}

.theme--light.v-footer {
  color: #000000de;
  background-color: #f5f5f5;
}

.theme--dark.v-footer {
  color: #fff;
  background-color: #272727;
}

.v-sheet.v-footer {
  border-radius: 0;
}

.v-sheet.v-footer:not(.v-sheet--outlined) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.v-sheet.v-footer.v-sheet--shaped {
  border-radius: 24px 0;
}

.v-footer {
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 16px;
  transition-property: background-color, left, right;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
  flex: 0 auto !important;
}

.v-footer:not([data-booted="true"]) {
  transition: none !important;
}

.v-footer--absolute, .v-footer--fixed {
  z-index: 3;
}

.v-footer--absolute {
  position: absolute;
}

.v-footer--absolute:not(.v-footer--inset) {
  width: 100%;
}

.v-footer--fixed {
  position: fixed;
}

.v-footer--padless {
  padding: 0;
}

.container.grow-shrink-0 {
  flex-grow: 0;
  flex-shrink: 0;
}

.container.fill-height {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.container.fill-height > .row {
  max-width: calc(100% + 24px);
  flex: 100%;
}

.container.fill-height > .layout {
  height: 100%;
  flex: auto;
}

.container.fill-height > .layout.grow-shrink-0 {
  flex-grow: 0;
  flex-shrink: 0;
}

.container.grid-list-xs .layout .flex {
  padding: 1px;
}

.container.grid-list-xs .layout:only-child {
  margin: -1px;
}

.container.grid-list-xs .layout:not(:only-child) {
  margin: auto -1px;
}

.container.grid-list-xs :not(:only-child) .layout:first-child {
  margin-top: -1px;
}

.container.grid-list-xs :not(:only-child) .layout:last-child {
  margin-bottom: -1px;
}

.container.grid-list-sm .layout .flex {
  padding: 2px;
}

.container.grid-list-sm .layout:only-child {
  margin: -2px;
}

.container.grid-list-sm .layout:not(:only-child) {
  margin: auto -2px;
}

.container.grid-list-sm :not(:only-child) .layout:first-child {
  margin-top: -2px;
}

.container.grid-list-sm :not(:only-child) .layout:last-child {
  margin-bottom: -2px;
}

.container.grid-list-md .layout .flex {
  padding: 4px;
}

.container.grid-list-md .layout:only-child {
  margin: -4px;
}

.container.grid-list-md .layout:not(:only-child) {
  margin: auto -4px;
}

.container.grid-list-md :not(:only-child) .layout:first-child {
  margin-top: -4px;
}

.container.grid-list-md :not(:only-child) .layout:last-child {
  margin-bottom: -4px;
}

.container.grid-list-lg .layout .flex {
  padding: 8px;
}

.container.grid-list-lg .layout:only-child {
  margin: -8px;
}

.container.grid-list-lg .layout:not(:only-child) {
  margin: auto -8px;
}

.container.grid-list-lg :not(:only-child) .layout:first-child {
  margin-top: -8px;
}

.container.grid-list-lg :not(:only-child) .layout:last-child {
  margin-bottom: -8px;
}

.container.grid-list-xl .layout .flex {
  padding: 12px;
}

.container.grid-list-xl .layout:only-child {
  margin: -12px;
}

.container.grid-list-xl .layout:not(:only-child) {
  margin: auto -12px;
}

.container.grid-list-xl :not(:only-child) .layout:first-child {
  margin-top: -12px;
}

.container.grid-list-xl :not(:only-child) .layout:last-child {
  margin-bottom: -12px;
}

.layout {
  min-width: 0;
  flex-wrap: nowrap;
  flex: auto;
  display: flex;
}

.layout.reverse {
  flex-direction: row-reverse;
}

.layout.column {
  flex-direction: column;
}

.layout.column.reverse {
  flex-direction: column-reverse;
}

.layout.column > .flex {
  max-width: 100%;
}

.layout.wrap {
  flex-wrap: wrap;
}

.layout.grow-shrink-0 {
  flex-grow: 0;
  flex-shrink: 0;
}

@media (min-width: 0) {
  .flex.xs12 {
    max-width: 100%;
    flex-grow: 0;
    flex-basis: 100%;
  }

  .flex.order-xs12 {
    order: 12;
  }

  .flex.xs11 {
    max-width: 91.6667%;
    flex-grow: 0;
    flex-basis: 91.6667%;
  }

  .flex.order-xs11 {
    order: 11;
  }

  .flex.xs10 {
    max-width: 83.3333%;
    flex-grow: 0;
    flex-basis: 83.3333%;
  }

  .flex.order-xs10 {
    order: 10;
  }

  .flex.xs9 {
    max-width: 75%;
    flex-grow: 0;
    flex-basis: 75%;
  }

  .flex.order-xs9 {
    order: 9;
  }

  .flex.xs8 {
    max-width: 66.6667%;
    flex-grow: 0;
    flex-basis: 66.6667%;
  }

  .flex.order-xs8 {
    order: 8;
  }

  .flex.xs7 {
    max-width: 58.3333%;
    flex-grow: 0;
    flex-basis: 58.3333%;
  }

  .flex.order-xs7 {
    order: 7;
  }

  .flex.xs6 {
    max-width: 50%;
    flex-grow: 0;
    flex-basis: 50%;
  }

  .flex.order-xs6 {
    order: 6;
  }

  .flex.xs5 {
    max-width: 41.6667%;
    flex-grow: 0;
    flex-basis: 41.6667%;
  }

  .flex.order-xs5 {
    order: 5;
  }

  .flex.xs4 {
    max-width: 33.3333%;
    flex-grow: 0;
    flex-basis: 33.3333%;
  }

  .flex.order-xs4 {
    order: 4;
  }

  .flex.xs3 {
    max-width: 25%;
    flex-grow: 0;
    flex-basis: 25%;
  }

  .flex.order-xs3 {
    order: 3;
  }

  .flex.xs2 {
    max-width: 16.6667%;
    flex-grow: 0;
    flex-basis: 16.6667%;
  }

  .flex.order-xs2 {
    order: 2;
  }

  .flex.xs1 {
    max-width: 8.33333%;
    flex-grow: 0;
    flex-basis: 8.33333%;
  }

  .flex.order-xs1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-xs12 {
    margin-left: 100%;
  }

  .v-application--is-rtl .flex.offset-xs12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-xs11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .flex.offset-xs11 {
    margin-right: 91.6667%;
  }

  .v-application--is-ltr .flex.offset-xs10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .flex.offset-xs10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .flex.offset-xs9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .flex.offset-xs9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-xs8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .flex.offset-xs8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .flex.offset-xs7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .flex.offset-xs7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .flex.offset-xs6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .flex.offset-xs6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-xs5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .flex.offset-xs5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .flex.offset-xs4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .flex.offset-xs4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .flex.offset-xs3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .flex.offset-xs3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-xs2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .flex.offset-xs2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .flex.offset-xs1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .flex.offset-xs1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .flex.offset-xs0 {
    margin-left: 0;
  }

  .v-application--is-rtl .flex.offset-xs0 {
    margin-right: 0;
  }
}

@media (min-width: 600px) {
  .flex.sm12 {
    max-width: 100%;
    flex-grow: 0;
    flex-basis: 100%;
  }

  .flex.order-sm12 {
    order: 12;
  }

  .flex.sm11 {
    max-width: 91.6667%;
    flex-grow: 0;
    flex-basis: 91.6667%;
  }

  .flex.order-sm11 {
    order: 11;
  }

  .flex.sm10 {
    max-width: 83.3333%;
    flex-grow: 0;
    flex-basis: 83.3333%;
  }

  .flex.order-sm10 {
    order: 10;
  }

  .flex.sm9 {
    max-width: 75%;
    flex-grow: 0;
    flex-basis: 75%;
  }

  .flex.order-sm9 {
    order: 9;
  }

  .flex.sm8 {
    max-width: 66.6667%;
    flex-grow: 0;
    flex-basis: 66.6667%;
  }

  .flex.order-sm8 {
    order: 8;
  }

  .flex.sm7 {
    max-width: 58.3333%;
    flex-grow: 0;
    flex-basis: 58.3333%;
  }

  .flex.order-sm7 {
    order: 7;
  }

  .flex.sm6 {
    max-width: 50%;
    flex-grow: 0;
    flex-basis: 50%;
  }

  .flex.order-sm6 {
    order: 6;
  }

  .flex.sm5 {
    max-width: 41.6667%;
    flex-grow: 0;
    flex-basis: 41.6667%;
  }

  .flex.order-sm5 {
    order: 5;
  }

  .flex.sm4 {
    max-width: 33.3333%;
    flex-grow: 0;
    flex-basis: 33.3333%;
  }

  .flex.order-sm4 {
    order: 4;
  }

  .flex.sm3 {
    max-width: 25%;
    flex-grow: 0;
    flex-basis: 25%;
  }

  .flex.order-sm3 {
    order: 3;
  }

  .flex.sm2 {
    max-width: 16.6667%;
    flex-grow: 0;
    flex-basis: 16.6667%;
  }

  .flex.order-sm2 {
    order: 2;
  }

  .flex.sm1 {
    max-width: 8.33333%;
    flex-grow: 0;
    flex-basis: 8.33333%;
  }

  .flex.order-sm1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-sm12 {
    margin-left: 100%;
  }

  .v-application--is-rtl .flex.offset-sm12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-sm11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .flex.offset-sm11 {
    margin-right: 91.6667%;
  }

  .v-application--is-ltr .flex.offset-sm10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .flex.offset-sm10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .flex.offset-sm9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .flex.offset-sm9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-sm8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .flex.offset-sm8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .flex.offset-sm7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .flex.offset-sm7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .flex.offset-sm6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .flex.offset-sm6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-sm5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .flex.offset-sm5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .flex.offset-sm4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .flex.offset-sm4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .flex.offset-sm3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .flex.offset-sm3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-sm2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .flex.offset-sm2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .flex.offset-sm1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .flex.offset-sm1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .flex.offset-sm0 {
    margin-left: 0;
  }

  .v-application--is-rtl .flex.offset-sm0 {
    margin-right: 0;
  }
}

@media (min-width: 960px) {
  .flex.md12 {
    max-width: 100%;
    flex-grow: 0;
    flex-basis: 100%;
  }

  .flex.order-md12 {
    order: 12;
  }

  .flex.md11 {
    max-width: 91.6667%;
    flex-grow: 0;
    flex-basis: 91.6667%;
  }

  .flex.order-md11 {
    order: 11;
  }

  .flex.md10 {
    max-width: 83.3333%;
    flex-grow: 0;
    flex-basis: 83.3333%;
  }

  .flex.order-md10 {
    order: 10;
  }

  .flex.md9 {
    max-width: 75%;
    flex-grow: 0;
    flex-basis: 75%;
  }

  .flex.order-md9 {
    order: 9;
  }

  .flex.md8 {
    max-width: 66.6667%;
    flex-grow: 0;
    flex-basis: 66.6667%;
  }

  .flex.order-md8 {
    order: 8;
  }

  .flex.md7 {
    max-width: 58.3333%;
    flex-grow: 0;
    flex-basis: 58.3333%;
  }

  .flex.order-md7 {
    order: 7;
  }

  .flex.md6 {
    max-width: 50%;
    flex-grow: 0;
    flex-basis: 50%;
  }

  .flex.order-md6 {
    order: 6;
  }

  .flex.md5 {
    max-width: 41.6667%;
    flex-grow: 0;
    flex-basis: 41.6667%;
  }

  .flex.order-md5 {
    order: 5;
  }

  .flex.md4 {
    max-width: 33.3333%;
    flex-grow: 0;
    flex-basis: 33.3333%;
  }

  .flex.order-md4 {
    order: 4;
  }

  .flex.md3 {
    max-width: 25%;
    flex-grow: 0;
    flex-basis: 25%;
  }

  .flex.order-md3 {
    order: 3;
  }

  .flex.md2 {
    max-width: 16.6667%;
    flex-grow: 0;
    flex-basis: 16.6667%;
  }

  .flex.order-md2 {
    order: 2;
  }

  .flex.md1 {
    max-width: 8.33333%;
    flex-grow: 0;
    flex-basis: 8.33333%;
  }

  .flex.order-md1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-md12 {
    margin-left: 100%;
  }

  .v-application--is-rtl .flex.offset-md12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-md11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .flex.offset-md11 {
    margin-right: 91.6667%;
  }

  .v-application--is-ltr .flex.offset-md10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .flex.offset-md10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .flex.offset-md9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .flex.offset-md9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-md8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .flex.offset-md8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .flex.offset-md7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .flex.offset-md7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .flex.offset-md6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .flex.offset-md6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-md5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .flex.offset-md5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .flex.offset-md4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .flex.offset-md4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .flex.offset-md3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .flex.offset-md3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-md2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .flex.offset-md2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .flex.offset-md1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .flex.offset-md1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .flex.offset-md0 {
    margin-left: 0;
  }

  .v-application--is-rtl .flex.offset-md0 {
    margin-right: 0;
  }
}

@media (min-width: 1264px) {
  .flex.lg12 {
    max-width: 100%;
    flex-grow: 0;
    flex-basis: 100%;
  }

  .flex.order-lg12 {
    order: 12;
  }

  .flex.lg11 {
    max-width: 91.6667%;
    flex-grow: 0;
    flex-basis: 91.6667%;
  }

  .flex.order-lg11 {
    order: 11;
  }

  .flex.lg10 {
    max-width: 83.3333%;
    flex-grow: 0;
    flex-basis: 83.3333%;
  }

  .flex.order-lg10 {
    order: 10;
  }

  .flex.lg9 {
    max-width: 75%;
    flex-grow: 0;
    flex-basis: 75%;
  }

  .flex.order-lg9 {
    order: 9;
  }

  .flex.lg8 {
    max-width: 66.6667%;
    flex-grow: 0;
    flex-basis: 66.6667%;
  }

  .flex.order-lg8 {
    order: 8;
  }

  .flex.lg7 {
    max-width: 58.3333%;
    flex-grow: 0;
    flex-basis: 58.3333%;
  }

  .flex.order-lg7 {
    order: 7;
  }

  .flex.lg6 {
    max-width: 50%;
    flex-grow: 0;
    flex-basis: 50%;
  }

  .flex.order-lg6 {
    order: 6;
  }

  .flex.lg5 {
    max-width: 41.6667%;
    flex-grow: 0;
    flex-basis: 41.6667%;
  }

  .flex.order-lg5 {
    order: 5;
  }

  .flex.lg4 {
    max-width: 33.3333%;
    flex-grow: 0;
    flex-basis: 33.3333%;
  }

  .flex.order-lg4 {
    order: 4;
  }

  .flex.lg3 {
    max-width: 25%;
    flex-grow: 0;
    flex-basis: 25%;
  }

  .flex.order-lg3 {
    order: 3;
  }

  .flex.lg2 {
    max-width: 16.6667%;
    flex-grow: 0;
    flex-basis: 16.6667%;
  }

  .flex.order-lg2 {
    order: 2;
  }

  .flex.lg1 {
    max-width: 8.33333%;
    flex-grow: 0;
    flex-basis: 8.33333%;
  }

  .flex.order-lg1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-lg12 {
    margin-left: 100%;
  }

  .v-application--is-rtl .flex.offset-lg12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-lg11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .flex.offset-lg11 {
    margin-right: 91.6667%;
  }

  .v-application--is-ltr .flex.offset-lg10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .flex.offset-lg10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .flex.offset-lg9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .flex.offset-lg9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-lg8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .flex.offset-lg8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .flex.offset-lg7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .flex.offset-lg7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .flex.offset-lg6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .flex.offset-lg6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-lg5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .flex.offset-lg5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .flex.offset-lg4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .flex.offset-lg4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .flex.offset-lg3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .flex.offset-lg3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-lg2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .flex.offset-lg2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .flex.offset-lg1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .flex.offset-lg1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .flex.offset-lg0 {
    margin-left: 0;
  }

  .v-application--is-rtl .flex.offset-lg0 {
    margin-right: 0;
  }
}

@media (min-width: 1904px) {
  .flex.xl12 {
    max-width: 100%;
    flex-grow: 0;
    flex-basis: 100%;
  }

  .flex.order-xl12 {
    order: 12;
  }

  .flex.xl11 {
    max-width: 91.6667%;
    flex-grow: 0;
    flex-basis: 91.6667%;
  }

  .flex.order-xl11 {
    order: 11;
  }

  .flex.xl10 {
    max-width: 83.3333%;
    flex-grow: 0;
    flex-basis: 83.3333%;
  }

  .flex.order-xl10 {
    order: 10;
  }

  .flex.xl9 {
    max-width: 75%;
    flex-grow: 0;
    flex-basis: 75%;
  }

  .flex.order-xl9 {
    order: 9;
  }

  .flex.xl8 {
    max-width: 66.6667%;
    flex-grow: 0;
    flex-basis: 66.6667%;
  }

  .flex.order-xl8 {
    order: 8;
  }

  .flex.xl7 {
    max-width: 58.3333%;
    flex-grow: 0;
    flex-basis: 58.3333%;
  }

  .flex.order-xl7 {
    order: 7;
  }

  .flex.xl6 {
    max-width: 50%;
    flex-grow: 0;
    flex-basis: 50%;
  }

  .flex.order-xl6 {
    order: 6;
  }

  .flex.xl5 {
    max-width: 41.6667%;
    flex-grow: 0;
    flex-basis: 41.6667%;
  }

  .flex.order-xl5 {
    order: 5;
  }

  .flex.xl4 {
    max-width: 33.3333%;
    flex-grow: 0;
    flex-basis: 33.3333%;
  }

  .flex.order-xl4 {
    order: 4;
  }

  .flex.xl3 {
    max-width: 25%;
    flex-grow: 0;
    flex-basis: 25%;
  }

  .flex.order-xl3 {
    order: 3;
  }

  .flex.xl2 {
    max-width: 16.6667%;
    flex-grow: 0;
    flex-basis: 16.6667%;
  }

  .flex.order-xl2 {
    order: 2;
  }

  .flex.xl1 {
    max-width: 8.33333%;
    flex-grow: 0;
    flex-basis: 8.33333%;
  }

  .flex.order-xl1 {
    order: 1;
  }

  .v-application--is-ltr .flex.offset-xl12 {
    margin-left: 100%;
  }

  .v-application--is-rtl .flex.offset-xl12 {
    margin-right: 100%;
  }

  .v-application--is-ltr .flex.offset-xl11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .flex.offset-xl11 {
    margin-right: 91.6667%;
  }

  .v-application--is-ltr .flex.offset-xl10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .flex.offset-xl10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .flex.offset-xl9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .flex.offset-xl9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .flex.offset-xl8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .flex.offset-xl8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .flex.offset-xl7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .flex.offset-xl7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .flex.offset-xl6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .flex.offset-xl6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .flex.offset-xl5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .flex.offset-xl5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .flex.offset-xl4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .flex.offset-xl4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .flex.offset-xl3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .flex.offset-xl3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .flex.offset-xl2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .flex.offset-xl2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .flex.offset-xl1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .flex.offset-xl1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .flex.offset-xl0 {
    margin-left: 0;
  }

  .v-application--is-rtl .flex.offset-xl0 {
    margin-right: 0;
  }
}

.child-flex > *, .flex {
  max-width: 100%;
  flex: auto;
}

.child-flex > .grow-shrink-0, .flex.grow-shrink-0 {
  flex-grow: 0;
  flex-shrink: 0;
}

.grow, .spacer {
  flex-grow: 1 !important;
}

.grow {
  flex-shrink: 0 !important;
}

.shrink {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}

.fill-height {
  height: 100%;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
}

@media (min-width: 960px) {
  .container {
    max-width: 900px;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1785px;
  }
}

.container--fluid {
  max-width: 100%;
}

.row {
  flex-wrap: wrap;
  flex: auto;
  margin: -12px;
  display: flex;
}

.row + .row {
  margin-top: 12px;
}

.row + .row--dense {
  margin-top: 4px;
}

.row--dense {
  margin: -4px;
}

.row--dense > .col, .row--dense > [class*="col-"] {
  padding: 4px;
}

.row.no-gutters {
  margin: 0;
}

.row.no-gutters > .col, .row.no-gutters > [class*="col-"] {
  padding: 0;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
  width: 100%;
  padding: 12px;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.v-application--is-ltr .offset-1 {
  margin-left: 8.33333%;
}

.v-application--is-rtl .offset-1 {
  margin-right: 8.33333%;
}

.v-application--is-ltr .offset-2 {
  margin-left: 16.6667%;
}

.v-application--is-rtl .offset-2 {
  margin-right: 16.6667%;
}

.v-application--is-ltr .offset-3 {
  margin-left: 25%;
}

.v-application--is-rtl .offset-3 {
  margin-right: 25%;
}

.v-application--is-ltr .offset-4 {
  margin-left: 33.3333%;
}

.v-application--is-rtl .offset-4 {
  margin-right: 33.3333%;
}

.v-application--is-ltr .offset-5 {
  margin-left: 41.6667%;
}

.v-application--is-rtl .offset-5 {
  margin-right: 41.6667%;
}

.v-application--is-ltr .offset-6 {
  margin-left: 50%;
}

.v-application--is-rtl .offset-6 {
  margin-right: 50%;
}

.v-application--is-ltr .offset-7 {
  margin-left: 58.3333%;
}

.v-application--is-rtl .offset-7 {
  margin-right: 58.3333%;
}

.v-application--is-ltr .offset-8 {
  margin-left: 66.6667%;
}

.v-application--is-rtl .offset-8 {
  margin-right: 66.6667%;
}

.v-application--is-ltr .offset-9 {
  margin-left: 75%;
}

.v-application--is-rtl .offset-9 {
  margin-right: 75%;
}

.v-application--is-ltr .offset-10 {
  margin-left: 83.3333%;
}

.v-application--is-rtl .offset-10 {
  margin-right: 83.3333%;
}

.v-application--is-ltr .offset-11 {
  margin-left: 91.6667%;
}

.v-application--is-rtl .offset-11 {
  margin-right: 91.6667%;
}

@media (min-width: 600px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .v-application--is-ltr .offset-sm-0 {
    margin-left: 0;
  }

  .v-application--is-rtl .offset-sm-0 {
    margin-right: 0;
  }

  .v-application--is-ltr .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .offset-sm-1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .offset-sm-2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .offset-sm-3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .offset-sm-3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .offset-sm-4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .offset-sm-5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .offset-sm-6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .offset-sm-6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .offset-sm-7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .offset-sm-8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .offset-sm-9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .offset-sm-9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .offset-sm-10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .offset-sm-11 {
    margin-right: 91.6667%;
  }
}

@media (min-width: 960px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .v-application--is-ltr .offset-md-0 {
    margin-left: 0;
  }

  .v-application--is-rtl .offset-md-0 {
    margin-right: 0;
  }

  .v-application--is-ltr .offset-md-1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .offset-md-1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .offset-md-2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .offset-md-2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .offset-md-3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .offset-md-3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .offset-md-4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .offset-md-4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .offset-md-5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .offset-md-5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .offset-md-6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .offset-md-6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .offset-md-7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .offset-md-7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .offset-md-8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .offset-md-8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .offset-md-9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .offset-md-9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .offset-md-10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .offset-md-10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .offset-md-11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .offset-md-11 {
    margin-right: 91.6667%;
  }
}

@media (min-width: 1264px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .v-application--is-ltr .offset-lg-0 {
    margin-left: 0;
  }

  .v-application--is-rtl .offset-lg-0 {
    margin-right: 0;
  }

  .v-application--is-ltr .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .offset-lg-1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .offset-lg-2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .offset-lg-3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .offset-lg-3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .offset-lg-4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .offset-lg-5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .offset-lg-6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .offset-lg-6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .offset-lg-7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .offset-lg-8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .offset-lg-9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .offset-lg-9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .offset-lg-10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .offset-lg-11 {
    margin-right: 91.6667%;
  }
}

@media (min-width: 1904px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .v-application--is-ltr .offset-xl-0 {
    margin-left: 0;
  }

  .v-application--is-rtl .offset-xl-0 {
    margin-right: 0;
  }

  .v-application--is-ltr .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .v-application--is-rtl .offset-xl-1 {
    margin-right: 8.33333%;
  }

  .v-application--is-ltr .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .v-application--is-rtl .offset-xl-2 {
    margin-right: 16.6667%;
  }

  .v-application--is-ltr .offset-xl-3 {
    margin-left: 25%;
  }

  .v-application--is-rtl .offset-xl-3 {
    margin-right: 25%;
  }

  .v-application--is-ltr .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .v-application--is-rtl .offset-xl-4 {
    margin-right: 33.3333%;
  }

  .v-application--is-ltr .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .v-application--is-rtl .offset-xl-5 {
    margin-right: 41.6667%;
  }

  .v-application--is-ltr .offset-xl-6 {
    margin-left: 50%;
  }

  .v-application--is-rtl .offset-xl-6 {
    margin-right: 50%;
  }

  .v-application--is-ltr .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .v-application--is-rtl .offset-xl-7 {
    margin-right: 58.3333%;
  }

  .v-application--is-ltr .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .v-application--is-rtl .offset-xl-8 {
    margin-right: 66.6667%;
  }

  .v-application--is-ltr .offset-xl-9 {
    margin-left: 75%;
  }

  .v-application--is-rtl .offset-xl-9 {
    margin-right: 75%;
  }

  .v-application--is-ltr .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .v-application--is-rtl .offset-xl-10 {
    margin-right: 83.3333%;
  }

  .v-application--is-ltr .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .v-application--is-rtl .offset-xl-11 {
    margin-right: 91.6667%;
  }
}

.theme--light.v-navigation-drawer {
  background-color: #fff;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: #0000001f;
}

.theme--light.v-navigation-drawer .v-divider {
  border-color: #0000001f;
}

.theme--dark.v-navigation-drawer {
  background-color: #363636;
}

.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: #ffffff1f;
}

.theme--dark.v-navigation-drawer .v-divider {
  border-color: #ffffff1f;
}

.v-navigation-drawer {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  pointer-events: auto;
  flex-direction: column;
  transition-property: transform, visibility, width;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  top: 0;
  left: 0;
  overflow: hidden;
}

.v-navigation-drawer:not([data-booted="true"]) {
  transition: none !important;
}

.v-navigation-drawer.v-navigation-drawer--right:after {
  left: 0;
  right: auto;
}

.v-navigation-drawer .v-list:not(.v-select-list) {
  background: inherit;
}

.v-navigation-drawer__border {
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  right: 0;
}

.v-navigation-drawer__content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.v-navigation-drawer__image {
  border-radius: inherit;
  height: 100%;
  z-index: -1;
  contain: strict;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.v-navigation-drawer__image .v-image {
  border-radius: inherit;
}

.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 50%;
  min-width: 100%;
  top: auto;
  bottom: 0;
}

.v-navigation-drawer--right {
  left: auto;
  right: 0;
}

.v-navigation-drawer--right > .v-navigation-drawer__border {
  left: 0;
  right: auto;
}

.v-navigation-drawer--absolute {
  z-index: 1;
}

.v-navigation-drawer--fixed {
  z-index: 6;
}

.v-navigation-drawer--absolute {
  position: absolute;
}

.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile) {
  z-index: 4;
}

.v-navigation-drawer--fixed {
  position: fixed;
}

.v-navigation-drawer--floating:after {
  display: none;
}

.v-navigation-drawer--mini-variant {
  overflow: hidden;
}

.v-navigation-drawer--mini-variant .v-list-item > :first-child {
  margin-left: 0;
  margin-right: 0;
}

.v-navigation-drawer--mini-variant .v-list-item > :not(:first-child) {
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  display: initial;
  overflow: hidden;
  position: absolute !important;
}

.v-navigation-drawer--mini-variant .v-list-group--no-action .v-list-group__items, .v-navigation-drawer--mini-variant .v-list-group--sub-group {
  display: none;
}

.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant .v-list-item {
  justify-content: center;
}

.v-navigation-drawer--temporary {
  z-index: 7;
}

.v-navigation-drawer--mobile {
  z-index: 6;
}

.v-navigation-drawer--close {
  visibility: hidden;
}

.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close), .v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f;
}

.theme--light.v-otp-input .v-input .v-input__control .v-input__slot {
  background: #fff;
}

.theme--dark.v-otp-input .v-input .v-input__control .v-input__slot {
  background: #303030;
}

.v-otp-input {
  flex-wrap: wrap;
  flex: auto;
  margin-left: -4px;
  margin-right: -4px;
  display: flex;
}

.v-otp-input input {
  text-align: center;
}

.v-otp-input .v-input {
  max-width: 100%;
  width: 100%;
  flex: 1 0 32px;
  margin: 0;
  padding: 4px;
}

.v-otp-input .v-input.v-otp-input--plain fieldset {
  display: none;
}

.v-otp-input .v-input input[type="number"]::-webkit-inner-spin-button, .v-otp-input .v-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-otp-input .v-input input[type="number"] {
  -moz-appearance: textfield;
}

.theme--light.v-overflow-btn.theme--light.v-overflow-btn > .v-input__control > .v-input__slot {
  border-color: #0000001f;
}

.theme--light.v-overflow-btn:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: #fff;
}

.theme--light.v-overflow-btn.v-overflow-btn--segmented .v-input__append-inner {
  border-left: thin solid #0000001f;
}

.theme--dark.v-overflow-btn.theme--dark.v-overflow-btn > .v-input__control > .v-input__slot {
  border-color: #ffffff1f;
}

.theme--dark.v-overflow-btn:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: #1e1e1e;
}

.theme--dark.v-overflow-btn.v-overflow-btn--segmented .v-input__append-inner {
  border-left: thin solid #ffffff1f;
}

.v-autocomplete__content.v-menu__content {
  box-shadow: 0 4px 6px #20212447;
}

.v-autocomplete__content.v-menu__content .v-select-list {
  border-radius: 0 0 4px 4px;
}

.v-overflow-btn {
  margin-top: 12px;
  padding-top: 0;
}

.v-overflow-btn:not(.v-overflow-btn--editable) > .v-input__control > .v-input__slot {
  cursor: pointer;
}

.v-overflow-btn .v-input__slot {
  border-style: solid;
  border-width: 2px 0;
}

.v-overflow-btn .v-input__slot:before {
  display: none;
}

.v-overflow-btn .v-select__slot {
  height: 48px;
}

.v-overflow-btn.v-input--dense .v-select__slot {
  height: 38px;
}

.v-overflow-btn.v-input--dense input {
  cursor: pointer;
}

.v-application--is-ltr .v-overflow-btn.v-input--dense input {
  margin-left: 16px;
}

.v-application--is-rtl .v-overflow-btn.v-input--dense input {
  margin-right: 16px;
}

.v-application--is-ltr .v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 16px;
}

.v-application--is-rtl .v-overflow-btn .v-select__selection--comma:first-child {
  margin-right: 16px;
}

.v-overflow-btn .v-input__slot {
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
}

.v-overflow-btn .v-input__slot:after, .v-overflow-btn .v-input__slot:before {
  display: none;
}

.v-overflow-btn .v-label {
  top: calc(50% - 10px);
}

.v-application--is-ltr .v-overflow-btn .v-label {
  margin-left: 16px;
}

.v-application--is-rtl .v-overflow-btn .v-label {
  margin-right: 16px;
}

.v-overflow-btn .v-input__append-inner {
  height: 48px;
  width: 42px;
  flex-shrink: 0;
  align-self: auto;
  align-items: center;
  margin-top: 0;
  padding: 0 4px;
}

.v-overflow-btn .v-input__append-outer, .v-overflow-btn .v-input__prepend-outer {
  margin-top: 12px;
  margin-bottom: 12px;
}

.v-overflow-btn .v-input__control:before {
  height: 1px;
  content: "";
  width: 100%;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  top: -1px;
  left: 0;
}

.v-overflow-btn.v-input--is-focused .v-input__slot, .v-overflow-btn.v-select--is-menu-active .v-input__slot {
  box-shadow: 0 1px 6px #20212447;
  border-color: #0000 !important;
}

.v-overflow-btn.v-input--is-focused .v-input__slot {
  border-radius: 4px;
}

.v-overflow-btn.v-select--is-menu-active .v-input__slot {
  border-radius: 4px 4px 0 0;
}

.v-overflow-btn .v-select__selections {
  width: 0;
}

.v-overflow-btn--segmented .v-input__slot {
  border-width: thin 0;
}

.v-overflow-btn--segmented .v-select__selections {
  flex-wrap: nowrap;
}

.v-overflow-btn--segmented .v-select__selections .v-btn {
  height: 48px;
  width: 100%;
  border-radius: 0;
  margin: 0;
}

.v-application--is-ltr .v-overflow-btn--segmented .v-select__selections .v-btn {
  margin-right: -16px;
}

.v-application--is-rtl .v-overflow-btn--segmented .v-select__selections .v-btn {
  margin-left: -16px;
}

.v-overflow-btn--segmented .v-select__selections .v-btn__content {
  justify-content: start;
}

.v-overflow-btn--segmented .v-select__selections .v-btn__content:before {
  background-color: #0000;
}

.v-overflow-btn--editable .v-select__slot input {
  cursor: text;
  padding: 8px 16px;
}

.v-overflow-btn--editable .v-input__append-inner, .v-overflow-btn--editable .v-input__append-inner * {
  cursor: pointer;
}

.theme--light.v-pagination .v-pagination__item {
  color: #000000de;
  background: #fff;
}

.theme--light.v-pagination .v-pagination__item--active {
  color: #fff;
}

.theme--light.v-pagination .v-pagination__navigation {
  background: #fff;
}

.theme--dark.v-pagination .v-pagination__item {
  color: #fff;
  background: #1e1e1e;
}

.theme--dark.v-pagination .v-pagination__item--active {
  color: #fff;
}

.theme--dark.v-pagination .v-pagination__navigation {
  background: #1e1e1e;
}

.v-pagination {
  max-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  list-style-type: none;
  display: inline-flex;
}

.v-pagination.v-pagination {
  padding-left: 0;
}

.v-pagination > li {
  align-items: center;
  display: flex;
}

.v-pagination--circle .v-pagination__item, .v-pagination--circle .v-pagination__more, .v-pagination--circle .v-pagination__navigation {
  border-radius: 50%;
}

.v-pagination--disabled {
  pointer-events: none;
  opacity: .6;
}

.v-pagination__item {
  height: 34px;
  min-width: 34px;
  width: auto;
  background: none;
  border-radius: 4px;
  margin: .3rem;
  padding: 0 5px;
  font-size: 1rem;
  text-decoration: none;
  transition: all .3s cubic-bezier(0, 0, .2, 1);
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-pagination__item--active {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.v-pagination__navigation {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: .3rem 10px;
  text-decoration: none;
  display: inline-flex;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-pagination__navigation .v-icon {
  vertical-align: middle;
  transition: all .2s cubic-bezier(.4, 0, .6, 1);
}

.v-pagination__navigation--disabled {
  opacity: .6;
  pointer-events: none;
}

.v-pagination__more {
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: flex-end;
  margin: .3rem;
  display: inline-flex;
}

.v-parallax {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

.v-parallax__image-container {
  z-index: 1;
  contain: strict;
  position: absolute;
  inset: 0;
}

.v-parallax__image {
  min-width: 100%;
  min-height: 100%;
  will-change: transform;
  z-index: 1;
  transition: opacity .3s cubic-bezier(.25, .8, .5, 1);
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.v-parallax__content {
  color: #fff;
  height: 100%;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  display: flex;
  position: relative;
}

.v-input--radio-group legend.v-label {
  cursor: text;
  height: auto;
  font-size: 14px;
}

.v-input--radio-group__input {
  cursor: default;
  width: 100%;
  border: none;
  display: flex;
}

.v-input--radio-group--column .v-input--radio-group__input > .v-label {
  padding-bottom: 8px;
}

.v-input--radio-group--row .v-input--radio-group__input > .v-label {
  padding-right: 8px;
}

.v-input--radio-group--row legend {
  align-self: center;
  display: inline-block;
}

.v-input--radio-group--row .v-input--radio-group__input {
  flex-flow: wrap;
}

.v-input--radio-group--column legend {
  padding-bottom: 8px;
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 8px;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: column;
}

.theme--light.v-radio--is-disabled label {
  color: #00000061;
}

.theme--dark.v-radio--is-disabled label {
  color: #ffffff80;
}

.v-radio {
  height: auto;
  outline: none;
  align-items: center;
  display: flex;
}

.v-radio--is-disabled {
  pointer-events: none;
  cursor: default;
}

.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 16px;
}

.theme--light.v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb {
  background: #fafafa;
}

.theme--dark.v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb {
  background: #424242;
}

.v-input--range-slider.v-input--is-disabled .v-slider__track-fill {
  display: none;
}

.v-input--range-slider.v-input--is-disabled.v-input--slider .v-slider.v-slider .v-slider__thumb {
  border-color: #0000;
}

.v-rating {
  max-width: 100%;
  white-space: nowrap;
}

.v-rating .v-icon {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50%;
  outline: none;
  padding: .5rem;
}

.v-rating .v-icon:after {
  display: none;
}

.v-application--is-ltr .v-rating .v-icon {
  transform: scaleX(1);
}

.v-application--is-rtl .v-rating .v-icon {
  transform: scaleX(-1);
}

.v-rating--readonly .v-icon {
  pointer-events: none;
}

.v-rating--dense .v-icon {
  padding: .1rem;
}

.theme--light.v-skeleton-loader .v-skeleton-loader__bone:after {
  background: linear-gradient(90deg, #fff0, #ffffff4d, #fff0);
}

.theme--light.v-skeleton-loader .v-skeleton-loader__avatar, .theme--light.v-skeleton-loader .v-skeleton-loader__button, .theme--light.v-skeleton-loader .v-skeleton-loader__chip, .theme--light.v-skeleton-loader .v-skeleton-loader__divider, .theme--light.v-skeleton-loader .v-skeleton-loader__heading, .theme--light.v-skeleton-loader .v-skeleton-loader__image, .theme--light.v-skeleton-loader .v-skeleton-loader__text {
  background: #0000001f;
}

.theme--light.v-skeleton-loader .v-skeleton-loader__actions, .theme--light.v-skeleton-loader .v-skeleton-loader__article, .theme--light.v-skeleton-loader .v-skeleton-loader__card-heading, .theme--light.v-skeleton-loader .v-skeleton-loader__card-text, .theme--light.v-skeleton-loader .v-skeleton-loader__date-picker, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-text, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-three-line, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-two-line, .theme--light.v-skeleton-loader .v-skeleton-loader__table-heading, .theme--light.v-skeleton-loader .v-skeleton-loader__table-tbody, .theme--light.v-skeleton-loader .v-skeleton-loader__table-tfoot, .theme--light.v-skeleton-loader .v-skeleton-loader__table-thead {
  background: #fff;
}

.theme--dark.v-skeleton-loader .v-skeleton-loader__bone:after {
  background: linear-gradient(90deg, #fff0, #ffffff0d, #fff0);
}

.theme--dark.v-skeleton-loader .v-skeleton-loader__avatar, .theme--dark.v-skeleton-loader .v-skeleton-loader__button, .theme--dark.v-skeleton-loader .v-skeleton-loader__chip, .theme--dark.v-skeleton-loader .v-skeleton-loader__divider, .theme--dark.v-skeleton-loader .v-skeleton-loader__heading, .theme--dark.v-skeleton-loader .v-skeleton-loader__image, .theme--dark.v-skeleton-loader .v-skeleton-loader__text {
  background: #ffffff1f;
}

.theme--dark.v-skeleton-loader .v-skeleton-loader__actions, .theme--dark.v-skeleton-loader .v-skeleton-loader__article, .theme--dark.v-skeleton-loader .v-skeleton-loader__card-heading, .theme--dark.v-skeleton-loader .v-skeleton-loader__card-text, .theme--dark.v-skeleton-loader .v-skeleton-loader__date-picker, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-text, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-three-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-two-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-heading, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-tbody, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-tfoot, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-thead {
  background: #1e1e1e;
}

.v-skeleton-loader {
  vertical-align: top;
  border-radius: 4px;
  position: relative;
}

.v-skeleton-loader__actions {
  text-align: right;
  padding: 16px 16px 8px;
}

.v-skeleton-loader__actions .v-skeleton-loader__button {
  display: inline-block;
}

.v-application--is-ltr .v-skeleton-loader__actions .v-skeleton-loader__button:first-child {
  margin-right: 12px;
}

.v-application--is-rtl .v-skeleton-loader__actions .v-skeleton-loader__button:first-child {
  margin-left: 12px;
}

.v-skeleton-loader .v-skeleton-loader__list-item, .v-skeleton-loader .v-skeleton-loader__list-item-avatar, .v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line, .v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line, .v-skeleton-loader .v-skeleton-loader__list-item-text, .v-skeleton-loader .v-skeleton-loader__list-item-three-line, .v-skeleton-loader .v-skeleton-loader__list-item-two-line {
  border-radius: 4px;
}

.v-skeleton-loader .v-skeleton-loader__actions:after, .v-skeleton-loader .v-skeleton-loader__article:after, .v-skeleton-loader .v-skeleton-loader__card-avatar:after, .v-skeleton-loader .v-skeleton-loader__card-heading:after, .v-skeleton-loader .v-skeleton-loader__card-text:after, .v-skeleton-loader .v-skeleton-loader__card:after, .v-skeleton-loader .v-skeleton-loader__date-picker-days:after, .v-skeleton-loader .v-skeleton-loader__date-picker-options:after, .v-skeleton-loader .v-skeleton-loader__date-picker:after, .v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line:after, .v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line:after, .v-skeleton-loader .v-skeleton-loader__list-item-avatar:after, .v-skeleton-loader .v-skeleton-loader__list-item-text:after, .v-skeleton-loader .v-skeleton-loader__list-item-three-line:after, .v-skeleton-loader .v-skeleton-loader__list-item-two-line:after, .v-skeleton-loader .v-skeleton-loader__list-item:after, .v-skeleton-loader .v-skeleton-loader__paragraph:after, .v-skeleton-loader .v-skeleton-loader__sentences:after, .v-skeleton-loader .v-skeleton-loader__table-cell:after, .v-skeleton-loader .v-skeleton-loader__table-heading:after, .v-skeleton-loader .v-skeleton-loader__table-row-divider:after, .v-skeleton-loader .v-skeleton-loader__table-row:after, .v-skeleton-loader .v-skeleton-loader__table-tbody:after, .v-skeleton-loader .v-skeleton-loader__table-tfoot:after, .v-skeleton-loader .v-skeleton-loader__table-thead:after, .v-skeleton-loader .v-skeleton-loader__table:after {
  display: none;
}

.v-application--is-ltr .v-skeleton-loader__article .v-skeleton-loader__heading {
  margin: 16px 0 16px 16px;
}

.v-application--is-rtl .v-skeleton-loader__article .v-skeleton-loader__heading {
  margin: 16px 16px 0;
}

.v-skeleton-loader__article .v-skeleton-loader__paragraph {
  padding: 16px;
}

.v-skeleton-loader__bone {
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}

.v-skeleton-loader__bone:after {
  content: "";
  height: 100%;
  z-index: 1;
  animation: 1.5s infinite loading;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

.v-skeleton-loader__avatar {
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.v-skeleton-loader__button {
  height: 36px;
  width: 64px;
  border-radius: 4px;
}

.v-skeleton-loader__card .v-skeleton-loader__image {
  border-radius: 0;
}

.v-skeleton-loader__card-heading .v-skeleton-loader__heading {
  margin: 16px;
}

.v-skeleton-loader__card-text {
  padding: 16px;
}

.v-skeleton-loader__chip {
  height: 32px;
  width: 96px;
  border-radius: 16px;
}

.v-skeleton-loader__date-picker {
  border-radius: inherit;
}

.v-skeleton-loader__date-picker .v-skeleton-loader__list-item:first-child .v-skeleton-loader__text {
  max-width: 88px;
  width: 20%;
}

.v-skeleton-loader__date-picker .v-skeleton-loader__heading {
  max-width: 256px;
  width: 40%;
}

.v-skeleton-loader__date-picker-days {
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 12px;
  display: flex;
}

.v-skeleton-loader__date-picker-days .v-skeleton-loader__avatar {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  flex: auto;
  margin: 4px;
}

.v-skeleton-loader__date-picker-options {
  align-items: center;
  padding: 16px;
  display: flex;
}

.v-skeleton-loader__date-picker-options .v-skeleton-loader__avatar {
  height: 40px;
  width: 40px;
}

.v-skeleton-loader__date-picker-options .v-skeleton-loader__avatar:nth-child(2) {
  margin-left: auto;
}

.v-application--is-ltr .v-skeleton-loader__date-picker-options .v-skeleton-loader__avatar:nth-child(2) {
  margin-right: 8px;
}

.v-application--is-rtl .v-skeleton-loader__date-picker-options .v-skeleton-loader__avatar:nth-child(2) {
  margin-left: 8px;
}

.v-skeleton-loader__date-picker-options .v-skeleton-loader__text.v-skeleton-loader__bone:first-child {
  max-width: 50%;
  width: 456px;
  margin-bottom: 0;
}

.v-skeleton-loader__divider {
  height: 2px;
  border-radius: 1px;
}

.v-skeleton-loader__heading {
  height: 24px;
  width: 45%;
  border-radius: 12px;
}

.v-skeleton-loader__image {
  height: 200px;
  border-radius: 0;
}

.v-skeleton-loader__image ~ .v-skeleton-loader__card-heading {
  border-radius: 0;
}

.v-skeleton-loader__image:first-child, .v-skeleton-loader__image:last-child {
  border-radius: inherit;
}

.v-skeleton-loader__list-item {
  height: 48px;
}

.v-skeleton-loader__list-item-three-line {
  flex-wrap: wrap;
}

.v-skeleton-loader__list-item-three-line > * {
  width: 100%;
  flex: 1 0 100%;
}

.v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__avatar, .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__avatar, .v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar {
  height: 40px;
  width: 40px;
}

.v-skeleton-loader__list-item-avatar {
  height: 48px;
}

.v-skeleton-loader__list-item-avatar-two-line, .v-skeleton-loader__list-item-two-line {
  height: 72px;
}

.v-skeleton-loader__list-item-avatar-three-line, .v-skeleton-loader__list-item-three-line {
  height: 88px;
}

.v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__avatar {
  align-self: flex-start;
}

.v-skeleton-loader__list-item, .v-skeleton-loader__list-item-avatar, .v-skeleton-loader__list-item-avatar-three-line, .v-skeleton-loader__list-item-avatar-two-line, .v-skeleton-loader__list-item-three-line, .v-skeleton-loader__list-item-two-line {
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.v-application--is-ltr .v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-three-line .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item-two-line .v-skeleton-loader__avatar, .v-application--is-ltr .v-skeleton-loader__list-item .v-skeleton-loader__avatar {
  margin-right: 16px;
}

.v-application--is-rtl .v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-three-line .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item-two-line .v-skeleton-loader__avatar, .v-application--is-rtl .v-skeleton-loader__list-item .v-skeleton-loader__avatar {
  margin-left: 16px;
}

.v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__text:last-child, .v-skeleton-loader__list-item-avatar-three-line .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__text:last-child, .v-skeleton-loader__list-item-avatar-two-line .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-avatar .v-skeleton-loader__text:last-child, .v-skeleton-loader__list-item-avatar .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-three-line .v-skeleton-loader__text:last-child, .v-skeleton-loader__list-item-three-line .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item-two-line .v-skeleton-loader__text:last-child, .v-skeleton-loader__list-item-two-line .v-skeleton-loader__text:only-child, .v-skeleton-loader__list-item .v-skeleton-loader__text:last-child, .v-skeleton-loader__list-item .v-skeleton-loader__text:only-child {
  margin-bottom: 0;
}

.v-skeleton-loader__paragraph, .v-skeleton-loader__sentences {
  flex: 1 0 auto;
}

.v-skeleton-loader__paragraph:not(:last-child) {
  margin-bottom: 6px;
}

.v-skeleton-loader__paragraph .v-skeleton-loader__text:first-child {
  max-width: 100%;
}

.v-skeleton-loader__paragraph .v-skeleton-loader__text:nth-child(2) {
  max-width: 50%;
}

.v-skeleton-loader__paragraph .v-skeleton-loader__text:nth-child(3), .v-skeleton-loader__sentences .v-skeleton-loader__text:nth-child(2) {
  max-width: 70%;
}

.v-skeleton-loader__sentences:not(:last-child) {
  margin-bottom: 6px;
}

.v-skeleton-loader__table-heading {
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
}

.v-skeleton-loader__table-heading .v-skeleton-loader__heading {
  max-width: 15%;
}

.v-skeleton-loader__table-heading .v-skeleton-loader__text {
  max-width: 40%;
}

.v-skeleton-loader__table-thead {
  justify-content: space-between;
  padding: 16px;
  display: flex;
}

.v-skeleton-loader__table-thead .v-skeleton-loader__heading {
  max-width: 5%;
}

.v-skeleton-loader__table-tbody {
  padding: 16px 16px 0;
}

.v-skeleton-loader__table-tfoot {
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  display: flex;
}

.v-application--is-ltr .v-skeleton-loader__table-tfoot > * {
  margin-left: 8px;
}

.v-application--is-rtl .v-skeleton-loader__table-tfoot > * {
  margin-right: 8px;
}

.v-skeleton-loader__table-tfoot .v-skeleton-loader__avatar {
  height: 40px;
  width: 40px;
}

.v-skeleton-loader__table-tfoot .v-skeleton-loader__text {
  margin-bottom: 0;
}

.v-skeleton-loader__table-tfoot .v-skeleton-loader__text:first-child {
  max-width: 128px;
}

.v-skeleton-loader__table-tfoot .v-skeleton-loader__text:nth-child(2) {
  max-width: 64px;
}

.v-skeleton-loader__table-row {
  justify-content: space-between;
  display: flex;
}

.v-skeleton-loader__table-cell {
  height: 48px;
  width: 88px;
  align-items: center;
  display: flex;
}

.v-skeleton-loader__table-cell .v-skeleton-loader__text {
  margin-bottom: 0;
}

.v-skeleton-loader__text {
  height: 12px;
  border-radius: 6px;
  flex: 1 0 auto;
  margin-bottom: 6px;
}

.v-skeleton-loader--boilerplate .v-skeleton-loader__bone:after {
  display: none;
}

.v-skeleton-loader--is-loading {
  overflow: hidden;
}

.v-skeleton-loader--tile, .v-skeleton-loader--tile .v-skeleton-loader__bone {
  border-radius: 0;
}

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}

.theme--light.v-snack__wrapper {
  color: #000000de;
}

.theme--dark.v-snack__wrapper {
  color: #fff;
}

.v-sheet.v-snack__wrapper {
  border-radius: 4px;
}

.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}

.v-sheet.v-snack__wrapper.v-sheet--shaped {
  border-radius: 24px 4px;
}

.v-snack {
  pointer-events: none;
  width: 100%;
  justify-content: center;
  font-size: .875rem;
  display: flex;
  inset: 0;
}

.v-snack:not(.v-snack--absolute) {
  height: 100vh;
  z-index: 1000;
  position: fixed;
}

.v-snack:not(.v-snack--centered):not(.v-snack--top) {
  align-items: flex-end;
}

.v-snack__wrapper {
  max-width: 672px;
  min-height: 48px;
  min-width: 344px;
  pointer-events: auto;
  z-index: 1;
  align-items: center;
  margin: 8px;
  padding: 0;
  transition-property: opacity, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: flex;
  position: relative;
  border-color: currentColor !important;
}

.v-snack__wrapper.theme--dark {
  color: #ffffffde;
  background-color: #333;
}

.v-snack__content {
  letter-spacing: .0178571em;
  text-align: initial;
  flex-grow: 1;
  margin-right: auto;
  padding: 14px 16px;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.v-snack__action {
  align-self: center;
  align-items: center;
  display: flex;
}

.v-snack__action .v-ripple__container {
  display: none;
}

.v-application--is-ltr .v-snack__action {
  margin-right: 8px;
}

.v-application--is-rtl .v-snack__action {
  margin-left: 8px;
}

.v-snack__action > .v-snack__btn.v-btn {
  padding: 0 8px;
}

.v-snack__btn {
  min-width: auto;
  margin: 0;
}

.v-snack--absolute {
  height: 100%;
  z-index: 1;
  position: absolute;
}

.v-snack--centered {
  align-items: center;
}

.v-snack--left {
  justify-content: flex-start;
  right: auto;
}

.v-snack--multi-line .v-snack__wrapper {
  min-height: 68px;
}

.v-snack--right {
  justify-content: flex-end;
  left: auto;
}

.v-snack:not(.v-snack--has-background) .v-snack__wrapper {
  box-shadow: none;
}

.v-snack--bottom {
  top: auto;
}

.v-snack--text .v-snack__wrapper:before {
  border-radius: inherit;
  content: "";
  opacity: .12;
  pointer-events: none;
  z-index: -1;
  background-color: currentColor;
  position: absolute;
  inset: 0;
}

.v-snack--top {
  align-items: flex-start;
  bottom: auto;
}

.v-snack--vertical .v-snack__wrapper {
  flex-direction: column;
}

.v-snack--vertical .v-snack__wrapper .v-snack__action {
  align-self: flex-end;
  margin-bottom: 8px;
}

.v-snack-transition-enter.v-snack__wrapper {
  transform: scale(.8);
}

.v-snack-transition-enter.v-snack__wrapper, .v-snack-transition-leave-to.v-snack__wrapper {
  opacity: 0;
}

.v-speed-dial {
  z-index: 1;
  position: relative;
}

.v-speed-dial--absolute {
  position: absolute;
}

.v-speed-dial--fixed {
  position: fixed;
}

.v-speed-dial--absolute, .v-speed-dial--fixed {
  z-index: 4;
}

.v-speed-dial--absolute > .v-btn--floating, .v-speed-dial--fixed > .v-btn--floating {
  margin: 0;
}

.v-speed-dial--top {
  top: 16px;
}

.v-speed-dial--bottom {
  bottom: 16px;
}

.v-speed-dial--left {
  left: 16px;
}

.v-speed-dial--right {
  right: 16px;
}

.v-speed-dial--direction-left .v-speed-dial__list, .v-speed-dial--direction-right .v-speed-dial__list {
  height: 100%;
  padding: 0 16px;
  top: 0;
}

.v-speed-dial--direction-bottom .v-speed-dial__list, .v-speed-dial--direction-top .v-speed-dial__list {
  width: 100%;
  left: 0;
}

.v-speed-dial--direction-top .v-speed-dial__list {
  flex-direction: column-reverse;
  bottom: 100%;
}

.v-speed-dial--direction-right .v-speed-dial__list {
  flex-direction: row;
  left: 100%;
}

.v-speed-dial--direction-bottom .v-speed-dial__list {
  flex-direction: column;
  top: 100%;
}

.v-speed-dial--direction-left .v-speed-dial__list {
  flex-direction: row-reverse;
  right: 100%;
}

.v-speed-dial__list {
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  display: flex;
  position: absolute;
}

.v-speed-dial__list .v-btn {
  margin: 6px;
}

.v-speed-dial:not(.v-speed-dial--is-active) .v-speed-dial__list {
  pointer-events: none;
}

.theme--light.v-stepper {
  background: #fff;
}

.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: #00000061;
}

.theme--light.v-stepper .v-stepper__step__step, .theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: #fff;
}

.theme--light.v-stepper .v-stepper__header .v-divider {
  border-color: #0000001f;
}

.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0 0 #000;
}

.theme--light.v-stepper .v-stepper__step--editable:hover {
  background: #0000000f;
}

.theme--light.v-stepper .v-stepper__step--editable:hover .v-stepper__label {
  text-shadow: 0 0 #000;
}

.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: #000000de;
}

.theme--light.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step {
  background: #0000008a;
}

.theme--light.v-stepper .v-stepper__label {
  color: #00000061;
}

.theme--light.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label, .theme--light.v-stepper .v-stepper__label small {
  color: #0009;
}

.v-application--is-ltr .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 1px solid #0000001f;
}

.v-application--is-rtl .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-right: 1px solid #0000001f;
}

.theme--dark.v-stepper {
  background: #303030;
}

.theme--dark.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: #ffffff80;
}

.theme--dark.v-stepper .v-stepper__step__step, .theme--dark.v-stepper .v-stepper__step__step .v-icon {
  color: #fff;
}

.theme--dark.v-stepper .v-stepper__header .v-divider {
  border-color: #ffffff1f;
}

.theme--dark.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0 0 #fff;
}

.theme--dark.v-stepper .v-stepper__step--editable:hover {
  background: #ffffff0f;
}

.theme--dark.v-stepper .v-stepper__step--editable:hover .v-stepper__label {
  text-shadow: 0 0 #fff;
}

.theme--dark.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: #ffffffde;
}

.theme--dark.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step {
  background: #ffffffbf;
}

.theme--dark.v-stepper .v-stepper__label {
  color: #ffffff80;
}

.theme--dark.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label, .theme--dark.v-stepper .v-stepper__label small {
  color: #ffffffb3;
}

.v-application--is-ltr .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 1px solid #ffffff1f;
}

.v-application--is-rtl .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-right: 1px solid #ffffff1f;
}

.v-sheet.v-stepper {
  border-radius: 4px;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-sheet.v-stepper.v-sheet--shaped {
  border-radius: 24px 4px;
}

.v-stepper {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.v-stepper__header {
  height: 72px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.v-stepper__header .v-divider {
  align-self: center;
  margin: 0 -16px;
}

.v-stepper__items {
  position: relative;
  overflow: hidden;
}

.v-stepper__step__step {
  height: 24px;
  min-width: 24px;
  width: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  display: inline-flex;
}

.v-application--is-ltr .v-stepper__step__step {
  margin-right: 8px;
}

.v-application--is-rtl .v-stepper__step__step {
  margin-left: 8px;
}

.v-stepper__step__step .v-icon.v-icon {
  font-size: 1.25rem;
}

.v-stepper__step__step .v-icon.v-icon.v-icon--svg {
  height: 1.25rem;
  width: 1.25rem;
}

.v-stepper__step {
  flex-direction: row;
  align-items: center;
  padding: 24px;
  display: flex;
  position: relative;
}

.v-stepper__step--active .v-stepper__label {
  transition: all .3s cubic-bezier(.4, 0, .6, 1);
}

.v-stepper__step--editable {
  cursor: pointer;
}

.v-stepper__step.v-stepper__step--error .v-stepper__step__step {
  color: inherit;
  background: none;
}

.v-stepper__step.v-stepper__step--error .v-stepper__step__step .v-icon {
  color: inherit;
  font-size: 1.5rem;
}

.v-stepper__step.v-stepper__step--error .v-stepper__label {
  color: inherit;
  text-shadow: none;
  font-weight: 500;
}

.v-stepper__step.v-stepper__step--error .v-stepper__label small {
  color: inherit;
}

.v-stepper__label {
  flex-grow: 1;
  line-height: 1;
  display: block;
}

.v-application--is-ltr .v-stepper__label {
  text-align: left;
}

.v-application--is-rtl .v-stepper__label {
  text-align: right;
}

.v-stepper__label small {
  text-shadow: none;
  font-size: .75rem;
  font-weight: 300;
  display: block;
}

.v-stepper__wrapper {
  transition: none;
  overflow: hidden;
}

.v-stepper__content {
  width: 100%;
  flex: 1 0 auto;
  padding: 24px 24px 16px;
  top: 0;
}

.v-stepper__content > .v-btn {
  margin: 24px 8px 8px 0;
}

.v-stepper--flat {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f !important;
}

.v-stepper--is-booted .v-stepper__content, .v-stepper--is-booted .v-stepper__wrapper {
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
}

.v-stepper--vertical {
  padding-bottom: 36px;
}

.v-stepper--vertical .v-stepper__content {
  width: auto;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  padding: 16px 60px 16px 23px;
}

.v-application--is-rtl .v-stepper--vertical .v-stepper__content {
  padding: 16px 23px 16px 60px;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 36px;
}

.v-application--is-rtl .v-stepper--vertical .v-stepper__content {
  margin: -8px 36px -16px -36px;
}

.v-stepper--vertical .v-stepper__step {
  padding: 24px 24px 16px;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__step__step {
  margin-right: 12px;
}

.v-application--is-rtl .v-stepper--vertical .v-stepper__step__step {
  margin-left: 12px;
}

.v-stepper--alt-labels .v-stepper__header {
  height: auto;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  align-self: flex-start;
  margin: 35px -67px 0;
}

.v-stepper--alt-labels .v-stepper__step {
  flex-direction: column;
  flex-basis: 175px;
  justify-content: flex-start;
  align-items: center;
}

.v-stepper--alt-labels .v-stepper__step small {
  text-align: center;
}

.v-stepper--alt-labels .v-stepper__step__step {
  margin-bottom: 11px;
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (max-width: 959.98px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: none;
  }

  .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
    margin-left: 0;
    margin-right: 0;
  }
}

.theme--light.v-input--switch .v-input--switch__thumb {
  color: #fff;
}

.theme--light.v-input--switch .v-input--switch__track {
  color: #00000061;
}

.theme--light.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty) .v-input--switch__thumb {
  color: #fafafa !important;
}

.theme--light.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty) .v-input--switch__track {
  color: #0000001f !important;
}

.theme--dark.v-input--switch .v-input--switch__thumb {
  color: #bdbdbd;
}

.theme--dark.v-input--switch .v-input--switch__track {
  color: #ffffff4d;
}

.theme--dark.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty) .v-input--switch__thumb {
  color: #424242 !important;
}

.theme--dark.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty) .v-input--switch__track {
  color: #ffffff1a !important;
}

.v-input--switch__thumb, .v-input--switch__track {
  pointer-events: none;
  background-color: currentColor;
  transition: inherit;
}

.v-input--switch__track {
  width: 36px;
  height: 14px;
  opacity: .6;
  border-radius: 8px;
  position: absolute;
  top: calc(50% - 7px);
  left: 2px;
  right: 2px;
}

.v-input--switch__thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  display: flex;
  position: relative;
  top: calc(50% - 10px);
}

.v-input--switch .v-input--selection-controls__input {
  width: 38px;
  transition: opacity .3s cubic-bezier(.25, .8, .5, 1);
}

.v-input--switch .v-input--selection-controls__ripple {
  top: calc(50% - 24px);
}

.v-input--switch.v-input--dense .v-input--switch__thumb {
  width: 18px;
  height: 18px;
}

.v-input--switch.v-input--dense .v-input--switch__track {
  height: 12px;
  width: 32px;
}

.v-input--switch.v-input--dense.v-input--switch--inset .v-input--switch__track {
  height: 22px;
  width: 44px;
  top: calc(50% - 12px);
  left: -3px;
}

.v-input--switch.v-input--dense .v-input--selection-controls__ripple {
  top: calc(50% - 22px);
}

.v-input--switch.v-input--is-dirty.v-input--is-disabled .v-input--selection-controls__input {
  opacity: .6;
}

.v-application--is-ltr .v-input--switch .v-input--selection-controls__ripple {
  left: -14px;
}

.v-application--is-ltr .v-input--switch.v-input--dense .v-input--selection-controls__ripple {
  left: -12px;
}

.v-application--is-ltr .v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple, .v-application--is-ltr .v-input--switch.v-input--is-dirty .v-input--switch__thumb {
  transform: translate(20px);
}

.v-application--is-rtl .v-input--switch .v-input--selection-controls__ripple {
  right: -14px;
}

.v-application--is-rtl .v-input--switch.v-input--dense .v-input--selection-controls__ripple {
  right: -12px;
}

.v-application--is-rtl .v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple, .v-application--is-rtl .v-input--switch.v-input--is-dirty .v-input--switch__thumb {
  transform: translate(-20px);
}

.v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset) .v-input--switch__thumb {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.v-input--switch--inset .v-input--selection-controls__input, .v-input--switch--inset .v-input--switch__track {
  width: 48px;
}

.v-input--switch--inset .v-input--switch__track {
  height: 28px;
  opacity: .32;
  border-radius: 14px;
  top: calc(50% - 14px);
  left: -4px;
}

.v-application--is-ltr .v-input--switch--inset .v-input--selection-controls__ripple, .v-application--is-ltr .v-input--switch--inset .v-input--switch__thumb {
  transform: translate(0) !important;
}

.v-application--is-rtl .v-input--switch--inset .v-input--selection-controls__ripple, .v-application--is-rtl .v-input--switch--inset .v-input--switch__thumb {
  transform: translate(-6px) !important;
}

.v-application--is-ltr .v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple, .v-application--is-ltr .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
  transform: translate(20px) !important;
}

.v-application--is-rtl .v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple, .v-application--is-rtl .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
  transform: translate(-26px) !important;
}

.theme--light.v-system-bar {
  color: #0009;
  background-color: #e0e0e0;
}

.theme--light.v-system-bar .v-icon {
  color: #0009;
}

.theme--light.v-system-bar--lights-out {
  background-color: #ffffffb3 !important;
}

.theme--dark.v-system-bar {
  color: #ffffffb3;
  background-color: #000;
}

.theme--dark.v-system-bar .v-icon {
  color: #ffffffb3;
}

.theme--dark.v-system-bar--lights-out {
  background-color: #0003 !important;
}

.v-system-bar {
  align-items: center;
  padding: 0 8px;
  font-size: .875rem;
  font-weight: 400;
  display: flex;
}

.v-system-bar .v-icon {
  margin-right: 4px;
  font-size: 1rem;
}

.v-system-bar--absolute, .v-system-bar--fixed {
  width: 100%;
  z-index: 3;
  top: 0;
  left: 0;
}

.v-system-bar--fixed {
  position: fixed;
}

.v-system-bar--absolute {
  position: absolute;
}

.v-system-bar--window .v-icon {
  margin-right: 8px;
  font-size: 1.25rem;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: #fff;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon {
  color: #0000008a;
}

.theme--light.v-tabs .v-tab:hover:before {
  opacity: .04;
}

.theme--light.v-tabs .v-tab--active:before, .theme--light.v-tabs .v-tab--active:hover:before, .theme--light.v-tabs .v-tab:focus:before {
  opacity: .12;
}

.theme--light.v-tabs .v-tab--active:focus:before {
  opacity: .16;
}

.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #1e1e1e;
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled, .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon {
  color: #fff9;
}

.theme--dark.v-tabs .v-tab:hover:before {
  opacity: .08;
}

.theme--dark.v-tabs .v-tab--active:before, .theme--dark.v-tabs .v-tab--active:hover:before, .theme--dark.v-tabs .v-tab:focus:before {
  opacity: .24;
}

.theme--dark.v-tabs .v-tab--active:focus:before {
  opacity: .32;
}

.theme--light.v-tabs-items {
  background-color: #fff;
}

.theme--dark.v-tabs-items {
  background-color: #1e1e1e;
}

.v-tabs-bar.accent .v-tab, .v-tabs-bar.accent .v-tabs-slider, .v-tabs-bar.error .v-tab, .v-tabs-bar.error .v-tabs-slider, .v-tabs-bar.info .v-tab, .v-tabs-bar.info .v-tabs-slider, .v-tabs-bar.primary .v-tab, .v-tabs-bar.primary .v-tabs-slider, .v-tabs-bar.secondary .v-tab, .v-tabs-bar.secondary .v-tabs-slider, .v-tabs-bar.success .v-tab, .v-tabs-bar.success .v-tabs-slider, .v-tabs-bar.warning .v-tab, .v-tabs-bar.warning .v-tabs-slider {
  color: #fff;
}

.v-tabs {
  width: 100%;
  flex: auto;
}

.v-tabs .v-menu__activator {
  height: 100%;
}

.v-tabs.v.tabs--vertical.v-tabs--right {
  flex-direction: row-reverse;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  white-space: normal;
}

.v-tabs:not(.v-tabs--vertical).v-tabs--right > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next, .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: initial;
  visibility: hidden;
}

.v-tabs-bar {
  border-radius: inherit;
  height: 48px;
}

.v-tabs-bar.v-item-group > * {
  cursor: auto;
}

.v-tab {
  cursor: pointer;
  letter-spacing: .0892857em;
  min-width: 90px;
  max-width: 360px;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  transition: none;
  display: flex;
  position: relative;
}

.v-tab.v-tab {
  color: inherit;
}

.v-tab:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  inset: 0;
}

.v-tab:before, .v-tabs-slider {
  background-color: currentColor;
}

.v-tabs-slider {
  height: 100%;
  width: 100%;
}

.v-tabs-slider-wrapper {
  z-index: 1;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  bottom: 0;
  margin: 0 !important;
}

.v-application--is-ltr .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tab:first-child, .v-application--is-ltr .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tabs-slider-wrapper + .v-tab {
  margin-left: 42px;
}

.v-application--is-rtl .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tab:first-child, .v-application--is-rtl .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tabs-slider-wrapper + .v-tab {
  margin-right: 42px;
}

.v-application--is-ltr .v-tabs--centered > .v-tabs-bar .v-tabs-bar__content > :last-child, .v-application--is-ltr .v-tabs--fixed-tabs > .v-tabs-bar .v-tabs-bar__content > :last-child {
  margin-right: auto;
}

.v-application--is-ltr .v-tabs--centered > .v-tabs-bar .v-tabs-bar__content > :first-child:not(.v-tabs-slider-wrapper), .v-application--is-ltr .v-tabs--centered > .v-tabs-bar .v-tabs-slider-wrapper + *, .v-application--is-ltr .v-tabs--fixed-tabs > .v-tabs-bar .v-tabs-bar__content > :first-child:not(.v-tabs-slider-wrapper), .v-application--is-ltr .v-tabs--fixed-tabs > .v-tabs-bar .v-tabs-slider-wrapper + *, .v-application--is-rtl .v-tabs--centered > .v-tabs-bar .v-tabs-bar__content > :last-child, .v-application--is-rtl .v-tabs--fixed-tabs > .v-tabs-bar .v-tabs-bar__content > :last-child {
  margin-left: auto;
}

.v-application--is-rtl .v-tabs--centered > .v-tabs-bar .v-tabs-bar__content > :first-child:not(.v-tabs-slider-wrapper), .v-application--is-rtl .v-tabs--centered > .v-tabs-bar .v-tabs-slider-wrapper + *, .v-application--is-rtl .v-tabs--fixed-tabs > .v-tabs-bar .v-tabs-bar__content > :first-child:not(.v-tabs-slider-wrapper), .v-application--is-rtl .v-tabs--fixed-tabs > .v-tabs-bar .v-tabs-slider-wrapper + * {
  margin-right: auto;
}

.v-tabs--fixed-tabs > .v-tabs-bar .v-tab {
  width: 100%;
  flex: auto;
}

.v-tabs--grow > .v-tabs-bar .v-tab {
  max-width: none;
  flex: 1 0 auto;
}

.v-tabs--icons-and-text > .v-tabs-bar {
  height: 72px;
}

.v-tabs--icons-and-text > .v-tabs-bar .v-tab {
  flex-direction: column-reverse;
}

.v-tabs--icons-and-text > .v-tabs-bar .v-tab > :first-child {
  margin-bottom: 6px;
}

.v-tabs--overflow > .v-tabs-bar .v-tab {
  flex: 1 0 auto;
}

.v-application--is-ltr .v-tabs--right > .v-tabs-bar .v-tab:first-child, .v-application--is-ltr .v-tabs--right > .v-tabs-bar .v-tabs-slider-wrapper + .v-tab {
  margin-left: auto;
}

.v-application--is-rtl .v-tabs--right > .v-tabs-bar .v-tab:first-child, .v-application--is-rtl .v-tabs--right > .v-tabs-bar .v-tabs-slider-wrapper + .v-tab {
  margin-right: auto;
}

.v-application--is-ltr .v-tabs--right > .v-tabs-bar .v-tab:last-child {
  margin-right: 0;
}

.v-application--is-rtl .v-tabs--right > .v-tabs-bar .v-tab:last-child {
  margin-left: 0;
}

.v-tabs--vertical {
  display: flex;
}

.v-tabs--vertical > .v-tabs-bar {
  height: auto;
  flex: 1 0 auto;
}

.v-tabs--vertical > .v-tabs-bar .v-slide-group__next, .v-tabs--vertical > .v-tabs-bar .v-slide-group__prev {
  display: none;
}

.v-tabs--vertical > .v-tabs-bar .v-tabs-bar__content {
  flex-direction: column;
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 48px;
}

.v-tabs--vertical > .v-tabs-bar .v-tabs-slider {
  height: 100%;
}

.v-tabs--vertical > .v-window {
  flex: 0 100%;
}

.v-tabs--vertical.v-tabs--icons-and-text > .v-tabs-bar .v-tab {
  height: 72px;
}

.v-tab--active {
  color: inherit;
}

.v-tab--active.v-tab:not(:focus):before {
  opacity: 0;
}

.v-tab--active .v-btn.v-btn--flat, .v-tab--active .v-icon {
  color: inherit;
}

.v-tab--disabled {
  opacity: .5;
}

.v-tab--disabled, .v-tab--disabled * {
  pointer-events: none;
}

.theme--light.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea {
  color: #fff;
}

.theme--light.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea::-moz-placeholder {
  color: #ffffff80;
}

.theme--light.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea:-ms-input-placeholder {
  color: #ffffff80;
}

.theme--light.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea::placeholder {
  color: #ffffff80;
}

.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea {
  color: #000000de;
}

.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea::-moz-placeholder {
  color: #00000061;
}

.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea:-ms-input-placeholder {
  color: #00000061;
}

.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea::placeholder {
  color: #00000061;
}

.v-textarea textarea {
  max-width: 100%;
  min-height: 32px;
  width: 100%;
  outline: none;
  flex: auto;
  align-self: stretch;
  padding: 0;
  line-height: 1.75rem;
}

.v-textarea .v-text-field__prefix, .v-textarea .v-text-field__suffix {
  align-self: start;
  padding-top: 2px;
}

.v-textarea.v-text-field--box .v-text-field__prefix, .v-textarea.v-text-field--box textarea, .v-textarea.v-text-field--enclosed .v-text-field__prefix, .v-textarea.v-text-field--enclosed textarea {
  margin-top: 24px;
}

.v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-text-field__prefix, .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-text-field__suffix, .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) textarea, .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-text-field__prefix, .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-text-field__suffix, .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) textarea, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-text-field__prefix, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-text-field__suffix, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) textarea, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-text-field__prefix, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-text-field__suffix, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) textarea {
  margin-top: 10px;
}

.v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-label, .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-label, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-label, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-label {
  top: 18px;
}

.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-text-field__prefix, .v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-text-field__suffix, .v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense textarea, .v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-text-field__prefix, .v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-text-field__suffix, .v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense textarea, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-text-field__prefix, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-text-field__suffix, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense textarea, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-text-field__prefix, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-text-field__suffix, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense textarea {
  margin-top: 6px;
}

.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-input__append-inner, .v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-input__append-outer, .v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-input__prepend-inner, .v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-input__prepend-outer, .v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-input__append-inner, .v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-input__append-outer, .v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-input__prepend-inner, .v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-input__prepend-outer, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__append-inner, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__append-outer, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__prepend-inner, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__prepend-outer, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-input__append-inner, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-input__append-outer, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-input__prepend-inner, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-input__prepend-outer {
  align-self: flex-start;
  margin-top: 8px;
}

.v-textarea.v-text-field--solo {
  align-items: flex-start;
}

.v-textarea.v-text-field--solo .v-input__control textarea {
  caret-color: auto;
}

.v-textarea.v-text-field--solo .v-input__append-inner, .v-textarea.v-text-field--solo .v-input__append-outer, .v-textarea.v-text-field--solo .v-input__prepend-inner, .v-textarea.v-text-field--solo .v-input__prepend-outer {
  align-self: flex-start;
  margin-top: 12px;
}

.v-application--is-ltr .v-textarea.v-text-field--solo .v-input__append-inner {
  padding-left: 12px;
}

.v-application--is-rtl .v-textarea.v-text-field--solo .v-input__append-inner {
  padding-right: 12px;
}

.v-textarea--auto-grow textarea {
  overflow: hidden;
}

.v-textarea--no-resize textarea {
  resize: none;
}

.v-textarea.v-text-field--enclosed .v-text-field__slot {
  align-self: stretch;
}

.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: -12px;
}

.v-application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-left: -12px;
}

.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-right: 12px;
}

.v-application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-left: 12px;
}

.theme--light.v-timeline:before {
  background: #0000001f;
}

.theme--light.v-timeline .v-timeline-item__dot {
  background: #fff;
}

.theme--light.v-timeline .v-timeline-item .v-card:not(.v-card--link):before {
  border-right-color: #0000001f;
}

.theme--dark.v-timeline:before {
  background: #ffffff1f;
}

.theme--dark.v-timeline .v-timeline-item__dot {
  background: #1e1e1e;
}

.theme--dark.v-timeline .v-timeline-item .v-card:not(.v-card--link):before {
  border-right-color: #0000001f;
}

.v-timeline {
  padding-top: 24px;
  position: relative;
}

.v-timeline:before {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
}

.v-timeline-item {
  padding-bottom: 24px;
  display: flex;
}

.v-timeline-item__body {
  height: 100%;
  flex: auto;
  position: relative;
}

.v-timeline-item__divider {
  min-width: 96px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.v-timeline-item__dot {
  z-index: 2;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  left: calc(50% - 19px);
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.v-timeline-item__dot .v-timeline-item__inner-dot {
  height: 30px;
  width: 30px;
  margin: 4px;
}

.v-timeline-item__dot--small {
  height: 24px;
  width: 24px;
  left: calc(50% - 12px);
}

.v-timeline-item__dot--small .v-timeline-item__inner-dot {
  height: 18px;
  width: 18px;
  margin: 3px;
}

.v-timeline-item__dot--large {
  height: 52px;
  width: 52px;
  left: calc(50% - 26px);
}

.v-timeline-item__dot--large .v-timeline-item__inner-dot {
  height: 42px;
  width: 42px;
  margin: 5px;
}

.v-timeline-item__inner-dot {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.v-timeline-item__opposite {
  max-width: calc(50% - 48px);
  flex: auto;
  align-self: center;
}

.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after, .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--before) {
  flex-direction: row-reverse;
}

.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__opposite, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--before) .v-timeline-item__opposite {
  text-align: right;
}

.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__opposite, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--before) .v-timeline-item__opposite {
  text-align: left;
}

.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link):before, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: -10px;
  right: auto;
  transform: rotate(0);
}

.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link):before, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: auto;
  right: -10px;
  transform: rotate(180deg);
}

.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--after .v-timeline-item__body, .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--before) .v-timeline-item__body {
  max-width: calc(50% - 48px);
}

.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before, .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n):not(.v-timeline-item--after) {
  flex-direction: row;
}

.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__opposite, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n):not(.v-timeline-item--after) .v-timeline-item__opposite {
  text-align: left;
}

.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__opposite, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n):not(.v-timeline-item--after) .v-timeline-item__opposite {
  text-align: right;
}

.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link):before, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: auto;
  right: -10px;
  transform: rotate(180deg);
}

.v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link):before, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: -10px;
  right: auto;
  transform: rotate(0);
}

.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item--before .v-timeline-item__body, .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse) .v-timeline-item:nth-child(2n):not(.v-timeline-item--after) .v-timeline-item__body {
  max-width: calc(50% - 48px);
}

.v-timeline-item__body > .v-card:not(.v-card--flat):after, .v-timeline-item__body > .v-card:not(.v-card--flat):not(.v-card--link):before {
  content: "";
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-right: 10px solid #000;
  position: absolute;
  top: calc(50% - 10px);
}

.v-timeline-item__body > .v-card:not(.v-card--flat):after {
  border-right-color: inherit;
}

.v-timeline-item__body > .v-card:not(.v-card--flat):not(.v-card--link):before {
  top: calc(50% - 8px);
}

.v-timeline--align-top .v-timeline-item__dot {
  align-self: start;
}

.v-timeline--align-top .v-timeline-item__body > .v-card:after {
  top: 10px;
}

.v-timeline--align-top .v-timeline-item__body > .v-card:not(.v-card--link):before {
  top: 12px;
}

.v-application--is-ltr .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse):before {
  left: calc(50% - 1px);
  right: auto;
}

.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense):before, .v-application--is-rtl .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse):before {
  left: auto;
  right: calc(50% - 1px);
}

.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense):before {
  left: calc(50% - 1px);
  right: auto;
}

.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before, .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--after) {
  flex-direction: row;
}

.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__opposite, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--after) .v-timeline-item__opposite {
  text-align: left;
}

.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__opposite, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--after) .v-timeline-item__opposite {
  text-align: right;
}

.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link):before, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: auto;
  right: -10px;
  transform: rotate(180deg);
}

.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link):before, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: -10px;
  right: auto;
  transform: rotate(0);
}

.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body, .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n+1):not(.v-timeline-item--after) .v-timeline-item__body {
  max-width: calc(50% - 48px);
}

.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after, .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n):not(.v-timeline-item--before) {
  flex-direction: row-reverse;
}

.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__opposite, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n):not(.v-timeline-item--before) .v-timeline-item__opposite {
  text-align: right;
}

.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__opposite, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n):not(.v-timeline-item--before) .v-timeline-item__opposite {
  text-align: left;
}

.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link):before, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: -10px;
  right: auto;
  transform: rotate(0);
}

.v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link):before, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: auto;
  right: -10px;
  transform: rotate(180deg);
}

.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body, .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(2n):not(.v-timeline-item--before) .v-timeline-item__body {
  max-width: calc(50% - 48px);
}

.v-application--is-ltr .v-timeline--reverse.v-timeline--dense:before {
  left: auto;
  right: 47px;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before, .v-application--is-rtl .v-timeline--reverse.v-timeline--dense:before {
  left: 47px;
  right: auto;
}

.v-application--is-rtl .v-timeline--dense:not(.v-timeline--reverse):before {
  left: auto;
  right: 47px;
}

.v-timeline--dense .v-timeline-item {
  flex-direction: row-reverse !important;
}

.v-application--is-ltr .v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: -10px;
  right: auto;
  transform: rotate(0);
}

.v-application--is-rtl .v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: auto;
  right: -10px;
  transform: rotate(180deg);
}

.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 96px);
}

.v-timeline--dense .v-timeline-item__opposite {
  display: none;
}

.v-timeline--reverse.v-timeline--dense .v-timeline-item {
  flex-direction: row !important;
}

.v-application--is-ltr .v-timeline--reverse.v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:after, .v-application--is-ltr .v-timeline--reverse.v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: auto;
  right: -10px;
  transform: rotate(180deg);
}

.v-application--is-rtl .v-timeline--reverse.v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:after, .v-application--is-rtl .v-timeline--reverse.v-timeline--dense .v-timeline-item .v-timeline-item__body > .v-card:not(.v-card--link):before {
  left: -10px;
  right: auto;
  transform: rotate(0);
}

.v-timeline-item--fill-dot .v-timeline-item__inner-dot {
  height: inherit;
  width: inherit;
  margin: 0;
}

.theme--light.v-time-picker-clock {
  background: #e0e0e0;
}

.theme--light.v-time-picker-clock .v-time-picker-clock__item--disabled {
  color: #00000042;
}

.theme--light.v-time-picker-clock .v-time-picker-clock__item--disabled.v-time-picker-clock__item--active {
  color: #ffffff4d;
}

.theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__hand {
  background-color: #bdbdbd;
}

.theme--light.v-time-picker-clock--indeterminate:after {
  color: #bdbdbd;
}

.theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__item--active {
  background-color: #bdbdbd;
}

.theme--dark.v-time-picker-clock {
  background: #616161;
}

.theme--dark.v-time-picker-clock .v-time-picker-clock__item--disabled, .theme--dark.v-time-picker-clock .v-time-picker-clock__item--disabled.v-time-picker-clock__item--active {
  color: #ffffff4d;
}

.theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__hand {
  background-color: #757575;
}

.theme--dark.v-time-picker-clock--indeterminate:after {
  color: #757575;
}

.theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__item--active {
  background-color: #757575;
}

.v-time-picker-clock {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  border-radius: 100%;
  flex: 1 0 auto;
  padding-top: 100%;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: relative;
}

.v-time-picker-clock__container {
  flex-direction: column;
  flex-basis: 290px;
  justify-content: center;
  padding: 10px;
  display: flex;
}

.v-time-picker-clock__ampm {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.v-time-picker-clock__hand {
  height: calc(50% - 4px);
  width: 2px;
  transform-origin: bottom;
  will-change: transform;
  z-index: 1;
  position: absolute;
  bottom: 50%;
  left: calc(50% - 1px);
}

.v-time-picker-clock__hand:before {
  border: 2px solid;
  border-color: inherit;
  width: 10px;
  height: 10px;
  background: none;
  border-radius: 100%;
  top: -4px;
}

.v-time-picker-clock__hand:after, .v-time-picker-clock__hand:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-time-picker-clock__hand:after {
  height: 8px;
  width: 8px;
  border-style: solid;
  border-color: inherit;
  background-color: inherit;
  border-radius: 100%;
  top: 100%;
}

.v-time-picker-clock__hand--inner:after {
  height: 14px;
}

.v-picker--full-width .v-time-picker-clock__container {
  max-width: 290px;
}

.v-time-picker-clock__inner {
  position: absolute;
  inset: 27px;
}

.v-time-picker-clock__item {
  cursor: default;
  height: 40px;
  text-align: center;
  width: 40px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
}

.v-time-picker-clock__item > span {
  z-index: 1;
}

.v-time-picker-clock__item:after, .v-time-picker-clock__item:before {
  content: "";
  height: 14px;
  width: 14px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-time-picker-clock__item--active {
  color: #fff;
  cursor: default;
  z-index: 2;
}

.v-time-picker-clock__item--disabled {
  pointer-events: none;
}

.v-picker--landscape .v-time-picker-clock__container {
  flex-direction: row;
}

.v-picker--landscape .v-time-picker-clock__ampm {
  flex-direction: column;
}

.v-time-picker-title {
  color: #fff;
  justify-content: flex-end;
  line-height: 1;
  display: flex;
}

.v-time-picker-title__time {
  white-space: nowrap;
  direction: ltr;
}

.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
  height: 70px;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  display: inline-flex;
}

.v-time-picker-title__ampm {
  text-transform: uppercase;
  flex-direction: column;
  align-self: flex-end;
  font-size: 16px;
  display: flex;
}

.v-application--is-ltr .v-time-picker-title__ampm {
  margin: 0 0 6px 8px;
}

.v-application--is-rtl .v-time-picker-title__ampm {
  margin: 0 8px 6px 0;
}

.v-time-picker-title__ampm div:only-child {
  flex-direction: row;
}

.v-time-picker-title__ampm--readonly .v-picker__title__btn.v-picker__title__btn--active {
  opacity: .6;
}

.v-picker__title--landscape .v-time-picker-title {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.v-picker__title--landscape .v-time-picker-title__time {
  text-align: right;
}

.v-picker__title--landscape .v-time-picker-title__time .v-picker__title__btn, .v-picker__title--landscape .v-time-picker-title__time span {
  height: 55px;
  font-size: 55px;
}

.v-picker__title--landscape .v-time-picker-title__ampm {
  text-align: center;
  align-self: auto;
  margin: 16px 0 0;
}

.v-picker--time .v-picker__title--landscape {
  padding: 0;
}

.v-picker--time .v-picker__title--landscape .v-time-picker-title__time {
  text-align: center;
}

.v-tooltip {
  display: none;
}

.v-tooltip--attached {
  display: inline;
}

.v-tooltip__content {
  color: #fff;
  text-transform: none;
  width: auto;
  opacity: 0;
  pointer-events: none;
  background: #616161e6;
  border-radius: 4px;
  padding: 5px 16px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  position: absolute;
}

.v-tooltip__content.menuable__content__active {
  opacity: .9;
}

.v-tooltip__content--fixed {
  position: fixed;
}

.v-tooltip__content[class*="-active"] {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.v-tooltip__content[class*="enter-active"] {
  transition-duration: .15s;
}

.v-tooltip__content[class*="leave-active"] {
  transition-duration: 75ms;
}

.theme--light.v-treeview {
  color: #000000de;
}

.theme--light.v-treeview--hoverable .v-treeview-node__root:hover:before, .theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root:hover:before {
  opacity: .04;
}

.theme--light.v-treeview--hoverable .v-treeview-node__root--active:before, .theme--light.v-treeview--hoverable .v-treeview-node__root--active:hover:before, .theme--light.v-treeview--hoverable .v-treeview-node__root:focus:before, .theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:before, .theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:hover:before, .theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root:focus:before {
  opacity: .12;
}

.theme--light.v-treeview--hoverable .v-treeview-node__root--active:focus:before, .theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:focus:before {
  opacity: .16;
}

.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:before, .theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:hover:before {
  opacity: .12;
}

.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:focus:before {
  opacity: .16;
}

.theme--light.v-treeview .v-treeview-node--disabled > .v-treeview-node__root > .v-treeview-node__content {
  color: #00000061 !important;
}

.theme--dark.v-treeview {
  color: #fff;
}

.theme--dark.v-treeview--hoverable .v-treeview-node__root:hover:before, .theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root:hover:before {
  opacity: .08;
}

.theme--dark.v-treeview--hoverable .v-treeview-node__root--active:before, .theme--dark.v-treeview--hoverable .v-treeview-node__root--active:hover:before, .theme--dark.v-treeview--hoverable .v-treeview-node__root:focus:before, .theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:before, .theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:hover:before, .theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root:focus:before {
  opacity: .24;
}

.theme--dark.v-treeview--hoverable .v-treeview-node__root--active:focus:before, .theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:focus:before {
  opacity: .32;
}

.theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:before, .theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:hover:before {
  opacity: .24;
}

.theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:focus:before {
  opacity: .32;
}

.theme--dark.v-treeview .v-treeview-node--disabled > .v-treeview-node__root > .v-treeview-node__content {
  color: #ffffff80 !important;
}

.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root, .v-treeview-node.v-treeview-node--shaped .v-treeview-node__root:before {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root, .v-treeview-node.v-treeview-node--rounded .v-treeview-node__root:before {
  border-radius: 24px !important;
}

.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-treeview-node--click > .v-treeview-node__root, .v-treeview-node--click > .v-treeview-node__root > .v-treeview-node__content > * {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.v-treeview-node.v-treeview-node--active .v-treeview-node__content .v-icon {
  color: inherit;
}

.v-treeview-node__root {
  min-height: 48px;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  position: relative;
}

.v-treeview-node__root:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  background-color: currentColor;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  inset: 0;
}

.v-treeview-node__root:after {
  content: "";
  min-height: inherit;
  font-size: 0;
}

.v-treeview-node__children {
  transition: all .2s cubic-bezier(0, 0, .2, 1);
}

.v-treeview--dense .v-treeview-node__root {
  min-height: 40px;
}

.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root, .v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root:before {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root, .v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root:before {
  border-radius: 20px !important;
}

.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-treeview-node__checkbox {
  width: 24px;
  -webkit-user-select: none;
  user-select: none;
}

.v-application--is-ltr .v-treeview-node__checkbox {
  margin-left: 6px;
}

.v-application--is-rtl .v-treeview-node__checkbox {
  margin-right: 6px;
}

.v-treeview-node__toggle {
  width: 24px;
  -webkit-user-select: none;
  user-select: none;
}

.v-treeview-node__toggle--loading {
  animation: 1s linear infinite progress-circular-rotate;
}

.v-application--is-ltr .v-treeview-node__toggle {
  transform: rotate(-90deg);
}

.v-application--is-ltr .v-treeview-node__toggle--open {
  transform: none;
}

.v-application--is-rtl .v-treeview-node__toggle {
  transform: rotate(90deg);
}

.v-application--is-rtl .v-treeview-node__toggle--open {
  transform: none;
}

.v-treeview-node__prepend {
  min-width: 24px;
}

.v-application--is-ltr .v-treeview-node__prepend {
  margin-right: 6px;
}

.v-application--is-rtl .v-treeview-node__prepend {
  margin-left: 6px;
}

.v-treeview-node__append {
  min-width: 24px;
}

.v-application--is-ltr .v-treeview-node__append {
  margin-left: 6px;
}

.v-application--is-rtl .v-treeview-node__append {
  margin-right: 6px;
}

.v-treeview-node__level {
  width: 24px;
}

.v-treeview-node__label {
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
}

.v-treeview-node__content {
  min-width: 0;
  flex: 1 0;
  align-items: center;
  display: flex;
}

.v-treeview-node__content .v-btn {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}

.v-application--is-ltr .v-treeview-node__content {
  margin-left: 6px;
}

.v-application--is-rtl .v-treeview-node__content {
  margin-right: 6px;
}

.v-virtual-scroll {
  height: 100%;
  max-width: 100%;
  flex: auto;
  display: block;
  position: relative;
  overflow: auto;
}

.v-virtual-scroll__container {
  display: block;
}

.v-virtual-scroll__item {
  position: absolute;
  left: 0;
  right: 0;
}

@font-face {
  font-family: Material Design Icons;
  src: url("materialdesignicons-webfont.24601213.eot");
  src: url("materialdesignicons-webfont.24601213.eot#iefix&v=5.9.55") format("embedded-opentype"), url("materialdesignicons-webfont.b2e4e801.woff2") format("woff2"), url("materialdesignicons-webfont.7175976f.woff") format("woff"), url("materialdesignicons-webfont.f5aa4569.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.mdi:before, .mdi-set {
  font: 24px / 1 Material Design Icons;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.mdi-ab-testing:before {
  content: "󰇉";
}

.mdi-abacus:before {
  content: "󱛠";
}

.mdi-abjad-arabic:before {
  content: "󱌨";
}

.mdi-abjad-hebrew:before {
  content: "󱌩";
}

.mdi-abugida-devanagari:before {
  content: "󱌪";
}

.mdi-abugida-thai:before {
  content: "󱌫";
}

.mdi-access-point:before {
  content: "󰀃";
}

.mdi-access-point-check:before {
  content: "󱔸";
}

.mdi-access-point-minus:before {
  content: "󱔹";
}

.mdi-access-point-network:before {
  content: "󰀂";
}

.mdi-access-point-network-off:before {
  content: "󰯡";
}

.mdi-access-point-off:before {
  content: "󱔑";
}

.mdi-access-point-plus:before {
  content: "󱔺";
}

.mdi-access-point-remove:before {
  content: "󱔻";
}

.mdi-account:before {
  content: "󰀄";
}

.mdi-account-alert:before {
  content: "󰀅";
}

.mdi-account-alert-outline:before {
  content: "󰭐";
}

.mdi-account-arrow-left:before {
  content: "󰭑";
}

.mdi-account-arrow-left-outline:before {
  content: "󰭒";
}

.mdi-account-arrow-right:before {
  content: "󰭓";
}

.mdi-account-arrow-right-outline:before {
  content: "󰭔";
}

.mdi-account-box:before {
  content: "󰀆";
}

.mdi-account-box-multiple:before {
  content: "󰤴";
}

.mdi-account-box-multiple-outline:before {
  content: "󱀊";
}

.mdi-account-box-outline:before {
  content: "󰀇";
}

.mdi-account-cancel:before {
  content: "󱋟";
}

.mdi-account-cancel-outline:before {
  content: "󱋠";
}

.mdi-account-cash:before {
  content: "󱂗";
}

.mdi-account-cash-outline:before {
  content: "󱂘";
}

.mdi-account-check:before {
  content: "󰀈";
}

.mdi-account-check-outline:before {
  content: "󰯢";
}

.mdi-account-child:before {
  content: "󰪉";
}

.mdi-account-child-circle:before {
  content: "󰪊";
}

.mdi-account-child-outline:before {
  content: "󱃈";
}

.mdi-account-circle:before {
  content: "󰀉";
}

.mdi-account-circle-outline:before {
  content: "󰭕";
}

.mdi-account-clock:before {
  content: "󰭖";
}

.mdi-account-clock-outline:before {
  content: "󰭗";
}

.mdi-account-cog:before {
  content: "󱍰";
}

.mdi-account-cog-outline:before {
  content: "󱍱";
}

.mdi-account-convert:before {
  content: "󰀊";
}

.mdi-account-convert-outline:before {
  content: "󱌁";
}

.mdi-account-cowboy-hat:before {
  content: "󰺛";
}

.mdi-account-details:before {
  content: "󰘱";
}

.mdi-account-details-outline:before {
  content: "󱍲";
}

.mdi-account-edit:before {
  content: "󰚼";
}

.mdi-account-edit-outline:before {
  content: "󰿻";
}

.mdi-account-group:before {
  content: "󰡉";
}

.mdi-account-group-outline:before {
  content: "󰭘";
}

.mdi-account-hard-hat:before {
  content: "󰖵";
}

.mdi-account-heart:before {
  content: "󰢙";
}

.mdi-account-heart-outline:before {
  content: "󰯣";
}

.mdi-account-key:before {
  content: "󰀋";
}

.mdi-account-key-outline:before {
  content: "󰯤";
}

.mdi-account-lock:before {
  content: "󱅞";
}

.mdi-account-lock-outline:before {
  content: "󱅟";
}

.mdi-account-minus:before {
  content: "󰀍";
}

.mdi-account-minus-outline:before {
  content: "󰫬";
}

.mdi-account-multiple:before {
  content: "󰀎";
}

.mdi-account-multiple-check:before {
  content: "󰣅";
}

.mdi-account-multiple-check-outline:before {
  content: "󱇾";
}

.mdi-account-multiple-minus:before {
  content: "󰗓";
}

.mdi-account-multiple-minus-outline:before {
  content: "󰯥";
}

.mdi-account-multiple-outline:before {
  content: "󰀏";
}

.mdi-account-multiple-plus:before {
  content: "󰀐";
}

.mdi-account-multiple-plus-outline:before {
  content: "󰠀";
}

.mdi-account-multiple-remove:before {
  content: "󱈊";
}

.mdi-account-multiple-remove-outline:before {
  content: "󱈋";
}

.mdi-account-music:before {
  content: "󰠃";
}

.mdi-account-music-outline:before {
  content: "󰳩";
}

.mdi-account-network:before {
  content: "󰀑";
}

.mdi-account-network-outline:before {
  content: "󰯦";
}

.mdi-account-off:before {
  content: "󰀒";
}

.mdi-account-off-outline:before {
  content: "󰯧";
}

.mdi-account-outline:before {
  content: "󰀓";
}

.mdi-account-plus:before {
  content: "󰀔";
}

.mdi-account-plus-outline:before {
  content: "󰠁";
}

.mdi-account-question:before {
  content: "󰭙";
}

.mdi-account-question-outline:before {
  content: "󰭚";
}

.mdi-account-reactivate:before {
  content: "󱔫";
}

.mdi-account-reactivate-outline:before {
  content: "󱔬";
}

.mdi-account-remove:before {
  content: "󰀕";
}

.mdi-account-remove-outline:before {
  content: "󰫭";
}

.mdi-account-search:before {
  content: "󰀖";
}

.mdi-account-search-outline:before {
  content: "󰤵";
}

.mdi-account-settings:before {
  content: "󰘰";
}

.mdi-account-settings-outline:before {
  content: "󱃉";
}

.mdi-account-star:before {
  content: "󰀗";
}

.mdi-account-star-outline:before {
  content: "󰯨";
}

.mdi-account-supervisor:before {
  content: "󰪋";
}

.mdi-account-supervisor-circle:before {
  content: "󰪌";
}

.mdi-account-supervisor-circle-outline:before {
  content: "󱓬";
}

.mdi-account-supervisor-outline:before {
  content: "󱄭";
}

.mdi-account-switch:before {
  content: "󰀙";
}

.mdi-account-switch-outline:before {
  content: "󰓋";
}

.mdi-account-tie:before {
  content: "󰳣";
}

.mdi-account-tie-outline:before {
  content: "󱃊";
}

.mdi-account-tie-voice:before {
  content: "󱌈";
}

.mdi-account-tie-voice-off:before {
  content: "󱌊";
}

.mdi-account-tie-voice-off-outline:before {
  content: "󱌋";
}

.mdi-account-tie-voice-outline:before {
  content: "󱌉";
}

.mdi-account-voice:before {
  content: "󰗋";
}

.mdi-adjust:before {
  content: "󰀚";
}

.mdi-adobe:before {
  content: "󰤶";
}

.mdi-adobe-acrobat:before {
  content: "󰾝";
}

.mdi-air-conditioner:before {
  content: "󰀛";
}

.mdi-air-filter:before {
  content: "󰵃";
}

.mdi-air-horn:before {
  content: "󰶬";
}

.mdi-air-humidifier:before {
  content: "󱂙";
}

.mdi-air-humidifier-off:before {
  content: "󱑦";
}

.mdi-air-purifier:before {
  content: "󰵄";
}

.mdi-airbag:before {
  content: "󰯩";
}

.mdi-airballoon:before {
  content: "󰀜";
}

.mdi-airballoon-outline:before {
  content: "󱀋";
}

.mdi-airplane:before {
  content: "󰀝";
}

.mdi-airplane-landing:before {
  content: "󰗔";
}

.mdi-airplane-off:before {
  content: "󰀞";
}

.mdi-airplane-takeoff:before {
  content: "󰗕";
}

.mdi-airport:before {
  content: "󰡋";
}

.mdi-alarm:before {
  content: "󰀠";
}

.mdi-alarm-bell:before {
  content: "󰞎";
}

.mdi-alarm-check:before {
  content: "󰀡";
}

.mdi-alarm-light:before {
  content: "󰞏";
}

.mdi-alarm-light-off:before {
  content: "󱜞";
}

.mdi-alarm-light-off-outline:before {
  content: "󱜟";
}

.mdi-alarm-light-outline:before {
  content: "󰯪";
}

.mdi-alarm-multiple:before {
  content: "󰀢";
}

.mdi-alarm-note:before {
  content: "󰹱";
}

.mdi-alarm-note-off:before {
  content: "󰹲";
}

.mdi-alarm-off:before {
  content: "󰀣";
}

.mdi-alarm-panel:before {
  content: "󱗄";
}

.mdi-alarm-panel-outline:before {
  content: "󱗅";
}

.mdi-alarm-plus:before {
  content: "󰀤";
}

.mdi-alarm-snooze:before {
  content: "󰚎";
}

.mdi-album:before {
  content: "󰀥";
}

.mdi-alert:before {
  content: "󰀦";
}

.mdi-alert-box:before {
  content: "󰀧";
}

.mdi-alert-box-outline:before {
  content: "󰳤";
}

.mdi-alert-circle:before {
  content: "󰀨";
}

.mdi-alert-circle-check:before {
  content: "󱇭";
}

.mdi-alert-circle-check-outline:before {
  content: "󱇮";
}

.mdi-alert-circle-outline:before {
  content: "󰗖";
}

.mdi-alert-decagram:before {
  content: "󰚽";
}

.mdi-alert-decagram-outline:before {
  content: "󰳥";
}

.mdi-alert-minus:before {
  content: "󱒻";
}

.mdi-alert-minus-outline:before {
  content: "󱒾";
}

.mdi-alert-octagon:before {
  content: "󰀩";
}

.mdi-alert-octagon-outline:before {
  content: "󰳦";
}

.mdi-alert-octagram:before {
  content: "󰝧";
}

.mdi-alert-octagram-outline:before {
  content: "󰳧";
}

.mdi-alert-outline:before {
  content: "󰀪";
}

.mdi-alert-plus:before {
  content: "󱒺";
}

.mdi-alert-plus-outline:before {
  content: "󱒽";
}

.mdi-alert-remove:before {
  content: "󱒼";
}

.mdi-alert-remove-outline:before {
  content: "󱒿";
}

.mdi-alert-rhombus:before {
  content: "󱇎";
}

.mdi-alert-rhombus-outline:before {
  content: "󱇏";
}

.mdi-alien:before {
  content: "󰢚";
}

.mdi-alien-outline:before {
  content: "󱃋";
}

.mdi-align-horizontal-center:before {
  content: "󱇃";
}

.mdi-align-horizontal-left:before {
  content: "󱇂";
}

.mdi-align-horizontal-right:before {
  content: "󱇄";
}

.mdi-align-vertical-bottom:before {
  content: "󱇅";
}

.mdi-align-vertical-center:before {
  content: "󱇆";
}

.mdi-align-vertical-top:before {
  content: "󱇇";
}

.mdi-all-inclusive:before {
  content: "󰚾";
}

.mdi-allergy:before {
  content: "󱉘";
}

.mdi-alpha:before {
  content: "󰀫";
}

.mdi-alpha-a:before {
  content: "󰫮";
}

.mdi-alpha-a-box:before {
  content: "󰬈";
}

.mdi-alpha-a-box-outline:before {
  content: "󰯫";
}

.mdi-alpha-a-circle:before {
  content: "󰯬";
}

.mdi-alpha-a-circle-outline:before {
  content: "󰯭";
}

.mdi-alpha-b:before {
  content: "󰫯";
}

.mdi-alpha-b-box:before {
  content: "󰬉";
}

.mdi-alpha-b-box-outline:before {
  content: "󰯮";
}

.mdi-alpha-b-circle:before {
  content: "󰯯";
}

.mdi-alpha-b-circle-outline:before {
  content: "󰯰";
}

.mdi-alpha-c:before {
  content: "󰫰";
}

.mdi-alpha-c-box:before {
  content: "󰬊";
}

.mdi-alpha-c-box-outline:before {
  content: "󰯱";
}

.mdi-alpha-c-circle:before {
  content: "󰯲";
}

.mdi-alpha-c-circle-outline:before {
  content: "󰯳";
}

.mdi-alpha-d:before {
  content: "󰫱";
}

.mdi-alpha-d-box:before {
  content: "󰬋";
}

.mdi-alpha-d-box-outline:before {
  content: "󰯴";
}

.mdi-alpha-d-circle:before {
  content: "󰯵";
}

.mdi-alpha-d-circle-outline:before {
  content: "󰯶";
}

.mdi-alpha-e:before {
  content: "󰫲";
}

.mdi-alpha-e-box:before {
  content: "󰬌";
}

.mdi-alpha-e-box-outline:before {
  content: "󰯷";
}

.mdi-alpha-e-circle:before {
  content: "󰯸";
}

.mdi-alpha-e-circle-outline:before {
  content: "󰯹";
}

.mdi-alpha-f:before {
  content: "󰫳";
}

.mdi-alpha-f-box:before {
  content: "󰬍";
}

.mdi-alpha-f-box-outline:before {
  content: "󰯺";
}

.mdi-alpha-f-circle:before {
  content: "󰯻";
}

.mdi-alpha-f-circle-outline:before {
  content: "󰯼";
}

.mdi-alpha-g:before {
  content: "󰫴";
}

.mdi-alpha-g-box:before {
  content: "󰬎";
}

.mdi-alpha-g-box-outline:before {
  content: "󰯽";
}

.mdi-alpha-g-circle:before {
  content: "󰯾";
}

.mdi-alpha-g-circle-outline:before {
  content: "󰯿";
}

.mdi-alpha-h:before {
  content: "󰫵";
}

.mdi-alpha-h-box:before {
  content: "󰬏";
}

.mdi-alpha-h-box-outline:before {
  content: "󰰀";
}

.mdi-alpha-h-circle:before {
  content: "󰰁";
}

.mdi-alpha-h-circle-outline:before {
  content: "󰰂";
}

.mdi-alpha-i:before {
  content: "󰫶";
}

.mdi-alpha-i-box:before {
  content: "󰬐";
}

.mdi-alpha-i-box-outline:before {
  content: "󰰃";
}

.mdi-alpha-i-circle:before {
  content: "󰰄";
}

.mdi-alpha-i-circle-outline:before {
  content: "󰰅";
}

.mdi-alpha-j:before {
  content: "󰫷";
}

.mdi-alpha-j-box:before {
  content: "󰬑";
}

.mdi-alpha-j-box-outline:before {
  content: "󰰆";
}

.mdi-alpha-j-circle:before {
  content: "󰰇";
}

.mdi-alpha-j-circle-outline:before {
  content: "󰰈";
}

.mdi-alpha-k:before {
  content: "󰫸";
}

.mdi-alpha-k-box:before {
  content: "󰬒";
}

.mdi-alpha-k-box-outline:before {
  content: "󰰉";
}

.mdi-alpha-k-circle:before {
  content: "󰰊";
}

.mdi-alpha-k-circle-outline:before {
  content: "󰰋";
}

.mdi-alpha-l:before {
  content: "󰫹";
}

.mdi-alpha-l-box:before {
  content: "󰬓";
}

.mdi-alpha-l-box-outline:before {
  content: "󰰌";
}

.mdi-alpha-l-circle:before {
  content: "󰰍";
}

.mdi-alpha-l-circle-outline:before {
  content: "󰰎";
}

.mdi-alpha-m:before {
  content: "󰫺";
}

.mdi-alpha-m-box:before {
  content: "󰬔";
}

.mdi-alpha-m-box-outline:before {
  content: "󰰏";
}

.mdi-alpha-m-circle:before {
  content: "󰰐";
}

.mdi-alpha-m-circle-outline:before {
  content: "󰰑";
}

.mdi-alpha-n:before {
  content: "󰫻";
}

.mdi-alpha-n-box:before {
  content: "󰬕";
}

.mdi-alpha-n-box-outline:before {
  content: "󰰒";
}

.mdi-alpha-n-circle:before {
  content: "󰰓";
}

.mdi-alpha-n-circle-outline:before {
  content: "󰰔";
}

.mdi-alpha-o:before {
  content: "󰫼";
}

.mdi-alpha-o-box:before {
  content: "󰬖";
}

.mdi-alpha-o-box-outline:before {
  content: "󰰕";
}

.mdi-alpha-o-circle:before {
  content: "󰰖";
}

.mdi-alpha-o-circle-outline:before {
  content: "󰰗";
}

.mdi-alpha-p:before {
  content: "󰫽";
}

.mdi-alpha-p-box:before {
  content: "󰬗";
}

.mdi-alpha-p-box-outline:before {
  content: "󰰘";
}

.mdi-alpha-p-circle:before {
  content: "󰰙";
}

.mdi-alpha-p-circle-outline:before {
  content: "󰰚";
}

.mdi-alpha-q:before {
  content: "󰫾";
}

.mdi-alpha-q-box:before {
  content: "󰬘";
}

.mdi-alpha-q-box-outline:before {
  content: "󰰛";
}

.mdi-alpha-q-circle:before {
  content: "󰰜";
}

.mdi-alpha-q-circle-outline:before {
  content: "󰰝";
}

.mdi-alpha-r:before {
  content: "󰫿";
}

.mdi-alpha-r-box:before {
  content: "󰬙";
}

.mdi-alpha-r-box-outline:before {
  content: "󰰞";
}

.mdi-alpha-r-circle:before {
  content: "󰰟";
}

.mdi-alpha-r-circle-outline:before {
  content: "󰰠";
}

.mdi-alpha-s:before {
  content: "󰬀";
}

.mdi-alpha-s-box:before {
  content: "󰬚";
}

.mdi-alpha-s-box-outline:before {
  content: "󰰡";
}

.mdi-alpha-s-circle:before {
  content: "󰰢";
}

.mdi-alpha-s-circle-outline:before {
  content: "󰰣";
}

.mdi-alpha-t:before {
  content: "󰬁";
}

.mdi-alpha-t-box:before {
  content: "󰬛";
}

.mdi-alpha-t-box-outline:before {
  content: "󰰤";
}

.mdi-alpha-t-circle:before {
  content: "󰰥";
}

.mdi-alpha-t-circle-outline:before {
  content: "󰰦";
}

.mdi-alpha-u:before {
  content: "󰬂";
}

.mdi-alpha-u-box:before {
  content: "󰬜";
}

.mdi-alpha-u-box-outline:before {
  content: "󰰧";
}

.mdi-alpha-u-circle:before {
  content: "󰰨";
}

.mdi-alpha-u-circle-outline:before {
  content: "󰰩";
}

.mdi-alpha-v:before {
  content: "󰬃";
}

.mdi-alpha-v-box:before {
  content: "󰬝";
}

.mdi-alpha-v-box-outline:before {
  content: "󰰪";
}

.mdi-alpha-v-circle:before {
  content: "󰰫";
}

.mdi-alpha-v-circle-outline:before {
  content: "󰰬";
}

.mdi-alpha-w:before {
  content: "󰬄";
}

.mdi-alpha-w-box:before {
  content: "󰬞";
}

.mdi-alpha-w-box-outline:before {
  content: "󰰭";
}

.mdi-alpha-w-circle:before {
  content: "󰰮";
}

.mdi-alpha-w-circle-outline:before {
  content: "󰰯";
}

.mdi-alpha-x:before {
  content: "󰬅";
}

.mdi-alpha-x-box:before {
  content: "󰬟";
}

.mdi-alpha-x-box-outline:before {
  content: "󰰰";
}

.mdi-alpha-x-circle:before {
  content: "󰰱";
}

.mdi-alpha-x-circle-outline:before {
  content: "󰰲";
}

.mdi-alpha-y:before {
  content: "󰬆";
}

.mdi-alpha-y-box:before {
  content: "󰬠";
}

.mdi-alpha-y-box-outline:before {
  content: "󰰳";
}

.mdi-alpha-y-circle:before {
  content: "󰰴";
}

.mdi-alpha-y-circle-outline:before {
  content: "󰰵";
}

.mdi-alpha-z:before {
  content: "󰬇";
}

.mdi-alpha-z-box:before {
  content: "󰬡";
}

.mdi-alpha-z-box-outline:before {
  content: "󰰶";
}

.mdi-alpha-z-circle:before {
  content: "󰰷";
}

.mdi-alpha-z-circle-outline:before {
  content: "󰰸";
}

.mdi-alphabet-aurebesh:before {
  content: "󱌬";
}

.mdi-alphabet-cyrillic:before {
  content: "󱌭";
}

.mdi-alphabet-greek:before {
  content: "󱌮";
}

.mdi-alphabet-latin:before {
  content: "󱌯";
}

.mdi-alphabet-piqad:before {
  content: "󱌰";
}

.mdi-alphabet-tengwar:before {
  content: "󱌷";
}

.mdi-alphabetical:before {
  content: "󰀬";
}

.mdi-alphabetical-off:before {
  content: "󱀌";
}

.mdi-alphabetical-variant:before {
  content: "󱀍";
}

.mdi-alphabetical-variant-off:before {
  content: "󱀎";
}

.mdi-altimeter:before {
  content: "󰗗";
}

.mdi-amazon:before {
  content: "󰀭";
}

.mdi-amazon-alexa:before {
  content: "󰣆";
}

.mdi-ambulance:before {
  content: "󰀯";
}

.mdi-ammunition:before {
  content: "󰳨";
}

.mdi-ampersand:before {
  content: "󰪍";
}

.mdi-amplifier:before {
  content: "󰀰";
}

.mdi-amplifier-off:before {
  content: "󱆵";
}

.mdi-anchor:before {
  content: "󰀱";
}

.mdi-android:before {
  content: "󰀲";
}

.mdi-android-auto:before {
  content: "󰪎";
}

.mdi-android-debug-bridge:before {
  content: "󰀳";
}

.mdi-android-messages:before {
  content: "󰵅";
}

.mdi-android-studio:before {
  content: "󰀴";
}

.mdi-angle-acute:before {
  content: "󰤷";
}

.mdi-angle-obtuse:before {
  content: "󰤸";
}

.mdi-angle-right:before {
  content: "󰤹";
}

.mdi-angular:before {
  content: "󰚲";
}

.mdi-angularjs:before {
  content: "󰚿";
}

.mdi-animation:before {
  content: "󰗘";
}

.mdi-animation-outline:before {
  content: "󰪏";
}

.mdi-animation-play:before {
  content: "󰤺";
}

.mdi-animation-play-outline:before {
  content: "󰪐";
}

.mdi-ansible:before {
  content: "󱂚";
}

.mdi-antenna:before {
  content: "󱄙";
}

.mdi-anvil:before {
  content: "󰢛";
}

.mdi-apache-kafka:before {
  content: "󱀏";
}

.mdi-api:before {
  content: "󱂛";
}

.mdi-api-off:before {
  content: "󱉗";
}

.mdi-apple:before {
  content: "󰀵";
}

.mdi-apple-airplay:before {
  content: "󰀟";
}

.mdi-apple-finder:before {
  content: "󰀶";
}

.mdi-apple-icloud:before {
  content: "󰀸";
}

.mdi-apple-ios:before {
  content: "󰀷";
}

.mdi-apple-keyboard-caps:before {
  content: "󰘲";
}

.mdi-apple-keyboard-command:before {
  content: "󰘳";
}

.mdi-apple-keyboard-control:before {
  content: "󰘴";
}

.mdi-apple-keyboard-option:before {
  content: "󰘵";
}

.mdi-apple-keyboard-shift:before {
  content: "󰘶";
}

.mdi-apple-safari:before {
  content: "󰀹";
}

.mdi-application:before {
  content: "󰘔";
}

.mdi-application-cog:before {
  content: "󱕷";
}

.mdi-application-export:before {
  content: "󰶭";
}

.mdi-application-import:before {
  content: "󰶮";
}

.mdi-application-settings:before {
  content: "󱕕";
}

.mdi-approximately-equal:before {
  content: "󰾞";
}

.mdi-approximately-equal-box:before {
  content: "󰾟";
}

.mdi-apps:before {
  content: "󰀻";
}

.mdi-apps-box:before {
  content: "󰵆";
}

.mdi-arch:before {
  content: "󰣇";
}

.mdi-archive:before {
  content: "󰀼";
}

.mdi-archive-alert:before {
  content: "󱓽";
}

.mdi-archive-alert-outline:before {
  content: "󱓾";
}

.mdi-archive-arrow-down:before {
  content: "󱉙";
}

.mdi-archive-arrow-down-outline:before {
  content: "󱉚";
}

.mdi-archive-arrow-up:before {
  content: "󱉛";
}

.mdi-archive-arrow-up-outline:before {
  content: "󱉜";
}

.mdi-archive-outline:before {
  content: "󱈎";
}

.mdi-arm-flex:before {
  content: "󰿗";
}

.mdi-arm-flex-outline:before {
  content: "󰿖";
}

.mdi-arrange-bring-forward:before {
  content: "󰀽";
}

.mdi-arrange-bring-to-front:before {
  content: "󰀾";
}

.mdi-arrange-send-backward:before {
  content: "󰀿";
}

.mdi-arrange-send-to-back:before {
  content: "󰁀";
}

.mdi-arrow-all:before {
  content: "󰁁";
}

.mdi-arrow-bottom-left:before {
  content: "󰁂";
}

.mdi-arrow-bottom-left-bold-outline:before {
  content: "󰦷";
}

.mdi-arrow-bottom-left-thick:before {
  content: "󰦸";
}

.mdi-arrow-bottom-left-thin-circle-outline:before {
  content: "󱖖";
}

.mdi-arrow-bottom-right:before {
  content: "󰁃";
}

.mdi-arrow-bottom-right-bold-outline:before {
  content: "󰦹";
}

.mdi-arrow-bottom-right-thick:before {
  content: "󰦺";
}

.mdi-arrow-bottom-right-thin-circle-outline:before {
  content: "󱖕";
}

.mdi-arrow-collapse:before {
  content: "󰘕";
}

.mdi-arrow-collapse-all:before {
  content: "󰁄";
}

.mdi-arrow-collapse-down:before {
  content: "󰞒";
}

.mdi-arrow-collapse-horizontal:before {
  content: "󰡌";
}

.mdi-arrow-collapse-left:before {
  content: "󰞓";
}

.mdi-arrow-collapse-right:before {
  content: "󰞔";
}

.mdi-arrow-collapse-up:before {
  content: "󰞕";
}

.mdi-arrow-collapse-vertical:before {
  content: "󰡍";
}

.mdi-arrow-decision:before {
  content: "󰦻";
}

.mdi-arrow-decision-auto:before {
  content: "󰦼";
}

.mdi-arrow-decision-auto-outline:before {
  content: "󰦽";
}

.mdi-arrow-decision-outline:before {
  content: "󰦾";
}

.mdi-arrow-down:before {
  content: "󰁅";
}

.mdi-arrow-down-bold:before {
  content: "󰜮";
}

.mdi-arrow-down-bold-box:before {
  content: "󰜯";
}

.mdi-arrow-down-bold-box-outline:before {
  content: "󰜰";
}

.mdi-arrow-down-bold-circle:before {
  content: "󰁇";
}

.mdi-arrow-down-bold-circle-outline:before {
  content: "󰁈";
}

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "󰁉";
}

.mdi-arrow-down-bold-outline:before {
  content: "󰦿";
}

.mdi-arrow-down-box:before {
  content: "󰛀";
}

.mdi-arrow-down-circle:before {
  content: "󰳛";
}

.mdi-arrow-down-circle-outline:before {
  content: "󰳜";
}

.mdi-arrow-down-drop-circle:before {
  content: "󰁊";
}

.mdi-arrow-down-drop-circle-outline:before {
  content: "󰁋";
}

.mdi-arrow-down-thick:before {
  content: "󰁆";
}

.mdi-arrow-down-thin-circle-outline:before {
  content: "󱖙";
}

.mdi-arrow-expand:before {
  content: "󰘖";
}

.mdi-arrow-expand-all:before {
  content: "󰁌";
}

.mdi-arrow-expand-down:before {
  content: "󰞖";
}

.mdi-arrow-expand-horizontal:before {
  content: "󰡎";
}

.mdi-arrow-expand-left:before {
  content: "󰞗";
}

.mdi-arrow-expand-right:before {
  content: "󰞘";
}

.mdi-arrow-expand-up:before {
  content: "󰞙";
}

.mdi-arrow-expand-vertical:before {
  content: "󰡏";
}

.mdi-arrow-horizontal-lock:before {
  content: "󱅛";
}

.mdi-arrow-left:before {
  content: "󰁍";
}

.mdi-arrow-left-bold:before {
  content: "󰜱";
}

.mdi-arrow-left-bold-box:before {
  content: "󰜲";
}

.mdi-arrow-left-bold-box-outline:before {
  content: "󰜳";
}

.mdi-arrow-left-bold-circle:before {
  content: "󰁏";
}

.mdi-arrow-left-bold-circle-outline:before {
  content: "󰁐";
}

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "󰁑";
}

.mdi-arrow-left-bold-outline:before {
  content: "󰧀";
}

.mdi-arrow-left-box:before {
  content: "󰛁";
}

.mdi-arrow-left-circle:before {
  content: "󰳝";
}

.mdi-arrow-left-circle-outline:before {
  content: "󰳞";
}

.mdi-arrow-left-drop-circle:before {
  content: "󰁒";
}

.mdi-arrow-left-drop-circle-outline:before {
  content: "󰁓";
}

.mdi-arrow-left-right:before {
  content: "󰹳";
}

.mdi-arrow-left-right-bold:before {
  content: "󰹴";
}

.mdi-arrow-left-right-bold-outline:before {
  content: "󰧁";
}

.mdi-arrow-left-thick:before {
  content: "󰁎";
}

.mdi-arrow-left-thin-circle-outline:before {
  content: "󱖚";
}

.mdi-arrow-right:before {
  content: "󰁔";
}

.mdi-arrow-right-bold:before {
  content: "󰜴";
}

.mdi-arrow-right-bold-box:before {
  content: "󰜵";
}

.mdi-arrow-right-bold-box-outline:before {
  content: "󰜶";
}

.mdi-arrow-right-bold-circle:before {
  content: "󰁖";
}

.mdi-arrow-right-bold-circle-outline:before {
  content: "󰁗";
}

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "󰁘";
}

.mdi-arrow-right-bold-outline:before {
  content: "󰧂";
}

.mdi-arrow-right-box:before {
  content: "󰛂";
}

.mdi-arrow-right-circle:before {
  content: "󰳟";
}

.mdi-arrow-right-circle-outline:before {
  content: "󰳠";
}

.mdi-arrow-right-drop-circle:before {
  content: "󰁙";
}

.mdi-arrow-right-drop-circle-outline:before {
  content: "󰁚";
}

.mdi-arrow-right-thick:before {
  content: "󰁕";
}

.mdi-arrow-right-thin-circle-outline:before {
  content: "󱖘";
}

.mdi-arrow-split-horizontal:before {
  content: "󰤻";
}

.mdi-arrow-split-vertical:before {
  content: "󰤼";
}

.mdi-arrow-top-left:before {
  content: "󰁛";
}

.mdi-arrow-top-left-bold-outline:before {
  content: "󰧃";
}

.mdi-arrow-top-left-bottom-right:before {
  content: "󰹵";
}

.mdi-arrow-top-left-bottom-right-bold:before {
  content: "󰹶";
}

.mdi-arrow-top-left-thick:before {
  content: "󰧄";
}

.mdi-arrow-top-left-thin-circle-outline:before {
  content: "󱖓";
}

.mdi-arrow-top-right:before {
  content: "󰁜";
}

.mdi-arrow-top-right-bold-outline:before {
  content: "󰧅";
}

.mdi-arrow-top-right-bottom-left:before {
  content: "󰹷";
}

.mdi-arrow-top-right-bottom-left-bold:before {
  content: "󰹸";
}

.mdi-arrow-top-right-thick:before {
  content: "󰧆";
}

.mdi-arrow-top-right-thin-circle-outline:before {
  content: "󱖔";
}

.mdi-arrow-up:before {
  content: "󰁝";
}

.mdi-arrow-up-bold:before {
  content: "󰜷";
}

.mdi-arrow-up-bold-box:before {
  content: "󰜸";
}

.mdi-arrow-up-bold-box-outline:before {
  content: "󰜹";
}

.mdi-arrow-up-bold-circle:before {
  content: "󰁟";
}

.mdi-arrow-up-bold-circle-outline:before {
  content: "󰁠";
}

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "󰁡";
}

.mdi-arrow-up-bold-outline:before {
  content: "󰧇";
}

.mdi-arrow-up-box:before {
  content: "󰛃";
}

.mdi-arrow-up-circle:before {
  content: "󰳡";
}

.mdi-arrow-up-circle-outline:before {
  content: "󰳢";
}

.mdi-arrow-up-down:before {
  content: "󰹹";
}

.mdi-arrow-up-down-bold:before {
  content: "󰹺";
}

.mdi-arrow-up-down-bold-outline:before {
  content: "󰧈";
}

.mdi-arrow-up-drop-circle:before {
  content: "󰁢";
}

.mdi-arrow-up-drop-circle-outline:before {
  content: "󰁣";
}

.mdi-arrow-up-thick:before {
  content: "󰁞";
}

.mdi-arrow-up-thin-circle-outline:before {
  content: "󱖗";
}

.mdi-arrow-vertical-lock:before {
  content: "󱅜";
}

.mdi-artstation:before {
  content: "󰭛";
}

.mdi-aspect-ratio:before {
  content: "󰨤";
}

.mdi-assistant:before {
  content: "󰁤";
}

.mdi-asterisk:before {
  content: "󰛄";
}

.mdi-at:before {
  content: "󰁥";
}

.mdi-atlassian:before {
  content: "󰠄";
}

.mdi-atm:before {
  content: "󰵇";
}

.mdi-atom:before {
  content: "󰝨";
}

.mdi-atom-variant:before {
  content: "󰹻";
}

.mdi-attachment:before {
  content: "󰁦";
}

.mdi-audio-video:before {
  content: "󰤽";
}

.mdi-audio-video-off:before {
  content: "󱆶";
}

.mdi-augmented-reality:before {
  content: "󰡐";
}

.mdi-auto-download:before {
  content: "󱍾";
}

.mdi-auto-fix:before {
  content: "󰁨";
}

.mdi-auto-upload:before {
  content: "󰁩";
}

.mdi-autorenew:before {
  content: "󰁪";
}

.mdi-av-timer:before {
  content: "󰁫";
}

.mdi-aws:before {
  content: "󰸏";
}

.mdi-axe:before {
  content: "󰣈";
}

.mdi-axis:before {
  content: "󰵈";
}

.mdi-axis-arrow:before {
  content: "󰵉";
}

.mdi-axis-arrow-info:before {
  content: "󱐎";
}

.mdi-axis-arrow-lock:before {
  content: "󰵊";
}

.mdi-axis-lock:before {
  content: "󰵋";
}

.mdi-axis-x-arrow:before {
  content: "󰵌";
}

.mdi-axis-x-arrow-lock:before {
  content: "󰵍";
}

.mdi-axis-x-rotate-clockwise:before {
  content: "󰵎";
}

.mdi-axis-x-rotate-counterclockwise:before {
  content: "󰵏";
}

.mdi-axis-x-y-arrow-lock:before {
  content: "󰵐";
}

.mdi-axis-y-arrow:before {
  content: "󰵑";
}

.mdi-axis-y-arrow-lock:before {
  content: "󰵒";
}

.mdi-axis-y-rotate-clockwise:before {
  content: "󰵓";
}

.mdi-axis-y-rotate-counterclockwise:before {
  content: "󰵔";
}

.mdi-axis-z-arrow:before {
  content: "󰵕";
}

.mdi-axis-z-arrow-lock:before {
  content: "󰵖";
}

.mdi-axis-z-rotate-clockwise:before {
  content: "󰵗";
}

.mdi-axis-z-rotate-counterclockwise:before {
  content: "󰵘";
}

.mdi-babel:before {
  content: "󰨥";
}

.mdi-baby:before {
  content: "󰁬";
}

.mdi-baby-bottle:before {
  content: "󰼹";
}

.mdi-baby-bottle-outline:before {
  content: "󰼺";
}

.mdi-baby-buggy:before {
  content: "󱏠";
}

.mdi-baby-carriage:before {
  content: "󰚏";
}

.mdi-baby-carriage-off:before {
  content: "󰾠";
}

.mdi-baby-face:before {
  content: "󰹼";
}

.mdi-baby-face-outline:before {
  content: "󰹽";
}

.mdi-backburger:before {
  content: "󰁭";
}

.mdi-backspace:before {
  content: "󰁮";
}

.mdi-backspace-outline:before {
  content: "󰭜";
}

.mdi-backspace-reverse:before {
  content: "󰹾";
}

.mdi-backspace-reverse-outline:before {
  content: "󰹿";
}

.mdi-backup-restore:before {
  content: "󰁯";
}

.mdi-bacteria:before {
  content: "󰻕";
}

.mdi-bacteria-outline:before {
  content: "󰻖";
}

.mdi-badge-account:before {
  content: "󰶧";
}

.mdi-badge-account-alert:before {
  content: "󰶨";
}

.mdi-badge-account-alert-outline:before {
  content: "󰶩";
}

.mdi-badge-account-horizontal:before {
  content: "󰸍";
}

.mdi-badge-account-horizontal-outline:before {
  content: "󰸎";
}

.mdi-badge-account-outline:before {
  content: "󰶪";
}

.mdi-badminton:before {
  content: "󰡑";
}

.mdi-bag-carry-on:before {
  content: "󰼻";
}

.mdi-bag-carry-on-check:before {
  content: "󰵥";
}

.mdi-bag-carry-on-off:before {
  content: "󰼼";
}

.mdi-bag-checked:before {
  content: "󰼽";
}

.mdi-bag-personal:before {
  content: "󰸐";
}

.mdi-bag-personal-off:before {
  content: "󰸑";
}

.mdi-bag-personal-off-outline:before {
  content: "󰸒";
}

.mdi-bag-personal-outline:before {
  content: "󰸓";
}

.mdi-bag-suitcase:before {
  content: "󱖋";
}

.mdi-bag-suitcase-off:before {
  content: "󱖍";
}

.mdi-bag-suitcase-off-outline:before {
  content: "󱖎";
}

.mdi-bag-suitcase-outline:before {
  content: "󱖌";
}

.mdi-baguette:before {
  content: "󰼾";
}

.mdi-balloon:before {
  content: "󰨦";
}

.mdi-ballot:before {
  content: "󰧉";
}

.mdi-ballot-outline:before {
  content: "󰧊";
}

.mdi-ballot-recount:before {
  content: "󰰹";
}

.mdi-ballot-recount-outline:before {
  content: "󰰺";
}

.mdi-bandage:before {
  content: "󰶯";
}

.mdi-bandcamp:before {
  content: "󰙵";
}

.mdi-bank:before {
  content: "󰁰";
}

.mdi-bank-check:before {
  content: "󱙕";
}

.mdi-bank-minus:before {
  content: "󰶰";
}

.mdi-bank-off:before {
  content: "󱙖";
}

.mdi-bank-off-outline:before {
  content: "󱙗";
}

.mdi-bank-outline:before {
  content: "󰺀";
}

.mdi-bank-plus:before {
  content: "󰶱";
}

.mdi-bank-remove:before {
  content: "󰶲";
}

.mdi-bank-transfer:before {
  content: "󰨧";
}

.mdi-bank-transfer-in:before {
  content: "󰨨";
}

.mdi-bank-transfer-out:before {
  content: "󰨩";
}

.mdi-barcode:before {
  content: "󰁱";
}

.mdi-barcode-off:before {
  content: "󱈶";
}

.mdi-barcode-scan:before {
  content: "󰁲";
}

.mdi-barley:before {
  content: "󰁳";
}

.mdi-barley-off:before {
  content: "󰭝";
}

.mdi-barn:before {
  content: "󰭞";
}

.mdi-barrel:before {
  content: "󰁴";
}

.mdi-baseball:before {
  content: "󰡒";
}

.mdi-baseball-bat:before {
  content: "󰡓";
}

.mdi-baseball-diamond:before {
  content: "󱗬";
}

.mdi-baseball-diamond-outline:before {
  content: "󱗭";
}

.mdi-bash:before {
  content: "󱆃";
}

.mdi-basket:before {
  content: "󰁶";
}

.mdi-basket-fill:before {
  content: "󰁷";
}

.mdi-basket-minus:before {
  content: "󱔣";
}

.mdi-basket-minus-outline:before {
  content: "󱔤";
}

.mdi-basket-off:before {
  content: "󱔥";
}

.mdi-basket-off-outline:before {
  content: "󱔦";
}

.mdi-basket-outline:before {
  content: "󱆁";
}

.mdi-basket-plus:before {
  content: "󱔧";
}

.mdi-basket-plus-outline:before {
  content: "󱔨";
}

.mdi-basket-remove:before {
  content: "󱔩";
}

.mdi-basket-remove-outline:before {
  content: "󱔪";
}

.mdi-basket-unfill:before {
  content: "󰁸";
}

.mdi-basketball:before {
  content: "󰠆";
}

.mdi-basketball-hoop:before {
  content: "󰰻";
}

.mdi-basketball-hoop-outline:before {
  content: "󰰼";
}

.mdi-bat:before {
  content: "󰭟";
}

.mdi-battery:before {
  content: "󰁹";
}

.mdi-battery-10:before {
  content: "󰁺";
}

.mdi-battery-10-bluetooth:before {
  content: "󰤾";
}

.mdi-battery-20:before {
  content: "󰁻";
}

.mdi-battery-20-bluetooth:before {
  content: "󰤿";
}

.mdi-battery-30:before {
  content: "󰁼";
}

.mdi-battery-30-bluetooth:before {
  content: "󰥀";
}

.mdi-battery-40:before {
  content: "󰁽";
}

.mdi-battery-40-bluetooth:before {
  content: "󰥁";
}

.mdi-battery-50:before {
  content: "󰁾";
}

.mdi-battery-50-bluetooth:before {
  content: "󰥂";
}

.mdi-battery-60:before {
  content: "󰁿";
}

.mdi-battery-60-bluetooth:before {
  content: "󰥃";
}

.mdi-battery-70:before {
  content: "󰂀";
}

.mdi-battery-70-bluetooth:before {
  content: "󰥄";
}

.mdi-battery-80:before {
  content: "󰂁";
}

.mdi-battery-80-bluetooth:before {
  content: "󰥅";
}

.mdi-battery-90:before {
  content: "󰂂";
}

.mdi-battery-90-bluetooth:before {
  content: "󰥆";
}

.mdi-battery-alert:before {
  content: "󰂃";
}

.mdi-battery-alert-bluetooth:before {
  content: "󰥇";
}

.mdi-battery-alert-variant:before {
  content: "󱃌";
}

.mdi-battery-alert-variant-outline:before {
  content: "󱃍";
}

.mdi-battery-bluetooth:before {
  content: "󰥈";
}

.mdi-battery-bluetooth-variant:before {
  content: "󰥉";
}

.mdi-battery-charging:before {
  content: "󰂄";
}

.mdi-battery-charging-10:before {
  content: "󰢜";
}

.mdi-battery-charging-100:before {
  content: "󰂅";
}

.mdi-battery-charging-20:before {
  content: "󰂆";
}

.mdi-battery-charging-30:before {
  content: "󰂇";
}

.mdi-battery-charging-40:before {
  content: "󰂈";
}

.mdi-battery-charging-50:before {
  content: "󰢝";
}

.mdi-battery-charging-60:before {
  content: "󰂉";
}

.mdi-battery-charging-70:before {
  content: "󰢞";
}

.mdi-battery-charging-80:before {
  content: "󰂊";
}

.mdi-battery-charging-90:before {
  content: "󰂋";
}

.mdi-battery-charging-high:before {
  content: "󱊦";
}

.mdi-battery-charging-low:before {
  content: "󱊤";
}

.mdi-battery-charging-medium:before {
  content: "󱊥";
}

.mdi-battery-charging-outline:before {
  content: "󰢟";
}

.mdi-battery-charging-wireless:before {
  content: "󰠇";
}

.mdi-battery-charging-wireless-10:before {
  content: "󰠈";
}

.mdi-battery-charging-wireless-20:before {
  content: "󰠉";
}

.mdi-battery-charging-wireless-30:before {
  content: "󰠊";
}

.mdi-battery-charging-wireless-40:before {
  content: "󰠋";
}

.mdi-battery-charging-wireless-50:before {
  content: "󰠌";
}

.mdi-battery-charging-wireless-60:before {
  content: "󰠍";
}

.mdi-battery-charging-wireless-70:before {
  content: "󰠎";
}

.mdi-battery-charging-wireless-80:before {
  content: "󰠏";
}

.mdi-battery-charging-wireless-90:before {
  content: "󰠐";
}

.mdi-battery-charging-wireless-alert:before {
  content: "󰠑";
}

.mdi-battery-charging-wireless-outline:before {
  content: "󰠒";
}

.mdi-battery-heart:before {
  content: "󱈏";
}

.mdi-battery-heart-outline:before {
  content: "󱈐";
}

.mdi-battery-heart-variant:before {
  content: "󱈑";
}

.mdi-battery-high:before {
  content: "󱊣";
}

.mdi-battery-low:before {
  content: "󱊡";
}

.mdi-battery-medium:before {
  content: "󱊢";
}

.mdi-battery-minus:before {
  content: "󰂌";
}

.mdi-battery-negative:before {
  content: "󰂍";
}

.mdi-battery-off:before {
  content: "󱉝";
}

.mdi-battery-off-outline:before {
  content: "󱉞";
}

.mdi-battery-outline:before {
  content: "󰂎";
}

.mdi-battery-plus:before {
  content: "󰂏";
}

.mdi-battery-positive:before {
  content: "󰂐";
}

.mdi-battery-unknown:before {
  content: "󰂑";
}

.mdi-battery-unknown-bluetooth:before {
  content: "󰥊";
}

.mdi-battlenet:before {
  content: "󰭠";
}

.mdi-beach:before {
  content: "󰂒";
}

.mdi-beaker:before {
  content: "󰳪";
}

.mdi-beaker-alert:before {
  content: "󱈩";
}

.mdi-beaker-alert-outline:before {
  content: "󱈪";
}

.mdi-beaker-check:before {
  content: "󱈫";
}

.mdi-beaker-check-outline:before {
  content: "󱈬";
}

.mdi-beaker-minus:before {
  content: "󱈭";
}

.mdi-beaker-minus-outline:before {
  content: "󱈮";
}

.mdi-beaker-outline:before {
  content: "󰚐";
}

.mdi-beaker-plus:before {
  content: "󱈯";
}

.mdi-beaker-plus-outline:before {
  content: "󱈰";
}

.mdi-beaker-question:before {
  content: "󱈱";
}

.mdi-beaker-question-outline:before {
  content: "󱈲";
}

.mdi-beaker-remove:before {
  content: "󱈳";
}

.mdi-beaker-remove-outline:before {
  content: "󱈴";
}

.mdi-bed:before {
  content: "󰋣";
}

.mdi-bed-double:before {
  content: "󰿔";
}

.mdi-bed-double-outline:before {
  content: "󰿓";
}

.mdi-bed-empty:before {
  content: "󰢠";
}

.mdi-bed-king:before {
  content: "󰿒";
}

.mdi-bed-king-outline:before {
  content: "󰿑";
}

.mdi-bed-outline:before {
  content: "󰂙";
}

.mdi-bed-queen:before {
  content: "󰿐";
}

.mdi-bed-queen-outline:before {
  content: "󰿛";
}

.mdi-bed-single:before {
  content: "󱁭";
}

.mdi-bed-single-outline:before {
  content: "󱁮";
}

.mdi-bee:before {
  content: "󰾡";
}

.mdi-bee-flower:before {
  content: "󰾢";
}

.mdi-beehive-off-outline:before {
  content: "󱏭";
}

.mdi-beehive-outline:before {
  content: "󱃎";
}

.mdi-beekeeper:before {
  content: "󱓢";
}

.mdi-beer:before {
  content: "󰂘";
}

.mdi-beer-outline:before {
  content: "󱌌";
}

.mdi-bell:before {
  content: "󰂚";
}

.mdi-bell-alert:before {
  content: "󰵙";
}

.mdi-bell-alert-outline:before {
  content: "󰺁";
}

.mdi-bell-cancel:before {
  content: "󱏧";
}

.mdi-bell-cancel-outline:before {
  content: "󱏨";
}

.mdi-bell-check:before {
  content: "󱇥";
}

.mdi-bell-check-outline:before {
  content: "󱇦";
}

.mdi-bell-circle:before {
  content: "󰵚";
}

.mdi-bell-circle-outline:before {
  content: "󰵛";
}

.mdi-bell-minus:before {
  content: "󱏩";
}

.mdi-bell-minus-outline:before {
  content: "󱏪";
}

.mdi-bell-off:before {
  content: "󰂛";
}

.mdi-bell-off-outline:before {
  content: "󰪑";
}

.mdi-bell-outline:before {
  content: "󰂜";
}

.mdi-bell-plus:before {
  content: "󰂝";
}

.mdi-bell-plus-outline:before {
  content: "󰪒";
}

.mdi-bell-remove:before {
  content: "󱏫";
}

.mdi-bell-remove-outline:before {
  content: "󱏬";
}

.mdi-bell-ring:before {
  content: "󰂞";
}

.mdi-bell-ring-outline:before {
  content: "󰂟";
}

.mdi-bell-sleep:before {
  content: "󰂠";
}

.mdi-bell-sleep-outline:before {
  content: "󰪓";
}

.mdi-beta:before {
  content: "󰂡";
}

.mdi-betamax:before {
  content: "󰧋";
}

.mdi-biathlon:before {
  content: "󰸔";
}

.mdi-bicycle:before {
  content: "󱂜";
}

.mdi-bicycle-basket:before {
  content: "󱈵";
}

.mdi-bicycle-electric:before {
  content: "󱖴";
}

.mdi-bicycle-penny-farthing:before {
  content: "󱗩";
}

.mdi-bike:before {
  content: "󰂣";
}

.mdi-bike-fast:before {
  content: "󱄟";
}

.mdi-billboard:before {
  content: "󱀐";
}

.mdi-billiards:before {
  content: "󰭡";
}

.mdi-billiards-rack:before {
  content: "󰭢";
}

.mdi-binoculars:before {
  content: "󰂥";
}

.mdi-bio:before {
  content: "󰂦";
}

.mdi-biohazard:before {
  content: "󰂧";
}

.mdi-bird:before {
  content: "󱗆";
}

.mdi-bitbucket:before {
  content: "󰂨";
}

.mdi-bitcoin:before {
  content: "󰠓";
}

.mdi-black-mesa:before {
  content: "󰂩";
}

.mdi-blender:before {
  content: "󰳫";
}

.mdi-blender-software:before {
  content: "󰂫";
}

.mdi-blinds:before {
  content: "󰂬";
}

.mdi-blinds-open:before {
  content: "󱀑";
}

.mdi-block-helper:before {
  content: "󰂭";
}

.mdi-blogger:before {
  content: "󰂮";
}

.mdi-blood-bag:before {
  content: "󰳬";
}

.mdi-bluetooth:before {
  content: "󰂯";
}

.mdi-bluetooth-audio:before {
  content: "󰂰";
}

.mdi-bluetooth-connect:before {
  content: "󰂱";
}

.mdi-bluetooth-off:before {
  content: "󰂲";
}

.mdi-bluetooth-settings:before {
  content: "󰂳";
}

.mdi-bluetooth-transfer:before {
  content: "󰂴";
}

.mdi-blur:before {
  content: "󰂵";
}

.mdi-blur-linear:before {
  content: "󰂶";
}

.mdi-blur-off:before {
  content: "󰂷";
}

.mdi-blur-radial:before {
  content: "󰂸";
}

.mdi-bolnisi-cross:before {
  content: "󰳭";
}

.mdi-bolt:before {
  content: "󰶳";
}

.mdi-bomb:before {
  content: "󰚑";
}

.mdi-bomb-off:before {
  content: "󰛅";
}

.mdi-bone:before {
  content: "󰂹";
}

.mdi-book:before {
  content: "󰂺";
}

.mdi-book-account:before {
  content: "󱎭";
}

.mdi-book-account-outline:before {
  content: "󱎮";
}

.mdi-book-alert:before {
  content: "󱙼";
}

.mdi-book-alert-outline:before {
  content: "󱙽";
}

.mdi-book-alphabet:before {
  content: "󰘝";
}

.mdi-book-arrow-down:before {
  content: "󱙾";
}

.mdi-book-arrow-down-outline:before {
  content: "󱙿";
}

.mdi-book-arrow-left:before {
  content: "󱚀";
}

.mdi-book-arrow-left-outline:before {
  content: "󱚁";
}

.mdi-book-arrow-right:before {
  content: "󱚂";
}

.mdi-book-arrow-right-outline:before {
  content: "󱚃";
}

.mdi-book-arrow-up:before {
  content: "󱚄";
}

.mdi-book-arrow-up-outline:before {
  content: "󱚅";
}

.mdi-book-cancel:before {
  content: "󱚆";
}

.mdi-book-cancel-outline:before {
  content: "󱚇";
}

.mdi-book-check:before {
  content: "󱓳";
}

.mdi-book-check-outline:before {
  content: "󱓴";
}

.mdi-book-clock:before {
  content: "󱚈";
}

.mdi-book-clock-outline:before {
  content: "󱚉";
}

.mdi-book-cog:before {
  content: "󱚊";
}

.mdi-book-cog-outline:before {
  content: "󱚋";
}

.mdi-book-cross:before {
  content: "󰂢";
}

.mdi-book-edit:before {
  content: "󱚌";
}

.mdi-book-edit-outline:before {
  content: "󱚍";
}

.mdi-book-education:before {
  content: "󱛉";
}

.mdi-book-education-outline:before {
  content: "󱛊";
}

.mdi-book-information-variant:before {
  content: "󱁯";
}

.mdi-book-lock:before {
  content: "󰞚";
}

.mdi-book-lock-open:before {
  content: "󰞛";
}

.mdi-book-lock-open-outline:before {
  content: "󱚎";
}

.mdi-book-lock-outline:before {
  content: "󱚏";
}

.mdi-book-marker:before {
  content: "󱚐";
}

.mdi-book-marker-outline:before {
  content: "󱚑";
}

.mdi-book-minus:before {
  content: "󰗙";
}

.mdi-book-minus-multiple:before {
  content: "󰪔";
}

.mdi-book-minus-multiple-outline:before {
  content: "󰤋";
}

.mdi-book-minus-outline:before {
  content: "󱚒";
}

.mdi-book-multiple:before {
  content: "󰂻";
}

.mdi-book-multiple-outline:before {
  content: "󰐶";
}

.mdi-book-music:before {
  content: "󰁧";
}

.mdi-book-music-outline:before {
  content: "󱚓";
}

.mdi-book-off:before {
  content: "󱚔";
}

.mdi-book-off-outline:before {
  content: "󱚕";
}

.mdi-book-open:before {
  content: "󰂽";
}

.mdi-book-open-blank-variant:before {
  content: "󰂾";
}

.mdi-book-open-outline:before {
  content: "󰭣";
}

.mdi-book-open-page-variant:before {
  content: "󰗚";
}

.mdi-book-open-page-variant-outline:before {
  content: "󱗖";
}

.mdi-book-open-variant:before {
  content: "󱓷";
}

.mdi-book-outline:before {
  content: "󰭤";
}

.mdi-book-play:before {
  content: "󰺂";
}

.mdi-book-play-outline:before {
  content: "󰺃";
}

.mdi-book-plus:before {
  content: "󰗛";
}

.mdi-book-plus-multiple:before {
  content: "󰪕";
}

.mdi-book-plus-multiple-outline:before {
  content: "󰫞";
}

.mdi-book-plus-outline:before {
  content: "󱚖";
}

.mdi-book-refresh:before {
  content: "󱚗";
}

.mdi-book-refresh-outline:before {
  content: "󱚘";
}

.mdi-book-remove:before {
  content: "󰪗";
}

.mdi-book-remove-multiple:before {
  content: "󰪖";
}

.mdi-book-remove-multiple-outline:before {
  content: "󰓊";
}

.mdi-book-remove-outline:before {
  content: "󱚙";
}

.mdi-book-search:before {
  content: "󰺄";
}

.mdi-book-search-outline:before {
  content: "󰺅";
}

.mdi-book-settings:before {
  content: "󱚚";
}

.mdi-book-settings-outline:before {
  content: "󱚛";
}

.mdi-book-sync:before {
  content: "󱚜";
}

.mdi-book-sync-outline:before {
  content: "󱛈";
}

.mdi-book-variant:before {
  content: "󰂿";
}

.mdi-book-variant-multiple:before {
  content: "󰂼";
}

.mdi-bookmark:before {
  content: "󰃀";
}

.mdi-bookmark-check:before {
  content: "󰃁";
}

.mdi-bookmark-check-outline:before {
  content: "󱍻";
}

.mdi-bookmark-minus:before {
  content: "󰧌";
}

.mdi-bookmark-minus-outline:before {
  content: "󰧍";
}

.mdi-bookmark-multiple:before {
  content: "󰸕";
}

.mdi-bookmark-multiple-outline:before {
  content: "󰸖";
}

.mdi-bookmark-music:before {
  content: "󰃂";
}

.mdi-bookmark-music-outline:before {
  content: "󱍹";
}

.mdi-bookmark-off:before {
  content: "󰧎";
}

.mdi-bookmark-off-outline:before {
  content: "󰧏";
}

.mdi-bookmark-outline:before {
  content: "󰃃";
}

.mdi-bookmark-plus:before {
  content: "󰃅";
}

.mdi-bookmark-plus-outline:before {
  content: "󰃄";
}

.mdi-bookmark-remove:before {
  content: "󰃆";
}

.mdi-bookmark-remove-outline:before {
  content: "󱍺";
}

.mdi-bookshelf:before {
  content: "󱉟";
}

.mdi-boom-gate:before {
  content: "󰺆";
}

.mdi-boom-gate-alert:before {
  content: "󰺇";
}

.mdi-boom-gate-alert-outline:before {
  content: "󰺈";
}

.mdi-boom-gate-down:before {
  content: "󰺉";
}

.mdi-boom-gate-down-outline:before {
  content: "󰺊";
}

.mdi-boom-gate-outline:before {
  content: "󰺋";
}

.mdi-boom-gate-up:before {
  content: "󰺌";
}

.mdi-boom-gate-up-outline:before {
  content: "󰺍";
}

.mdi-boombox:before {
  content: "󰗜";
}

.mdi-boomerang:before {
  content: "󱃏";
}

.mdi-bootstrap:before {
  content: "󰛆";
}

.mdi-border-all:before {
  content: "󰃇";
}

.mdi-border-all-variant:before {
  content: "󰢡";
}

.mdi-border-bottom:before {
  content: "󰃈";
}

.mdi-border-bottom-variant:before {
  content: "󰢢";
}

.mdi-border-color:before {
  content: "󰃉";
}

.mdi-border-horizontal:before {
  content: "󰃊";
}

.mdi-border-inside:before {
  content: "󰃋";
}

.mdi-border-left:before {
  content: "󰃌";
}

.mdi-border-left-variant:before {
  content: "󰢣";
}

.mdi-border-none:before {
  content: "󰃍";
}

.mdi-border-none-variant:before {
  content: "󰢤";
}

.mdi-border-outside:before {
  content: "󰃎";
}

.mdi-border-right:before {
  content: "󰃏";
}

.mdi-border-right-variant:before {
  content: "󰢥";
}

.mdi-border-style:before {
  content: "󰃐";
}

.mdi-border-top:before {
  content: "󰃑";
}

.mdi-border-top-variant:before {
  content: "󰢦";
}

.mdi-border-vertical:before {
  content: "󰃒";
}

.mdi-bottle-soda:before {
  content: "󱁰";
}

.mdi-bottle-soda-classic:before {
  content: "󱁱";
}

.mdi-bottle-soda-classic-outline:before {
  content: "󱍣";
}

.mdi-bottle-soda-outline:before {
  content: "󱁲";
}

.mdi-bottle-tonic:before {
  content: "󱄮";
}

.mdi-bottle-tonic-outline:before {
  content: "󱄯";
}

.mdi-bottle-tonic-plus:before {
  content: "󱄰";
}

.mdi-bottle-tonic-plus-outline:before {
  content: "󱄱";
}

.mdi-bottle-tonic-skull:before {
  content: "󱄲";
}

.mdi-bottle-tonic-skull-outline:before {
  content: "󱄳";
}

.mdi-bottle-wine:before {
  content: "󰡔";
}

.mdi-bottle-wine-outline:before {
  content: "󱌐";
}

.mdi-bow-tie:before {
  content: "󰙸";
}

.mdi-bowl:before {
  content: "󰊎";
}

.mdi-bowl-mix:before {
  content: "󰘗";
}

.mdi-bowl-mix-outline:before {
  content: "󰋤";
}

.mdi-bowl-outline:before {
  content: "󰊩";
}

.mdi-bowling:before {
  content: "󰃓";
}

.mdi-box:before {
  content: "󰃔";
}

.mdi-box-cutter:before {
  content: "󰃕";
}

.mdi-box-cutter-off:before {
  content: "󰭊";
}

.mdi-box-shadow:before {
  content: "󰘷";
}

.mdi-boxing-glove:before {
  content: "󰭥";
}

.mdi-braille:before {
  content: "󰧐";
}

.mdi-brain:before {
  content: "󰧑";
}

.mdi-bread-slice:before {
  content: "󰳮";
}

.mdi-bread-slice-outline:before {
  content: "󰳯";
}

.mdi-bridge:before {
  content: "󰘘";
}

.mdi-briefcase:before {
  content: "󰃖";
}

.mdi-briefcase-account:before {
  content: "󰳰";
}

.mdi-briefcase-account-outline:before {
  content: "󰳱";
}

.mdi-briefcase-check:before {
  content: "󰃗";
}

.mdi-briefcase-check-outline:before {
  content: "󱌞";
}

.mdi-briefcase-clock:before {
  content: "󱃐";
}

.mdi-briefcase-clock-outline:before {
  content: "󱃑";
}

.mdi-briefcase-download:before {
  content: "󰃘";
}

.mdi-briefcase-download-outline:before {
  content: "󰰽";
}

.mdi-briefcase-edit:before {
  content: "󰪘";
}

.mdi-briefcase-edit-outline:before {
  content: "󰰾";
}

.mdi-briefcase-minus:before {
  content: "󰨪";
}

.mdi-briefcase-minus-outline:before {
  content: "󰰿";
}

.mdi-briefcase-off:before {
  content: "󱙘";
}

.mdi-briefcase-off-outline:before {
  content: "󱙙";
}

.mdi-briefcase-outline:before {
  content: "󰠔";
}

.mdi-briefcase-plus:before {
  content: "󰨫";
}

.mdi-briefcase-plus-outline:before {
  content: "󰱀";
}

.mdi-briefcase-remove:before {
  content: "󰨬";
}

.mdi-briefcase-remove-outline:before {
  content: "󰱁";
}

.mdi-briefcase-search:before {
  content: "󰨭";
}

.mdi-briefcase-search-outline:before {
  content: "󰱂";
}

.mdi-briefcase-upload:before {
  content: "󰃙";
}

.mdi-briefcase-upload-outline:before {
  content: "󰱃";
}

.mdi-briefcase-variant:before {
  content: "󱒔";
}

.mdi-briefcase-variant-off:before {
  content: "󱙚";
}

.mdi-briefcase-variant-off-outline:before {
  content: "󱙛";
}

.mdi-briefcase-variant-outline:before {
  content: "󱒕";
}

.mdi-brightness-1:before {
  content: "󰃚";
}

.mdi-brightness-2:before {
  content: "󰃛";
}

.mdi-brightness-3:before {
  content: "󰃜";
}

.mdi-brightness-4:before {
  content: "󰃝";
}

.mdi-brightness-5:before {
  content: "󰃞";
}

.mdi-brightness-6:before {
  content: "󰃟";
}

.mdi-brightness-7:before {
  content: "󰃠";
}

.mdi-brightness-auto:before {
  content: "󰃡";
}

.mdi-brightness-percent:before {
  content: "󰳲";
}

.mdi-broadcast:before {
  content: "󱜠";
}

.mdi-broadcast-off:before {
  content: "󱜡";
}

.mdi-broom:before {
  content: "󰃢";
}

.mdi-brush:before {
  content: "󰃣";
}

.mdi-bucket:before {
  content: "󱐕";
}

.mdi-bucket-outline:before {
  content: "󱐖";
}

.mdi-buddhism:before {
  content: "󰥋";
}

.mdi-buffer:before {
  content: "󰘙";
}

.mdi-buffet:before {
  content: "󰕸";
}

.mdi-bug:before {
  content: "󰃤";
}

.mdi-bug-check:before {
  content: "󰨮";
}

.mdi-bug-check-outline:before {
  content: "󰨯";
}

.mdi-bug-outline:before {
  content: "󰨰";
}

.mdi-bugle:before {
  content: "󰶴";
}

.mdi-bulldozer:before {
  content: "󰬢";
}

.mdi-bullet:before {
  content: "󰳳";
}

.mdi-bulletin-board:before {
  content: "󰃥";
}

.mdi-bullhorn:before {
  content: "󰃦";
}

.mdi-bullhorn-outline:before {
  content: "󰬣";
}

.mdi-bullseye:before {
  content: "󰗝";
}

.mdi-bullseye-arrow:before {
  content: "󰣉";
}

.mdi-bulma:before {
  content: "󱋧";
}

.mdi-bunk-bed:before {
  content: "󱌂";
}

.mdi-bunk-bed-outline:before {
  content: "󰂗";
}

.mdi-bus:before {
  content: "󰃧";
}

.mdi-bus-alert:before {
  content: "󰪙";
}

.mdi-bus-articulated-end:before {
  content: "󰞜";
}

.mdi-bus-articulated-front:before {
  content: "󰞝";
}

.mdi-bus-clock:before {
  content: "󰣊";
}

.mdi-bus-double-decker:before {
  content: "󰞞";
}

.mdi-bus-marker:before {
  content: "󱈒";
}

.mdi-bus-multiple:before {
  content: "󰼿";
}

.mdi-bus-school:before {
  content: "󰞟";
}

.mdi-bus-side:before {
  content: "󰞠";
}

.mdi-bus-stop:before {
  content: "󱀒";
}

.mdi-bus-stop-covered:before {
  content: "󱀓";
}

.mdi-bus-stop-uncovered:before {
  content: "󱀔";
}

.mdi-butterfly:before {
  content: "󱖉";
}

.mdi-butterfly-outline:before {
  content: "󱖊";
}

.mdi-cable-data:before {
  content: "󱎔";
}

.mdi-cached:before {
  content: "󰃨";
}

.mdi-cactus:before {
  content: "󰶵";
}

.mdi-cake:before {
  content: "󰃩";
}

.mdi-cake-layered:before {
  content: "󰃪";
}

.mdi-cake-variant:before {
  content: "󰃫";
}

.mdi-calculator:before {
  content: "󰃬";
}

.mdi-calculator-variant:before {
  content: "󰪚";
}

.mdi-calculator-variant-outline:before {
  content: "󱖦";
}

.mdi-calendar:before {
  content: "󰃭";
}

.mdi-calendar-account:before {
  content: "󰻗";
}

.mdi-calendar-account-outline:before {
  content: "󰻘";
}

.mdi-calendar-alert:before {
  content: "󰨱";
}

.mdi-calendar-arrow-left:before {
  content: "󱄴";
}

.mdi-calendar-arrow-right:before {
  content: "󱄵";
}

.mdi-calendar-blank:before {
  content: "󰃮";
}

.mdi-calendar-blank-multiple:before {
  content: "󱁳";
}

.mdi-calendar-blank-outline:before {
  content: "󰭦";
}

.mdi-calendar-check:before {
  content: "󰃯";
}

.mdi-calendar-check-outline:before {
  content: "󰱄";
}

.mdi-calendar-clock:before {
  content: "󰃰";
}

.mdi-calendar-clock-outline:before {
  content: "󱛡";
}

.mdi-calendar-cursor:before {
  content: "󱕻";
}

.mdi-calendar-edit:before {
  content: "󰢧";
}

.mdi-calendar-end:before {
  content: "󱙬";
}

.mdi-calendar-export:before {
  content: "󰬤";
}

.mdi-calendar-heart:before {
  content: "󰧒";
}

.mdi-calendar-import:before {
  content: "󰬥";
}

.mdi-calendar-lock:before {
  content: "󱙁";
}

.mdi-calendar-lock-outline:before {
  content: "󱙂";
}

.mdi-calendar-minus:before {
  content: "󰵜";
}

.mdi-calendar-month:before {
  content: "󰸗";
}

.mdi-calendar-month-outline:before {
  content: "󰸘";
}

.mdi-calendar-multiple:before {
  content: "󰃱";
}

.mdi-calendar-multiple-check:before {
  content: "󰃲";
}

.mdi-calendar-multiselect:before {
  content: "󰨲";
}

.mdi-calendar-outline:before {
  content: "󰭧";
}

.mdi-calendar-plus:before {
  content: "󰃳";
}

.mdi-calendar-question:before {
  content: "󰚒";
}

.mdi-calendar-range:before {
  content: "󰙹";
}

.mdi-calendar-range-outline:before {
  content: "󰭨";
}

.mdi-calendar-refresh:before {
  content: "󰇡";
}

.mdi-calendar-refresh-outline:before {
  content: "󰈃";
}

.mdi-calendar-remove:before {
  content: "󰃴";
}

.mdi-calendar-remove-outline:before {
  content: "󰱅";
}

.mdi-calendar-search:before {
  content: "󰥌";
}

.mdi-calendar-star:before {
  content: "󰧓";
}

.mdi-calendar-start:before {
  content: "󱙭";
}

.mdi-calendar-sync:before {
  content: "󰺎";
}

.mdi-calendar-sync-outline:before {
  content: "󰺏";
}

.mdi-calendar-text:before {
  content: "󰃵";
}

.mdi-calendar-text-outline:before {
  content: "󰱆";
}

.mdi-calendar-today:before {
  content: "󰃶";
}

.mdi-calendar-week:before {
  content: "󰨳";
}

.mdi-calendar-week-begin:before {
  content: "󰨴";
}

.mdi-calendar-weekend:before {
  content: "󰻙";
}

.mdi-calendar-weekend-outline:before {
  content: "󰻚";
}

.mdi-call-made:before {
  content: "󰃷";
}

.mdi-call-merge:before {
  content: "󰃸";
}

.mdi-call-missed:before {
  content: "󰃹";
}

.mdi-call-received:before {
  content: "󰃺";
}

.mdi-call-split:before {
  content: "󰃻";
}

.mdi-camcorder:before {
  content: "󰃼";
}

.mdi-camcorder-off:before {
  content: "󰃿";
}

.mdi-camera:before {
  content: "󰄀";
}

.mdi-camera-account:before {
  content: "󰣋";
}

.mdi-camera-burst:before {
  content: "󰚓";
}

.mdi-camera-control:before {
  content: "󰭩";
}

.mdi-camera-enhance:before {
  content: "󰄁";
}

.mdi-camera-enhance-outline:before {
  content: "󰭪";
}

.mdi-camera-flip:before {
  content: "󱗙";
}

.mdi-camera-flip-outline:before {
  content: "󱗚";
}

.mdi-camera-front:before {
  content: "󰄂";
}

.mdi-camera-front-variant:before {
  content: "󰄃";
}

.mdi-camera-gopro:before {
  content: "󰞡";
}

.mdi-camera-image:before {
  content: "󰣌";
}

.mdi-camera-iris:before {
  content: "󰄄";
}

.mdi-camera-metering-center:before {
  content: "󰞢";
}

.mdi-camera-metering-matrix:before {
  content: "󰞣";
}

.mdi-camera-metering-partial:before {
  content: "󰞤";
}

.mdi-camera-metering-spot:before {
  content: "󰞥";
}

.mdi-camera-off:before {
  content: "󰗟";
}

.mdi-camera-outline:before {
  content: "󰵝";
}

.mdi-camera-party-mode:before {
  content: "󰄅";
}

.mdi-camera-plus:before {
  content: "󰻛";
}

.mdi-camera-plus-outline:before {
  content: "󰻜";
}

.mdi-camera-rear:before {
  content: "󰄆";
}

.mdi-camera-rear-variant:before {
  content: "󰄇";
}

.mdi-camera-retake:before {
  content: "󰸙";
}

.mdi-camera-retake-outline:before {
  content: "󰸚";
}

.mdi-camera-switch:before {
  content: "󰄈";
}

.mdi-camera-switch-outline:before {
  content: "󰡊";
}

.mdi-camera-timer:before {
  content: "󰄉";
}

.mdi-camera-wireless:before {
  content: "󰶶";
}

.mdi-camera-wireless-outline:before {
  content: "󰶷";
}

.mdi-campfire:before {
  content: "󰻝";
}

.mdi-cancel:before {
  content: "󰜺";
}

.mdi-candle:before {
  content: "󰗢";
}

.mdi-candycane:before {
  content: "󰄊";
}

.mdi-cannabis:before {
  content: "󰞦";
}

.mdi-cannabis-off:before {
  content: "󱙮";
}

.mdi-caps-lock:before {
  content: "󰪛";
}

.mdi-car:before {
  content: "󰄋";
}

.mdi-car-2-plus:before {
  content: "󱀕";
}

.mdi-car-3-plus:before {
  content: "󱀖";
}

.mdi-car-arrow-left:before {
  content: "󱎲";
}

.mdi-car-arrow-right:before {
  content: "󱎳";
}

.mdi-car-back:before {
  content: "󰸛";
}

.mdi-car-battery:before {
  content: "󰄌";
}

.mdi-car-brake-abs:before {
  content: "󰱇";
}

.mdi-car-brake-alert:before {
  content: "󰱈";
}

.mdi-car-brake-hold:before {
  content: "󰵞";
}

.mdi-car-brake-parking:before {
  content: "󰵟";
}

.mdi-car-brake-retarder:before {
  content: "󱀗";
}

.mdi-car-child-seat:before {
  content: "󰾣";
}

.mdi-car-clutch:before {
  content: "󱀘";
}

.mdi-car-cog:before {
  content: "󱏌";
}

.mdi-car-connected:before {
  content: "󰄍";
}

.mdi-car-convertible:before {
  content: "󰞧";
}

.mdi-car-coolant-level:before {
  content: "󱀙";
}

.mdi-car-cruise-control:before {
  content: "󰵠";
}

.mdi-car-defrost-front:before {
  content: "󰵡";
}

.mdi-car-defrost-rear:before {
  content: "󰵢";
}

.mdi-car-door:before {
  content: "󰭫";
}

.mdi-car-door-lock:before {
  content: "󱂝";
}

.mdi-car-electric:before {
  content: "󰭬";
}

.mdi-car-electric-outline:before {
  content: "󱖵";
}

.mdi-car-emergency:before {
  content: "󱘏";
}

.mdi-car-esp:before {
  content: "󰱉";
}

.mdi-car-estate:before {
  content: "󰞨";
}

.mdi-car-hatchback:before {
  content: "󰞩";
}

.mdi-car-info:before {
  content: "󱆾";
}

.mdi-car-key:before {
  content: "󰭭";
}

.mdi-car-lifted-pickup:before {
  content: "󱔭";
}

.mdi-car-light-dimmed:before {
  content: "󰱊";
}

.mdi-car-light-fog:before {
  content: "󰱋";
}

.mdi-car-light-high:before {
  content: "󰱌";
}

.mdi-car-limousine:before {
  content: "󰣍";
}

.mdi-car-multiple:before {
  content: "󰭮";
}

.mdi-car-off:before {
  content: "󰸜";
}

.mdi-car-outline:before {
  content: "󱓭";
}

.mdi-car-parking-lights:before {
  content: "󰵣";
}

.mdi-car-pickup:before {
  content: "󰞪";
}

.mdi-car-seat:before {
  content: "󰾤";
}

.mdi-car-seat-cooler:before {
  content: "󰾥";
}

.mdi-car-seat-heater:before {
  content: "󰾦";
}

.mdi-car-settings:before {
  content: "󱏍";
}

.mdi-car-shift-pattern:before {
  content: "󰽀";
}

.mdi-car-side:before {
  content: "󰞫";
}

.mdi-car-sports:before {
  content: "󰞬";
}

.mdi-car-tire-alert:before {
  content: "󰱍";
}

.mdi-car-traction-control:before {
  content: "󰵤";
}

.mdi-car-turbocharger:before {
  content: "󱀚";
}

.mdi-car-wash:before {
  content: "󰄎";
}

.mdi-car-windshield:before {
  content: "󱀛";
}

.mdi-car-windshield-outline:before {
  content: "󱀜";
}

.mdi-carabiner:before {
  content: "󱓀";
}

.mdi-caravan:before {
  content: "󰞭";
}

.mdi-card:before {
  content: "󰭯";
}

.mdi-card-account-details:before {
  content: "󰗒";
}

.mdi-card-account-details-outline:before {
  content: "󰶫";
}

.mdi-card-account-details-star:before {
  content: "󰊣";
}

.mdi-card-account-details-star-outline:before {
  content: "󰛛";
}

.mdi-card-account-mail:before {
  content: "󰆎";
}

.mdi-card-account-mail-outline:before {
  content: "󰺘";
}

.mdi-card-account-phone:before {
  content: "󰺙";
}

.mdi-card-account-phone-outline:before {
  content: "󰺚";
}

.mdi-card-bulleted:before {
  content: "󰭰";
}

.mdi-card-bulleted-off:before {
  content: "󰭱";
}

.mdi-card-bulleted-off-outline:before {
  content: "󰭲";
}

.mdi-card-bulleted-outline:before {
  content: "󰭳";
}

.mdi-card-bulleted-settings:before {
  content: "󰭴";
}

.mdi-card-bulleted-settings-outline:before {
  content: "󰭵";
}

.mdi-card-minus:before {
  content: "󱘀";
}

.mdi-card-minus-outline:before {
  content: "󱘁";
}

.mdi-card-off:before {
  content: "󱘂";
}

.mdi-card-off-outline:before {
  content: "󱘃";
}

.mdi-card-outline:before {
  content: "󰭶";
}

.mdi-card-plus:before {
  content: "󱇿";
}

.mdi-card-plus-outline:before {
  content: "󱈀";
}

.mdi-card-remove:before {
  content: "󱘄";
}

.mdi-card-remove-outline:before {
  content: "󱘅";
}

.mdi-card-search:before {
  content: "󱁴";
}

.mdi-card-search-outline:before {
  content: "󱁵";
}

.mdi-card-text:before {
  content: "󰭷";
}

.mdi-card-text-outline:before {
  content: "󰭸";
}

.mdi-cards:before {
  content: "󰘸";
}

.mdi-cards-club:before {
  content: "󰣎";
}

.mdi-cards-diamond:before {
  content: "󰣏";
}

.mdi-cards-diamond-outline:before {
  content: "󱀝";
}

.mdi-cards-heart:before {
  content: "󰣐";
}

.mdi-cards-outline:before {
  content: "󰘹";
}

.mdi-cards-playing-outline:before {
  content: "󰘺";
}

.mdi-cards-spade:before {
  content: "󰣑";
}

.mdi-cards-variant:before {
  content: "󰛇";
}

.mdi-carrot:before {
  content: "󰄏";
}

.mdi-cart:before {
  content: "󰄐";
}

.mdi-cart-arrow-down:before {
  content: "󰵦";
}

.mdi-cart-arrow-right:before {
  content: "󰱎";
}

.mdi-cart-arrow-up:before {
  content: "󰵧";
}

.mdi-cart-check:before {
  content: "󱗪";
}

.mdi-cart-minus:before {
  content: "󰵨";
}

.mdi-cart-off:before {
  content: "󰙫";
}

.mdi-cart-outline:before {
  content: "󰄑";
}

.mdi-cart-plus:before {
  content: "󰄒";
}

.mdi-cart-remove:before {
  content: "󰵩";
}

.mdi-cart-variant:before {
  content: "󱗫";
}

.mdi-case-sensitive-alt:before {
  content: "󰄓";
}

.mdi-cash:before {
  content: "󰄔";
}

.mdi-cash-100:before {
  content: "󰄕";
}

.mdi-cash-check:before {
  content: "󱓮";
}

.mdi-cash-lock:before {
  content: "󱓪";
}

.mdi-cash-lock-open:before {
  content: "󱓫";
}

.mdi-cash-marker:before {
  content: "󰶸";
}

.mdi-cash-minus:before {
  content: "󱉠";
}

.mdi-cash-multiple:before {
  content: "󰄖";
}

.mdi-cash-plus:before {
  content: "󱉡";
}

.mdi-cash-refund:before {
  content: "󰪜";
}

.mdi-cash-register:before {
  content: "󰳴";
}

.mdi-cash-remove:before {
  content: "󱉢";
}

.mdi-cash-usd:before {
  content: "󱅶";
}

.mdi-cash-usd-outline:before {
  content: "󰄗";
}

.mdi-cassette:before {
  content: "󰧔";
}

.mdi-cast:before {
  content: "󰄘";
}

.mdi-cast-audio:before {
  content: "󱀞";
}

.mdi-cast-connected:before {
  content: "󰄙";
}

.mdi-cast-education:before {
  content: "󰸝";
}

.mdi-cast-off:before {
  content: "󰞊";
}

.mdi-castle:before {
  content: "󰄚";
}

.mdi-cat:before {
  content: "󰄛";
}

.mdi-cctv:before {
  content: "󰞮";
}

.mdi-ceiling-light:before {
  content: "󰝩";
}

.mdi-cellphone:before {
  content: "󰄜";
}

.mdi-cellphone-android:before {
  content: "󰄝";
}

.mdi-cellphone-arrow-down:before {
  content: "󰧕";
}

.mdi-cellphone-basic:before {
  content: "󰄞";
}

.mdi-cellphone-charging:before {
  content: "󱎗";
}

.mdi-cellphone-cog:before {
  content: "󰥑";
}

.mdi-cellphone-dock:before {
  content: "󰄟";
}

.mdi-cellphone-erase:before {
  content: "󰥍";
}

.mdi-cellphone-information:before {
  content: "󰽁";
}

.mdi-cellphone-iphone:before {
  content: "󰄠";
}

.mdi-cellphone-key:before {
  content: "󰥎";
}

.mdi-cellphone-link:before {
  content: "󰄡";
}

.mdi-cellphone-link-off:before {
  content: "󰄢";
}

.mdi-cellphone-lock:before {
  content: "󰥏";
}

.mdi-cellphone-message:before {
  content: "󰣓";
}

.mdi-cellphone-message-off:before {
  content: "󱃒";
}

.mdi-cellphone-nfc:before {
  content: "󰺐";
}

.mdi-cellphone-nfc-off:before {
  content: "󱋘";
}

.mdi-cellphone-off:before {
  content: "󰥐";
}

.mdi-cellphone-play:before {
  content: "󱀟";
}

.mdi-cellphone-screenshot:before {
  content: "󰨵";
}

.mdi-cellphone-settings:before {
  content: "󰄣";
}

.mdi-cellphone-sound:before {
  content: "󰥒";
}

.mdi-cellphone-text:before {
  content: "󰣒";
}

.mdi-cellphone-wireless:before {
  content: "󰠕";
}

.mdi-celtic-cross:before {
  content: "󰳵";
}

.mdi-centos:before {
  content: "󱄚";
}

.mdi-certificate:before {
  content: "󰄤";
}

.mdi-certificate-outline:before {
  content: "󱆈";
}

.mdi-chair-rolling:before {
  content: "󰽈";
}

.mdi-chair-school:before {
  content: "󰄥";
}

.mdi-charity:before {
  content: "󰱏";
}

.mdi-chart-arc:before {
  content: "󰄦";
}

.mdi-chart-areaspline:before {
  content: "󰄧";
}

.mdi-chart-areaspline-variant:before {
  content: "󰺑";
}

.mdi-chart-bar:before {
  content: "󰄨";
}

.mdi-chart-bar-stacked:before {
  content: "󰝪";
}

.mdi-chart-bell-curve:before {
  content: "󰱐";
}

.mdi-chart-bell-curve-cumulative:before {
  content: "󰾧";
}

.mdi-chart-box:before {
  content: "󱕍";
}

.mdi-chart-box-outline:before {
  content: "󱕎";
}

.mdi-chart-box-plus-outline:before {
  content: "󱕏";
}

.mdi-chart-bubble:before {
  content: "󰗣";
}

.mdi-chart-donut:before {
  content: "󰞯";
}

.mdi-chart-donut-variant:before {
  content: "󰞰";
}

.mdi-chart-gantt:before {
  content: "󰙬";
}

.mdi-chart-histogram:before {
  content: "󰄩";
}

.mdi-chart-line:before {
  content: "󰄪";
}

.mdi-chart-line-stacked:before {
  content: "󰝫";
}

.mdi-chart-line-variant:before {
  content: "󰞱";
}

.mdi-chart-multiline:before {
  content: "󰣔";
}

.mdi-chart-multiple:before {
  content: "󱈓";
}

.mdi-chart-pie:before {
  content: "󰄫";
}

.mdi-chart-ppf:before {
  content: "󱎀";
}

.mdi-chart-sankey:before {
  content: "󱇟";
}

.mdi-chart-sankey-variant:before {
  content: "󱇠";
}

.mdi-chart-scatter-plot:before {
  content: "󰺒";
}

.mdi-chart-scatter-plot-hexbin:before {
  content: "󰙭";
}

.mdi-chart-timeline:before {
  content: "󰙮";
}

.mdi-chart-timeline-variant:before {
  content: "󰺓";
}

.mdi-chart-timeline-variant-shimmer:before {
  content: "󱖶";
}

.mdi-chart-tree:before {
  content: "󰺔";
}

.mdi-chat:before {
  content: "󰭹";
}

.mdi-chat-alert:before {
  content: "󰭺";
}

.mdi-chat-alert-outline:before {
  content: "󱋉";
}

.mdi-chat-minus:before {
  content: "󱐐";
}

.mdi-chat-minus-outline:before {
  content: "󱐓";
}

.mdi-chat-outline:before {
  content: "󰻞";
}

.mdi-chat-plus:before {
  content: "󱐏";
}

.mdi-chat-plus-outline:before {
  content: "󱐒";
}

.mdi-chat-processing:before {
  content: "󰭻";
}

.mdi-chat-processing-outline:before {
  content: "󱋊";
}

.mdi-chat-question:before {
  content: "󱜸";
}

.mdi-chat-question-outline:before {
  content: "󱜹";
}

.mdi-chat-remove:before {
  content: "󱐑";
}

.mdi-chat-remove-outline:before {
  content: "󱐔";
}

.mdi-chat-sleep:before {
  content: "󱋑";
}

.mdi-chat-sleep-outline:before {
  content: "󱋒";
}

.mdi-check:before {
  content: "󰄬";
}

.mdi-check-all:before {
  content: "󰄭";
}

.mdi-check-bold:before {
  content: "󰸞";
}

.mdi-check-box-multiple-outline:before {
  content: "󰱑";
}

.mdi-check-box-outline:before {
  content: "󰱒";
}

.mdi-check-circle:before {
  content: "󰗠";
}

.mdi-check-circle-outline:before {
  content: "󰗡";
}

.mdi-check-decagram:before {
  content: "󰞑";
}

.mdi-check-decagram-outline:before {
  content: "󱝀";
}

.mdi-check-network:before {
  content: "󰱓";
}

.mdi-check-network-outline:before {
  content: "󰱔";
}

.mdi-check-outline:before {
  content: "󰡕";
}

.mdi-check-underline:before {
  content: "󰸟";
}

.mdi-check-underline-circle:before {
  content: "󰸠";
}

.mdi-check-underline-circle-outline:before {
  content: "󰸡";
}

.mdi-checkbook:before {
  content: "󰪝";
}

.mdi-checkbox-blank:before {
  content: "󰄮";
}

.mdi-checkbox-blank-circle:before {
  content: "󰄯";
}

.mdi-checkbox-blank-circle-outline:before {
  content: "󰄰";
}

.mdi-checkbox-blank-off:before {
  content: "󱋬";
}

.mdi-checkbox-blank-off-outline:before {
  content: "󱋭";
}

.mdi-checkbox-blank-outline:before {
  content: "󰄱";
}

.mdi-checkbox-intermediate:before {
  content: "󰡖";
}

.mdi-checkbox-marked:before {
  content: "󰄲";
}

.mdi-checkbox-marked-circle:before {
  content: "󰄳";
}

.mdi-checkbox-marked-circle-outline:before {
  content: "󰄴";
}

.mdi-checkbox-marked-outline:before {
  content: "󰄵";
}

.mdi-checkbox-multiple-blank:before {
  content: "󰄶";
}

.mdi-checkbox-multiple-blank-circle:before {
  content: "󰘻";
}

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "󰘼";
}

.mdi-checkbox-multiple-blank-outline:before {
  content: "󰄷";
}

.mdi-checkbox-multiple-marked:before {
  content: "󰄸";
}

.mdi-checkbox-multiple-marked-circle:before {
  content: "󰘽";
}

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "󰘾";
}

.mdi-checkbox-multiple-marked-outline:before {
  content: "󰄹";
}

.mdi-checkerboard:before {
  content: "󰄺";
}

.mdi-checkerboard-minus:before {
  content: "󱈂";
}

.mdi-checkerboard-plus:before {
  content: "󱈁";
}

.mdi-checkerboard-remove:before {
  content: "󱈃";
}

.mdi-cheese:before {
  content: "󱊹";
}

.mdi-cheese-off:before {
  content: "󱏮";
}

.mdi-chef-hat:before {
  content: "󰭼";
}

.mdi-chemical-weapon:before {
  content: "󰄻";
}

.mdi-chess-bishop:before {
  content: "󰡜";
}

.mdi-chess-king:before {
  content: "󰡗";
}

.mdi-chess-knight:before {
  content: "󰡘";
}

.mdi-chess-pawn:before {
  content: "󰡙";
}

.mdi-chess-queen:before {
  content: "󰡚";
}

.mdi-chess-rook:before {
  content: "󰡛";
}

.mdi-chevron-double-down:before {
  content: "󰄼";
}

.mdi-chevron-double-left:before {
  content: "󰄽";
}

.mdi-chevron-double-right:before {
  content: "󰄾";
}

.mdi-chevron-double-up:before {
  content: "󰄿";
}

.mdi-chevron-down:before {
  content: "󰅀";
}

.mdi-chevron-down-box:before {
  content: "󰧖";
}

.mdi-chevron-down-box-outline:before {
  content: "󰧗";
}

.mdi-chevron-down-circle:before {
  content: "󰬦";
}

.mdi-chevron-down-circle-outline:before {
  content: "󰬧";
}

.mdi-chevron-left:before {
  content: "󰅁";
}

.mdi-chevron-left-box:before {
  content: "󰧘";
}

.mdi-chevron-left-box-outline:before {
  content: "󰧙";
}

.mdi-chevron-left-circle:before {
  content: "󰬨";
}

.mdi-chevron-left-circle-outline:before {
  content: "󰬩";
}

.mdi-chevron-right:before {
  content: "󰅂";
}

.mdi-chevron-right-box:before {
  content: "󰧚";
}

.mdi-chevron-right-box-outline:before {
  content: "󰧛";
}

.mdi-chevron-right-circle:before {
  content: "󰬪";
}

.mdi-chevron-right-circle-outline:before {
  content: "󰬫";
}

.mdi-chevron-triple-down:before {
  content: "󰶹";
}

.mdi-chevron-triple-left:before {
  content: "󰶺";
}

.mdi-chevron-triple-right:before {
  content: "󰶻";
}

.mdi-chevron-triple-up:before {
  content: "󰶼";
}

.mdi-chevron-up:before {
  content: "󰅃";
}

.mdi-chevron-up-box:before {
  content: "󰧜";
}

.mdi-chevron-up-box-outline:before {
  content: "󰧝";
}

.mdi-chevron-up-circle:before {
  content: "󰬬";
}

.mdi-chevron-up-circle-outline:before {
  content: "󰬭";
}

.mdi-chili-hot:before {
  content: "󰞲";
}

.mdi-chili-medium:before {
  content: "󰞳";
}

.mdi-chili-mild:before {
  content: "󰞴";
}

.mdi-chili-off:before {
  content: "󱑧";
}

.mdi-chip:before {
  content: "󰘚";
}

.mdi-christianity:before {
  content: "󰥓";
}

.mdi-christianity-outline:before {
  content: "󰳶";
}

.mdi-church:before {
  content: "󰅄";
}

.mdi-cigar:before {
  content: "󱆉";
}

.mdi-cigar-off:before {
  content: "󱐛";
}

.mdi-circle:before {
  content: "󰝥";
}

.mdi-circle-box:before {
  content: "󱗜";
}

.mdi-circle-box-outline:before {
  content: "󱗝";
}

.mdi-circle-double:before {
  content: "󰺕";
}

.mdi-circle-edit-outline:before {
  content: "󰣕";
}

.mdi-circle-expand:before {
  content: "󰺖";
}

.mdi-circle-half:before {
  content: "󱎕";
}

.mdi-circle-half-full:before {
  content: "󱎖";
}

.mdi-circle-medium:before {
  content: "󰧞";
}

.mdi-circle-multiple:before {
  content: "󰬸";
}

.mdi-circle-multiple-outline:before {
  content: "󰚕";
}

.mdi-circle-off-outline:before {
  content: "󱃓";
}

.mdi-circle-outline:before {
  content: "󰝦";
}

.mdi-circle-slice-1:before {
  content: "󰪞";
}

.mdi-circle-slice-2:before {
  content: "󰪟";
}

.mdi-circle-slice-3:before {
  content: "󰪠";
}

.mdi-circle-slice-4:before {
  content: "󰪡";
}

.mdi-circle-slice-5:before {
  content: "󰪢";
}

.mdi-circle-slice-6:before {
  content: "󰪣";
}

.mdi-circle-slice-7:before {
  content: "󰪤";
}

.mdi-circle-slice-8:before {
  content: "󰪥";
}

.mdi-circle-small:before {
  content: "󰧟";
}

.mdi-circular-saw:before {
  content: "󰸢";
}

.mdi-city:before {
  content: "󰅆";
}

.mdi-city-variant:before {
  content: "󰨶";
}

.mdi-city-variant-outline:before {
  content: "󰨷";
}

.mdi-clipboard:before {
  content: "󰅇";
}

.mdi-clipboard-account:before {
  content: "󰅈";
}

.mdi-clipboard-account-outline:before {
  content: "󰱕";
}

.mdi-clipboard-alert:before {
  content: "󰅉";
}

.mdi-clipboard-alert-outline:before {
  content: "󰳷";
}

.mdi-clipboard-arrow-down:before {
  content: "󰅊";
}

.mdi-clipboard-arrow-down-outline:before {
  content: "󰱖";
}

.mdi-clipboard-arrow-left:before {
  content: "󰅋";
}

.mdi-clipboard-arrow-left-outline:before {
  content: "󰳸";
}

.mdi-clipboard-arrow-right:before {
  content: "󰳹";
}

.mdi-clipboard-arrow-right-outline:before {
  content: "󰳺";
}

.mdi-clipboard-arrow-up:before {
  content: "󰱗";
}

.mdi-clipboard-arrow-up-outline:before {
  content: "󰱘";
}

.mdi-clipboard-check:before {
  content: "󰅎";
}

.mdi-clipboard-check-multiple:before {
  content: "󱉣";
}

.mdi-clipboard-check-multiple-outline:before {
  content: "󱉤";
}

.mdi-clipboard-check-outline:before {
  content: "󰢨";
}

.mdi-clipboard-clock:before {
  content: "󱛢";
}

.mdi-clipboard-clock-outline:before {
  content: "󱛣";
}

.mdi-clipboard-edit:before {
  content: "󱓥";
}

.mdi-clipboard-edit-outline:before {
  content: "󱓦";
}

.mdi-clipboard-file:before {
  content: "󱉥";
}

.mdi-clipboard-file-outline:before {
  content: "󱉦";
}

.mdi-clipboard-flow:before {
  content: "󰛈";
}

.mdi-clipboard-flow-outline:before {
  content: "󱄗";
}

.mdi-clipboard-list:before {
  content: "󱃔";
}

.mdi-clipboard-list-outline:before {
  content: "󱃕";
}

.mdi-clipboard-minus:before {
  content: "󱘘";
}

.mdi-clipboard-minus-outline:before {
  content: "󱘙";
}

.mdi-clipboard-multiple:before {
  content: "󱉧";
}

.mdi-clipboard-multiple-outline:before {
  content: "󱉨";
}

.mdi-clipboard-off:before {
  content: "󱘚";
}

.mdi-clipboard-off-outline:before {
  content: "󱘛";
}

.mdi-clipboard-outline:before {
  content: "󰅌";
}

.mdi-clipboard-play:before {
  content: "󰱙";
}

.mdi-clipboard-play-multiple:before {
  content: "󱉩";
}

.mdi-clipboard-play-multiple-outline:before {
  content: "󱉪";
}

.mdi-clipboard-play-outline:before {
  content: "󰱚";
}

.mdi-clipboard-plus:before {
  content: "󰝑";
}

.mdi-clipboard-plus-outline:before {
  content: "󱌟";
}

.mdi-clipboard-pulse:before {
  content: "󰡝";
}

.mdi-clipboard-pulse-outline:before {
  content: "󰡞";
}

.mdi-clipboard-remove:before {
  content: "󱘜";
}

.mdi-clipboard-remove-outline:before {
  content: "󱘝";
}

.mdi-clipboard-search:before {
  content: "󱘞";
}

.mdi-clipboard-search-outline:before {
  content: "󱘟";
}

.mdi-clipboard-text:before {
  content: "󰅍";
}

.mdi-clipboard-text-multiple:before {
  content: "󱉫";
}

.mdi-clipboard-text-multiple-outline:before {
  content: "󱉬";
}

.mdi-clipboard-text-off:before {
  content: "󱘠";
}

.mdi-clipboard-text-off-outline:before {
  content: "󱘡";
}

.mdi-clipboard-text-outline:before {
  content: "󰨸";
}

.mdi-clipboard-text-play:before {
  content: "󰱛";
}

.mdi-clipboard-text-play-outline:before {
  content: "󰱜";
}

.mdi-clipboard-text-search:before {
  content: "󱘢";
}

.mdi-clipboard-text-search-outline:before {
  content: "󱘣";
}

.mdi-clippy:before {
  content: "󰅏";
}

.mdi-clock:before {
  content: "󰥔";
}

.mdi-clock-alert:before {
  content: "󰥕";
}

.mdi-clock-alert-outline:before {
  content: "󰗎";
}

.mdi-clock-check:before {
  content: "󰾨";
}

.mdi-clock-check-outline:before {
  content: "󰾩";
}

.mdi-clock-digital:before {
  content: "󰺗";
}

.mdi-clock-end:before {
  content: "󰅑";
}

.mdi-clock-fast:before {
  content: "󰅒";
}

.mdi-clock-in:before {
  content: "󰅓";
}

.mdi-clock-out:before {
  content: "󰅔";
}

.mdi-clock-outline:before {
  content: "󰅐";
}

.mdi-clock-start:before {
  content: "󰅕";
}

.mdi-clock-time-eight:before {
  content: "󱑆";
}

.mdi-clock-time-eight-outline:before {
  content: "󱑒";
}

.mdi-clock-time-eleven:before {
  content: "󱑉";
}

.mdi-clock-time-eleven-outline:before {
  content: "󱑕";
}

.mdi-clock-time-five:before {
  content: "󱑃";
}

.mdi-clock-time-five-outline:before {
  content: "󱑏";
}

.mdi-clock-time-four:before {
  content: "󱑂";
}

.mdi-clock-time-four-outline:before {
  content: "󱑎";
}

.mdi-clock-time-nine:before {
  content: "󱑇";
}

.mdi-clock-time-nine-outline:before {
  content: "󱑓";
}

.mdi-clock-time-one:before {
  content: "󱐿";
}

.mdi-clock-time-one-outline:before {
  content: "󱑋";
}

.mdi-clock-time-seven:before {
  content: "󱑅";
}

.mdi-clock-time-seven-outline:before {
  content: "󱑑";
}

.mdi-clock-time-six:before {
  content: "󱑄";
}

.mdi-clock-time-six-outline:before {
  content: "󱑐";
}

.mdi-clock-time-ten:before {
  content: "󱑈";
}

.mdi-clock-time-ten-outline:before {
  content: "󱑔";
}

.mdi-clock-time-three:before {
  content: "󱑁";
}

.mdi-clock-time-three-outline:before {
  content: "󱑍";
}

.mdi-clock-time-twelve:before {
  content: "󱑊";
}

.mdi-clock-time-twelve-outline:before {
  content: "󱑖";
}

.mdi-clock-time-two:before {
  content: "󱑀";
}

.mdi-clock-time-two-outline:before {
  content: "󱑌";
}

.mdi-close:before {
  content: "󰅖";
}

.mdi-close-box:before {
  content: "󰅗";
}

.mdi-close-box-multiple:before {
  content: "󰱝";
}

.mdi-close-box-multiple-outline:before {
  content: "󰱞";
}

.mdi-close-box-outline:before {
  content: "󰅘";
}

.mdi-close-circle:before {
  content: "󰅙";
}

.mdi-close-circle-multiple:before {
  content: "󰘪";
}

.mdi-close-circle-multiple-outline:before {
  content: "󰢃";
}

.mdi-close-circle-outline:before {
  content: "󰅚";
}

.mdi-close-network:before {
  content: "󰅛";
}

.mdi-close-network-outline:before {
  content: "󰱟";
}

.mdi-close-octagon:before {
  content: "󰅜";
}

.mdi-close-octagon-outline:before {
  content: "󰅝";
}

.mdi-close-outline:before {
  content: "󰛉";
}

.mdi-close-thick:before {
  content: "󱎘";
}

.mdi-closed-caption:before {
  content: "󰅞";
}

.mdi-closed-caption-outline:before {
  content: "󰶽";
}

.mdi-cloud:before {
  content: "󰅟";
}

.mdi-cloud-alert:before {
  content: "󰧠";
}

.mdi-cloud-braces:before {
  content: "󰞵";
}

.mdi-cloud-check:before {
  content: "󰅠";
}

.mdi-cloud-check-outline:before {
  content: "󱋌";
}

.mdi-cloud-circle:before {
  content: "󰅡";
}

.mdi-cloud-download:before {
  content: "󰅢";
}

.mdi-cloud-download-outline:before {
  content: "󰭽";
}

.mdi-cloud-lock:before {
  content: "󱇱";
}

.mdi-cloud-lock-outline:before {
  content: "󱇲";
}

.mdi-cloud-off-outline:before {
  content: "󰅤";
}

.mdi-cloud-outline:before {
  content: "󰅣";
}

.mdi-cloud-print:before {
  content: "󰅥";
}

.mdi-cloud-print-outline:before {
  content: "󰅦";
}

.mdi-cloud-question:before {
  content: "󰨹";
}

.mdi-cloud-refresh:before {
  content: "󰔪";
}

.mdi-cloud-search:before {
  content: "󰥖";
}

.mdi-cloud-search-outline:before {
  content: "󰥗";
}

.mdi-cloud-sync:before {
  content: "󰘿";
}

.mdi-cloud-sync-outline:before {
  content: "󱋖";
}

.mdi-cloud-tags:before {
  content: "󰞶";
}

.mdi-cloud-upload:before {
  content: "󰅧";
}

.mdi-cloud-upload-outline:before {
  content: "󰭾";
}

.mdi-clover:before {
  content: "󰠖";
}

.mdi-coach-lamp:before {
  content: "󱀠";
}

.mdi-coat-rack:before {
  content: "󱂞";
}

.mdi-code-array:before {
  content: "󰅨";
}

.mdi-code-braces:before {
  content: "󰅩";
}

.mdi-code-braces-box:before {
  content: "󱃖";
}

.mdi-code-brackets:before {
  content: "󰅪";
}

.mdi-code-equal:before {
  content: "󰅫";
}

.mdi-code-greater-than:before {
  content: "󰅬";
}

.mdi-code-greater-than-or-equal:before {
  content: "󰅭";
}

.mdi-code-json:before {
  content: "󰘦";
}

.mdi-code-less-than:before {
  content: "󰅮";
}

.mdi-code-less-than-or-equal:before {
  content: "󰅯";
}

.mdi-code-not-equal:before {
  content: "󰅰";
}

.mdi-code-not-equal-variant:before {
  content: "󰅱";
}

.mdi-code-parentheses:before {
  content: "󰅲";
}

.mdi-code-parentheses-box:before {
  content: "󱃗";
}

.mdi-code-string:before {
  content: "󰅳";
}

.mdi-code-tags:before {
  content: "󰅴";
}

.mdi-code-tags-check:before {
  content: "󰚔";
}

.mdi-codepen:before {
  content: "󰅵";
}

.mdi-coffee:before {
  content: "󰅶";
}

.mdi-coffee-maker:before {
  content: "󱂟";
}

.mdi-coffee-off:before {
  content: "󰾪";
}

.mdi-coffee-off-outline:before {
  content: "󰾫";
}

.mdi-coffee-outline:before {
  content: "󰛊";
}

.mdi-coffee-to-go:before {
  content: "󰅷";
}

.mdi-coffee-to-go-outline:before {
  content: "󱌎";
}

.mdi-coffin:before {
  content: "󰭿";
}

.mdi-cog:before {
  content: "󰒓";
}

.mdi-cog-box:before {
  content: "󰒔";
}

.mdi-cog-clockwise:before {
  content: "󱇝";
}

.mdi-cog-counterclockwise:before {
  content: "󱇞";
}

.mdi-cog-off:before {
  content: "󱏎";
}

.mdi-cog-off-outline:before {
  content: "󱏏";
}

.mdi-cog-outline:before {
  content: "󰢻";
}

.mdi-cog-refresh:before {
  content: "󱑞";
}

.mdi-cog-refresh-outline:before {
  content: "󱑟";
}

.mdi-cog-sync:before {
  content: "󱑠";
}

.mdi-cog-sync-outline:before {
  content: "󱑡";
}

.mdi-cog-transfer:before {
  content: "󱁛";
}

.mdi-cog-transfer-outline:before {
  content: "󱁜";
}

.mdi-cogs:before {
  content: "󰣖";
}

.mdi-collage:before {
  content: "󰙀";
}

.mdi-collapse-all:before {
  content: "󰪦";
}

.mdi-collapse-all-outline:before {
  content: "󰪧";
}

.mdi-color-helper:before {
  content: "󰅹";
}

.mdi-comma:before {
  content: "󰸣";
}

.mdi-comma-box:before {
  content: "󰸫";
}

.mdi-comma-box-outline:before {
  content: "󰸤";
}

.mdi-comma-circle:before {
  content: "󰸥";
}

.mdi-comma-circle-outline:before {
  content: "󰸦";
}

.mdi-comment:before {
  content: "󰅺";
}

.mdi-comment-account:before {
  content: "󰅻";
}

.mdi-comment-account-outline:before {
  content: "󰅼";
}

.mdi-comment-alert:before {
  content: "󰅽";
}

.mdi-comment-alert-outline:before {
  content: "󰅾";
}

.mdi-comment-arrow-left:before {
  content: "󰧡";
}

.mdi-comment-arrow-left-outline:before {
  content: "󰧢";
}

.mdi-comment-arrow-right:before {
  content: "󰧣";
}

.mdi-comment-arrow-right-outline:before {
  content: "󰧤";
}

.mdi-comment-bookmark:before {
  content: "󱖮";
}

.mdi-comment-bookmark-outline:before {
  content: "󱖯";
}

.mdi-comment-check:before {
  content: "󰅿";
}

.mdi-comment-check-outline:before {
  content: "󰆀";
}

.mdi-comment-edit:before {
  content: "󱆿";
}

.mdi-comment-edit-outline:before {
  content: "󱋄";
}

.mdi-comment-eye:before {
  content: "󰨺";
}

.mdi-comment-eye-outline:before {
  content: "󰨻";
}

.mdi-comment-flash:before {
  content: "󱖰";
}

.mdi-comment-flash-outline:before {
  content: "󱖱";
}

.mdi-comment-minus:before {
  content: "󱗟";
}

.mdi-comment-minus-outline:before {
  content: "󱗠";
}

.mdi-comment-multiple:before {
  content: "󰡟";
}

.mdi-comment-multiple-outline:before {
  content: "󰆁";
}

.mdi-comment-off:before {
  content: "󱗡";
}

.mdi-comment-off-outline:before {
  content: "󱗢";
}

.mdi-comment-outline:before {
  content: "󰆂";
}

.mdi-comment-plus:before {
  content: "󰧥";
}

.mdi-comment-plus-outline:before {
  content: "󰆃";
}

.mdi-comment-processing:before {
  content: "󰆄";
}

.mdi-comment-processing-outline:before {
  content: "󰆅";
}

.mdi-comment-question:before {
  content: "󰠗";
}

.mdi-comment-question-outline:before {
  content: "󰆆";
}

.mdi-comment-quote:before {
  content: "󱀡";
}

.mdi-comment-quote-outline:before {
  content: "󱀢";
}

.mdi-comment-remove:before {
  content: "󰗞";
}

.mdi-comment-remove-outline:before {
  content: "󰆇";
}

.mdi-comment-search:before {
  content: "󰨼";
}

.mdi-comment-search-outline:before {
  content: "󰨽";
}

.mdi-comment-text:before {
  content: "󰆈";
}

.mdi-comment-text-multiple:before {
  content: "󰡠";
}

.mdi-comment-text-multiple-outline:before {
  content: "󰡡";
}

.mdi-comment-text-outline:before {
  content: "󰆉";
}

.mdi-compare:before {
  content: "󰆊";
}

.mdi-compare-horizontal:before {
  content: "󱒒";
}

.mdi-compare-vertical:before {
  content: "󱒓";
}

.mdi-compass:before {
  content: "󰆋";
}

.mdi-compass-off:before {
  content: "󰮀";
}

.mdi-compass-off-outline:before {
  content: "󰮁";
}

.mdi-compass-outline:before {
  content: "󰆌";
}

.mdi-compass-rose:before {
  content: "󱎂";
}

.mdi-concourse-ci:before {
  content: "󱂠";
}

.mdi-connection:before {
  content: "󱘖";
}

.mdi-console:before {
  content: "󰆍";
}

.mdi-console-line:before {
  content: "󰞷";
}

.mdi-console-network:before {
  content: "󰢩";
}

.mdi-console-network-outline:before {
  content: "󰱠";
}

.mdi-consolidate:before {
  content: "󱃘";
}

.mdi-contactless-payment:before {
  content: "󰵪";
}

.mdi-contactless-payment-circle:before {
  content: "󰌡";
}

.mdi-contactless-payment-circle-outline:before {
  content: "󰐈";
}

.mdi-contacts:before {
  content: "󰛋";
}

.mdi-contacts-outline:before {
  content: "󰖸";
}

.mdi-contain:before {
  content: "󰨾";
}

.mdi-contain-end:before {
  content: "󰨿";
}

.mdi-contain-start:before {
  content: "󰩀";
}

.mdi-content-copy:before {
  content: "󰆏";
}

.mdi-content-cut:before {
  content: "󰆐";
}

.mdi-content-duplicate:before {
  content: "󰆑";
}

.mdi-content-paste:before {
  content: "󰆒";
}

.mdi-content-save:before {
  content: "󰆓";
}

.mdi-content-save-alert:before {
  content: "󰽂";
}

.mdi-content-save-alert-outline:before {
  content: "󰽃";
}

.mdi-content-save-all:before {
  content: "󰆔";
}

.mdi-content-save-all-outline:before {
  content: "󰽄";
}

.mdi-content-save-cog:before {
  content: "󱑛";
}

.mdi-content-save-cog-outline:before {
  content: "󱑜";
}

.mdi-content-save-edit:before {
  content: "󰳻";
}

.mdi-content-save-edit-outline:before {
  content: "󰳼";
}

.mdi-content-save-move:before {
  content: "󰸧";
}

.mdi-content-save-move-outline:before {
  content: "󰸨";
}

.mdi-content-save-off:before {
  content: "󱙃";
}

.mdi-content-save-off-outline:before {
  content: "󱙄";
}

.mdi-content-save-outline:before {
  content: "󰠘";
}

.mdi-content-save-settings:before {
  content: "󰘛";
}

.mdi-content-save-settings-outline:before {
  content: "󰬮";
}

.mdi-contrast:before {
  content: "󰆕";
}

.mdi-contrast-box:before {
  content: "󰆖";
}

.mdi-contrast-circle:before {
  content: "󰆗";
}

.mdi-controller-classic:before {
  content: "󰮂";
}

.mdi-controller-classic-outline:before {
  content: "󰮃";
}

.mdi-cookie:before {
  content: "󰆘";
}

.mdi-cookie-alert:before {
  content: "󱛐";
}

.mdi-cookie-alert-outline:before {
  content: "󱛑";
}

.mdi-cookie-check:before {
  content: "󱛒";
}

.mdi-cookie-check-outline:before {
  content: "󱛓";
}

.mdi-cookie-clock:before {
  content: "󱛤";
}

.mdi-cookie-clock-outline:before {
  content: "󱛥";
}

.mdi-cookie-cog:before {
  content: "󱛔";
}

.mdi-cookie-cog-outline:before {
  content: "󱛕";
}

.mdi-cookie-edit:before {
  content: "󱛦";
}

.mdi-cookie-edit-outline:before {
  content: "󱛧";
}

.mdi-cookie-lock:before {
  content: "󱛨";
}

.mdi-cookie-lock-outline:before {
  content: "󱛩";
}

.mdi-cookie-minus:before {
  content: "󱛚";
}

.mdi-cookie-minus-outline:before {
  content: "󱛛";
}

.mdi-cookie-off:before {
  content: "󱛪";
}

.mdi-cookie-off-outline:before {
  content: "󱛫";
}

.mdi-cookie-outline:before {
  content: "󱛞";
}

.mdi-cookie-plus:before {
  content: "󱛖";
}

.mdi-cookie-plus-outline:before {
  content: "󱛗";
}

.mdi-cookie-refresh:before {
  content: "󱛬";
}

.mdi-cookie-refresh-outline:before {
  content: "󱛭";
}

.mdi-cookie-remove:before {
  content: "󱛘";
}

.mdi-cookie-remove-outline:before {
  content: "󱛙";
}

.mdi-cookie-settings:before {
  content: "󱛜";
}

.mdi-cookie-settings-outline:before {
  content: "󱛝";
}

.mdi-coolant-temperature:before {
  content: "󰏈";
}

.mdi-copyright:before {
  content: "󰗦";
}

.mdi-cordova:before {
  content: "󰥘";
}

.mdi-corn:before {
  content: "󰞸";
}

.mdi-corn-off:before {
  content: "󱏯";
}

.mdi-cosine-wave:before {
  content: "󱑹";
}

.mdi-counter:before {
  content: "󰆙";
}

.mdi-cow:before {
  content: "󰆚";
}

.mdi-cpu-32-bit:before {
  content: "󰻟";
}

.mdi-cpu-64-bit:before {
  content: "󰻠";
}

.mdi-crane:before {
  content: "󰡢";
}

.mdi-creation:before {
  content: "󰙴";
}

.mdi-creative-commons:before {
  content: "󰵫";
}

.mdi-credit-card:before {
  content: "󰿯";
}

.mdi-credit-card-check:before {
  content: "󱏐";
}

.mdi-credit-card-check-outline:before {
  content: "󱏑";
}

.mdi-credit-card-clock:before {
  content: "󰻡";
}

.mdi-credit-card-clock-outline:before {
  content: "󰻢";
}

.mdi-credit-card-marker:before {
  content: "󰚨";
}

.mdi-credit-card-marker-outline:before {
  content: "󰶾";
}

.mdi-credit-card-minus:before {
  content: "󰾬";
}

.mdi-credit-card-minus-outline:before {
  content: "󰾭";
}

.mdi-credit-card-multiple:before {
  content: "󰿰";
}

.mdi-credit-card-multiple-outline:before {
  content: "󰆜";
}

.mdi-credit-card-off:before {
  content: "󰿱";
}

.mdi-credit-card-off-outline:before {
  content: "󰗤";
}

.mdi-credit-card-outline:before {
  content: "󰆛";
}

.mdi-credit-card-plus:before {
  content: "󰿲";
}

.mdi-credit-card-plus-outline:before {
  content: "󰙶";
}

.mdi-credit-card-refresh:before {
  content: "󱙅";
}

.mdi-credit-card-refresh-outline:before {
  content: "󱙆";
}

.mdi-credit-card-refund:before {
  content: "󰿳";
}

.mdi-credit-card-refund-outline:before {
  content: "󰪨";
}

.mdi-credit-card-remove:before {
  content: "󰾮";
}

.mdi-credit-card-remove-outline:before {
  content: "󰾯";
}

.mdi-credit-card-scan:before {
  content: "󰿴";
}

.mdi-credit-card-scan-outline:before {
  content: "󰆝";
}

.mdi-credit-card-search:before {
  content: "󱙇";
}

.mdi-credit-card-search-outline:before {
  content: "󱙈";
}

.mdi-credit-card-settings:before {
  content: "󰿵";
}

.mdi-credit-card-settings-outline:before {
  content: "󰣗";
}

.mdi-credit-card-sync:before {
  content: "󱙉";
}

.mdi-credit-card-sync-outline:before {
  content: "󱙊";
}

.mdi-credit-card-wireless:before {
  content: "󰠂";
}

.mdi-credit-card-wireless-off:before {
  content: "󰕺";
}

.mdi-credit-card-wireless-off-outline:before {
  content: "󰕻";
}

.mdi-credit-card-wireless-outline:before {
  content: "󰵬";
}

.mdi-cricket:before {
  content: "󰵭";
}

.mdi-crop:before {
  content: "󰆞";
}

.mdi-crop-free:before {
  content: "󰆟";
}

.mdi-crop-landscape:before {
  content: "󰆠";
}

.mdi-crop-portrait:before {
  content: "󰆡";
}

.mdi-crop-rotate:before {
  content: "󰚖";
}

.mdi-crop-square:before {
  content: "󰆢";
}

.mdi-crosshairs:before {
  content: "󰆣";
}

.mdi-crosshairs-gps:before {
  content: "󰆤";
}

.mdi-crosshairs-off:before {
  content: "󰽅";
}

.mdi-crosshairs-question:before {
  content: "󱄶";
}

.mdi-crown:before {
  content: "󰆥";
}

.mdi-crown-outline:before {
  content: "󱇐";
}

.mdi-cryengine:before {
  content: "󰥙";
}

.mdi-crystal-ball:before {
  content: "󰬯";
}

.mdi-cube:before {
  content: "󰆦";
}

.mdi-cube-off:before {
  content: "󱐜";
}

.mdi-cube-off-outline:before {
  content: "󱐝";
}

.mdi-cube-outline:before {
  content: "󰆧";
}

.mdi-cube-scan:before {
  content: "󰮄";
}

.mdi-cube-send:before {
  content: "󰆨";
}

.mdi-cube-unfolded:before {
  content: "󰆩";
}

.mdi-cup:before {
  content: "󰆪";
}

.mdi-cup-off:before {
  content: "󰗥";
}

.mdi-cup-off-outline:before {
  content: "󱍽";
}

.mdi-cup-outline:before {
  content: "󱌏";
}

.mdi-cup-water:before {
  content: "󰆫";
}

.mdi-cupboard:before {
  content: "󰽆";
}

.mdi-cupboard-outline:before {
  content: "󰽇";
}

.mdi-cupcake:before {
  content: "󰥚";
}

.mdi-curling:before {
  content: "󰡣";
}

.mdi-currency-bdt:before {
  content: "󰡤";
}

.mdi-currency-brl:before {
  content: "󰮅";
}

.mdi-currency-btc:before {
  content: "󰆬";
}

.mdi-currency-cny:before {
  content: "󰞺";
}

.mdi-currency-eth:before {
  content: "󰞻";
}

.mdi-currency-eur:before {
  content: "󰆭";
}

.mdi-currency-eur-off:before {
  content: "󱌕";
}

.mdi-currency-gbp:before {
  content: "󰆮";
}

.mdi-currency-ils:before {
  content: "󰱡";
}

.mdi-currency-inr:before {
  content: "󰆯";
}

.mdi-currency-jpy:before {
  content: "󰞼";
}

.mdi-currency-krw:before {
  content: "󰞽";
}

.mdi-currency-kzt:before {
  content: "󰡥";
}

.mdi-currency-mnt:before {
  content: "󱔒";
}

.mdi-currency-ngn:before {
  content: "󰆰";
}

.mdi-currency-php:before {
  content: "󰧦";
}

.mdi-currency-rial:before {
  content: "󰺜";
}

.mdi-currency-rub:before {
  content: "󰆱";
}

.mdi-currency-sign:before {
  content: "󰞾";
}

.mdi-currency-try:before {
  content: "󰆲";
}

.mdi-currency-twd:before {
  content: "󰞿";
}

.mdi-currency-usd:before {
  content: "󰇁";
}

.mdi-currency-usd-circle:before {
  content: "󱅫";
}

.mdi-currency-usd-circle-outline:before {
  content: "󰅸";
}

.mdi-currency-usd-off:before {
  content: "󰙺";
}

.mdi-current-ac:before {
  content: "󱒀";
}

.mdi-current-dc:before {
  content: "󰥜";
}

.mdi-cursor-default:before {
  content: "󰇀";
}

.mdi-cursor-default-click:before {
  content: "󰳽";
}

.mdi-cursor-default-click-outline:before {
  content: "󰳾";
}

.mdi-cursor-default-gesture:before {
  content: "󱄧";
}

.mdi-cursor-default-gesture-outline:before {
  content: "󱄨";
}

.mdi-cursor-default-outline:before {
  content: "󰆿";
}

.mdi-cursor-move:before {
  content: "󰆾";
}

.mdi-cursor-pointer:before {
  content: "󰆽";
}

.mdi-cursor-text:before {
  content: "󰗧";
}

.mdi-dance-ballroom:before {
  content: "󱗻";
}

.mdi-dance-pole:before {
  content: "󱕸";
}

.mdi-data-matrix:before {
  content: "󱔼";
}

.mdi-data-matrix-edit:before {
  content: "󱔽";
}

.mdi-data-matrix-minus:before {
  content: "󱔾";
}

.mdi-data-matrix-plus:before {
  content: "󱔿";
}

.mdi-data-matrix-remove:before {
  content: "󱕀";
}

.mdi-data-matrix-scan:before {
  content: "󱕁";
}

.mdi-database:before {
  content: "󰆼";
}

.mdi-database-alert:before {
  content: "󱘺";
}

.mdi-database-alert-outline:before {
  content: "󱘤";
}

.mdi-database-arrow-down:before {
  content: "󱘻";
}

.mdi-database-arrow-down-outline:before {
  content: "󱘥";
}

.mdi-database-arrow-left:before {
  content: "󱘼";
}

.mdi-database-arrow-left-outline:before {
  content: "󱘦";
}

.mdi-database-arrow-right:before {
  content: "󱘽";
}

.mdi-database-arrow-right-outline:before {
  content: "󱘧";
}

.mdi-database-arrow-up:before {
  content: "󱘾";
}

.mdi-database-arrow-up-outline:before {
  content: "󱘨";
}

.mdi-database-check:before {
  content: "󰪩";
}

.mdi-database-check-outline:before {
  content: "󱘩";
}

.mdi-database-clock:before {
  content: "󱘿";
}

.mdi-database-clock-outline:before {
  content: "󱘪";
}

.mdi-database-cog:before {
  content: "󱙋";
}

.mdi-database-cog-outline:before {
  content: "󱙌";
}

.mdi-database-edit:before {
  content: "󰮆";
}

.mdi-database-edit-outline:before {
  content: "󱘫";
}

.mdi-database-export:before {
  content: "󰥞";
}

.mdi-database-export-outline:before {
  content: "󱘬";
}

.mdi-database-import:before {
  content: "󰥝";
}

.mdi-database-import-outline:before {
  content: "󱘭";
}

.mdi-database-lock:before {
  content: "󰪪";
}

.mdi-database-lock-outline:before {
  content: "󱘮";
}

.mdi-database-marker:before {
  content: "󱋶";
}

.mdi-database-marker-outline:before {
  content: "󱘯";
}

.mdi-database-minus:before {
  content: "󰆻";
}

.mdi-database-minus-outline:before {
  content: "󱘰";
}

.mdi-database-off:before {
  content: "󱙀";
}

.mdi-database-off-outline:before {
  content: "󱘱";
}

.mdi-database-outline:before {
  content: "󱘲";
}

.mdi-database-plus:before {
  content: "󰆺";
}

.mdi-database-plus-outline:before {
  content: "󱘳";
}

.mdi-database-refresh:before {
  content: "󰗂";
}

.mdi-database-refresh-outline:before {
  content: "󱘴";
}

.mdi-database-remove:before {
  content: "󰴀";
}

.mdi-database-remove-outline:before {
  content: "󱘵";
}

.mdi-database-search:before {
  content: "󰡦";
}

.mdi-database-search-outline:before {
  content: "󱘶";
}

.mdi-database-settings:before {
  content: "󰴁";
}

.mdi-database-settings-outline:before {
  content: "󱘷";
}

.mdi-database-sync:before {
  content: "󰳿";
}

.mdi-database-sync-outline:before {
  content: "󱘸";
}

.mdi-death-star:before {
  content: "󰣘";
}

.mdi-death-star-variant:before {
  content: "󰣙";
}

.mdi-deathly-hallows:before {
  content: "󰮇";
}

.mdi-debian:before {
  content: "󰣚";
}

.mdi-debug-step-into:before {
  content: "󰆹";
}

.mdi-debug-step-out:before {
  content: "󰆸";
}

.mdi-debug-step-over:before {
  content: "󰆷";
}

.mdi-decagram:before {
  content: "󰝬";
}

.mdi-decagram-outline:before {
  content: "󰝭";
}

.mdi-decimal:before {
  content: "󱂡";
}

.mdi-decimal-comma:before {
  content: "󱂢";
}

.mdi-decimal-comma-decrease:before {
  content: "󱂣";
}

.mdi-decimal-comma-increase:before {
  content: "󱂤";
}

.mdi-decimal-decrease:before {
  content: "󰆶";
}

.mdi-decimal-increase:before {
  content: "󰆵";
}

.mdi-delete:before {
  content: "󰆴";
}

.mdi-delete-alert:before {
  content: "󱂥";
}

.mdi-delete-alert-outline:before {
  content: "󱂦";
}

.mdi-delete-circle:before {
  content: "󰚃";
}

.mdi-delete-circle-outline:before {
  content: "󰮈";
}

.mdi-delete-clock:before {
  content: "󱕖";
}

.mdi-delete-clock-outline:before {
  content: "󱕗";
}

.mdi-delete-empty:before {
  content: "󰛌";
}

.mdi-delete-empty-outline:before {
  content: "󰺝";
}

.mdi-delete-forever:before {
  content: "󰗨";
}

.mdi-delete-forever-outline:before {
  content: "󰮉";
}

.mdi-delete-off:before {
  content: "󱂧";
}

.mdi-delete-off-outline:before {
  content: "󱂨";
}

.mdi-delete-outline:before {
  content: "󰧧";
}

.mdi-delete-restore:before {
  content: "󰠙";
}

.mdi-delete-sweep:before {
  content: "󰗩";
}

.mdi-delete-sweep-outline:before {
  content: "󰱢";
}

.mdi-delete-variant:before {
  content: "󰆳";
}

.mdi-delta:before {
  content: "󰇂";
}

.mdi-desk:before {
  content: "󱈹";
}

.mdi-desk-lamp:before {
  content: "󰥟";
}

.mdi-deskphone:before {
  content: "󰇃";
}

.mdi-desktop-classic:before {
  content: "󰟀";
}

.mdi-desktop-mac:before {
  content: "󰇄";
}

.mdi-desktop-mac-dashboard:before {
  content: "󰧨";
}

.mdi-desktop-tower:before {
  content: "󰇅";
}

.mdi-desktop-tower-monitor:before {
  content: "󰪫";
}

.mdi-details:before {
  content: "󰇆";
}

.mdi-dev-to:before {
  content: "󰵮";
}

.mdi-developer-board:before {
  content: "󰚗";
}

.mdi-deviantart:before {
  content: "󰇇";
}

.mdi-devices:before {
  content: "󰾰";
}

.mdi-diabetes:before {
  content: "󱄦";
}

.mdi-dialpad:before {
  content: "󰘜";
}

.mdi-diameter:before {
  content: "󰱣";
}

.mdi-diameter-outline:before {
  content: "󰱤";
}

.mdi-diameter-variant:before {
  content: "󰱥";
}

.mdi-diamond:before {
  content: "󰮊";
}

.mdi-diamond-outline:before {
  content: "󰮋";
}

.mdi-diamond-stone:before {
  content: "󰇈";
}

.mdi-dice-1:before {
  content: "󰇊";
}

.mdi-dice-1-outline:before {
  content: "󱅊";
}

.mdi-dice-2:before {
  content: "󰇋";
}

.mdi-dice-2-outline:before {
  content: "󱅋";
}

.mdi-dice-3:before {
  content: "󰇌";
}

.mdi-dice-3-outline:before {
  content: "󱅌";
}

.mdi-dice-4:before {
  content: "󰇍";
}

.mdi-dice-4-outline:before {
  content: "󱅍";
}

.mdi-dice-5:before {
  content: "󰇎";
}

.mdi-dice-5-outline:before {
  content: "󱅎";
}

.mdi-dice-6:before {
  content: "󰇏";
}

.mdi-dice-6-outline:before {
  content: "󱅏";
}

.mdi-dice-d10:before {
  content: "󱅓";
}

.mdi-dice-d10-outline:before {
  content: "󰝯";
}

.mdi-dice-d12:before {
  content: "󱅔";
}

.mdi-dice-d12-outline:before {
  content: "󰡧";
}

.mdi-dice-d20:before {
  content: "󱅕";
}

.mdi-dice-d20-outline:before {
  content: "󰗪";
}

.mdi-dice-d4:before {
  content: "󱅐";
}

.mdi-dice-d4-outline:before {
  content: "󰗫";
}

.mdi-dice-d6:before {
  content: "󱅑";
}

.mdi-dice-d6-outline:before {
  content: "󰗭";
}

.mdi-dice-d8:before {
  content: "󱅒";
}

.mdi-dice-d8-outline:before {
  content: "󰗬";
}

.mdi-dice-multiple:before {
  content: "󰝮";
}

.mdi-dice-multiple-outline:before {
  content: "󱅖";
}

.mdi-digital-ocean:before {
  content: "󱈷";
}

.mdi-dip-switch:before {
  content: "󰟁";
}

.mdi-directions:before {
  content: "󰇐";
}

.mdi-directions-fork:before {
  content: "󰙁";
}

.mdi-disc:before {
  content: "󰗮";
}

.mdi-disc-alert:before {
  content: "󰇑";
}

.mdi-disc-player:before {
  content: "󰥠";
}

.mdi-discord:before {
  content: "󰙯";
}

.mdi-dishwasher:before {
  content: "󰪬";
}

.mdi-dishwasher-alert:before {
  content: "󱆸";
}

.mdi-dishwasher-off:before {
  content: "󱆹";
}

.mdi-disqus:before {
  content: "󰇒";
}

.mdi-distribute-horizontal-center:before {
  content: "󱇉";
}

.mdi-distribute-horizontal-left:before {
  content: "󱇈";
}

.mdi-distribute-horizontal-right:before {
  content: "󱇊";
}

.mdi-distribute-vertical-bottom:before {
  content: "󱇋";
}

.mdi-distribute-vertical-center:before {
  content: "󱇌";
}

.mdi-distribute-vertical-top:before {
  content: "󱇍";
}

.mdi-diving-flippers:before {
  content: "󰶿";
}

.mdi-diving-helmet:before {
  content: "󰷀";
}

.mdi-diving-scuba:before {
  content: "󰷁";
}

.mdi-diving-scuba-flag:before {
  content: "󰷂";
}

.mdi-diving-scuba-tank:before {
  content: "󰷃";
}

.mdi-diving-scuba-tank-multiple:before {
  content: "󰷄";
}

.mdi-diving-snorkel:before {
  content: "󰷅";
}

.mdi-division:before {
  content: "󰇔";
}

.mdi-division-box:before {
  content: "󰇕";
}

.mdi-dlna:before {
  content: "󰩁";
}

.mdi-dna:before {
  content: "󰚄";
}

.mdi-dns:before {
  content: "󰇖";
}

.mdi-dns-outline:before {
  content: "󰮌";
}

.mdi-do-not-disturb:before {
  content: "󰚘";
}

.mdi-do-not-disturb-off:before {
  content: "󰚙";
}

.mdi-dock-bottom:before {
  content: "󱂩";
}

.mdi-dock-left:before {
  content: "󱂪";
}

.mdi-dock-right:before {
  content: "󱂫";
}

.mdi-dock-top:before {
  content: "󱔓";
}

.mdi-dock-window:before {
  content: "󱂬";
}

.mdi-docker:before {
  content: "󰡨";
}

.mdi-doctor:before {
  content: "󰩂";
}

.mdi-dog:before {
  content: "󰩃";
}

.mdi-dog-service:before {
  content: "󰪭";
}

.mdi-dog-side:before {
  content: "󰩄";
}

.mdi-dog-side-off:before {
  content: "󱛮";
}

.mdi-dolby:before {
  content: "󰚳";
}

.mdi-dolly:before {
  content: "󰺞";
}

.mdi-domain:before {
  content: "󰇗";
}

.mdi-domain-off:before {
  content: "󰵯";
}

.mdi-domain-plus:before {
  content: "󱂭";
}

.mdi-domain-remove:before {
  content: "󱂮";
}

.mdi-dome-light:before {
  content: "󱐞";
}

.mdi-domino-mask:before {
  content: "󱀣";
}

.mdi-donkey:before {
  content: "󰟂";
}

.mdi-door:before {
  content: "󰠚";
}

.mdi-door-closed:before {
  content: "󰠛";
}

.mdi-door-closed-lock:before {
  content: "󱂯";
}

.mdi-door-open:before {
  content: "󰠜";
}

.mdi-doorbell:before {
  content: "󱋦";
}

.mdi-doorbell-video:before {
  content: "󰡩";
}

.mdi-dot-net:before {
  content: "󰪮";
}

.mdi-dots-grid:before {
  content: "󱗼";
}

.mdi-dots-hexagon:before {
  content: "󱗿";
}

.mdi-dots-horizontal:before {
  content: "󰇘";
}

.mdi-dots-horizontal-circle:before {
  content: "󰟃";
}

.mdi-dots-horizontal-circle-outline:before {
  content: "󰮍";
}

.mdi-dots-square:before {
  content: "󱗽";
}

.mdi-dots-triangle:before {
  content: "󱗾";
}

.mdi-dots-vertical:before {
  content: "󰇙";
}

.mdi-dots-vertical-circle:before {
  content: "󰟄";
}

.mdi-dots-vertical-circle-outline:before {
  content: "󰮎";
}

.mdi-douban:before {
  content: "󰚚";
}

.mdi-download:before {
  content: "󰇚";
}

.mdi-download-box:before {
  content: "󱑢";
}

.mdi-download-box-outline:before {
  content: "󱑣";
}

.mdi-download-circle:before {
  content: "󱑤";
}

.mdi-download-circle-outline:before {
  content: "󱑥";
}

.mdi-download-lock:before {
  content: "󱌠";
}

.mdi-download-lock-outline:before {
  content: "󱌡";
}

.mdi-download-multiple:before {
  content: "󰧩";
}

.mdi-download-network:before {
  content: "󰛴";
}

.mdi-download-network-outline:before {
  content: "󰱦";
}

.mdi-download-off:before {
  content: "󱂰";
}

.mdi-download-off-outline:before {
  content: "󱂱";
}

.mdi-download-outline:before {
  content: "󰮏";
}

.mdi-drag:before {
  content: "󰇛";
}

.mdi-drag-horizontal:before {
  content: "󰇜";
}

.mdi-drag-horizontal-variant:before {
  content: "󱋰";
}

.mdi-drag-variant:before {
  content: "󰮐";
}

.mdi-drag-vertical:before {
  content: "󰇝";
}

.mdi-drag-vertical-variant:before {
  content: "󱋱";
}

.mdi-drama-masks:before {
  content: "󰴂";
}

.mdi-draw:before {
  content: "󰽉";
}

.mdi-drawing:before {
  content: "󰇞";
}

.mdi-drawing-box:before {
  content: "󰇟";
}

.mdi-dresser:before {
  content: "󰽊";
}

.mdi-dresser-outline:before {
  content: "󰽋";
}

.mdi-drone:before {
  content: "󰇢";
}

.mdi-dropbox:before {
  content: "󰇣";
}

.mdi-drupal:before {
  content: "󰇤";
}

.mdi-duck:before {
  content: "󰇥";
}

.mdi-dumbbell:before {
  content: "󰇦";
}

.mdi-dump-truck:before {
  content: "󰱧";
}

.mdi-ear-hearing:before {
  content: "󰟅";
}

.mdi-ear-hearing-off:before {
  content: "󰩅";
}

.mdi-earth:before {
  content: "󰇧";
}

.mdi-earth-arrow-right:before {
  content: "󱌑";
}

.mdi-earth-box:before {
  content: "󰛍";
}

.mdi-earth-box-minus:before {
  content: "󱐇";
}

.mdi-earth-box-off:before {
  content: "󰛎";
}

.mdi-earth-box-plus:before {
  content: "󱐆";
}

.mdi-earth-box-remove:before {
  content: "󱐈";
}

.mdi-earth-minus:before {
  content: "󱐄";
}

.mdi-earth-off:before {
  content: "󰇨";
}

.mdi-earth-plus:before {
  content: "󱐃";
}

.mdi-earth-remove:before {
  content: "󱐅";
}

.mdi-egg:before {
  content: "󰪯";
}

.mdi-egg-easter:before {
  content: "󰪰";
}

.mdi-egg-off:before {
  content: "󱏰";
}

.mdi-egg-off-outline:before {
  content: "󱏱";
}

.mdi-egg-outline:before {
  content: "󱏲";
}

.mdi-eiffel-tower:before {
  content: "󱕫";
}

.mdi-eight-track:before {
  content: "󰧪";
}

.mdi-eject:before {
  content: "󰇪";
}

.mdi-eject-outline:before {
  content: "󰮑";
}

.mdi-electric-switch:before {
  content: "󰺟";
}

.mdi-electric-switch-closed:before {
  content: "󱃙";
}

.mdi-electron-framework:before {
  content: "󱀤";
}

.mdi-elephant:before {
  content: "󰟆";
}

.mdi-elevation-decline:before {
  content: "󰇫";
}

.mdi-elevation-rise:before {
  content: "󰇬";
}

.mdi-elevator:before {
  content: "󰇭";
}

.mdi-elevator-down:before {
  content: "󱋂";
}

.mdi-elevator-passenger:before {
  content: "󱎁";
}

.mdi-elevator-up:before {
  content: "󱋁";
}

.mdi-ellipse:before {
  content: "󰺠";
}

.mdi-ellipse-outline:before {
  content: "󰺡";
}

.mdi-email:before {
  content: "󰇮";
}

.mdi-email-alert:before {
  content: "󰛏";
}

.mdi-email-alert-outline:before {
  content: "󰵂";
}

.mdi-email-box:before {
  content: "󰴃";
}

.mdi-email-check:before {
  content: "󰪱";
}

.mdi-email-check-outline:before {
  content: "󰪲";
}

.mdi-email-edit:before {
  content: "󰻣";
}

.mdi-email-edit-outline:before {
  content: "󰻤";
}

.mdi-email-lock:before {
  content: "󰇱";
}

.mdi-email-mark-as-unread:before {
  content: "󰮒";
}

.mdi-email-minus:before {
  content: "󰻥";
}

.mdi-email-minus-outline:before {
  content: "󰻦";
}

.mdi-email-multiple:before {
  content: "󰻧";
}

.mdi-email-multiple-outline:before {
  content: "󰻨";
}

.mdi-email-newsletter:before {
  content: "󰾱";
}

.mdi-email-off:before {
  content: "󱏣";
}

.mdi-email-off-outline:before {
  content: "󱏤";
}

.mdi-email-open:before {
  content: "󰇯";
}

.mdi-email-open-multiple:before {
  content: "󰻩";
}

.mdi-email-open-multiple-outline:before {
  content: "󰻪";
}

.mdi-email-open-outline:before {
  content: "󰗯";
}

.mdi-email-outline:before {
  content: "󰇰";
}

.mdi-email-plus:before {
  content: "󰧫";
}

.mdi-email-plus-outline:before {
  content: "󰧬";
}

.mdi-email-receive:before {
  content: "󱃚";
}

.mdi-email-receive-outline:before {
  content: "󱃛";
}

.mdi-email-remove:before {
  content: "󱙡";
}

.mdi-email-remove-outline:before {
  content: "󱙢";
}

.mdi-email-search:before {
  content: "󰥡";
}

.mdi-email-search-outline:before {
  content: "󰥢";
}

.mdi-email-send:before {
  content: "󱃜";
}

.mdi-email-send-outline:before {
  content: "󱃝";
}

.mdi-email-sync:before {
  content: "󱋇";
}

.mdi-email-sync-outline:before {
  content: "󱋈";
}

.mdi-email-variant:before {
  content: "󰗰";
}

.mdi-ember:before {
  content: "󰬰";
}

.mdi-emby:before {
  content: "󰚴";
}

.mdi-emoticon:before {
  content: "󰱨";
}

.mdi-emoticon-angry:before {
  content: "󰱩";
}

.mdi-emoticon-angry-outline:before {
  content: "󰱪";
}

.mdi-emoticon-confused:before {
  content: "󱃞";
}

.mdi-emoticon-confused-outline:before {
  content: "󱃟";
}

.mdi-emoticon-cool:before {
  content: "󰱫";
}

.mdi-emoticon-cool-outline:before {
  content: "󰇳";
}

.mdi-emoticon-cry:before {
  content: "󰱬";
}

.mdi-emoticon-cry-outline:before {
  content: "󰱭";
}

.mdi-emoticon-dead:before {
  content: "󰱮";
}

.mdi-emoticon-dead-outline:before {
  content: "󰚛";
}

.mdi-emoticon-devil:before {
  content: "󰱯";
}

.mdi-emoticon-devil-outline:before {
  content: "󰇴";
}

.mdi-emoticon-excited:before {
  content: "󰱰";
}

.mdi-emoticon-excited-outline:before {
  content: "󰚜";
}

.mdi-emoticon-frown:before {
  content: "󰽌";
}

.mdi-emoticon-frown-outline:before {
  content: "󰽍";
}

.mdi-emoticon-happy:before {
  content: "󰱱";
}

.mdi-emoticon-happy-outline:before {
  content: "󰇵";
}

.mdi-emoticon-kiss:before {
  content: "󰱲";
}

.mdi-emoticon-kiss-outline:before {
  content: "󰱳";
}

.mdi-emoticon-lol:before {
  content: "󱈔";
}

.mdi-emoticon-lol-outline:before {
  content: "󱈕";
}

.mdi-emoticon-neutral:before {
  content: "󰱴";
}

.mdi-emoticon-neutral-outline:before {
  content: "󰇶";
}

.mdi-emoticon-outline:before {
  content: "󰇲";
}

.mdi-emoticon-poop:before {
  content: "󰇷";
}

.mdi-emoticon-poop-outline:before {
  content: "󰱵";
}

.mdi-emoticon-sad:before {
  content: "󰱶";
}

.mdi-emoticon-sad-outline:before {
  content: "󰇸";
}

.mdi-emoticon-sick:before {
  content: "󱕼";
}

.mdi-emoticon-sick-outline:before {
  content: "󱕽";
}

.mdi-emoticon-tongue:before {
  content: "󰇹";
}

.mdi-emoticon-tongue-outline:before {
  content: "󰱷";
}

.mdi-emoticon-wink:before {
  content: "󰱸";
}

.mdi-emoticon-wink-outline:before {
  content: "󰱹";
}

.mdi-engine:before {
  content: "󰇺";
}

.mdi-engine-off:before {
  content: "󰩆";
}

.mdi-engine-off-outline:before {
  content: "󰩇";
}

.mdi-engine-outline:before {
  content: "󰇻";
}

.mdi-epsilon:before {
  content: "󱃠";
}

.mdi-equal:before {
  content: "󰇼";
}

.mdi-equal-box:before {
  content: "󰇽";
}

.mdi-equalizer:before {
  content: "󰺢";
}

.mdi-equalizer-outline:before {
  content: "󰺣";
}

.mdi-eraser:before {
  content: "󰇾";
}

.mdi-eraser-variant:before {
  content: "󰙂";
}

.mdi-escalator:before {
  content: "󰇿";
}

.mdi-escalator-box:before {
  content: "󱎙";
}

.mdi-escalator-down:before {
  content: "󱋀";
}

.mdi-escalator-up:before {
  content: "󱊿";
}

.mdi-eslint:before {
  content: "󰱺";
}

.mdi-et:before {
  content: "󰪳";
}

.mdi-ethereum:before {
  content: "󰡪";
}

.mdi-ethernet:before {
  content: "󰈀";
}

.mdi-ethernet-cable:before {
  content: "󰈁";
}

.mdi-ethernet-cable-off:before {
  content: "󰈂";
}

.mdi-ev-plug-ccs1:before {
  content: "󱔙";
}

.mdi-ev-plug-ccs2:before {
  content: "󱔚";
}

.mdi-ev-plug-chademo:before {
  content: "󱔛";
}

.mdi-ev-plug-tesla:before {
  content: "󱔜";
}

.mdi-ev-plug-type1:before {
  content: "󱔝";
}

.mdi-ev-plug-type2:before {
  content: "󱔞";
}

.mdi-ev-station:before {
  content: "󰗱";
}

.mdi-evernote:before {
  content: "󰈄";
}

.mdi-excavator:before {
  content: "󱀥";
}

.mdi-exclamation:before {
  content: "󰈅";
}

.mdi-exclamation-thick:before {
  content: "󱈸";
}

.mdi-exit-run:before {
  content: "󰩈";
}

.mdi-exit-to-app:before {
  content: "󰈆";
}

.mdi-expand-all:before {
  content: "󰪴";
}

.mdi-expand-all-outline:before {
  content: "󰪵";
}

.mdi-expansion-card:before {
  content: "󰢮";
}

.mdi-expansion-card-variant:before {
  content: "󰾲";
}

.mdi-exponent:before {
  content: "󰥣";
}

.mdi-exponent-box:before {
  content: "󰥤";
}

.mdi-export:before {
  content: "󰈇";
}

.mdi-export-variant:before {
  content: "󰮓";
}

.mdi-eye:before {
  content: "󰈈";
}

.mdi-eye-check:before {
  content: "󰴄";
}

.mdi-eye-check-outline:before {
  content: "󰴅";
}

.mdi-eye-circle:before {
  content: "󰮔";
}

.mdi-eye-circle-outline:before {
  content: "󰮕";
}

.mdi-eye-minus:before {
  content: "󱀦";
}

.mdi-eye-minus-outline:before {
  content: "󱀧";
}

.mdi-eye-off:before {
  content: "󰈉";
}

.mdi-eye-off-outline:before {
  content: "󰛑";
}

.mdi-eye-outline:before {
  content: "󰛐";
}

.mdi-eye-plus:before {
  content: "󰡫";
}

.mdi-eye-plus-outline:before {
  content: "󰡬";
}

.mdi-eye-remove:before {
  content: "󱗣";
}

.mdi-eye-remove-outline:before {
  content: "󱗤";
}

.mdi-eye-settings:before {
  content: "󰡭";
}

.mdi-eye-settings-outline:before {
  content: "󰡮";
}

.mdi-eyedropper:before {
  content: "󰈊";
}

.mdi-eyedropper-minus:before {
  content: "󱏝";
}

.mdi-eyedropper-off:before {
  content: "󱏟";
}

.mdi-eyedropper-plus:before {
  content: "󱏜";
}

.mdi-eyedropper-remove:before {
  content: "󱏞";
}

.mdi-eyedropper-variant:before {
  content: "󰈋";
}

.mdi-face:before {
  content: "󰙃";
}

.mdi-face-agent:before {
  content: "󰵰";
}

.mdi-face-mask:before {
  content: "󱖆";
}

.mdi-face-mask-outline:before {
  content: "󱖇";
}

.mdi-face-outline:before {
  content: "󰮖";
}

.mdi-face-profile:before {
  content: "󰙄";
}

.mdi-face-profile-woman:before {
  content: "󱁶";
}

.mdi-face-recognition:before {
  content: "󰱻";
}

.mdi-face-shimmer:before {
  content: "󱗌";
}

.mdi-face-shimmer-outline:before {
  content: "󱗍";
}

.mdi-face-woman:before {
  content: "󱁷";
}

.mdi-face-woman-outline:before {
  content: "󱁸";
}

.mdi-face-woman-shimmer:before {
  content: "󱗎";
}

.mdi-face-woman-shimmer-outline:before {
  content: "󱗏";
}

.mdi-facebook:before {
  content: "󰈌";
}

.mdi-facebook-gaming:before {
  content: "󰟝";
}

.mdi-facebook-messenger:before {
  content: "󰈎";
}

.mdi-facebook-workplace:before {
  content: "󰬱";
}

.mdi-factory:before {
  content: "󰈏";
}

.mdi-family-tree:before {
  content: "󱘎";
}

.mdi-fan:before {
  content: "󰈐";
}

.mdi-fan-alert:before {
  content: "󱑬";
}

.mdi-fan-auto:before {
  content: "󱜝";
}

.mdi-fan-chevron-down:before {
  content: "󱑭";
}

.mdi-fan-chevron-up:before {
  content: "󱑮";
}

.mdi-fan-minus:before {
  content: "󱑰";
}

.mdi-fan-off:before {
  content: "󰠝";
}

.mdi-fan-plus:before {
  content: "󱑯";
}

.mdi-fan-remove:before {
  content: "󱑱";
}

.mdi-fan-speed-1:before {
  content: "󱑲";
}

.mdi-fan-speed-2:before {
  content: "󱑳";
}

.mdi-fan-speed-3:before {
  content: "󱑴";
}

.mdi-fast-forward:before {
  content: "󰈑";
}

.mdi-fast-forward-10:before {
  content: "󰵱";
}

.mdi-fast-forward-30:before {
  content: "󰴆";
}

.mdi-fast-forward-5:before {
  content: "󱇸";
}

.mdi-fast-forward-60:before {
  content: "󱘋";
}

.mdi-fast-forward-outline:before {
  content: "󰛒";
}

.mdi-fax:before {
  content: "󰈒";
}

.mdi-feather:before {
  content: "󰛓";
}

.mdi-feature-search:before {
  content: "󰩉";
}

.mdi-feature-search-outline:before {
  content: "󰩊";
}

.mdi-fedora:before {
  content: "󰣛";
}

.mdi-fencing:before {
  content: "󱓁";
}

.mdi-ferris-wheel:before {
  content: "󰺤";
}

.mdi-ferry:before {
  content: "󰈓";
}

.mdi-file:before {
  content: "󰈔";
}

.mdi-file-account:before {
  content: "󰜻";
}

.mdi-file-account-outline:before {
  content: "󱀨";
}

.mdi-file-alert:before {
  content: "󰩋";
}

.mdi-file-alert-outline:before {
  content: "󰩌";
}

.mdi-file-cabinet:before {
  content: "󰪶";
}

.mdi-file-cad:before {
  content: "󰻫";
}

.mdi-file-cad-box:before {
  content: "󰻬";
}

.mdi-file-cancel:before {
  content: "󰷆";
}

.mdi-file-cancel-outline:before {
  content: "󰷇";
}

.mdi-file-certificate:before {
  content: "󱆆";
}

.mdi-file-certificate-outline:before {
  content: "󱆇";
}

.mdi-file-chart:before {
  content: "󰈕";
}

.mdi-file-chart-outline:before {
  content: "󱀩";
}

.mdi-file-check:before {
  content: "󰈖";
}

.mdi-file-check-outline:before {
  content: "󰸩";
}

.mdi-file-clock:before {
  content: "󱋡";
}

.mdi-file-clock-outline:before {
  content: "󱋢";
}

.mdi-file-cloud:before {
  content: "󰈗";
}

.mdi-file-cloud-outline:before {
  content: "󱀪";
}

.mdi-file-code:before {
  content: "󰈮";
}

.mdi-file-code-outline:before {
  content: "󱀫";
}

.mdi-file-cog:before {
  content: "󱁻";
}

.mdi-file-cog-outline:before {
  content: "󱁼";
}

.mdi-file-compare:before {
  content: "󰢪";
}

.mdi-file-delimited:before {
  content: "󰈘";
}

.mdi-file-delimited-outline:before {
  content: "󰺥";
}

.mdi-file-document:before {
  content: "󰈙";
}

.mdi-file-document-edit:before {
  content: "󰷈";
}

.mdi-file-document-edit-outline:before {
  content: "󰷉";
}

.mdi-file-document-multiple:before {
  content: "󱔗";
}

.mdi-file-document-multiple-outline:before {
  content: "󱔘";
}

.mdi-file-document-outline:before {
  content: "󰧮";
}

.mdi-file-download:before {
  content: "󰥥";
}

.mdi-file-download-outline:before {
  content: "󰥦";
}

.mdi-file-edit:before {
  content: "󱇧";
}

.mdi-file-edit-outline:before {
  content: "󱇨";
}

.mdi-file-excel:before {
  content: "󰈛";
}

.mdi-file-excel-box:before {
  content: "󰈜";
}

.mdi-file-excel-box-outline:before {
  content: "󱀬";
}

.mdi-file-excel-outline:before {
  content: "󱀭";
}

.mdi-file-export:before {
  content: "󰈝";
}

.mdi-file-export-outline:before {
  content: "󱀮";
}

.mdi-file-eye:before {
  content: "󰷊";
}

.mdi-file-eye-outline:before {
  content: "󰷋";
}

.mdi-file-find:before {
  content: "󰈞";
}

.mdi-file-find-outline:before {
  content: "󰮗";
}

.mdi-file-hidden:before {
  content: "󰘓";
}

.mdi-file-image:before {
  content: "󰈟";
}

.mdi-file-image-outline:before {
  content: "󰺰";
}

.mdi-file-import:before {
  content: "󰈠";
}

.mdi-file-import-outline:before {
  content: "󱀯";
}

.mdi-file-key:before {
  content: "󱆄";
}

.mdi-file-key-outline:before {
  content: "󱆅";
}

.mdi-file-link:before {
  content: "󱅷";
}

.mdi-file-link-outline:before {
  content: "󱅸";
}

.mdi-file-lock:before {
  content: "󰈡";
}

.mdi-file-lock-outline:before {
  content: "󱀰";
}

.mdi-file-move:before {
  content: "󰪹";
}

.mdi-file-move-outline:before {
  content: "󱀱";
}

.mdi-file-multiple:before {
  content: "󰈢";
}

.mdi-file-multiple-outline:before {
  content: "󱀲";
}

.mdi-file-music:before {
  content: "󰈣";
}

.mdi-file-music-outline:before {
  content: "󰸪";
}

.mdi-file-outline:before {
  content: "󰈤";
}

.mdi-file-pdf:before {
  content: "󰈥";
}

.mdi-file-pdf-box:before {
  content: "󰈦";
}

.mdi-file-pdf-box-outline:before {
  content: "󰾳";
}

.mdi-file-pdf-outline:before {
  content: "󰸭";
}

.mdi-file-percent:before {
  content: "󰠞";
}

.mdi-file-percent-outline:before {
  content: "󱀳";
}

.mdi-file-phone:before {
  content: "󱅹";
}

.mdi-file-phone-outline:before {
  content: "󱅺";
}

.mdi-file-plus:before {
  content: "󰝒";
}

.mdi-file-plus-outline:before {
  content: "󰻭";
}

.mdi-file-powerpoint:before {
  content: "󰈧";
}

.mdi-file-powerpoint-box:before {
  content: "󰈨";
}

.mdi-file-powerpoint-box-outline:before {
  content: "󱀴";
}

.mdi-file-powerpoint-outline:before {
  content: "󱀵";
}

.mdi-file-presentation-box:before {
  content: "󰈩";
}

.mdi-file-question:before {
  content: "󰡯";
}

.mdi-file-question-outline:before {
  content: "󱀶";
}

.mdi-file-refresh:before {
  content: "󰤘";
}

.mdi-file-refresh-outline:before {
  content: "󰕁";
}

.mdi-file-remove:before {
  content: "󰮘";
}

.mdi-file-remove-outline:before {
  content: "󱀷";
}

.mdi-file-replace:before {
  content: "󰬲";
}

.mdi-file-replace-outline:before {
  content: "󰬳";
}

.mdi-file-restore:before {
  content: "󰙰";
}

.mdi-file-restore-outline:before {
  content: "󱀸";
}

.mdi-file-search:before {
  content: "󰱼";
}

.mdi-file-search-outline:before {
  content: "󰱽";
}

.mdi-file-send:before {
  content: "󰈪";
}

.mdi-file-send-outline:before {
  content: "󱀹";
}

.mdi-file-settings:before {
  content: "󱁹";
}

.mdi-file-settings-outline:before {
  content: "󱁺";
}

.mdi-file-star:before {
  content: "󱀺";
}

.mdi-file-star-outline:before {
  content: "󱀻";
}

.mdi-file-swap:before {
  content: "󰾴";
}

.mdi-file-swap-outline:before {
  content: "󰾵";
}

.mdi-file-sync:before {
  content: "󱈖";
}

.mdi-file-sync-outline:before {
  content: "󱈗";
}

.mdi-file-table:before {
  content: "󰱾";
}

.mdi-file-table-box:before {
  content: "󱃡";
}

.mdi-file-table-box-multiple:before {
  content: "󱃢";
}

.mdi-file-table-box-multiple-outline:before {
  content: "󱃣";
}

.mdi-file-table-box-outline:before {
  content: "󱃤";
}

.mdi-file-table-outline:before {
  content: "󰱿";
}

.mdi-file-tree:before {
  content: "󰙅";
}

.mdi-file-tree-outline:before {
  content: "󱏒";
}

.mdi-file-undo:before {
  content: "󰣜";
}

.mdi-file-undo-outline:before {
  content: "󱀼";
}

.mdi-file-upload:before {
  content: "󰩍";
}

.mdi-file-upload-outline:before {
  content: "󰩎";
}

.mdi-file-video:before {
  content: "󰈫";
}

.mdi-file-video-outline:before {
  content: "󰸬";
}

.mdi-file-word:before {
  content: "󰈬";
}

.mdi-file-word-box:before {
  content: "󰈭";
}

.mdi-file-word-box-outline:before {
  content: "󱀽";
}

.mdi-file-word-outline:before {
  content: "󱀾";
}

.mdi-film:before {
  content: "󰈯";
}

.mdi-filmstrip:before {
  content: "󰈰";
}

.mdi-filmstrip-box:before {
  content: "󰌲";
}

.mdi-filmstrip-box-multiple:before {
  content: "󰴘";
}

.mdi-filmstrip-off:before {
  content: "󰈱";
}

.mdi-filter:before {
  content: "󰈲";
}

.mdi-filter-menu:before {
  content: "󱃥";
}

.mdi-filter-menu-outline:before {
  content: "󱃦";
}

.mdi-filter-minus:before {
  content: "󰻮";
}

.mdi-filter-minus-outline:before {
  content: "󰻯";
}

.mdi-filter-off:before {
  content: "󱓯";
}

.mdi-filter-off-outline:before {
  content: "󱓰";
}

.mdi-filter-outline:before {
  content: "󰈳";
}

.mdi-filter-plus:before {
  content: "󰻰";
}

.mdi-filter-plus-outline:before {
  content: "󰻱";
}

.mdi-filter-remove:before {
  content: "󰈴";
}

.mdi-filter-remove-outline:before {
  content: "󰈵";
}

.mdi-filter-variant:before {
  content: "󰈶";
}

.mdi-filter-variant-minus:before {
  content: "󱄒";
}

.mdi-filter-variant-plus:before {
  content: "󱄓";
}

.mdi-filter-variant-remove:before {
  content: "󱀿";
}

.mdi-finance:before {
  content: "󰠟";
}

.mdi-find-replace:before {
  content: "󰛔";
}

.mdi-fingerprint:before {
  content: "󰈷";
}

.mdi-fingerprint-off:before {
  content: "󰺱";
}

.mdi-fire:before {
  content: "󰈸";
}

.mdi-fire-alert:before {
  content: "󱗗";
}

.mdi-fire-extinguisher:before {
  content: "󰻲";
}

.mdi-fire-hydrant:before {
  content: "󱄷";
}

.mdi-fire-hydrant-alert:before {
  content: "󱄸";
}

.mdi-fire-hydrant-off:before {
  content: "󱄹";
}

.mdi-fire-off:before {
  content: "󱜢";
}

.mdi-fire-truck:before {
  content: "󰢫";
}

.mdi-firebase:before {
  content: "󰥧";
}

.mdi-firefox:before {
  content: "󰈹";
}

.mdi-fireplace:before {
  content: "󰸮";
}

.mdi-fireplace-off:before {
  content: "󰸯";
}

.mdi-firework:before {
  content: "󰸰";
}

.mdi-firework-off:before {
  content: "󱜣";
}

.mdi-fish:before {
  content: "󰈺";
}

.mdi-fish-off:before {
  content: "󱏳";
}

.mdi-fishbowl:before {
  content: "󰻳";
}

.mdi-fishbowl-outline:before {
  content: "󰻴";
}

.mdi-fit-to-page:before {
  content: "󰻵";
}

.mdi-fit-to-page-outline:before {
  content: "󰻶";
}

.mdi-flag:before {
  content: "󰈻";
}

.mdi-flag-checkered:before {
  content: "󰈼";
}

.mdi-flag-minus:before {
  content: "󰮙";
}

.mdi-flag-minus-outline:before {
  content: "󱂲";
}

.mdi-flag-outline:before {
  content: "󰈽";
}

.mdi-flag-plus:before {
  content: "󰮚";
}

.mdi-flag-plus-outline:before {
  content: "󱂳";
}

.mdi-flag-remove:before {
  content: "󰮛";
}

.mdi-flag-remove-outline:before {
  content: "󱂴";
}

.mdi-flag-triangle:before {
  content: "󰈿";
}

.mdi-flag-variant:before {
  content: "󰉀";
}

.mdi-flag-variant-outline:before {
  content: "󰈾";
}

.mdi-flare:before {
  content: "󰵲";
}

.mdi-flash:before {
  content: "󰉁";
}

.mdi-flash-alert:before {
  content: "󰻷";
}

.mdi-flash-alert-outline:before {
  content: "󰻸";
}

.mdi-flash-auto:before {
  content: "󰉂";
}

.mdi-flash-circle:before {
  content: "󰠠";
}

.mdi-flash-off:before {
  content: "󰉃";
}

.mdi-flash-outline:before {
  content: "󰛕";
}

.mdi-flash-red-eye:before {
  content: "󰙻";
}

.mdi-flashlight:before {
  content: "󰉄";
}

.mdi-flashlight-off:before {
  content: "󰉅";
}

.mdi-flask:before {
  content: "󰂓";
}

.mdi-flask-empty:before {
  content: "󰂔";
}

.mdi-flask-empty-minus:before {
  content: "󱈺";
}

.mdi-flask-empty-minus-outline:before {
  content: "󱈻";
}

.mdi-flask-empty-off:before {
  content: "󱏴";
}

.mdi-flask-empty-off-outline:before {
  content: "󱏵";
}

.mdi-flask-empty-outline:before {
  content: "󰂕";
}

.mdi-flask-empty-plus:before {
  content: "󱈼";
}

.mdi-flask-empty-plus-outline:before {
  content: "󱈽";
}

.mdi-flask-empty-remove:before {
  content: "󱈾";
}

.mdi-flask-empty-remove-outline:before {
  content: "󱈿";
}

.mdi-flask-minus:before {
  content: "󱉀";
}

.mdi-flask-minus-outline:before {
  content: "󱉁";
}

.mdi-flask-off:before {
  content: "󱏶";
}

.mdi-flask-off-outline:before {
  content: "󱏷";
}

.mdi-flask-outline:before {
  content: "󰂖";
}

.mdi-flask-plus:before {
  content: "󱉂";
}

.mdi-flask-plus-outline:before {
  content: "󱉃";
}

.mdi-flask-remove:before {
  content: "󱉄";
}

.mdi-flask-remove-outline:before {
  content: "󱉅";
}

.mdi-flask-round-bottom:before {
  content: "󱉋";
}

.mdi-flask-round-bottom-empty:before {
  content: "󱉌";
}

.mdi-flask-round-bottom-empty-outline:before {
  content: "󱉍";
}

.mdi-flask-round-bottom-outline:before {
  content: "󱉎";
}

.mdi-fleur-de-lis:before {
  content: "󱌃";
}

.mdi-flip-horizontal:before {
  content: "󱃧";
}

.mdi-flip-to-back:before {
  content: "󰉇";
}

.mdi-flip-to-front:before {
  content: "󰉈";
}

.mdi-flip-vertical:before {
  content: "󱃨";
}

.mdi-floor-lamp:before {
  content: "󰣝";
}

.mdi-floor-lamp-dual:before {
  content: "󱁀";
}

.mdi-floor-lamp-variant:before {
  content: "󱁁";
}

.mdi-floor-plan:before {
  content: "󰠡";
}

.mdi-floppy:before {
  content: "󰉉";
}

.mdi-floppy-variant:before {
  content: "󰧯";
}

.mdi-flower:before {
  content: "󰉊";
}

.mdi-flower-outline:before {
  content: "󰧰";
}

.mdi-flower-poppy:before {
  content: "󰴈";
}

.mdi-flower-tulip:before {
  content: "󰧱";
}

.mdi-flower-tulip-outline:before {
  content: "󰧲";
}

.mdi-focus-auto:before {
  content: "󰽎";
}

.mdi-focus-field:before {
  content: "󰽏";
}

.mdi-focus-field-horizontal:before {
  content: "󰽐";
}

.mdi-focus-field-vertical:before {
  content: "󰽑";
}

.mdi-folder:before {
  content: "󰉋";
}

.mdi-folder-account:before {
  content: "󰉌";
}

.mdi-folder-account-outline:before {
  content: "󰮜";
}

.mdi-folder-alert:before {
  content: "󰷌";
}

.mdi-folder-alert-outline:before {
  content: "󰷍";
}

.mdi-folder-clock:before {
  content: "󰪺";
}

.mdi-folder-clock-outline:before {
  content: "󰪻";
}

.mdi-folder-cog:before {
  content: "󱁿";
}

.mdi-folder-cog-outline:before {
  content: "󱂀";
}

.mdi-folder-download:before {
  content: "󰉍";
}

.mdi-folder-download-outline:before {
  content: "󱃩";
}

.mdi-folder-edit:before {
  content: "󰣞";
}

.mdi-folder-edit-outline:before {
  content: "󰷎";
}

.mdi-folder-google-drive:before {
  content: "󰉎";
}

.mdi-folder-heart:before {
  content: "󱃪";
}

.mdi-folder-heart-outline:before {
  content: "󱃫";
}

.mdi-folder-home:before {
  content: "󱂵";
}

.mdi-folder-home-outline:before {
  content: "󱂶";
}

.mdi-folder-image:before {
  content: "󰉏";
}

.mdi-folder-information:before {
  content: "󱂷";
}

.mdi-folder-information-outline:before {
  content: "󱂸";
}

.mdi-folder-key:before {
  content: "󰢬";
}

.mdi-folder-key-network:before {
  content: "󰢭";
}

.mdi-folder-key-network-outline:before {
  content: "󰲀";
}

.mdi-folder-key-outline:before {
  content: "󱃬";
}

.mdi-folder-lock:before {
  content: "󰉐";
}

.mdi-folder-lock-open:before {
  content: "󰉑";
}

.mdi-folder-marker:before {
  content: "󱉭";
}

.mdi-folder-marker-outline:before {
  content: "󱉮";
}

.mdi-folder-move:before {
  content: "󰉒";
}

.mdi-folder-move-outline:before {
  content: "󱉆";
}

.mdi-folder-multiple:before {
  content: "󰉓";
}

.mdi-folder-multiple-image:before {
  content: "󰉔";
}

.mdi-folder-multiple-outline:before {
  content: "󰉕";
}

.mdi-folder-multiple-plus:before {
  content: "󱑾";
}

.mdi-folder-multiple-plus-outline:before {
  content: "󱑿";
}

.mdi-folder-music:before {
  content: "󱍙";
}

.mdi-folder-music-outline:before {
  content: "󱍚";
}

.mdi-folder-network:before {
  content: "󰡰";
}

.mdi-folder-network-outline:before {
  content: "󰲁";
}

.mdi-folder-open:before {
  content: "󰝰";
}

.mdi-folder-open-outline:before {
  content: "󰷏";
}

.mdi-folder-outline:before {
  content: "󰉖";
}

.mdi-folder-plus:before {
  content: "󰉗";
}

.mdi-folder-plus-outline:before {
  content: "󰮝";
}

.mdi-folder-pound:before {
  content: "󰴉";
}

.mdi-folder-pound-outline:before {
  content: "󰴊";
}

.mdi-folder-refresh:before {
  content: "󰝉";
}

.mdi-folder-refresh-outline:before {
  content: "󰕂";
}

.mdi-folder-remove:before {
  content: "󰉘";
}

.mdi-folder-remove-outline:before {
  content: "󰮞";
}

.mdi-folder-search:before {
  content: "󰥨";
}

.mdi-folder-search-outline:before {
  content: "󰥩";
}

.mdi-folder-settings:before {
  content: "󱁽";
}

.mdi-folder-settings-outline:before {
  content: "󱁾";
}

.mdi-folder-star:before {
  content: "󰚝";
}

.mdi-folder-star-multiple:before {
  content: "󱏓";
}

.mdi-folder-star-multiple-outline:before {
  content: "󱏔";
}

.mdi-folder-star-outline:before {
  content: "󰮟";
}

.mdi-folder-swap:before {
  content: "󰾶";
}

.mdi-folder-swap-outline:before {
  content: "󰾷";
}

.mdi-folder-sync:before {
  content: "󰴋";
}

.mdi-folder-sync-outline:before {
  content: "󰴌";
}

.mdi-folder-table:before {
  content: "󱋣";
}

.mdi-folder-table-outline:before {
  content: "󱋤";
}

.mdi-folder-text:before {
  content: "󰲂";
}

.mdi-folder-text-outline:before {
  content: "󰲃";
}

.mdi-folder-upload:before {
  content: "󰉙";
}

.mdi-folder-upload-outline:before {
  content: "󱃭";
}

.mdi-folder-zip:before {
  content: "󰛫";
}

.mdi-folder-zip-outline:before {
  content: "󰞹";
}

.mdi-font-awesome:before {
  content: "󰀺";
}

.mdi-food:before {
  content: "󰉚";
}

.mdi-food-apple:before {
  content: "󰉛";
}

.mdi-food-apple-outline:before {
  content: "󰲄";
}

.mdi-food-croissant:before {
  content: "󰟈";
}

.mdi-food-drumstick:before {
  content: "󱐟";
}

.mdi-food-drumstick-off:before {
  content: "󱑨";
}

.mdi-food-drumstick-off-outline:before {
  content: "󱑩";
}

.mdi-food-drumstick-outline:before {
  content: "󱐠";
}

.mdi-food-fork-drink:before {
  content: "󰗲";
}

.mdi-food-halal:before {
  content: "󱕲";
}

.mdi-food-kosher:before {
  content: "󱕳";
}

.mdi-food-off:before {
  content: "󰗳";
}

.mdi-food-steak:before {
  content: "󱑪";
}

.mdi-food-steak-off:before {
  content: "󱑫";
}

.mdi-food-turkey:before {
  content: "󱜜";
}

.mdi-food-variant:before {
  content: "󰉜";
}

.mdi-food-variant-off:before {
  content: "󱏥";
}

.mdi-foot-print:before {
  content: "󰽒";
}

.mdi-football:before {
  content: "󰉝";
}

.mdi-football-australian:before {
  content: "󰉞";
}

.mdi-football-helmet:before {
  content: "󰉟";
}

.mdi-forklift:before {
  content: "󰟉";
}

.mdi-form-dropdown:before {
  content: "󱐀";
}

.mdi-form-select:before {
  content: "󱐁";
}

.mdi-form-textarea:before {
  content: "󱂕";
}

.mdi-form-textbox:before {
  content: "󰘎";
}

.mdi-form-textbox-lock:before {
  content: "󱍝";
}

.mdi-form-textbox-password:before {
  content: "󰟵";
}

.mdi-format-align-bottom:before {
  content: "󰝓";
}

.mdi-format-align-center:before {
  content: "󰉠";
}

.mdi-format-align-justify:before {
  content: "󰉡";
}

.mdi-format-align-left:before {
  content: "󰉢";
}

.mdi-format-align-middle:before {
  content: "󰝔";
}

.mdi-format-align-right:before {
  content: "󰉣";
}

.mdi-format-align-top:before {
  content: "󰝕";
}

.mdi-format-annotation-minus:before {
  content: "󰪼";
}

.mdi-format-annotation-plus:before {
  content: "󰙆";
}

.mdi-format-bold:before {
  content: "󰉤";
}

.mdi-format-clear:before {
  content: "󰉥";
}

.mdi-format-color-fill:before {
  content: "󰉦";
}

.mdi-format-color-highlight:before {
  content: "󰸱";
}

.mdi-format-color-marker-cancel:before {
  content: "󱌓";
}

.mdi-format-color-text:before {
  content: "󰚞";
}

.mdi-format-columns:before {
  content: "󰣟";
}

.mdi-format-float-center:before {
  content: "󰉧";
}

.mdi-format-float-left:before {
  content: "󰉨";
}

.mdi-format-float-none:before {
  content: "󰉩";
}

.mdi-format-float-right:before {
  content: "󰉪";
}

.mdi-format-font:before {
  content: "󰛖";
}

.mdi-format-font-size-decrease:before {
  content: "󰧳";
}

.mdi-format-font-size-increase:before {
  content: "󰧴";
}

.mdi-format-header-1:before {
  content: "󰉫";
}

.mdi-format-header-2:before {
  content: "󰉬";
}

.mdi-format-header-3:before {
  content: "󰉭";
}

.mdi-format-header-4:before {
  content: "󰉮";
}

.mdi-format-header-5:before {
  content: "󰉯";
}

.mdi-format-header-6:before {
  content: "󰉰";
}

.mdi-format-header-decrease:before {
  content: "󰉱";
}

.mdi-format-header-equal:before {
  content: "󰉲";
}

.mdi-format-header-increase:before {
  content: "󰉳";
}

.mdi-format-header-pound:before {
  content: "󰉴";
}

.mdi-format-horizontal-align-center:before {
  content: "󰘞";
}

.mdi-format-horizontal-align-left:before {
  content: "󰘟";
}

.mdi-format-horizontal-align-right:before {
  content: "󰘠";
}

.mdi-format-indent-decrease:before {
  content: "󰉵";
}

.mdi-format-indent-increase:before {
  content: "󰉶";
}

.mdi-format-italic:before {
  content: "󰉷";
}

.mdi-format-letter-case:before {
  content: "󰬴";
}

.mdi-format-letter-case-lower:before {
  content: "󰬵";
}

.mdi-format-letter-case-upper:before {
  content: "󰬶";
}

.mdi-format-letter-ends-with:before {
  content: "󰾸";
}

.mdi-format-letter-matches:before {
  content: "󰾹";
}

.mdi-format-letter-starts-with:before {
  content: "󰾺";
}

.mdi-format-line-spacing:before {
  content: "󰉸";
}

.mdi-format-line-style:before {
  content: "󰗈";
}

.mdi-format-line-weight:before {
  content: "󰗉";
}

.mdi-format-list-bulleted:before {
  content: "󰉹";
}

.mdi-format-list-bulleted-square:before {
  content: "󰷐";
}

.mdi-format-list-bulleted-triangle:before {
  content: "󰺲";
}

.mdi-format-list-bulleted-type:before {
  content: "󰉺";
}

.mdi-format-list-checkbox:before {
  content: "󰥪";
}

.mdi-format-list-checks:before {
  content: "󰝖";
}

.mdi-format-list-numbered:before {
  content: "󰉻";
}

.mdi-format-list-numbered-rtl:before {
  content: "󰴍";
}

.mdi-format-list-text:before {
  content: "󱉯";
}

.mdi-format-overline:before {
  content: "󰺳";
}

.mdi-format-page-break:before {
  content: "󰛗";
}

.mdi-format-paint:before {
  content: "󰉼";
}

.mdi-format-paragraph:before {
  content: "󰉽";
}

.mdi-format-pilcrow:before {
  content: "󰛘";
}

.mdi-format-quote-close:before {
  content: "󰉾";
}

.mdi-format-quote-close-outline:before {
  content: "󱆨";
}

.mdi-format-quote-open:before {
  content: "󰝗";
}

.mdi-format-quote-open-outline:before {
  content: "󱆧";
}

.mdi-format-rotate-90:before {
  content: "󰚪";
}

.mdi-format-section:before {
  content: "󰚟";
}

.mdi-format-size:before {
  content: "󰉿";
}

.mdi-format-strikethrough:before {
  content: "󰊀";
}

.mdi-format-strikethrough-variant:before {
  content: "󰊁";
}

.mdi-format-subscript:before {
  content: "󰊂";
}

.mdi-format-superscript:before {
  content: "󰊃";
}

.mdi-format-text:before {
  content: "󰊄";
}

.mdi-format-text-rotation-angle-down:before {
  content: "󰾻";
}

.mdi-format-text-rotation-angle-up:before {
  content: "󰾼";
}

.mdi-format-text-rotation-down:before {
  content: "󰵳";
}

.mdi-format-text-rotation-down-vertical:before {
  content: "󰾽";
}

.mdi-format-text-rotation-none:before {
  content: "󰵴";
}

.mdi-format-text-rotation-up:before {
  content: "󰾾";
}

.mdi-format-text-rotation-vertical:before {
  content: "󰾿";
}

.mdi-format-text-variant:before {
  content: "󰸲";
}

.mdi-format-text-variant-outline:before {
  content: "󱔏";
}

.mdi-format-text-wrapping-clip:before {
  content: "󰴎";
}

.mdi-format-text-wrapping-overflow:before {
  content: "󰴏";
}

.mdi-format-text-wrapping-wrap:before {
  content: "󰴐";
}

.mdi-format-textbox:before {
  content: "󰴑";
}

.mdi-format-textdirection-l-to-r:before {
  content: "󰊅";
}

.mdi-format-textdirection-r-to-l:before {
  content: "󰊆";
}

.mdi-format-title:before {
  content: "󰗴";
}

.mdi-format-underline:before {
  content: "󰊇";
}

.mdi-format-vertical-align-bottom:before {
  content: "󰘡";
}

.mdi-format-vertical-align-center:before {
  content: "󰘢";
}

.mdi-format-vertical-align-top:before {
  content: "󰘣";
}

.mdi-format-wrap-inline:before {
  content: "󰊈";
}

.mdi-format-wrap-square:before {
  content: "󰊉";
}

.mdi-format-wrap-tight:before {
  content: "󰊊";
}

.mdi-format-wrap-top-bottom:before {
  content: "󰊋";
}

.mdi-forum:before {
  content: "󰊌";
}

.mdi-forum-outline:before {
  content: "󰠢";
}

.mdi-forward:before {
  content: "󰊍";
}

.mdi-forwardburger:before {
  content: "󰵵";
}

.mdi-fountain:before {
  content: "󰥫";
}

.mdi-fountain-pen:before {
  content: "󰴒";
}

.mdi-fountain-pen-tip:before {
  content: "󰴓";
}

.mdi-freebsd:before {
  content: "󰣠";
}

.mdi-frequently-asked-questions:before {
  content: "󰺴";
}

.mdi-fridge:before {
  content: "󰊐";
}

.mdi-fridge-alert:before {
  content: "󱆱";
}

.mdi-fridge-alert-outline:before {
  content: "󱆲";
}

.mdi-fridge-bottom:before {
  content: "󰊒";
}

.mdi-fridge-industrial:before {
  content: "󱗮";
}

.mdi-fridge-industrial-alert:before {
  content: "󱗯";
}

.mdi-fridge-industrial-alert-outline:before {
  content: "󱗰";
}

.mdi-fridge-industrial-off:before {
  content: "󱗱";
}

.mdi-fridge-industrial-off-outline:before {
  content: "󱗲";
}

.mdi-fridge-industrial-outline:before {
  content: "󱗳";
}

.mdi-fridge-off:before {
  content: "󱆯";
}

.mdi-fridge-off-outline:before {
  content: "󱆰";
}

.mdi-fridge-outline:before {
  content: "󰊏";
}

.mdi-fridge-top:before {
  content: "󰊑";
}

.mdi-fridge-variant:before {
  content: "󱗴";
}

.mdi-fridge-variant-alert:before {
  content: "󱗵";
}

.mdi-fridge-variant-alert-outline:before {
  content: "󱗶";
}

.mdi-fridge-variant-off:before {
  content: "󱗷";
}

.mdi-fridge-variant-off-outline:before {
  content: "󱗸";
}

.mdi-fridge-variant-outline:before {
  content: "󱗹";
}

.mdi-fruit-cherries:before {
  content: "󱁂";
}

.mdi-fruit-cherries-off:before {
  content: "󱏸";
}

.mdi-fruit-citrus:before {
  content: "󱁃";
}

.mdi-fruit-citrus-off:before {
  content: "󱏹";
}

.mdi-fruit-grapes:before {
  content: "󱁄";
}

.mdi-fruit-grapes-outline:before {
  content: "󱁅";
}

.mdi-fruit-pineapple:before {
  content: "󱁆";
}

.mdi-fruit-watermelon:before {
  content: "󱁇";
}

.mdi-fuel:before {
  content: "󰟊";
}

.mdi-fullscreen:before {
  content: "󰊓";
}

.mdi-fullscreen-exit:before {
  content: "󰊔";
}

.mdi-function:before {
  content: "󰊕";
}

.mdi-function-variant:before {
  content: "󰡱";
}

.mdi-furigana-horizontal:before {
  content: "󱂁";
}

.mdi-furigana-vertical:before {
  content: "󱂂";
}

.mdi-fuse:before {
  content: "󰲅";
}

.mdi-fuse-alert:before {
  content: "󱐭";
}

.mdi-fuse-blade:before {
  content: "󰲆";
}

.mdi-fuse-off:before {
  content: "󱐬";
}

.mdi-gamepad:before {
  content: "󰊖";
}

.mdi-gamepad-circle:before {
  content: "󰸳";
}

.mdi-gamepad-circle-down:before {
  content: "󰸴";
}

.mdi-gamepad-circle-left:before {
  content: "󰸵";
}

.mdi-gamepad-circle-outline:before {
  content: "󰸶";
}

.mdi-gamepad-circle-right:before {
  content: "󰸷";
}

.mdi-gamepad-circle-up:before {
  content: "󰸸";
}

.mdi-gamepad-down:before {
  content: "󰸹";
}

.mdi-gamepad-left:before {
  content: "󰸺";
}

.mdi-gamepad-right:before {
  content: "󰸻";
}

.mdi-gamepad-round:before {
  content: "󰸼";
}

.mdi-gamepad-round-down:before {
  content: "󰸽";
}

.mdi-gamepad-round-left:before {
  content: "󰸾";
}

.mdi-gamepad-round-outline:before {
  content: "󰸿";
}

.mdi-gamepad-round-right:before {
  content: "󰹀";
}

.mdi-gamepad-round-up:before {
  content: "󰹁";
}

.mdi-gamepad-square:before {
  content: "󰺵";
}

.mdi-gamepad-square-outline:before {
  content: "󰺶";
}

.mdi-gamepad-up:before {
  content: "󰹂";
}

.mdi-gamepad-variant:before {
  content: "󰊗";
}

.mdi-gamepad-variant-outline:before {
  content: "󰺷";
}

.mdi-gamma:before {
  content: "󱃮";
}

.mdi-gantry-crane:before {
  content: "󰷑";
}

.mdi-garage:before {
  content: "󰛙";
}

.mdi-garage-alert:before {
  content: "󰡲";
}

.mdi-garage-alert-variant:before {
  content: "󱋕";
}

.mdi-garage-open:before {
  content: "󰛚";
}

.mdi-garage-open-variant:before {
  content: "󱋔";
}

.mdi-garage-variant:before {
  content: "󱋓";
}

.mdi-gas-cylinder:before {
  content: "󰙇";
}

.mdi-gas-station:before {
  content: "󰊘";
}

.mdi-gas-station-off:before {
  content: "󱐉";
}

.mdi-gas-station-off-outline:before {
  content: "󱐊";
}

.mdi-gas-station-outline:before {
  content: "󰺸";
}

.mdi-gate:before {
  content: "󰊙";
}

.mdi-gate-and:before {
  content: "󰣡";
}

.mdi-gate-arrow-right:before {
  content: "󱅩";
}

.mdi-gate-nand:before {
  content: "󰣢";
}

.mdi-gate-nor:before {
  content: "󰣣";
}

.mdi-gate-not:before {
  content: "󰣤";
}

.mdi-gate-open:before {
  content: "󱅪";
}

.mdi-gate-or:before {
  content: "󰣥";
}

.mdi-gate-xnor:before {
  content: "󰣦";
}

.mdi-gate-xor:before {
  content: "󰣧";
}

.mdi-gatsby:before {
  content: "󰹃";
}

.mdi-gauge:before {
  content: "󰊚";
}

.mdi-gauge-empty:before {
  content: "󰡳";
}

.mdi-gauge-full:before {
  content: "󰡴";
}

.mdi-gauge-low:before {
  content: "󰡵";
}

.mdi-gavel:before {
  content: "󰊛";
}

.mdi-gender-female:before {
  content: "󰊜";
}

.mdi-gender-male:before {
  content: "󰊝";
}

.mdi-gender-male-female:before {
  content: "󰊞";
}

.mdi-gender-male-female-variant:before {
  content: "󱄿";
}

.mdi-gender-non-binary:before {
  content: "󱅀";
}

.mdi-gender-transgender:before {
  content: "󰊟";
}

.mdi-gentoo:before {
  content: "󰣨";
}

.mdi-gesture:before {
  content: "󰟋";
}

.mdi-gesture-double-tap:before {
  content: "󰜼";
}

.mdi-gesture-pinch:before {
  content: "󰪽";
}

.mdi-gesture-spread:before {
  content: "󰪾";
}

.mdi-gesture-swipe:before {
  content: "󰵶";
}

.mdi-gesture-swipe-down:before {
  content: "󰜽";
}

.mdi-gesture-swipe-horizontal:before {
  content: "󰪿";
}

.mdi-gesture-swipe-left:before {
  content: "󰜾";
}

.mdi-gesture-swipe-right:before {
  content: "󰜿";
}

.mdi-gesture-swipe-up:before {
  content: "󰝀";
}

.mdi-gesture-swipe-vertical:before {
  content: "󰫀";
}

.mdi-gesture-tap:before {
  content: "󰝁";
}

.mdi-gesture-tap-box:before {
  content: "󱊩";
}

.mdi-gesture-tap-button:before {
  content: "󱊨";
}

.mdi-gesture-tap-hold:before {
  content: "󰵷";
}

.mdi-gesture-two-double-tap:before {
  content: "󰝂";
}

.mdi-gesture-two-tap:before {
  content: "󰝃";
}

.mdi-ghost:before {
  content: "󰊠";
}

.mdi-ghost-off:before {
  content: "󰧵";
}

.mdi-ghost-off-outline:before {
  content: "󱙜";
}

.mdi-ghost-outline:before {
  content: "󱙝";
}

.mdi-gif:before {
  content: "󰵸";
}

.mdi-gift:before {
  content: "󰹄";
}

.mdi-gift-off:before {
  content: "󱛯";
}

.mdi-gift-off-outline:before {
  content: "󱛰";
}

.mdi-gift-open:before {
  content: "󱛱";
}

.mdi-gift-open-outline:before {
  content: "󱛲";
}

.mdi-gift-outline:before {
  content: "󰊡";
}

.mdi-git:before {
  content: "󰊢";
}

.mdi-github:before {
  content: "󰊤";
}

.mdi-gitlab:before {
  content: "󰮠";
}

.mdi-glass-cocktail:before {
  content: "󰍖";
}

.mdi-glass-cocktail-off:before {
  content: "󱗦";
}

.mdi-glass-flute:before {
  content: "󰊥";
}

.mdi-glass-mug:before {
  content: "󰊦";
}

.mdi-glass-mug-off:before {
  content: "󱗧";
}

.mdi-glass-mug-variant:before {
  content: "󱄖";
}

.mdi-glass-mug-variant-off:before {
  content: "󱗨";
}

.mdi-glass-pint-outline:before {
  content: "󱌍";
}

.mdi-glass-stange:before {
  content: "󰊧";
}

.mdi-glass-tulip:before {
  content: "󰊨";
}

.mdi-glass-wine:before {
  content: "󰡶";
}

.mdi-glasses:before {
  content: "󰊪";
}

.mdi-globe-light:before {
  content: "󱋗";
}

.mdi-globe-model:before {
  content: "󰣩";
}

.mdi-gmail:before {
  content: "󰊫";
}

.mdi-gnome:before {
  content: "󰊬";
}

.mdi-go-kart:before {
  content: "󰵹";
}

.mdi-go-kart-track:before {
  content: "󰵺";
}

.mdi-gog:before {
  content: "󰮡";
}

.mdi-gold:before {
  content: "󱉏";
}

.mdi-golf:before {
  content: "󰠣";
}

.mdi-golf-cart:before {
  content: "󱆤";
}

.mdi-golf-tee:before {
  content: "󱂃";
}

.mdi-gondola:before {
  content: "󰚆";
}

.mdi-goodreads:before {
  content: "󰵻";
}

.mdi-google:before {
  content: "󰊭";
}

.mdi-google-ads:before {
  content: "󰲇";
}

.mdi-google-analytics:before {
  content: "󰟌";
}

.mdi-google-assistant:before {
  content: "󰟍";
}

.mdi-google-cardboard:before {
  content: "󰊮";
}

.mdi-google-chrome:before {
  content: "󰊯";
}

.mdi-google-circles:before {
  content: "󰊰";
}

.mdi-google-circles-communities:before {
  content: "󰊱";
}

.mdi-google-circles-extended:before {
  content: "󰊲";
}

.mdi-google-circles-group:before {
  content: "󰊳";
}

.mdi-google-classroom:before {
  content: "󰋀";
}

.mdi-google-cloud:before {
  content: "󱇶";
}

.mdi-google-controller:before {
  content: "󰊴";
}

.mdi-google-controller-off:before {
  content: "󰊵";
}

.mdi-google-downasaur:before {
  content: "󱍢";
}

.mdi-google-drive:before {
  content: "󰊶";
}

.mdi-google-earth:before {
  content: "󰊷";
}

.mdi-google-fit:before {
  content: "󰥬";
}

.mdi-google-glass:before {
  content: "󰊸";
}

.mdi-google-hangouts:before {
  content: "󰋉";
}

.mdi-google-home:before {
  content: "󰠤";
}

.mdi-google-keep:before {
  content: "󰛜";
}

.mdi-google-lens:before {
  content: "󰧶";
}

.mdi-google-maps:before {
  content: "󰗵";
}

.mdi-google-my-business:before {
  content: "󱁈";
}

.mdi-google-nearby:before {
  content: "󰊹";
}

.mdi-google-photos:before {
  content: "󰛝";
}

.mdi-google-play:before {
  content: "󰊼";
}

.mdi-google-plus:before {
  content: "󰊽";
}

.mdi-google-podcast:before {
  content: "󰺹";
}

.mdi-google-spreadsheet:before {
  content: "󰧷";
}

.mdi-google-street-view:before {
  content: "󰲈";
}

.mdi-google-translate:before {
  content: "󰊿";
}

.mdi-gradient:before {
  content: "󰚠";
}

.mdi-grain:before {
  content: "󰵼";
}

.mdi-graph:before {
  content: "󱁉";
}

.mdi-graph-outline:before {
  content: "󱁊";
}

.mdi-graphql:before {
  content: "󰡷";
}

.mdi-grass:before {
  content: "󱔐";
}

.mdi-grave-stone:before {
  content: "󰮢";
}

.mdi-grease-pencil:before {
  content: "󰙈";
}

.mdi-greater-than:before {
  content: "󰥭";
}

.mdi-greater-than-or-equal:before {
  content: "󰥮";
}

.mdi-grid:before {
  content: "󰋁";
}

.mdi-grid-large:before {
  content: "󰝘";
}

.mdi-grid-off:before {
  content: "󰋂";
}

.mdi-grill:before {
  content: "󰹅";
}

.mdi-grill-outline:before {
  content: "󱆊";
}

.mdi-group:before {
  content: "󰋃";
}

.mdi-guitar-acoustic:before {
  content: "󰝱";
}

.mdi-guitar-electric:before {
  content: "󰋄";
}

.mdi-guitar-pick:before {
  content: "󰋅";
}

.mdi-guitar-pick-outline:before {
  content: "󰋆";
}

.mdi-guy-fawkes-mask:before {
  content: "󰠥";
}

.mdi-hail:before {
  content: "󰫁";
}

.mdi-hair-dryer:before {
  content: "󱃯";
}

.mdi-hair-dryer-outline:before {
  content: "󱃰";
}

.mdi-halloween:before {
  content: "󰮣";
}

.mdi-hamburger:before {
  content: "󰚅";
}

.mdi-hammer:before {
  content: "󰣪";
}

.mdi-hammer-screwdriver:before {
  content: "󱌢";
}

.mdi-hammer-wrench:before {
  content: "󱌣";
}

.mdi-hand:before {
  content: "󰩏";
}

.mdi-hand-heart:before {
  content: "󱃱";
}

.mdi-hand-heart-outline:before {
  content: "󱕾";
}

.mdi-hand-left:before {
  content: "󰹆";
}

.mdi-hand-okay:before {
  content: "󰩐";
}

.mdi-hand-peace:before {
  content: "󰩑";
}

.mdi-hand-peace-variant:before {
  content: "󰩒";
}

.mdi-hand-pointing-down:before {
  content: "󰩓";
}

.mdi-hand-pointing-left:before {
  content: "󰩔";
}

.mdi-hand-pointing-right:before {
  content: "󰋇";
}

.mdi-hand-pointing-up:before {
  content: "󰩕";
}

.mdi-hand-right:before {
  content: "󰹇";
}

.mdi-hand-saw:before {
  content: "󰹈";
}

.mdi-hand-wash:before {
  content: "󱕿";
}

.mdi-hand-wash-outline:before {
  content: "󱖀";
}

.mdi-hand-water:before {
  content: "󱎟";
}

.mdi-handball:before {
  content: "󰽓";
}

.mdi-handcuffs:before {
  content: "󱄾";
}

.mdi-handshake:before {
  content: "󱈘";
}

.mdi-handshake-outline:before {
  content: "󱖡";
}

.mdi-hanger:before {
  content: "󰋈";
}

.mdi-hard-hat:before {
  content: "󰥯";
}

.mdi-harddisk:before {
  content: "󰋊";
}

.mdi-harddisk-plus:before {
  content: "󱁋";
}

.mdi-harddisk-remove:before {
  content: "󱁌";
}

.mdi-hat-fedora:before {
  content: "󰮤";
}

.mdi-hazard-lights:before {
  content: "󰲉";
}

.mdi-hdr:before {
  content: "󰵽";
}

.mdi-hdr-off:before {
  content: "󰵾";
}

.mdi-head:before {
  content: "󱍞";
}

.mdi-head-alert:before {
  content: "󱌸";
}

.mdi-head-alert-outline:before {
  content: "󱌹";
}

.mdi-head-check:before {
  content: "󱌺";
}

.mdi-head-check-outline:before {
  content: "󱌻";
}

.mdi-head-cog:before {
  content: "󱌼";
}

.mdi-head-cog-outline:before {
  content: "󱌽";
}

.mdi-head-dots-horizontal:before {
  content: "󱌾";
}

.mdi-head-dots-horizontal-outline:before {
  content: "󱌿";
}

.mdi-head-flash:before {
  content: "󱍀";
}

.mdi-head-flash-outline:before {
  content: "󱍁";
}

.mdi-head-heart:before {
  content: "󱍂";
}

.mdi-head-heart-outline:before {
  content: "󱍃";
}

.mdi-head-lightbulb:before {
  content: "󱍄";
}

.mdi-head-lightbulb-outline:before {
  content: "󱍅";
}

.mdi-head-minus:before {
  content: "󱍆";
}

.mdi-head-minus-outline:before {
  content: "󱍇";
}

.mdi-head-outline:before {
  content: "󱍟";
}

.mdi-head-plus:before {
  content: "󱍈";
}

.mdi-head-plus-outline:before {
  content: "󱍉";
}

.mdi-head-question:before {
  content: "󱍊";
}

.mdi-head-question-outline:before {
  content: "󱍋";
}

.mdi-head-remove:before {
  content: "󱍌";
}

.mdi-head-remove-outline:before {
  content: "󱍍";
}

.mdi-head-snowflake:before {
  content: "󱍎";
}

.mdi-head-snowflake-outline:before {
  content: "󱍏";
}

.mdi-head-sync:before {
  content: "󱍐";
}

.mdi-head-sync-outline:before {
  content: "󱍑";
}

.mdi-headphones:before {
  content: "󰋋";
}

.mdi-headphones-bluetooth:before {
  content: "󰥰";
}

.mdi-headphones-box:before {
  content: "󰋌";
}

.mdi-headphones-off:before {
  content: "󰟎";
}

.mdi-headphones-settings:before {
  content: "󰋍";
}

.mdi-headset:before {
  content: "󰋎";
}

.mdi-headset-dock:before {
  content: "󰋏";
}

.mdi-headset-off:before {
  content: "󰋐";
}

.mdi-heart:before {
  content: "󰋑";
}

.mdi-heart-box:before {
  content: "󰋒";
}

.mdi-heart-box-outline:before {
  content: "󰋓";
}

.mdi-heart-broken:before {
  content: "󰋔";
}

.mdi-heart-broken-outline:before {
  content: "󰴔";
}

.mdi-heart-circle:before {
  content: "󰥱";
}

.mdi-heart-circle-outline:before {
  content: "󰥲";
}

.mdi-heart-cog:before {
  content: "󱙣";
}

.mdi-heart-cog-outline:before {
  content: "󱙤";
}

.mdi-heart-flash:before {
  content: "󰻹";
}

.mdi-heart-half:before {
  content: "󰛟";
}

.mdi-heart-half-full:before {
  content: "󰛞";
}

.mdi-heart-half-outline:before {
  content: "󰛠";
}

.mdi-heart-minus:before {
  content: "󱐯";
}

.mdi-heart-minus-outline:before {
  content: "󱐲";
}

.mdi-heart-multiple:before {
  content: "󰩖";
}

.mdi-heart-multiple-outline:before {
  content: "󰩗";
}

.mdi-heart-off:before {
  content: "󰝙";
}

.mdi-heart-off-outline:before {
  content: "󱐴";
}

.mdi-heart-outline:before {
  content: "󰋕";
}

.mdi-heart-plus:before {
  content: "󱐮";
}

.mdi-heart-plus-outline:before {
  content: "󱐱";
}

.mdi-heart-pulse:before {
  content: "󰗶";
}

.mdi-heart-remove:before {
  content: "󱐰";
}

.mdi-heart-remove-outline:before {
  content: "󱐳";
}

.mdi-heart-settings:before {
  content: "󱙥";
}

.mdi-heart-settings-outline:before {
  content: "󱙦";
}

.mdi-helicopter:before {
  content: "󰫂";
}

.mdi-help:before {
  content: "󰋖";
}

.mdi-help-box:before {
  content: "󰞋";
}

.mdi-help-circle:before {
  content: "󰋗";
}

.mdi-help-circle-outline:before {
  content: "󰘥";
}

.mdi-help-network:before {
  content: "󰛵";
}

.mdi-help-network-outline:before {
  content: "󰲊";
}

.mdi-help-rhombus:before {
  content: "󰮥";
}

.mdi-help-rhombus-outline:before {
  content: "󰮦";
}

.mdi-hexadecimal:before {
  content: "󱊧";
}

.mdi-hexagon:before {
  content: "󰋘";
}

.mdi-hexagon-multiple:before {
  content: "󰛡";
}

.mdi-hexagon-multiple-outline:before {
  content: "󱃲";
}

.mdi-hexagon-outline:before {
  content: "󰋙";
}

.mdi-hexagon-slice-1:before {
  content: "󰫃";
}

.mdi-hexagon-slice-2:before {
  content: "󰫄";
}

.mdi-hexagon-slice-3:before {
  content: "󰫅";
}

.mdi-hexagon-slice-4:before {
  content: "󰫆";
}

.mdi-hexagon-slice-5:before {
  content: "󰫇";
}

.mdi-hexagon-slice-6:before {
  content: "󰫈";
}

.mdi-hexagram:before {
  content: "󰫉";
}

.mdi-hexagram-outline:before {
  content: "󰫊";
}

.mdi-high-definition:before {
  content: "󰟏";
}

.mdi-high-definition-box:before {
  content: "󰡸";
}

.mdi-highway:before {
  content: "󰗷";
}

.mdi-hiking:before {
  content: "󰵿";
}

.mdi-hinduism:before {
  content: "󰥳";
}

.mdi-history:before {
  content: "󰋚";
}

.mdi-hockey-puck:before {
  content: "󰡹";
}

.mdi-hockey-sticks:before {
  content: "󰡺";
}

.mdi-hololens:before {
  content: "󰋛";
}

.mdi-home:before {
  content: "󰋜";
}

.mdi-home-account:before {
  content: "󰠦";
}

.mdi-home-alert:before {
  content: "󰡻";
}

.mdi-home-alert-outline:before {
  content: "󱗐";
}

.mdi-home-analytics:before {
  content: "󰺺";
}

.mdi-home-assistant:before {
  content: "󰟐";
}

.mdi-home-automation:before {
  content: "󰟑";
}

.mdi-home-circle:before {
  content: "󰟒";
}

.mdi-home-circle-outline:before {
  content: "󱁍";
}

.mdi-home-city:before {
  content: "󰴕";
}

.mdi-home-city-outline:before {
  content: "󰴖";
}

.mdi-home-currency-usd:before {
  content: "󰢯";
}

.mdi-home-edit:before {
  content: "󱅙";
}

.mdi-home-edit-outline:before {
  content: "󱅚";
}

.mdi-home-export-outline:before {
  content: "󰾛";
}

.mdi-home-flood:before {
  content: "󰻺";
}

.mdi-home-floor-0:before {
  content: "󰷒";
}

.mdi-home-floor-1:before {
  content: "󰶀";
}

.mdi-home-floor-2:before {
  content: "󰶁";
}

.mdi-home-floor-3:before {
  content: "󰶂";
}

.mdi-home-floor-a:before {
  content: "󰶃";
}

.mdi-home-floor-b:before {
  content: "󰶄";
}

.mdi-home-floor-g:before {
  content: "󰶅";
}

.mdi-home-floor-l:before {
  content: "󰶆";
}

.mdi-home-floor-negative-1:before {
  content: "󰷓";
}

.mdi-home-group:before {
  content: "󰷔";
}

.mdi-home-heart:before {
  content: "󰠧";
}

.mdi-home-import-outline:before {
  content: "󰾜";
}

.mdi-home-lightbulb:before {
  content: "󱉑";
}

.mdi-home-lightbulb-outline:before {
  content: "󱉒";
}

.mdi-home-lock:before {
  content: "󰣫";
}

.mdi-home-lock-open:before {
  content: "󰣬";
}

.mdi-home-map-marker:before {
  content: "󰗸";
}

.mdi-home-minus:before {
  content: "󰥴";
}

.mdi-home-minus-outline:before {
  content: "󱏕";
}

.mdi-home-modern:before {
  content: "󰋝";
}

.mdi-home-outline:before {
  content: "󰚡";
}

.mdi-home-plus:before {
  content: "󰥵";
}

.mdi-home-plus-outline:before {
  content: "󱏖";
}

.mdi-home-remove:before {
  content: "󱉇";
}

.mdi-home-remove-outline:before {
  content: "󱏗";
}

.mdi-home-roof:before {
  content: "󱄫";
}

.mdi-home-search:before {
  content: "󱎰";
}

.mdi-home-search-outline:before {
  content: "󱎱";
}

.mdi-home-thermometer:before {
  content: "󰽔";
}

.mdi-home-thermometer-outline:before {
  content: "󰽕";
}

.mdi-home-variant:before {
  content: "󰋞";
}

.mdi-home-variant-outline:before {
  content: "󰮧";
}

.mdi-hook:before {
  content: "󰛢";
}

.mdi-hook-off:before {
  content: "󰛣";
}

.mdi-hops:before {
  content: "󰋟";
}

.mdi-horizontal-rotate-clockwise:before {
  content: "󱃳";
}

.mdi-horizontal-rotate-counterclockwise:before {
  content: "󱃴";
}

.mdi-horse:before {
  content: "󱖿";
}

.mdi-horse-human:before {
  content: "󱗀";
}

.mdi-horse-variant:before {
  content: "󱗁";
}

.mdi-horseshoe:before {
  content: "󰩘";
}

.mdi-hospital:before {
  content: "󰿶";
}

.mdi-hospital-box:before {
  content: "󰋠";
}

.mdi-hospital-box-outline:before {
  content: "󰿷";
}

.mdi-hospital-building:before {
  content: "󰋡";
}

.mdi-hospital-marker:before {
  content: "󰋢";
}

.mdi-hot-tub:before {
  content: "󰠨";
}

.mdi-hours-24:before {
  content: "󱑸";
}

.mdi-hubspot:before {
  content: "󰴗";
}

.mdi-hulu:before {
  content: "󰠩";
}

.mdi-human:before {
  content: "󰋦";
}

.mdi-human-baby-changing-table:before {
  content: "󱎋";
}

.mdi-human-cane:before {
  content: "󱖁";
}

.mdi-human-capacity-decrease:before {
  content: "󱖛";
}

.mdi-human-capacity-increase:before {
  content: "󱖜";
}

.mdi-human-child:before {
  content: "󰋧";
}

.mdi-human-edit:before {
  content: "󱓨";
}

.mdi-human-female:before {
  content: "󰙉";
}

.mdi-human-female-boy:before {
  content: "󰩙";
}

.mdi-human-female-dance:before {
  content: "󱗉";
}

.mdi-human-female-female:before {
  content: "󰩚";
}

.mdi-human-female-girl:before {
  content: "󰩛";
}

.mdi-human-greeting:before {
  content: "󰙊";
}

.mdi-human-greeting-proximity:before {
  content: "󱖝";
}

.mdi-human-handsdown:before {
  content: "󰙋";
}

.mdi-human-handsup:before {
  content: "󰙌";
}

.mdi-human-male:before {
  content: "󰙍";
}

.mdi-human-male-boy:before {
  content: "󰩜";
}

.mdi-human-male-child:before {
  content: "󱎌";
}

.mdi-human-male-female:before {
  content: "󰋨";
}

.mdi-human-male-girl:before {
  content: "󰩝";
}

.mdi-human-male-height:before {
  content: "󰻻";
}

.mdi-human-male-height-variant:before {
  content: "󰻼";
}

.mdi-human-male-male:before {
  content: "󰩞";
}

.mdi-human-pregnant:before {
  content: "󰗏";
}

.mdi-human-queue:before {
  content: "󱕱";
}

.mdi-human-scooter:before {
  content: "󱇩";
}

.mdi-human-wheelchair:before {
  content: "󱎍";
}

.mdi-humble-bundle:before {
  content: "󰝄";
}

.mdi-hvac:before {
  content: "󱍒";
}

.mdi-hvac-off:before {
  content: "󱖞";
}

.mdi-hydraulic-oil-level:before {
  content: "󱌤";
}

.mdi-hydraulic-oil-temperature:before {
  content: "󱌥";
}

.mdi-hydro-power:before {
  content: "󱋥";
}

.mdi-ice-cream:before {
  content: "󰠪";
}

.mdi-ice-cream-off:before {
  content: "󰹒";
}

.mdi-ice-pop:before {
  content: "󰻽";
}

.mdi-id-card:before {
  content: "󰿀";
}

.mdi-identifier:before {
  content: "󰻾";
}

.mdi-ideogram-cjk:before {
  content: "󱌱";
}

.mdi-ideogram-cjk-variant:before {
  content: "󱌲";
}

.mdi-iframe:before {
  content: "󰲋";
}

.mdi-iframe-array:before {
  content: "󱃵";
}

.mdi-iframe-array-outline:before {
  content: "󱃶";
}

.mdi-iframe-braces:before {
  content: "󱃷";
}

.mdi-iframe-braces-outline:before {
  content: "󱃸";
}

.mdi-iframe-outline:before {
  content: "󰲌";
}

.mdi-iframe-parentheses:before {
  content: "󱃹";
}

.mdi-iframe-parentheses-outline:before {
  content: "󱃺";
}

.mdi-iframe-variable:before {
  content: "󱃻";
}

.mdi-iframe-variable-outline:before {
  content: "󱃼";
}

.mdi-image:before {
  content: "󰋩";
}

.mdi-image-album:before {
  content: "󰋪";
}

.mdi-image-area:before {
  content: "󰋫";
}

.mdi-image-area-close:before {
  content: "󰋬";
}

.mdi-image-auto-adjust:before {
  content: "󰿁";
}

.mdi-image-broken:before {
  content: "󰋭";
}

.mdi-image-broken-variant:before {
  content: "󰋮";
}

.mdi-image-edit:before {
  content: "󱇣";
}

.mdi-image-edit-outline:before {
  content: "󱇤";
}

.mdi-image-filter-black-white:before {
  content: "󰋰";
}

.mdi-image-filter-center-focus:before {
  content: "󰋱";
}

.mdi-image-filter-center-focus-strong:before {
  content: "󰻿";
}

.mdi-image-filter-center-focus-strong-outline:before {
  content: "󰼀";
}

.mdi-image-filter-center-focus-weak:before {
  content: "󰋲";
}

.mdi-image-filter-drama:before {
  content: "󰋳";
}

.mdi-image-filter-frames:before {
  content: "󰋴";
}

.mdi-image-filter-hdr:before {
  content: "󰋵";
}

.mdi-image-filter-none:before {
  content: "󰋶";
}

.mdi-image-filter-tilt-shift:before {
  content: "󰋷";
}

.mdi-image-filter-vintage:before {
  content: "󰋸";
}

.mdi-image-frame:before {
  content: "󰹉";
}

.mdi-image-minus:before {
  content: "󱐙";
}

.mdi-image-move:before {
  content: "󰧸";
}

.mdi-image-multiple:before {
  content: "󰋹";
}

.mdi-image-multiple-outline:before {
  content: "󰋯";
}

.mdi-image-off:before {
  content: "󰠫";
}

.mdi-image-off-outline:before {
  content: "󱇑";
}

.mdi-image-outline:before {
  content: "󰥶";
}

.mdi-image-plus:before {
  content: "󰡼";
}

.mdi-image-remove:before {
  content: "󱐘";
}

.mdi-image-search:before {
  content: "󰥷";
}

.mdi-image-search-outline:before {
  content: "󰥸";
}

.mdi-image-size-select-actual:before {
  content: "󰲍";
}

.mdi-image-size-select-large:before {
  content: "󰲎";
}

.mdi-image-size-select-small:before {
  content: "󰲏";
}

.mdi-image-text:before {
  content: "󱘍";
}

.mdi-import:before {
  content: "󰋺";
}

.mdi-inbox:before {
  content: "󰚇";
}

.mdi-inbox-arrow-down:before {
  content: "󰋻";
}

.mdi-inbox-arrow-down-outline:before {
  content: "󱉰";
}

.mdi-inbox-arrow-up:before {
  content: "󰏑";
}

.mdi-inbox-arrow-up-outline:before {
  content: "󱉱";
}

.mdi-inbox-full:before {
  content: "󱉲";
}

.mdi-inbox-full-outline:before {
  content: "󱉳";
}

.mdi-inbox-multiple:before {
  content: "󰢰";
}

.mdi-inbox-multiple-outline:before {
  content: "󰮨";
}

.mdi-inbox-outline:before {
  content: "󱉴";
}

.mdi-inbox-remove:before {
  content: "󱖟";
}

.mdi-inbox-remove-outline:before {
  content: "󱖠";
}

.mdi-incognito:before {
  content: "󰗹";
}

.mdi-incognito-circle:before {
  content: "󱐡";
}

.mdi-incognito-circle-off:before {
  content: "󱐢";
}

.mdi-incognito-off:before {
  content: "󰁵";
}

.mdi-infinity:before {
  content: "󰛤";
}

.mdi-information:before {
  content: "󰋼";
}

.mdi-information-outline:before {
  content: "󰋽";
}

.mdi-information-variant:before {
  content: "󰙎";
}

.mdi-instagram:before {
  content: "󰋾";
}

.mdi-instrument-triangle:before {
  content: "󱁎";
}

.mdi-invert-colors:before {
  content: "󰌁";
}

.mdi-invert-colors-off:before {
  content: "󰹊";
}

.mdi-iobroker:before {
  content: "󱋨";
}

.mdi-ip:before {
  content: "󰩟";
}

.mdi-ip-network:before {
  content: "󰩠";
}

.mdi-ip-network-outline:before {
  content: "󰲐";
}

.mdi-ipod:before {
  content: "󰲑";
}

.mdi-islam:before {
  content: "󰥹";
}

.mdi-island:before {
  content: "󱁏";
}

.mdi-iv-bag:before {
  content: "󱂹";
}

.mdi-jabber:before {
  content: "󰷕";
}

.mdi-jeepney:before {
  content: "󰌂";
}

.mdi-jellyfish:before {
  content: "󰼁";
}

.mdi-jellyfish-outline:before {
  content: "󰼂";
}

.mdi-jira:before {
  content: "󰌃";
}

.mdi-jquery:before {
  content: "󰡽";
}

.mdi-jsfiddle:before {
  content: "󰌄";
}

.mdi-judaism:before {
  content: "󰥺";
}

.mdi-jump-rope:before {
  content: "󱋿";
}

.mdi-kabaddi:before {
  content: "󰶇";
}

.mdi-kangaroo:before {
  content: "󱕘";
}

.mdi-karate:before {
  content: "󰠬";
}

.mdi-keg:before {
  content: "󰌅";
}

.mdi-kettle:before {
  content: "󰗺";
}

.mdi-kettle-alert:before {
  content: "󱌗";
}

.mdi-kettle-alert-outline:before {
  content: "󱌘";
}

.mdi-kettle-off:before {
  content: "󱌛";
}

.mdi-kettle-off-outline:before {
  content: "󱌜";
}

.mdi-kettle-outline:before {
  content: "󰽖";
}

.mdi-kettle-pour-over:before {
  content: "󱜼";
}

.mdi-kettle-steam:before {
  content: "󱌙";
}

.mdi-kettle-steam-outline:before {
  content: "󱌚";
}

.mdi-kettlebell:before {
  content: "󱌀";
}

.mdi-key:before {
  content: "󰌆";
}

.mdi-key-arrow-right:before {
  content: "󱌒";
}

.mdi-key-chain:before {
  content: "󱕴";
}

.mdi-key-chain-variant:before {
  content: "󱕵";
}

.mdi-key-change:before {
  content: "󰌇";
}

.mdi-key-link:before {
  content: "󱆟";
}

.mdi-key-minus:before {
  content: "󰌈";
}

.mdi-key-outline:before {
  content: "󰷖";
}

.mdi-key-plus:before {
  content: "󰌉";
}

.mdi-key-remove:before {
  content: "󰌊";
}

.mdi-key-star:before {
  content: "󱆞";
}

.mdi-key-variant:before {
  content: "󰌋";
}

.mdi-key-wireless:before {
  content: "󰿂";
}

.mdi-keyboard:before {
  content: "󰌌";
}

.mdi-keyboard-backspace:before {
  content: "󰌍";
}

.mdi-keyboard-caps:before {
  content: "󰌎";
}

.mdi-keyboard-close:before {
  content: "󰌏";
}

.mdi-keyboard-esc:before {
  content: "󱊷";
}

.mdi-keyboard-f1:before {
  content: "󱊫";
}

.mdi-keyboard-f10:before {
  content: "󱊴";
}

.mdi-keyboard-f11:before {
  content: "󱊵";
}

.mdi-keyboard-f12:before {
  content: "󱊶";
}

.mdi-keyboard-f2:before {
  content: "󱊬";
}

.mdi-keyboard-f3:before {
  content: "󱊭";
}

.mdi-keyboard-f4:before {
  content: "󱊮";
}

.mdi-keyboard-f5:before {
  content: "󱊯";
}

.mdi-keyboard-f6:before {
  content: "󱊰";
}

.mdi-keyboard-f7:before {
  content: "󱊱";
}

.mdi-keyboard-f8:before {
  content: "󱊲";
}

.mdi-keyboard-f9:before {
  content: "󱊳";
}

.mdi-keyboard-off:before {
  content: "󰌐";
}

.mdi-keyboard-off-outline:before {
  content: "󰹋";
}

.mdi-keyboard-outline:before {
  content: "󰥻";
}

.mdi-keyboard-return:before {
  content: "󰌑";
}

.mdi-keyboard-settings:before {
  content: "󰧹";
}

.mdi-keyboard-settings-outline:before {
  content: "󰧺";
}

.mdi-keyboard-space:before {
  content: "󱁐";
}

.mdi-keyboard-tab:before {
  content: "󰌒";
}

.mdi-keyboard-variant:before {
  content: "󰌓";
}

.mdi-khanda:before {
  content: "󱃽";
}

.mdi-kickstarter:before {
  content: "󰝅";
}

.mdi-klingon:before {
  content: "󱍛";
}

.mdi-knife:before {
  content: "󰧻";
}

.mdi-knife-military:before {
  content: "󰧼";
}

.mdi-koala:before {
  content: "󱜿";
}

.mdi-kodi:before {
  content: "󰌔";
}

.mdi-kubernetes:before {
  content: "󱃾";
}

.mdi-label:before {
  content: "󰌕";
}

.mdi-label-multiple:before {
  content: "󱍵";
}

.mdi-label-multiple-outline:before {
  content: "󱍶";
}

.mdi-label-off:before {
  content: "󰫋";
}

.mdi-label-off-outline:before {
  content: "󰫌";
}

.mdi-label-outline:before {
  content: "󰌖";
}

.mdi-label-percent:before {
  content: "󱋪";
}

.mdi-label-percent-outline:before {
  content: "󱋫";
}

.mdi-label-variant:before {
  content: "󰫍";
}

.mdi-label-variant-outline:before {
  content: "󰫎";
}

.mdi-ladder:before {
  content: "󱖢";
}

.mdi-ladybug:before {
  content: "󰠭";
}

.mdi-lambda:before {
  content: "󰘧";
}

.mdi-lamp:before {
  content: "󰚵";
}

.mdi-lamps:before {
  content: "󱕶";
}

.mdi-lan:before {
  content: "󰌗";
}

.mdi-lan-check:before {
  content: "󱊪";
}

.mdi-lan-connect:before {
  content: "󰌘";
}

.mdi-lan-disconnect:before {
  content: "󰌙";
}

.mdi-lan-pending:before {
  content: "󰌚";
}

.mdi-language-c:before {
  content: "󰙱";
}

.mdi-language-cpp:before {
  content: "󰙲";
}

.mdi-language-csharp:before {
  content: "󰌛";
}

.mdi-language-css3:before {
  content: "󰌜";
}

.mdi-language-fortran:before {
  content: "󱈚";
}

.mdi-language-go:before {
  content: "󰟓";
}

.mdi-language-haskell:before {
  content: "󰲒";
}

.mdi-language-html5:before {
  content: "󰌝";
}

.mdi-language-java:before {
  content: "󰬷";
}

.mdi-language-javascript:before {
  content: "󰌞";
}

.mdi-language-kotlin:before {
  content: "󱈙";
}

.mdi-language-lua:before {
  content: "󰢱";
}

.mdi-language-markdown:before {
  content: "󰍔";
}

.mdi-language-markdown-outline:before {
  content: "󰽛";
}

.mdi-language-php:before {
  content: "󰌟";
}

.mdi-language-python:before {
  content: "󰌠";
}

.mdi-language-r:before {
  content: "󰟔";
}

.mdi-language-ruby:before {
  content: "󰴭";
}

.mdi-language-ruby-on-rails:before {
  content: "󰫏";
}

.mdi-language-rust:before {
  content: "󱘗";
}

.mdi-language-swift:before {
  content: "󰛥";
}

.mdi-language-typescript:before {
  content: "󰛦";
}

.mdi-language-xaml:before {
  content: "󰙳";
}

.mdi-laptop:before {
  content: "󰌢";
}

.mdi-laptop-chromebook:before {
  content: "󰌣";
}

.mdi-laptop-mac:before {
  content: "󰌤";
}

.mdi-laptop-off:before {
  content: "󰛧";
}

.mdi-laptop-windows:before {
  content: "󰌥";
}

.mdi-laravel:before {
  content: "󰫐";
}

.mdi-laser-pointer:before {
  content: "󱒄";
}

.mdi-lasso:before {
  content: "󰼃";
}

.mdi-lastpass:before {
  content: "󰑆";
}

.mdi-latitude:before {
  content: "󰽗";
}

.mdi-launch:before {
  content: "󰌧";
}

.mdi-lava-lamp:before {
  content: "󰟕";
}

.mdi-layers:before {
  content: "󰌨";
}

.mdi-layers-minus:before {
  content: "󰹌";
}

.mdi-layers-off:before {
  content: "󰌩";
}

.mdi-layers-off-outline:before {
  content: "󰧽";
}

.mdi-layers-outline:before {
  content: "󰧾";
}

.mdi-layers-plus:before {
  content: "󰹍";
}

.mdi-layers-remove:before {
  content: "󰹎";
}

.mdi-layers-search:before {
  content: "󱈆";
}

.mdi-layers-search-outline:before {
  content: "󱈇";
}

.mdi-layers-triple:before {
  content: "󰽘";
}

.mdi-layers-triple-outline:before {
  content: "󰽙";
}

.mdi-lead-pencil:before {
  content: "󰙏";
}

.mdi-leaf:before {
  content: "󰌪";
}

.mdi-leaf-maple:before {
  content: "󰲓";
}

.mdi-leaf-maple-off:before {
  content: "󱋚";
}

.mdi-leaf-off:before {
  content: "󱋙";
}

.mdi-leak:before {
  content: "󰷗";
}

.mdi-leak-off:before {
  content: "󰷘";
}

.mdi-led-off:before {
  content: "󰌫";
}

.mdi-led-on:before {
  content: "󰌬";
}

.mdi-led-outline:before {
  content: "󰌭";
}

.mdi-led-strip:before {
  content: "󰟖";
}

.mdi-led-strip-variant:before {
  content: "󱁑";
}

.mdi-led-variant-off:before {
  content: "󰌮";
}

.mdi-led-variant-on:before {
  content: "󰌯";
}

.mdi-led-variant-outline:before {
  content: "󰌰";
}

.mdi-leek:before {
  content: "󱅽";
}

.mdi-less-than:before {
  content: "󰥼";
}

.mdi-less-than-or-equal:before {
  content: "󰥽";
}

.mdi-library:before {
  content: "󰌱";
}

.mdi-library-shelves:before {
  content: "󰮩";
}

.mdi-license:before {
  content: "󰿃";
}

.mdi-lifebuoy:before {
  content: "󰡾";
}

.mdi-light-switch:before {
  content: "󰥾";
}

.mdi-lightbulb:before {
  content: "󰌵";
}

.mdi-lightbulb-cfl:before {
  content: "󱈈";
}

.mdi-lightbulb-cfl-off:before {
  content: "󱈉";
}

.mdi-lightbulb-cfl-spiral:before {
  content: "󱉵";
}

.mdi-lightbulb-cfl-spiral-off:before {
  content: "󱋃";
}

.mdi-lightbulb-group:before {
  content: "󱉓";
}

.mdi-lightbulb-group-off:before {
  content: "󱋍";
}

.mdi-lightbulb-group-off-outline:before {
  content: "󱋎";
}

.mdi-lightbulb-group-outline:before {
  content: "󱉔";
}

.mdi-lightbulb-multiple:before {
  content: "󱉕";
}

.mdi-lightbulb-multiple-off:before {
  content: "󱋏";
}

.mdi-lightbulb-multiple-off-outline:before {
  content: "󱋐";
}

.mdi-lightbulb-multiple-outline:before {
  content: "󱉖";
}

.mdi-lightbulb-off:before {
  content: "󰹏";
}

.mdi-lightbulb-off-outline:before {
  content: "󰹐";
}

.mdi-lightbulb-on:before {
  content: "󰛨";
}

.mdi-lightbulb-on-outline:before {
  content: "󰛩";
}

.mdi-lightbulb-outline:before {
  content: "󰌶";
}

.mdi-lighthouse:before {
  content: "󰧿";
}

.mdi-lighthouse-on:before {
  content: "󰨀";
}

.mdi-lightning-bolt:before {
  content: "󱐋";
}

.mdi-lightning-bolt-outline:before {
  content: "󱐌";
}

.mdi-lingerie:before {
  content: "󱑶";
}

.mdi-link:before {
  content: "󰌷";
}

.mdi-link-box:before {
  content: "󰴚";
}

.mdi-link-box-outline:before {
  content: "󰴛";
}

.mdi-link-box-variant:before {
  content: "󰴜";
}

.mdi-link-box-variant-outline:before {
  content: "󰴝";
}

.mdi-link-lock:before {
  content: "󱂺";
}

.mdi-link-off:before {
  content: "󰌸";
}

.mdi-link-plus:before {
  content: "󰲔";
}

.mdi-link-variant:before {
  content: "󰌹";
}

.mdi-link-variant-minus:before {
  content: "󱃿";
}

.mdi-link-variant-off:before {
  content: "󰌺";
}

.mdi-link-variant-plus:before {
  content: "󱄀";
}

.mdi-link-variant-remove:before {
  content: "󱄁";
}

.mdi-linkedin:before {
  content: "󰌻";
}

.mdi-linux:before {
  content: "󰌽";
}

.mdi-linux-mint:before {
  content: "󰣭";
}

.mdi-lipstick:before {
  content: "󱎵";
}

.mdi-list-status:before {
  content: "󱖫";
}

.mdi-litecoin:before {
  content: "󰩡";
}

.mdi-loading:before {
  content: "󰝲";
}

.mdi-location-enter:before {
  content: "󰿄";
}

.mdi-location-exit:before {
  content: "󰿅";
}

.mdi-lock:before {
  content: "󰌾";
}

.mdi-lock-alert:before {
  content: "󰣮";
}

.mdi-lock-alert-outline:before {
  content: "󱗑";
}

.mdi-lock-check:before {
  content: "󱎚";
}

.mdi-lock-check-outline:before {
  content: "󱚨";
}

.mdi-lock-clock:before {
  content: "󰥿";
}

.mdi-lock-minus:before {
  content: "󱚩";
}

.mdi-lock-minus-outline:before {
  content: "󱚪";
}

.mdi-lock-off:before {
  content: "󱙱";
}

.mdi-lock-off-outline:before {
  content: "󱙲";
}

.mdi-lock-open:before {
  content: "󰌿";
}

.mdi-lock-open-alert:before {
  content: "󱎛";
}

.mdi-lock-open-alert-outline:before {
  content: "󱗒";
}

.mdi-lock-open-check:before {
  content: "󱎜";
}

.mdi-lock-open-check-outline:before {
  content: "󱚫";
}

.mdi-lock-open-minus:before {
  content: "󱚬";
}

.mdi-lock-open-minus-outline:before {
  content: "󱚭";
}

.mdi-lock-open-outline:before {
  content: "󰍀";
}

.mdi-lock-open-plus:before {
  content: "󱚮";
}

.mdi-lock-open-plus-outline:before {
  content: "󱚯";
}

.mdi-lock-open-remove:before {
  content: "󱚰";
}

.mdi-lock-open-remove-outline:before {
  content: "󱚱";
}

.mdi-lock-open-variant:before {
  content: "󰿆";
}

.mdi-lock-open-variant-outline:before {
  content: "󰿇";
}

.mdi-lock-outline:before {
  content: "󰍁";
}

.mdi-lock-pattern:before {
  content: "󰛪";
}

.mdi-lock-plus:before {
  content: "󰗻";
}

.mdi-lock-plus-outline:before {
  content: "󱚲";
}

.mdi-lock-question:before {
  content: "󰣯";
}

.mdi-lock-remove:before {
  content: "󱚳";
}

.mdi-lock-remove-outline:before {
  content: "󱚴";
}

.mdi-lock-reset:before {
  content: "󰝳";
}

.mdi-lock-smart:before {
  content: "󰢲";
}

.mdi-locker:before {
  content: "󰟗";
}

.mdi-locker-multiple:before {
  content: "󰟘";
}

.mdi-login:before {
  content: "󰍂";
}

.mdi-login-variant:before {
  content: "󰗼";
}

.mdi-logout:before {
  content: "󰍃";
}

.mdi-logout-variant:before {
  content: "󰗽";
}

.mdi-longitude:before {
  content: "󰽚";
}

.mdi-looks:before {
  content: "󰍄";
}

.mdi-lotion:before {
  content: "󱖂";
}

.mdi-lotion-outline:before {
  content: "󱖃";
}

.mdi-lotion-plus:before {
  content: "󱖄";
}

.mdi-lotion-plus-outline:before {
  content: "󱖅";
}

.mdi-loupe:before {
  content: "󰍅";
}

.mdi-lumx:before {
  content: "󰍆";
}

.mdi-lungs:before {
  content: "󱂄";
}

.mdi-magnet:before {
  content: "󰍇";
}

.mdi-magnet-on:before {
  content: "󰍈";
}

.mdi-magnify:before {
  content: "󰍉";
}

.mdi-magnify-close:before {
  content: "󰦀";
}

.mdi-magnify-minus:before {
  content: "󰍊";
}

.mdi-magnify-minus-cursor:before {
  content: "󰩢";
}

.mdi-magnify-minus-outline:before {
  content: "󰛬";
}

.mdi-magnify-plus:before {
  content: "󰍋";
}

.mdi-magnify-plus-cursor:before {
  content: "󰩣";
}

.mdi-magnify-plus-outline:before {
  content: "󰛭";
}

.mdi-magnify-remove-cursor:before {
  content: "󱈌";
}

.mdi-magnify-remove-outline:before {
  content: "󱈍";
}

.mdi-magnify-scan:before {
  content: "󱉶";
}

.mdi-mail:before {
  content: "󰺻";
}

.mdi-mailbox:before {
  content: "󰛮";
}

.mdi-mailbox-open:before {
  content: "󰶈";
}

.mdi-mailbox-open-outline:before {
  content: "󰶉";
}

.mdi-mailbox-open-up:before {
  content: "󰶊";
}

.mdi-mailbox-open-up-outline:before {
  content: "󰶋";
}

.mdi-mailbox-outline:before {
  content: "󰶌";
}

.mdi-mailbox-up:before {
  content: "󰶍";
}

.mdi-mailbox-up-outline:before {
  content: "󰶎";
}

.mdi-manjaro:before {
  content: "󱘊";
}

.mdi-map:before {
  content: "󰍍";
}

.mdi-map-check:before {
  content: "󰺼";
}

.mdi-map-check-outline:before {
  content: "󰺽";
}

.mdi-map-clock:before {
  content: "󰴞";
}

.mdi-map-clock-outline:before {
  content: "󰴟";
}

.mdi-map-legend:before {
  content: "󰨁";
}

.mdi-map-marker:before {
  content: "󰍎";
}

.mdi-map-marker-alert:before {
  content: "󰼅";
}

.mdi-map-marker-alert-outline:before {
  content: "󰼆";
}

.mdi-map-marker-check:before {
  content: "󰲕";
}

.mdi-map-marker-check-outline:before {
  content: "󱋻";
}

.mdi-map-marker-circle:before {
  content: "󰍏";
}

.mdi-map-marker-distance:before {
  content: "󰣰";
}

.mdi-map-marker-down:before {
  content: "󱄂";
}

.mdi-map-marker-left:before {
  content: "󱋛";
}

.mdi-map-marker-left-outline:before {
  content: "󱋝";
}

.mdi-map-marker-minus:before {
  content: "󰙐";
}

.mdi-map-marker-minus-outline:before {
  content: "󱋹";
}

.mdi-map-marker-multiple:before {
  content: "󰍐";
}

.mdi-map-marker-multiple-outline:before {
  content: "󱉷";
}

.mdi-map-marker-off:before {
  content: "󰍑";
}

.mdi-map-marker-off-outline:before {
  content: "󱋽";
}

.mdi-map-marker-outline:before {
  content: "󰟙";
}

.mdi-map-marker-path:before {
  content: "󰴠";
}

.mdi-map-marker-plus:before {
  content: "󰙑";
}

.mdi-map-marker-plus-outline:before {
  content: "󱋸";
}

.mdi-map-marker-question:before {
  content: "󰼇";
}

.mdi-map-marker-question-outline:before {
  content: "󰼈";
}

.mdi-map-marker-radius:before {
  content: "󰍒";
}

.mdi-map-marker-radius-outline:before {
  content: "󱋼";
}

.mdi-map-marker-remove:before {
  content: "󰼉";
}

.mdi-map-marker-remove-outline:before {
  content: "󱋺";
}

.mdi-map-marker-remove-variant:before {
  content: "󰼊";
}

.mdi-map-marker-right:before {
  content: "󱋜";
}

.mdi-map-marker-right-outline:before {
  content: "󱋞";
}

.mdi-map-marker-star:before {
  content: "󱘈";
}

.mdi-map-marker-star-outline:before {
  content: "󱘉";
}

.mdi-map-marker-up:before {
  content: "󱄃";
}

.mdi-map-minus:before {
  content: "󰦁";
}

.mdi-map-outline:before {
  content: "󰦂";
}

.mdi-map-plus:before {
  content: "󰦃";
}

.mdi-map-search:before {
  content: "󰦄";
}

.mdi-map-search-outline:before {
  content: "󰦅";
}

.mdi-mapbox:before {
  content: "󰮪";
}

.mdi-margin:before {
  content: "󰍓";
}

.mdi-marker:before {
  content: "󰙒";
}

.mdi-marker-cancel:before {
  content: "󰷙";
}

.mdi-marker-check:before {
  content: "󰍕";
}

.mdi-mastodon:before {
  content: "󰫑";
}

.mdi-material-design:before {
  content: "󰦆";
}

.mdi-material-ui:before {
  content: "󰍗";
}

.mdi-math-compass:before {
  content: "󰍘";
}

.mdi-math-cos:before {
  content: "󰲖";
}

.mdi-math-integral:before {
  content: "󰿈";
}

.mdi-math-integral-box:before {
  content: "󰿉";
}

.mdi-math-log:before {
  content: "󱂅";
}

.mdi-math-norm:before {
  content: "󰿊";
}

.mdi-math-norm-box:before {
  content: "󰿋";
}

.mdi-math-sin:before {
  content: "󰲗";
}

.mdi-math-tan:before {
  content: "󰲘";
}

.mdi-matrix:before {
  content: "󰘨";
}

.mdi-medal:before {
  content: "󰦇";
}

.mdi-medal-outline:before {
  content: "󱌦";
}

.mdi-medical-bag:before {
  content: "󰛯";
}

.mdi-meditation:before {
  content: "󱅻";
}

.mdi-memory:before {
  content: "󰍛";
}

.mdi-menu:before {
  content: "󰍜";
}

.mdi-menu-down:before {
  content: "󰍝";
}

.mdi-menu-down-outline:before {
  content: "󰚶";
}

.mdi-menu-left:before {
  content: "󰍞";
}

.mdi-menu-left-outline:before {
  content: "󰨂";
}

.mdi-menu-open:before {
  content: "󰮫";
}

.mdi-menu-right:before {
  content: "󰍟";
}

.mdi-menu-right-outline:before {
  content: "󰨃";
}

.mdi-menu-swap:before {
  content: "󰩤";
}

.mdi-menu-swap-outline:before {
  content: "󰩥";
}

.mdi-menu-up:before {
  content: "󰍠";
}

.mdi-menu-up-outline:before {
  content: "󰚷";
}

.mdi-merge:before {
  content: "󰽜";
}

.mdi-message:before {
  content: "󰍡";
}

.mdi-message-alert:before {
  content: "󰍢";
}

.mdi-message-alert-outline:before {
  content: "󰨄";
}

.mdi-message-arrow-left:before {
  content: "󱋲";
}

.mdi-message-arrow-left-outline:before {
  content: "󱋳";
}

.mdi-message-arrow-right:before {
  content: "󱋴";
}

.mdi-message-arrow-right-outline:before {
  content: "󱋵";
}

.mdi-message-bookmark:before {
  content: "󱖬";
}

.mdi-message-bookmark-outline:before {
  content: "󱖭";
}

.mdi-message-bulleted:before {
  content: "󰚢";
}

.mdi-message-bulleted-off:before {
  content: "󰚣";
}

.mdi-message-cog:before {
  content: "󰛱";
}

.mdi-message-cog-outline:before {
  content: "󱅲";
}

.mdi-message-draw:before {
  content: "󰍣";
}

.mdi-message-flash:before {
  content: "󱖩";
}

.mdi-message-flash-outline:before {
  content: "󱖪";
}

.mdi-message-image:before {
  content: "󰍤";
}

.mdi-message-image-outline:before {
  content: "󱅬";
}

.mdi-message-lock:before {
  content: "󰿌";
}

.mdi-message-lock-outline:before {
  content: "󱅭";
}

.mdi-message-minus:before {
  content: "󱅮";
}

.mdi-message-minus-outline:before {
  content: "󱅯";
}

.mdi-message-off:before {
  content: "󱙍";
}

.mdi-message-off-outline:before {
  content: "󱙎";
}

.mdi-message-outline:before {
  content: "󰍥";
}

.mdi-message-plus:before {
  content: "󰙓";
}

.mdi-message-plus-outline:before {
  content: "󱂻";
}

.mdi-message-processing:before {
  content: "󰍦";
}

.mdi-message-processing-outline:before {
  content: "󱅰";
}

.mdi-message-question:before {
  content: "󱜺";
}

.mdi-message-question-outline:before {
  content: "󱜻";
}

.mdi-message-reply:before {
  content: "󰍧";
}

.mdi-message-reply-outline:before {
  content: "󱜽";
}

.mdi-message-reply-text:before {
  content: "󰍨";
}

.mdi-message-reply-text-outline:before {
  content: "󱜾";
}

.mdi-message-settings:before {
  content: "󰛰";
}

.mdi-message-settings-outline:before {
  content: "󱅱";
}

.mdi-message-text:before {
  content: "󰍩";
}

.mdi-message-text-clock:before {
  content: "󱅳";
}

.mdi-message-text-clock-outline:before {
  content: "󱅴";
}

.mdi-message-text-lock:before {
  content: "󰿍";
}

.mdi-message-text-lock-outline:before {
  content: "󱅵";
}

.mdi-message-text-outline:before {
  content: "󰍪";
}

.mdi-message-video:before {
  content: "󰍫";
}

.mdi-meteor:before {
  content: "󰘩";
}

.mdi-metronome:before {
  content: "󰟚";
}

.mdi-metronome-tick:before {
  content: "󰟛";
}

.mdi-micro-sd:before {
  content: "󰟜";
}

.mdi-microphone:before {
  content: "󰍬";
}

.mdi-microphone-minus:before {
  content: "󰢳";
}

.mdi-microphone-off:before {
  content: "󰍭";
}

.mdi-microphone-outline:before {
  content: "󰍮";
}

.mdi-microphone-plus:before {
  content: "󰢴";
}

.mdi-microphone-settings:before {
  content: "󰍯";
}

.mdi-microphone-variant:before {
  content: "󰍰";
}

.mdi-microphone-variant-off:before {
  content: "󰍱";
}

.mdi-microscope:before {
  content: "󰙔";
}

.mdi-microsoft:before {
  content: "󰍲";
}

.mdi-microsoft-access:before {
  content: "󱎎";
}

.mdi-microsoft-azure:before {
  content: "󰠅";
}

.mdi-microsoft-azure-devops:before {
  content: "󰿕";
}

.mdi-microsoft-bing:before {
  content: "󰂤";
}

.mdi-microsoft-dynamics-365:before {
  content: "󰦈";
}

.mdi-microsoft-edge:before {
  content: "󰇩";
}

.mdi-microsoft-edge-legacy:before {
  content: "󱉐";
}

.mdi-microsoft-excel:before {
  content: "󱎏";
}

.mdi-microsoft-internet-explorer:before {
  content: "󰌀";
}

.mdi-microsoft-office:before {
  content: "󰏆";
}

.mdi-microsoft-onedrive:before {
  content: "󰏊";
}

.mdi-microsoft-onenote:before {
  content: "󰝇";
}

.mdi-microsoft-outlook:before {
  content: "󰴢";
}

.mdi-microsoft-powerpoint:before {
  content: "󱎐";
}

.mdi-microsoft-sharepoint:before {
  content: "󱎑";
}

.mdi-microsoft-teams:before {
  content: "󰊻";
}

.mdi-microsoft-visual-studio:before {
  content: "󰘐";
}

.mdi-microsoft-visual-studio-code:before {
  content: "󰨞";
}

.mdi-microsoft-windows:before {
  content: "󰖳";
}

.mdi-microsoft-windows-classic:before {
  content: "󰨡";
}

.mdi-microsoft-word:before {
  content: "󱎒";
}

.mdi-microsoft-xbox:before {
  content: "󰖹";
}

.mdi-microsoft-xbox-controller:before {
  content: "󰖺";
}

.mdi-microsoft-xbox-controller-battery-alert:before {
  content: "󰝋";
}

.mdi-microsoft-xbox-controller-battery-charging:before {
  content: "󰨢";
}

.mdi-microsoft-xbox-controller-battery-empty:before {
  content: "󰝌";
}

.mdi-microsoft-xbox-controller-battery-full:before {
  content: "󰝍";
}

.mdi-microsoft-xbox-controller-battery-low:before {
  content: "󰝎";
}

.mdi-microsoft-xbox-controller-battery-medium:before {
  content: "󰝏";
}

.mdi-microsoft-xbox-controller-battery-unknown:before {
  content: "󰝐";
}

.mdi-microsoft-xbox-controller-menu:before {
  content: "󰹯";
}

.mdi-microsoft-xbox-controller-off:before {
  content: "󰖻";
}

.mdi-microsoft-xbox-controller-view:before {
  content: "󰹰";
}

.mdi-microsoft-yammer:before {
  content: "󰞉";
}

.mdi-microwave:before {
  content: "󰲙";
}

.mdi-microwave-off:before {
  content: "󱐣";
}

.mdi-middleware:before {
  content: "󰽝";
}

.mdi-middleware-outline:before {
  content: "󰽞";
}

.mdi-midi:before {
  content: "󰣱";
}

.mdi-midi-port:before {
  content: "󰣲";
}

.mdi-mine:before {
  content: "󰷚";
}

.mdi-minecraft:before {
  content: "󰍳";
}

.mdi-mini-sd:before {
  content: "󰨅";
}

.mdi-minidisc:before {
  content: "󰨆";
}

.mdi-minus:before {
  content: "󰍴";
}

.mdi-minus-box:before {
  content: "󰍵";
}

.mdi-minus-box-multiple:before {
  content: "󱅁";
}

.mdi-minus-box-multiple-outline:before {
  content: "󱅂";
}

.mdi-minus-box-outline:before {
  content: "󰛲";
}

.mdi-minus-circle:before {
  content: "󰍶";
}

.mdi-minus-circle-multiple:before {
  content: "󰍚";
}

.mdi-minus-circle-multiple-outline:before {
  content: "󰫓";
}

.mdi-minus-circle-off:before {
  content: "󱑙";
}

.mdi-minus-circle-off-outline:before {
  content: "󱑚";
}

.mdi-minus-circle-outline:before {
  content: "󰍷";
}

.mdi-minus-network:before {
  content: "󰍸";
}

.mdi-minus-network-outline:before {
  content: "󰲚";
}

.mdi-minus-thick:before {
  content: "󱘹";
}

.mdi-mirror:before {
  content: "󱇽";
}

.mdi-mixed-martial-arts:before {
  content: "󰶏";
}

.mdi-mixed-reality:before {
  content: "󰡿";
}

.mdi-molecule:before {
  content: "󰮬";
}

.mdi-molecule-co:before {
  content: "󱋾";
}

.mdi-molecule-co2:before {
  content: "󰟤";
}

.mdi-monitor:before {
  content: "󰍹";
}

.mdi-monitor-cellphone:before {
  content: "󰦉";
}

.mdi-monitor-cellphone-star:before {
  content: "󰦊";
}

.mdi-monitor-clean:before {
  content: "󱄄";
}

.mdi-monitor-dashboard:before {
  content: "󰨇";
}

.mdi-monitor-edit:before {
  content: "󱋆";
}

.mdi-monitor-eye:before {
  content: "󱎴";
}

.mdi-monitor-lock:before {
  content: "󰷛";
}

.mdi-monitor-multiple:before {
  content: "󰍺";
}

.mdi-monitor-off:before {
  content: "󰶐";
}

.mdi-monitor-screenshot:before {
  content: "󰹑";
}

.mdi-monitor-share:before {
  content: "󱒃";
}

.mdi-monitor-speaker:before {
  content: "󰽟";
}

.mdi-monitor-speaker-off:before {
  content: "󰽠";
}

.mdi-monitor-star:before {
  content: "󰷜";
}

.mdi-moon-first-quarter:before {
  content: "󰽡";
}

.mdi-moon-full:before {
  content: "󰽢";
}

.mdi-moon-last-quarter:before {
  content: "󰽣";
}

.mdi-moon-new:before {
  content: "󰽤";
}

.mdi-moon-waning-crescent:before {
  content: "󰽥";
}

.mdi-moon-waning-gibbous:before {
  content: "󰽦";
}

.mdi-moon-waxing-crescent:before {
  content: "󰽧";
}

.mdi-moon-waxing-gibbous:before {
  content: "󰽨";
}

.mdi-moped:before {
  content: "󱂆";
}

.mdi-moped-electric:before {
  content: "󱖷";
}

.mdi-moped-electric-outline:before {
  content: "󱖸";
}

.mdi-moped-outline:before {
  content: "󱖹";
}

.mdi-more:before {
  content: "󰍻";
}

.mdi-mother-heart:before {
  content: "󱌔";
}

.mdi-mother-nurse:before {
  content: "󰴡";
}

.mdi-motion:before {
  content: "󱖲";
}

.mdi-motion-outline:before {
  content: "󱖳";
}

.mdi-motion-pause:before {
  content: "󱖐";
}

.mdi-motion-pause-outline:before {
  content: "󱖒";
}

.mdi-motion-play:before {
  content: "󱖏";
}

.mdi-motion-play-outline:before {
  content: "󱖑";
}

.mdi-motion-sensor:before {
  content: "󰶑";
}

.mdi-motion-sensor-off:before {
  content: "󱐵";
}

.mdi-motorbike:before {
  content: "󰍼";
}

.mdi-motorbike-electric:before {
  content: "󱖺";
}

.mdi-mouse:before {
  content: "󰍽";
}

.mdi-mouse-bluetooth:before {
  content: "󰦋";
}

.mdi-mouse-move-down:before {
  content: "󱕐";
}

.mdi-mouse-move-up:before {
  content: "󱕑";
}

.mdi-mouse-move-vertical:before {
  content: "󱕒";
}

.mdi-mouse-off:before {
  content: "󰍾";
}

.mdi-mouse-variant:before {
  content: "󰍿";
}

.mdi-mouse-variant-off:before {
  content: "󰎀";
}

.mdi-move-resize:before {
  content: "󰙕";
}

.mdi-move-resize-variant:before {
  content: "󰙖";
}

.mdi-movie:before {
  content: "󰎁";
}

.mdi-movie-check:before {
  content: "󱛳";
}

.mdi-movie-check-outline:before {
  content: "󱛴";
}

.mdi-movie-cog:before {
  content: "󱛵";
}

.mdi-movie-cog-outline:before {
  content: "󱛶";
}

.mdi-movie-edit:before {
  content: "󱄢";
}

.mdi-movie-edit-outline:before {
  content: "󱄣";
}

.mdi-movie-filter:before {
  content: "󱄤";
}

.mdi-movie-filter-outline:before {
  content: "󱄥";
}

.mdi-movie-minus:before {
  content: "󱛷";
}

.mdi-movie-minus-outline:before {
  content: "󱛸";
}

.mdi-movie-off:before {
  content: "󱛹";
}

.mdi-movie-off-outline:before {
  content: "󱛺";
}

.mdi-movie-open:before {
  content: "󰿎";
}

.mdi-movie-open-check:before {
  content: "󱛻";
}

.mdi-movie-open-check-outline:before {
  content: "󱛼";
}

.mdi-movie-open-cog:before {
  content: "󱛽";
}

.mdi-movie-open-cog-outline:before {
  content: "󱛾";
}

.mdi-movie-open-edit:before {
  content: "󱛿";
}

.mdi-movie-open-edit-outline:before {
  content: "󱜀";
}

.mdi-movie-open-minus:before {
  content: "󱜁";
}

.mdi-movie-open-minus-outline:before {
  content: "󱜂";
}

.mdi-movie-open-off:before {
  content: "󱜃";
}

.mdi-movie-open-off-outline:before {
  content: "󱜄";
}

.mdi-movie-open-outline:before {
  content: "󰿏";
}

.mdi-movie-open-play:before {
  content: "󱜅";
}

.mdi-movie-open-play-outline:before {
  content: "󱜆";
}

.mdi-movie-open-plus:before {
  content: "󱜇";
}

.mdi-movie-open-plus-outline:before {
  content: "󱜈";
}

.mdi-movie-open-remove:before {
  content: "󱜉";
}

.mdi-movie-open-remove-outline:before {
  content: "󱜊";
}

.mdi-movie-open-settings:before {
  content: "󱜋";
}

.mdi-movie-open-settings-outline:before {
  content: "󱜌";
}

.mdi-movie-open-star:before {
  content: "󱜍";
}

.mdi-movie-open-star-outline:before {
  content: "󱜎";
}

.mdi-movie-outline:before {
  content: "󰷝";
}

.mdi-movie-play:before {
  content: "󱜏";
}

.mdi-movie-play-outline:before {
  content: "󱜐";
}

.mdi-movie-plus:before {
  content: "󱜑";
}

.mdi-movie-plus-outline:before {
  content: "󱜒";
}

.mdi-movie-remove:before {
  content: "󱜓";
}

.mdi-movie-remove-outline:before {
  content: "󱜔";
}

.mdi-movie-roll:before {
  content: "󰟞";
}

.mdi-movie-search:before {
  content: "󱇒";
}

.mdi-movie-search-outline:before {
  content: "󱇓";
}

.mdi-movie-settings:before {
  content: "󱜕";
}

.mdi-movie-settings-outline:before {
  content: "󱜖";
}

.mdi-movie-star:before {
  content: "󱜗";
}

.mdi-movie-star-outline:before {
  content: "󱜘";
}

.mdi-mower:before {
  content: "󱙯";
}

.mdi-mower-bag:before {
  content: "󱙰";
}

.mdi-muffin:before {
  content: "󰦌";
}

.mdi-multiplication:before {
  content: "󰎂";
}

.mdi-multiplication-box:before {
  content: "󰎃";
}

.mdi-mushroom:before {
  content: "󰟟";
}

.mdi-mushroom-off:before {
  content: "󱏺";
}

.mdi-mushroom-off-outline:before {
  content: "󱏻";
}

.mdi-mushroom-outline:before {
  content: "󰟠";
}

.mdi-music:before {
  content: "󰝚";
}

.mdi-music-accidental-double-flat:before {
  content: "󰽩";
}

.mdi-music-accidental-double-sharp:before {
  content: "󰽪";
}

.mdi-music-accidental-flat:before {
  content: "󰽫";
}

.mdi-music-accidental-natural:before {
  content: "󰽬";
}

.mdi-music-accidental-sharp:before {
  content: "󰽭";
}

.mdi-music-box:before {
  content: "󰎄";
}

.mdi-music-box-multiple:before {
  content: "󰌳";
}

.mdi-music-box-multiple-outline:before {
  content: "󰼄";
}

.mdi-music-box-outline:before {
  content: "󰎅";
}

.mdi-music-circle:before {
  content: "󰎆";
}

.mdi-music-circle-outline:before {
  content: "󰫔";
}

.mdi-music-clef-alto:before {
  content: "󰽮";
}

.mdi-music-clef-bass:before {
  content: "󰽯";
}

.mdi-music-clef-treble:before {
  content: "󰽰";
}

.mdi-music-note:before {
  content: "󰎇";
}

.mdi-music-note-bluetooth:before {
  content: "󰗾";
}

.mdi-music-note-bluetooth-off:before {
  content: "󰗿";
}

.mdi-music-note-eighth:before {
  content: "󰎈";
}

.mdi-music-note-eighth-dotted:before {
  content: "󰽱";
}

.mdi-music-note-half:before {
  content: "󰎉";
}

.mdi-music-note-half-dotted:before {
  content: "󰽲";
}

.mdi-music-note-off:before {
  content: "󰎊";
}

.mdi-music-note-off-outline:before {
  content: "󰽳";
}

.mdi-music-note-outline:before {
  content: "󰽴";
}

.mdi-music-note-plus:before {
  content: "󰷞";
}

.mdi-music-note-quarter:before {
  content: "󰎋";
}

.mdi-music-note-quarter-dotted:before {
  content: "󰽵";
}

.mdi-music-note-sixteenth:before {
  content: "󰎌";
}

.mdi-music-note-sixteenth-dotted:before {
  content: "󰽶";
}

.mdi-music-note-whole:before {
  content: "󰎍";
}

.mdi-music-note-whole-dotted:before {
  content: "󰽷";
}

.mdi-music-off:before {
  content: "󰝛";
}

.mdi-music-rest-eighth:before {
  content: "󰽸";
}

.mdi-music-rest-half:before {
  content: "󰽹";
}

.mdi-music-rest-quarter:before {
  content: "󰽺";
}

.mdi-music-rest-sixteenth:before {
  content: "󰽻";
}

.mdi-music-rest-whole:before {
  content: "󰽼";
}

.mdi-mustache:before {
  content: "󱗞";
}

.mdi-nail:before {
  content: "󰷟";
}

.mdi-nas:before {
  content: "󰣳";
}

.mdi-nativescript:before {
  content: "󰢀";
}

.mdi-nature:before {
  content: "󰎎";
}

.mdi-nature-people:before {
  content: "󰎏";
}

.mdi-navigation:before {
  content: "󰎐";
}

.mdi-navigation-outline:before {
  content: "󱘇";
}

.mdi-near-me:before {
  content: "󰗍";
}

.mdi-necklace:before {
  content: "󰼋";
}

.mdi-needle:before {
  content: "󰎑";
}

.mdi-netflix:before {
  content: "󰝆";
}

.mdi-network:before {
  content: "󰛳";
}

.mdi-network-off:before {
  content: "󰲛";
}

.mdi-network-off-outline:before {
  content: "󰲜";
}

.mdi-network-outline:before {
  content: "󰲝";
}

.mdi-network-strength-1:before {
  content: "󰣴";
}

.mdi-network-strength-1-alert:before {
  content: "󰣵";
}

.mdi-network-strength-2:before {
  content: "󰣶";
}

.mdi-network-strength-2-alert:before {
  content: "󰣷";
}

.mdi-network-strength-3:before {
  content: "󰣸";
}

.mdi-network-strength-3-alert:before {
  content: "󰣹";
}

.mdi-network-strength-4:before {
  content: "󰣺";
}

.mdi-network-strength-4-alert:before {
  content: "󰣻";
}

.mdi-network-strength-off:before {
  content: "󰣼";
}

.mdi-network-strength-off-outline:before {
  content: "󰣽";
}

.mdi-network-strength-outline:before {
  content: "󰣾";
}

.mdi-new-box:before {
  content: "󰎔";
}

.mdi-newspaper:before {
  content: "󰎕";
}

.mdi-newspaper-minus:before {
  content: "󰼌";
}

.mdi-newspaper-plus:before {
  content: "󰼍";
}

.mdi-newspaper-variant:before {
  content: "󱀁";
}

.mdi-newspaper-variant-multiple:before {
  content: "󱀂";
}

.mdi-newspaper-variant-multiple-outline:before {
  content: "󱀃";
}

.mdi-newspaper-variant-outline:before {
  content: "󱀄";
}

.mdi-nfc:before {
  content: "󰎖";
}

.mdi-nfc-search-variant:before {
  content: "󰹓";
}

.mdi-nfc-tap:before {
  content: "󰎗";
}

.mdi-nfc-variant:before {
  content: "󰎘";
}

.mdi-nfc-variant-off:before {
  content: "󰹔";
}

.mdi-ninja:before {
  content: "󰝴";
}

.mdi-nintendo-game-boy:before {
  content: "󱎓";
}

.mdi-nintendo-switch:before {
  content: "󰟡";
}

.mdi-nintendo-wii:before {
  content: "󰖫";
}

.mdi-nintendo-wiiu:before {
  content: "󰜭";
}

.mdi-nix:before {
  content: "󱄅";
}

.mdi-nodejs:before {
  content: "󰎙";
}

.mdi-noodles:before {
  content: "󱅾";
}

.mdi-not-equal:before {
  content: "󰦍";
}

.mdi-not-equal-variant:before {
  content: "󰦎";
}

.mdi-note:before {
  content: "󰎚";
}

.mdi-note-minus:before {
  content: "󱙏";
}

.mdi-note-minus-outline:before {
  content: "󱙐";
}

.mdi-note-multiple:before {
  content: "󰚸";
}

.mdi-note-multiple-outline:before {
  content: "󰚹";
}

.mdi-note-outline:before {
  content: "󰎛";
}

.mdi-note-plus:before {
  content: "󰎜";
}

.mdi-note-plus-outline:before {
  content: "󰎝";
}

.mdi-note-remove:before {
  content: "󱙑";
}

.mdi-note-remove-outline:before {
  content: "󱙒";
}

.mdi-note-search:before {
  content: "󱙓";
}

.mdi-note-search-outline:before {
  content: "󱙔";
}

.mdi-note-text:before {
  content: "󰎞";
}

.mdi-note-text-outline:before {
  content: "󱇗";
}

.mdi-notebook:before {
  content: "󰠮";
}

.mdi-notebook-check:before {
  content: "󱓵";
}

.mdi-notebook-check-outline:before {
  content: "󱓶";
}

.mdi-notebook-edit:before {
  content: "󱓧";
}

.mdi-notebook-edit-outline:before {
  content: "󱓩";
}

.mdi-notebook-minus:before {
  content: "󱘐";
}

.mdi-notebook-minus-outline:before {
  content: "󱘑";
}

.mdi-notebook-multiple:before {
  content: "󰹕";
}

.mdi-notebook-outline:before {
  content: "󰺿";
}

.mdi-notebook-plus:before {
  content: "󱘒";
}

.mdi-notebook-plus-outline:before {
  content: "󱘓";
}

.mdi-notebook-remove:before {
  content: "󱘔";
}

.mdi-notebook-remove-outline:before {
  content: "󱘕";
}

.mdi-notification-clear-all:before {
  content: "󰎟";
}

.mdi-npm:before {
  content: "󰛷";
}

.mdi-nuke:before {
  content: "󰚤";
}

.mdi-null:before {
  content: "󰟢";
}

.mdi-numeric:before {
  content: "󰎠";
}

.mdi-numeric-0:before {
  content: "󰬹";
}

.mdi-numeric-0-box:before {
  content: "󰎡";
}

.mdi-numeric-0-box-multiple:before {
  content: "󰼎";
}

.mdi-numeric-0-box-multiple-outline:before {
  content: "󰎢";
}

.mdi-numeric-0-box-outline:before {
  content: "󰎣";
}

.mdi-numeric-0-circle:before {
  content: "󰲞";
}

.mdi-numeric-0-circle-outline:before {
  content: "󰲟";
}

.mdi-numeric-1:before {
  content: "󰬺";
}

.mdi-numeric-1-box:before {
  content: "󰎤";
}

.mdi-numeric-1-box-multiple:before {
  content: "󰼏";
}

.mdi-numeric-1-box-multiple-outline:before {
  content: "󰎥";
}

.mdi-numeric-1-box-outline:before {
  content: "󰎦";
}

.mdi-numeric-1-circle:before {
  content: "󰲠";
}

.mdi-numeric-1-circle-outline:before {
  content: "󰲡";
}

.mdi-numeric-10:before {
  content: "󰿩";
}

.mdi-numeric-10-box:before {
  content: "󰽽";
}

.mdi-numeric-10-box-multiple:before {
  content: "󰿪";
}

.mdi-numeric-10-box-multiple-outline:before {
  content: "󰿫";
}

.mdi-numeric-10-box-outline:before {
  content: "󰽾";
}

.mdi-numeric-10-circle:before {
  content: "󰿬";
}

.mdi-numeric-10-circle-outline:before {
  content: "󰿭";
}

.mdi-numeric-2:before {
  content: "󰬻";
}

.mdi-numeric-2-box:before {
  content: "󰎧";
}

.mdi-numeric-2-box-multiple:before {
  content: "󰼐";
}

.mdi-numeric-2-box-multiple-outline:before {
  content: "󰎨";
}

.mdi-numeric-2-box-outline:before {
  content: "󰎩";
}

.mdi-numeric-2-circle:before {
  content: "󰲢";
}

.mdi-numeric-2-circle-outline:before {
  content: "󰲣";
}

.mdi-numeric-3:before {
  content: "󰬼";
}

.mdi-numeric-3-box:before {
  content: "󰎪";
}

.mdi-numeric-3-box-multiple:before {
  content: "󰼑";
}

.mdi-numeric-3-box-multiple-outline:before {
  content: "󰎫";
}

.mdi-numeric-3-box-outline:before {
  content: "󰎬";
}

.mdi-numeric-3-circle:before {
  content: "󰲤";
}

.mdi-numeric-3-circle-outline:before {
  content: "󰲥";
}

.mdi-numeric-4:before {
  content: "󰬽";
}

.mdi-numeric-4-box:before {
  content: "󰎭";
}

.mdi-numeric-4-box-multiple:before {
  content: "󰼒";
}

.mdi-numeric-4-box-multiple-outline:before {
  content: "󰎲";
}

.mdi-numeric-4-box-outline:before {
  content: "󰎮";
}

.mdi-numeric-4-circle:before {
  content: "󰲦";
}

.mdi-numeric-4-circle-outline:before {
  content: "󰲧";
}

.mdi-numeric-5:before {
  content: "󰬾";
}

.mdi-numeric-5-box:before {
  content: "󰎱";
}

.mdi-numeric-5-box-multiple:before {
  content: "󰼓";
}

.mdi-numeric-5-box-multiple-outline:before {
  content: "󰎯";
}

.mdi-numeric-5-box-outline:before {
  content: "󰎰";
}

.mdi-numeric-5-circle:before {
  content: "󰲨";
}

.mdi-numeric-5-circle-outline:before {
  content: "󰲩";
}

.mdi-numeric-6:before {
  content: "󰬿";
}

.mdi-numeric-6-box:before {
  content: "󰎳";
}

.mdi-numeric-6-box-multiple:before {
  content: "󰼔";
}

.mdi-numeric-6-box-multiple-outline:before {
  content: "󰎴";
}

.mdi-numeric-6-box-outline:before {
  content: "󰎵";
}

.mdi-numeric-6-circle:before {
  content: "󰲪";
}

.mdi-numeric-6-circle-outline:before {
  content: "󰲫";
}

.mdi-numeric-7:before {
  content: "󰭀";
}

.mdi-numeric-7-box:before {
  content: "󰎶";
}

.mdi-numeric-7-box-multiple:before {
  content: "󰼕";
}

.mdi-numeric-7-box-multiple-outline:before {
  content: "󰎷";
}

.mdi-numeric-7-box-outline:before {
  content: "󰎸";
}

.mdi-numeric-7-circle:before {
  content: "󰲬";
}

.mdi-numeric-7-circle-outline:before {
  content: "󰲭";
}

.mdi-numeric-8:before {
  content: "󰭁";
}

.mdi-numeric-8-box:before {
  content: "󰎹";
}

.mdi-numeric-8-box-multiple:before {
  content: "󰼖";
}

.mdi-numeric-8-box-multiple-outline:before {
  content: "󰎺";
}

.mdi-numeric-8-box-outline:before {
  content: "󰎻";
}

.mdi-numeric-8-circle:before {
  content: "󰲮";
}

.mdi-numeric-8-circle-outline:before {
  content: "󰲯";
}

.mdi-numeric-9:before {
  content: "󰭂";
}

.mdi-numeric-9-box:before {
  content: "󰎼";
}

.mdi-numeric-9-box-multiple:before {
  content: "󰼗";
}

.mdi-numeric-9-box-multiple-outline:before {
  content: "󰎽";
}

.mdi-numeric-9-box-outline:before {
  content: "󰎾";
}

.mdi-numeric-9-circle:before {
  content: "󰲰";
}

.mdi-numeric-9-circle-outline:before {
  content: "󰲱";
}

.mdi-numeric-9-plus:before {
  content: "󰿮";
}

.mdi-numeric-9-plus-box:before {
  content: "󰎿";
}

.mdi-numeric-9-plus-box-multiple:before {
  content: "󰼘";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "󰏀";
}

.mdi-numeric-9-plus-box-outline:before {
  content: "󰏁";
}

.mdi-numeric-9-plus-circle:before {
  content: "󰲲";
}

.mdi-numeric-9-plus-circle-outline:before {
  content: "󰲳";
}

.mdi-numeric-negative-1:before {
  content: "󱁒";
}

.mdi-numeric-positive-1:before {
  content: "󱗋";
}

.mdi-nut:before {
  content: "󰛸";
}

.mdi-nutrition:before {
  content: "󰏂";
}

.mdi-nuxt:before {
  content: "󱄆";
}

.mdi-oar:before {
  content: "󰙼";
}

.mdi-ocarina:before {
  content: "󰷠";
}

.mdi-oci:before {
  content: "󱋩";
}

.mdi-ocr:before {
  content: "󱄺";
}

.mdi-octagon:before {
  content: "󰏃";
}

.mdi-octagon-outline:before {
  content: "󰏄";
}

.mdi-octagram:before {
  content: "󰛹";
}

.mdi-octagram-outline:before {
  content: "󰝵";
}

.mdi-odnoklassniki:before {
  content: "󰏅";
}

.mdi-offer:before {
  content: "󱈛";
}

.mdi-office-building:before {
  content: "󰦑";
}

.mdi-office-building-marker:before {
  content: "󱔠";
}

.mdi-office-building-marker-outline:before {
  content: "󱔡";
}

.mdi-office-building-outline:before {
  content: "󱔟";
}

.mdi-oil:before {
  content: "󰏇";
}

.mdi-oil-lamp:before {
  content: "󰼙";
}

.mdi-oil-level:before {
  content: "󱁓";
}

.mdi-oil-temperature:before {
  content: "󰿸";
}

.mdi-omega:before {
  content: "󰏉";
}

.mdi-one-up:before {
  content: "󰮭";
}

.mdi-onepassword:before {
  content: "󰢁";
}

.mdi-opacity:before {
  content: "󰗌";
}

.mdi-open-in-app:before {
  content: "󰏋";
}

.mdi-open-in-new:before {
  content: "󰏌";
}

.mdi-open-source-initiative:before {
  content: "󰮮";
}

.mdi-openid:before {
  content: "󰏍";
}

.mdi-opera:before {
  content: "󰏎";
}

.mdi-orbit:before {
  content: "󰀘";
}

.mdi-orbit-variant:before {
  content: "󱗛";
}

.mdi-order-alphabetical-ascending:before {
  content: "󰈍";
}

.mdi-order-alphabetical-descending:before {
  content: "󰴇";
}

.mdi-order-bool-ascending:before {
  content: "󰊾";
}

.mdi-order-bool-ascending-variant:before {
  content: "󰦏";
}

.mdi-order-bool-descending:before {
  content: "󱎄";
}

.mdi-order-bool-descending-variant:before {
  content: "󰦐";
}

.mdi-order-numeric-ascending:before {
  content: "󰕅";
}

.mdi-order-numeric-descending:before {
  content: "󰕆";
}

.mdi-origin:before {
  content: "󰭃";
}

.mdi-ornament:before {
  content: "󰏏";
}

.mdi-ornament-variant:before {
  content: "󰏐";
}

.mdi-outdoor-lamp:before {
  content: "󱁔";
}

.mdi-overscan:before {
  content: "󱀅";
}

.mdi-owl:before {
  content: "󰏒";
}

.mdi-pac-man:before {
  content: "󰮯";
}

.mdi-package:before {
  content: "󰏓";
}

.mdi-package-down:before {
  content: "󰏔";
}

.mdi-package-up:before {
  content: "󰏕";
}

.mdi-package-variant:before {
  content: "󰏖";
}

.mdi-package-variant-closed:before {
  content: "󰏗";
}

.mdi-page-first:before {
  content: "󰘀";
}

.mdi-page-last:before {
  content: "󰘁";
}

.mdi-page-layout-body:before {
  content: "󰛺";
}

.mdi-page-layout-footer:before {
  content: "󰛻";
}

.mdi-page-layout-header:before {
  content: "󰛼";
}

.mdi-page-layout-header-footer:before {
  content: "󰽿";
}

.mdi-page-layout-sidebar-left:before {
  content: "󰛽";
}

.mdi-page-layout-sidebar-right:before {
  content: "󰛾";
}

.mdi-page-next:before {
  content: "󰮰";
}

.mdi-page-next-outline:before {
  content: "󰮱";
}

.mdi-page-previous:before {
  content: "󰮲";
}

.mdi-page-previous-outline:before {
  content: "󰮳";
}

.mdi-pail:before {
  content: "󱐗";
}

.mdi-pail-minus:before {
  content: "󱐷";
}

.mdi-pail-minus-outline:before {
  content: "󱐼";
}

.mdi-pail-off:before {
  content: "󱐹";
}

.mdi-pail-off-outline:before {
  content: "󱐾";
}

.mdi-pail-outline:before {
  content: "󱐺";
}

.mdi-pail-plus:before {
  content: "󱐶";
}

.mdi-pail-plus-outline:before {
  content: "󱐻";
}

.mdi-pail-remove:before {
  content: "󱐸";
}

.mdi-pail-remove-outline:before {
  content: "󱐽";
}

.mdi-palette:before {
  content: "󰏘";
}

.mdi-palette-advanced:before {
  content: "󰏙";
}

.mdi-palette-outline:before {
  content: "󰸌";
}

.mdi-palette-swatch:before {
  content: "󰢵";
}

.mdi-palette-swatch-outline:before {
  content: "󱍜";
}

.mdi-palm-tree:before {
  content: "󱁕";
}

.mdi-pan:before {
  content: "󰮴";
}

.mdi-pan-bottom-left:before {
  content: "󰮵";
}

.mdi-pan-bottom-right:before {
  content: "󰮶";
}

.mdi-pan-down:before {
  content: "󰮷";
}

.mdi-pan-horizontal:before {
  content: "󰮸";
}

.mdi-pan-left:before {
  content: "󰮹";
}

.mdi-pan-right:before {
  content: "󰮺";
}

.mdi-pan-top-left:before {
  content: "󰮻";
}

.mdi-pan-top-right:before {
  content: "󰮼";
}

.mdi-pan-up:before {
  content: "󰮽";
}

.mdi-pan-vertical:before {
  content: "󰮾";
}

.mdi-panda:before {
  content: "󰏚";
}

.mdi-pandora:before {
  content: "󰏛";
}

.mdi-panorama:before {
  content: "󰏜";
}

.mdi-panorama-fisheye:before {
  content: "󰏝";
}

.mdi-panorama-horizontal:before {
  content: "󰏞";
}

.mdi-panorama-vertical:before {
  content: "󰏟";
}

.mdi-panorama-wide-angle:before {
  content: "󰏠";
}

.mdi-paper-cut-vertical:before {
  content: "󰏡";
}

.mdi-paper-roll:before {
  content: "󱅗";
}

.mdi-paper-roll-outline:before {
  content: "󱅘";
}

.mdi-paperclip:before {
  content: "󰏢";
}

.mdi-parachute:before {
  content: "󰲴";
}

.mdi-parachute-outline:before {
  content: "󰲵";
}

.mdi-parking:before {
  content: "󰏣";
}

.mdi-party-popper:before {
  content: "󱁖";
}

.mdi-passport:before {
  content: "󰟣";
}

.mdi-passport-biometric:before {
  content: "󰷡";
}

.mdi-pasta:before {
  content: "󱅠";
}

.mdi-patio-heater:before {
  content: "󰾀";
}

.mdi-patreon:before {
  content: "󰢂";
}

.mdi-pause:before {
  content: "󰏤";
}

.mdi-pause-circle:before {
  content: "󰏥";
}

.mdi-pause-circle-outline:before {
  content: "󰏦";
}

.mdi-pause-octagon:before {
  content: "󰏧";
}

.mdi-pause-octagon-outline:before {
  content: "󰏨";
}

.mdi-paw:before {
  content: "󰏩";
}

.mdi-paw-off:before {
  content: "󰙗";
}

.mdi-paw-off-outline:before {
  content: "󱙶";
}

.mdi-paw-outline:before {
  content: "󱙵";
}

.mdi-pdf-box:before {
  content: "󰹖";
}

.mdi-peace:before {
  content: "󰢄";
}

.mdi-peanut:before {
  content: "󰿼";
}

.mdi-peanut-off:before {
  content: "󰿽";
}

.mdi-peanut-off-outline:before {
  content: "󰿿";
}

.mdi-peanut-outline:before {
  content: "󰿾";
}

.mdi-pen:before {
  content: "󰏪";
}

.mdi-pen-lock:before {
  content: "󰷢";
}

.mdi-pen-minus:before {
  content: "󰷣";
}

.mdi-pen-off:before {
  content: "󰷤";
}

.mdi-pen-plus:before {
  content: "󰷥";
}

.mdi-pen-remove:before {
  content: "󰷦";
}

.mdi-pencil:before {
  content: "󰏫";
}

.mdi-pencil-box:before {
  content: "󰏬";
}

.mdi-pencil-box-multiple:before {
  content: "󱅄";
}

.mdi-pencil-box-multiple-outline:before {
  content: "󱅅";
}

.mdi-pencil-box-outline:before {
  content: "󰏭";
}

.mdi-pencil-circle:before {
  content: "󰛿";
}

.mdi-pencil-circle-outline:before {
  content: "󰝶";
}

.mdi-pencil-lock:before {
  content: "󰏮";
}

.mdi-pencil-lock-outline:before {
  content: "󰷧";
}

.mdi-pencil-minus:before {
  content: "󰷨";
}

.mdi-pencil-minus-outline:before {
  content: "󰷩";
}

.mdi-pencil-off:before {
  content: "󰏯";
}

.mdi-pencil-off-outline:before {
  content: "󰷪";
}

.mdi-pencil-outline:before {
  content: "󰲶";
}

.mdi-pencil-plus:before {
  content: "󰷫";
}

.mdi-pencil-plus-outline:before {
  content: "󰷬";
}

.mdi-pencil-remove:before {
  content: "󰷭";
}

.mdi-pencil-remove-outline:before {
  content: "󰷮";
}

.mdi-pencil-ruler:before {
  content: "󱍓";
}

.mdi-penguin:before {
  content: "󰻀";
}

.mdi-pentagon:before {
  content: "󰜁";
}

.mdi-pentagon-outline:before {
  content: "󰜀";
}

.mdi-pentagram:before {
  content: "󱙧";
}

.mdi-percent:before {
  content: "󰏰";
}

.mdi-percent-outline:before {
  content: "󱉸";
}

.mdi-periodic-table:before {
  content: "󰢶";
}

.mdi-perspective-less:before {
  content: "󰴣";
}

.mdi-perspective-more:before {
  content: "󰴤";
}

.mdi-pharmacy:before {
  content: "󰏱";
}

.mdi-phone:before {
  content: "󰏲";
}

.mdi-phone-alert:before {
  content: "󰼚";
}

.mdi-phone-alert-outline:before {
  content: "󱆎";
}

.mdi-phone-bluetooth:before {
  content: "󰏳";
}

.mdi-phone-bluetooth-outline:before {
  content: "󱆏";
}

.mdi-phone-cancel:before {
  content: "󱂼";
}

.mdi-phone-cancel-outline:before {
  content: "󱆐";
}

.mdi-phone-check:before {
  content: "󱆩";
}

.mdi-phone-check-outline:before {
  content: "󱆪";
}

.mdi-phone-classic:before {
  content: "󰘂";
}

.mdi-phone-classic-off:before {
  content: "󱉹";
}

.mdi-phone-dial:before {
  content: "󱕙";
}

.mdi-phone-dial-outline:before {
  content: "󱕚";
}

.mdi-phone-forward:before {
  content: "󰏴";
}

.mdi-phone-forward-outline:before {
  content: "󱆑";
}

.mdi-phone-hangup:before {
  content: "󰏵";
}

.mdi-phone-hangup-outline:before {
  content: "󱆒";
}

.mdi-phone-in-talk:before {
  content: "󰏶";
}

.mdi-phone-in-talk-outline:before {
  content: "󱆂";
}

.mdi-phone-incoming:before {
  content: "󰏷";
}

.mdi-phone-incoming-outline:before {
  content: "󱆓";
}

.mdi-phone-lock:before {
  content: "󰏸";
}

.mdi-phone-lock-outline:before {
  content: "󱆔";
}

.mdi-phone-log:before {
  content: "󰏹";
}

.mdi-phone-log-outline:before {
  content: "󱆕";
}

.mdi-phone-message:before {
  content: "󱆖";
}

.mdi-phone-message-outline:before {
  content: "󱆗";
}

.mdi-phone-minus:before {
  content: "󰙘";
}

.mdi-phone-minus-outline:before {
  content: "󱆘";
}

.mdi-phone-missed:before {
  content: "󰏺";
}

.mdi-phone-missed-outline:before {
  content: "󱆥";
}

.mdi-phone-off:before {
  content: "󰷯";
}

.mdi-phone-off-outline:before {
  content: "󱆦";
}

.mdi-phone-outgoing:before {
  content: "󰏻";
}

.mdi-phone-outgoing-outline:before {
  content: "󱆙";
}

.mdi-phone-outline:before {
  content: "󰷰";
}

.mdi-phone-paused:before {
  content: "󰏼";
}

.mdi-phone-paused-outline:before {
  content: "󱆚";
}

.mdi-phone-plus:before {
  content: "󰙙";
}

.mdi-phone-plus-outline:before {
  content: "󱆛";
}

.mdi-phone-remove:before {
  content: "󱔯";
}

.mdi-phone-remove-outline:before {
  content: "󱔰";
}

.mdi-phone-return:before {
  content: "󰠯";
}

.mdi-phone-return-outline:before {
  content: "󱆜";
}

.mdi-phone-ring:before {
  content: "󱆫";
}

.mdi-phone-ring-outline:before {
  content: "󱆬";
}

.mdi-phone-rotate-landscape:before {
  content: "󰢅";
}

.mdi-phone-rotate-portrait:before {
  content: "󰢆";
}

.mdi-phone-settings:before {
  content: "󰏽";
}

.mdi-phone-settings-outline:before {
  content: "󱆝";
}

.mdi-phone-voip:before {
  content: "󰏾";
}

.mdi-pi:before {
  content: "󰏿";
}

.mdi-pi-box:before {
  content: "󰐀";
}

.mdi-pi-hole:before {
  content: "󰷱";
}

.mdi-piano:before {
  content: "󰙽";
}

.mdi-pickaxe:before {
  content: "󰢷";
}

.mdi-picture-in-picture-bottom-right:before {
  content: "󰹗";
}

.mdi-picture-in-picture-bottom-right-outline:before {
  content: "󰹘";
}

.mdi-picture-in-picture-top-right:before {
  content: "󰹙";
}

.mdi-picture-in-picture-top-right-outline:before {
  content: "󰹚";
}

.mdi-pier:before {
  content: "󰢇";
}

.mdi-pier-crane:before {
  content: "󰢈";
}

.mdi-pig:before {
  content: "󰐁";
}

.mdi-pig-variant:before {
  content: "󱀆";
}

.mdi-pig-variant-outline:before {
  content: "󱙸";
}

.mdi-piggy-bank:before {
  content: "󱀇";
}

.mdi-piggy-bank-outline:before {
  content: "󱙹";
}

.mdi-pill:before {
  content: "󰐂";
}

.mdi-pillar:before {
  content: "󰜂";
}

.mdi-pin:before {
  content: "󰐃";
}

.mdi-pin-off:before {
  content: "󰐄";
}

.mdi-pin-off-outline:before {
  content: "󰤰";
}

.mdi-pin-outline:before {
  content: "󰤱";
}

.mdi-pine-tree:before {
  content: "󰐅";
}

.mdi-pine-tree-box:before {
  content: "󰐆";
}

.mdi-pine-tree-fire:before {
  content: "󱐚";
}

.mdi-pinterest:before {
  content: "󰐇";
}

.mdi-pinwheel:before {
  content: "󰫕";
}

.mdi-pinwheel-outline:before {
  content: "󰫖";
}

.mdi-pipe:before {
  content: "󰟥";
}

.mdi-pipe-disconnected:before {
  content: "󰟦";
}

.mdi-pipe-leak:before {
  content: "󰢉";
}

.mdi-pipe-wrench:before {
  content: "󱍔";
}

.mdi-pirate:before {
  content: "󰨈";
}

.mdi-pistol:before {
  content: "󰜃";
}

.mdi-piston:before {
  content: "󰢊";
}

.mdi-pitchfork:before {
  content: "󱕓";
}

.mdi-pizza:before {
  content: "󰐉";
}

.mdi-play:before {
  content: "󰐊";
}

.mdi-play-box:before {
  content: "󱉺";
}

.mdi-play-box-multiple:before {
  content: "󰴙";
}

.mdi-play-box-multiple-outline:before {
  content: "󱏦";
}

.mdi-play-box-outline:before {
  content: "󰐋";
}

.mdi-play-circle:before {
  content: "󰐌";
}

.mdi-play-circle-outline:before {
  content: "󰐍";
}

.mdi-play-network:before {
  content: "󰢋";
}

.mdi-play-network-outline:before {
  content: "󰲷";
}

.mdi-play-outline:before {
  content: "󰼛";
}

.mdi-play-pause:before {
  content: "󰐎";
}

.mdi-play-protected-content:before {
  content: "󰐏";
}

.mdi-play-speed:before {
  content: "󰣿";
}

.mdi-playlist-check:before {
  content: "󰗇";
}

.mdi-playlist-edit:before {
  content: "󰤀";
}

.mdi-playlist-minus:before {
  content: "󰐐";
}

.mdi-playlist-music:before {
  content: "󰲸";
}

.mdi-playlist-music-outline:before {
  content: "󰲹";
}

.mdi-playlist-play:before {
  content: "󰐑";
}

.mdi-playlist-plus:before {
  content: "󰐒";
}

.mdi-playlist-remove:before {
  content: "󰐓";
}

.mdi-playlist-star:before {
  content: "󰷲";
}

.mdi-plex:before {
  content: "󰚺";
}

.mdi-plus:before {
  content: "󰐕";
}

.mdi-plus-box:before {
  content: "󰐖";
}

.mdi-plus-box-multiple:before {
  content: "󰌴";
}

.mdi-plus-box-multiple-outline:before {
  content: "󱅃";
}

.mdi-plus-box-outline:before {
  content: "󰜄";
}

.mdi-plus-circle:before {
  content: "󰐗";
}

.mdi-plus-circle-multiple:before {
  content: "󰍌";
}

.mdi-plus-circle-multiple-outline:before {
  content: "󰐘";
}

.mdi-plus-circle-outline:before {
  content: "󰐙";
}

.mdi-plus-minus:before {
  content: "󰦒";
}

.mdi-plus-minus-box:before {
  content: "󰦓";
}

.mdi-plus-minus-variant:before {
  content: "󱓉";
}

.mdi-plus-network:before {
  content: "󰐚";
}

.mdi-plus-network-outline:before {
  content: "󰲺";
}

.mdi-plus-one:before {
  content: "󰐛";
}

.mdi-plus-outline:before {
  content: "󰜅";
}

.mdi-plus-thick:before {
  content: "󱇬";
}

.mdi-podcast:before {
  content: "󰦔";
}

.mdi-podium:before {
  content: "󰴥";
}

.mdi-podium-bronze:before {
  content: "󰴦";
}

.mdi-podium-gold:before {
  content: "󰴧";
}

.mdi-podium-silver:before {
  content: "󰴨";
}

.mdi-point-of-sale:before {
  content: "󰶒";
}

.mdi-pokeball:before {
  content: "󰐝";
}

.mdi-pokemon-go:before {
  content: "󰨉";
}

.mdi-poker-chip:before {
  content: "󰠰";
}

.mdi-polaroid:before {
  content: "󰐞";
}

.mdi-police-badge:before {
  content: "󱅧";
}

.mdi-police-badge-outline:before {
  content: "󱅨";
}

.mdi-poll:before {
  content: "󰐟";
}

.mdi-poll-box:before {
  content: "󰐠";
}

.mdi-poll-box-outline:before {
  content: "󱉻";
}

.mdi-polo:before {
  content: "󱓃";
}

.mdi-polymer:before {
  content: "󰐡";
}

.mdi-pool:before {
  content: "󰘆";
}

.mdi-popcorn:before {
  content: "󰐢";
}

.mdi-post:before {
  content: "󱀈";
}

.mdi-post-outline:before {
  content: "󱀉";
}

.mdi-postage-stamp:before {
  content: "󰲻";
}

.mdi-pot:before {
  content: "󰋥";
}

.mdi-pot-mix:before {
  content: "󰙛";
}

.mdi-pot-mix-outline:before {
  content: "󰙷";
}

.mdi-pot-outline:before {
  content: "󰋿";
}

.mdi-pot-steam:before {
  content: "󰙚";
}

.mdi-pot-steam-outline:before {
  content: "󰌦";
}

.mdi-pound:before {
  content: "󰐣";
}

.mdi-pound-box:before {
  content: "󰐤";
}

.mdi-pound-box-outline:before {
  content: "󱅿";
}

.mdi-power:before {
  content: "󰐥";
}

.mdi-power-cycle:before {
  content: "󰤁";
}

.mdi-power-off:before {
  content: "󰤂";
}

.mdi-power-on:before {
  content: "󰤃";
}

.mdi-power-plug:before {
  content: "󰚥";
}

.mdi-power-plug-off:before {
  content: "󰚦";
}

.mdi-power-plug-off-outline:before {
  content: "󱐤";
}

.mdi-power-plug-outline:before {
  content: "󱐥";
}

.mdi-power-settings:before {
  content: "󰐦";
}

.mdi-power-sleep:before {
  content: "󰤄";
}

.mdi-power-socket:before {
  content: "󰐧";
}

.mdi-power-socket-au:before {
  content: "󰤅";
}

.mdi-power-socket-de:before {
  content: "󱄇";
}

.mdi-power-socket-eu:before {
  content: "󰟧";
}

.mdi-power-socket-fr:before {
  content: "󱄈";
}

.mdi-power-socket-it:before {
  content: "󱓿";
}

.mdi-power-socket-jp:before {
  content: "󱄉";
}

.mdi-power-socket-uk:before {
  content: "󰟨";
}

.mdi-power-socket-us:before {
  content: "󰟩";
}

.mdi-power-standby:before {
  content: "󰤆";
}

.mdi-powershell:before {
  content: "󰨊";
}

.mdi-prescription:before {
  content: "󰜆";
}

.mdi-presentation:before {
  content: "󰐨";
}

.mdi-presentation-play:before {
  content: "󰐩";
}

.mdi-pretzel:before {
  content: "󱕢";
}

.mdi-printer:before {
  content: "󰐪";
}

.mdi-printer-3d:before {
  content: "󰐫";
}

.mdi-printer-3d-nozzle:before {
  content: "󰹛";
}

.mdi-printer-3d-nozzle-alert:before {
  content: "󱇀";
}

.mdi-printer-3d-nozzle-alert-outline:before {
  content: "󱇁";
}

.mdi-printer-3d-nozzle-outline:before {
  content: "󰹜";
}

.mdi-printer-alert:before {
  content: "󰐬";
}

.mdi-printer-check:before {
  content: "󱅆";
}

.mdi-printer-eye:before {
  content: "󱑘";
}

.mdi-printer-off:before {
  content: "󰹝";
}

.mdi-printer-pos:before {
  content: "󱁗";
}

.mdi-printer-search:before {
  content: "󱑗";
}

.mdi-printer-settings:before {
  content: "󰜇";
}

.mdi-printer-wireless:before {
  content: "󰨋";
}

.mdi-priority-high:before {
  content: "󰘃";
}

.mdi-priority-low:before {
  content: "󰘄";
}

.mdi-professional-hexagon:before {
  content: "󰐭";
}

.mdi-progress-alert:before {
  content: "󰲼";
}

.mdi-progress-check:before {
  content: "󰦕";
}

.mdi-progress-clock:before {
  content: "󰦖";
}

.mdi-progress-close:before {
  content: "󱄊";
}

.mdi-progress-download:before {
  content: "󰦗";
}

.mdi-progress-question:before {
  content: "󱔢";
}

.mdi-progress-upload:before {
  content: "󰦘";
}

.mdi-progress-wrench:before {
  content: "󰲽";
}

.mdi-projector:before {
  content: "󰐮";
}

.mdi-projector-screen:before {
  content: "󰐯";
}

.mdi-projector-screen-outline:before {
  content: "󱜤";
}

.mdi-propane-tank:before {
  content: "󱍗";
}

.mdi-propane-tank-outline:before {
  content: "󱍘";
}

.mdi-protocol:before {
  content: "󰿘";
}

.mdi-publish:before {
  content: "󰚧";
}

.mdi-pulse:before {
  content: "󰐰";
}

.mdi-pump:before {
  content: "󱐂";
}

.mdi-pumpkin:before {
  content: "󰮿";
}

.mdi-purse:before {
  content: "󰼜";
}

.mdi-purse-outline:before {
  content: "󰼝";
}

.mdi-puzzle:before {
  content: "󰐱";
}

.mdi-puzzle-check:before {
  content: "󱐦";
}

.mdi-puzzle-check-outline:before {
  content: "󱐧";
}

.mdi-puzzle-edit:before {
  content: "󱓓";
}

.mdi-puzzle-edit-outline:before {
  content: "󱓙";
}

.mdi-puzzle-heart:before {
  content: "󱓔";
}

.mdi-puzzle-heart-outline:before {
  content: "󱓚";
}

.mdi-puzzle-minus:before {
  content: "󱓑";
}

.mdi-puzzle-minus-outline:before {
  content: "󱓗";
}

.mdi-puzzle-outline:before {
  content: "󰩦";
}

.mdi-puzzle-plus:before {
  content: "󱓐";
}

.mdi-puzzle-plus-outline:before {
  content: "󱓖";
}

.mdi-puzzle-remove:before {
  content: "󱓒";
}

.mdi-puzzle-remove-outline:before {
  content: "󱓘";
}

.mdi-puzzle-star:before {
  content: "󱓕";
}

.mdi-puzzle-star-outline:before {
  content: "󱓛";
}

.mdi-qi:before {
  content: "󰦙";
}

.mdi-qqchat:before {
  content: "󰘅";
}

.mdi-qrcode:before {
  content: "󰐲";
}

.mdi-qrcode-edit:before {
  content: "󰢸";
}

.mdi-qrcode-minus:before {
  content: "󱆌";
}

.mdi-qrcode-plus:before {
  content: "󱆋";
}

.mdi-qrcode-remove:before {
  content: "󱆍";
}

.mdi-qrcode-scan:before {
  content: "󰐳";
}

.mdi-quadcopter:before {
  content: "󰐴";
}

.mdi-quality-high:before {
  content: "󰐵";
}

.mdi-quality-low:before {
  content: "󰨌";
}

.mdi-quality-medium:before {
  content: "󰨍";
}

.mdi-quora:before {
  content: "󰴩";
}

.mdi-rabbit:before {
  content: "󰤇";
}

.mdi-racing-helmet:before {
  content: "󰶓";
}

.mdi-racquetball:before {
  content: "󰶔";
}

.mdi-radar:before {
  content: "󰐷";
}

.mdi-radiator:before {
  content: "󰐸";
}

.mdi-radiator-disabled:before {
  content: "󰫗";
}

.mdi-radiator-off:before {
  content: "󰫘";
}

.mdi-radio:before {
  content: "󰐹";
}

.mdi-radio-am:before {
  content: "󰲾";
}

.mdi-radio-fm:before {
  content: "󰲿";
}

.mdi-radio-handheld:before {
  content: "󰐺";
}

.mdi-radio-off:before {
  content: "󱈜";
}

.mdi-radio-tower:before {
  content: "󰐻";
}

.mdi-radioactive:before {
  content: "󰐼";
}

.mdi-radioactive-off:before {
  content: "󰻁";
}

.mdi-radiobox-blank:before {
  content: "󰐽";
}

.mdi-radiobox-marked:before {
  content: "󰐾";
}

.mdi-radiology-box:before {
  content: "󱓅";
}

.mdi-radiology-box-outline:before {
  content: "󱓆";
}

.mdi-radius:before {
  content: "󰳀";
}

.mdi-radius-outline:before {
  content: "󰳁";
}

.mdi-railroad-light:before {
  content: "󰼞";
}

.mdi-rake:before {
  content: "󱕄";
}

.mdi-raspberry-pi:before {
  content: "󰐿";
}

.mdi-ray-end:before {
  content: "󰑀";
}

.mdi-ray-end-arrow:before {
  content: "󰑁";
}

.mdi-ray-start:before {
  content: "󰑂";
}

.mdi-ray-start-arrow:before {
  content: "󰑃";
}

.mdi-ray-start-end:before {
  content: "󰑄";
}

.mdi-ray-start-vertex-end:before {
  content: "󱗘";
}

.mdi-ray-vertex:before {
  content: "󰑅";
}

.mdi-react:before {
  content: "󰜈";
}

.mdi-read:before {
  content: "󰑇";
}

.mdi-receipt:before {
  content: "󰑉";
}

.mdi-record:before {
  content: "󰑊";
}

.mdi-record-circle:before {
  content: "󰻂";
}

.mdi-record-circle-outline:before {
  content: "󰻃";
}

.mdi-record-player:before {
  content: "󰦚";
}

.mdi-record-rec:before {
  content: "󰑋";
}

.mdi-rectangle:before {
  content: "󰹞";
}

.mdi-rectangle-outline:before {
  content: "󰹟";
}

.mdi-recycle:before {
  content: "󰑌";
}

.mdi-recycle-variant:before {
  content: "󱎝";
}

.mdi-reddit:before {
  content: "󰑍";
}

.mdi-redhat:before {
  content: "󱄛";
}

.mdi-redo:before {
  content: "󰑎";
}

.mdi-redo-variant:before {
  content: "󰑏";
}

.mdi-reflect-horizontal:before {
  content: "󰨎";
}

.mdi-reflect-vertical:before {
  content: "󰨏";
}

.mdi-refresh:before {
  content: "󰑐";
}

.mdi-refresh-circle:before {
  content: "󱍷";
}

.mdi-regex:before {
  content: "󰑑";
}

.mdi-registered-trademark:before {
  content: "󰩧";
}

.mdi-reiterate:before {
  content: "󱖈";
}

.mdi-relation-many-to-many:before {
  content: "󱒖";
}

.mdi-relation-many-to-one:before {
  content: "󱒗";
}

.mdi-relation-many-to-one-or-many:before {
  content: "󱒘";
}

.mdi-relation-many-to-only-one:before {
  content: "󱒙";
}

.mdi-relation-many-to-zero-or-many:before {
  content: "󱒚";
}

.mdi-relation-many-to-zero-or-one:before {
  content: "󱒛";
}

.mdi-relation-one-or-many-to-many:before {
  content: "󱒜";
}

.mdi-relation-one-or-many-to-one:before {
  content: "󱒝";
}

.mdi-relation-one-or-many-to-one-or-many:before {
  content: "󱒞";
}

.mdi-relation-one-or-many-to-only-one:before {
  content: "󱒟";
}

.mdi-relation-one-or-many-to-zero-or-many:before {
  content: "󱒠";
}

.mdi-relation-one-or-many-to-zero-or-one:before {
  content: "󱒡";
}

.mdi-relation-one-to-many:before {
  content: "󱒢";
}

.mdi-relation-one-to-one:before {
  content: "󱒣";
}

.mdi-relation-one-to-one-or-many:before {
  content: "󱒤";
}

.mdi-relation-one-to-only-one:before {
  content: "󱒥";
}

.mdi-relation-one-to-zero-or-many:before {
  content: "󱒦";
}

.mdi-relation-one-to-zero-or-one:before {
  content: "󱒧";
}

.mdi-relation-only-one-to-many:before {
  content: "󱒨";
}

.mdi-relation-only-one-to-one:before {
  content: "󱒩";
}

.mdi-relation-only-one-to-one-or-many:before {
  content: "󱒪";
}

.mdi-relation-only-one-to-only-one:before {
  content: "󱒫";
}

.mdi-relation-only-one-to-zero-or-many:before {
  content: "󱒬";
}

.mdi-relation-only-one-to-zero-or-one:before {
  content: "󱒭";
}

.mdi-relation-zero-or-many-to-many:before {
  content: "󱒮";
}

.mdi-relation-zero-or-many-to-one:before {
  content: "󱒯";
}

.mdi-relation-zero-or-many-to-one-or-many:before {
  content: "󱒰";
}

.mdi-relation-zero-or-many-to-only-one:before {
  content: "󱒱";
}

.mdi-relation-zero-or-many-to-zero-or-many:before {
  content: "󱒲";
}

.mdi-relation-zero-or-many-to-zero-or-one:before {
  content: "󱒳";
}

.mdi-relation-zero-or-one-to-many:before {
  content: "󱒴";
}

.mdi-relation-zero-or-one-to-one:before {
  content: "󱒵";
}

.mdi-relation-zero-or-one-to-one-or-many:before {
  content: "󱒶";
}

.mdi-relation-zero-or-one-to-only-one:before {
  content: "󱒷";
}

.mdi-relation-zero-or-one-to-zero-or-many:before {
  content: "󱒸";
}

.mdi-relation-zero-or-one-to-zero-or-one:before {
  content: "󱒹";
}

.mdi-relative-scale:before {
  content: "󰑒";
}

.mdi-reload:before {
  content: "󰑓";
}

.mdi-reload-alert:before {
  content: "󱄋";
}

.mdi-reminder:before {
  content: "󰢌";
}

.mdi-remote:before {
  content: "󰑔";
}

.mdi-remote-desktop:before {
  content: "󰢹";
}

.mdi-remote-off:before {
  content: "󰻄";
}

.mdi-remote-tv:before {
  content: "󰻅";
}

.mdi-remote-tv-off:before {
  content: "󰻆";
}

.mdi-rename-box:before {
  content: "󰑕";
}

.mdi-reorder-horizontal:before {
  content: "󰚈";
}

.mdi-reorder-vertical:before {
  content: "󰚉";
}

.mdi-repeat:before {
  content: "󰑖";
}

.mdi-repeat-off:before {
  content: "󰑗";
}

.mdi-repeat-once:before {
  content: "󰑘";
}

.mdi-replay:before {
  content: "󰑙";
}

.mdi-reply:before {
  content: "󰑚";
}

.mdi-reply-all:before {
  content: "󰑛";
}

.mdi-reply-all-outline:before {
  content: "󰼟";
}

.mdi-reply-circle:before {
  content: "󱆮";
}

.mdi-reply-outline:before {
  content: "󰼠";
}

.mdi-reproduction:before {
  content: "󰑜";
}

.mdi-resistor:before {
  content: "󰭄";
}

.mdi-resistor-nodes:before {
  content: "󰭅";
}

.mdi-resize:before {
  content: "󰩨";
}

.mdi-resize-bottom-right:before {
  content: "󰑝";
}

.mdi-responsive:before {
  content: "󰑞";
}

.mdi-restart:before {
  content: "󰜉";
}

.mdi-restart-alert:before {
  content: "󱄌";
}

.mdi-restart-off:before {
  content: "󰶕";
}

.mdi-restore:before {
  content: "󰦛";
}

.mdi-restore-alert:before {
  content: "󱄍";
}

.mdi-rewind:before {
  content: "󰑟";
}

.mdi-rewind-10:before {
  content: "󰴪";
}

.mdi-rewind-30:before {
  content: "󰶖";
}

.mdi-rewind-5:before {
  content: "󱇹";
}

.mdi-rewind-60:before {
  content: "󱘌";
}

.mdi-rewind-outline:before {
  content: "󰜊";
}

.mdi-rhombus:before {
  content: "󰜋";
}

.mdi-rhombus-medium:before {
  content: "󰨐";
}

.mdi-rhombus-medium-outline:before {
  content: "󱓜";
}

.mdi-rhombus-outline:before {
  content: "󰜌";
}

.mdi-rhombus-split:before {
  content: "󰨑";
}

.mdi-rhombus-split-outline:before {
  content: "󱓝";
}

.mdi-ribbon:before {
  content: "󰑠";
}

.mdi-rice:before {
  content: "󰟪";
}

.mdi-rickshaw:before {
  content: "󱖻";
}

.mdi-rickshaw-electric:before {
  content: "󱖼";
}

.mdi-ring:before {
  content: "󰟫";
}

.mdi-rivet:before {
  content: "󰹠";
}

.mdi-road:before {
  content: "󰑡";
}

.mdi-road-variant:before {
  content: "󰑢";
}

.mdi-robber:before {
  content: "󱁘";
}

.mdi-robot:before {
  content: "󰚩";
}

.mdi-robot-angry:before {
  content: "󱚝";
}

.mdi-robot-angry-outline:before {
  content: "󱚞";
}

.mdi-robot-confused:before {
  content: "󱚟";
}

.mdi-robot-confused-outline:before {
  content: "󱚠";
}

.mdi-robot-dead:before {
  content: "󱚡";
}

.mdi-robot-dead-outline:before {
  content: "󱚢";
}

.mdi-robot-excited:before {
  content: "󱚣";
}

.mdi-robot-excited-outline:before {
  content: "󱚤";
}

.mdi-robot-happy:before {
  content: "󱜙";
}

.mdi-robot-happy-outline:before {
  content: "󱜚";
}

.mdi-robot-industrial:before {
  content: "󰭆";
}

.mdi-robot-love:before {
  content: "󱚥";
}

.mdi-robot-love-outline:before {
  content: "󱚦";
}

.mdi-robot-mower:before {
  content: "󱇷";
}

.mdi-robot-mower-outline:before {
  content: "󱇳";
}

.mdi-robot-off:before {
  content: "󱚧";
}

.mdi-robot-off-outline:before {
  content: "󱙻";
}

.mdi-robot-outline:before {
  content: "󱙺";
}

.mdi-robot-vacuum:before {
  content: "󰜍";
}

.mdi-robot-vacuum-variant:before {
  content: "󰤈";
}

.mdi-rocket:before {
  content: "󰑣";
}

.mdi-rocket-launch:before {
  content: "󱓞";
}

.mdi-rocket-launch-outline:before {
  content: "󱓟";
}

.mdi-rocket-outline:before {
  content: "󱎯";
}

.mdi-rodent:before {
  content: "󱌧";
}

.mdi-roller-skate:before {
  content: "󰴫";
}

.mdi-roller-skate-off:before {
  content: "󰅅";
}

.mdi-rollerblade:before {
  content: "󰴬";
}

.mdi-rollerblade-off:before {
  content: "󰀮";
}

.mdi-rollupjs:before {
  content: "󰯀";
}

.mdi-roman-numeral-1:before {
  content: "󱂈";
}

.mdi-roman-numeral-10:before {
  content: "󱂑";
}

.mdi-roman-numeral-2:before {
  content: "󱂉";
}

.mdi-roman-numeral-3:before {
  content: "󱂊";
}

.mdi-roman-numeral-4:before {
  content: "󱂋";
}

.mdi-roman-numeral-5:before {
  content: "󱂌";
}

.mdi-roman-numeral-6:before {
  content: "󱂍";
}

.mdi-roman-numeral-7:before {
  content: "󱂎";
}

.mdi-roman-numeral-8:before {
  content: "󱂏";
}

.mdi-roman-numeral-9:before {
  content: "󱂐";
}

.mdi-room-service:before {
  content: "󰢍";
}

.mdi-room-service-outline:before {
  content: "󰶗";
}

.mdi-rotate-3d:before {
  content: "󰻇";
}

.mdi-rotate-3d-variant:before {
  content: "󰑤";
}

.mdi-rotate-left:before {
  content: "󰑥";
}

.mdi-rotate-left-variant:before {
  content: "󰑦";
}

.mdi-rotate-orbit:before {
  content: "󰶘";
}

.mdi-rotate-right:before {
  content: "󰑧";
}

.mdi-rotate-right-variant:before {
  content: "󰑨";
}

.mdi-rounded-corner:before {
  content: "󰘇";
}

.mdi-router:before {
  content: "󱇢";
}

.mdi-router-network:before {
  content: "󱂇";
}

.mdi-router-wireless:before {
  content: "󰑩";
}

.mdi-router-wireless-off:before {
  content: "󱖣";
}

.mdi-router-wireless-settings:before {
  content: "󰩩";
}

.mdi-routes:before {
  content: "󰑪";
}

.mdi-routes-clock:before {
  content: "󱁙";
}

.mdi-rowing:before {
  content: "󰘈";
}

.mdi-rss:before {
  content: "󰑫";
}

.mdi-rss-box:before {
  content: "󰑬";
}

.mdi-rss-off:before {
  content: "󰼡";
}

.mdi-rug:before {
  content: "󱑵";
}

.mdi-rugby:before {
  content: "󰶙";
}

.mdi-ruler:before {
  content: "󰑭";
}

.mdi-ruler-square:before {
  content: "󰳂";
}

.mdi-ruler-square-compass:before {
  content: "󰺾";
}

.mdi-run:before {
  content: "󰜎";
}

.mdi-run-fast:before {
  content: "󰑮";
}

.mdi-rv-truck:before {
  content: "󱇔";
}

.mdi-sack:before {
  content: "󰴮";
}

.mdi-sack-percent:before {
  content: "󰴯";
}

.mdi-safe:before {
  content: "󰩪";
}

.mdi-safe-square:before {
  content: "󱉼";
}

.mdi-safe-square-outline:before {
  content: "󱉽";
}

.mdi-safety-goggles:before {
  content: "󰴰";
}

.mdi-sail-boat:before {
  content: "󰻈";
}

.mdi-sale:before {
  content: "󰑯";
}

.mdi-salesforce:before {
  content: "󰢎";
}

.mdi-sass:before {
  content: "󰟬";
}

.mdi-satellite:before {
  content: "󰑰";
}

.mdi-satellite-uplink:before {
  content: "󰤉";
}

.mdi-satellite-variant:before {
  content: "󰑱";
}

.mdi-sausage:before {
  content: "󰢺";
}

.mdi-saw-blade:before {
  content: "󰹡";
}

.mdi-sawtooth-wave:before {
  content: "󱑺";
}

.mdi-saxophone:before {
  content: "󰘉";
}

.mdi-scale:before {
  content: "󰑲";
}

.mdi-scale-balance:before {
  content: "󰗑";
}

.mdi-scale-bathroom:before {
  content: "󰑳";
}

.mdi-scale-off:before {
  content: "󱁚";
}

.mdi-scan-helper:before {
  content: "󱏘";
}

.mdi-scanner:before {
  content: "󰚫";
}

.mdi-scanner-off:before {
  content: "󰤊";
}

.mdi-scatter-plot:before {
  content: "󰻉";
}

.mdi-scatter-plot-outline:before {
  content: "󰻊";
}

.mdi-school:before {
  content: "󰑴";
}

.mdi-school-outline:before {
  content: "󱆀";
}

.mdi-scissors-cutting:before {
  content: "󰩫";
}

.mdi-scooter:before {
  content: "󱖽";
}

.mdi-scooter-electric:before {
  content: "󱖾";
}

.mdi-scoreboard:before {
  content: "󱉾";
}

.mdi-scoreboard-outline:before {
  content: "󱉿";
}

.mdi-screen-rotation:before {
  content: "󰑵";
}

.mdi-screen-rotation-lock:before {
  content: "󰑸";
}

.mdi-screw-flat-top:before {
  content: "󰷳";
}

.mdi-screw-lag:before {
  content: "󰷴";
}

.mdi-screw-machine-flat-top:before {
  content: "󰷵";
}

.mdi-screw-machine-round-top:before {
  content: "󰷶";
}

.mdi-screw-round-top:before {
  content: "󰷷";
}

.mdi-screwdriver:before {
  content: "󰑶";
}

.mdi-script:before {
  content: "󰯁";
}

.mdi-script-outline:before {
  content: "󰑷";
}

.mdi-script-text:before {
  content: "󰯂";
}

.mdi-script-text-key:before {
  content: "󱜥";
}

.mdi-script-text-key-outline:before {
  content: "󱜦";
}

.mdi-script-text-outline:before {
  content: "󰯃";
}

.mdi-script-text-play:before {
  content: "󱜧";
}

.mdi-script-text-play-outline:before {
  content: "󱜨";
}

.mdi-sd:before {
  content: "󰑹";
}

.mdi-seal:before {
  content: "󰑺";
}

.mdi-seal-variant:before {
  content: "󰿙";
}

.mdi-search-web:before {
  content: "󰜏";
}

.mdi-seat:before {
  content: "󰳃";
}

.mdi-seat-flat:before {
  content: "󰑻";
}

.mdi-seat-flat-angled:before {
  content: "󰑼";
}

.mdi-seat-individual-suite:before {
  content: "󰑽";
}

.mdi-seat-legroom-extra:before {
  content: "󰑾";
}

.mdi-seat-legroom-normal:before {
  content: "󰑿";
}

.mdi-seat-legroom-reduced:before {
  content: "󰒀";
}

.mdi-seat-outline:before {
  content: "󰳄";
}

.mdi-seat-passenger:before {
  content: "󱉉";
}

.mdi-seat-recline-extra:before {
  content: "󰒁";
}

.mdi-seat-recline-normal:before {
  content: "󰒂";
}

.mdi-seatbelt:before {
  content: "󰳅";
}

.mdi-security:before {
  content: "󰒃";
}

.mdi-security-network:before {
  content: "󰒄";
}

.mdi-seed:before {
  content: "󰹢";
}

.mdi-seed-off:before {
  content: "󱏽";
}

.mdi-seed-off-outline:before {
  content: "󱏾";
}

.mdi-seed-outline:before {
  content: "󰹣";
}

.mdi-seesaw:before {
  content: "󱖤";
}

.mdi-segment:before {
  content: "󰻋";
}

.mdi-select:before {
  content: "󰒅";
}

.mdi-select-all:before {
  content: "󰒆";
}

.mdi-select-color:before {
  content: "󰴱";
}

.mdi-select-compare:before {
  content: "󰫙";
}

.mdi-select-drag:before {
  content: "󰩬";
}

.mdi-select-group:before {
  content: "󰾂";
}

.mdi-select-inverse:before {
  content: "󰒇";
}

.mdi-select-marker:before {
  content: "󱊀";
}

.mdi-select-multiple:before {
  content: "󱊁";
}

.mdi-select-multiple-marker:before {
  content: "󱊂";
}

.mdi-select-off:before {
  content: "󰒈";
}

.mdi-select-place:before {
  content: "󰿚";
}

.mdi-select-search:before {
  content: "󱈄";
}

.mdi-selection:before {
  content: "󰒉";
}

.mdi-selection-drag:before {
  content: "󰩭";
}

.mdi-selection-ellipse:before {
  content: "󰴲";
}

.mdi-selection-ellipse-arrow-inside:before {
  content: "󰼢";
}

.mdi-selection-marker:before {
  content: "󱊃";
}

.mdi-selection-multiple:before {
  content: "󱊅";
}

.mdi-selection-multiple-marker:before {
  content: "󱊄";
}

.mdi-selection-off:before {
  content: "󰝷";
}

.mdi-selection-search:before {
  content: "󱈅";
}

.mdi-semantic-web:before {
  content: "󱌖";
}

.mdi-send:before {
  content: "󰒊";
}

.mdi-send-check:before {
  content: "󱅡";
}

.mdi-send-check-outline:before {
  content: "󱅢";
}

.mdi-send-circle:before {
  content: "󰷸";
}

.mdi-send-circle-outline:before {
  content: "󰷹";
}

.mdi-send-clock:before {
  content: "󱅣";
}

.mdi-send-clock-outline:before {
  content: "󱅤";
}

.mdi-send-lock:before {
  content: "󰟭";
}

.mdi-send-lock-outline:before {
  content: "󱅦";
}

.mdi-send-outline:before {
  content: "󱅥";
}

.mdi-serial-port:before {
  content: "󰙜";
}

.mdi-server:before {
  content: "󰒋";
}

.mdi-server-minus:before {
  content: "󰒌";
}

.mdi-server-network:before {
  content: "󰒍";
}

.mdi-server-network-off:before {
  content: "󰒎";
}

.mdi-server-off:before {
  content: "󰒏";
}

.mdi-server-plus:before {
  content: "󰒐";
}

.mdi-server-remove:before {
  content: "󰒑";
}

.mdi-server-security:before {
  content: "󰒒";
}

.mdi-set-all:before {
  content: "󰝸";
}

.mdi-set-center:before {
  content: "󰝹";
}

.mdi-set-center-right:before {
  content: "󰝺";
}

.mdi-set-left:before {
  content: "󰝻";
}

.mdi-set-left-center:before {
  content: "󰝼";
}

.mdi-set-left-right:before {
  content: "󰝽";
}

.mdi-set-merge:before {
  content: "󱓠";
}

.mdi-set-none:before {
  content: "󰝾";
}

.mdi-set-right:before {
  content: "󰝿";
}

.mdi-set-split:before {
  content: "󱓡";
}

.mdi-set-square:before {
  content: "󱑝";
}

.mdi-set-top-box:before {
  content: "󰦟";
}

.mdi-settings-helper:before {
  content: "󰩮";
}

.mdi-shaker:before {
  content: "󱄎";
}

.mdi-shaker-outline:before {
  content: "󱄏";
}

.mdi-shape:before {
  content: "󰠱";
}

.mdi-shape-circle-plus:before {
  content: "󰙝";
}

.mdi-shape-outline:before {
  content: "󰠲";
}

.mdi-shape-oval-plus:before {
  content: "󱇺";
}

.mdi-shape-plus:before {
  content: "󰒕";
}

.mdi-shape-polygon-plus:before {
  content: "󰙞";
}

.mdi-shape-rectangle-plus:before {
  content: "󰙟";
}

.mdi-shape-square-plus:before {
  content: "󰙠";
}

.mdi-shape-square-rounded-plus:before {
  content: "󱓺";
}

.mdi-share:before {
  content: "󰒖";
}

.mdi-share-all:before {
  content: "󱇴";
}

.mdi-share-all-outline:before {
  content: "󱇵";
}

.mdi-share-circle:before {
  content: "󱆭";
}

.mdi-share-off:before {
  content: "󰼣";
}

.mdi-share-off-outline:before {
  content: "󰼤";
}

.mdi-share-outline:before {
  content: "󰤲";
}

.mdi-share-variant:before {
  content: "󰒗";
}

.mdi-share-variant-outline:before {
  content: "󱔔";
}

.mdi-shark-fin:before {
  content: "󱙳";
}

.mdi-shark-fin-outline:before {
  content: "󱙴";
}

.mdi-sheep:before {
  content: "󰳆";
}

.mdi-shield:before {
  content: "󰒘";
}

.mdi-shield-account:before {
  content: "󰢏";
}

.mdi-shield-account-outline:before {
  content: "󰨒";
}

.mdi-shield-account-variant:before {
  content: "󱖧";
}

.mdi-shield-account-variant-outline:before {
  content: "󱖨";
}

.mdi-shield-airplane:before {
  content: "󰚻";
}

.mdi-shield-airplane-outline:before {
  content: "󰳇";
}

.mdi-shield-alert:before {
  content: "󰻌";
}

.mdi-shield-alert-outline:before {
  content: "󰻍";
}

.mdi-shield-bug:before {
  content: "󱏚";
}

.mdi-shield-bug-outline:before {
  content: "󱏛";
}

.mdi-shield-car:before {
  content: "󰾃";
}

.mdi-shield-check:before {
  content: "󰕥";
}

.mdi-shield-check-outline:before {
  content: "󰳈";
}

.mdi-shield-cross:before {
  content: "󰳉";
}

.mdi-shield-cross-outline:before {
  content: "󰳊";
}

.mdi-shield-edit:before {
  content: "󱆠";
}

.mdi-shield-edit-outline:before {
  content: "󱆡";
}

.mdi-shield-half:before {
  content: "󱍠";
}

.mdi-shield-half-full:before {
  content: "󰞀";
}

.mdi-shield-home:before {
  content: "󰚊";
}

.mdi-shield-home-outline:before {
  content: "󰳋";
}

.mdi-shield-key:before {
  content: "󰯄";
}

.mdi-shield-key-outline:before {
  content: "󰯅";
}

.mdi-shield-link-variant:before {
  content: "󰴳";
}

.mdi-shield-link-variant-outline:before {
  content: "󰴴";
}

.mdi-shield-lock:before {
  content: "󰦝";
}

.mdi-shield-lock-outline:before {
  content: "󰳌";
}

.mdi-shield-off:before {
  content: "󰦞";
}

.mdi-shield-off-outline:before {
  content: "󰦜";
}

.mdi-shield-outline:before {
  content: "󰒙";
}

.mdi-shield-plus:before {
  content: "󰫚";
}

.mdi-shield-plus-outline:before {
  content: "󰫛";
}

.mdi-shield-refresh:before {
  content: "󰂪";
}

.mdi-shield-refresh-outline:before {
  content: "󰇠";
}

.mdi-shield-remove:before {
  content: "󰫜";
}

.mdi-shield-remove-outline:before {
  content: "󰫝";
}

.mdi-shield-search:before {
  content: "󰶚";
}

.mdi-shield-star:before {
  content: "󱄻";
}

.mdi-shield-star-outline:before {
  content: "󱄼";
}

.mdi-shield-sun:before {
  content: "󱁝";
}

.mdi-shield-sun-outline:before {
  content: "󱁞";
}

.mdi-shield-sync:before {
  content: "󱆢";
}

.mdi-shield-sync-outline:before {
  content: "󱆣";
}

.mdi-ship-wheel:before {
  content: "󰠳";
}

.mdi-shoe-ballet:before {
  content: "󱗊";
}

.mdi-shoe-cleat:before {
  content: "󱗇";
}

.mdi-shoe-formal:before {
  content: "󰭇";
}

.mdi-shoe-heel:before {
  content: "󰭈";
}

.mdi-shoe-print:before {
  content: "󰷺";
}

.mdi-shoe-sneaker:before {
  content: "󱗈";
}

.mdi-shopping:before {
  content: "󰒚";
}

.mdi-shopping-music:before {
  content: "󰒛";
}

.mdi-shopping-outline:before {
  content: "󱇕";
}

.mdi-shopping-search:before {
  content: "󰾄";
}

.mdi-shore:before {
  content: "󱓹";
}

.mdi-shovel:before {
  content: "󰜐";
}

.mdi-shovel-off:before {
  content: "󰜑";
}

.mdi-shower:before {
  content: "󰦠";
}

.mdi-shower-head:before {
  content: "󰦡";
}

.mdi-shredder:before {
  content: "󰒜";
}

.mdi-shuffle:before {
  content: "󰒝";
}

.mdi-shuffle-disabled:before {
  content: "󰒞";
}

.mdi-shuffle-variant:before {
  content: "󰒟";
}

.mdi-shuriken:before {
  content: "󱍿";
}

.mdi-sigma:before {
  content: "󰒠";
}

.mdi-sigma-lower:before {
  content: "󰘫";
}

.mdi-sign-caution:before {
  content: "󰒡";
}

.mdi-sign-direction:before {
  content: "󰞁";
}

.mdi-sign-direction-minus:before {
  content: "󱀀";
}

.mdi-sign-direction-plus:before {
  content: "󰿜";
}

.mdi-sign-direction-remove:before {
  content: "󰿝";
}

.mdi-sign-pole:before {
  content: "󱓸";
}

.mdi-sign-real-estate:before {
  content: "󱄘";
}

.mdi-sign-text:before {
  content: "󰞂";
}

.mdi-signal:before {
  content: "󰒢";
}

.mdi-signal-2g:before {
  content: "󰜒";
}

.mdi-signal-3g:before {
  content: "󰜓";
}

.mdi-signal-4g:before {
  content: "󰜔";
}

.mdi-signal-5g:before {
  content: "󰩯";
}

.mdi-signal-cellular-1:before {
  content: "󰢼";
}

.mdi-signal-cellular-2:before {
  content: "󰢽";
}

.mdi-signal-cellular-3:before {
  content: "󰢾";
}

.mdi-signal-cellular-outline:before {
  content: "󰢿";
}

.mdi-signal-distance-variant:before {
  content: "󰹤";
}

.mdi-signal-hspa:before {
  content: "󰜕";
}

.mdi-signal-hspa-plus:before {
  content: "󰜖";
}

.mdi-signal-off:before {
  content: "󰞃";
}

.mdi-signal-variant:before {
  content: "󰘊";
}

.mdi-signature:before {
  content: "󰷻";
}

.mdi-signature-freehand:before {
  content: "󰷼";
}

.mdi-signature-image:before {
  content: "󰷽";
}

.mdi-signature-text:before {
  content: "󰷾";
}

.mdi-silo:before {
  content: "󰭉";
}

.mdi-silverware:before {
  content: "󰒣";
}

.mdi-silverware-clean:before {
  content: "󰿞";
}

.mdi-silverware-fork:before {
  content: "󰒤";
}

.mdi-silverware-fork-knife:before {
  content: "󰩰";
}

.mdi-silverware-spoon:before {
  content: "󰒥";
}

.mdi-silverware-variant:before {
  content: "󰒦";
}

.mdi-sim:before {
  content: "󰒧";
}

.mdi-sim-alert:before {
  content: "󰒨";
}

.mdi-sim-alert-outline:before {
  content: "󱗓";
}

.mdi-sim-off:before {
  content: "󰒩";
}

.mdi-sim-off-outline:before {
  content: "󱗔";
}

.mdi-sim-outline:before {
  content: "󱗕";
}

.mdi-simple-icons:before {
  content: "󱌝";
}

.mdi-sina-weibo:before {
  content: "󰫟";
}

.mdi-sine-wave:before {
  content: "󰥛";
}

.mdi-sitemap:before {
  content: "󰒪";
}

.mdi-size-l:before {
  content: "󱎦";
}

.mdi-size-m:before {
  content: "󱎥";
}

.mdi-size-s:before {
  content: "󱎤";
}

.mdi-size-xl:before {
  content: "󱎧";
}

.mdi-size-xs:before {
  content: "󱎣";
}

.mdi-size-xxl:before {
  content: "󱎨";
}

.mdi-size-xxs:before {
  content: "󱎢";
}

.mdi-size-xxxl:before {
  content: "󱎩";
}

.mdi-skate:before {
  content: "󰴵";
}

.mdi-skateboard:before {
  content: "󱓂";
}

.mdi-skew-less:before {
  content: "󰴶";
}

.mdi-skew-more:before {
  content: "󰴷";
}

.mdi-ski:before {
  content: "󱌄";
}

.mdi-ski-cross-country:before {
  content: "󱌅";
}

.mdi-ski-water:before {
  content: "󱌆";
}

.mdi-skip-backward:before {
  content: "󰒫";
}

.mdi-skip-backward-outline:before {
  content: "󰼥";
}

.mdi-skip-forward:before {
  content: "󰒬";
}

.mdi-skip-forward-outline:before {
  content: "󰼦";
}

.mdi-skip-next:before {
  content: "󰒭";
}

.mdi-skip-next-circle:before {
  content: "󰙡";
}

.mdi-skip-next-circle-outline:before {
  content: "󰙢";
}

.mdi-skip-next-outline:before {
  content: "󰼧";
}

.mdi-skip-previous:before {
  content: "󰒮";
}

.mdi-skip-previous-circle:before {
  content: "󰙣";
}

.mdi-skip-previous-circle-outline:before {
  content: "󰙤";
}

.mdi-skip-previous-outline:before {
  content: "󰼨";
}

.mdi-skull:before {
  content: "󰚌";
}

.mdi-skull-crossbones:before {
  content: "󰯆";
}

.mdi-skull-crossbones-outline:before {
  content: "󰯇";
}

.mdi-skull-outline:before {
  content: "󰯈";
}

.mdi-skull-scan:before {
  content: "󱓇";
}

.mdi-skull-scan-outline:before {
  content: "󱓈";
}

.mdi-skype:before {
  content: "󰒯";
}

.mdi-skype-business:before {
  content: "󰒰";
}

.mdi-slack:before {
  content: "󰒱";
}

.mdi-slash-forward:before {
  content: "󰿟";
}

.mdi-slash-forward-box:before {
  content: "󰿠";
}

.mdi-sleep:before {
  content: "󰒲";
}

.mdi-sleep-off:before {
  content: "󰒳";
}

.mdi-slide:before {
  content: "󱖥";
}

.mdi-slope-downhill:before {
  content: "󰷿";
}

.mdi-slope-uphill:before {
  content: "󰸀";
}

.mdi-slot-machine:before {
  content: "󱄔";
}

.mdi-slot-machine-outline:before {
  content: "󱄕";
}

.mdi-smart-card:before {
  content: "󱂽";
}

.mdi-smart-card-outline:before {
  content: "󱂾";
}

.mdi-smart-card-reader:before {
  content: "󱂿";
}

.mdi-smart-card-reader-outline:before {
  content: "󱃀";
}

.mdi-smog:before {
  content: "󰩱";
}

.mdi-smoke-detector:before {
  content: "󰎒";
}

.mdi-smoking:before {
  content: "󰒴";
}

.mdi-smoking-off:before {
  content: "󰒵";
}

.mdi-smoking-pipe:before {
  content: "󱐍";
}

.mdi-smoking-pipe-off:before {
  content: "󱐨";
}

.mdi-snail:before {
  content: "󱙷";
}

.mdi-snake:before {
  content: "󱔎";
}

.mdi-snapchat:before {
  content: "󰒶";
}

.mdi-snowboard:before {
  content: "󱌇";
}

.mdi-snowflake:before {
  content: "󰜗";
}

.mdi-snowflake-alert:before {
  content: "󰼩";
}

.mdi-snowflake-melt:before {
  content: "󱋋";
}

.mdi-snowflake-off:before {
  content: "󱓣";
}

.mdi-snowflake-variant:before {
  content: "󰼪";
}

.mdi-snowman:before {
  content: "󰒷";
}

.mdi-soccer:before {
  content: "󰒸";
}

.mdi-soccer-field:before {
  content: "󰠴";
}

.mdi-social-distance-2-meters:before {
  content: "󱕹";
}

.mdi-social-distance-6-feet:before {
  content: "󱕺";
}

.mdi-sofa:before {
  content: "󰒹";
}

.mdi-sofa-outline:before {
  content: "󱕭";
}

.mdi-sofa-single:before {
  content: "󱕮";
}

.mdi-sofa-single-outline:before {
  content: "󱕯";
}

.mdi-solar-panel:before {
  content: "󰶛";
}

.mdi-solar-panel-large:before {
  content: "󰶜";
}

.mdi-solar-power:before {
  content: "󰩲";
}

.mdi-soldering-iron:before {
  content: "󱂒";
}

.mdi-solid:before {
  content: "󰚍";
}

.mdi-sony-playstation:before {
  content: "󰐔";
}

.mdi-sort:before {
  content: "󰒺";
}

.mdi-sort-alphabetical-ascending:before {
  content: "󰖽";
}

.mdi-sort-alphabetical-ascending-variant:before {
  content: "󱅈";
}

.mdi-sort-alphabetical-descending:before {
  content: "󰖿";
}

.mdi-sort-alphabetical-descending-variant:before {
  content: "󱅉";
}

.mdi-sort-alphabetical-variant:before {
  content: "󰒻";
}

.mdi-sort-ascending:before {
  content: "󰒼";
}

.mdi-sort-bool-ascending:before {
  content: "󱎅";
}

.mdi-sort-bool-ascending-variant:before {
  content: "󱎆";
}

.mdi-sort-bool-descending:before {
  content: "󱎇";
}

.mdi-sort-bool-descending-variant:before {
  content: "󱎈";
}

.mdi-sort-calendar-ascending:before {
  content: "󱕇";
}

.mdi-sort-calendar-descending:before {
  content: "󱕈";
}

.mdi-sort-clock-ascending:before {
  content: "󱕉";
}

.mdi-sort-clock-ascending-outline:before {
  content: "󱕊";
}

.mdi-sort-clock-descending:before {
  content: "󱕋";
}

.mdi-sort-clock-descending-outline:before {
  content: "󱕌";
}

.mdi-sort-descending:before {
  content: "󰒽";
}

.mdi-sort-numeric-ascending:before {
  content: "󱎉";
}

.mdi-sort-numeric-ascending-variant:before {
  content: "󰤍";
}

.mdi-sort-numeric-descending:before {
  content: "󱎊";
}

.mdi-sort-numeric-descending-variant:before {
  content: "󰫒";
}

.mdi-sort-numeric-variant:before {
  content: "󰒾";
}

.mdi-sort-reverse-variant:before {
  content: "󰌼";
}

.mdi-sort-variant:before {
  content: "󰒿";
}

.mdi-sort-variant-lock:before {
  content: "󰳍";
}

.mdi-sort-variant-lock-open:before {
  content: "󰳎";
}

.mdi-sort-variant-remove:before {
  content: "󱅇";
}

.mdi-soundcloud:before {
  content: "󰓀";
}

.mdi-source-branch:before {
  content: "󰘬";
}

.mdi-source-branch-check:before {
  content: "󱓏";
}

.mdi-source-branch-minus:before {
  content: "󱓋";
}

.mdi-source-branch-plus:before {
  content: "󱓊";
}

.mdi-source-branch-refresh:before {
  content: "󱓍";
}

.mdi-source-branch-remove:before {
  content: "󱓌";
}

.mdi-source-branch-sync:before {
  content: "󱓎";
}

.mdi-source-commit:before {
  content: "󰜘";
}

.mdi-source-commit-end:before {
  content: "󰜙";
}

.mdi-source-commit-end-local:before {
  content: "󰜚";
}

.mdi-source-commit-local:before {
  content: "󰜛";
}

.mdi-source-commit-next-local:before {
  content: "󰜜";
}

.mdi-source-commit-start:before {
  content: "󰜝";
}

.mdi-source-commit-start-next-local:before {
  content: "󰜞";
}

.mdi-source-fork:before {
  content: "󰓁";
}

.mdi-source-merge:before {
  content: "󰘭";
}

.mdi-source-pull:before {
  content: "󰓂";
}

.mdi-source-repository:before {
  content: "󰳏";
}

.mdi-source-repository-multiple:before {
  content: "󰳐";
}

.mdi-soy-sauce:before {
  content: "󰟮";
}

.mdi-soy-sauce-off:before {
  content: "󱏼";
}

.mdi-spa:before {
  content: "󰳑";
}

.mdi-spa-outline:before {
  content: "󰳒";
}

.mdi-space-invaders:before {
  content: "󰯉";
}

.mdi-space-station:before {
  content: "󱎃";
}

.mdi-spade:before {
  content: "󰹥";
}

.mdi-sparkles:before {
  content: "󱕅";
}

.mdi-speaker:before {
  content: "󰓃";
}

.mdi-speaker-bluetooth:before {
  content: "󰦢";
}

.mdi-speaker-multiple:before {
  content: "󰴸";
}

.mdi-speaker-off:before {
  content: "󰓄";
}

.mdi-speaker-wireless:before {
  content: "󰜟";
}

.mdi-speedometer:before {
  content: "󰓅";
}

.mdi-speedometer-medium:before {
  content: "󰾅";
}

.mdi-speedometer-slow:before {
  content: "󰾆";
}

.mdi-spellcheck:before {
  content: "󰓆";
}

.mdi-spider:before {
  content: "󱇪";
}

.mdi-spider-thread:before {
  content: "󱇫";
}

.mdi-spider-web:before {
  content: "󰯊";
}

.mdi-spirit-level:before {
  content: "󱓱";
}

.mdi-spoon-sugar:before {
  content: "󱐩";
}

.mdi-spotify:before {
  content: "󰓇";
}

.mdi-spotlight:before {
  content: "󰓈";
}

.mdi-spotlight-beam:before {
  content: "󰓉";
}

.mdi-spray:before {
  content: "󰙥";
}

.mdi-spray-bottle:before {
  content: "󰫠";
}

.mdi-sprinkler:before {
  content: "󱁟";
}

.mdi-sprinkler-variant:before {
  content: "󱁠";
}

.mdi-sprout:before {
  content: "󰹦";
}

.mdi-sprout-outline:before {
  content: "󰹧";
}

.mdi-square:before {
  content: "󰝤";
}

.mdi-square-circle:before {
  content: "󱔀";
}

.mdi-square-edit-outline:before {
  content: "󰤌";
}

.mdi-square-medium:before {
  content: "󰨓";
}

.mdi-square-medium-outline:before {
  content: "󰨔";
}

.mdi-square-off:before {
  content: "󱋮";
}

.mdi-square-off-outline:before {
  content: "󱋯";
}

.mdi-square-outline:before {
  content: "󰝣";
}

.mdi-square-root:before {
  content: "󰞄";
}

.mdi-square-root-box:before {
  content: "󰦣";
}

.mdi-square-rounded:before {
  content: "󱓻";
}

.mdi-square-rounded-outline:before {
  content: "󱓼";
}

.mdi-square-small:before {
  content: "󰨕";
}

.mdi-square-wave:before {
  content: "󱑻";
}

.mdi-squeegee:before {
  content: "󰫡";
}

.mdi-ssh:before {
  content: "󰣀";
}

.mdi-stack-exchange:before {
  content: "󰘋";
}

.mdi-stack-overflow:before {
  content: "󰓌";
}

.mdi-stackpath:before {
  content: "󰍙";
}

.mdi-stadium:before {
  content: "󰿹";
}

.mdi-stadium-variant:before {
  content: "󰜠";
}

.mdi-stairs:before {
  content: "󰓍";
}

.mdi-stairs-box:before {
  content: "󱎞";
}

.mdi-stairs-down:before {
  content: "󱊾";
}

.mdi-stairs-up:before {
  content: "󱊽";
}

.mdi-stamper:before {
  content: "󰴹";
}

.mdi-standard-definition:before {
  content: "󰟯";
}

.mdi-star:before {
  content: "󰓎";
}

.mdi-star-box:before {
  content: "󰩳";
}

.mdi-star-box-multiple:before {
  content: "󱊆";
}

.mdi-star-box-multiple-outline:before {
  content: "󱊇";
}

.mdi-star-box-outline:before {
  content: "󰩴";
}

.mdi-star-check:before {
  content: "󱕦";
}

.mdi-star-check-outline:before {
  content: "󱕪";
}

.mdi-star-circle:before {
  content: "󰓏";
}

.mdi-star-circle-outline:before {
  content: "󰦤";
}

.mdi-star-cog:before {
  content: "󱙨";
}

.mdi-star-cog-outline:before {
  content: "󱙩";
}

.mdi-star-face:before {
  content: "󰦥";
}

.mdi-star-four-points:before {
  content: "󰫢";
}

.mdi-star-four-points-outline:before {
  content: "󰫣";
}

.mdi-star-half:before {
  content: "󰉆";
}

.mdi-star-half-full:before {
  content: "󰓐";
}

.mdi-star-minus:before {
  content: "󱕤";
}

.mdi-star-minus-outline:before {
  content: "󱕨";
}

.mdi-star-off:before {
  content: "󰓑";
}

.mdi-star-off-outline:before {
  content: "󱕛";
}

.mdi-star-outline:before {
  content: "󰓒";
}

.mdi-star-plus:before {
  content: "󱕣";
}

.mdi-star-plus-outline:before {
  content: "󱕧";
}

.mdi-star-remove:before {
  content: "󱕥";
}

.mdi-star-remove-outline:before {
  content: "󱕩";
}

.mdi-star-settings:before {
  content: "󱙪";
}

.mdi-star-settings-outline:before {
  content: "󱙫";
}

.mdi-star-shooting:before {
  content: "󱝁";
}

.mdi-star-shooting-outline:before {
  content: "󱝂";
}

.mdi-star-three-points:before {
  content: "󰫤";
}

.mdi-star-three-points-outline:before {
  content: "󰫥";
}

.mdi-state-machine:before {
  content: "󱇯";
}

.mdi-steam:before {
  content: "󰓓";
}

.mdi-steering:before {
  content: "󰓔";
}

.mdi-steering-off:before {
  content: "󰤎";
}

.mdi-step-backward:before {
  content: "󰓕";
}

.mdi-step-backward-2:before {
  content: "󰓖";
}

.mdi-step-forward:before {
  content: "󰓗";
}

.mdi-step-forward-2:before {
  content: "󰓘";
}

.mdi-stethoscope:before {
  content: "󰓙";
}

.mdi-sticker:before {
  content: "󱍤";
}

.mdi-sticker-alert:before {
  content: "󱍥";
}

.mdi-sticker-alert-outline:before {
  content: "󱍦";
}

.mdi-sticker-check:before {
  content: "󱍧";
}

.mdi-sticker-check-outline:before {
  content: "󱍨";
}

.mdi-sticker-circle-outline:before {
  content: "󰗐";
}

.mdi-sticker-emoji:before {
  content: "󰞅";
}

.mdi-sticker-minus:before {
  content: "󱍩";
}

.mdi-sticker-minus-outline:before {
  content: "󱍪";
}

.mdi-sticker-outline:before {
  content: "󱍫";
}

.mdi-sticker-plus:before {
  content: "󱍬";
}

.mdi-sticker-plus-outline:before {
  content: "󱍭";
}

.mdi-sticker-remove:before {
  content: "󱍮";
}

.mdi-sticker-remove-outline:before {
  content: "󱍯";
}

.mdi-stocking:before {
  content: "󰓚";
}

.mdi-stomach:before {
  content: "󱂓";
}

.mdi-stop:before {
  content: "󰓛";
}

.mdi-stop-circle:before {
  content: "󰙦";
}

.mdi-stop-circle-outline:before {
  content: "󰙧";
}

.mdi-store:before {
  content: "󰓜";
}

.mdi-store-24-hour:before {
  content: "󰓝";
}

.mdi-store-minus:before {
  content: "󱙞";
}

.mdi-store-outline:before {
  content: "󱍡";
}

.mdi-store-plus:before {
  content: "󱙟";
}

.mdi-store-remove:before {
  content: "󱙠";
}

.mdi-storefront:before {
  content: "󰟇";
}

.mdi-storefront-outline:before {
  content: "󱃁";
}

.mdi-stove:before {
  content: "󰓞";
}

.mdi-strategy:before {
  content: "󱇖";
}

.mdi-stretch-to-page:before {
  content: "󰼫";
}

.mdi-stretch-to-page-outline:before {
  content: "󰼬";
}

.mdi-string-lights:before {
  content: "󱊺";
}

.mdi-string-lights-off:before {
  content: "󱊻";
}

.mdi-subdirectory-arrow-left:before {
  content: "󰘌";
}

.mdi-subdirectory-arrow-right:before {
  content: "󰘍";
}

.mdi-submarine:before {
  content: "󱕬";
}

.mdi-subtitles:before {
  content: "󰨖";
}

.mdi-subtitles-outline:before {
  content: "󰨗";
}

.mdi-subway:before {
  content: "󰚬";
}

.mdi-subway-alert-variant:before {
  content: "󰶝";
}

.mdi-subway-variant:before {
  content: "󰓟";
}

.mdi-summit:before {
  content: "󰞆";
}

.mdi-sunglasses:before {
  content: "󰓠";
}

.mdi-surround-sound:before {
  content: "󰗅";
}

.mdi-surround-sound-2-0:before {
  content: "󰟰";
}

.mdi-surround-sound-2-1:before {
  content: "󱜩";
}

.mdi-surround-sound-3-1:before {
  content: "󰟱";
}

.mdi-surround-sound-5-1:before {
  content: "󰟲";
}

.mdi-surround-sound-5-1-2:before {
  content: "󱜪";
}

.mdi-surround-sound-7-1:before {
  content: "󰟳";
}

.mdi-svg:before {
  content: "󰜡";
}

.mdi-swap-horizontal:before {
  content: "󰓡";
}

.mdi-swap-horizontal-bold:before {
  content: "󰯍";
}

.mdi-swap-horizontal-circle:before {
  content: "󰿡";
}

.mdi-swap-horizontal-circle-outline:before {
  content: "󰿢";
}

.mdi-swap-horizontal-variant:before {
  content: "󰣁";
}

.mdi-swap-vertical:before {
  content: "󰓢";
}

.mdi-swap-vertical-bold:before {
  content: "󰯎";
}

.mdi-swap-vertical-circle:before {
  content: "󰿣";
}

.mdi-swap-vertical-circle-outline:before {
  content: "󰿤";
}

.mdi-swap-vertical-variant:before {
  content: "󰣂";
}

.mdi-swim:before {
  content: "󰓣";
}

.mdi-switch:before {
  content: "󰓤";
}

.mdi-sword:before {
  content: "󰓥";
}

.mdi-sword-cross:before {
  content: "󰞇";
}

.mdi-syllabary-hangul:before {
  content: "󱌳";
}

.mdi-syllabary-hiragana:before {
  content: "󱌴";
}

.mdi-syllabary-katakana:before {
  content: "󱌵";
}

.mdi-syllabary-katakana-halfwidth:before {
  content: "󱌶";
}

.mdi-symbol:before {
  content: "󱔁";
}

.mdi-symfony:before {
  content: "󰫦";
}

.mdi-sync:before {
  content: "󰓦";
}

.mdi-sync-alert:before {
  content: "󰓧";
}

.mdi-sync-circle:before {
  content: "󱍸";
}

.mdi-sync-off:before {
  content: "󰓨";
}

.mdi-tab:before {
  content: "󰓩";
}

.mdi-tab-minus:before {
  content: "󰭋";
}

.mdi-tab-plus:before {
  content: "󰝜";
}

.mdi-tab-remove:before {
  content: "󰭌";
}

.mdi-tab-unselected:before {
  content: "󰓪";
}

.mdi-table:before {
  content: "󰓫";
}

.mdi-table-account:before {
  content: "󱎹";
}

.mdi-table-alert:before {
  content: "󱎺";
}

.mdi-table-arrow-down:before {
  content: "󱎻";
}

.mdi-table-arrow-left:before {
  content: "󱎼";
}

.mdi-table-arrow-right:before {
  content: "󱎽";
}

.mdi-table-arrow-up:before {
  content: "󱎾";
}

.mdi-table-border:before {
  content: "󰨘";
}

.mdi-table-cancel:before {
  content: "󱎿";
}

.mdi-table-chair:before {
  content: "󱁡";
}

.mdi-table-check:before {
  content: "󱏀";
}

.mdi-table-clock:before {
  content: "󱏁";
}

.mdi-table-cog:before {
  content: "󱏂";
}

.mdi-table-column:before {
  content: "󰠵";
}

.mdi-table-column-plus-after:before {
  content: "󰓬";
}

.mdi-table-column-plus-before:before {
  content: "󰓭";
}

.mdi-table-column-remove:before {
  content: "󰓮";
}

.mdi-table-column-width:before {
  content: "󰓯";
}

.mdi-table-edit:before {
  content: "󰓰";
}

.mdi-table-eye:before {
  content: "󱂔";
}

.mdi-table-eye-off:before {
  content: "󱏃";
}

.mdi-table-furniture:before {
  content: "󰖼";
}

.mdi-table-headers-eye:before {
  content: "󱈝";
}

.mdi-table-headers-eye-off:before {
  content: "󱈞";
}

.mdi-table-heart:before {
  content: "󱏄";
}

.mdi-table-key:before {
  content: "󱏅";
}

.mdi-table-large:before {
  content: "󰓱";
}

.mdi-table-large-plus:before {
  content: "󰾇";
}

.mdi-table-large-remove:before {
  content: "󰾈";
}

.mdi-table-lock:before {
  content: "󱏆";
}

.mdi-table-merge-cells:before {
  content: "󰦦";
}

.mdi-table-minus:before {
  content: "󱏇";
}

.mdi-table-multiple:before {
  content: "󱏈";
}

.mdi-table-network:before {
  content: "󱏉";
}

.mdi-table-of-contents:before {
  content: "󰠶";
}

.mdi-table-off:before {
  content: "󱏊";
}

.mdi-table-picnic:before {
  content: "󱝃";
}

.mdi-table-plus:before {
  content: "󰩵";
}

.mdi-table-refresh:before {
  content: "󱎠";
}

.mdi-table-remove:before {
  content: "󰩶";
}

.mdi-table-row:before {
  content: "󰠷";
}

.mdi-table-row-height:before {
  content: "󰓲";
}

.mdi-table-row-plus-after:before {
  content: "󰓳";
}

.mdi-table-row-plus-before:before {
  content: "󰓴";
}

.mdi-table-row-remove:before {
  content: "󰓵";
}

.mdi-table-search:before {
  content: "󰤏";
}

.mdi-table-settings:before {
  content: "󰠸";
}

.mdi-table-split-cell:before {
  content: "󱐪";
}

.mdi-table-star:before {
  content: "󱏋";
}

.mdi-table-sync:before {
  content: "󱎡";
}

.mdi-table-tennis:before {
  content: "󰹨";
}

.mdi-tablet:before {
  content: "󰓶";
}

.mdi-tablet-android:before {
  content: "󰓷";
}

.mdi-tablet-cellphone:before {
  content: "󰦧";
}

.mdi-tablet-dashboard:before {
  content: "󰻎";
}

.mdi-tablet-ipad:before {
  content: "󰓸";
}

.mdi-taco:before {
  content: "󰝢";
}

.mdi-tag:before {
  content: "󰓹";
}

.mdi-tag-arrow-down:before {
  content: "󱜫";
}

.mdi-tag-arrow-down-outline:before {
  content: "󱜬";
}

.mdi-tag-arrow-left:before {
  content: "󱜭";
}

.mdi-tag-arrow-left-outline:before {
  content: "󱜮";
}

.mdi-tag-arrow-right:before {
  content: "󱜯";
}

.mdi-tag-arrow-right-outline:before {
  content: "󱜰";
}

.mdi-tag-arrow-up:before {
  content: "󱜱";
}

.mdi-tag-arrow-up-outline:before {
  content: "󱜲";
}

.mdi-tag-faces:before {
  content: "󰓺";
}

.mdi-tag-heart:before {
  content: "󰚋";
}

.mdi-tag-heart-outline:before {
  content: "󰯏";
}

.mdi-tag-minus:before {
  content: "󰤐";
}

.mdi-tag-minus-outline:before {
  content: "󱈟";
}

.mdi-tag-multiple:before {
  content: "󰓻";
}

.mdi-tag-multiple-outline:before {
  content: "󱋷";
}

.mdi-tag-off:before {
  content: "󱈠";
}

.mdi-tag-off-outline:before {
  content: "󱈡";
}

.mdi-tag-outline:before {
  content: "󰓼";
}

.mdi-tag-plus:before {
  content: "󰜢";
}

.mdi-tag-plus-outline:before {
  content: "󱈢";
}

.mdi-tag-remove:before {
  content: "󰜣";
}

.mdi-tag-remove-outline:before {
  content: "󱈣";
}

.mdi-tag-text:before {
  content: "󱈤";
}

.mdi-tag-text-outline:before {
  content: "󰓽";
}

.mdi-tailwind:before {
  content: "󱏿";
}

.mdi-tank:before {
  content: "󰴺";
}

.mdi-tanker-truck:before {
  content: "󰿥";
}

.mdi-tape-drive:before {
  content: "󱛟";
}

.mdi-tape-measure:before {
  content: "󰭍";
}

.mdi-target:before {
  content: "󰓾";
}

.mdi-target-account:before {
  content: "󰯐";
}

.mdi-target-variant:before {
  content: "󰩷";
}

.mdi-taxi:before {
  content: "󰓿";
}

.mdi-tea:before {
  content: "󰶞";
}

.mdi-tea-outline:before {
  content: "󰶟";
}

.mdi-teach:before {
  content: "󰢐";
}

.mdi-teamviewer:before {
  content: "󰔀";
}

.mdi-telegram:before {
  content: "󰔁";
}

.mdi-telescope:before {
  content: "󰭎";
}

.mdi-television:before {
  content: "󰔂";
}

.mdi-television-ambient-light:before {
  content: "󱍖";
}

.mdi-television-box:before {
  content: "󰠹";
}

.mdi-television-classic:before {
  content: "󰟴";
}

.mdi-television-classic-off:before {
  content: "󰠺";
}

.mdi-television-clean:before {
  content: "󱄐";
}

.mdi-television-guide:before {
  content: "󰔃";
}

.mdi-television-off:before {
  content: "󰠻";
}

.mdi-television-pause:before {
  content: "󰾉";
}

.mdi-television-play:before {
  content: "󰻏";
}

.mdi-television-stop:before {
  content: "󰾊";
}

.mdi-temperature-celsius:before {
  content: "󰔄";
}

.mdi-temperature-fahrenheit:before {
  content: "󰔅";
}

.mdi-temperature-kelvin:before {
  content: "󰔆";
}

.mdi-tennis:before {
  content: "󰶠";
}

.mdi-tennis-ball:before {
  content: "󰔇";
}

.mdi-tent:before {
  content: "󰔈";
}

.mdi-terraform:before {
  content: "󱁢";
}

.mdi-terrain:before {
  content: "󰔉";
}

.mdi-test-tube:before {
  content: "󰙨";
}

.mdi-test-tube-empty:before {
  content: "󰤑";
}

.mdi-test-tube-off:before {
  content: "󰤒";
}

.mdi-text:before {
  content: "󰦨";
}

.mdi-text-account:before {
  content: "󱕰";
}

.mdi-text-box:before {
  content: "󰈚";
}

.mdi-text-box-check:before {
  content: "󰺦";
}

.mdi-text-box-check-outline:before {
  content: "󰺧";
}

.mdi-text-box-minus:before {
  content: "󰺨";
}

.mdi-text-box-minus-outline:before {
  content: "󰺩";
}

.mdi-text-box-multiple:before {
  content: "󰪷";
}

.mdi-text-box-multiple-outline:before {
  content: "󰪸";
}

.mdi-text-box-outline:before {
  content: "󰧭";
}

.mdi-text-box-plus:before {
  content: "󰺪";
}

.mdi-text-box-plus-outline:before {
  content: "󰺫";
}

.mdi-text-box-remove:before {
  content: "󰺬";
}

.mdi-text-box-remove-outline:before {
  content: "󰺭";
}

.mdi-text-box-search:before {
  content: "󰺮";
}

.mdi-text-box-search-outline:before {
  content: "󰺯";
}

.mdi-text-recognition:before {
  content: "󱄽";
}

.mdi-text-search:before {
  content: "󱎸";
}

.mdi-text-shadow:before {
  content: "󰙩";
}

.mdi-text-short:before {
  content: "󰦩";
}

.mdi-text-subject:before {
  content: "󰦪";
}

.mdi-text-to-speech:before {
  content: "󰔊";
}

.mdi-text-to-speech-off:before {
  content: "󰔋";
}

.mdi-texture:before {
  content: "󰔌";
}

.mdi-texture-box:before {
  content: "󰿦";
}

.mdi-theater:before {
  content: "󰔍";
}

.mdi-theme-light-dark:before {
  content: "󰔎";
}

.mdi-thermometer:before {
  content: "󰔏";
}

.mdi-thermometer-alert:before {
  content: "󰸁";
}

.mdi-thermometer-chevron-down:before {
  content: "󰸂";
}

.mdi-thermometer-chevron-up:before {
  content: "󰸃";
}

.mdi-thermometer-high:before {
  content: "󱃂";
}

.mdi-thermometer-lines:before {
  content: "󰔐";
}

.mdi-thermometer-low:before {
  content: "󱃃";
}

.mdi-thermometer-minus:before {
  content: "󰸄";
}

.mdi-thermometer-off:before {
  content: "󱔱";
}

.mdi-thermometer-plus:before {
  content: "󰸅";
}

.mdi-thermostat:before {
  content: "󰎓";
}

.mdi-thermostat-box:before {
  content: "󰢑";
}

.mdi-thought-bubble:before {
  content: "󰟶";
}

.mdi-thought-bubble-outline:before {
  content: "󰟷";
}

.mdi-thumb-down:before {
  content: "󰔑";
}

.mdi-thumb-down-outline:before {
  content: "󰔒";
}

.mdi-thumb-up:before {
  content: "󰔓";
}

.mdi-thumb-up-outline:before {
  content: "󰔔";
}

.mdi-thumbs-up-down:before {
  content: "󰔕";
}

.mdi-ticket:before {
  content: "󰔖";
}

.mdi-ticket-account:before {
  content: "󰔗";
}

.mdi-ticket-confirmation:before {
  content: "󰔘";
}

.mdi-ticket-confirmation-outline:before {
  content: "󱎪";
}

.mdi-ticket-outline:before {
  content: "󰤓";
}

.mdi-ticket-percent:before {
  content: "󰜤";
}

.mdi-ticket-percent-outline:before {
  content: "󱐫";
}

.mdi-tie:before {
  content: "󰔙";
}

.mdi-tilde:before {
  content: "󰜥";
}

.mdi-timelapse:before {
  content: "󰔚";
}

.mdi-timeline:before {
  content: "󰯑";
}

.mdi-timeline-alert:before {
  content: "󰾕";
}

.mdi-timeline-alert-outline:before {
  content: "󰾘";
}

.mdi-timeline-check:before {
  content: "󱔲";
}

.mdi-timeline-check-outline:before {
  content: "󱔳";
}

.mdi-timeline-clock:before {
  content: "󱇻";
}

.mdi-timeline-clock-outline:before {
  content: "󱇼";
}

.mdi-timeline-help:before {
  content: "󰾙";
}

.mdi-timeline-help-outline:before {
  content: "󰾚";
}

.mdi-timeline-minus:before {
  content: "󱔴";
}

.mdi-timeline-minus-outline:before {
  content: "󱔵";
}

.mdi-timeline-outline:before {
  content: "󰯒";
}

.mdi-timeline-plus:before {
  content: "󰾖";
}

.mdi-timeline-plus-outline:before {
  content: "󰾗";
}

.mdi-timeline-remove:before {
  content: "󱔶";
}

.mdi-timeline-remove-outline:before {
  content: "󱔷";
}

.mdi-timeline-text:before {
  content: "󰯓";
}

.mdi-timeline-text-outline:before {
  content: "󰯔";
}

.mdi-timer:before {
  content: "󱎫";
}

.mdi-timer-10:before {
  content: "󰔜";
}

.mdi-timer-3:before {
  content: "󰔝";
}

.mdi-timer-off:before {
  content: "󱎬";
}

.mdi-timer-off-outline:before {
  content: "󰔞";
}

.mdi-timer-outline:before {
  content: "󰔛";
}

.mdi-timer-sand:before {
  content: "󰔟";
}

.mdi-timer-sand-empty:before {
  content: "󰚭";
}

.mdi-timer-sand-full:before {
  content: "󰞌";
}

.mdi-timetable:before {
  content: "󰔠";
}

.mdi-toaster:before {
  content: "󱁣";
}

.mdi-toaster-off:before {
  content: "󱆷";
}

.mdi-toaster-oven:before {
  content: "󰳓";
}

.mdi-toggle-switch:before {
  content: "󰔡";
}

.mdi-toggle-switch-off:before {
  content: "󰔢";
}

.mdi-toggle-switch-off-outline:before {
  content: "󰨙";
}

.mdi-toggle-switch-outline:before {
  content: "󰨚";
}

.mdi-toilet:before {
  content: "󰦫";
}

.mdi-toolbox:before {
  content: "󰦬";
}

.mdi-toolbox-outline:before {
  content: "󰦭";
}

.mdi-tools:before {
  content: "󱁤";
}

.mdi-tooltip:before {
  content: "󰔣";
}

.mdi-tooltip-account:before {
  content: "󰀌";
}

.mdi-tooltip-check:before {
  content: "󱕜";
}

.mdi-tooltip-check-outline:before {
  content: "󱕝";
}

.mdi-tooltip-edit:before {
  content: "󰔤";
}

.mdi-tooltip-edit-outline:before {
  content: "󱋅";
}

.mdi-tooltip-image:before {
  content: "󰔥";
}

.mdi-tooltip-image-outline:before {
  content: "󰯕";
}

.mdi-tooltip-minus:before {
  content: "󱕞";
}

.mdi-tooltip-minus-outline:before {
  content: "󱕟";
}

.mdi-tooltip-outline:before {
  content: "󰔦";
}

.mdi-tooltip-plus:before {
  content: "󰯖";
}

.mdi-tooltip-plus-outline:before {
  content: "󰔧";
}

.mdi-tooltip-remove:before {
  content: "󱕠";
}

.mdi-tooltip-remove-outline:before {
  content: "󱕡";
}

.mdi-tooltip-text:before {
  content: "󰔨";
}

.mdi-tooltip-text-outline:before {
  content: "󰯗";
}

.mdi-tooth:before {
  content: "󰣃";
}

.mdi-tooth-outline:before {
  content: "󰔩";
}

.mdi-toothbrush:before {
  content: "󱄩";
}

.mdi-toothbrush-electric:before {
  content: "󱄬";
}

.mdi-toothbrush-paste:before {
  content: "󱄪";
}

.mdi-torch:before {
  content: "󱘆";
}

.mdi-tortoise:before {
  content: "󰴻";
}

.mdi-toslink:before {
  content: "󱊸";
}

.mdi-tournament:before {
  content: "󰦮";
}

.mdi-tow-truck:before {
  content: "󰠼";
}

.mdi-tower-beach:before {
  content: "󰚁";
}

.mdi-tower-fire:before {
  content: "󰚂";
}

.mdi-toy-brick:before {
  content: "󱊈";
}

.mdi-toy-brick-marker:before {
  content: "󱊉";
}

.mdi-toy-brick-marker-outline:before {
  content: "󱊊";
}

.mdi-toy-brick-minus:before {
  content: "󱊋";
}

.mdi-toy-brick-minus-outline:before {
  content: "󱊌";
}

.mdi-toy-brick-outline:before {
  content: "󱊍";
}

.mdi-toy-brick-plus:before {
  content: "󱊎";
}

.mdi-toy-brick-plus-outline:before {
  content: "󱊏";
}

.mdi-toy-brick-remove:before {
  content: "󱊐";
}

.mdi-toy-brick-remove-outline:before {
  content: "󱊑";
}

.mdi-toy-brick-search:before {
  content: "󱊒";
}

.mdi-toy-brick-search-outline:before {
  content: "󱊓";
}

.mdi-track-light:before {
  content: "󰤔";
}

.mdi-trackpad:before {
  content: "󰟸";
}

.mdi-trackpad-lock:before {
  content: "󰤳";
}

.mdi-tractor:before {
  content: "󰢒";
}

.mdi-tractor-variant:before {
  content: "󱓄";
}

.mdi-trademark:before {
  content: "󰩸";
}

.mdi-traffic-cone:before {
  content: "󱍼";
}

.mdi-traffic-light:before {
  content: "󰔫";
}

.mdi-train:before {
  content: "󰔬";
}

.mdi-train-car:before {
  content: "󰯘";
}

.mdi-train-car-passenger:before {
  content: "󱜳";
}

.mdi-train-car-passenger-door:before {
  content: "󱜴";
}

.mdi-train-car-passenger-door-open:before {
  content: "󱜵";
}

.mdi-train-car-passenger-variant:before {
  content: "󱜶";
}

.mdi-train-variant:before {
  content: "󰣄";
}

.mdi-tram:before {
  content: "󰔭";
}

.mdi-tram-side:before {
  content: "󰿧";
}

.mdi-transcribe:before {
  content: "󰔮";
}

.mdi-transcribe-close:before {
  content: "󰔯";
}

.mdi-transfer:before {
  content: "󱁥";
}

.mdi-transfer-down:before {
  content: "󰶡";
}

.mdi-transfer-left:before {
  content: "󰶢";
}

.mdi-transfer-right:before {
  content: "󰔰";
}

.mdi-transfer-up:before {
  content: "󰶣";
}

.mdi-transit-connection:before {
  content: "󰴼";
}

.mdi-transit-connection-horizontal:before {
  content: "󱕆";
}

.mdi-transit-connection-variant:before {
  content: "󰴽";
}

.mdi-transit-detour:before {
  content: "󰾋";
}

.mdi-transit-skip:before {
  content: "󱔕";
}

.mdi-transit-transfer:before {
  content: "󰚮";
}

.mdi-transition:before {
  content: "󰤕";
}

.mdi-transition-masked:before {
  content: "󰤖";
}

.mdi-translate:before {
  content: "󰗊";
}

.mdi-translate-off:before {
  content: "󰸆";
}

.mdi-transmission-tower:before {
  content: "󰴾";
}

.mdi-trash-can:before {
  content: "󰩹";
}

.mdi-trash-can-outline:before {
  content: "󰩺";
}

.mdi-tray:before {
  content: "󱊔";
}

.mdi-tray-alert:before {
  content: "󱊕";
}

.mdi-tray-full:before {
  content: "󱊖";
}

.mdi-tray-minus:before {
  content: "󱊗";
}

.mdi-tray-plus:before {
  content: "󱊘";
}

.mdi-tray-remove:before {
  content: "󱊙";
}

.mdi-treasure-chest:before {
  content: "󰜦";
}

.mdi-tree:before {
  content: "󰔱";
}

.mdi-tree-outline:before {
  content: "󰹩";
}

.mdi-trello:before {
  content: "󰔲";
}

.mdi-trending-down:before {
  content: "󰔳";
}

.mdi-trending-neutral:before {
  content: "󰔴";
}

.mdi-trending-up:before {
  content: "󰔵";
}

.mdi-triangle:before {
  content: "󰔶";
}

.mdi-triangle-outline:before {
  content: "󰔷";
}

.mdi-triangle-wave:before {
  content: "󱑼";
}

.mdi-triforce:before {
  content: "󰯙";
}

.mdi-trophy:before {
  content: "󰔸";
}

.mdi-trophy-award:before {
  content: "󰔹";
}

.mdi-trophy-broken:before {
  content: "󰶤";
}

.mdi-trophy-outline:before {
  content: "󰔺";
}

.mdi-trophy-variant:before {
  content: "󰔻";
}

.mdi-trophy-variant-outline:before {
  content: "󰔼";
}

.mdi-truck:before {
  content: "󰔽";
}

.mdi-truck-check:before {
  content: "󰳔";
}

.mdi-truck-check-outline:before {
  content: "󱊚";
}

.mdi-truck-delivery:before {
  content: "󰔾";
}

.mdi-truck-delivery-outline:before {
  content: "󱊛";
}

.mdi-truck-fast:before {
  content: "󰞈";
}

.mdi-truck-fast-outline:before {
  content: "󱊜";
}

.mdi-truck-outline:before {
  content: "󱊝";
}

.mdi-truck-trailer:before {
  content: "󰜧";
}

.mdi-trumpet:before {
  content: "󱂖";
}

.mdi-tshirt-crew:before {
  content: "󰩻";
}

.mdi-tshirt-crew-outline:before {
  content: "󰔿";
}

.mdi-tshirt-v:before {
  content: "󰩼";
}

.mdi-tshirt-v-outline:before {
  content: "󰕀";
}

.mdi-tumble-dryer:before {
  content: "󰤗";
}

.mdi-tumble-dryer-alert:before {
  content: "󱆺";
}

.mdi-tumble-dryer-off:before {
  content: "󱆻";
}

.mdi-tune:before {
  content: "󰘮";
}

.mdi-tune-variant:before {
  content: "󱕂";
}

.mdi-tune-vertical:before {
  content: "󰙪";
}

.mdi-tune-vertical-variant:before {
  content: "󱕃";
}

.mdi-turkey:before {
  content: "󱜛";
}

.mdi-turnstile:before {
  content: "󰳕";
}

.mdi-turnstile-outline:before {
  content: "󰳖";
}

.mdi-turtle:before {
  content: "󰳗";
}

.mdi-twitch:before {
  content: "󰕃";
}

.mdi-twitter:before {
  content: "󰕄";
}

.mdi-twitter-retweet:before {
  content: "󰕇";
}

.mdi-two-factor-authentication:before {
  content: "󰦯";
}

.mdi-typewriter:before {
  content: "󰼭";
}

.mdi-ubisoft:before {
  content: "󰯚";
}

.mdi-ubuntu:before {
  content: "󰕈";
}

.mdi-ufo:before {
  content: "󱃄";
}

.mdi-ufo-outline:before {
  content: "󱃅";
}

.mdi-ultra-high-definition:before {
  content: "󰟹";
}

.mdi-umbraco:before {
  content: "󰕉";
}

.mdi-umbrella:before {
  content: "󰕊";
}

.mdi-umbrella-closed:before {
  content: "󰦰";
}

.mdi-umbrella-closed-outline:before {
  content: "󱏢";
}

.mdi-umbrella-closed-variant:before {
  content: "󱏡";
}

.mdi-umbrella-outline:before {
  content: "󰕋";
}

.mdi-undo:before {
  content: "󰕌";
}

.mdi-undo-variant:before {
  content: "󰕍";
}

.mdi-unfold-less-horizontal:before {
  content: "󰕎";
}

.mdi-unfold-less-vertical:before {
  content: "󰝠";
}

.mdi-unfold-more-horizontal:before {
  content: "󰕏";
}

.mdi-unfold-more-vertical:before {
  content: "󰝡";
}

.mdi-ungroup:before {
  content: "󰕐";
}

.mdi-unicode:before {
  content: "󰻐";
}

.mdi-unicorn:before {
  content: "󱗂";
}

.mdi-unicorn-variant:before {
  content: "󱗃";
}

.mdi-unicycle:before {
  content: "󱗥";
}

.mdi-unity:before {
  content: "󰚯";
}

.mdi-unreal:before {
  content: "󰦱";
}

.mdi-untappd:before {
  content: "󰕑";
}

.mdi-update:before {
  content: "󰚰";
}

.mdi-upload:before {
  content: "󰕒";
}

.mdi-upload-lock:before {
  content: "󱍳";
}

.mdi-upload-lock-outline:before {
  content: "󱍴";
}

.mdi-upload-multiple:before {
  content: "󰠽";
}

.mdi-upload-network:before {
  content: "󰛶";
}

.mdi-upload-network-outline:before {
  content: "󰳘";
}

.mdi-upload-off:before {
  content: "󱃆";
}

.mdi-upload-off-outline:before {
  content: "󱃇";
}

.mdi-upload-outline:before {
  content: "󰸇";
}

.mdi-usb:before {
  content: "󰕓";
}

.mdi-usb-flash-drive:before {
  content: "󱊞";
}

.mdi-usb-flash-drive-outline:before {
  content: "󱊟";
}

.mdi-usb-port:before {
  content: "󱇰";
}

.mdi-valve:before {
  content: "󱁦";
}

.mdi-valve-closed:before {
  content: "󱁧";
}

.mdi-valve-open:before {
  content: "󱁨";
}

.mdi-van-passenger:before {
  content: "󰟺";
}

.mdi-van-utility:before {
  content: "󰟻";
}

.mdi-vanish:before {
  content: "󰟼";
}

.mdi-vanish-quarter:before {
  content: "󱕔";
}

.mdi-vanity-light:before {
  content: "󱇡";
}

.mdi-variable:before {
  content: "󰫧";
}

.mdi-variable-box:before {
  content: "󱄑";
}

.mdi-vector-arrange-above:before {
  content: "󰕔";
}

.mdi-vector-arrange-below:before {
  content: "󰕕";
}

.mdi-vector-bezier:before {
  content: "󰫨";
}

.mdi-vector-circle:before {
  content: "󰕖";
}

.mdi-vector-circle-variant:before {
  content: "󰕗";
}

.mdi-vector-combine:before {
  content: "󰕘";
}

.mdi-vector-curve:before {
  content: "󰕙";
}

.mdi-vector-difference:before {
  content: "󰕚";
}

.mdi-vector-difference-ab:before {
  content: "󰕛";
}

.mdi-vector-difference-ba:before {
  content: "󰕜";
}

.mdi-vector-ellipse:before {
  content: "󰢓";
}

.mdi-vector-intersection:before {
  content: "󰕝";
}

.mdi-vector-line:before {
  content: "󰕞";
}

.mdi-vector-link:before {
  content: "󰿨";
}

.mdi-vector-point:before {
  content: "󰕟";
}

.mdi-vector-polygon:before {
  content: "󰕠";
}

.mdi-vector-polyline:before {
  content: "󰕡";
}

.mdi-vector-polyline-edit:before {
  content: "󱈥";
}

.mdi-vector-polyline-minus:before {
  content: "󱈦";
}

.mdi-vector-polyline-plus:before {
  content: "󱈧";
}

.mdi-vector-polyline-remove:before {
  content: "󱈨";
}

.mdi-vector-radius:before {
  content: "󰝊";
}

.mdi-vector-rectangle:before {
  content: "󰗆";
}

.mdi-vector-selection:before {
  content: "󰕢";
}

.mdi-vector-square:before {
  content: "󰀁";
}

.mdi-vector-triangle:before {
  content: "󰕣";
}

.mdi-vector-union:before {
  content: "󰕤";
}

.mdi-vhs:before {
  content: "󰨛";
}

.mdi-vibrate:before {
  content: "󰕦";
}

.mdi-vibrate-off:before {
  content: "󰳙";
}

.mdi-video:before {
  content: "󰕧";
}

.mdi-video-3d:before {
  content: "󰟽";
}

.mdi-video-3d-off:before {
  content: "󱏙";
}

.mdi-video-3d-variant:before {
  content: "󰻑";
}

.mdi-video-4k-box:before {
  content: "󰠾";
}

.mdi-video-account:before {
  content: "󰤙";
}

.mdi-video-box:before {
  content: "󰃽";
}

.mdi-video-box-off:before {
  content: "󰃾";
}

.mdi-video-check:before {
  content: "󱁩";
}

.mdi-video-check-outline:before {
  content: "󱁪";
}

.mdi-video-high-definition:before {
  content: "󱔮";
}

.mdi-video-image:before {
  content: "󰤚";
}

.mdi-video-input-antenna:before {
  content: "󰠿";
}

.mdi-video-input-component:before {
  content: "󰡀";
}

.mdi-video-input-hdmi:before {
  content: "󰡁";
}

.mdi-video-input-scart:before {
  content: "󰾌";
}

.mdi-video-input-svideo:before {
  content: "󰡂";
}

.mdi-video-minus:before {
  content: "󰦲";
}

.mdi-video-minus-outline:before {
  content: "󰊺";
}

.mdi-video-off:before {
  content: "󰕨";
}

.mdi-video-off-outline:before {
  content: "󰯛";
}

.mdi-video-outline:before {
  content: "󰯜";
}

.mdi-video-plus:before {
  content: "󰦳";
}

.mdi-video-plus-outline:before {
  content: "󰇓";
}

.mdi-video-stabilization:before {
  content: "󰤛";
}

.mdi-video-switch:before {
  content: "󰕩";
}

.mdi-video-switch-outline:before {
  content: "󰞐";
}

.mdi-video-vintage:before {
  content: "󰨜";
}

.mdi-video-wireless:before {
  content: "󰻒";
}

.mdi-video-wireless-outline:before {
  content: "󰻓";
}

.mdi-view-agenda:before {
  content: "󰕪";
}

.mdi-view-agenda-outline:before {
  content: "󱇘";
}

.mdi-view-array:before {
  content: "󰕫";
}

.mdi-view-array-outline:before {
  content: "󱒅";
}

.mdi-view-carousel:before {
  content: "󰕬";
}

.mdi-view-carousel-outline:before {
  content: "󱒆";
}

.mdi-view-column:before {
  content: "󰕭";
}

.mdi-view-column-outline:before {
  content: "󱒇";
}

.mdi-view-comfy:before {
  content: "󰹪";
}

.mdi-view-comfy-outline:before {
  content: "󱒈";
}

.mdi-view-compact:before {
  content: "󰹫";
}

.mdi-view-compact-outline:before {
  content: "󰹬";
}

.mdi-view-dashboard:before {
  content: "󰕮";
}

.mdi-view-dashboard-outline:before {
  content: "󰨝";
}

.mdi-view-dashboard-variant:before {
  content: "󰡃";
}

.mdi-view-dashboard-variant-outline:before {
  content: "󱒉";
}

.mdi-view-day:before {
  content: "󰕯";
}

.mdi-view-day-outline:before {
  content: "󱒊";
}

.mdi-view-grid:before {
  content: "󰕰";
}

.mdi-view-grid-outline:before {
  content: "󱇙";
}

.mdi-view-grid-plus:before {
  content: "󰾍";
}

.mdi-view-grid-plus-outline:before {
  content: "󱇚";
}

.mdi-view-headline:before {
  content: "󰕱";
}

.mdi-view-list:before {
  content: "󰕲";
}

.mdi-view-list-outline:before {
  content: "󱒋";
}

.mdi-view-module:before {
  content: "󰕳";
}

.mdi-view-module-outline:before {
  content: "󱒌";
}

.mdi-view-parallel:before {
  content: "󰜨";
}

.mdi-view-parallel-outline:before {
  content: "󱒍";
}

.mdi-view-quilt:before {
  content: "󰕴";
}

.mdi-view-quilt-outline:before {
  content: "󱒎";
}

.mdi-view-sequential:before {
  content: "󰜩";
}

.mdi-view-sequential-outline:before {
  content: "󱒏";
}

.mdi-view-split-horizontal:before {
  content: "󰯋";
}

.mdi-view-split-vertical:before {
  content: "󰯌";
}

.mdi-view-stream:before {
  content: "󰕵";
}

.mdi-view-stream-outline:before {
  content: "󱒐";
}

.mdi-view-week:before {
  content: "󰕶";
}

.mdi-view-week-outline:before {
  content: "󱒑";
}

.mdi-vimeo:before {
  content: "󰕷";
}

.mdi-violin:before {
  content: "󰘏";
}

.mdi-virtual-reality:before {
  content: "󰢔";
}

.mdi-virus:before {
  content: "󱎶";
}

.mdi-virus-outline:before {
  content: "󱎷";
}

.mdi-vk:before {
  content: "󰕹";
}

.mdi-vlc:before {
  content: "󰕼";
}

.mdi-voice-off:before {
  content: "󰻔";
}

.mdi-voicemail:before {
  content: "󰕽";
}

.mdi-volleyball:before {
  content: "󰦴";
}

.mdi-volume-high:before {
  content: "󰕾";
}

.mdi-volume-low:before {
  content: "󰕿";
}

.mdi-volume-medium:before {
  content: "󰖀";
}

.mdi-volume-minus:before {
  content: "󰝞";
}

.mdi-volume-mute:before {
  content: "󰝟";
}

.mdi-volume-off:before {
  content: "󰖁";
}

.mdi-volume-plus:before {
  content: "󰝝";
}

.mdi-volume-source:before {
  content: "󱄠";
}

.mdi-volume-variant-off:before {
  content: "󰸈";
}

.mdi-volume-vibrate:before {
  content: "󱄡";
}

.mdi-vote:before {
  content: "󰨟";
}

.mdi-vote-outline:before {
  content: "󰨠";
}

.mdi-vpn:before {
  content: "󰖂";
}

.mdi-vuejs:before {
  content: "󰡄";
}

.mdi-vuetify:before {
  content: "󰹭";
}

.mdi-walk:before {
  content: "󰖃";
}

.mdi-wall:before {
  content: "󰟾";
}

.mdi-wall-sconce:before {
  content: "󰤜";
}

.mdi-wall-sconce-flat:before {
  content: "󰤝";
}

.mdi-wall-sconce-flat-variant:before {
  content: "󰐜";
}

.mdi-wall-sconce-round:before {
  content: "󰝈";
}

.mdi-wall-sconce-round-variant:before {
  content: "󰤞";
}

.mdi-wallet:before {
  content: "󰖄";
}

.mdi-wallet-giftcard:before {
  content: "󰖅";
}

.mdi-wallet-membership:before {
  content: "󰖆";
}

.mdi-wallet-outline:before {
  content: "󰯝";
}

.mdi-wallet-plus:before {
  content: "󰾎";
}

.mdi-wallet-plus-outline:before {
  content: "󰾏";
}

.mdi-wallet-travel:before {
  content: "󰖇";
}

.mdi-wallpaper:before {
  content: "󰸉";
}

.mdi-wan:before {
  content: "󰖈";
}

.mdi-wardrobe:before {
  content: "󰾐";
}

.mdi-wardrobe-outline:before {
  content: "󰾑";
}

.mdi-warehouse:before {
  content: "󰾁";
}

.mdi-washing-machine:before {
  content: "󰜪";
}

.mdi-washing-machine-alert:before {
  content: "󱆼";
}

.mdi-washing-machine-off:before {
  content: "󱆽";
}

.mdi-watch:before {
  content: "󰖉";
}

.mdi-watch-export:before {
  content: "󰖊";
}

.mdi-watch-export-variant:before {
  content: "󰢕";
}

.mdi-watch-import:before {
  content: "󰖋";
}

.mdi-watch-import-variant:before {
  content: "󰢖";
}

.mdi-watch-variant:before {
  content: "󰢗";
}

.mdi-watch-vibrate:before {
  content: "󰚱";
}

.mdi-watch-vibrate-off:before {
  content: "󰳚";
}

.mdi-water:before {
  content: "󰖌";
}

.mdi-water-alert:before {
  content: "󱔂";
}

.mdi-water-alert-outline:before {
  content: "󱔃";
}

.mdi-water-boiler:before {
  content: "󰾒";
}

.mdi-water-boiler-alert:before {
  content: "󱆳";
}

.mdi-water-boiler-off:before {
  content: "󱆴";
}

.mdi-water-check:before {
  content: "󱔄";
}

.mdi-water-check-outline:before {
  content: "󱔅";
}

.mdi-water-minus:before {
  content: "󱔆";
}

.mdi-water-minus-outline:before {
  content: "󱔇";
}

.mdi-water-off:before {
  content: "󰖍";
}

.mdi-water-off-outline:before {
  content: "󱔈";
}

.mdi-water-outline:before {
  content: "󰸊";
}

.mdi-water-percent:before {
  content: "󰖎";
}

.mdi-water-percent-alert:before {
  content: "󱔉";
}

.mdi-water-plus:before {
  content: "󱔊";
}

.mdi-water-plus-outline:before {
  content: "󱔋";
}

.mdi-water-polo:before {
  content: "󱊠";
}

.mdi-water-pump:before {
  content: "󰖏";
}

.mdi-water-pump-off:before {
  content: "󰾓";
}

.mdi-water-remove:before {
  content: "󱔌";
}

.mdi-water-remove-outline:before {
  content: "󱔍";
}

.mdi-water-well:before {
  content: "󱁫";
}

.mdi-water-well-outline:before {
  content: "󱁬";
}

.mdi-watering-can:before {
  content: "󱒁";
}

.mdi-watering-can-outline:before {
  content: "󱒂";
}

.mdi-watermark:before {
  content: "󰘒";
}

.mdi-wave:before {
  content: "󰼮";
}

.mdi-waveform:before {
  content: "󱑽";
}

.mdi-waves:before {
  content: "󰞍";
}

.mdi-waze:before {
  content: "󰯞";
}

.mdi-weather-cloudy:before {
  content: "󰖐";
}

.mdi-weather-cloudy-alert:before {
  content: "󰼯";
}

.mdi-weather-cloudy-arrow-right:before {
  content: "󰹮";
}

.mdi-weather-fog:before {
  content: "󰖑";
}

.mdi-weather-hail:before {
  content: "󰖒";
}

.mdi-weather-hazy:before {
  content: "󰼰";
}

.mdi-weather-hurricane:before {
  content: "󰢘";
}

.mdi-weather-lightning:before {
  content: "󰖓";
}

.mdi-weather-lightning-rainy:before {
  content: "󰙾";
}

.mdi-weather-night:before {
  content: "󰖔";
}

.mdi-weather-night-partly-cloudy:before {
  content: "󰼱";
}

.mdi-weather-partly-cloudy:before {
  content: "󰖕";
}

.mdi-weather-partly-lightning:before {
  content: "󰼲";
}

.mdi-weather-partly-rainy:before {
  content: "󰼳";
}

.mdi-weather-partly-snowy:before {
  content: "󰼴";
}

.mdi-weather-partly-snowy-rainy:before {
  content: "󰼵";
}

.mdi-weather-pouring:before {
  content: "󰖖";
}

.mdi-weather-rainy:before {
  content: "󰖗";
}

.mdi-weather-snowy:before {
  content: "󰖘";
}

.mdi-weather-snowy-heavy:before {
  content: "󰼶";
}

.mdi-weather-snowy-rainy:before {
  content: "󰙿";
}

.mdi-weather-sunny:before {
  content: "󰖙";
}

.mdi-weather-sunny-alert:before {
  content: "󰼷";
}

.mdi-weather-sunny-off:before {
  content: "󱓤";
}

.mdi-weather-sunset:before {
  content: "󰖚";
}

.mdi-weather-sunset-down:before {
  content: "󰖛";
}

.mdi-weather-sunset-up:before {
  content: "󰖜";
}

.mdi-weather-tornado:before {
  content: "󰼸";
}

.mdi-weather-windy:before {
  content: "󰖝";
}

.mdi-weather-windy-variant:before {
  content: "󰖞";
}

.mdi-web:before {
  content: "󰖟";
}

.mdi-web-box:before {
  content: "󰾔";
}

.mdi-web-clock:before {
  content: "󱉊";
}

.mdi-webcam:before {
  content: "󰖠";
}

.mdi-webcam-off:before {
  content: "󱜷";
}

.mdi-webhook:before {
  content: "󰘯";
}

.mdi-webpack:before {
  content: "󰜫";
}

.mdi-webrtc:before {
  content: "󱉈";
}

.mdi-wechat:before {
  content: "󰘑";
}

.mdi-weight:before {
  content: "󰖡";
}

.mdi-weight-gram:before {
  content: "󰴿";
}

.mdi-weight-kilogram:before {
  content: "󰖢";
}

.mdi-weight-lifter:before {
  content: "󱅝";
}

.mdi-weight-pound:before {
  content: "󰦵";
}

.mdi-whatsapp:before {
  content: "󰖣";
}

.mdi-wheel-barrow:before {
  content: "󱓲";
}

.mdi-wheelchair-accessibility:before {
  content: "󰖤";
}

.mdi-whistle:before {
  content: "󰦶";
}

.mdi-whistle-outline:before {
  content: "󱊼";
}

.mdi-white-balance-auto:before {
  content: "󰖥";
}

.mdi-white-balance-incandescent:before {
  content: "󰖦";
}

.mdi-white-balance-iridescent:before {
  content: "󰖧";
}

.mdi-white-balance-sunny:before {
  content: "󰖨";
}

.mdi-widgets:before {
  content: "󰜬";
}

.mdi-widgets-outline:before {
  content: "󱍕";
}

.mdi-wifi:before {
  content: "󰖩";
}

.mdi-wifi-alert:before {
  content: "󱚵";
}

.mdi-wifi-arrow-down:before {
  content: "󱚶";
}

.mdi-wifi-arrow-left:before {
  content: "󱚷";
}

.mdi-wifi-arrow-left-right:before {
  content: "󱚸";
}

.mdi-wifi-arrow-right:before {
  content: "󱚹";
}

.mdi-wifi-arrow-up:before {
  content: "󱚺";
}

.mdi-wifi-arrow-up-down:before {
  content: "󱚻";
}

.mdi-wifi-cancel:before {
  content: "󱚼";
}

.mdi-wifi-check:before {
  content: "󱚽";
}

.mdi-wifi-cog:before {
  content: "󱚾";
}

.mdi-wifi-lock:before {
  content: "󱚿";
}

.mdi-wifi-lock-open:before {
  content: "󱛀";
}

.mdi-wifi-marker:before {
  content: "󱛁";
}

.mdi-wifi-minus:before {
  content: "󱛂";
}

.mdi-wifi-off:before {
  content: "󰖪";
}

.mdi-wifi-plus:before {
  content: "󱛃";
}

.mdi-wifi-refresh:before {
  content: "󱛄";
}

.mdi-wifi-remove:before {
  content: "󱛅";
}

.mdi-wifi-settings:before {
  content: "󱛆";
}

.mdi-wifi-star:before {
  content: "󰸋";
}

.mdi-wifi-strength-1:before {
  content: "󰤟";
}

.mdi-wifi-strength-1-alert:before {
  content: "󰤠";
}

.mdi-wifi-strength-1-lock:before {
  content: "󰤡";
}

.mdi-wifi-strength-1-lock-open:before {
  content: "󱛋";
}

.mdi-wifi-strength-2:before {
  content: "󰤢";
}

.mdi-wifi-strength-2-alert:before {
  content: "󰤣";
}

.mdi-wifi-strength-2-lock:before {
  content: "󰤤";
}

.mdi-wifi-strength-2-lock-open:before {
  content: "󱛌";
}

.mdi-wifi-strength-3:before {
  content: "󰤥";
}

.mdi-wifi-strength-3-alert:before {
  content: "󰤦";
}

.mdi-wifi-strength-3-lock:before {
  content: "󰤧";
}

.mdi-wifi-strength-3-lock-open:before {
  content: "󱛍";
}

.mdi-wifi-strength-4:before {
  content: "󰤨";
}

.mdi-wifi-strength-4-alert:before {
  content: "󰤩";
}

.mdi-wifi-strength-4-lock:before {
  content: "󰤪";
}

.mdi-wifi-strength-4-lock-open:before {
  content: "󱛎";
}

.mdi-wifi-strength-alert-outline:before {
  content: "󰤫";
}

.mdi-wifi-strength-lock-open-outline:before {
  content: "󱛏";
}

.mdi-wifi-strength-lock-outline:before {
  content: "󰤬";
}

.mdi-wifi-strength-off:before {
  content: "󰤭";
}

.mdi-wifi-strength-off-outline:before {
  content: "󰤮";
}

.mdi-wifi-strength-outline:before {
  content: "󰤯";
}

.mdi-wifi-sync:before {
  content: "󱛇";
}

.mdi-wikipedia:before {
  content: "󰖬";
}

.mdi-wind-turbine:before {
  content: "󰶥";
}

.mdi-window-close:before {
  content: "󰖭";
}

.mdi-window-closed:before {
  content: "󰖮";
}

.mdi-window-closed-variant:before {
  content: "󱇛";
}

.mdi-window-maximize:before {
  content: "󰖯";
}

.mdi-window-minimize:before {
  content: "󰖰";
}

.mdi-window-open:before {
  content: "󰖱";
}

.mdi-window-open-variant:before {
  content: "󱇜";
}

.mdi-window-restore:before {
  content: "󰖲";
}

.mdi-window-shutter:before {
  content: "󱄜";
}

.mdi-window-shutter-alert:before {
  content: "󱄝";
}

.mdi-window-shutter-open:before {
  content: "󱄞";
}

.mdi-windsock:before {
  content: "󱗺";
}

.mdi-wiper:before {
  content: "󰫩";
}

.mdi-wiper-wash:before {
  content: "󰶦";
}

.mdi-wizard-hat:before {
  content: "󱑷";
}

.mdi-wordpress:before {
  content: "󰖴";
}

.mdi-wrap:before {
  content: "󰖶";
}

.mdi-wrap-disabled:before {
  content: "󰯟";
}

.mdi-wrench:before {
  content: "󰖷";
}

.mdi-wrench-outline:before {
  content: "󰯠";
}

.mdi-xamarin:before {
  content: "󰡅";
}

.mdi-xamarin-outline:before {
  content: "󰡆";
}

.mdi-xing:before {
  content: "󰖾";
}

.mdi-xml:before {
  content: "󰗀";
}

.mdi-xmpp:before {
  content: "󰟿";
}

.mdi-y-combinator:before {
  content: "󰘤";
}

.mdi-yahoo:before {
  content: "󰭏";
}

.mdi-yeast:before {
  content: "󰗁";
}

.mdi-yin-yang:before {
  content: "󰚀";
}

.mdi-yoga:before {
  content: "󱅼";
}

.mdi-youtube:before {
  content: "󰗃";
}

.mdi-youtube-gaming:before {
  content: "󰡈";
}

.mdi-youtube-studio:before {
  content: "󰡇";
}

.mdi-youtube-subscription:before {
  content: "󰵀";
}

.mdi-youtube-tv:before {
  content: "󰑈";
}

.mdi-yurt:before {
  content: "󱔖";
}

.mdi-z-wave:before {
  content: "󰫪";
}

.mdi-zend:before {
  content: "󰫫";
}

.mdi-zigbee:before {
  content: "󰵁";
}

.mdi-zip-box:before {
  content: "󰗄";
}

.mdi-zip-box-outline:before {
  content: "󰿺";
}

.mdi-zip-disk:before {
  content: "󰨣";
}

.mdi-zodiac-aquarius:before {
  content: "󰩽";
}

.mdi-zodiac-aries:before {
  content: "󰩾";
}

.mdi-zodiac-cancer:before {
  content: "󰩿";
}

.mdi-zodiac-capricorn:before {
  content: "󰪀";
}

.mdi-zodiac-gemini:before {
  content: "󰪁";
}

.mdi-zodiac-leo:before {
  content: "󰪂";
}

.mdi-zodiac-libra:before {
  content: "󰪃";
}

.mdi-zodiac-pisces:before {
  content: "󰪄";
}

.mdi-zodiac-sagittarius:before {
  content: "󰪅";
}

.mdi-zodiac-scorpio:before {
  content: "󰪆";
}

.mdi-zodiac-taurus:before {
  content: "󰪇";
}

.mdi-zodiac-virgo:before {
  content: "󰪈";
}

.mdi-blank:before {
  content: "";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: #0000008a;
}

.mdi-dark.mdi-inactive:before {
  color: #00000042;
}

.mdi-light:before {
  color: #fff;
}

.mdi-light.mdi-inactive:before {
  color: #ffffff4d;
}

.mdi-rotate-45:before {
  transform: rotate(45deg);
}

.mdi-rotate-90:before {
  transform: rotate(90deg);
}

.mdi-rotate-135:before {
  transform: rotate(135deg);
}

.mdi-rotate-180:before {
  transform: rotate(180deg);
}

.mdi-rotate-225:before {
  transform: rotate(225deg);
}

.mdi-rotate-270:before {
  transform: rotate(270deg);
}

.mdi-rotate-315:before {
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  filter: FlipH;
  -ms-filter: "FlipH";
  transform: scaleX(-1);
}

.mdi-flip-v:before {
  filter: FlipV;
  -ms-filter: "FlipV";
  transform: scaleY(-1);
}

.mdi-spin:before {
  animation: 2s linear infinite mdi-spin;
}

@keyframes mdi-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

/*# sourceMappingURL=index.5d7edd15.css.map */
